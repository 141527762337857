import { InputNumber, Row } from 'antd'
import { useEffect, useRef, useState, useContext } from 'react';
import ScreenWidthContext from '../context/screenwidthcontext';
import '../styles.css'
import './pawnshop.css'
import checkNotMobile from '../components/logic/mobile';
import { Link } from 'react-router-dom';
import ConnectedContext from '../context/connectedcontext';
import Web3Context from '../context/web3context';
import toContract from '../types/truffle_contracts';
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';
import Web3 from 'web3';
import { Raffle } from './pawnshop_types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getScratchBalance } from '../components/utils/scratch-functions';
import Spinner from '../components/spinner';

import BoomerBingoContract from '../contracts/BoomerBingo.json'
import BoomerSquadContract from '../contracts/BoomerSquad.json'
import ScratchContract from '../contracts/Scratch.json'
import { webStorage } from '../config';


export const BoomerBingo = () => {

    //Screen Width context
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    // Get web3 and the account (metamask public key) to place it on our button text once user is CONNECTED
    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)
    // context to verify if the user WANTS to be connected ot the site, IE if they have clicked the connect button yet, again we cant DISCONNECT the user's metamask, only give the illusion of NOT being connected
    const {connected, setConnected} = useContext(ConnectedContext)
    // contract read and write providers context
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    
    const bingoWrite = toContract(BoomerBingoContract)
    const bingoRead = toContract(BoomerBingoContract)

    const boomerRead = toContract(BoomerSquadContract)

    const scratchRead = toContract(ScratchContract)
    const scratchWrite = toContract(ScratchContract)
    

    if(contractWriteProviders){
        const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
        bingoWrite.setProvider(contractWriteProviders)
        scratchWrite.setProvider(contractWriteProviders)

        bingoRead.setProvider(contractReadProviders)
        boomerRead.setProvider(contractReadProviders)
        scratchRead.setProvider(contractReadProviders)

    }

    const [games, setGames] = useState([] as number[])
    const [bingoCards, setBingoCards] = useState([] as any[])
    const [gameNumber, setGameNumber] = useState(0)
    const [boomerNumber, setBoomerNumber] = useState('0')
    const [boomers, setBoomers] = useState([] as any[])
    const [placedBoomers, setPlacedBoomers] = useState([] as any[])
    const [gameDraws, setGameDraws] = useState([] as any[])
    const [allowance, setAllowance] = useState('')
    const [loadingCards, setLoadingCards] = useState(false)

    const GameButtons = (props) => {
        const gamelist = props.list
        return (
            gamelist.map((game, index) => 
                <div className={`bingo-game-button shadow-button ${gameNumber===game?'selected':''}`} onClick={() => selectGame(game)}>
                    {game}
                </div>
            )
        )
    }

    const BoomerButtons = (props) => {
        const boomerslist = props.list
        return(
            boomerslist.map((boomer, index) =>
                {
                    const boom = boomer.toString()
                    return(
                        !placedBoomers.includes(boom)?
                        <div className={`bingo-boomer-button small-shadow-button ${boomerNumber===boom?'selected':''}`} onClick={() => selectBoomer(boom)}>
                            {boom}
                        </div>
                        :<></>
                    )
                }

            )
        )
    }

    const BingoDraws = (props) => {
        const drawlist = props.list
        console.log(drawlist)
        return(
            drawlist.map((draw, index) => {
                let numSquareColor = getSquareColor(draw)
                return(
                    <div className={`bingo-ball ${numSquareColor}`}>
                        {draw.toString()}
                    </div>
                )
            })
        )
    }

    const BingoCards = (props) => {
        const cardlist = props.list
        let squareNum = 0
        let cardnum = 0
        let m =''
        if(!checkNotMobile(screenWidth)){
            m='mobile-'
        }
        return (
            cardlist.map((card, index) => {
                cardnum++
                return(
                <div className={`${m}bingo-full-card`}>
                    <Row justify='center' className='bingo-card-number'>
                        Bingo Card #{cardnum}
                    </Row>
                <div className={`${m}bingo-bingo-row`}>
                    <div className={`bingo-card-letter orange`}>
                        B
                        <div className={`bingo-card-letter-small`}>
                            *Backg.
                        </div>
                        <div className={`bingo-card-letter-small`}>
                            *Skin
                        </div>
                    </div>
                    <div className={`bingo-card-letter green`}>
                        I
                        <div className={`bingo-card-letter-small`}>
                            *Crypto
                        </div>
                        <div className={`bingo-card-letter-small`}>
                            *Activity
                        </div>
                    </div>
                    <div className={`bingo-card-letter blue`}>
                        N
                        <div className={`bingo-card-letter-small`}>
                            *Outfit
                        </div>
                        <div className={`bingo-card-letter-small`}>
                            *Transp.
                        </div>
                    </div>
                    <div className={`bingo-card-letter purple`}>
                        G
                        <div className={`bingo-card-letter-small`}>
                            *Head
                        </div>
                    </div>
                    <div className={`bingo-card-letter Yellow`}>
                        O
                        <div className={`bingo-card-letter-small`}>
                            *Mouth
                        </div>
                        <div className={`bingo-card-letter-small`}>
                            *Eyes
                        </div>
                    </div>
                </div>
                <div className={`${m}bingo-card`}>
                    <div className={`bingo-column`}>
                    {
                        card.slice(0, 5).map((square, index) => 
                        <div className={`bingo-square`}>
                            <BingoSquare number={squareNum} square={square} />
                            <script>{squareNum++}</script>
                        </div>
                        )
                    }
                    </div>
                    <div className={`bingo-column`}>
                    {
                        card.slice(5, 10).map((square, index) => 
                        <div className={`bingo-square`}>
                            <BingoSquare number={squareNum} square={square} />
                            <script>{squareNum++}</script>
                        </div>
                        )
                    }
                    </div>
                    <div className={`bingo-column`}>
                    {
                        card.slice(10, 15).map((square, index) => 
                        <div className={`bingo-square`}>
                            <BingoSquare number={squareNum} square={square} />
                            <script>{squareNum++}</script>
                        </div>
                        )
                    }
                    </div>
                    <div className={`bingo-column`}>
                    {
                        card.slice(15, 20).map((square, index) => 
                        <div className={`bingo-square`}>
                            <BingoSquare number={squareNum} square={square} />
                            <script>{squareNum++}</script>
                        </div>
                        )
                    }
                    </div>
                    <div className={`bingo-column`}>
                    {
                        card.slice(20, 25).map((square, index) => 
                        <div className={`bingo-square`}>
                            <BingoSquare number={squareNum} square={square} />
                            <script>{squareNum++}</script>
                        </div>
                        )
                    }
                    </div>
                </div>
            </div>  
            )}

            )
        )
    }

    const getSquareColor = (number) => {
        let num = Number(number)
        if(num < 40){
            return 'orange-square'
        }
        if(num < 72){
            return 'green-square'
        }
        if(num < 123){
            return 'blue-square'
        }
        if(num < 154){
            return 'purple-square'
        }
        if(num < 204){
            return 'yellow-square'
        }
    }

    const BingoSquare = (props) => {
        const num = props.number
        const square = props.square
        const squareNumbers = square[1]
        const squareIsWin = square[3]
        let m =''
        if(!checkNotMobile(screenWidth)){
            m='mobile-'
        }
        return(
            square[0] === '0'?
            <div className='square small-shadow-button' onClick={() => placeBoomer(num)}>
                {num}
            </div>
            :
            <div className={`square ${squareIsWin?'opacity-50':''}`}>
                <img className='bingo-boomer-image' src={'https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/' + square[0] + '.png'}/>
                <div className='flex-and-center bingo-square-numbers-row'>
                {squareNumbers.map((number, index) => {
                    let numSquareColor = getSquareColor(number)
                    return(
                    <div className={`${m}bingo-square-number ${numSquareColor}`}>
                        {number}
                    </div>
                    )
                })}
                </div>
                {
                    squareIsWin?
                    <div className='flex-and-center bingo-check-cirle'>
                        <div className={`${m}check`}></div>
                    </div>
                    :<></>
                }
 
            </div>
        )
    }

    const placeBoomer = async(bingoSquare: number) => {
        let bb = await bingoWrite.deployed()
        console.log(scratchBalance)
        if(boomerNumber==='0'){
            toast.error('Please Select a Boomer Token ID', {autoClose: 5000});
            return
        }
        if(Number(scratchBalance) < 10){
            toast.error('Not Enough $SCRATCH to Enter!', {autoClose: 5000});
            return
        }
        await toast.promise(
            bb.placeBoomer(boomerNumber, bingoSquare, {from: account}),
            {
              pending: 'Placing Boomer Number in Bingo Square...',
              success: {
                render(){
                  return <div>Success! Now refreshing browser.</div>
                },
                // other options
                icon: "🟢",
              },
              error: 'Something went wrong! :x.'
            }
        ).then(
            () => setTimeout((() => window.location.reload()), 5500)
        )
    }

    const approveBoomerBingoContract = async() => {
        let bb = await bingoRead.deployed()
        let s = await scratchWrite.deployed()
        // approve contract for 100 scratch
        await toast.promise(
            s.approve(bb.address, '1000000000000000000000', {from: account}),
            {
              pending: 'Approving Boomer Bingo contract...',
              success: {
                render(){
                  return <div>Success! Now refreshing browser</div>
                },
                // other options
                icon: "🟢",
              },
              error: 'Something went wrong! :x.'
            }
        ).then(
            () => setTimeout((() => window.location.reload()), 5500)
        )
    }

    const selectGame = (game: number) => {
        setGameNumber(game)
        console.log(game)
    }

    const selectBoomer = (boomer: string) => {
        setBoomerNumber(boomer)
        console.log(boomer)
    }

    useEffect(() => {
        let on = true
        const getBingoCards = async() => {
            setLoadingCards(true)
            let bb = await bingoRead.deployed()
            let bingoGame = await bb.games(gameNumber)
            let amountOfCards = Math.ceil(Number(bingoGame.squareLimit.toString())/25)
            let tempGameDraws = await bb.viewGameBallsDrawn(gameNumber)
            let tempCardArray = []
            for(let i = 0; i < amountOfCards; i++){
                let card = await bb.viewBingoCard(gameNumber, i*25)
                tempCardArray.push(card)
            }
            let tempPlacedBoomers = [] as any[]
            for(let i in tempCardArray){
                for(let ii in tempCardArray[i])
                if(tempCardArray[i][ii][0] !== '0'){
                    tempPlacedBoomers.push(tempCardArray[i][ii][0])
                }
            }

            setBingoCards(tempCardArray)
            setGameDraws(tempGameDraws)
            setPlacedBoomers(tempPlacedBoomers)
            setLoadingCards(false)
        }
        if(on){
            getBingoCards()
        }
        return () => {on = false}
    },[gameNumber])

    useEffect(() => {
        let on = true
        const getGames = async() => {
            let bb = await bingoRead.deployed()
            let gameIndex = await bb.gameIndex()
            let tempGames = [] as number[]
            for (let i = 0; i < Number(gameIndex.toString()); i++){
                tempGames.push(i+1)
            }
            setGames(tempGames)
        }
        if(on){
            getGames()
        }

        return () => {on = false}
    },[account])

    useEffect(() => {
        let on = true
        const checkAllowance = async() => {
            let bb = await bingoRead.deployed()
            let s = await scratchRead.deployed()
            let allowance = await s.allowance(account, bb.address)
            setAllowance(allowance.toString())
        }
        if(on){
            checkAllowance()
        }
        return () => {on = false}
    },[account])

    useEffect(() => {
        let on = true
        const getBoomers = async() => {
            let bs = await boomerRead.deployed()
            const balance = await bs.balanceOf(account)
            const tokenIDPromises: any[] = []
            for (let i = 0; i < balance; i++){
              tokenIDPromises.push(bs.tokenOfOwnerByIndex(account, i))
            }
            const tokenIDs = await Promise.all(tokenIDPromises)
            setBoomers(tokenIDs)
        }
        if(on){
            getBoomers()
        }

        return () => {on = false}
    },[account])

    return(
        checkNotMobile(screenWidth)?
        <div className='bingo-background'>
            <img className='banner' style={{width: '100%', marginBottom: '50px'}} src={webStorage + '/pawnshop/bingobanner.png'} alt=""/>
            <ToastContainer position="top-center"/>
            <div className='flex-and-center flex-column nowbold' style={{gap: '20px', margin: '20px', color: 'white'}}>
                <div className='bingo-title flex-and-center flex-column bingo-select-game'>
                    {
                        BigInt(allowance) < BigInt(10)?
                        <div className='bingo-approve-button shadow-button' onClick={() => approveBoomerBingoContract()}>
                            0. Approve Bingo Contract
                        </div>
                        :<></>
                    }
                    1. Select Bingo Game Number
                    <div className='flex-and-center'>
                        <GameButtons list={games}/>
                    </div>
                    2. Select Boomer Token ID
                    <Row className='flex-and-center'>
                        <BoomerButtons list={boomers}/>
                    </Row>
                    3. Select a Square!<div>{'(5 $SCRATCH EACH)'}</div>
                </div>
                <Spinner loading={loadingCards} size={200}/>
                {
                    gameDraws.length > 0?
                    <div className='flex-and-center flex-column bingo-title bingo-balls-drawn'>
                        Bingo Balls Drawn
                        <Row className='flex-and-center bingo-ball-row'>
                            <BingoDraws list={gameDraws} />
                        </Row>
                    </div>
                    :<></>
                }
                {
                    gameNumber !== 0?
                    <Row className='flex-and-center'>
                        <BingoCards  list={bingoCards}/>
                    </Row>:<></>
                }
            </div>
        </div>

        :
        <>
            <img className='banner' style={{width: '100%'}} src={webStorage + '/pawnshop/bingobanner.png'} alt=""/>
            <ToastContainer position="top-center"/>
            <div className='flex-and-center flex-column nowbold' style={{gap: '20px', margin: '20px', color: 'white'}}>
                <div className='mobile-bingo-title flex-and-center flex-column mobile-bingo-select-game'>
                    {
                        BigInt(allowance) < BigInt(10)?
                        <div className='mobile-bingo-approve-button shadow-button' onClick={() => approveBoomerBingoContract()}>
                            0. Approve Bingo Contract
                        </div>
                        :<></>
                    }
                    1. Select Bingo Game Number
                    <div className='flex-and-center'>
                        <GameButtons list={games}/>
                    </div>
                    2. Select Boomer Token ID
                    <Row className='flex-and-center'>
                        <BoomerButtons list={boomers}/>
                    </Row>
                    3. Select a Square!<div>{'(5 $SCRATCH EACH)'}</div>
                </div>
                <Spinner loading={loadingCards} size={200}/>
                {
                    gameDraws.length > 0?
                    <div className='flex-and-center flex-column mobile-bingo-title mobile-bingo-balls-drawn'>
                        Bingo Balls Drawn
                        <Row className='flex-and-center bingo-ball-row'>
                            <BingoDraws list={gameDraws} />
                        </Row>
                    </div>
                    :<></>
                }
                {
                    gameNumber !== 0?
                    <Row className='flex-and-center'>
                        <BingoCards  list={bingoCards}/>
                    </Row>:<></>
                }
            </div>
        </>
    )
}