export const merchObjs = [

    {
        name: "ZTB LABS",
        iamge: "ztblabs_logo2",
        link: "https://ztblabs.com/"
    },
    {
        name: "ETSY",
        iamge: "ztblabs_logo2",
        link: "https://www.etsy.com/shop/ZTBLabs"
    },
]