import React, { useEffect, useState } from 'react';
import Web3Context from './../context/web3context';
import { useContext } from 'react';
import { getDiscordUsername, removeDiscordFromWeb3, saveDiscordToWeb3 } from './chainchecker_api_calls';
import { truncateAddress } from '../utils';
import { webStorage } from '../config';
import './chaincheckerverify.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Spinner from "../components/spinner";

export const ChainCheckerVerify = () => {


    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)
    const [username, setUsername] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    // verification button
    const verifyButton =
    <a href="https://discord.com/api/oauth2/authorize?client_id=1103082806910910544&redirect_uri=https%3A%2F%2Fwww.boomersquad.io%2Fchaincheckerverify&response_type=code&scope=identify" rel="noopener noreferrer">
        {/* <a href='https://discord.com/api/oauth2/authorize?client_id=1103082806910910544&redirect_uri=https%3A%2F%2Fmain.d62neh58o1b3i.amplifyapp.com%2Fchaincheckerverify&response_type=code&scope=identify'><div> */}
        <button className="cc-verify-button">
            Verify
        </button>
    </a>

    const unverifyButton =
    <button className="cc-unverify-button" onClick={() => {
        toast.promise(
            removeDiscordFromWeb3(username),
            {
                pending: 'Unlinking Discord account...',
                success: 'Successfully unlinked Discord account',
                error: 'Error unlinking Discord account'
            }
        ).then(
            (response) => {
                if (response.status === 200) {
                    setUsername('')
                }
            }
        )
    }}>
        Unlink Discord
    </button>


    const VerificationBox = ({ web3Address, username }) => {      
        return (
            <div style={
                { 
                    backgroundColor: 'black', 
                    padding: '40px', 
                    borderRadius: '40px', 
                    color: 'white', 
                    minWidth: '350px',
                    maxWidth: '1000px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px'
                }
            }>
                <img src={webStorage + '/chainchecker/chainchecker_logo.png'} style={{width: '100px', borderRadius: '100px'}}/>
                <strong>Chain Checker</strong>
                <div>
                    <p className="cc-small-text">Discord Web3 Verification Bot</p>
                </div>
                {
                    !loading?
                    <>
                        <div className='flex-and-center flex-column' style={{ marginTop: '15px'}}>
                            <strong>Web3 Address</strong> <div className="cc-small-text">{truncateAddress(web3Address, 3)}</div>
                        </div>
                        <div>
                            {
                                username === ''?
                                <div style={{marginBottom: '15px'}}>
                                    {verifyButton}
                                </div>
                                :
                                <>
                                    <div className='flex-and-center flex-column' style={{marginBottom: '15px'}}>
                                        <strong>Discord User</strong> <div className="cc-small-text">{username}</div>
                                    </div>
                                    <div className='flex-and-center'>
                                        {unverifyButton}
                                    </div>
                                </>

                            }
                        </div>
                    </>

                    :
                    <div>
                        <Spinner loading={true}/>
                    </div>
                }


            </div>
        )
    }

    const getQueryParams = (search: string) => {
        return search
          ? (/^[?#]/.test(search) ? search.slice(1) : search)
              .split('&')
              .reduce((params: any, param: string) => {
                    const [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {})
          : {};
      };


    useEffect(() => {
        const search = window.location.search;
        const queryParams = getQueryParams(search);
    
        if (queryParams.code && account !== '') {
            // Get discord user by calling API with auth code
            saveDiscordToWeb3(queryParams.code, account)
            .then(
                (response) => {
                    if(response.status === 200){
                        const urlWithoutParams = window.location.origin + window.location.pathname;
                        window.history.replaceState({}, document.title, urlWithoutParams);
                        console.log('user', response.data.user)
                        setUsername(response.data.user)
                        toast.success('Successfully linked Discord account')
                    } else {
                        toast.error('Error linking Discord')
                    }
                    setLoading(false)
                }
            )
        }  else if (account !== '') {
            // Get discord user by calling API with auth code
            getDiscordUsername(account)
            .then(
                (response) => {
                    console.log('response', response)
                    if (response.status === 200) {
                        setUsername(response.data.username)
                    }
                    setLoading(false)
                }
            );
        }
    }, [account]);
    //https://discord.com/api/oauth2/authorize?client_id=1103082806910910544&redirect_uri=https%3A%2F%2Fmain.d62neh58o1b3i.amplifyapp.com%2Fchaincheckerverify&response_type=code&scope=identify
    return (
        <>
            <ToastContainer position="top-center"/>
            <div className='flex-and-center flex-column' style={{padding: '13% 0px', color: 'white', gap: '30px', fontSize: '30px'}}>
                <VerificationBox web3Address={account} username={username} />
            </div>
        </>

    );
};
