const axios = require('axios').default; // axios.<method> will now provide autocomplete and parameter typings

export const fetchMetadata = async (account: string, contractAddress: string) => {
    if (account === '') {
        return [] as any[]
    }
    let res = await axios.post('https://api.boomersquad.io/fetch_metadata',
        {
            account: account,
            contractAddress: contractAddress
        },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        })
    return res.data
}

export const fetchSingleTokenMeta = async (tokenid: string, contractAddress: string) => {
    let res = await axios.post('https://api.boomersquad.io/fetch_metadata_single',
        {
            tokenid: tokenid,
            contractAddress: contractAddress
        },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        })
    return res.data
}

export const getStakingStats = async (account: string) => {
    let res = await axios.post('https://api.boomersquad.io/boomroom/staking_stats', { account: account },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )

    return res.data
}

export const getStakingStatsV2 = async (account: string) => {
    let res = await axios.post('https://api.boomersquad.io/boomroom/staking_statsV2', { account: account },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )

    return res.data
}

export const getBoomRoomComponents = async (boomroom: any) => {
    let res = await axios.post('https://api.boomersquad.io/fetch_boomroom_components',
        { boomroom: boomroom },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )

    return res.data
}

export const requestChangeVariant = async (id: string, variant: string) => {
    let res = await axios.post('https://api.boomersquad.io/zoomer_switch_variant',
        {
            id: id,
            variant: variant
        },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )

    return res.data
}

export const updateCoverTrait = async (id: string) => {
    let res = await axios.post('https://api.boomersquad.io/zoomer_update_cover',
        {
            id: id,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )

    return res.data
}

export const getPlaygroundData = async () => {
    let res = await axios.get('https://api.boomersquad.io/playground_data')
    // let res = await axios.get('http://localhost:3002/playground_data')
    return res.data
}

export const getAllNFTs = async (address: string) => {

    const headers = {
        'x-api-key': process.env.REACT_APP_BOOMER_API_AUTH,
        'Content-Type': 'application/json', // specify the content type of your request
    };

    let res = await axios.get('https://api.boomersquad.io/nfts/' + address, { headers: headers })
    return res.data
}

export const pickGameTeamRequest = async (gameName: string, userAddress: string, teamName: string) => {
    let res = await axios.post('https://api.boomersquad.io/gameTeamPicker/pickTeam',
        {
            gameName: gameName,
            userAddress: userAddress,
            teamName: teamName
        },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    )

    return res.data
}

export const getAllTeamMembersForGame = async (gameName: string) => {
    let res = await axios.get('https://api.boomersquad.io/gameTeamPicker/getAllTeamMembersForGame/' + gameName)
    return res.data
}

export const getGameTeamForUser = async (gameName: string, userAddress: string) => {
    let res = await axios.get('https://api.boomersquad.io/gameTeamPicker/getTeamForUserInGame/' + gameName + '/' + userAddress)
    return res.data
}

// app.post('/gameTeamPicker/pickTeam', async (req: Request, res: Response) => {
//   const gameName: string = req.body.gameName;
//   const userAddress: string = req.body.userAddress;
//   const teamName: string = req.body.teamName;

//   if (!gameName || !userAddress || !teamName) {
//       res.status(400).send({ message: 'Missing required parameters' });
//       return;
//   }

//   pickGameTeam(gameName, userAddress, teamName)
//       .then(
//           (result) => res.status(200).send({ message: 'Team picked successfully!' }),
//       )
//       .catch((e) => {
//           console.log(e)
//           res.status(400).send({ message: 'Error picking team' })
//       })
// })

// app.get('/gameTeamPicker/getAllTeamMembersForGame/:gameName', async (req: Request, res: Response) => {
//   const gameName: string = req.params.gameName;