import { useState, useContext, useEffect, useCallback } from 'react';
import ConnectedContext from '../context/connectedcontext';
import Web3Context from '../context/web3context';
import toContract from '../types/truffle_contracts';
import BoomerSquad from './../contracts/BoomerSquad.json';
import Web3 from 'web3';
import { List, Card, Row, Pagination, Col, Collapse, Modal, Button, InputNumber, Space, Divider, message } from 'antd';
import ScreenWidthContext from '../context/screenwidthcontext';
import Spinner from '../components/spinner'
import connectedAndGoodChain from '../components/logic/connectedandgoodchain';
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';
import checkNotMobile from '../components/logic/mobile';
import Scratch from "../contracts/Scratch.json"
import BoomRoomContract from '../contracts/BoomRoom.json' //boom room contract
import BRSeason2 from '../contracts/BRSeason2.json'
import { boomroomBaseURI, contractAddresses } from './boomroom_config';
import { StakingPool } from './boomroom_interfaces';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { brokenRoomMetadata, getContractTokens } from './boomroom_utils';
import {truncateAddress, truncateBalance} from './../utils'
import { YoutubeVideo } from '../components/youtubevideo/youtubevideo';
import { fetchMetadata, getStakingStats, getStakingStatsV2 } from '../components/utils/api_calls';
import { webStorage } from '../config';
const axios = require('axios').default; // axios.<method> will now provide autocomplete and parameter typings

function BoomRoomStakingS2 () {

  // Get web3 and the account (metamask public key) to place it on our button text once user is CONNECTED
  const {account, setAccount, balance, setBalance, chainID, setChainID} = useContext(Web3Context) //ommiting web3 and creating it locally because of having to set up contract
  // context to verify if the user WANTS to be connected ot the site, IE if they have clicked the connect button yet, again we cant DISCONNECT the user's metamask, only give the illusion of NOT being connected
  const {connected, setConnected} = useContext(ConnectedContext)
  // Screen Width context
  const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
  // contract read and write providers context
  const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
  const boomRoomRead = toContract(BoomRoomContract)
  const boomRoomWrite = toContract(BoomRoomContract)

  const seasonRead = toContract(BRSeason2)
  const seasonWrite = toContract(BRSeason2)

  const boomerRead = toContract(BoomerSquad)

  const scratch = toContract(Scratch)

  // hooks
  const [boomRoomMetadataList, setBoomRoomMetadataList] = useState([] as any[])
  const [boomRoomMetadataListStaked, setBoomRoomMetadataListStaked] = useState([] as any[])
  const [roomsLoading, setRoomsLoading] = useState(true)
  const [stakedLoading, setStakedLoading] = useState(true)
  const [poolsLoading, setPoolsLoading] = useState(true)
  const [activeSort, setActiveSort] = useState('All')
  const [roomsToStake, setRoomsToStake] = useState([] as string[])
  const [roomsToUnstake, setRoomsToUnstake] = useState([] as string[])
  const [stakingPools, setStakingPools] = useState([] as StakingPool[])
  const [approved, setApproved] = useState(false)
  const [userRoomsStaked, setUserRoomsStaked] = useState([] as any[])
  const [userPoolScores, setUserPoolScores] = useState([] as any[])
  const [mode, setMode] = useState('Staking')
  const [userScratchEarned, setUserScratchEarned] = useState('0')
  const [boomerBalance, setBoomerBalance] = useState(0)
  const [poolScratchEarned, setPoolScratchEarned] = useState([] as string[])

  // set up contract
  if(contractWriteProviders){
    const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer

    scratch.setProvider(contractReadProviders)
    boomRoomRead.setProvider(contractReadProviders)
    seasonRead.setProvider(contractReadProviders)
    boomerRead.setProvider(contractReadProviders)

    boomRoomWrite.setProvider(contractWriteProviders)
    seasonWrite.setProvider(contractWriteProviders)
  }

  // Contract functions

  const unstakeRooms = async() => {
    let brs = await seasonWrite.deployed()
    let roomIndexes = []
    for(let i = userRoomsStaked.length-1; i > -1; i--){
      if(roomsToUnstake.includes(userRoomsStaked[i])){
        roomIndexes.push(i)
      }
    }
    await toast.promise(
      brs.unstakeRooms(roomIndexes, {from: account}),
      {
        pending: 'Unstaking Boom Rooms....',
        success: {
          render(){
            return <div>Success! Now refreshing browser.</div>
          },
          // other options
          icon: "🟢",
        },
        error: 'Failed to Unstake.'
      }
  ).catch((e) => {console.log('something went wrong :(', e); return})
  setTimeout((() => window.location.reload()), 5500)
    
  }

  const withdrawScratch = async() => {
    let brs = await seasonWrite.deployed()
    await toast.promise(
      brs.getRewards({from: account}),
      {
        pending: 'Withdrawing Scratch...',
        success: {
          render(){
            return <div>Success! Now refreshing browser.</div>
          },
          // other options
          icon: "🟢",
        },
        error: 'Failed to Withdraw.'
      }
  ).catch((e) => {console.log('something went wrong :(', e); return})
  setTimeout((() => window.location.reload()), 5500)
  }

  const stakeRooms = async() => {
    let brs = await seasonWrite.deployed()
    if(roomsToStake.length === 0){
      toast.error('Please select at least 1 room.', {autoClose: 5000})
      return
    }
    await toast.promise(
      brs.stakeRooms(roomsToStake, {from: account}),
      {
        pending: 'Staking Boom Rooms....',
        success: {
          render(){
            return <div>Success! Now refreshing browser.</div>
          },
          // other options
          icon: "🟢",
        },
        error: 'Failed to Stake.'
      }
  ).catch((e) => {console.log('something went wrong :(', e); return})
  setTimeout((() => window.location.reload()), 5500)
  }

  const approveStakingContract = async() => {
    let brs = await seasonRead.deployed()
    let br = await boomRoomRead.deployed()
    await toast.promise(
      br.setApprovalForAll(brs.address, true, {from: account}),
      {
        pending: 'Approving Staking Contract',
        success: {
          render(){
            return <div>Success! Now refreshing browser.</div>
          },
          // other options
          icon: "🟢",
        },
        error: 'Failed to set Approval.'
      }
  ).catch((e) => {console.log('something went wrong :(', e); return})
  setTimeout((() => window.location.reload()), 5500)
  }

  // Functions

  const selectRoomForStaking = (tokenID: string) => {
    setRoomsToStake(roomsToStake.concat([tokenID]))
  }

  const unselectRoomForStaking = (tokenID: string) => {
    const index = roomsToStake.indexOf(tokenID);
    let newArr: string[] = []
    for(let i = 0; i < roomsToStake.length; i++){
      if(i !== index){
        newArr.push(roomsToStake[i])
      }
    }
    
    setRoomsToStake(newArr)

  }

  const selectRoomForUnstaking = (tokenID: string) => {
    let newUnstakeList = [] as any[]
    newUnstakeList.push(tokenID)
    setRoomsToUnstake(roomsToUnstake.concat(newUnstakeList))
  }

  const unselectRoomForUnstaking = (tokenID: string) => {
    const index = roomsToUnstake.indexOf(tokenID);
    let newArr: string[] = []
    for(let i = 0; i < roomsToUnstake.length; i++){
      if(i !== index){
        newArr.push(roomsToUnstake[i])
      }
    }
    
    setRoomsToUnstake(newArr)

  }

  const selectAll = () => {
    let allSelected = []
    for(let i in boomRoomMetadataList){
      if(boomRoomMetadataList[i]){
        if(boomRoomMetadataList[i].attributes[2].value.toString() !== '0'){
          allSelected.push(boomRoomMetadataList[i].id)
        }
      }
    }
    setRoomsToStake(allSelected)
  }

  const selectAllUnstake = () => {
    let allSelected = []
    for(let i in boomRoomMetadataListStaked){
      allSelected.push(boomRoomMetadataListStaked[i].id)
    }
    setRoomsToUnstake(allSelected)
  }

  const reset = () => {
    setRoomsToStake([] as string[])
  }

  const resetUnstake = () => {
    setRoomsToUnstake([] as string[])
  }

  const getPoolImageByType = (poolType: number) => {
    switch(poolType){
      case 0:
        return 'Free_Pool.png'
      case 1:
        return 'Single_Pool.png'
      case 2:
        return 'Studio_Pool.png'
      case 3:
        return 'Double_Pool.png'
      case 4:
        return 'Loft_Pool.png'
      case 5:
        return 'Penthouse_Pool.png'
    }
  }

  const getPoolNameByType = (poolType: number) => {
    switch(poolType){
      case 0:
        return 'Fair Market'
      case 1:
        return 'Protected Single'
      case 2:
        return 'Protected Studio'
      case 3:
        return 'Protected Double'
      case 4:
        return 'Protected Loft'
      case 5:
        return 'Protected Penthouse'
    }
  }

  // Components
  const BoomRoomList = (props) => {
    const boomroomlist = props.boomroomlist
    let roomList = []
    for(let i in boomroomlist){
      if(boomroomlist[i] !== undefined && boomroomlist[i] !== null){
        if(boomroomlist[i].attributes[0].value === activeSort && boomroomlist[i].attributes[2].value !== '0' || activeSort ==='All' && boomroomlist[i].attributes[2].value !== '0'){
          roomList.push(boomroomlist[i])
        }
      } else {
        roomList.push(brokenRoomMetadata)
      }
    }
    const boomroomItems = roomList.map((boomroom, index) => <BoomRoomCard key={index} value={boomroom} />)
    return (
        boomroomItems.length > 0?
        <Row justify='center'>
          {boomroomItems}
        </Row>
        :
        <>
          <img src={webStorage + '/boomrooms/' + 'noroomsfound.png'} style={{marginTop: '60px', maxWidth: '800px', width: '90%'}}/>
        </>
      )
  }

  const BoomRoomCard = (boomroom) => {
    const id = boomroom.value.id
    const name = boomroom.value.name
    const multiplier = boomroom.value.attributes[1].value
    const score = boomroom.value.attributes[2].value
    const image = boomroom.value.image
    const openCompSlots = boomroom.value.attributes[3].value
    return(
        mode === 'Staking'?
          !roomsToStake.includes(id)?
           <div className='flex-and-center flex-column bigger-on-hover' onClick={id!=='0'?() => {selectRoomForStaking(id.toString())}:()=>''}>
            <div className='br-boomroomcard neon-border-orange'>
              <img className='br-boomroomcard-image' src={image}/>
              <div className='br-blackline'/>
              <div className='br-boomroomcard-bottombox'>
                <div className='flex-and-center flex-column'>
                  <div>{name}</div>
                  <div>{'Open Component Slots: ' + openCompSlots}</div>
                  <div>{'Multiplier: ' + multiplier}</div>
                  <div>{'Total Score: ' + score}</div>
                </div>
              </div>
            </div>
           </div>
           :
           <div className='flex-and-center flex-column bigger-on-hover' onClick={() => {unselectRoomForStaking(id.toString())}}>
           <div className='br-boomroomcard neon-border-green'>
             <img className='br-boomroomcard-image' src={image}/>
             <div className='br-blackline'/>
             <div className='br-boomroomcard-bottombox'>
               <div className='flex-and-center flex-column'>
                 <div>{name}</div>
                 <div>{'Open Component Slots: ' + openCompSlots}</div>
                 <div>{'Multiplier: ' + multiplier}</div>
                 <div>{'Total Score: ' + score}</div>
               </div>
             </div>
           </div>
          </div>
        :

        !roomsToUnstake.includes(id)?
        <div className='flex-and-center flex-column bigger-on-hover' onClick={() => {selectRoomForUnstaking(id.toString())}}>
         <div className='br-boomroomcard neon-border-lblue'>
           <img className='br-boomroomcard-image' src={image}/>
           <div className='br-blackline'/>
           <div className='br-boomroomcard-bottombox'>
             <div className='flex-and-center flex-column'>
               <div>{name}</div>
               <div>{'Open Component Slots: ' + openCompSlots}</div>
               <div>{'Multiplier: ' + multiplier}</div>
               <div>{'Total Score: ' + score}</div>
             </div>
           </div>
         </div>
        </div>
        :
        <div className='flex-and-center flex-column bigger-on-hover' onClick={() => {unselectRoomForUnstaking(id.toString())}}>
        <div className='br-boomroomcard neon-border-green'>
          <img className='br-boomroomcard-image' src={image}/>
          <div className='br-blackline'/>
          <div className='br-boomroomcard-bottombox'>
            <div className='flex-and-center flex-column'>
              <div>{name}</div>
              <div>{'Open Component Slots: ' + openCompSlots}</div>
              <div>{'Multiplier: ' + multiplier}</div>
              <div>{'Total Score: ' + score}</div>
            </div>
          </div>
        </div>
       </div>
        
    )
  }

  const PoolList = (props) => {
    const poolList = props.poolList
    const poolItems = poolList.map((pool, index) => <PoolItem key={index} value={pool} />)
    return(<Row justify='center'>{poolItems}</Row>)

  }

  const PoolItem = (pool) => {
    const poolType = pool.value.poolType
    let scratchRate  = pool.value.scratchRate
    const totalRooms = pool.value.totalRooms
    const totalPoints = pool.value.totalPoints
    const image = getPoolImageByType(poolType)
    const poolName = getPoolNameByType(poolType)

    return(
      <div className='br-stakingpool neon-border-orange'>
        <img className='br-pool-image' src={webStorage + '/boomrooms/' + image}/>
        <div className='br-pool-title'>{poolName}&nbsp;Pool</div>
        <Row  className='br-pool-section'>
          <div className='neon-text neon-green'>{'$SCRATCH'}&nbsp;</div><div>{'Rate Per Minute'}</div><div className='span'/><div className='neon-text neon-green'>{Web3.utils.fromWei((BigInt(scratchRate)*BigInt(60)).toString(), 'ether').substring(0, 6)}</div>
        </Row>
        <Row  className='br-pool-section'>
          <div>{'Total Rooms Staked'}</div><div className='span'/><div>{totalRooms}</div>
        </Row>
        <Row  className='br-pool-section'>
          <div>{'Total Points'}</div><div className='span'/><div>{totalPoints}</div>
        </Row>
        <div style={{marginBottom: '15px'}}/>
        <Row  className='br-pool-section'>
            <div>{"Your Points"}</div><div className='span'/><div>{userPoolScores[poolType]?userPoolScores[poolType]:"0"}</div>
        </Row>
        <Row  className='br-pool-section'>
        <div>{"Your Pool Share"}</div>
        <div className='span'/>
        <div>
            {userPoolScores[poolType]!==undefined&&userPoolScores[poolType]!=='0'?(Number(userPoolScores[poolType])/Number(totalPoints)*100).toFixed(4):"0"}%
        </div>
        </Row>
        <Row  className='br-pool-section'>
          <div className='neon-text neon-green'>{'$SCRATCH Earned'}</div><div className='span'></div><div className='neon-text neon-green'>{poolScratchEarned[poolType]}</div>
        </Row>
      </div>
    )
  }

  const Scoreboard = () => {
    return(
      <div className='br-scoreboard neon-border-orange'>
      <div className='br-scoreboard-row neon-text neon-grey'><div>Account</div><div className='span'></div><div>{truncateAddress(account, 3)?truncateAddress(account, 3):'NONE'}</div></div>
      <div className='br-scoreboard-row neon-text neon-yellow'><div>Total Rooms Staked</div><div className='span'></div><div>{userRoomsStaked.length}</div></div>
      <div className='br-scoreboard-row neon-text neon-lblue'><div>Total Points Staked</div><div className='span'></div><div>{userPoolScores[0]?userPoolScores[0]:'0'}</div></div>
      <div className='br-scoreboard-row'><div className='neon-text neon-orange'>{'Boomers Owned'}</div><div className='span'></div><div className='neon-text neon-orange'>{boomerBalance}</div></div>
      <div className='br-scoreboard-row'><div className='neon-text neon-orange'>{'Withdraw Tax'}</div><div className='span'></div><div className='neon-text neon-orange'>{boomerBalance>0?'0%':'50%'}</div></div>
      <div className='br-scoreboard-row justify-center'><div className='neon-text neon-green font-25'>{'$SCRATCH Earned'}</div></div>
      <div className='br-scoreboard-row'><div className='neon-text neon-green'>{'Fair Pool'}</div><div className='span'></div><div className='neon-text neon-green'>{poolScratchEarned[0]}</div></div>
      <div className='br-scoreboard-row'><div className='neon-text neon-green'>{'Single Pool'}</div><div className='span'></div><div className='neon-text neon-green'>{poolScratchEarned[1]}</div></div>
      <div className='br-scoreboard-row'><div className='neon-text neon-green'>{'Studio Pool'}</div><div className='span'></div><div className='neon-text neon-green'>{poolScratchEarned[2]}</div></div>
      <div className='br-scoreboard-row'><div className='neon-text neon-green'>{'Double Pool'}</div><div className='span'></div><div className='neon-text neon-green'>{poolScratchEarned[3]}</div></div>
      <div className='br-scoreboard-row'><div className='neon-text neon-green'>{'Loft Pool'}</div><div className='span'></div><div className='neon-text neon-green'>{poolScratchEarned[4]}</div></div>
      <div className='br-scoreboard-row'><div className='neon-text neon-green'>{'Penthouse Pool'}</div><div className='span'></div><div className='neon-text neon-green'>{poolScratchEarned[5]}</div></div>
      <div className='br-scoreboard-row'><div className='neon-text neon-green'>{'Total'}</div><div className='span'></div><div className='neon-text neon-green'>{userScratchEarned}</div></div>
      <div className={`neon-button-simple neon-green neon-hover-green bigger-on-hover border-radius-30`} onClick={withdrawScratch}>Withdraw $SCRATCH</div>
    </div>
    )
  }

  useEffect(()=>{
    let isApiSubscribed = true;
    const getUserStats = async() => {
      if(isApiSubscribed && account !== ''){
      let stats = await getStakingStatsV2(account)
      setBoomRoomMetadataListStaked(stats.fullJSONList)
      setUserScratchEarned(stats.corrected)
      setUserPoolScores(stats.userScoreByPoolType)
      setPoolScratchEarned(stats.userEarnedByPoolType)
      setUserRoomsStaked(stats.userRoomsStaked)
      setStakedLoading(false)
    }}
    getUserStats()
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  },[connected, account])

  useEffect(()=>{
    let isApiSubscribed = true;
    const getBoomerBalance = async() => {
      let boom = await boomerRead.deployed()
      let balance = await boom.balanceOf(account)
      setBoomerBalance(Number(balance.toString()))
    }
    if(isApiSubscribed && account !== ''){
      getBoomerBalance()
    }
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  },[connected]) 

  useEffect(()=>{
    let isApiSubscribed = true;
    const checkIsApprovedForAll = async() => {
      let brs = await seasonRead.deployed()
      let br = await boomRoomRead.deployed()
      let approvedForAll = await br.isApprovedForAll(account, brs.address)
      setApproved(approvedForAll)
    }
    if(isApiSubscribed && account !== ''){
      checkIsApprovedForAll()
    }
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  },[connected])

  useEffect(()=>{
    let isApiSubscribed = true;
    const getSeasonPools = async() => {
        let res = await fetch('https://api.boomersquad.io/BRSeason_poolsV2')
        let pools = await res.json()
        let tempStakingPools = [] as any[]
        for(let i = 0; i < pools.length; i++){
            tempStakingPools.push({
            poolType: i, 
            scratchRate: pools[i].scratchRate.toString(),
            totalRooms: pools[i].totalRooms.toString(),
            totalPoints: pools[i].totalPoints.toString()
            })
      }
      if(isApiSubscribed){
        setStakingPools(tempStakingPools)
        setPoolsLoading(false)
      }

    }
    if(account !== ""){
        getSeasonPools()
    }
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  },[connected])

  //Call initTable to ultimately set the state or uriList
  useEffect(() => {
    let isApiSubscribed = true;

    if(isApiSubscribed){
      fetchMetadata(account, contractAddresses.boom_room).then((fullBoomRooms)=>{
          setBoomRoomMetadataList(fullBoomRooms)
          setRoomsLoading(false)
      })
    }
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  },[account])


    return (

        // check if user on mobile
        checkNotMobile(screenWidth)?
        <>
        <ToastContainer position="top-center"/>
        <YoutubeVideo videoURL="https://www.youtube.com/embed/EOUXHEXjPlI" />
          <div className='neon-text neon-orange br-page-title'>
            Staking Grounds V2
          </div>
          
          <div className='neon-line neon-orange'></div>
          <div className='br-subtitle'>My Stats</div>
          <Scoreboard />
          <div className='br-subtitle'>Staking Pools</div>
          
          {
              roomsLoading || stakedLoading || poolsLoading?
              <div className='mobile-br-construction-spinner-box flex-and-center'>
                <Spinner loading={roomsLoading || stakedLoading || poolsLoading} size={100}/>
              </div>
              :
              <>
                <PoolList poolList={stakingPools}/>
              </>
          }
          <div className='br-subtitle'>Select Mode</div>
          <Row justify='center' className='br-roomconstruction-sortrow' style={{marginBottom: '30px'}}>
            <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${mode==='Staking'?'active': ''}`} onClick={() => setMode('Staking')}>Staking</div>
            <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${mode==='Unstaking'?'active': ''}`} onClick={() => setMode('Unstaking')}>Unstaking</div>
          </Row>
          {
            mode === 'Staking'?
            <>
              {
                approved?
                <Row>
                  <div className={`neon-button-simple width-250 margin-20 ${true?'neon-green neon-hover-green bigger-on-hover':'neon-dgreen'}`} onClick={stakeRooms}>Stake Selected</div>
                  <div className={`neon-button-simple width-250 margin-20 neon-yellow neon-hover-yellow bigger-on-hover`} onClick={selectAll}>Select All</div>
                  <div className={`neon-button-simple width-250 margin-20 neon-red neon-hover-red bigger-on-hover`} onClick={reset}>Reset</div>
                </Row>
                :
                <Row>
                  <div className={`neon-button-simple margin-20 ${true?'neon-green neon-hover-green bigger-on-hover':'neon-dgreen'}`} onClick={approveStakingContract}>Approve Staking Contract</div>
                </Row>
              }
              <div className='br-subtitle'>Select Rooms to Stake</div>
              <Row justify='center' className='br-roomconstruction-sortrow'>
                <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${activeSort==='All'?'active': ''}`} onClick={() => setActiveSort('All')}>All</div>
                <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${activeSort==='Single'?'active': ''}`} onClick={() => setActiveSort('Single')}>Single</div>
                <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${activeSort==='Studio'?'active': ''}`} onClick={() => setActiveSort('Studio')}>Studio</div>
                <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${activeSort==='Double'?'active': ''}`} onClick={() => setActiveSort('Double')}>Double</div>
                <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${activeSort==='Loft'?'active': ''}`} onClick={() => setActiveSort('Loft')}>Loft</div>
                <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${activeSort==='Penthouse'?'active': ''}`} onClick={() => setActiveSort('Penthouse')}>Penthouse</div>
              </Row>
              {
                roomsLoading || stakedLoading || poolsLoading?
                  <div className='mobile-br-construction-spinner-box flex-and-center'>
                    <Spinner loading={roomsLoading || stakedLoading || poolsLoading} size={100}/>
                  </div>
                  :
                  <>
                    <BoomRoomList boomroomlist={boomRoomMetadataList}/>
                  </>
              }
            </>
            
            :
            <>
              {
                approved?
                <Row>
                  <div className={`neon-button-simple margin-20 ${true?'neon-green neon-hover-green bigger-on-hover':'neon-dgreen'}`} onClick={unstakeRooms}>Unstake Selected</div>
                  <div className={`neon-button-simple width-250 margin-20 neon-yellow neon-hover-yellow bigger-on-hover`} onClick={selectAllUnstake}>Select All</div>
                  <div className={`neon-button-simple width-250 margin-20 neon-red neon-hover-red bigger-on-hover`} onClick={resetUnstake}>Reset</div>
                </Row>
                :
                <Row>
                  <div className={`neon-button-simple margin-20 ${true?'neon-green neon-hover-green bigger-on-hover':'neon-dgreen'}`} onClick={approveStakingContract}>Approve Staking Contract</div>
                </Row>
              }
              <div className='br-subtitle'>Select Room to Unstake</div>
              <Row justify='center' className='br-roomconstruction-sortrow'>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${activeSort==='All'?'active': ''}`} onClick={() => setActiveSort('All')}>All</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${activeSort==='Single'?'active': ''}`} onClick={() => setActiveSort('Single')}>Single</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${activeSort==='Studio'?'active': ''}`} onClick={() => setActiveSort('Studio')}>Studio</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${activeSort==='Double'?'active': ''}`} onClick={() => setActiveSort('Double')}>Double</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${activeSort==='Loft'?'active': ''}`} onClick={() => setActiveSort('Loft')}>Loft</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${activeSort==='Penthouse'?'active': ''}`} onClick={() => setActiveSort('Penthouse')}>Penthouse</div>
              </Row>
              {
                roomsLoading || stakedLoading || poolsLoading?
                  <div className='mobile-br-construction-spinner-box flex-and-center'>
                    <Spinner loading={roomsLoading || stakedLoading || poolsLoading} size={100}/>
                  </div>
                  :
                  <>
                    <BoomRoomList boomroomlist={boomRoomMetadataListStaked}/>
                  </>
              }
            </>
          }
        </>

        // MOBILE MOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILE
        // MOBILE MOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILE
        // MOBILE MOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILE
        // MOBILE MOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILE
        // MOBILE MOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILE
        // MOBILE MOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILE
        // MOBILE MOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILE
        // MOBILE MOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILEMOBILE
        :
        <>
        <ToastContainer position="top-center"/>
        <YoutubeVideo videoURL="https://www.youtube.com/embed/EOUXHEXjPlI" />
          <div className='neon-text neon-orange mobile-br-page-title'>
            Staking Grounds V2
          </div>
          <div className='neon-line neon-orange'></div>
          <div className='mobile-br-subtitle'>My Stats</div>
          <Scoreboard />
          <div className='mobile-br-subtitle'>Staking Pools</div>
          {/* <div className='br-scoreboard'></div> */}
          {
              roomsLoading || stakedLoading || poolsLoading?
              <div className='mobile-br-construction-spinner-box flex-and-center'>
                <Spinner loading={roomsLoading || stakedLoading || poolsLoading} size={100}/>
              </div>
              :
              <>
                <PoolList poolList={stakingPools}/>
              </>
          }
          
          <div className='mobile-br-subtitle'>Select Mode</div>
          <Row justify='center' className='br-roomconstruction-sortrow' style={{marginBottom: '30px'}}>
            <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${mode==='Staking'?'active': ''}`} onClick={() => setMode('Staking')}>Staking</div>
            <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${mode==='Unstaking'?'active': ''}`} onClick={() => setMode('Unstaking')}>Unstaking</div>
          </Row>
          {
            mode === 'Staking'?
            <>
              {
                approved?
                <Row justify='center' align='middle'>
                  <div className={`neon-button-simple width-250 margin-20 ${true?'neon-green neon-hover-green bigger-on-hover':'neon-dgreen'}`} onClick={stakeRooms}>Stake Selected</div>
                  <div className={`neon-button-simple width-250 margin-20 neon-yellow neon-hover-yellow bigger-on-hover`} onClick={selectAll}>Select All</div>
                  <div className={`neon-button-simple width-250 margin-20 neon-red neon-hover-red bigger-on-hover`} onClick={reset}>Reset</div>
                </Row>
                :
                <Row>
                  <div className={`neon-button-simple margin-20 ${true?'neon-green neon-hover-green bigger-on-hover':'neon-dgreen'}`} onClick={approveStakingContract}>Approve Staking Contract</div>
                </Row>
              }
              <div className='mobile-br-subtitle'>Select Rooms to Stake</div>
              <Row justify='center' className='br-roomconstruction-sortrow'>
                <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${activeSort==='All'?'active': ''}`} onClick={() => setActiveSort('All')}>All</div>
                <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${activeSort==='Single'?'active': ''}`} onClick={() => setActiveSort('Single')}>Single</div>
                <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${activeSort==='Studio'?'active': ''}`} onClick={() => setActiveSort('Studio')}>Studio</div>
                <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${activeSort==='Double'?'active': ''}`} onClick={() => setActiveSort('Double')}>Double</div>
                <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${activeSort==='Loft'?'active': ''}`} onClick={() => setActiveSort('Loft')}>Loft</div>
                <div className={`neon-button-simple neon-orange bigger-on-hover br-construction-select-buttons ${activeSort==='Penthouse'?'active': ''}`} onClick={() => setActiveSort('Penthouse')}>Penthouse</div>
              </Row>
              {
                roomsLoading || stakedLoading || poolsLoading?
                  <div className='mobile-br-construction-spinner-box flex-and-center'>
                    <Spinner loading={roomsLoading || stakedLoading || poolsLoading} size={100}/>
                  </div>
                  :
                  <>
                    <BoomRoomList boomroomlist={boomRoomMetadataList}/>
                  </>
              }
            </>
            
            :
            <>
              {
                approved?
                <Row justify='center' align='middle'>
                  <div className={`neon-button-simple margin-20 ${true?'neon-green neon-hover-green bigger-on-hover':'neon-dgreen'}`} onClick={unstakeRooms}>Unstake Selected</div>
                  <div className={`neon-button-simple width-250 margin-20 neon-yellow neon-hover-yellow bigger-on-hover`} onClick={selectAllUnstake}>Select All</div>
                  <div className={`neon-button-simple width-250 margin-20 neon-red neon-hover-red bigger-on-hover`} onClick={resetUnstake}>Reset</div>
                </Row>
                :
                <Row justify='center' align='middle'>
                  <div className={`neon-button-simple margin-20 ${true?'neon-green neon-hover-green bigger-on-hover':'neon-dgreen'}`} onClick={approveStakingContract}>Approve Staking Contract</div>
                </Row>
              }
              <div className='mobile-br-subtitle'>Select Room to Unstake</div>
              <Row justify='center' className='br-roomconstruction-sortrow'>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${activeSort==='All'?'active': ''}`} onClick={() => setActiveSort('All')}>All</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${activeSort==='Single'?'active': ''}`} onClick={() => setActiveSort('Single')}>Single</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${activeSort==='Studio'?'active': ''}`} onClick={() => setActiveSort('Studio')}>Studio</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${activeSort==='Double'?'active': ''}`} onClick={() => setActiveSort('Double')}>Double</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${activeSort==='Loft'?'active': ''}`} onClick={() => setActiveSort('Loft')}>Loft</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-construction-select-buttons ${activeSort==='Penthouse'?'active': ''}`} onClick={() => setActiveSort('Penthouse')}>Penthouse</div>
              </Row>
              {
                roomsLoading || stakedLoading || poolsLoading?
                  <div className='mobile-br-construction-spinner-box flex-and-center'>
                    <Spinner loading={roomsLoading || stakedLoading || poolsLoading} size={100}/>
                  </div>
                  :
                  <>
                    <BoomRoomList boomroomlist={boomRoomMetadataListStaked}/>
                  </>
              }
            </>
          }
        </>

    )
}

export default BoomRoomStakingS2