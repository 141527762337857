import { webStorage } from "../config"

export interface NewsObject {
    title: string
    date: string
    article: any
}

export const newsObjects = [
    {
        title: 'NOSTALGIA',
        date: 'February 9 2023',
        article: (
        <div>
            <img src={webStorage + '/newsletters/Nostalgia_1.png'} style={{width: '100%'}}/>
            <img src={webStorage + '/newsletters/Nostalgia_2.png'} style={{width: '100%'}}/>
            <img src={webStorage + '/newsletters/Nostalgia_3.png'} style={{width: '100%'}}/>
            <img src={webStorage + '/newsletters/Nostalgia_4.png'} style={{width: '100%'}}/>
            <img src={webStorage + '/newsletters/Nostalgia_5.png'} style={{width: '100%'}}/>
        </div> 
        )
    },
]
