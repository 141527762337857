import { useParams } from 'react-router-dom';
import { useState, useContext, useEffect, useCallback } from 'react';
import checkNotMobile from "../components/logic/mobile";
import ScreenWidthContext from '../context/screenwidthcontext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { webStorage } from '../config';
import { fetchSingleTokenMeta } from '../components/utils/api_calls';
import { convertIPFSUrl } from '../utils';

export const SingleToken = () => {

    const { screenWidth, setScreenWidth } = useContext(ScreenWidthContext)

    const [owner, setOwner] = useState('0x...')
    const [cAdd, setCAdd] = useState('0x...')

    const { contractAddress, id } = useParams();

    const [metadata, setMetadata] = useState({} as any)

    const Attributes = (props) => {
        let list = props.list
        let listItems = list.map((item) => {
            let trait_type = item.trait_type.toString()
            let value = item.value.toString()
            return (
                <div className='zoomer-trait-box'>
                    <div className='zoomer-trait-box-type'>
                        {trait_type}
                    </div>
                    <div className='zoomer-trait-box-value'>
                        {value}
                    </div>
                </div>
            )
        })

        return listItems
    }

    useEffect(() => {
        const getMetadata = async () => {
            let json = await fetchSingleTokenMeta(id.toString(), contractAddress)
            return {
                json: json.json,
                owner: json.owner,
                add: contractAddress
            }
        }

        getMetadata().then((result) => { setMetadata(result.json); setOwner(result.owner); setCAdd(result.add) })
    }, [id])

    let m = checkNotMobile(screenWidth) ? '' : 'm-'

    return (
        <>
            <ToastContainer position="top-center" />
            {/* <div className='centered-x-and-y'><img className='banner' src={webStorage + '/zoomers/zoomer_mint_banner.png'} alt="foo"/></div> */}
            <div className={m + 'zoomer-all-container'} style={{ marginTop: '100px' }}>
                <div className={m + 'zoomer-section-container'}>
                    <img className='zoomer-section-image' src={metadata.image ? metadata.image.startsWith('ipfs://') ? convertIPFSUrl(metadata.image) : metadata.image : ''} />
                </div>
                <div className={m + 'zoomer-section-container'}>
                    <div className='zoomer-section-box'>
                        <div className='zoomer-section-row'>
                            <div className='zoomer-section-name'>
                                {metadata.name}
                            </div>
                        </div>
                    </div>
                    <div className='zoomer-section-box'>
                        <div className='zoomer-info-container'>
                            <div className="test-container">
                                <div className="test-title">Token ID</div>
                                <div className="test-description">{metadata.id || metadata.edition}</div>
                            </div>
                            <div className="test-container">
                                <div className="test-title">Contract Address</div>
                                <div className="test-description"><a href={'https://cronoscan.com/address/' + cAdd} target='blank'>{cAdd}</a></div>
                            </div>
                            <div className="test-container">
                                <div className="test-title">Owner</div>
                                <div className="test-description"><a href={'https://cronoscan.com/address/' + owner} target='blank'>{owner}</a></div>
                            </div>
                            <div className="test-container">
                                <div className="test-title">Description</div>
                                <div className="test-description">{metadata.description}</div>
                            </div>
                        </div>
                    </div>
                    <div className='zoomer-section-box'>
                        <div className='zoomer-attributes-container'>
                            <Attributes list={metadata.attributes ? metadata.attributes : []} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}