import { useState, useContext, useEffect } from 'react';
import ConnectedContext from '../context/connectedcontext';
import Web3Context from '../context/web3context';
import toContract from '../types/truffle_contracts';
import Web3 from 'web3';
import { Row } from 'antd';
import ScreenWidthContext from '../context/screenwidthcontext';
import Spinner from '../components/spinner'
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';
import checkNotMobile from '../components/logic/mobile';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BoomRoomContract from '../contracts/BoomRoom.json' //boom room contract
import { contractAddresses } from './boomroom_config';
import Activity from '../contracts/Activity.json'
import Bed from "../contracts/Bed.json"
import Building from "../contracts/Building.json"
import Couch from "../contracts/Couch.json"
import Desk from "../contracts/Desk.json"
import Door from "../contracts/Door.json"
import Entertainment from "../contracts/Entertainment.json"
import Pet from "../contracts/Pet.json"
import Table from "../contracts/Table.json"
import Window from "../contracts/Window.json"
import Scratch from "../contracts/Scratch.json"
import { componentTraitCodex } from './component_trait_codex';
import { compTraitScoreCodex } from './component_trait_score_codex';
import { YoutubeVideo } from '../components/youtubevideo/youtubevideo';
import { fetchMetadata } from '../components/utils/api_calls';
import { webStorage } from '../config';

function BoomRoomComponentBuilder () {

    //set up for web3 and account context
    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)
    // context to verify if the user WANTS to be connected ot the site, IE if they have clicked the connect button yet, again we cant DISCONNECT the user's metamask, only give the illusion of NOT being connected
    const {connected, setConnected} = useContext(ConnectedContext)
    // Screen Width context
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    // contract read and write providers context
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    
    const contractRead = toContract(BoomRoomContract)
    const contractWrite = toContract(BoomRoomContract)

    const activity = toContract(Activity)
    const bed = toContract(Bed)
    const building = toContract(Building)
    const couch = toContract(Couch)
    const desk = toContract(Desk)
    const door = toContract(Door)
    const entertainment = toContract(Entertainment)
    const pet = toContract(Pet)
    const table = toContract(Table)
    const windowComponent = toContract(Window)

    const scratch = toContract(Scratch)

    // set up contract
    if(contractWriteProviders){
        const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
        contractRead.setProvider(contractReadProviders)
        contractWrite.setProvider(contractWriteProviders)
        // activity.setProvider(contractReadProviders)
        // bed.setProvider(contractReadProviders)
        // building.setProvider(contractReadProviders)
        // couch.setProvider(contractReadProviders)
        // desk.setProvider(contractReadProviders)
        // door.setProvider(contractReadProviders)
        // entertainment.setProvider(contractReadProviders)
        // pet.setProvider(contractReadProviders)
        // table.setProvider(contractReadProviders)
        // windowComponent.setProvider(contractReadProviders)

        // scratch.setProvider(contractReadProviders)
    }

    // const [componentJSONList, setComponentJSONList] = useState([] as any[])
    // const [activityJsons, setActivityJsons] = useState([] as any[])
    // const [bedJsons, setBedJsons] = useState([] as any[])
    // const [buildingJsons, setBuildingJsons] = useState([] as any[])
    // const [couchJsons, setCouchJsons] = useState([] as any[])
    // const [deskJsons, setDeskJsons] = useState([] as any[])
    // const [doorJsons, setDoorJsons] = useState([] as any[])
    // const [entertainmentJsons, setEntertainmentJsons] = useState([] as any[])
    // const [petJsons, setPetJsons] = useState([] as any[])
    // const [tableJsons, setTableJsons] = useState([] as any[])
    // const [windowJsons, setWindowJsons] = useState([] as any[])
  /**
   * Interfaces
   */

  interface Component {
    id: string,
    name: string,
    type: string,
    image: string,
    score: string,
    attributes: Array<{trait_type: string, value: string}>
  }

  /**
   * General hooks
   */

    // select mode
  const [activeSort, setActiveSort] = useState('Window')
  const [mode, setMode] = useState('select')

    // upgrade mode
  const [upgradeSelection, setUpgradeSelection] = useState({} as any)
  const [upgradeTraitSelection, setUpgradeTraitSelection] = useState('')
  const [upgradeTraitTypeSelection, setUpgradeTraitTypeSelection] = useState('')
  const [upgradeSelection2, setUpgradeSelection2] = useState({} as any)

  const [compAddJsons, setCompAddJsons] = useState([] as any[])

  const [loading, setLoading] = useState(true)
  


  /**
   * General functions
   */

  const getUpgradeScratchCost = (traitList: any[]) => {
    let traitCount = 0
    for(let i in traitList){
      if(traitList[i].value !== 'None'){
        traitCount++
      }
    }
    if(traitCount === 0){
      return 0
    }
    if(traitCount === 1){
      return 7
    }
    if(traitCount === 2){
      return 15
    }
    if(traitCount === 3){
      return 22
    }
  }

  const swapTraits = async() => {

    //error in case of not enough scratch
    let upgradeScratchCost: number = getUpgradeScratchCost(upgradeSelection.attributes.slice(2))
    if(upgradeScratchCost > Number(scratchBalance)){
      toast.error('Not enough $SCRATCH!', {autoClose: 5000});
      return
    }

    // error in case of swapping same type of trait
    for(let i in upgradeSelection.attributes.slice(2)){
      if(upgradeTraitTypeSelection === upgradeSelection.attributes.slice(2)[i].trait_type && upgradeSelection.attributes.slice(2)[i].value !== 'None'){
        toast.error('Component already has trait of that type.', {autoClose: 5000});
        return
      }
    }

    let contractInstance = await getCompContract()
    console.log(upgradeSelection2.attributes[0].value)
    let traitToSwap = await getTraitCode(upgradeSelection2.attributes[0].value, upgradeSelection2.id, upgradeTraitSelection, upgradeTraitTypeSelection, contractInstance)
    await toast.promise(
      contractInstance.swapTrait(upgradeSelection.id, upgradeSelection2.id, traitToSwap, {from: account}),
      {
        pending: 'Upgrading Component...',
        success: {
          render(){
            return <div>Success! Now refreshing browser.</div>
          },
          // other options
          icon: "🟢",
        },
        error: 'Failed to Upgrade.'
      }
  )
  .then(() => setTimeout((() => window.location.reload()), 5500))
  .catch((e) => {console.log('something went wrong :(', e); return})
  }

  const getTraitCode = async(compType: string, tokenID: string, trait: string, traitType: string, contractInstance) => {
    console.log(compType, tokenID, trait, traitType)
    let metadata = await contractInstance.getComponentMetadata(tokenID)
    let traitArray = []
    let codeOfTraitToSwap = ''
    for(let i in metadata[0]){
      traitArray.push(metadata[0][i].toString())
    }
    console.log(traitArray)
    for(let i in traitArray){
      if(componentTraitCodex[compType][traitType][traitArray[i]] === trait){
        codeOfTraitToSwap = traitArray[i]
      }
    }
    return codeOfTraitToSwap
  }

  const getCompContract = async() => {
    let contract: any
    if(upgradeSelection2.attributes[0].value === 'Window'){contract = windowComponent}
    if(upgradeSelection2.attributes[0].value === 'Door'){contract = door}
    if(upgradeSelection2.attributes[0].value === 'Bed'){contract = bed}
    if(upgradeSelection2.attributes[0].value === 'Building'){contract = building}
    if(upgradeSelection2.attributes[0].value === 'Table'){contract = table}
    if(upgradeSelection2.attributes[0].value === 'Desk'){contract = desk}
    if(upgradeSelection2.attributes[0].value === 'Entertainment'){contract = entertainment}
    if(upgradeSelection2.attributes[0].value === 'Couch'){contract = couch}
    if(upgradeSelection2.attributes[0].value === 'Pet'){contract = pet}
    if(upgradeSelection2.attributes[0].value === 'Activity'){contract = activity}
    contract.setProvider(contractWriteProviders)
    let instance = await contract.deployed()
    return instance
  }

  const EmptyComponent = () => {
    return(
      <Row justify='center'>
      <div className='br-componentcard neon-border-lblue bigger-on-hover'  onClick={() => {setUpgradeSelection({
        id: '0',
        name: 'Empty Component',
        attributes: [
          {trait_type: 'Free Slot', value: 'Empty Component'},
          {trait_type: 'Free Slot', value: '0'},
          {trait_type: 'Free Slot', value: 'None'},
          {trait_type: 'Free Slot', value: 'None'},
          {trait_type: 'Free Slot', value: 'None'},
          {trait_type: 'Free Slot', value: 'None'}
        ]
      }); setMode('upgrade')}}>
      <div className='br-componentcard-image'/>
      <div className='br-componentcard-bottombox neon-border-lblue flex-and-center flex-column'>
        <div>{'Empty Component'}</div>
      </div>
      </div>
    </Row>
    )
  }

  const ComponentList = (props) => {
    const componentList = props.componentList
    const componentItems = componentList.map((component, index) => <ComponentCard key={index} value={component} />)
    return (
      <Row justify='center' style={{marginTop: '30px'}}>
        {
          componentItems.length > 0 && !loading?
          <>
            {componentItems}
          </>
          :
          <>
            <img src={webStorage + '/boomrooms/nocompfound.png'} style={{marginTop: '20px', maxWidth: '1000px', width: '95%'}}/>
          </>
        }
        
      </Row>
      )
  }

  const ComponentCard = (component) => {
    if(component.value === undefined || component.value === null){
      return(
        <div className='br-componentcard neon-border-red bigger-on-hover'>
         <img className='br-componentcard-image' src={webStorage + '/boomrooms/broken_metadata.png'}/>
         <div className='br-componentcard-bottombox neon-border-red flex-and-center flex-column'>
           <div>{'Metadata Error'}</div>
         </div>
        </div>
      )
    }
    const id = component.value.id
    const name = component.value.name
    const type = component.value.attributes[0].value
    const score = component.value.attributes[1].value
    const image = component.value.image
    const attributes = component.value.attributes

    let traitCount = 4
    for(let i in attributes){
      if(attributes[i].value === 'None'){
        traitCount--
      }
    }
    return(
        // <div className='br-componentcard neon-border-lblue bigger-on-hover' onClick={() => ''}>
        <div className='br-componentcard neon-border-lblue bigger-on-hover' onClick={() => {setMode('upgrade'); setUpgradeSelection(component.value)}}>
        <img className='br-componentcard-image' src={image}/>
        <div className='br-componentcard-bottombox neon-border-lblue flex-and-center flex-column'>
          <div>{type}</div>
          <div>{'Component #' + id}</div>
          <div>{'Trait Count: ' + traitCount}</div>
          <div>{'Score: ' + score}</div>
        </div>
        </div>
    )
  }

  const ComponentUpgradeList = (props) => {
    const componentUpgradeList = props.componentUpgradeList
    const componentType = props.componentType
    const componentUpgradeItems = componentUpgradeList.map((component, index) => <ComponentUpgradeCard key={index} value={component} selectedType={componentType}/>)
    return (<Row justify='center'>{componentUpgradeItems}</Row>)
  }

  const ComponentUpgradeCard = (component) => {
    if(component.value === undefined || component.value === null){
      return(
        <div className='br-componentcard neon-border-red bigger-on-hover'>
         <img className='br-componentcard-image' src={webStorage + '/boomrooms/broken_metadata.png'}/>
         <div className='br-componentcard-bottombox neon-border-red flex-and-center flex-column'>
           <div>{'Metadata Error'}</div>
         </div>
        </div>
      )
    }
    const id = component.value.id
    const name = component.value.name
    const type = component.value.attributes[0].value
    const score = component.value.attributes[1].value
    const image = component.value.image
    const attributes = component.value.attributes
    const selectedType = component.selectedType

    let traitCount = 4
    for(let i in attributes){
      if(attributes[i].value === 'None'){
        traitCount--
      }
    }

    return(
      <>
        {
              // only see components of same type - AND hide the selected component to upgrade
            upgradeSelection.id==='0' || (selectedType===type && id !== upgradeSelection.id)?
           <div className='br-componentcard neon-border-lblue bigger-on-hover' onClick={() => {setUpgradeSelection2(component.value); setUpgradeTraitSelection(''); setUpgradeTraitTypeSelection('')}}>
            <img className='br-componentcard-image' src={image}/>
            <div className='br-blackline'/>
            <div className='br-componentcard-bottombox'>
              <div className='flex-and-center flex-column'>
                <div>{'Component #' + id}</div>
                <div className='br-blackline-half'/>
                <div>{'Trait Count: ' + traitCount}</div>
                <div className='br-blackline-half'/>
                <div>{'Score: ' + score}</div>
              </div>
            </div>
           </div>
           :
           <>
           </>

        }
      </>
    )
  }

  const UpgradeTraitList = (props) => {
    const upgradeTraitList = props.upgradeTraitList
    const selectable = props.selectable
    const upgradeTraitItems = upgradeTraitList.map((trait, index) => <UpgradeTrait key={index} value={trait} selectable={selectable}/>)
    return (<Row justify='center'>{upgradeTraitItems}</Row>)
  }

  const UpgradeTrait = (trait) => {
    const type = trait.value.trait_type
    const value = trait.value.value
    const selectable = trait.selectable
    let score = '0'
    console.log('type', type)
    console.log('value', value)
    if(type !== 'Free Slot'){
      score = compTraitScoreCodex[activeSort][type][value]
    }
    return(
      checkNotMobile(screenWidth)?
      selectable && value !== 'None'?
        // right side component traits
        <div className={`neon-border-lblue bigger-on-hover br-traitlist ${upgradeTraitSelection===value?'neon-border-green': ''}`} onClick={() => {setUpgradeTraitSelection(value); setUpgradeTraitTypeSelection(type)}}>
          <div className='br-upgradecomp-trait-type'>{type}</div>
          <div className='br-upgradecomp-trait-value'>{value}</div>
          <div className='br-upgradecomp-trait-score'>{score?score:'0'}</div>
        </div>

        //left side component traits
        :
        <div className='neon-border-lblue br-traitlist'>
          <div className='br-upgradecomp-trait-type'>{type}</div>
          <div className='br-upgradecomp-trait-value'>{value}</div>
          <div className='br-upgradecomp-trait-score'>{score?score:'0'}</div>
        </div>
      :
      selectable && value !== 'None'?
      // right side component traits
      <div className={`neon-border-lblue bigger-on-hover mobile-br-traitlist ${upgradeTraitSelection===value?'neon-border-green': ''}`} onClick={() => {setUpgradeTraitSelection(value); setUpgradeTraitTypeSelection(type)}}>
        <div className='br-upgradecomp-trait-type'>{type}</div>
        <div className='br-upgradecomp-trait-value'>{value}</div>
        <div className='br-upgradecomp-trait-score'>{score?score:'0'}</div>
      </div>

      //left side component traits
      :
      <div className='neon-border-lblue mobile-br-traitlist'>
        <div className='br-upgradecomp-trait-type'>{type}</div>
        <div className='br-upgradecomp-trait-value'>{value}</div>
        <div className='br-upgradecomp-trait-score'>{score?score:'0'}</div>
      </div>
    )
  }

  // const pickListBasedOnSort = (compType: string) => {
  //   switch(compType){
  //     case 'Window':
  //       return windowJsons
  //     case 'Door':
  //       return doorJsons
  //     case 'Bed':
  //       return bedJsons
  //     case 'Building':
  //       return buildingJsons
  //     case 'Table':
  //       return tableJsons
  //     case 'Desk':
  //       return deskJsons
  //     case 'Entertainment':
  //       return entertainmentJsons
  //     case 'Couch':
  //       return couchJsons
  //     case 'Pet':
  //       return petJsons
  //     case 'Activity':
  //       return activityJsons
  //   }
  // }

  // const getContractTokens = async(contract: any, baseURI: string) => { 
  //   let instance = await contract.deployed()
  //   const balance = await instance.balanceOf(account)
  //   const tokenIDPromises: any[] = []
  //   for (let i = 0; i < balance; i++){
  //     tokenIDPromises.push(instance.tokenOfOwnerByIndex(account, i))
  //   }
  //   const tokenIDs = await Promise.all(tokenIDPromises)
  //   const responseList: any[] = []
  //   for(let i = 0; i < tokenIDs.length; i++){
  //     responseList.push((fetch(baseURI+tokenIDs[i].toString()).catch(()=> console.log('failed to load'))))
  //   }
  //   const fullList = await Promise.all(responseList)
  //   let fullJSONPromises: any[] = []
  //   for (let i in fullList){
  //     fullJSONPromises.push(((fullList[i].json()).catch(()=> console.log('failed to load'))))
  //   }
  //   const fullJSONList = await Promise.all(fullJSONPromises)
  //   return fullJSONList
  // }
  // load windows
  let contractBasedOnComponentSort = {
    'Window': contractAddresses.window,
    'Door': contractAddresses.door,
    'Bed': contractAddresses.bed,
    'Building': contractAddresses.building,
    'Table': contractAddresses.table,
    'Desk': contractAddresses.desk,
    'Entertainment': contractAddresses.entertainment,
    'Couch': contractAddresses.couch,
    'Pet': contractAddresses.pet,
    'Activity': contractAddresses.activity,
  }
    // load windows
    useEffect(() => {
      let isApiSubscribed = true
      const loadBasedOnSort = async(activeSort) => {
        setLoading(true)
        let contractAddress = contractBasedOnComponentSort[activeSort]
        fetchMetadata(account, contractAddress).then((allJsons) => {
          setCompAddJsons(allJsons)
          setLoading(false)
          console.log('Pulling metadata....')
        })
      }
      if(isApiSubscribed){
        loadBasedOnSort(activeSort)
      }
      return () => {
        // cancel the subscription
        isApiSubscribed = false;
      }
  },[activeSort, connected])

    return (

        // check if user on mobile
        checkNotMobile(screenWidth)?
        <>
          <ToastContainer position="top-center"/>
          <YoutubeVideo videoURL="https://www.youtube.com/embed/iGg40g_nh_o" />
          <div className='neon-text neon-lblue br-page-title'>Component Builder</div>
          <div className='neon-line neon-lblue'></div>
          {
            mode==='select'?
            <>
              <div className='br-subtitle'>
                Select Component Type
              </div>
              <Row justify='center' className='br-componentbuilder-sortrow'>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-compbuilder-select-buttons ${activeSort==='Window'?'active': ''}`} onClick={() => setActiveSort('Window')}>Window</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-compbuilder-select-buttons ${activeSort==='Door'?'active': ''}`} onClick={() => setActiveSort('Door')}>Door</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-compbuilder-select-buttons ${activeSort==='Bed'?'active': ''}`} onClick={() => setActiveSort('Bed')}>Bed</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-compbuilder-select-buttons ${activeSort==='Building'?'active': ''}`} onClick={() => setActiveSort('Building')}>Building</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-compbuilder-select-buttons ${activeSort==='Table'?'active': ''}`} onClick={() => setActiveSort('Table')}>Table</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-compbuilder-select-buttons ${activeSort==='Desk'?'active': ''}`} onClick={() => setActiveSort('Desk')}>Desk</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-compbuilder-select-buttons ${activeSort==='Entertainment'?'active': ''}`} onClick={() => setActiveSort('Entertainment')}>Entertainment</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-compbuilder-select-buttons ${activeSort==='Couch'?'active': ''}`} onClick={() => setActiveSort('Couch')}>Couch</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-compbuilder-select-buttons ${activeSort==='Pet'?'active': ''}`} onClick={() => setActiveSort('Pet')}>Pet</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover br-compbuilder-select-buttons ${activeSort==='Activity'?'active': ''}`} onClick={() => setActiveSort('Activity')}>Activity</div>
              </Row>
              <EmptyComponent/>
              {
                loading?
                <div className='mobile-br-construction-spinner-box flex-and-center'>
                  <Spinner loading={true} size={100}/>
                </div>
                :
                <>
                  <ComponentList componentList={compAddJsons} />
                </>
              }
            </>

            :
            <>
            </>
          }
          {
            mode==='upgrade'?
            <>

              {/**Upgreade game START */}
              <div className='br-upgradecomponenet-square'>

                {
                    // left items
                  <div className='br-upgradecomponenet-square-side'>
                    <div className='br-upgradecomponenet-square-name flex-and-center'>{upgradeSelection.attributes[0].value+' #'+upgradeSelection.id}</div>
                    <div className='br-upgradecomponenet-square-image neon-border-lblue'><img className='br-componentupgrade-image' src={upgradeSelection.image}/></div>
                    <div className='br-upgradecomponenet-square-info'>{'Total Score: ' + upgradeSelection.attributes[1].value}</div>
                    <div className='br-upgradecomponenet-square-traits'><UpgradeTraitList upgradeTraitList={upgradeSelection.attributes.slice(2)} selectable={false}/></div>
                  </div>
                }
                <div className={`br-upgradecomponenet-square-arrow neon-text ${upgradeTraitSelection!==''?'neon-green':'neon-dgreen'}`}>
                    {'<<<'}
                </div>
                {
                  // right items
                    // a second component is selected
                  upgradeSelection2.id !== undefined?
                  <div className='br-upgradecomponenet-square-side'>
                    <div className='br-upgradecomponenet-square-name flex-and-center'>{upgradeSelection2.attributes[0].value+' #'+upgradeSelection2.id}</div>
                    <div className='br-upgradecomponenet-square-image neon-border-lblue'><img className='br-componentupgrade-image' src={upgradeSelection2.image}/></div>
                    <div className='br-upgradecomponenet-square-info neon-text neon-green'>{'SELECT TRAIT HERE'}</div>
                    <div className='br-upgradecomponenet-square-traits'><UpgradeTraitList upgradeTraitList={upgradeSelection2.attributes.slice(2)} selectable={true}/></div>
                  </div>
                    // second component NOT selected
                  :
                  <div className='br-upgradecomponenet-square-side'>
                    <div className='br-upgradecomponenet-square-name flex-and-center'></div>
                    <div className='br-upgradecomponenet-square-image neon-border-lblue'><img className='br-componentupgrade-image'/></div>
                    <div className='br-upgradecomponenet-square-info'></div>
                    <div className='br-upgradecomponenet-square-traits'></div>
                  </div>
                }
                </div>
                  {/**Scratch Cost */}
                  <Row className='br-subsubtitle neon-text neon-green' style={{marginTop: '20px'}}>Cost:&nbsp;{getUpgradeScratchCost(upgradeSelection.attributes.slice(2))}&nbsp;{'$SCRATCH'}&nbsp;</Row>
                  {/**Upgrade Button*/}
                  <div className={`neon-button-simple width-250 ${upgradeTraitSelection!==''?'neon-green neon-hover-green bigger-on-hover':'neon-dgreen'}`} onClick={swapTraits}>upgrade</div>
                  {/**Cancel Button*/}
                  <div className='neon-button-simple width-250 neon-red neon-hover-red bigger-on-hover' onClick={() => {setMode('select'); setUpgradeTraitSelection(''); setUpgradeTraitTypeSelection(''); setUpgradeSelection({} as Component); setUpgradeSelection2({} as Component)}}>cancel</div>

              {/**Upgreade game END */}

              <div className='br-subtitle'>
                Select Component #2
              </div>
              <ComponentUpgradeList componentUpgradeList={compAddJsons} componentType={upgradeSelection.attributes[0].value} />
            </>
            :
            <></>
          }
        </>
        // mobile
        :
        <>
          <ToastContainer position="top-center"/>
          <YoutubeVideo videoURL="https://www.youtube.com/embed/iGg40g_nh_o" />
          <div className='neon-text neon-lblue mobile-br-page-title'>Component Builder</div>
          <div className='neon-line neon-lblue'></div>
          {
            mode==='select'?
            <>
              <div className='br-subtitle'>
                Select Component Type
              </div>
              <Row justify='center' className='br-componentbuilder-sortrow'>
                {/* <div className={`neon-button-simple neon-lblue bigger-on-hover br-compbuilder-select-buttons ${activeSort==='All'?'active': ''}`} onClick={() => setActiveSort('All')}>All</div> */}
                <div className={`neon-button-simple neon-lblue bigger-on-hover mobile-br-compbuilder-select-buttons ${activeSort==='Window'?'active': ''}`} onClick={() => setActiveSort('Window')}>Window</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover mobile-br-compbuilder-select-buttons ${activeSort==='Door'?'active': ''}`} onClick={() => setActiveSort('Door')}>Door</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover mobile-br-compbuilder-select-buttons ${activeSort==='Bed'?'active': ''}`} onClick={() => setActiveSort('Bed')}>Bed</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover mobile-br-compbuilder-select-buttons ${activeSort==='Building'?'active': ''}`} onClick={() => setActiveSort('Building')}>Building</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover mobile-br-compbuilder-select-buttons ${activeSort==='Table'?'active': ''}`} onClick={() => setActiveSort('Table')}>Table</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover mobile-br-compbuilder-select-buttons ${activeSort==='Desk'?'active': ''}`} onClick={() => setActiveSort('Desk')}>Desk</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover mobile-br-compbuilder-select-buttons ${activeSort==='Entertainment'?'active': ''}`} onClick={() => setActiveSort('Entertainment')}>Entertainment</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover mobile-br-compbuilder-select-buttons ${activeSort==='Couch'?'active': ''}`} onClick={() => setActiveSort('Couch')}>Couch</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover mobile-br-compbuilder-select-buttons ${activeSort==='Pet'?'active': ''}`} onClick={() => setActiveSort('Pet')}>Pet</div>
                <div className={`neon-button-simple neon-lblue bigger-on-hover mobile-br-compbuilder-select-buttons ${activeSort==='Activity'?'active': ''}`} onClick={() => setActiveSort('Activity')}>Activity</div>
              </Row>
              <Row justify='center'>
              </Row>
              <EmptyComponent/>
              {
                loading?
                <div className='mobile-br-construction-spinner-box flex-and-center'>
                  <Spinner loading={true} size={100}/>
                </div>
                :
                <>
                  <ComponentList componentList={compAddJsons} />
                </>
              }       
            </>

            :
            <>
            </>
          }
                    {
            mode==='upgrade'?
            <>

              {/**Upgreade game START */}
              <div className='mobile-br-upgradecomponenet-square'>

                {
                    // left items
                  <div className='mobile-br-upgradecomponenet-square-side'>
                    <div className='mobile-br-upgradecomponenet-square-name flex-and-center'>{upgradeSelection.attributes[0].value+' #'+upgradeSelection.id}</div>
                    <div className='mobile-br-upgradecomponenet-square-image neon-border-lblue'><img className='mobile-br-componentupgrade-image' src={upgradeSelection.image}/></div>
                    <div className='br-upgradecomponenet-square-info'>{'Total Score: ' + upgradeSelection.attributes[1].value}</div>
                    <div className='br-upgradecomponenet-square-traits'><UpgradeTraitList upgradeTraitList={upgradeSelection.attributes.slice(2)} selectable={false}/></div>
                  </div>
                }
                <div className={`mobile-br-upgradecomponenet-square-arrow neon-text ${upgradeTraitSelection!==''?'neon-green':'neon-dgreen'}`} style={{marginTop: '-10px'}}>
                    {'^'}
                </div>
                <div className={`mobile-br-upgradecomponenet-square-arrow neon-text ${upgradeTraitSelection!==''?'neon-green':'neon-dgreen'}`} style={{marginBottom: '-100px', marginTop: '-100px'}}>
                    {'^'}
                </div>
                <div className={`mobile-br-upgradecomponenet-square-arrow neon-text ${upgradeTraitSelection!==''?'neon-green':'neon-dgreen'}`} style={{marginBottom: '-80px'}}>
                    {'^'}
                </div>
                {
                  // right items
                    // a second component is selected
                  upgradeSelection2.id !== undefined?
                  <div className='mobile-br-upgradecomponenet-square-side'>
                    <div className='mobile-br-upgradecomponenet-square-name flex-and-center'>{upgradeSelection2.attributes[0].value+' #'+upgradeSelection2.id}</div>
                    <div className='mobile-br-upgradecomponenet-square-image neon-border-lblue'><img className='mobile-br-componentupgrade-image' src={upgradeSelection2.image}/></div>
                    <div className='br-upgradecomponenet-square-info neon-text neon-green'>{'SELECT TRAIT HERE'}</div>
                    <div className='br-upgradecomponenet-square-traits'><UpgradeTraitList upgradeTraitList={upgradeSelection2.attributes.slice(2)} selectable={true}/></div>
                  </div>
                    // second component NOT selected
                  :
                  <div className='mobile-br-upgradecomponenet-square-side'>
                    <div className='mobile-br-upgradecomponenet-square-name flex-and-center'></div>
                    <div className='mobile-br-upgradecomponenet-square-image neon-border-lblue'><img className='mobile-br-componentupgrade-image'/></div>
                    <div className='br-upgradecomponenet-square-info'></div>
                    <div className='br-upgradecomponenet-square-traits'></div>
                  </div>
                }
                </div>
                  {/**Scratch Cost */}
                  <Row className='br-subsubtitle neon-text neon-green' style={{marginTop: '20px'}}>Cost:&nbsp;{getUpgradeScratchCost(upgradeSelection.attributes.slice(2))}&nbsp;{'$SCRATCH'}&nbsp;</Row>
                  {/**Upgrade Button*/}
                  <div className={`neon-button-simple width-250 ${upgradeTraitSelection!==''?'neon-green neon-hover-green bigger-on-hover':'neon-dgreen'}`} onClick={swapTraits}>upgrade</div>
                  {/**Cancel Button*/}
                  <div className='neon-button-simple width-250 neon-red neon-hover-red bigger-on-hover' onClick={() => {setMode('select'); setUpgradeTraitSelection(''); setUpgradeTraitTypeSelection(''); setUpgradeSelection({} as Component); setUpgradeSelection2({} as Component)}}>cancel</div>

              {/**Upgreade game END */}

              <div className='mobile-br-subtitle'>
                Select Component #2
              </div>
              <ComponentUpgradeList componentUpgradeList={compAddJsons} componentType={upgradeSelection.attributes[0].value} />
            </>
            :
            <></>
          }
        </>

    )
}

export default BoomRoomComponentBuilder