export const compTraitScoreCodex = {

    // componentType 100
    'Window':{

        // TRAIT TYPES
        '1':'Scenery',
        '2':'Weather',
        '3':'Frame',
        '4':'Accessory',

        'Scenery':{
        // TRAIT_TYPE_1 traits
        "Farm":"1",
        "Desert":"1",
        "Forest":"1",
        "Mountain":"2",
        "City":"2",
        "Beach":"2",
        "Clouds":"3",
        "Blocky":"3",
        },
        'Weather':{
        // TRAIT_TYPE_2 traits
        "Rain":"1",
        "Clear":"1",
        "Sunrise":"1",
        "Foggy":"2",
        "Moon":"2",
        "Snow":"2",
        "Stars":"3",
        "Coins":"3",
        },
        'Frame':{
        // TRAIT_TYPE_3 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Accessory':{
        // TRAIT_TYPE_4 traits
        "Bird":"1",
        "Baseball":"1",
        "Eggs":"1",
        "Flowers":"2",
        "Arrow":"2",
        "Wrecking Ball":"2",
        "Stain Glass":"3",
        "Boomer":"3",
        },
    },
    // componentType 101
    'Door':{

        // TRAIT TYPES
        '1':'Room',
        '2':'Door',
        '3':'Knob',
        '4':'Accessory',
        'Room':{
        // TRAIT_TYPE_1 traits
        "Bathroom":"1",
        "Pool":"1",
        "Steam":"1",
        "Disco":"2",
        "Theater":"2",
        "Garden":"2",
        "Matrix":"3",
        "Portal":"3",
        },
        'Door':{
        // TRAIT_TYPE_2 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Knob':{
        // TRAIT_TYPE_3 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Accessory':{
        // TRAIT_TYPE_4 traits
        "Boomer Poster":"1",
        "Zoomer Poster":"1",
        "Radio Poster":"1",
        "Rain Coat":"2",
        "Basketball":"2",
        "Bike":"2",
        "Jet Pack":"3",
        "Rainbow":"3",
        },
    },
    // componentType 102
    'Bed':{

        // TRAIT TYPES
        '1':'Frame',
        '2':'Pillow',
        '3':'Sheets',
        '4':'Accessory',
        'Frame':{
        // TRAIT_TYPE_1 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Pillow':{
        // TRAIT_TYPE_2 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Sheets':{
        // TRAIT_TYPE_3 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Accessory':{
        // TRAIT_TYPE_4 traits
        "Boomer Kit":"1",
        "Laundry":"1",
        "Teddy":"1",
        "Sombrero":"2",
        "Clown":"2",
        "Bee Keeper":"2",
        "Matrix Kit":"3",
        "Boomer":"3",
        },
    },
    // componentType 103
    'Building':{

        // TRAIT TYPES
        '1':'Brick',
        '2':'Walls',
        '3':'Floor',
        '4':'On Brick',
        'Brick':{
        // TRAIT_TYPE_1 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Walls':{
        // TRAIT_TYPE_2 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Floor':{
        // TRAIT_TYPE_3 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'On Brick':{
        // TRAIT_TYPE_4 traits
        "Marbles":"1",
        "Bonsai":"1",
        "Cleaning":"1",
        "Giant":"2",
        "Balloons":"2",
        "Tape":"2",
        "Goblins":"3",
        "Sledgehammer":"3",
        },
    },
    // componentType 104
    'Table':{

        // TRAIT TYPES
        '1':'Chairs',
        '2':'Frame',
        '3':'Cloth',
        '4':'Accessory',
        'Chairs':{
        // TRAIT_TYPE_1 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Frame':{
        // TRAIT_TYPE_2 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Cloth':{
        // TRAIT_TYPE_3 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Accessory':{
        // TRAIT_TYPE_4 traits
        "Pizza Party":"1",
        "Dominos":"1",
        "Chess":"1",
        "DND":"2",
        "Psychic":"2",
        "Dinner":"2",
        "Pot o Gold":"3",
        "Sack of Crypto":"3",
        },  
    },
    // componentType 105
    'Desk':{

        // TRAIT TYPES
        '1':'Frame',
        '2':'Chair',
        '3':'On Desk Left',
        '4':'On Desk Right',
        'Frame':{
        // TRAIT_TYPE_1 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Chair':{
        // TRAIT_TYPE_2 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'On Desk Left':{
        // TRAIT_TYPE_3 traits
        "Candy":"1",
        "Newton":"1",
        "Snow Globe":"1",
        "Globe":"2",
        "Bonsai tree":"2",
        "Vid":"2",
        "Bar":"3",
        "Moon":"3",
        },
        'On Desk Right':{
        // TRAIT_TYPE_4 traits
        "Books":"1",
        "Lamp":"1",
        "Typewriter":"1",
        "Rotary Phone":"2",
        "Old PC":"2",
        "Artist":"2",
        "Laptop":"3",
        "Ham Radio":"3",
        },
    },
    // componentType 106
    'Entertainment':{

        // TRAIT TYPES
        '1':'Frame',
        '2':'Device',
        '3':'Small Device',
        '4':'Picture Frame',
        'Frame':{
        // TRAIT_TYPE_1 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Device':{
        // TRAIT_TYPE_2 traits
        "Record Player":"1",
        "Stereo Radio":"1",
        "Jukebox":"1",
        "Ice Cream":"2",
        "Aquarium":"2",
        "Rabbit Ears":"2",
        "Crane Game":"3",
        "Flat Screen TV":"3",
        },
        'Small Device':{
        // TRAIT_TYPE_3 traits
        "Alarm Clock":"1",
        "Fish":"1",
        "Old Telephone":"1",
        "Clock":"2",
        "Lava Lamp":"2",
        "Gumball":"2",
        "Video Game":"3",
        "Portal":"3",
        },
        'Picture Frame':{
        // TRAIT_TYPE_4 traits
        "Boomer Squad":"1",
        "Zoomer Squad":"1",
        "Boomer Radio":"1",
        "Dancing Crab":"2",
        "Snow Man":"2",
        "Colorful":"2",
        "Lion":"3",
        "OG":"3",
        }, 
    },
    // componentType 107
    'Couch':{

        // TRAIT TYPES
        '1':'Frame',
        '2':'Cushions',
        '3':'Small Pillows',
        '4':'Accessory',
        'Frame':{
        // TRAIT_TYPE_1 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Cushions':{
        // TRAIT_TYPE_2 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Small Pillows':{
        // TRAIT_TYPE_3 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Accessory':{
        // TRAIT_TYPE_4 traits
        "Backpack":"1",
        "Blanket":"1",
        "Change":"1",
        "Popcorn":"2",
        "Comics":"2",
        "Unicorn":"2",
        "Snowman":"3",
        "Boomer":"3",
        },
    },
    // componentType 108
    'Pet':{

        // TRAIT TYPES
        '1':'Pet',
        '2':'Bowl',
        '3':'Bed',
        '4':'Toy',
        'Pet':{
        // TRAIT_TYPE_1 traits
        "Dog":"1",
        "Cat":"1",
        "Pig":"1",
        "Bear":"2",
        "Ice Cat":"2",
        "Hellfire Dog":"2",
        "Droid":"3",
        "Alien Pet":"3",
        },
        'Bowl':{
        // TRAIT_TYPE_2 traits
        "Cave ":"1",
        "Forest":"1",
        "Ice":"1",
        "Aqua":"2",
        "Gothic":"2",
        "Alien":"2",
        "Trippy":"3",
        "Gold":"3",
        },
        'Bed':{
        // TRAIT_TYPE_3 traits
        "Cage":"1",
        "Bed":"1",
        "Bed of Leaves":"1",
        "Fancy":"2",
        "Egg":"2",
        "King":"2",
        "Car":"3",
        "Cloud":"3",
        },
        'Toy':{
        // TRAIT_TYPE_4 traits
        "Ball":"1",
        "Bone":"1",
        "Tug Rope":"1",
        "Robot":"2",
        "Mouse":"2",
        "News Paper":"2",
        "Boomer Chew Toy":"3",
        "Zoomer Chew Toy":"3",
        },
    },
    // componentType 109
    'Activity':{

        // TRAIT TYPES
        '1':'Main',
        '2':'Bag',
        '3':'Transportation',
        '4':'Secondary',
        'Main':{
        // TRAIT_TYPE_1 traits
        "Stationary Bike":"1",
        "Foosball Table":"1",
        "Poker Table":"1",
        "Punching Bag":"2",
        "Trampolene":"2",
        "Mechanical Bull":"2",
        "DJ Table":"3",
        "Coin Operated Unicorn Ride":"3",
        },
        'Bag':{
        // TRAIT_TYPE_2 traits
        "Duffle Bag":"1",
        "Backpack":"1",
        "Briefcase":"1",
        "Suitcase":"2",
        "Bindle":"2",
        "Fanny Pack":"2",
        "Bag of CRO":"3",
        "Pot of Gold":"3",
        },
        'Transportation':{
        // TRAIT_TYPE_3 traits
        "Walker":"1",
        "Roller Blades":"1",
        "Scooter":"1",
        "Skateboard":"2",
        "Pogo Stick":"2",
        "NOS":"2",
        "Jet Pack":"3",
        "Portal":"3",
        },
        'Secondary':{
        // TRAIT_TYPE_4 traits
        "Board Game":"1",
        "Books":"1",
        "Payphone":"1",
        "Jenga":"2",
        "Barbie House":"2",
        "Pile of Money":"2",
        "Bong":"3",
        "Crane Game":"3",
        },
    }
}