export const componentTraitCodex = {

    // componentType 100
    'Window':{

        // TRAIT TYPES
        '1':'Scenery',
        '2':'Weather',
        '3':'Frame',
        '4':'Accessory',

        'Scenery':{
        // TRAIT_TYPE_1 traits
        "101":"Farm",
        "102":"Desert",
        "103":"Forest",
        "104":"Mountain",
        "105":"City",
        "106":"Beach",
        "107":"Clouds",
        "108":"Blocky",
        },
        'Weather':{
        // TRAIT_TYPE_2 traits
        "201":"Rain",
        "202":"Clear",
        "203":"Sunrise",
        "204":"Foggy",
        "205":"Moon",
        "206":"Snow",
        "207":"Stars",
        "208":"Coins",
        },
        'Frame':{
        // TRAIT_TYPE_3 traits
        "301":"Cave ",
        "302":"Forest",
        "303":"Ice",
        "304":"Aqua",
        "305":"Gothic",
        "306":"Alien",
        "307":"Trippy",
        "308":"Gold",
        },
        'Accessory':{
        // TRAIT_TYPE_4 traits
        "401":"Bird",
        "402":"Baseball",
        "403":"Eggs",
        "404":"Flowers",
        "405":"Arrow",
        "406":"Wrecking Ball",
        "407":"Stain Glass",
        "408":"Boomer",
        },
    },
    // componentType 101
    'Door':{

        // TRAIT TYPES
        '1':'Room',
        '2':'Door',
        '3':'Knob',
        '4':'Accessory',
        'Room':{
        // TRAIT_TYPE_1 traits
        "101":"Bathroom",
        "102":"Pool",
        "103":"Steam",
        "104":"Disco",
        "105":"Theater",
        "106":"Garden",
        "107":"Matrix",
        "108":"Portal",
        },
        'Door':{
        // TRAIT_TYPE_2 traits
        "201":"Cave ",
        "202":"Forest",
        "203":"Ice",
        "204":"Aqua",
        "205":"Gothic",
        "206":"Alien",
        "207":"Trippy",
        "208":"Gold",
        },
        'Knob':{
        // TRAIT_TYPE_3 traits
        "301":"Cave ",
        "302":"Forest",
        "303":"Ice",
        "304":"Aqua",
        "305":"Gothic",
        "306":"Alien",
        "307":"Trippy",
        "308":"Gold",
        },
        'Accessory':{
        // TRAIT_TYPE_4 traits
        "401":"Boomer Poster",
        "402":"Zoomer Poster",
        "403":"Radio Poster",
        "404":"Rain Coat",
        "405":"Basketball",
        "406":"Bike",
        "407":"Jet Pack",
        "408":"Rainbow",
        },
    },
    // componentType 102
    'Bed':{

        // TRAIT TYPES
        '1':'Frame',
        '2':'Pillow',
        '3':'Sheets',
        '4':'Accessory',
        'Frame':{
        // TRAIT_TYPE_1 traits
        "101":"Cave ",
        "102":"Forest",
        "103":"Ice",
        "104":"Aqua",
        "105":"Gothic",
        "106":"Alien",
        "107":"Trippy",
        "108":"Gold",
        },
        'Pillow':{
        // TRAIT_TYPE_2 traits
        "201":"Cave ",
        "202":"Forest",
        "203":"Ice",
        "204":"Aqua",
        "205":"Gothic",
        "206":"Alien",
        "207":"Trippy",
        "208":"Gold",
        },
        'Sheets':{
        // TRAIT_TYPE_3 traits
        "301":"Cave ",
        "302":"Forest",
        "303":"Ice",
        "304":"Aqua",
        "305":"Gothic",
        "306":"Alien",
        "307":"Trippy",
        "308":"Gold",
        },
        'Accessory':{
        // TRAIT_TYPE_4 traits
        "401":"Boomer Kit",
        "402":"Laundry",
        "403":"Teddy",
        "404":"Sombrero",
        "405":"Clown",
        "406":"Bee Keeper",
        "407":"Matrix Kit",
        "408":"Boomer",
        },
    },
    // componentType 103
    'Building':{

        // TRAIT TYPES
        '1':'Brick',
        '2':'Walls',
        '3':'Floor',
        '4':'On Brick',
        'Brick':{
        // TRAIT_TYPE_1 traits
        "101":"Cave ",
        "102":"Forest",
        "103":"Ice",
        "104":"Aqua",
        "105":"Gothic",
        "106":"Alien",
        "107":"Trippy",
        "108":"Gold",
        },
        'Walls':{
        // TRAIT_TYPE_2 traits
        "201":"Cave ",
        "202":"Forest",
        "203":"Ice",
        "204":"Aqua",
        "205":"Gothic",
        "206":"Alien",
        "207":"Trippy",
        "208":"Gold",
        },
        'Floor':{
        // TRAIT_TYPE_3 traits
        "301":"Cave ",
        "302":"Forest",
        "303":"Ice",
        "304":"Aqua",
        "305":"Gothic",
        "306":"Alien",
        "307":"Trippy",
        "308":"Gold",
        },
        'On Brick':{
        // TRAIT_TYPE_4 traits
        "401":"Marbles",
        "402":"Bonsai",
        "403":"Cleaning",
        "404":"Giant",
        "405":"Balloons",
        "406":"Tape",
        "407":"Goblins",
        "408":"Sledgehammer",
        },
    },
    // componentType 104
    'Table':{

        // TRAIT TYPES
        '1':'Chairs',
        '2':'Frame',
        '3':'Cloth',
        '4':'Accessory',
        'Chairs':{
        // TRAIT_TYPE_1 traits
        "101":"Cave ",
        "102":"Forest",
        "103":"Ice",
        "104":"Aqua",
        "105":"Gothic",
        "106":"Alien",
        "107":"Trippy",
        "108":"Gold",
        },
        'Frame':{
        // TRAIT_TYPE_2 traits
        "201":"Cave ",
        "202":"Forest",
        "203":"Ice",
        "204":"Aqua",
        "205":"Gothic",
        "206":"Alien",
        "207":"Trippy",
        "208":"Gold",
        },
        'Cloth':{
        // TRAIT_TYPE_3 traits
        "301":"Cave ",
        "302":"Forest",
        "303":"Ice",
        "304":"Aqua",
        "305":"Gothic",
        "306":"Alien",
        "307":"Trippy",
        "308":"Gold",
        },
        'Accessory':{
        // TRAIT_TYPE_4 traits
        "401":"Pizza Party",
        "402":"Dominos",
        "403":"Chess",
        "404":"DND",
        "405":"Psychic",
        "406":"Dinner",
        "407":"Pot o Gold",
        "408":"Sack of Crypto",
        },  
    },
    // componentType 105
    'Desk':{

        // TRAIT TYPES
        '1':'Frame',
        '2':'Chair',
        '3':'On Desk Left',
        '4':'On Desk Right',
        'Frame':{
        // TRAIT_TYPE_1 traits
        "101":"Cave ",
        "102":"Forest",
        "103":"Ice",
        "104":"Aqua",
        "105":"Gothic",
        "106":"Alien",
        "107":"Trippy",
        "108":"Gold",
        },
        'Chair':{
        // TRAIT_TYPE_2 traits
        "201":"Cave ",
        "202":"Forest",
        "203":"Ice",
        "204":"Aqua",
        "205":"Gothic",
        "206":"Alien",
        "207":"Trippy",
        "208":"Gold",
        },
        'On Desk Left':{
        // TRAIT_TYPE_3 traits
        "301":"Candy",
        "302":"Newton",
        "303":"Snow Globe",
        "304":"Globe",
        "305":"Bonsai tree",
        "306":"Vid",
        "307":"Bar",
        "308":"Moon",
        },
        'On Desk Right':{
        // TRAIT_TYPE_4 traits
        "401":"Books",
        "402":"Lamp",
        "403":"Typewriter",
        "404":"Rotary Phone",
        "405":"Old PC",
        "406":"Artist",
        "407":"Laptop",
        "408":"Ham Radio",
        },
    },
    // componentType 106
    'Entertainment':{

        // TRAIT TYPES
        '1':'Frame',
        '2':'Device',
        '3':'Small Device',
        '4':'Picture Frame',
        'Frame':{
        // TRAIT_TYPE_1 traits
        "101":"Cave ",
        "102":"Forest",
        "103":"Ice",
        "104":"Aqua",
        "105":"Gothic",
        "106":"Alien",
        "107":"Trippy",
        "108":"Gold",
        },
        'Device':{
        // TRAIT_TYPE_2 traits
        "201":"Record Player",
        "202":"Stereo Radio",
        "203":"Jukebox",
        "204":"Ice Cream",
        "205":"Aquarium",
        "206":"Rabbit Ears",
        "207":"Crane Game",
        "208":"Flat Screen TV",
        },
        'Small Device':{
        // TRAIT_TYPE_3 traits
        "301":"Alarm Clock",
        "302":"Fish",
        "303":"Old Telephone",
        "304":"Clock",
        "305":"Lava Lamp",
        "306":"Gumball",
        "307":"Video Game",
        "308":"Portal",
        },
        'Picture Frame':{
        // TRAIT_TYPE_4 traits
        "401":"Boomer Squad",
        "402":"Zoomer Squad",
        "403":"Boomer Radio",
        "404":"Dancing Crab",
        "405":"Snow Man",
        "406":"Colorful",
        "407":"Lion",
        "408":"OG",
        }, 
    },
    // componentType 107
    'Couch':{

        // TRAIT TYPES
        '1':'Frame',
        '2':'Cushions',
        '3':'Small Pillows',
        '4':'Accessory',
        'Frame':{
        // TRAIT_TYPE_1 traits
        "101":"Cave ",
        "102":"Forest",
        "103":"Ice",
        "104":"Aqua",
        "105":"Gothic",
        "106":"Alien",
        "107":"Trippy",
        "108":"Gold",
        },
        'Cushions':{
        // TRAIT_TYPE_2 traits
        "201":"Cave ",
        "202":"Forest",
        "203":"Ice",
        "204":"Aqua",
        "205":"Gothic",
        "206":"Alien",
        "207":"Trippy",
        "208":"Gold",
        },
        'Small Pillows':{
        // TRAIT_TYPE_3 traits
        "301":"Cave ",
        "302":"Forest",
        "303":"Ice",
        "304":"Aqua",
        "305":"Gothic",
        "306":"Alien",
        "307":"Trippy",
        "308":"Gold",
        },
        'Accessory':{
        // TRAIT_TYPE_4 traits
        "401":"Backpack",
        "402":"Blanket",
        "403":"Change",
        "404":"Popcorn",
        "405":"Comics",
        "406":"Unicorn",
        "407":"Snowman",
        "408":"Boomer",
        },
    },
    // componentType 108
    'Pet':{

        // TRAIT TYPES
        '1':'Pet',
        '2':'Bowl',
        '3':'Bed',
        '4':'Toy',
        'Pet':{
        // TRAIT_TYPE_1 traits
        "101":"Dog",
        "102":"Cat",
        "103":"Pig",
        "104":"Bear",
        "105":"Ice Cat",
        "106":"Hellfire Dog",
        "107":"Droid",
        "108":"Alien Pet",
        },
        'Bowl':{
        // TRAIT_TYPE_2 traits
        "201":"Cave ",
        "202":"Forest",
        "203":"Ice",
        "204":"Aqua",
        "205":"Gothic",
        "206":"Alien",
        "207":"Trippy",
        "208":"Gold",
        },
        'Bed':{
        // TRAIT_TYPE_3 traits
        "301":"Cage",
        "302":"Bed",
        "303":"Bed of Leaves",
        "304":"Fancy",
        "305":"Egg",
        "306":"King",
        "307":"Car",
        "308":"Cloud",
        },
        'Toy':{
        // TRAIT_TYPE_4 traits
        "401":"Ball",
        "402":"Bone",
        "403":"Tug Rope",
        "404":"Robot",
        "405":"Mouse",
        "406":"News Paper",
        "407":"Boomer Chew Toy",
        "408":"Zoomer Chew Toy",
        },
    },
    // componentType 109
    'Activity':{

        // TRAIT TYPES
        '1':'Main',
        '2':'Bag',
        '3':'Transportation',
        '4':'Secondary',
        'Main':{
        // TRAIT_TYPE_1 traits
        "101":"Stationary Bike",
        "102":"Foosball Table",
        "103":"Poker Table",
        "104":"Punching Bag",
        "105":"Trampolene",
        "106":"Mechanical Bull",
        "107":"DJ Table",
        "108":"Coin Operated Unicorn Ride",
        },
        'Bag':{
        // TRAIT_TYPE_2 traits
        "201":"Duffle Bag",
        "202":"Backpack",
        "203":"Briefcase",
        "204":"Suitcase",
        "205":"Bindle",
        "206":"Fanny Pack",
        "207":"Bag of CRO",
        "208":"Pot of Gold",
        },
        'Transportation':{
        // TRAIT_TYPE_3 traits
        "301":"Walker",
        "302":"Roller Blades",
        "303":"Scooter",
        "304":"Skateboard",
        "305":"Pogo Stick",
        "306":"NOS",
        "307":"Jet Pack",
        "308":"Portal",
        },
        'Secondary':{
        // TRAIT_TYPE_4 traits
        "401":"Board Game",
        "402":"Books",
        "403":"Payphone",
        "404":"Jenga",
        "405":"Barbie House",
        "406":"Pile of Money",
        "407":"Bong",
        "408":"Crane Game",
        },
    }
}