import { webStorage } from "../config"
import { merchObjs } from "./merchObjects"

export const Merch = () => {
    return(
        <div>
            <img className='banner' style={{width: '100%'}} src={webStorage + '/merch/merch_banner2.png'} alt=""/>
            <div className="merch-container">
                {
                    merchObjs.map((item)=>{return(
                        <a href={item.link} target='_blank'><div className="merch-button bigger-on-hover">
                            {item.name}
                            <img className="merch-logo" src={webStorage + '/merch/' + item.iamge + '.png'}/>
                        </div></a>
                    )})
                }
            </div>
        </div>

    )
}