export const getNameFromAddress = {
    '0x0000000000000000000000000000000000000000': 'CRO',
    '0x14fB0e7640e7fb7765CFA87cEc973ff5465d1c66': 'SCRATCH',
    '0x06C04B0AD236e7Ca3B3189b1d049FE80109C7977': 'CANDY',
    '0x833a8be904918d594f67843e9BA5fa0740131D76': 'CosmeticMinter',
    '0xA9FD6BCD3EeF1B92a9569435D944742158E946e6': 'Cro Raffles',
    '0xfa3CFCE62000B018CBBb4dcD92887fCC98740eCd': 'Cro Raffles',
    '0xED2179Bf4Df2ba98de22459933f7455b415d1ad2': 'Swap',
    '0x12eEcA2Eda54D6a87E71CdBd88dd5255Fe635082': 'PlaygroundMeme',
    '0x288898a6057d2D4989c533E96Cb3bc30843c91D7': 'PUUSH',
    '0x3b41B27E74Dd366CE27cB389dc7877D4e1516d4d': 'MERY',
    '0x055c517654d72A45B0d64Dc8733f8A38E27Fd49C': 'RYOSHI',
}