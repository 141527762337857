import { Row } from 'antd'
import { useContext } from 'react';
import ScreenWidthContext from './context/screenwidthcontext';
import './styles.css'
import checkNotMobile from './components/logic/mobile';
import { webStorage } from './config';

// interface RefContext {
//     homeRef: React.Ref<HTMLDivElement>,
//     missionRef: React.Ref<HTMLDivElement>,
//     roadmapRef: React.Ref<HTMLDivElement>,
//     storyRef: React.Ref<HTMLDivElement>,
//     teamRef: React.Ref<HTMLDivElement>,
//     faqRef: React.Ref<HTMLDivElement>,
// }

function About() {
    
/******************************************************************************************************************************************************************CONTEXT */    

    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)

/******************************************************************************************************************************************************************TEAM START */

    interface TeamInfo {
        name: string;
        picture: string;
        title: string;
        bio: string;
        aka: string;
        artist: boolean;
    }

    let myArtistsInfo: TeamInfo[] = [
        {
            name: 'Luka',
            picture: 'luka.png',
            title: 'PFP Artist',
            bio: 'https://www.behance.net/JotaLumiSketches',
            aka: 'Luka',
            artist: true,
           
        },
        {
            name: 'Chairim',
            picture: 'chairim.png',
            title: 'Comic Artist',
            bio: 'https://www.instagram.com/chairim_/',
            aka: 'Chairim',
            artist: true,
           
        },
    ]

    let myTeamInfo: TeamInfo[] = [
        {
            name: 'Joe Czarny', 
            picture: 'joe.png', 
            title: 'Project Lead', 
            bio: "Joe has built a career on solving problems & leading teams to success for over 10 years. From implementing projects & systems, to taking control of failing organizations & guiding them to success. His ability to identify problems & then lead a team to solve them will be critical as the team navigates through our road-map. His passion for winning will ensure the team is properly positioned to deliver.",
            aka: 'Elder Karl',
            artist: false,
           
        },
        {
            name: 'Michel Veilleux', 
            picture: 'michel.png', 
            title: 'Dev Lead', 
            bio: 'Michel is a proactive software developer, devoted to undertaking any challenge for anything and everything Boomer Squad. Guided by his extensive background in Computer Science and Psychology, he is dedicated to applying his knowledge and experience to empower the Boomer Squad holders, as well as to strengthen the infrastructure supporting them. Being a full believer in the power of blockchain and its community, Michel enjoys collaborating with developers who share his passion, both teaching and learning from them.', 
            aka: 'Super Rider',
            artist: false,
         
        },
        {
            name: 'Emanuel Martonfi', 
            picture: 'emanuel.png', 
            title: 'Community Manager', 
            bio: "Emanuel has a long history of community involvement both off & online. His experiences come from years of managing online gaming communities & guilds. These skills have also transitioned 'offline' with heavy involvement in leadership roles in local volunteer organizations. With a background in emergency services, he brings a level-headed tempered approach to the project & is up to the challenge of taking a more direct hand in the future of crypto.",
            aka: 'Has Been',
            artist: false,
           
        },
        {
            name: 'David Dawson', 
            picture: 'david.png', 
            title: 'Sales Lead', 
            bio: 'Dawson has been in crypto since 2017 developing a robust understanding of the space and how to navigate it which has made him an advocate for the technology. With 3 successful businesses in the Web2 world he is excited to explore what he can bring to Web3. His experiences as a successful entrepreneur bring the team a deeper understanding of how to successfully monetize an idea in a Web3 world.',
            aka: 'Dawson',
            artist: false,
           
        },
        {
            name: 'Tim K.', 
            picture: 'tim.png', 
            title: 'Developer', 
            bio: "A software engineer with a passion for solving problems Tim has built complex systems in all cycles of development. First as an engineer at a tech startup, he helped build a new product from scratch. After that he helped improve a mature communications platform with millions of concurrent connections. Currently, he is implementing serverless compute technology for a system with tens of millions of requests per second & is excited to bring his expertise into crypto.",
            aka: 'Tired Tim',
            artist: false,
           
        },
        {
            name: 'Colin Barker', 
            picture: 'colin.png', 
            title: 'Developer', 
            bio: "Longtime Business applications developer & certified nerd. Colin has spent the last 15 years in Public & Private IT development & is super excited to be involved with this projecting bridging his expertise to Web3. Colin is an outdoors person, primarily due to the before mentioned 15 years in development.",
            aka: 'DoctorSamage',
            artist: false,
           
        },
        {
            name: 'Michael P.', 
            picture: 'michael.png', 
            title: 'Developer', 
            bio: "Senior Engineer turned crypto enthusiast who started tracking & trading crypto in 2016. Michael brings over 9 years of development experience to the team & among many things he is most excited to apply his skills to building in the space rather then just investing in it.", 
            aka: 'Yekim',
            artist: false,
          
        },
        {
            name: 'Michelle A.', 
            picture: 'michelle.png', 
            title: 'Digital Marketer', 
            bio: "Video gamer & IRL Marketing professional who has been trading NFT's since 2018 (starting with Cryptokitties, of course). She loves animals, board games, collecting things, & building a passive income empire. Her passion to keep forming connections & finding generational wealth with others provides the Boomer Squad an experienced community manager that knows the space very well.", 
            aka: 'Alyshira',
            artist: false,
           
        },
        {
            name: 'Anthony Marcheschi', 
            picture: 'anthony.png', 
            title: 'Game Master', 
            bio: "Anthony has started to embrace the lifestyle of growing into a true boomer. A previous all night gamer now up at 5AM and in bed by 9PM. He still possesses vast gaming knowledge both as a player & as a game master. Proper game theory will be critical for many phases of the project & Anthony will play a key role in establishing proper game mechanics in our Boomer Squad ecosystem.", 
            aka: 'Rocco',
            artist: false,
           
        },
        {
            name: 'Josh N.', 
            picture: 'josh.png', 
            title: 'Security Lead', 
            bio: "Josh comes from a background with over 5 years in IT and a degree in Economics. He was first interested in blockchain because of the innovations in security & decentralization it provides. With NFTs being the new evolution on the blockchain frontier, Josh is optimistic about future applications as more people's everyday lives become more interconnected to the digital space.",
            aka: 'How',
            artist: false,
            
        },
        {
            name: 'Justin W.', 
            picture: 'justin.png', 
            title: 'Collaborators', 
            bio: "Justin is a sales specialist with over 7 years of experience. He will use that sales expertise to build a network of meaningful business relationships for the project as it progresses through its road map. Justin adds a unique & illuminating spin to NFTs, his input will help build a place for collaboration for the Boomer Squad which will open doors for the project's benefit.",
            aka: 'Buckaroo',
            artist: false,
           
        },
    ];

    const TeamBlurp = (teamMember) => {
        let name = teamMember.value.name
        let picture = teamMember.value.picture
        let title =  teamMember.value.title
        let bio = teamMember.value.bio
        let aka = teamMember.value.aka
        let artist = teamMember.value.artist

        //Will render a short form factor of the team bio or long form using showAllInfo logic 

        return (
            checkNotMobile(screenWidth)?
                artist
                    ?

                        <div className='flex-and-center flex-column white-text'>
                            <a className='meet-member-artist-link' href={bio} target='_blank'>
                            <div className='team-row-name'>{name}</div>
                            <div className='team-row-title'>{title}</div>
                            <img className='team-image' src={webStorage + '/teampic/' + picture} alt="Boomer Squad NFT artist team member picture."/>
                            </a>
                        </div>
                    :
                    <div className='flex-and-center flex-column white-text'>
                        <div className='team-row-name'>{name}</div>
                        <div className='team-row-title'>{title}</div>
                        <img className='team-image' src={webStorage + '/teampic/' + picture} alt="Boomer Squad NFT team member picture."/>
                    </div>
                :
                artist
                ?

                    <div className='flex-and-center flex-column white-text'>
                        <a className='mobile-meet-member-artist-link' href={bio} target='_blank'>
                        <div className='mobile-team-row-name'>{name}</div>
                        <div className='mobile-team-row-title'>{title}</div>
                        <img className='mobile-team-image' src={webStorage + '/teampic/' + picture} alt="Boomer Squad NFT artist team member picture."/>
                        </a>
                    </div>
                :
                <div className='flex-and-center flex-column white-text'>
                    <div className='mobile-team-row-name'>{name}</div>
                    <div className='mobile-team-row-title'>{title}</div>
                    <img className='mobile-team-image' src={webStorage + '/teampic/' + picture} alt="Boomer Squad NFT team member picture."/>
                </div>
        )
    }
    const TeamBlurpsList = (props) => {
        const teamArray = props.team
           
        
        const teamItems = teamArray.map((teamMember, index) =>

            <TeamBlurp key={index} value={teamMember} />
            
        )
        return (
            checkNotMobile(screenWidth)?
            <Row className='team-row'>
                {teamItems}
            </Row>
            :
            <Row className='mobile-team-row'>
                {teamItems}
            </Row>
        )

    }

/******************************************************************************************************************************************************************FAQ START */
    interface FAQ {
        question: string;
        answer: string;
    }

    const LinkIcon = () => {
        return(<img style={{height: '15px', marginLeft: '5px'}} src={webStorage + '/navbar/linkicon.png'}/>)
    }


    const FAQs: FAQ[] = [
            {
                question: 'Do you do Collabs / Partnerships?',
                answer: 'Yes, we have a ticket bot on our discord where you can reach the team for a collab or partnership proposals. We will not respond directly to random DMs. We believe in an ecosystem where communities work together to learn and grow with each other. A rising tide lifts all boats!',
            },
            {
                question: 'Be Safe - Do Your Own Research.',
                answer: 'With the emergence of the space unfortunately comes individuals who prey on others through malicious or nefarious activity. Boomer Squad Team members will never DM you first on Twitter or Discord. We will never stealth launch our Mint. Trust, but verify.',
            },

        ]

    const text1 = <div>
        Boomer Squad aims to build an ecosystem of games, collections, events, and web2/web3 services that all reflect back to our main gen 0 collection.
                            Built by a team of 11, we have been growing our brand and community since January of 2022. We kicked off with our launch of the Boomer Squad Gen 0 NFT collection in May of 2022 on Cronos.<br/><br/>

                            Since our debut, we have launched multiple innovative on-block products. Our second release was Chow Time - an on-chain marketplace arbitrage game that lasted 30 days. Our most recent launch comes in the form of our Boom Rooms and the ecosystem utility token $SCRATCH. Other products of our ecosystem include process.env
                            the launchpad, our upcoming Zoomer collection, the Boomer Squad Art Collection (BSAC), Boomer Radio, and an upcoming marketplace.
    </div>

    const text2 = <div>
          A collection of 8008 Boomers with over 180 traits and 18 unique 1/1 legendaries using crypto in the only way they know how.<br/><br/>
                            Our goal is to build a self-sustaining ecosystem, using our Gen 0 Boomer Squad NFTs as the heart of our project.
                            A full breakdown of our entire ecosystem and the project as a whole can be found in our<a href='https://boomer-squad.gitbook.io/boomer-squad-nft/' target='_blank'>&nbsp;GitBook<LinkIcon/>.</a>
    </div>

    const FaqBlurp = (FAQ) => {
        let question = FAQ.value.question
        let answer = FAQ.value.answer

        return (
            checkNotMobile(screenWidth)?
            <div className='faq-item white-text'>
                <Row className='faq-row-question' justify='center'>{question}</Row>
                <Row className='faq-row-answer' justify='center'>{answer}</Row>
            </div>
            :
            <div className='mobile-faq-item white-text'>
                <Row className='faq-row-question' justify='center'>{question}</Row>
                <Row className='faq-row-answer' justify='center'>{answer}</Row>
            </div>
        )
    }
    const FAQList = (props) => {
        const faqArray = props.faqs
        const faqItems = faqArray.map((FAQ, index) =>
            <FaqBlurp key={index} value={FAQ} />
        )
        return (
            <Row justify='center'>
                {faqItems}
            </Row>
        )
    }

    return (
        checkNotMobile(screenWidth)?
        <div className='flex-and-center flex-column nowbold'>
            <img src={webStorage + "/home/homebanner.png"} className="sloganPic transition" alt='Boomer Land NFT neighbourhood containing a shop, the Boomer Squad NFT retirement home, a Metagas station, and a park generally filled with zoomers.'/>
{/****************************************************************************************************************************************************MISSION + ROADMAP */}
                <div className='home-section'>
                <div className='title'>Boomer Squad</div>
                <div className='title-sub'>Past, Present, and Future</div>
                    <div className='mission-section'>
                        <div className='mission-section-medium'>
                            <img className='mission-section-image' src={webStorage + '/home/whitepaper_tree.png'} />
                        </div>
                        <div className='mission-section-medium'>
                            {text1}
                        </div>
                    </div>
                    <div className='mission-section'>
                        <div className='mission-section-small'>
                            {text2}
                        </div>
                        <div className='mission-section-small'>
                            <div>
                                <img className='mission-section-image-small' src={webStorage + '/home/4.png'} />
                                <img className='mission-section-image-small' src={webStorage + '/home/5.png'} />
                            </div>
                            <div>
                                <img className='mission-section-image-small' src={webStorage + '/home/7.png'} />
                                <img className='mission-section-image-small' src={webStorage + '/home/10.png'} />
                            </div>
                        </div>
                    </div>
                    {/* <div className='mission-section-textonly'>
                        <div className='mission-section-big big-flashy-text'>
                            <div className='neon-text neon-green text-60'>{'OVER 4 MILLION CRO'}</div><div>{'GIVEN BACK THROUGH MATTRESS FUND, AIRDROPS, AND FREE MINTS'}</div>
                        </div>
                        <div className='mission-section-big big-flashy-text'>
                            <div className='neon-text neon-lblue text-60'>{'OVER 100K TRANSACTIONS'}</div><div>{'ACROSS ALL 20 SMART CONTRACTS'}</div>
                        </div>
                        <div className='mission-section-big big-flashy-text'>
                            <div className='neon-text neon-pink text-60'>{'OVER 5 MILLION CRO'}</div><div>{'IN SECONDARY SALES ACROSS ALL COLLECTIONS'}</div>
                        </div>
                    </div> */}
                </div>
           
{/****************************************************************************************************************************************************TEAM */}
            <div className='home-section'>
                <div className='title'>Boomer Squad Team</div>
                <TeamBlurpsList team={myTeamInfo} />
                <div className='title'>Artists</div>
                <TeamBlurpsList team={myArtistsInfo} />
            </div>
{/****************************************************************************************************************************************************FAQ */}           
            <div className='home-section'>
                <div className="title">
                    {/* <img className="faq-image-one" src={"../home/timmy-questionmark.png"} alt="" /> */}
                    FAQs
                </div>
                <FAQList faqs={FAQs}/>
            </div >
        </div>  
        : 
        ////////////////////////////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE
////////////////////////////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE//////////////////////////////////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE///////////////////MOBILE//////
<div className='flex-and-center flex-column nowbold'>
<img src={webStorage + "/home/homebanner.png"} className="sloganPic transition" alt='Boomer Land NFT neighbourhood containing a shop, the Boomer Squad NFT retirement home, a Metagas station, and a park generally filled with zoomers.'/>
{/****************************************************************************************************************************************************MISSION + ROADMAP */}
    <div className='mobile-home-section'>
        <div className='mobile-title'>Boomer Squad</div>
        <div className='mobile-title-sub'>Past, Present, and Future</div>
        <img className='mobile-mission-section-image' src={webStorage + '/home/whitepaper_tree.png'} />
        {text1}
        <div className='mission-section-small'>
            <div>
                <img className='mobile-mission-section-image-small' src={webStorage + '/home/4.png'} />
                <img className='mobile-mission-section-image-small' src={webStorage + '/home/5.png'} />
            </div>
            <div>
                <img className='mobile-mission-section-image-small' src={webStorage + '/home/7.png'} />
                <img className='mobile-mission-section-image-small' src={webStorage + '/home/10.png'} />
            </div>
        </div>
        {text2}

        {/* <div className='mobile-big-text-info'><div className='neon-text neon-green text-40'>{'OVER 4 MILLION CRO'}</div><div>{'GIVEN BACK THROUGH MATTRESS FUND, AIRDROPS, AND FREE MINTS'}</div></div>
        <div className='mobile-big-text-info'><div className='neon-text neon-lblue text-40'>{'OVER 100K TRANSACTIONS'}</div><div>{'ACROSS ALL 20 SMART CONTRACTS'}</div></div>
        <div className='mobile-big-text-info'><div className='neon-text neon-pink text-40'>{'OVER 5 MILLION CRO'}</div><div>{'IN SECONDARY SALES ACROSS ALL COLLECTIONS'}</div></div> */}



{/****************************************************************************************************************************************************TEAM */}
    <div className='mobile-title'>Boomer Squad Team</div>
    <TeamBlurpsList team={myTeamInfo} />
    <div className='mobile-title'>Artists</div>
    <TeamBlurpsList team={myArtistsInfo} />
{/****************************************************************************************************************************************************FAQ */}           
    <div className="mobile-title">FAQs</div>
    <FAQList faqs={FAQs}/>
</div >
</div> 
                
    )

}
export default About