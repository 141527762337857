
import React from 'react';
import Web3 from 'web3'

const Web3Context = React.createContext({
  
    account: '',
    setAccount: (account: string) => {},

    balance: '',
    setBalance: (balance: string) => {},

    chainID: 0,
    setChainID: (chainID: number) => {},

    scratchBalance: '',
    setScratchBalance: (scratchBalance: string) => {},

  })

export default Web3Context