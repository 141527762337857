import { useState, useContext, useEffect } from 'react';
import ConnectedContext from '../context/connectedcontext';
import Web3Context from '../context/web3context';
import toContract from '../types/truffle_contracts';

import Web3 from 'web3';
import { Row } from 'antd';
import ScreenWidthContext from '../context/screenwidthcontext';
import Spinner from '../components/spinner'
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';
import checkNotMobile from '../components/logic/mobile';

import { CosmeticComponent, BoomRoomPayload, ComponentPayload, DecorateRoomPayload, SigPayload } from "./boomroom_interfaces"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BoomRoomContract from '../contracts/BoomRoom.json' //boom room contract
import { componentImagesBaseURI, contractAddresses, cosmeticImageBaseURI } from './boomroom_config';

import Activity from '../contracts/Activity.json'
import Bed from "../contracts/Bed.json"
import Building from "../contracts/Building.json"
import Couch from "../contracts/Couch.json"
import Desk from "../contracts/Desk.json"
import Door from "../contracts/Door.json"
import Entertainment from "../contracts/Entertainment.json"
import Pet from "../contracts/Pet.json"
import Table from "../contracts/Table.json"
import Window from "../contracts/Window.json"

import Draggable from 'react-draggable'; // The default
import { brokenRoomMetadata, componentTraitNames } from './boomroom_utils';
import { YoutubeVideo } from '../components/youtubevideo/youtubevideo';
import { fetchMetadata } from '../components/utils/api_calls';
import { getCosmeticInfo } from './cosmetic_codex';
import { webStorage } from '../config';

const axios = require('axios').default; // axios.<method> will now provide autocomplete and parameter typings

function BoomRoomDecorator () {

  // Get web3 and the account (metamask public key) to place it on our button text once user is CONNECTED
  const {account, setAccount, balance, setBalance, chainID, setChainID} = useContext(Web3Context) //ommiting web3 and creating it locally because of having to set up contract
  // context to verify if the user WANTS to be connected ot the site, IE if they have clicked the connect button yet, again we cant DISCONNECT the user's metamask, only give the illusion of NOT being connected
  const {connected, setConnected} = useContext(ConnectedContext)
  // Screen Width context
  const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
  // contract read and write providers context
  const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)

  const activity = toContract(Activity)
  const bed = toContract(Bed)
  const building = toContract(Building)
  const couch = toContract(Couch)
  const desk = toContract(Desk)
  const door = toContract(Door)
  const entertainment = toContract(Entertainment)
  const pet = toContract(Pet)
  const table = toContract(Table)
  const windowComponent = toContract(Window)

  const contractRead = toContract(BoomRoomContract)
  const contractWrite = toContract(BoomRoomContract)
    // set up contract
  if(contractWriteProviders){
    const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
    contractRead.setProvider(contractReadProviders)
    contractWrite.setProvider(contractWriteProviders)
    activity.setProvider(contractReadProviders)
    bed.setProvider(contractReadProviders)
    building.setProvider(contractReadProviders)
    couch.setProvider(contractReadProviders)
    desk.setProvider(contractReadProviders)
    door.setProvider(contractReadProviders)
    entertainment.setProvider(contractReadProviders)
    pet.setProvider(contractReadProviders)
    table.setProvider(contractReadProviders)
    windowComponent.setProvider(contractReadProviders)
  }

  const [boomRoomMetadataList, setBoomRoomMetadataList] = useState([] as any[])
  const [roomsLoading, setRoomsLoading] = useState(true)
  const [compLoading, setCompLoading] = useState(true)
  const [cosmeticMetadataList, setCosmeticMetadataList] = useState([] as any[])

  const [count, setCount] = useState(0)

/**
 * 
 * IMPORTANT - COMPONENT ORDERING
 * 0. WINDOW  
 * 1. DOOR
 * 2. BED
 * 3. BUILDING
 * 4. TABLE
 * 5. DESK
 * 6. ENTERTAINMENT
 * 7. COUCH
 * 8. PET
 * 9. ACTIVITY
 * 
 * 
 * 
 * width of image = 40%
 */


  // select mode
  const [activeSort, setActiveSort] = useState('All')
  const [mode, setMode] = useState('SelectRoom')

  const [decorateSelection, setDecorateSelection] = useState({} as any)
  const [decorateComponents, setDecorateComponents] = useState([] as Comp[])

  const [dragComponentList, setDragComponentList] = useState([] as TempComp[])

  const selectRoom = (boomroom) => {
    setDecorateSelection(boomroom)
    resetAll()
  }

  const emptyCosmetic = (): CosmeticComponent => {
    return{
      cosmetic_collection: 'None',
      cosmetic_edition: 'None',
      cosmetic_id: '0',
      cosmetic_image: '0'
    }
  }

  const [updatedCosmetic_ONE, setUpdatedCosmetic_ONE] = useState(emptyCosmetic())
  const [updatedCosmetic_TWO, setUpdatedCosmetic_TWO] = useState(emptyCosmetic())
  const [updatedCosmetic_THREE, setUpdatedCosmetic_THREE] = useState(emptyCosmetic())
  const [updatedCosmetic_FOUR, setUpdatedCosmetic_FOUR] = useState(emptyCosmetic())
  const [updatedCosmetic_FIVE, setUpdatedCosmetic_FIVE] = useState(emptyCosmetic())
  const [updatedCosmetic_SIX, setUpdatedCosmetic_SIX] = useState(emptyCosmetic())
  const [updatedCosmetic_SEVEN, setUpdatedCosmetic_SEVEN] = useState(emptyCosmetic())
  const [updatedCosmetic_EIGHT, setUpdatedCosmetic_EIGHT] = useState(emptyCosmetic())
  const [updatedCosmetic_NINE, setUpdatedCosmetic_NINE] = useState(emptyCosmetic())

  const updatedCosmeticsList_PENTHOUSE = [updatedCosmetic_ONE, updatedCosmetic_TWO, updatedCosmetic_THREE, updatedCosmetic_FOUR, updatedCosmetic_FIVE, updatedCosmetic_SIX, updatedCosmetic_SEVEN, updatedCosmetic_EIGHT, updatedCosmetic_NINE]
  const updatedCosmeticsList_LOFT = [updatedCosmetic_ONE, updatedCosmetic_TWO, updatedCosmetic_THREE, updatedCosmetic_FOUR, updatedCosmetic_FIVE, updatedCosmetic_SIX]
  const updatedCosmeticsList_MEDIUM = [updatedCosmetic_ONE, updatedCosmetic_TWO, updatedCosmetic_THREE, updatedCosmetic_FOUR, updatedCosmetic_FIVE]
  const updatedCosmeticsList_STUDIO = [updatedCosmetic_ONE, updatedCosmetic_TWO, updatedCosmetic_THREE, updatedCosmetic_FOUR]
  const updatedCosmeticsList_SINGLE = [updatedCosmetic_ONE, updatedCosmetic_TWO, updatedCosmetic_THREE]

  /**
   * 
   * 
   * General functions
   * 
   * 
   */

  interface Comp {
    component_type: string,
    component_id: string,
    component_image: string,
    component_code: string
  }

  interface TempComp {
    component_type: string,
    component_code: string,
    x_pos: number,
    y_pos: number,
  }

  interface DragComp{
    component_code: string,
    x_pos: number,
    y_pos: number,
    x_pos2: number,
    y_pos2: number,
  }

  const movable = ['Window', 'Door', 'Building']
  const [bedScale, setBedScale] = useState(0.7)
  const [tableScale, setTableScale] = useState(0.7)
  const [deskScale, setDeskScale] = useState(0.7)
  const [entertainmentScale, setEntertainmentScale] = useState(0.7)
  const [couchScale, setCouchScale] = useState(0.7)
  const [petScale, setPetScale] = useState(0.7)
  const [activityScale, setActivityScale] = useState(0.7)

  const [cos0Scale, setCos0Scale] = useState(0.7)
  const [cos1Scale, setCos1Scale] = useState(0.7)
  const [cos2Scale, setCos2Scale] = useState(0.7)
  const [cos3Scale, setCos3Scale] = useState(0.7)
  const [cos4Scale, setCos4Scale] = useState(0.7)
  const [cos5Scale, setCos5Scale] = useState(0.7)
  const [cos6Scale, setCos6Scale] = useState(0.7)
  const [cos7Scale, setCos7Scale] = useState(0.7)
  const [cos8Scale, setCos8Scale] = useState(0.7)

  const getBoomRoomPinnedComponents = async(boomroom: any):Promise<Array<Comp>>  => {

    let roomComps: Comp[] = []
    for(let i in boomroom.attributes){
      for(let ii in componentTraitNames){
        if(boomroom.attributes[i].trait_type === componentTraitNames[ii]){
          let tokenMetadata = await getComponentMetadataFromContract(componentTraitNames[ii], boomroom.attributes[i].value)
          roomComps.push({
            component_type: componentTraitNames[ii], 
            component_id: boomroom.attributes[i].value,
            component_image: componentImagesBaseURI+generateComponentCode(tokenMetadata, componentTraitNames[ii])+'.png',
            component_code: generateComponentCode(tokenMetadata, componentTraitNames[ii])
          })
        }
      }
    }
    return roomComps
  }

function generateComponentCode(component: any, componentType: string): string{
  let one = convertRoomSizeToNumber(decorateSelection.attributes[0].value)
  let two = convertComponentTypeToNumber(componentType)
  let three = component[0][0].toString() !== '0'?(component[0][0].toString()).substring(2):'0'
  let four = component[0][1].toString() !== '0'?(component[0][1].toString()).substring(2):'0'
  let five = component[0][2].toString() !== '0'?(component[0][2].toString()).substring(2):'0'
  let six = component[0][3].toString() !== '0'?(component[0][3].toString()).substring(2):'0'
  let componentCode = one+two+three+four+five+six
  let compCodeFixed = fixForSize(componentCode)
  return compCodeFixed
}

const fixForSize = (code: string):string => {
  let fixedCode: string = code
  let size = code.substring(0, 1)
  let compType = code.substring(1, 2)
  //fix penthouse
  if(size === '5'){
    if(compType === '9'){
      fixedCode = '1'+code.substring(1)
    }
  }
  //fix loft
  if(size === '4'){
    if(compType === '9' || compType === '8'){
      fixedCode = '1'+code.substring(1)
    }
  }
  //fix double
  if(size === '3'){
    if(compType === '9' || compType === '8'){
      fixedCode = '1'+code.substring(1)
    }
    if(compType === '7' || compType === '6' || compType === '5' || compType === '4' || compType === '2'){
      fixedCode = '4'+code.substring(1)
    }
  }
  //fix studio
  if(size === '2'){
    if(compType === '9' || compType === '8' || compType === '7' || compType === '6' || compType === '5' || compType === '4' || compType === '2'){
      fixedCode = '1'+code.substring(1)
    }
  }
  return fixedCode
}

const convertRoomSizeToNumber = (roomSizeString: string): string => {
  let roomSizeNumber: string
  if(roomSizeString === 'Single'){roomSizeNumber = '1'}
  if(roomSizeString === 'Studio'){roomSizeNumber = '2'}
  if(roomSizeString === 'Double'){roomSizeNumber = '3'}
  if(roomSizeString === 'Loft'){roomSizeNumber = '4'}
  if(roomSizeString === 'Penthouse'){roomSizeNumber = '5'}
  return roomSizeNumber
}

  const convertComponentTypeToNumber = (componentType: string): string => {
    let compNumber: string
    if(componentType === 'Window'){compNumber = '0'}
    if(componentType === 'Door'){compNumber = '1'}
    if(componentType === 'Bed'){compNumber = '2'}
    if(componentType === 'Building'){compNumber = '3'}
    if(componentType === 'Table'){compNumber = '4'}
    if(componentType === 'Desk'){compNumber = '5'}
    if(componentType === 'Entertainment'){compNumber = '6'}
    if(componentType === 'Couch'){compNumber = '7'}
    if(componentType === 'Pet'){compNumber = '8'}
    if(componentType === 'Activity'){compNumber = '9'}
    return compNumber
  }

  const getComponentMetadataFromContract = async(componentType: string, tokenID: string) => {
    let contract: any
    if(componentType === 'Window'){contract = windowComponent}
    if(componentType === 'Door'){contract = door}
    if(componentType === 'Bed'){contract = bed}
    if(componentType === 'Building'){contract = building}
    if(componentType === 'Table'){contract = table}
    if(componentType === 'Desk'){contract = desk}
    if(componentType === 'Entertainment'){contract = entertainment}
    if(componentType === 'Couch'){contract = couch}
    if(componentType === 'Pet'){contract = pet}
    if(componentType === 'Activity'){contract = activity}
    let instance = await contract.deployed()
    let tokenMetadata = await instance.getComponentMetadata(tokenID)
    return tokenMetadata
  }

  /**
 * Components
 */
  const BoomRoomList = (props) => {
      const boomroomlist = props.boomroomlist
      let roomList = []
      for(let i in boomroomlist){
        if(boomroomlist[i] !== undefined && boomroomlist[i] !== null && boomroomlist[i].attributes !== undefined){
          if(boomroomlist[i].attributes[0].value === activeSort && boomroomlist[i].attributes[2].value !== '0' || activeSort ==='All' && boomroomlist[i].attributes[2].value !== '0'){
            roomList.push(boomroomlist[i])
          }
        } else {
          roomList.push(brokenRoomMetadata)
        }
      }
      const boomroomItems = roomList.map((boomroom, index) => <BoomRoomCard key={index} value={boomroom} />)
      return (
        boomroomItems.length > 0?
        <Row justify='center'>
          {boomroomItems}
        </Row>
        :
        <>
          <img src={webStorage + '/boomrooms/' + 'noroomsfound.png'} style={{marginTop: '60px', maxWidth: '800px', width: '90%'}}/>
        </>
      )
    }
  
    const BoomRoomCard = (boomroom) => {
      const id = boomroom.value.id
      const name = boomroom.value.name
      const type = boomroom.value.attributes[0].value
      const multiplier = boomroom.value.attributes[1].value
      const score = boomroom.value.attributes[2].value
      const image = boomroom.value.image
      const openCompSlots = boomroom.value.attributes[3].value
      return(
        <>
          {
            <div className='flex-and-center flex-column bigger-on-hover' onClick={id!=='0'?() => {setMode('RoomDecorate'); selectRoom(boomroom.value)}:()=>''}>
              <div className='br-boomroomcard neon-border-pink'>
                <img className='br-boomroomcard-image' src={image}/>
                <div className='br-blackline'/>
                <div className='br-boomroomcard-bottombox'>
                  <div className='flex-and-center flex-column'>
                    <div>{name}</div>
                    <div>{'Open Component Slots: ' + openCompSlots}</div>
                    <div>{'Multiplier: ' + multiplier}</div>
                    <div>{'Total Score: ' + score}</div>
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      )
    }

  const CosmeticList = (props) => {
    const cosmeticList = props.cosmeticList
    let count = 0
    const cosmeticItems = cosmeticList.map((component, index) => <><CosmeticSlot key={index} value={component} count={count}/><script>{count++}</script></>)
    return (<Row justify='center'>{cosmeticItems}</Row>)
  }

  const CosmeticSlot = (cosmetic) => {
    const cosmetic_collection = cosmetic.value.cosmetic_collection
    const cosmetic_edition = cosmetic.value.cosmetic_edition
    const cosmetic_id = cosmetic.value.cosmetic_id
    const cosmetic_image = cosmetic.value.cosmetic_image
    const count = cosmetic.count
    return (
      cosmetic_id != '0'?
      <>
        <div className='flex justify-center flex-column'>
          <div className='neon-text neon-pink font-35'>{'COSMETIC'}</div>
          <div className='br-roomconstruct-componentcard-container neon-border-pink'>
            <img className='br-roomconstruct-componentcard-image' src={cosmeticImageBaseURI +cosmetic_image+'.png'}/>
            <div className='br-blackline' />
            <div className='br-roomconstruct-componentcard-bottombox flex-and-center flex-column'>
              <div>{'Cosmetic #' + cosmetic_id}</div>
              <div className='br-blackline-half'/>
              <div>{cosmetic_collection}</div>
              <div className='br-blackline-half'/>
              <div>{cosmetic_edition}</div>
            </div>
            <div className='br-roomconstruct-componentcard-buttoncontainer flex justify-around'>
              <div className='neon-text neon-red bigger-on-hover font-30' onClick={() => {removeCosmetic(count); removeCosmeticFromComps(count)}}>Remove</div>
            </div>
          </div>
        </div>
      </>

      :
      <>
        <div className='flex justify-center flex-column'>
          <div className='neon-text neon-pink font-35'>{'COSMETIC'}</div>
          <div className='br-roomconstruct-componentcard-container neon-border-pink'>
            <div className='bg-black br-roomconstruct-componentcard-empty'/>
            <div className='br-blackline' />
            <div className='br-roomconstruct-componentcard-bottombox flex-and-center flex-column'>
              <div>{'Open Cosmetic Slot'}</div>
              <div className='br-blackline-half'/>
              <div>{'Add'}</div>
              <div className='br-blackline-half'/>
              <div>{'Me!'}</div>
            </div>
            <div className='br-roomconstruct-componentcard-buttoncontainer flex-and-center'>
              <div className='neon-text neon-green bigger-on-hover font-30' onClick={() => {setMode('CosmeticAdd'); setCount(count)}}>Add</div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const removeCosmeticFromComps = (count: number) => {
    let newlist = [] as any[]
    let newDragList = [] as any[]
    for(let i in decorateComponents){
      if(decorateComponents[i].component_type !== count.toString()){
        newlist.push(decorateComponents[i])
      }
    }
    for(let i in dragComponentList){
      if(dragComponentList[i].component_type !== count.toString()){
        newDragList.push(dragComponentList[i])
      }
    }
    setDragComponentList(newDragList)
    setDecorateComponents(newlist)
  }

  const AddCosmeticList = (props) => {
    const cosmeticList = props.cosmeticList
    const cosmeticItems = cosmeticList.map((cosmetic, index) => {
        return (
          <AddCosmetic key={index} value={cosmetic}/>
        )
      })
    return (<Row justify='center'>{cosmeticItems}</Row>)
  }

  const AddCosmetic = (cosmetic) => {
    const cosmetic_collection = cosmetic.value.attributes[0].value
    const cosmetic_edition = cosmetic.value.attributes[1].value
    const cosmetic_id = cosmetic.value.id
    const cosmetic_image = cosmetic.value.image
    
    return(
      isAlreadyAdded(cosmetic_id.toString())?
      <></>:
      <>
        <div className='flex justify-center flex-column'>
          <div className='br-roomconstruct-componentcard-container neon-border-yellow'>
            <img className='br-roomconstruct-componentcard-image' src={cosmetic_image}/>
            <div className='br-blackline' />
            <div className='br-roomconstruct-componentcard-bottombox flex-and-center flex-column'>
              <div>{'Cosmetic #' + cosmetic_id}</div>
              <div>{cosmetic_collection}</div>
              <div>{cosmetic_edition}</div>
            </div>
            <div className='br-roomconstruct-componentcard-buttoncontainer flex justify-around'>
              <div className='neon-text neon-green bigger-on-hover font-30' onClick={() => {
                setMode('RoomDecorate'); 
                addCosmetic(cosmetic.value); 
                setDecorateComponents(decorateComponents.concat([{
                  component_type: count.toString(), 
                  component_code: getCosmeticInfo(cosmetic_id, decorateSelection.attributes[0].value).imageCode,
                  component_id: cosmetic_id.toString(),
                  component_image: componentImagesBaseURI+getCosmeticInfo(cosmetic_id, decorateSelection.attributes[0].value).imageCode+'.png'
                  }]))
                }}>Add</div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const isAlreadyAdded = (tokenID: string) => {
    let myBool = false
    if(updatedCosmetic_ONE.cosmetic_id === tokenID){
      myBool = true
    }
    if(updatedCosmetic_TWO.cosmetic_id === tokenID){
      myBool = true
    }
    if(updatedCosmetic_THREE.cosmetic_id === tokenID){
      myBool = true
    }
    if(updatedCosmetic_FOUR.cosmetic_id === tokenID){
      myBool = true
    }
    if(updatedCosmetic_FIVE.cosmetic_id === tokenID){
      myBool = true
    }
    if(updatedCosmetic_SIX.cosmetic_id === tokenID){
      myBool = true
    }
    if(updatedCosmetic_SEVEN.cosmetic_id === tokenID){
      myBool = true
    }
    if(updatedCosmetic_EIGHT.cosmetic_id === tokenID){
      myBool = true
    }
    if(updatedCosmetic_NINE.cosmetic_id === tokenID){
      myBool = true
    }
    return myBool
  }



  const fullCosmetic = (tokenID): CosmeticComponent => {
    let cosmetic = getCosmeticInfo(Number(tokenID.toString()), '')
    return{
      cosmetic_collection: cosmetic.collectionName,
      cosmetic_edition: cosmetic.editionName,
      cosmetic_id: tokenID.toString(),
      cosmetic_image: cosmetic.coverImage
    }
  }

  const addCosmetic = (cosmetic: any) => {
    switch(count){
      case 0:
        setUpdatedCosmetic_ONE(fullCosmetic(cosmetic.id))
        return
      case 1:
        setUpdatedCosmetic_TWO(fullCosmetic(cosmetic.id))
        return
      case 2:
        setUpdatedCosmetic_THREE(fullCosmetic(cosmetic.id))
        return
      case 3:
        setUpdatedCosmetic_FOUR(fullCosmetic(cosmetic.id))
        return
      case 4:
        setUpdatedCosmetic_FIVE(fullCosmetic(cosmetic.id))
        return
      case 5:
        setUpdatedCosmetic_SIX(fullCosmetic(cosmetic.id))
        return
      case 6:
        setUpdatedCosmetic_SEVEN(fullCosmetic(cosmetic.id))
        return
      case 7:
        setUpdatedCosmetic_EIGHT(fullCosmetic(cosmetic.id))
        return
      case 8:
        setUpdatedCosmetic_NINE(fullCosmetic(cosmetic.id))
        return
    }
  }

  const removeCosmetic = (count: number) => {
    switch(count){
      case 0:
        setUpdatedCosmetic_ONE(emptyCosmetic())
        return
      case 1:
        setUpdatedCosmetic_TWO(emptyCosmetic())
        return
      case 2:
        setUpdatedCosmetic_THREE(emptyCosmetic())
        return
      case 3:
        setUpdatedCosmetic_FOUR(emptyCosmetic())
        return
      case 4:
        setUpdatedCosmetic_FIVE(emptyCosmetic())
        return
      case 5:
        setUpdatedCosmetic_SIX(emptyCosmetic())
        return
      case 6:
        setUpdatedCosmetic_SEVEN(emptyCosmetic())
        return
      case 7:
        setUpdatedCosmetic_EIGHT(emptyCosmetic())
        return
      case 8:
        setUpdatedCosmetic_NINE(emptyCosmetic())
        return
    }
  }

  //determines which cosmetic list to use depending on boom room size, since bigger boom rooms can hold more cosmetic slots
  const selectUpdatedCosmeticList = (boomroomType) => {
    if(boomroomType === 'Single'){
      return updatedCosmeticsList_SINGLE
    } else if(boomroomType === 'Studio') {
      return updatedCosmeticsList_STUDIO
    } else if(boomroomType === 'Double') {
      return updatedCosmeticsList_MEDIUM
    } else if(boomroomType === 'Loft') {
      return updatedCosmeticsList_LOFT
    } else if(boomroomType === 'Penthouse') {
      return updatedCosmeticsList_PENTHOUSE
    }
  }

  const scaleDown = (compType: string) => {
    if(getScalingBasedOnType(compType) < 0.2){
      return
    }
    switch(compType){
      case 'Bed':
        setBedScale(bedScale-0.1)
        break
      case 'Table':
        setTableScale(tableScale-0.1)
        break
      case 'Desk':
        setDeskScale(deskScale-0.1)
        break
      case 'Entertainment':
        setEntertainmentScale(entertainmentScale-0.1)
        break
      case 'Couch':
        setCouchScale(couchScale-0.1)
        break
      case 'Pet':
        setPetScale(petScale-0.1)
        break
      case 'Activity':
        setActivityScale(activityScale-0.1)
        break
      case '0':
        setCos0Scale(cos0Scale-0.1)
        break
      case '1':
        setCos1Scale(cos1Scale-0.1)
        break
      case '2':
        setCos2Scale(cos2Scale-0.1)
        break
      case '3':
        setCos3Scale(cos3Scale-0.1)
        break
      case '4':
        setCos4Scale(cos4Scale-0.1)
        break
      case '5':
        setCos5Scale(cos5Scale-0.1)
        break
      case '6':
        setCos6Scale(cos6Scale-0.1)
        break
      case '7':
        setCos7Scale(cos7Scale-0.1)
        break
      case '8':
        setCos8Scale(cos8Scale-0.1)
        break
    }
  }

  const scaleUp = (compType: string) => {
    if(getScalingBasedOnType(compType) > 3){
      return
    }
    switch(compType){
      case 'Bed':
        setBedScale(bedScale+0.1)
        break
      case 'Table':
        setTableScale(tableScale+0.1)
        break
      case 'Desk':
        setDeskScale(deskScale+0.1)
        break
      case 'Entertainment':
        setEntertainmentScale(entertainmentScale+0.1)
        break
      case 'Couch':
        setCouchScale(couchScale+0.1)
        break
      case 'Pet':
        setPetScale(petScale+0.1)
        break
      case 'Activity':
        setActivityScale(activityScale+0.1)
        break
      case '0':
        setCos0Scale(cos0Scale+0.1)
        break
      case '1':
        setCos1Scale(cos1Scale+0.1)
        break
      case '2':
        setCos2Scale(cos2Scale+0.1)
        break
      case '3':
        setCos3Scale(cos3Scale+0.1)
        break
      case '4':
        setCos4Scale(cos4Scale+0.1)
        break
      case '5':
        setCos5Scale(cos5Scale+0.1)
        break
      case '6':
        setCos6Scale(cos6Scale+0.1)
        break
      case '7':
        setCos7Scale(cos7Scale+0.1)
        break
      case '8':
        setCos8Scale(cos8Scale+0.1)
        break
    }
  }

  const ComponentImageList = (props) => {
    const componentList = props.componentList
    const componentItems = componentList.map((component, index) => <ComponentImage key={index} value={component} />)
    return (
    componentItems.length > 0?
    <Row justify='center'>{componentItems}</Row>
    :
    <>
    <Spinner loading={true} size={200} />
    </>)
  }

  const ComponentImage = (props) => {
    const component = props.value
    const componentImage = props.value.component_image
    const componentType = props.value.component_type
    const componentId = props.value.component_id

    return (
      componentId != '0'?
      <div className='flex-and-center flex-column'>
        <div className='br-roomdecorator-componentlist-container neon-border-pink flex-and-center flex-column'>
          <img className='width-100 br-roomdecorator-componentlist-image' src={componentImage}/>
          <div className='br-roomdecorator-componentlist-container-bottombox flex-and-center flex-column'>
            <div>{componentType}{' #'}{componentId}</div>
            <div className='br-roomdecorator-componentlist-container-bottombox-buttons'>
              <div className='neon-text neon-green bigger-on-hover font-20 move-forward' onClick={() => {addComponent(component)}}>Add</div>
              <div className='neon-text neon-red bigger-on-hover font-20 move-forward' onClick={() => {removeComponent(component)}}>Remove</div>
            </div>
          </div>
        </div>
        <Row className='br-boomroom-scaling'>
        {
          !movable.includes(componentType)?
          <>
          <div className = 'bigger-on-hover scaling-button green' onClick={() => scaleUp(componentType)}>+</div>
          {getScalingBasedOnType(componentType).toFixed(1)}
          <div className = 'bigger-on-hover scaling-button red' onClick={() => scaleDown(componentType)}>-</div>
          </>
          :<></>
        }
        </Row>
      </div>
      :<></>
    )
  }

  /**
  * Dragging
  */

  /**
   * 
   * components that will not move: building, door, window
   * 
   * 
   */
  const checkIfFixed = (code: string): boolean => {
    let fixed: boolean = false
    if(
      code === '600043' ||
      code === '600044' ||
      code === '600045' ||
      code === '600046' ||
      code === '600047'
      ){
      fixed = true
      return fixed
      }
    if(code.substring(0,1) === '6'){
      return fixed
    }
    let num = Number(code.substring(1,2))
    if(num === 0 || num === 1 || num === 3){
      fixed = true
    }
    return fixed
  }

  const DraggableComponentList = (props) => {
    const componentList = props.componentList
    const componentItems = componentList.map((component, index) => <DraggableImage key={index} value={component} />)
    return (<>{componentItems}</>)
  }

  const resetAll = () => {
    setDragComponentList([] as TempComp[])
    setBedScale(0.7)
    setTableScale(0.7)
    setEntertainmentScale(0.7)
    setCouchScale(0.7)
    setPetScale(0.7)
    setActivityScale(0.7)
    setDeskScale(0.7)
    setCos0Scale(0.7)
    setCos1Scale(0.7)
    setCos2Scale(0.7)
    setCos3Scale(0.7)
    setCos4Scale(0.7)
    setCos5Scale(0.7)
    setCos6Scale(0.7)
    setCos7Scale(0.7)
    setCos8Scale(0.7)
  }

  const getScalingBasedOnType = (compType: string):number => {
    switch(compType){
      case 'Bed':
       return bedScale
      case 'Table':
        return tableScale
      case 'Desk':
        return deskScale
      case 'Entertainment':
        return entertainmentScale
      case 'Couch':
        return couchScale
      case 'Pet':
        return petScale
      case 'Activity':
        return activityScale
      case '0':
       return cos0Scale
      case '1':
        return cos1Scale
      case '2':
        return cos2Scale
      case '3':
        return cos3Scale
      case '4':
        return cos4Scale
      case '5':
        return cos5Scale
      case '6':
        return cos6Scale
      case '7':
        return cos7Scale
      case '8':
        return cos8Scale
    }
  }

  const DraggableImage = (props) => {
    const component: TempComp = props.value
    const componentType = props.value.component_type
    console.log(props)
    let fixed: boolean = false
    fixed = checkIfFixed(component.component_code)
    const [position, setPosition] = useState({ x: component.x_pos, y: component.y_pos });
    let scale = getScalingBasedOnType(componentType)
    let xScale = (325*scale).toString()+'px'
    let yScale = (325*scale).toString()+'px'
    let xScaleMobile = (144*scale).toString()+'px'
    let yScaleMobile = (144*scale).toString()+'px'
    let bounds = {left: -250-50*scale, top: -250-50*scale, right: 850-250*scale, bottom: 850-250*scale}
    let mobileBounds = {left: -111-22*scale, top: -111-22*scale, right: 377-111*scale, bottom: 377-111*scale}
    if(movable.includes(componentType)){
      bounds = {left: 0, top: 0, right: 0, bottom: 0}
    }
    const trackPos = (data) => {
      setPosition({ x: data.x, y: data.y });
      component.x_pos = data.x
      component.y_pos = data.y
   };
   return(
    checkNotMobile(screenWidth)?
    <Draggable onDrag={(e, data) => trackPos(data)} bounds={fixed?'parent':bounds} defaultPosition={{x: component.x_pos, y: component.y_pos}}>
        <img className={`br-draggable-image`} style={fixed?{width: '750px', height: '750px'}:{width: xScale, height: yScale}} src={componentImagesBaseURI+component.component_code+'.png'}/>
    </Draggable>
    :
    <Draggable onDrag={(e, data) => trackPos(data)} bounds={fixed?'parent':mobileBounds} defaultPosition={{x: component.x_pos, y: component.y_pos}}>
        <img className={`br-draggable-image`} style={fixed?{width: '333px', height: '333px'}:{width: xScaleMobile, height: yScaleMobile}} src={componentImagesBaseURI+component.component_code+'.png'}/>
    </Draggable>
   )
  }

  /**
   * 
   * General Functions
   * 
   */

  const saveImage = async() => {
    const web3 = new Web3(contractWriteProviders);
    let componentPayloadList: ComponentPayload[] = []
    // loop through all components on canvas
    for (let i in dragComponentList){
      if(checkNotMobile(screenWidth)){
        componentPayloadList.push({
          component_code: dragComponentList[i].component_code,
          x_pos: (Number(dragComponentList[i].x_pos)*2).toString(),
          y_pos: (Number(dragComponentList[i].y_pos)*2).toString(),
          x_pos2: ((Number(dragComponentList[i].x_pos)*2)+650*getScalingBasedOnType(dragComponentList[i].component_type)).toString(),
          y_pos2: ((Number(dragComponentList[i].y_pos)*2)+650*getScalingBasedOnType(dragComponentList[i].component_type)).toString(),
        })
      } else {
        componentPayloadList.push({
          component_code: dragComponentList[i].component_code,
          x_pos: (Number(dragComponentList[i].x_pos)*4.5).toString(),
          y_pos: (Number(dragComponentList[i].y_pos)*4.5).toString(),
          x_pos2: ((Number(dragComponentList[i].x_pos)*4.5)+650*getScalingBasedOnType(dragComponentList[i].component_type)).toString(),
          y_pos2: ((Number(dragComponentList[i].y_pos)*4.5)+650*getScalingBasedOnType(dragComponentList[i].component_type)).toString(),
        })
      }

    }
    // create boomroom payload for post request
    let roomInfoToSend: BoomRoomPayload = {
        token_id: decorateSelection.id,
        room_size: decorateSelection.attributes[0].value,
        component_list: componentPayloadList
    }
    // let msg: string  = JSON.stringify(roomInfoToSend)
    // let prefix = "\x19Ethereum Signed Message:\n" + msg.length
    // let msgHash: string = web3.utils.sha3(prefix+msg) as string
    // let sigHash: string = await web3.eth.sign(msgHash as string, account);
    // let sigInfoToSend: SigPayload = {
    //   signatureHash: sigHash,
    //   address: account
    // }
    let sigInfoToSend: SigPayload = {
      signatureHash: 'sigHash',
      address: 'account'
    }
    // create boom room payload
    let payload: DecorateRoomPayload = {
      sigObject: sigInfoToSend,
      roomObject: roomInfoToSend
    }
    await toast.promise(
      axios.post('https://api.boomersquad.io/boomroom/image_decoration', payload, {mode:'cors'}),
      {
        pending: 'Decorating your Boom Room...',
        success: {
          render(){
            return <div>Success! Now refreshing, please wait a up to 30 minutes for new image.</div>
          },
          // other options
          icon: "🟢",
        },
        error: 'Failed to Decorate.'
      }
  ).then(() => setTimeout((() => window.location.reload()), 7000))
  // .catch((e) => {console.log('something went wrong :(', e); return})
    // axios.post('https://api.boomersquad.io/boomroom/image_decoration', payload, {mode:'cors'}).then((res: any) => console.log(res))
    // axios.post('http://localhost:3001/boomroom/image_decoration', payload).then((res: any) => console.log(res))

  }

  const addComponent = (component: Comp) => {
    let checkAlreadyAdded = false
    for(let i in dragComponentList){
      if (dragComponentList[i].component_type === component.component_type){
        checkAlreadyAdded = true
      }
    }
    if(!checkAlreadyAdded){
      setDragComponentList(dragComponentList.concat([{
        component_code: component.component_code,
        component_type: component.component_type,
        x_pos: 0,
        y_pos: 0,
      }]))
    }
  }

  const removeComponent = (compToRemove: Comp) => {
    let newList = [] as any[]
    for(let i in dragComponentList){
      if(dragComponentList[i].component_type !== compToRemove.component_type){
        newList.push(dragComponentList[i])
      }
    }
    setDragComponentList(newList)
  }

  useEffect(() => {
    let isApiSubscribed = true;
    let getDec = async() => {
      if(isApiSubscribed){
      let dedede = await getBoomRoomPinnedComponents(decorateSelection)
      setDecorateComponents(dedede)
      setCompLoading(false)
      }
    }
    getDec()
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  },[decorateSelection])

    // get cosmetic metadata
    useEffect(() => {
      let isApiSubscribed = true;
      if(isApiSubscribed){
        fetchMetadata(account, contractAddresses.cosmetic).then((fullCosmetics)=>{
            setCosmeticMetadataList(fullCosmetics)
        })
      }
      return () => {
        // cancel the subscription
        isApiSubscribed = false;
      };
    },[account])

    //Call initTable to ultimately set the state or uriList
    useEffect(() => {
      let isApiSubscribed = true;

      if(isApiSubscribed){
        fetchMetadata(account, contractAddresses.boom_room).then((fullBoomRooms)=>{
            setBoomRoomMetadataList(fullBoomRooms)
            setRoomsLoading(false)
        })
      }
      return () => {
        // cancel the subscription
        isApiSubscribed = false;
      };
    },[account])


  return (

      // // check if user on mobile
      checkNotMobile(screenWidth)?
      <>
      <ToastContainer position="top-center"/>
      <YoutubeVideo videoURL="https://www.youtube.com/embed/f1pLg9DWM7Y" />
      <div className='neon-text neon-pink br-page-title'>Room Decorator</div>
      <div className='neon-line neon-pink'></div>
      {
        mode==='SelectRoom'?
        <>
          <div className='br-subtitle'>Select Room Type</div>
          <Row justify='center' className='br-roomconstruction-sortrow'>
            <div className={`neon-button-simple neon-pink bigger-on-hover br-construction-select-buttons ${activeSort==='All'?'active': ''}`} onClick={() => setActiveSort('All')}>All</div>
            <div className={`neon-button-simple neon-pink bigger-on-hover br-construction-select-buttons ${activeSort==='Single'?'active': ''}`} onClick={() => setActiveSort('Single')}>Single</div>
            <div className={`neon-button-simple neon-pink bigger-on-hover br-construction-select-buttons ${activeSort==='Studio'?'active': ''}`} onClick={() => setActiveSort('Studio')}>Studio</div>
            <div className={`neon-button-simple neon-pink bigger-on-hover br-construction-select-buttons ${activeSort==='Double'?'active': ''}`} onClick={() => setActiveSort('Double')}>Double</div>
            <div className={`neon-button-simple neon-pink bigger-on-hover br-construction-select-buttons ${activeSort==='Loft'?'active': ''}`} onClick={() => setActiveSort('Loft')}>Loft</div>
            <div className={`neon-button-simple neon-pink bigger-on-hover br-construction-select-buttons ${activeSort==='Penthouse'?'active': ''}`} onClick={() => setActiveSort('Penthouse')}>Penthouse</div>
          </Row>
          {
              roomsLoading?
              <div className='mobile-br-construction-spinner-box flex-and-center'>
                <Spinner loading={true} size={100}/>
              </div>
              :
              <>
                <BoomRoomList boomroomlist={boomRoomMetadataList}/>
              </>
            }
        </>

        :
        <>
        </>
      }
      {
        mode === 'RoomDecorate'?
        <>
          <Row justify='center'>
            <div className='flex-and-center'>
              <div className='flex-and-center flex-column'>
                  <div className='br-subtitle'>{decorateSelection.name}</div>
                  <div className='neon-border-pink'>
                  <div className='br-roomdecorator-roomimage'>
                      <DraggableComponentList componentList={dragComponentList} />
                      <img className='width-100 position-relative' src={webStorage + '/boomrooms/' + decorateSelection.attributes[0].value+'.png'}/>
                      
                  </div>
              </div>
              </div>
              <div className='br-roomdecorator-roomcomponents'>
                {
                  compLoading?
                  <>
                    <Spinner loading={true} size={100} />
                  </>
                  :
                  <>
                    <ComponentImageList componentList={decorateComponents} />
                  </>
                }
              </div>
            </div>
          </Row>

          <Row justify='center' style={{marginTop: '100px'}}>
            <div className='neon-button-simple neon-green width-250 bigger-on-hover neon-hover-green move-forward' onClick={saveImage}>
              Save Image
            </div>
            <div className='neon-button-simple neon-yellow width-250 bigger-on-hover neon-hover-yellow move-forward' onClick={() => resetAll()}>
              Reset
            </div>
            <div className='neon-button-simple neon-red width-250 bigger-on-hover neon-hover-red move-forward' onClick={() => {setDragComponentList([] as TempComp[]); setMode('SelectRoom'); setDecorateSelection({} as any); resetAll()}}>
              Back
            </div>
          </Row>
          <Row justify='center' style={{marginTop: '30px'}}>
            <CosmeticList cosmeticList={selectUpdatedCosmeticList(decorateSelection.attributes[0].value)} />
          </Row>
        </>
        :
        <>
        </>
      }
        {
          mode === 'CosmeticAdd'?
          <>
            <div className={`neon-button-simple margin-20 neon-red neon-hover-red bigger-on-hover`} onClick={() => setMode('RoomDecorate')}>Back</div>
            <Row justify='center'>
              <AddCosmeticList cosmeticList={cosmeticMetadataList} />
            </Row>
          </>
          :
          <>
          </>
        }
      </>

      // mobile
      :
      <>
      <ToastContainer position="top-center"/>
      <YoutubeVideo videoURL="https://www.youtube.com/embed/f1pLg9DWM7Y" />
      <div className='neon-text neon-pink mobile-br-page-title'>Room Decorator</div>
      <div className='neon-line neon-pink'></div>
      {
        mode==='SelectRoom'?
        <>
          <div className='mobile-br-subtitle'>Select Room Type</div>
          <Row justify='center' className='br-roomconstruction-sortrow'>
            <div className={`neon-button-simple neon-pink bigger-on-hover br-construction-select-buttons ${activeSort==='All'?'active': ''}`} onClick={() => setActiveSort('All')}>All</div>
            <div className={`neon-button-simple neon-pink bigger-on-hover br-construction-select-buttons ${activeSort==='Single'?'active': ''}`} onClick={() => setActiveSort('Single')}>Single</div>
            <div className={`neon-button-simple neon-pink bigger-on-hover br-construction-select-buttons ${activeSort==='Studio'?'active': ''}`} onClick={() => setActiveSort('Studio')}>Studio</div>
            <div className={`neon-button-simple neon-pink bigger-on-hover br-construction-select-buttons ${activeSort==='Double'?'active': ''}`} onClick={() => setActiveSort('Double')}>Double</div>
            <div className={`neon-button-simple neon-pink bigger-on-hover br-construction-select-buttons ${activeSort==='Loft'?'active': ''}`} onClick={() => setActiveSort('Loft')}>Loft</div>
            <div className={`neon-button-simple neon-pink bigger-on-hover br-construction-select-buttons ${activeSort==='Penthouse'?'active': ''}`} onClick={() => setActiveSort('Penthouse')}>Penthouse</div>
          </Row>
          {
              roomsLoading?
              <div className='mobile-br-construction-spinner-box flex-and-center'>
                <Spinner loading={true} size={100}/>
              </div>
              :
              <>
                <BoomRoomList boomroomlist={boomRoomMetadataList}/>
              </>
            }
        </>

        :
        <>
        </>
      }
      {
        mode === 'RoomDecorate'?
        <>
            <div className='flex-and-center flex-column'>
            <Row justify='center' style={{marginTop: '10px'}}>
            <div className='neon-button-simple neon-red width-250 bigger-on-hover neon-hover-red move-forward' onClick={() => {setDragComponentList([] as TempComp[]); setMode('SelectRoom'); setDecorateSelection({} as any); resetAll()}}>
              Back
            </div>
            <div className='neon-button-simple neon-yellow width-250 bigger-on-hover neon-hover-yellow move-forward' onClick={() => resetAll()}>
              Reset
            </div>
            <div className='neon-button-simple neon-green width-250 bigger-on-hover neon-hover-green move-forward' onClick={saveImage}>
              Save Image
            </div>
            
          </Row>
                  <div className='mobile-br-subtitle'>{decorateSelection.name}</div>
                  <div className='neon-border-pink'>
                  <div className='mobile-br-roomdecorator-roomimage'>
                      <DraggableComponentList componentList={dragComponentList} />
                      <img className='width-100 position-relative' src={webStorage + '/boomrooms/' + decorateSelection.attributes[0].value+'.png'}/>
                      
                  </div>
          
              </div>
              <div className='mobile-br-roomdecorator-roomcomponents'>
                {
                  compLoading?
                  <>
                    <Spinner loading={true} size={100} />
                  </>
                  :
                  <>
                    <ComponentImageList componentList={decorateComponents} />
                  </>
                }
              </div>
            <Row justify='center' style={{marginTop: '30px'}}>
              <CosmeticList cosmeticList={selectUpdatedCosmeticList(decorateSelection.attributes[0].value)} />
            </Row>
            </div>
        </>
        :
        <>
        </>
      }
      {
        mode === 'CosmeticAdd'?
        <>
          <div className={`neon-button-simple margin-20 neon-red neon-hover-red bigger-on-hover`} onClick={() => setMode('RoomDecorate')}>Back</div>
          <Row justify='center'>
            <AddCosmeticList cosmeticList={cosmeticMetadataList} />
          </Row>
        </>
        :
        <>
        </>
      }
      </>

  )
}

export default BoomRoomDecorator