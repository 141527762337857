import { useContext } from 'react';
import ConnectedContext from '../../context/connectedcontext';
import ModalVisibilityContext from '../../context/modalvisiblitycontext';
import Web3Context from '../../context/web3context';
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
// import Web3ProviderContext from '../../context/web3ProviderContext';
import Web3ContractReadWriteContext from '../../context/web3ContractReadWriteContext';
import { getScratchBalance } from '../utils/scratch-functions';
import { providers } from './providers';
import { webStorage } from '../../config';


function WalletConnectButton() {

    // //web 3 provider
    // const {web3Provider, setWeb3Provider} = useContext(Web3ProviderContext)

    //set up for web3 and account context
    const { account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance } = useContext(Web3Context)

    //set up for connected logic context
    const { connected, setConnected } = useContext(ConnectedContext)

    //set up for Modal Visibility Context
    const { modalVisibility, setModalVisibility } = useContext(ModalVisibilityContext)

    // set up contract read and write providers
    const { contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders } = useContext(Web3ContractReadWriteContext)


    //called when pressing on the metamask button inside the connect modal
    async function Connect() {


        let provider: any;
        provider = new WalletConnectProvider({
            chainId: 25,
            rpc: {
                25: "https://evm.cronos.org/", // cronos mainet
            },
        });
        console.log(provider)
        //  Enable session (triggers QR Code modal)
        await provider.enable();

        //there is an issue with typescript typechecking here, to bypass it we need to typecast provider as <any>

        const web3 = new Web3(provider);

        // set contract read and write
        setContractReadProviders(providers.readProvider || provider || "ws://localhost:8545")
        setContractWriteProviders(provider || "ws://localhost:8545")


        //set and propagate web3 through the entire app
        //    setWeb3(web3temp)
        //    console.log('web3')
        //    console.log(web3)

        //ask for access from wallet connect
        web3.eth.getAccounts().then(function (accounts) {
            const account = accounts[0]

            //set and propagate account
            setAccount(accounts[0])
            console.log(accounts[0])

            web3.eth.getBalance(accounts[0], function (err, result) {
                if (err) {
                    console.log(err)
                } else {
                    console.log(web3.utils.fromWei(result, "ether") + " ETH")
                    setBalance(web3.utils.fromWei(result, "ether"))
                }
            })
            web3.eth.getChainId().then(function (result: number) {
                setChainID(result)
                console.log(result)

            }).catch(function (error) {
                console.log(error)
                setChainID(25)
            })
            getScratchBalance(provider, accounts[0]).then(function (scratchResult) {
                setScratchBalance(scratchResult as string)
                console.log('Scratch balance:', scratchResult)

            }).then(function (anything) {
                setConnected(true)
                setModalVisibility(!modalVisibility)
                localStorage.setItem('isWalletConnected', 'true')
            })
        });
    }

    return (
        <div className='provider-style' onClick={Connect}>Wallet Connect<img src={webStorage + '/navbar/walletconnectlogo.png'} alt="walletconnectlogo" /></div>
    )
}
export default WalletConnectButton