import { useContext } from 'react';
import ScreenWidthContext from '../context/screenwidthcontext';
import '../styles.css'
import './pawnshop.css'
import checkNotMobile from '../components/logic/mobile';
import { Link } from 'react-router-dom';
import { webStorage } from '../config';

export const PawnShop = () => {

    //Screen Width context
    const { screenWidth, setScreenWidth } = useContext(ScreenWidthContext)

    return (
        checkNotMobile(screenWidth) ?
            <>
                <div className="container2"><img className='banner' src={webStorage + '/pawnshop/pawnshopbanner.png'} alt="myboomersbanner" /></div>
                <div className='flex-and-center flex-column pawnshop-background' style={{ gap: '60px', margin: '80px 0px' }}>
                    <a href='/swap'>
                        <div className='pawnshop-purchase-scratch-button flex-and-center flex-column shadow-button'>
                            <div className='pawnshop-scratch-purchase'>Purchase <img style={{ width: '100px' }} src={webStorage + '/boomrooms/SCRATCH.svg'} /> Here!</div>
                        </div>
                    </a>
                    <a href='/boomerbingo' className='flex-and-center'>
                        <img className='pawnshop-menu-button shadow-button' src={webStorage + '/pawnshop/bingobanner.png'} />
                    </a>
                </div>
            </>


            :
            <>
                <div className='centered-x-and-y'><img className='banner' src={webStorage + '/pawnshop/pawnshopbanner.png'} alt="foo" /></div>
                <div className='flex-and-center flex-column pawnshop-background' style={{ gap: '40px', margin: '50px 0px' }}>
                    <a href='/swap'>
                        <div className='mobile-pawnshop-purchase-scratch-button flex-and-center flex-column shadow-button'>
                            <div>Purchase <img style={{ width: '50px' }} src={webStorage + '/boomrooms/SCRATCH.svg'} /> Here!</div>
                        </div>
                    </a>
                    <a href='/boomerbingo' >
                        <img className='mobile-pawnshop-menu-button shadow-button' src={webStorage + '/pawnshop/bingobanner.png'} />
                    </a>
                </div>
            </>
    )
}