import React from 'react';

const ModalVisibilityContext = React.createContext({
    modalVisibility: false,
    setModalVisibility: (modalVisibility: boolean) => {}
  })

export default ModalVisibilityContext



/*
import React from 'react';

const ModalVisibilityContext = React.createContext({
    modalVisibility: false,
    setModalVisibility: (modalVisibility: boolean) => {!modalVisibility}
  })

export default ModalVisibilityContext
*/