
import { useState, useContext, useEffect, useCallback } from 'react';
import ConnectedContext from '../../context/connectedcontext';
import Web3Context from '../../context/web3context';
import toContract from '../../types/truffle_contracts';

import Web3 from 'web3';
import { Row, InputNumber } from 'antd';
import ScreenWidthContext from '../../context/screenwidthcontext';

import Spinner from '../../components/spinner'
import connectedAndGoodChain from '../../components/logic/connectedandgoodchain';
import Web3ContractReadWriteContext from '../../context/web3ContractReadWriteContext';
import { MovePageDownHeader } from '../../components/movepagedownheader';

import AristoDogHouses from '../../contracts/AristoDogHouses.json'

import '../launchpad.css'
import '../launchpadmobile.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { webStorage } from '../../config';


function ArtisanDoghouse() {

    // Get web3 and the account (metamask public key) to place it on our button text once user is CONNECTED
    const {account, setAccount, balance, setBalance, chainID, setChainID} = useContext(Web3Context) //ommiting web3 and creating it locally because of having to set up contract
    // context to verify if the user WANTS to be connected ot the site, IE if they have clicked the connect button yet, again we cant DISCONNECT the user's metamask, only give the illusion of NOT being connected
    const {connected, setConnected} = useContext(ConnectedContext)
    // Screen Width context
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    // contract read and write providers context
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    
    const contractRead = toContract(AristoDogHouses)
    const contractWrite = toContract(AristoDogHouses)


    // set up contract
    if(contractWriteProviders){
        const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
        contractRead.setProvider(contractReadProviders)
        contractWrite.setProvider(contractWriteProviders)
    }

    // hooks for use throughout the mint page
    const [currentSupply, setCurrentSupply] = useState('')
    const [amountToMint, setAmountToMint] = useState(1)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)

    const [currentAllowedMint, setCurrentAllowedMint] = useState('')
    const [freeClaimAmount, setFreeClaimAmount] = useState('')
    const [whitelisted, setWhitelisted] = useState(false)

    const [mode, setMode] = useState('select')
    



    // 1000000000000000000 = 1 cro

    /**
     * 
     * Skeleton Variables
     * 
     */

    const description: string = `
    The DogHouses are the key to receive a portion of the royalties generated by the AristoSwap platform. 50% of the profits generated by raffles, NFT swap, auctions and more to come in our new roadmap will be shared before the end of the year.

    There will be a total of 3.333 Dog Houses, and each one of them will allow to stake up to NFTs in them, including AristoDogs, Gen3 NFTs or partnered project's NFTs. 
    
    Finally there will be 3 kinds of houses to be minted: The Doghouse, the Townhouse and the Mansions. Each one of them will earn a different amount of points, and ergo, it will be entitled more royalties.
    `

    const maxSupply ='3333'
    const mintPrice = '275000000000000000000'
    const wlMintPrice = '275000000000000000000'
    const pathVar = '/artisandoghouse'
    const discordLink = 'https://discord.gg/WCJDYBJwPc'
    const twitterLink = 'https://twitter.com/AristoDogNFT'
    const websiteLink = 'http://www.aristoswap.app'
    const instaLink = ''
    const mediumLink = 'https://aristodogs.medium.com/'

    const MintDescription = () => {
        return(
            <>
                <div className='lp-mintdescription-row'>{'Mint Tiers: WL Mint, Public Mint'}</div>
                <div className='lp-mintdescription-row'>{'WL Mint Price: 275 CRO'}</div>
                <div className='lp-mintdescription-row'>{'Public Mint Price: 275 CRO'}</div>
                <div className='lp-mintdescription-row'>{'Public & WL Mint Date: December 18th 7pm UTC'}</div>
                <div className='lp-mintdescription-row'>{'Max Supply: ' + maxSupply}</div>
                <div className='lp-mintdescription-row'>{'Max mint per wallet: No Limit'}</div>
            </>
        )
    }

    const Socials = () => {
        return(
            <Row>
                <a href={discordLink} target='_blank'><img className={`${screenWidth > 1279?'lp-socials-logo bigger-on-hover':'mobile-lp-socials-logo'}`} src={webStorage + '/navbar/discordlogo.png'} alt="foo" /></a>
                <a href={twitterLink} target="_blank"><img className={`${screenWidth > 1279?'lp-socials-logo bigger-on-hover':'mobile-lp-socials-logo'}`} src={webStorage + '/navbar/twitterlogo.png'} alt="foo" /></a>
                <a href={websiteLink} target="_blank"><img className={`${screenWidth > 1279?'lp-socials-logo bigger-on-hover':'mobile-lp-socials-logo'}`} src={webStorage + '/navbar/websitelogo.png'} alt="foo" /></a>
            </Row>
        )
    }

    /**
     * 
     * Smart Contract Functions
     * 
     */

    const onWLMint = async() => {
        // TODO mint function smart contract
        setLoading2(true)
        const web3 = new Web3(contractWriteProviders)
        let cfContract = await contractWrite.deployed()
        if(!whitelisted){
            toast.error('Sorry, you are not on the Whitelist!', {autoClose: 5000});
            setLoading2(false)
            return
        }

        await toast.promise(
            cfContract.whitelistMint(amountToMint, {from: account, value: (BigInt(amountToMint)*BigInt(wlMintPrice)).toString()}),
            {
              pending: 'WL Minting...',
              success: {
                render(){
                  return <div>Success!</div>
                },
                // other options
                icon: "🟢",
              },
              error: 'Failed to mint.'
            }
        ).catch(()=> setLoading2(false))

        setLoading2(false)
        updateCurSup()

        // TODO refresh balance on successful mint
        web3.eth.getBalance(account, function(err, result) {
            if (err) {
                console.log(err)
            } else {
                console.log(web3.utils.fromWei(result, "ether") + " ETH")
                setBalance(web3.utils.fromWei(result, "ether"))
            }
        })
    }

    const onPublicMint = async() => {
        // TODO mint function smart contract
        setLoading3(true)
        const web3 = new Web3(contractWriteProviders)
        let cfContract = await contractWrite.deployed()

        await toast.promise(
            cfContract.mint(amountToMint, {from: account, value: (BigInt(amountToMint)*BigInt(mintPrice)).toString()}),
            {
              pending: 'Public Minting...',
              success: {
                render(){
                  return <div>Success!</div>
                },
                // other options
                icon: "🟢",
              },
              error: 'Failed to mint.'
            }
        ).catch(()=> setLoading3(false))

        setLoading3(false)
        updateCurSup()

        // TODO refresh balance on successful mint
        web3.eth.getBalance(account, function(err, result) {
            if (err) {
                console.log(err)
            } else {
                console.log(web3.utils.fromWei(result, "ether") + " ETH")
                setBalance(web3.utils.fromWei(result, "ether"))
            }
        })
    }

    // update the value in the input number mint box
    const onChange = (value: number) => {
        setAmountToMint(value)
    }

    // update current supply
    const updateCurSup = async() => {

        // TODO get new current supply from contract
        let cfContract = await contractWrite.deployed()

        let curSup = await cfContract.totalSupply()

        setCurrentSupply(curSup.toString())
    }

    // // get WL allocation
    // const getCurrentAllowed = async() => {

    //     // This was left blank because WL users had unlimited mints

    //     // TODO get allowed amount of NFTs that can be minted from smart contract using user 0x address as parameter
    // }

    // const getFreeMintClaims = async() => {
    //     let cfContract = await contractRead.deployed()

    //     let curFree = await cfContract.checkFreeClaim(account)
    //     setFreeClaimAmount(curFree.toString())
    // }

    const checkOnWL = async() => {
        let cfContract = await contractRead.deployed()

        let onWL = await cfContract._whitelistClaim(account)
        setWhitelisted(onWL)
    }

    // load info on user web3 connections
    useEffect(() => {
        const load = async() => {

            // getCurrentAllowed()
            updateCurSup()
            // getFreeMintClaims()
            checkOnWL()
        }

        load()
    },[connected])


    return (

    screenWidth > 1279?
        <div>
            <ToastContainer position="top-center"/>
            <div className='centered-x-and-y'><img className='banner' src={webStorage +'/launchpad' + pathVar + '/BANNER.png'} alt="foo"/></div>
            <div className='lp-nftbanner-line'/>
            {/* <Row justify='center'>
                <div className='lp-nftbanner'>
                    <img className='lp-nft' src={webStorage +'/launchpad/cronosfossils/NFT1.png'} alt="foo"/>
                    <img className='lp-nft' src={webStorage +'/launchpad/cronosfossils/NFT2.png'} alt="foo"/>
                    <img className='lp-nft' src={webStorage +'/launchpad/cronosfossils/NFT3.png'} alt="foo"/>
                </div>
            </Row> */}
            <Row justify='center'>
                <div className='lp-description'>
                    {description}
                </div>
            </Row>
            <Row justify='center'>
                <div className='lp-socials flex-and-center flex-column'>
                    Socials
                    <Socials/>
                </div>
            </Row>
            <Row justify='center'>
                <div className='lp-mintrow'>
                    <div>
                        <img className='lp-mintgif' src={webStorage + '/launchpad' + pathVar + '/MINTGIF.gif'} alt="chow time"/>
                    </div>
                    <div className='lp-mintdescription'>
                        <MintDescription/>
                    </div>
                </div>
            </Row>
            {/* <Row justify='center'>
                <img className='lp-soldout' src={webStorage + '/stickers/soldout.png'}/>
            </Row> */}
            {connectedAndGoodChain(connected, chainID)?
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast flex-and-center flex-column'>
                        <div className='lp-mintdescription-row'>{'Current Supply: '}{currentSupply}{' / '+maxSupply}</div>
                        <div className='lp-mintdescription-row'>{'Whitelisted?: '}{whitelisted?'Yes!':'No'}</div>
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-button lp-blue bigger-on-hover text-shadow' onClick={onWLMint}>
                    <Spinner loading={loading2}/>
                    {loading2?'':'WL Mint'}
                    </div>
                    <div className='lp-button lp-pink bigger-on-hover text-shadow' onClick={onPublicMint}>
                    <Spinner loading={loading3}/>
                    {loading3?'':'Public Mint'}
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>{'Amount to Mint: '}</div><InputNumber className='lp-mint-inputnumber' size="large" min={1} max={10} defaultValue={1} onChange={onChange}/>
                </Row>
            </>
            :
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>
                        <div className='lp-mintdescription-connectwallet'>{'Connect Wallet To Mint!'}</div>
                    </div>
                </Row>
            </>}
            {/* <Row justify='center'>
                <img className='lp-roadmap' src={webStorage +'/launchpad' + pathVar + '/ROADMAP.jpg'} alt="foo"/>
            </Row> */}
        </div>

        :
//MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================
        <div>
            <ToastContainer position="top-center"/>
            <div className='centered-x-and-y'><img className='banner' src={webStorage +'/launchpad' + pathVar + '/BANNER.png'} alt="foo"/></div>
            <div className='mobile-lp-nftbanner-line'/>
            {/* <Row justify='center'>
                <div className='mobile-lp-nftbanner'>
                    <img className='mobile-lp-nft' src={webStorage +'/launchpad/cronosfossils/NFT1.png'} alt="foo"/>
                    <img className='mobile-lp-nft' src={webStorage +'/launchpad/cronosfossils/NFT2.png'} alt="foo"/>
                    <img className='mobile-lp-nft' src={webStorage +'/launchpad/cronosfossils/NFT3.png'} alt="foo"/>
                </div>
            </Row> */}
            <Row justify='center'>
                <div className='mobile-lp-description'>
                    {description}
                </div>
            </Row>
            <Row justify='center'>
                <div className='mobile-lp-socials flex-and-center flex-column'>
                    Socials
                    <Socials/>
                </div>
            </Row>
            <Row justify='center'>
                <div className='mobile-lp-mintrow flex-and-center flex-column'>
                    <div>
                        <img className='mobile-lp-mintgif' src={webStorage + '/launchpad' + pathVar + '/MINTGIF.gif'} alt="chow time"/>
                    </div>
                    <div className='mobile-lp-mintdescription'>
                        <MintDescription/>
                    </div>
                </div>
            </Row>
            {/* <Row justify='center'>
                <img className='mobile-lp-soldout' src={webStorage + '/stickers/soldout.png'}/>
            </Row> */}
            {connectedAndGoodChain(connected, chainID)?
            <>
                <Row justify='center' style={{textAlign: 'center'}}>
                    <div className='lp-mintdescription-fast flex-and-center flex-column'>
                        <div className='lp-mintdescription-row'>{'Current Supply: '}{currentSupply}{'/'+maxSupply}</div>
                        <div className='lp-mintdescription-row'>{'Whitelisted?: '}{whitelisted?'Yes!':'No'}</div>
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-button lp-blue bigger-on-hover text-shadow' onClick={onWLMint}>
                    <Spinner loading={loading2}/>
                    {loading2?'':'WL Mint'}
                    </div>
                    <div className='lp-button lp-pink bigger-on-hover text-shadow' onClick={onPublicMint}>
                    <Spinner loading={loading3}/>
                    {loading3?'':'Public Mint'}
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>{'Amount to Mint: '}</div><InputNumber className='lp-mint-inputnumber' size="large" min={1} max={10} defaultValue={1} onChange={onChange}/>
                </Row>
            </>
            :
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>
                        <div className='lp-mintdescription-row'>{'Connect Wallet To Mint!'}</div>
                    </div>
                </Row>
            </>}
            {/* <Row justify='center'>
                <img className='mobile-lp-roadmap' src={webStorage +'/launchpad' + pathVar + '/ROADMAP.jpg'} alt="foo"/>
            </Row> */}
        </div>
    )
}

export default ArtisanDoghouse