import { useState, useContext, useEffect } from 'react';
import Web3Context from './../../context/web3context';
import Web3ContractReadWriteContext from './../../context/web3ContractReadWriteContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { webStorage } from './../../config';
import './../theplayground.css'
import { Pagination } from '../../components/pagination';
import Spinner from '../../components/spinner';
import { Row } from 'antd';
import { PGMNavButtons } from './PGMNavButtons';
import { getPGMApiData } from './PGMApiCalls';
import { simplifyAndDisplayWeiToEth } from './PGMMint';
import { exitPlayground, getCoinPrizePoolData, getLastRoundStartTimeData, getRoundCountData, getViewCanExitData, getViewPlayerRewardsData } from './PGMWeb3Calls';
import CustomIcon from '../../components/CustomIcon/CustomIcon';
import { PGMConfig } from './PGMConfig';

export const PGMPlayground = () => {

    const { account } = useContext(Web3Context);
    const { contractWriteProviders } = useContext(Web3ContractReadWriteContext);

    const onSelectPGT = (tokenID) => {
        if (selectedPGTs.includes(tokenID)) {
            setSelectedPGTs(selectedPGTs.filter((item) => item !== tokenID))
        } else {
            setSelectedPGTs([].concat(selectedPGTs, tokenID))
        }
    }

    const chickenOutSelected = async (tokenIDs: string[]) => {
        // Give toast error if time left is less than 0
        let unixTime = Math.round(+new Date() / 1000);
        let timeSinceLast = unixTime - Number(scoreboard.lastRoundStartTime);
        let good = 1740 - timeSinceLast;
        if (good < 0) {
            toast.error('Time has expired, please wait for the next round', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        await exitPlayground(tokenIDs, account, contractWriteProviders)
    }

    const [currentPage1, setCurrentPage1] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [currentPage3, setCurrentPage3] = useState(1);

    const ShowPogList = (props) => {
        let selectable = props.selectable;
        let list = props.list;
        let currentPage = props.currentPage;
        let setCurrentPage = props.setCurrentPage;
        let type = props.type;
        const itemsPerPage = 18;

        const handlePageChange = (newPage) => {
            setCurrentPage(newPage);
        };

        const getImageUrl = (type) => {
            if (type === 'trash') {
                return webStorage + `/playground/WASTED.png`
            } else if (type === 'inplay') {
                return webStorage + `/playground/GLHF2.png`
            } else if (type === 'chicken') {
                return webStorage + `/playground/CO.png`
            }
        }

        const start = (currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        const paginatedList = list.slice(start, end);

        let listItems = paginatedList.map((item) => {
            return (
                <div
                    className={selectable ? 'bigger-on-hover' : ''}
                    style={{
                        textAlign: 'center',
                        backgroundColor: 'white',
                        overflow: 'hidden',
                        borderRadius: '8px',
                        outline: selectedPGTs.includes(item) ? '3px dashed #7FFF00' : '',
                    }}
                    onClick={() => (selectable ? onSelectPGT(item) : '')}
                >
                    PGT #{item}
                    <div className="theplayground-pog-image-container" style={{ position: 'relative' }}>
                        <img style={{ width: '100%', height: '100%' }} src={getImageUrl(type)} />
                        {selectedPGTs.includes(item) && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 255, 0, 0.5)',
                                }}
                            ></div>
                        )}
                    </div>
                </div>

            )
        })

        const totalPages = Math.ceil(list.length / itemsPerPage);

        return (
            <>
                <div className="playground-poglist-container">
                    {listItems}
                </div>
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </>

        );
    }

    const ScoreboardTimer = ({ initialTime }) => {
        const [timeRemaining, setTimeRemaining] = useState(initialTime);

        useEffect(() => {
            const timer = setInterval(() => {
                initialTime > 0 &&
                    setTimeRemaining((prevTimeRemaining) => prevTimeRemaining - 1);
            }, 1000);

            return () => clearInterval(timer);
        }, []);

        const minutes = Math.floor(timeRemaining / 60 < 0 ? 0 : timeRemaining / 60);
        const seconds = timeRemaining % 60;


        return (
            <div className={'timer'}>
                <div>{minutes.toString().padStart(2, '0')}</div>
                <div className={'separator'}>:</div>
                <div>{seconds.toString().padStart(2, '0')}</div>
            </div>
        );
    };



    const Scoreboard = ({
        prizePots,
        playerRewards,
        tokens,
        roundCount,
        lastRoundStartTime,
        canExit
    }) => {

        const turnSecondsIntoMinutes = (seconds: number) => {

            //get current unix time in seconds
            let unixTime = Math.round(+new Date() / 1000);
            let timeSinceLast = unixTime - seconds;
            let good = 1740 - timeSinceLast;

            return good > 0 ? good : 0;
        }
        // const [timeRemaining, setTimeRemaining] = useState(Number(lastRoundStartTime));

        // floor of tokens remaining divided by 100
        let toTrash = Math.floor(tokens.remaining / 50)
        if (toTrash === 0) {
            toTrash = 1
        }

        return (
            <div className="scoreboard">
                <h2>PLAYGROUND</h2>
                <h3>ROUND {roundCount}</h3>
                <div className="content">
                    <div className="section pot">
                        <h3>CURRENT COIN PRIZE POOLS</h3>
                        <p><div><CustomIcon name="PUUSH" size={25} /></div>{prizePots.PUUSH}</p>
                        <p><div><CustomIcon name="MERY" size={25} /></div>{prizePots.MERY}</p>
                        <p><div><CustomIcon name="RYOSHI" size={25} /></div>{prizePots.RYOSHI}</p>
                    </div>
                    <div className="section sellout">
                        <h3>≈ CHICK'N OUT REWARDS</h3>
                        <p><div><CustomIcon name="PUUSH" size={25} /></div>≈ {playerRewards.PUUSH}</p>
                        <p><div><CustomIcon name="MERY" size={25} /></div>≈ {playerRewards.MERY}</p>
                        <p><div><CustomIcon name="RYOSHI" size={25} /></div>≈ {playerRewards.RYOSHI}</p>
                    </div>
                    <div className="section stage">
                        <h3>STAGE</h3>
                        <p style={{ justifyContent: 'center', backgroundColor: canExit ? "green" : "black" }}>{`Chick'n Out`}</p>
                        <p style={{ justifyContent: 'center', backgroundColor: canExit ? "black" : "green" }}>{'Intermission'}</p>
                    </div>
                    <div className="section summary">
                        <h3>SUMMARY</h3>
                        <p><div>In Play</div>{tokens.remaining}</p>
                        <p><div>Trashed</div>{tokens.destroyed}</p>
                        <p><div>Chick'nd Out</div>{tokens.withdrawn}</p>
                    </div>
                </div>
                <Row justify={'center'} align='middle' style={{ marginTop: '5px' }}>CHICK'N OUT TIME LEFT &nbsp;<ScoreboardTimer initialTime={turnSecondsIntoMinutes(Number(lastRoundStartTime))} /></Row>
                <Row justify={'center'} style={{ marginTop: '5px' }}>≈ LIVES TO TRASH AT ROUND END: {toTrash}</Row>
            </div>
        );
    };

    type ScoreboardObject = {
        prizePots: {
            PUUSH: string;
            MERY: string;
            RYOSHI: string;
        };
        playerRewards: {
            PUUSH: string;
            MERY: string;
            RYOSHI: string;
        };
        chicken: boolean;
        tokens: {
            inPlay: number;
            trashed: number;
            out: number;
        };
        roundCount: string;
        lastRoundStartTime: string;
        canExit: boolean;
    };
    const [loading, setLoading] = useState(true)
    const [playgroundPogs, setPlaygroundPogs] = useState([] as string[])
    const [trashCanPogs, setTrashCanPogs] = useState([] as string[])
    const [chickenPogs, setChickenPogs] = useState([] as string[])
    const [selectedPGTs, setSelectedPGTs] = useState([] as string[])
    const [scoreboard, setScoreboard] = useState({
        prizePots: {
            PUUSH: '0',
            MERY: '0',
            RYOSHI: '0',
        },
        playerRewards: {
            PUUSH: '0',
            MERY: '0',
            RYOSHI: '0',
        },
        chicken: false,
        tokens: {
            inPlay: 0,
            trashed: 0,
            out: 0
        },
        roundCount: '0',
        lastRoundStartTime: '0',
        canExit: false
    } as ScoreboardObject)

    type PGMContractData = {
        prizePoolList: string[];
        roundCount: string;
        lastRoundStartTime: string;
        playerRewards: string[];
        canExit: boolean;
    };

    const loadEverything = async () => {
        const getContractData = async (): Promise<PGMContractData> => {
            const [PUUSHPrizePool, MERYPrizePool, RYOSHIPrizePool] = [
                await getCoinPrizePoolData(contractWriteProviders, PGMConfig.PuushContractAddress),
                await getCoinPrizePoolData(contractWriteProviders, PGMConfig.MeryContractAddress),
                await getCoinPrizePoolData(contractWriteProviders, PGMConfig.RyoshiContractAddress),
            ]
            const roundCount = await getRoundCountData(contractWriteProviders)
            const lastRoundStartTime = await getLastRoundStartTimeData(contractWriteProviders)
            let playerRewards = await getViewPlayerRewardsData(contractWriteProviders)
            let canExit = await getViewCanExitData(contractWriteProviders)
            return ({
                prizePoolList: [PUUSHPrizePool, MERYPrizePool, RYOSHIPrizePool],
                roundCount: roundCount,
                lastRoundStartTime: lastRoundStartTime,
                playerRewards: playerRewards,
                canExit: canExit
            })

        }
        const getPlaygroundPogs = async () => {
            let playgroundData = await getPGMApiData()
            let accountExists: boolean = playgroundData.addresses[account]
            const registered = accountExists ? playgroundData.addresses[account].registered : [];
            const destroyed = accountExists ? playgroundData.addresses[account].destroyed : [];
            const removed = accountExists ? playgroundData.addresses[account].removed : [];
            return ({
                // Remove destroyed pogs and chicken pogs from registered
                registered: registered.filter((number) => !destroyed.includes(number) && !removed.includes(number)),
                destroyed: destroyed,
                removed: removed,
                inPlay: playgroundData.totalRegistered - playgroundData.totalDestroyed - playgroundData.totalRemoved,
                trashed: playgroundData.totalDestroyed,
                out: playgroundData.totalRemoved
            })
        }
        const [contractResult, apiResult] = await Promise.all([
            getContractData(),
            getPlaygroundPogs(),
        ]);

        return { contractResult: contractResult, apiResult: apiResult };
    }

    //useEffect get walletPogs and playgroundPogs
    useEffect(() => {
        setLoading(true)

        const loadData = () => {
            loadEverything()
                .then((result) => {
                    setPlaygroundPogs(result.apiResult.registered);
                    setTrashCanPogs(result.apiResult.destroyed);
                    setChickenPogs(result.apiResult.removed);

                    setScoreboard({
                        ...scoreboard,
                        prizePots: {
                            PUUSH: simplifyAndDisplayWeiToEth(result.contractResult.prizePoolList[0]),
                            MERY: simplifyAndDisplayWeiToEth(result.contractResult.prizePoolList[1]),
                            RYOSHI: simplifyAndDisplayWeiToEth(result.contractResult.prizePoolList[2]),
                        },
                        playerRewards: {
                            PUUSH: simplifyAndDisplayWeiToEth(result.contractResult.playerRewards[0]),
                            MERY: simplifyAndDisplayWeiToEth(result.contractResult.playerRewards[1]),
                            RYOSHI: simplifyAndDisplayWeiToEth(result.contractResult.playerRewards[2]),
                        },
                        tokens: {
                            inPlay: result.apiResult.inPlay,
                            trashed: result.apiResult.trashed,
                            out: result.apiResult.out,
                        },
                        roundCount: result.contractResult.roundCount.toString(),
                        lastRoundStartTime: result.contractResult.lastRoundStartTime.toString(),
                        canExit: result.contractResult.canExit,
                    });
                    console.log('Finished Reloading')
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error while waiting for both functions:', error);
                });
        };
        let intervalId;
        if (contractWriteProviders && account !== '') {
            loadData(); // Call the function initially
            intervalId = setInterval(loadData, 60000); // Call the function every 10 seconds
        };

        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);



    }, [account, contractWriteProviders])

    return (
        <div className='playground-background-cover'>
            <ToastContainer position="top-center" />
            <div className='centered-x-and-y'><img className='banner2' src={webStorage + '/playground/meme/playground_meme_banner.png'} alt='' /></div>
            <Row justify={'center'}>
                <Scoreboard
                    prizePots={scoreboard.prizePots}
                    playerRewards={scoreboard.playerRewards}
                    tokens={{
                        remaining: scoreboard.tokens.inPlay,
                        destroyed: scoreboard.tokens.trashed,
                        withdrawn: scoreboard.tokens.out
                    }}
                    roundCount={scoreboard.roundCount}
                    lastRoundStartTime={scoreboard.lastRoundStartTime}
                    canExit={scoreboard.canExit}
                />
            </Row>
            <PGMNavButtons />
            <div className='theplayground-pogs-container'>
                <div className='theplayground-poglist' style={{ borderColor: 'green' }}>
                    <div className='theplayground-list-title'>Lives In Play - {playgroundPogs.length}</div>
                    <Row justify={'center'} style={{ marginBottom: '20px' }}>
                        {
                            scoreboard.canExit ?
                                <div className='playground-button shadow-button-w' onClick={() => chickenOutSelected(selectedPGTs)}>
                                    Chick'n Out Selected
                                </div>
                                :
                                <div className='playground-button inactive'>
                                    Chick'n Out Selected
                                </div>
                        }

                    </Row>
                    {
                        loading ? <Spinner loading={true} size={250} /> :
                            <ShowPogList list={playgroundPogs} selectable={true} currentPage={currentPage1} setCurrentPage={setCurrentPage1} type={'inplay'} />

                    }

                </div>
                <div className='theplayground-poglist' style={{ borderColor: 'red' }}>
                    <div className='theplayground-list-title'>Trashed - {trashCanPogs.length}</div>
                    {
                        loading ? <Spinner loading={true} size={250} /> :
                            <ShowPogList list={trashCanPogs} selectable={false} currentPage={currentPage2} setCurrentPage={setCurrentPage2} type={'trash'} />

                    }
                </div>
            </div>
            <div className='theplayground-pogs-container'>
                <div className='theplayground-poglist' style={{ borderColor: 'gold' }}>
                    <div className='theplayground-list-title'>Chick'nd Out - {chickenPogs.length}</div>
                    {
                        loading ? <Spinner loading={true} size={250} /> :
                            <ShowPogList list={chickenPogs} selectable={false} currentPage={currentPage3} setCurrentPage={setCurrentPage3} type={'chicken'} />
                    }
                </div>
            </div>
        </div>
    )
}