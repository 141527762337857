import { Modal } from 'antd';
import { useContext, useEffect } from 'react';
import WalletConnectButton from './components/providers/walletconnectbutton';
import MetamaskButton from './components/providers/metamaskbutton';
import { Row } from 'antd';
import {truncateAddress, truncateBalance} from './utils'
import ConnectedContext from './context/connectedcontext'
import ModalVisibilityContext from './context/modalvisiblitycontext';
import WalletExtensionButton from './components/providers/walletextensionbutton';
import {styles} from './styles'
import './index.css'
import "antd/dist/antd.css";
import ScreenWidthContext from './context/screenwidthcontext';
import Web3Context from './context/web3context';
import Web3 from 'web3';
import Web3ContractReadWriteContext from './context/web3ContractReadWriteContext';
import { getScratchBalance } from './components/utils/scratch-functions';
import checkNotMobile from './components/logic/mobile';
import { webStorage } from './config';


function ConnectModal() {

    // Get web3 and the account (metamask public key) to place it on our button text once user is CONNECTED
    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context) //ommiting web3 and creating it locally because of having to set up contract
    // context to verify if the user WANTS to be connected ot the site, IE if they have clicked the connect button yet, again we cant DISCONNECT the user's metamask, only give the illusion of NOT being connected
    const {connected, setConnected} = useContext(ConnectedContext)
    // Screen Width context
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    // contract read and write providers context
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    //set up for Modal Visibility Context
    const {modalVisibility, setModalVisibility} = useContext(ModalVisibilityContext)

    //toggles visibility of modal, called when clicking on "Connect Wallet" button and used when hitting "Close" in the modal or pressing anywhere in the "mask area" aka grey area of modal  
    const toggleVisibility= () =>{
        setModalVisibility(!modalVisibility)
    }
    
    //On clicking disconnect in the modal brought up by clicking on the wallet button while user is connected using metamask
    const disconnect = () => {
        localStorage.clear();
        setConnected(!connected)
        setModalVisibility(!modalVisibility)
    }

    //persist metamask connection in local storage
    useEffect(() => {
        function connect(){
            //initialize web3
            const web3temp = new Web3(Web3.givenProvider || "ws://localhost:8545");
            //ask for access from metamask
            web3temp.eth.requestAccounts().then(function(accounts) {
                const account = accounts[0]
                setContractReadProviders(Web3.givenProvider || "https://mmf-rpc.xstaking.sg/" || "ws://localhost:8545")
                setContractWriteProviders(Web3.givenProvider || "ws://localhost:8545")
                setAccount(account)
                web3temp.eth.getBalance(account, function(err, result) {
                    if (err) {
                        console.log(err)
                    } else {
                        console.log(web3temp.utils.fromWei(result, "ether") + " ETH")
                        setBalance(web3temp.utils.fromWei(result, "ether"))
                    }
                })
                getScratchBalance((Web3.givenProvider || "https://mmf-rpc.xstaking.sg/" || "ws://localhost:8545"), account).then(function(scratchResult){
                    setScratchBalance(scratchResult as string)
                })
                web3temp.eth.getChainId().then(function(result:any){
                    setChainID(result)
                    console.log(result)
                })
    
            }).then(function(anything){
                setConnected(true)
                localStorage.setItem('isWalletConnected', 'true')
            });
    
        }
        
        const connectWalletOnPageLoad = async() => {
            if (localStorage?.getItem('isWalletConnected') === 'true') {
                connect()
            }
        }
        connectWalletOnPageLoad()
    }, [])

    //Use logic to determine which button to show: Either the connect wallet button or the user's metamask public key if the user is already connected
    return (
            !connected?
                <>
                    <div className='header-connectbutton bigger-on-hover' onClick={toggleVisibility}>
                        Connect Wallet
                    </div>
                    <Modal
                        centered
                        visible={modalVisibility}
                        footer={null}
                        width='340px'
                        onCancel={toggleVisibility}
                        bodyStyle={styles.connectModalBody}
                        closable={false}
                        // closeIcon={<img src={webStorage + '/whitex.png'} alt="whitex" height={'20vw'}/>}
                        transitionName=""//Used to remove the transition animations of the modal

                    >
                        <Row justify='center'>
                            <img className={`${screenWidth>1279? 'connect-modal-logo' : 'mobile-connect-modal-logo'}`} src={webStorage + '/navbar/boomersquadlogo2.png'} alt="boomerlogotest"/>
                        </Row>
                        <Row className='connect-modal-title' justify='center'>
                            Connect Wallet
                        </Row>
                        <div className='provider-row'>
                            <MetamaskButton />
                            <WalletExtensionButton />
                            <WalletConnectButton />
                        </div>
                    </Modal>
                </>

            :

            (chainID === 25 || chainID === 338 || chainID ===  1337)?

            <>
            {
                checkNotMobile(screenWidth)?
                <div className='connected-wallet-info'>
                    <div className='balance-row'><img className='connected-wallet-info-cronoslogo' src={webStorage + '/navbar/cronosLogoHR.png'} alt=""/>{truncateBalance(balance, 2)}</div>
                    <div className='balance-row'><img className='connected-wallet-info-cronoslogo' src={webStorage + '/boomrooms/SCRATCH.svg'} alt=""/>{truncateBalance(scratchBalance, 2)}</div>
                    <div className='connected-wallet-info-address' onClick={toggleVisibility}>{truncateAddress(account, 3)}</div>
                </div>
                :
                <div className='mobile-connected-wallet-info'>
                    <div className='flex-and-center flex-column' style={{marginRight: '5px'}}>
                        <div className='mobile-balance-row'><img className='mobile-connected-wallet-info-cronoslogo' src={webStorage + '/navbar/cronosLogoHR.png'} alt=""/>{truncateBalance(balance, 2)}</div>
                        <div className='mobile-balance-row'><img className='mobile-connected-wallet-info-cronoslogo' src={webStorage + '/boomrooms/SCRATCH.svg'} alt=""/>{truncateBalance(scratchBalance, 2)}</div>
                    </div>
                    <div className='connected-wallet-info-address' onClick={toggleVisibility}>{truncateAddress(account, 3)}</div>
                </div>
            }

            <Modal
            
                centered
                visible={modalVisibility}
                closable={false}
                footer={null}
                width={0}
                bodyStyle={{height:'0px'}}
                onCancel={toggleVisibility}
                transitionName=""
                >
                    <Row justify='center' align="middle">
                    <div className='connected-wallet-modal-disconnect bigger-on-hover' onClick={disconnect}>
                        Disconnect
                    </div>
                    </Row>
            </Modal>
        </>

        :

        <>
        <div className='connect-wrong-network' onClick={toggleVisibility}>
            Wrong Network
        </div>
        <Modal
            
            centered
            visible={modalVisibility}
            closable={false}
            footer={null}
            width={0}
            bodyStyle={{height:'0px'}}
            onCancel={toggleVisibility}
            transitionName=""
            >
                <Row justify='center' align="middle">
                <div className='connected-wallet-modal-disconnect' onClick={disconnect}>
                    Disconnect
                </div>
                </Row>
        </Modal>
        </>
    );
}

export default ConnectModal