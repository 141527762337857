type CustomIconProps = {
    name: string;
    size?: number;
    linkUrl?: string;
};

const CustomIcon: React.FC<CustomIconProps> = ({ name, size = 50, linkUrl = '' }) => {

    const getUrl = (name: string) => {
        switch (name) {
            case 'twitter':
                return 'https://upload.wikimedia.org/wikipedia/commons/5/57/X_logo_2023_%28white%29.png';
            case 'telegram':
                return 'https://static-00.iconduck.com/assets.00/telegram-icon-512x512-z8lb0qsx.png';
            case 'discord':
                return 'https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6cc3c481a15a141738_icon_clyde_white_RGB.png';
            case 'copy':
                return 'https://puushdabutton-web-images.s3.amazonaws.com/copy.jpg';
            case 'contract':
                return 'https://puushdabutton-web-images.s3.amazonaws.com/contract.png';
            case 'opennew':
                return 'https://puushdabutton-web-images.s3.amazonaws.com/opennew.png';
            case 'timeWarning':
                return 'https://puushdabutton-web-images.s3.amazonaws.com/time_warning_red.png';
            case 'burn':
                return 'https://puushdabutton-web-images.s3.amazonaws.com/burn.png';
            case 'PUUSH':
                return 'https://cdn-prod.ebisusbay.com/files/dex/images/tokens/puush.webp';
            case 'MERY':
                return 'https://dd.dexscreener.com/ds-data/tokens/cronos/0x3b41b27e74dd366ce27cb389dc7877d4e1516d4d.png';
            case 'RYOSHI':
                return 'https://cdn-prod.ebisusbay.com/img/ryoshi-with-knife/coin-logo.png';
            default:
                return '';
        };
    }

    const style = {
        height: `${size}px`,
        cursor: 'pointer',
        borderRadius: '50%',
    }

    return (
        linkUrl !== '' ? (
            <a href={linkUrl} target="blank" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img className={`CustomIcon`} src={getUrl(name)} style={style} />
            </a>
        ) : // ========================  Fix this  ======================
            <img className={`CustomIcon`} src={getUrl(name)} style={style} />
    );
}
export default CustomIcon;