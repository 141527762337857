import { Row } from "antd"
import { useState, useContext, useEffect } from 'react';
import { BazaarItem, bazaarTestObj } from "./bazaarTestObj"
import './bazaarStyles.css'
import './bazaarStylesM.css'
import checkNotMobile from "../components/logic/mobile";
import ScreenWidthContext from '../context/screenwidthcontext';
import Web3Context from '../context/web3context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { webStorage } from '../config';
import { Link } from 'react-router-dom';

import Web3 from 'web3';
import BazaarContract from '../contracts/Bazaar.json'
import ScratchContract from '../contracts/Scratch.json'
import Web3ContractReadWriteContext from "../context/web3ContractReadWriteContext";
import ConnectedContext from "../context/connectedcontext";
import toContract from "../types/truffle_contracts";

export const Bazaar = () => {


    const {connected, setConnected} = useContext(ConnectedContext)
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)

    const bazWrite = toContract(BazaarContract)
    const scratchWrite = toContract(ScratchContract)


    // set up contract
    if(contractWriteProviders){
        const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
        bazWrite.setProvider(contractWriteProviders)
        scratchWrite.setProvider(contractWriteProviders)
    }


    const [sortedList, setSortedList] = useState([] as BazaarItem[])
    const [sortOption, setSortOption] = useState('Time: Most Recent')
    const [filteredList, setFilteredList] = useState([] as BazaarItem[])
    const [filtersOn, setFiltersOn] = useState(false)

    const BazaarItems = (props) => {
        const arr: BazaarItem[] = props.list
        const items = arr.map((item, index) =>
            <BazaarCard key={index} value={item} />
        )
        return (
            <>
                {items}
            </>
        )
    }

    const SortBox = () => {

        const options = ['Price: Low to High', 'Price: High to Low', 'Time: Most Recent']

        let m = ''
        if(!checkNotMobile(screenWidth)){m = 'm-'}
        return(
            <div className='flex-and-center' style={{gap: '5px'}}>
                <div className={`${m}sort-dropdown small-shadow-button`}>
                    <Row justify='center' align='middle' style={{gap: '12px'}}>Sort by<div className='dropdown-arrow'/></Row>
                    <div id='dropdown1' className="sort-dropdown-content">
                        <div className='sort-dropdown-invisible'/>
                        <div className='sort-dropdown-visible'>
                            <div className='sort-dropdown-item' onClick={()=>setSortOption(options[0])}>{options[0]}</div>
                            <div className='sort-dropdown-item' onClick={()=>setSortOption(options[1])}>{options[1]}</div>
                            <div className='sort-dropdown-item' onClick={()=>setSortOption(options[2])}>{options[2]}</div>
                        </div>
                    </div>
                </div>
                <div className={`${m}sort-option`}>
                    {sortOption}
                </div>
            </div>
        )

    }

    // let keys = []
    const BazaarCard = (props) => {

        const id = props.value.id
        const stock = props.value.stock
        const name = props.value.name
        const price = props.value.price
        const currency = props.value.currency
        const image = props.value.images[0]
        const project = props.value.project
        const type = props.value.type
        const WLIndex = props.value.WLIndex;

        let m = ''
        if(!checkNotMobile(screenWidth)){
            m = 'm-'
        }

        return(
            <>
                <div className={m+"bazaar-item"}>
                    <div className={m+"bazaar-item-image-container"}>
                        <img className={m+'bazaar-item-image'} src={webStorage+ '/bazaar/item_images/' + id + "/" + image + '.png'}/>
                    </div>
                    <div className={m+"bazaar-item-info-container"}>
                        <Link to={`/bazaar/${id}`}><div className={m+'bazaar-item-info-name'}>{name}</div></Link>
                        <div className={m+'bazaar-item-info-brand'}>by {project}</div>
                        <div className={m+'bazaar-item-info-price'}>{price}&nbsp;{currency}</div>
                        {/* <div className={m+'bazaar-item-info-stock'}>{stock} in stock{WLIndex !== ''?' - Whitelist Required':''}</div> */}
                        <div className={m+'bazaar-item-info-type'}>{type}</div>
                    </div>
                </div>
            </>
        )
    }

    const [allP, setAllP] = useState([])
    const [allT, setAllT] = useState([])
    const [allW, setAllW] = useState([])
    const [allC, setAllC] = useState([])
    const [allS, setAllS] = useState([])

    let getFilterOptionByName = {
        'Project': {list: allP, func: setAllP},
        'Type': {list: allT, func: setAllT},
        'Whitelist': {list: allW, func: setAllW},
        'Currency': {list: allC, func: setAllC},
        // 'Stock': {list: allS, func: setAllS}
    }
    // const [doit, setDoit] = useState(true)

    const Filters = (props) => {
        const arr: BazaarItem[] = props.list
        let allProjects = {}
        let allTypes = {}
        let allWl = {}
        let allCurrencies = {}
        let allStocks = {}

        let m = ''
        if(!checkNotMobile(screenWidth)){
            m = 'm-'
        }

        // if not added into filter yet, add it
        // have amount fo each showing in filters

        for(let i in arr){
            allProjects[arr[i].project] = 1 + allProjects[arr[i].project] || 1
            allTypes[arr[i].type] = 1 + allTypes[arr[i].type] || 1
            allCurrencies[arr[i].currency] = 1 + allCurrencies[arr[i].currency] || 1
            if(arr[i].WLIndex !== '0'){
                allWl['Required'] = 1 + allWl['Required'] || 1
            } else {
                allWl['Not Required'] = 1 + allWl['Not Required'] || 1
            }
        }

        
        return (
            <div className={`${m}bazaar-filters`}>
                <Filter name={'Project'} filters={allProjects}/>
                <Filter name={'Type'} filters={allTypes}/>
                <Filter name={'Whitelist'} filters={allWl}/>
                <Filter name={'Currency'} filters={allCurrencies}/>
            </div>
        )
    }

    const clickOnBox = (key, type) => {
        if(!getFilterOptionByName[type].list.includes(key)){
            addToFilter(key, type)
        } else {
            removeFromFilter(key, type)
            var element = document.getElementById(key);
            element.classList.remove('filled');
        }
    }

    const addToFilter = (id, type) => {
            console.log(getFilterOptionByName[type].list)
            let temp = getFilterOptionByName[type].list
            getFilterOptionByName[type].func([].concat(temp, [id]))
            console.log(getFilterOptionByName[type].list)
    }

    const removeFromFilter = (id, type) => {
        const index = getFilterOptionByName[type].list.indexOf(id);
        let result = []
        if (index > -1) { // only splice array when item is found
            result = getFilterOptionByName[type].list.filter((data, idx) => idx !== index );
        }
        getFilterOptionByName[type].func(result)
    }

    const Filter = (props) => {
        const name = props.name;
        const filters = props.filters;
        // const [on, setOn] = useState(true)
        let arr = []
        for (const [key, value] of Object.entries(filters)) {
            arr.push({key: key, value: value})
        }
        const items = arr.map((item, index) =>
            <Filt key={index} value={item} name={name}/>
        )
        return (
            <div className="filter-title-container">
                <div className="filter-title-row">
                    {name}
                </div>
                <div id={`filterRow${name}`} className={`filter-subtitle-container clicked`}>
                    {items}                 
                </div>
            </div>
        )
    }

    const Filt = (props) => {
        const key = props.value.key;
        const value = props.value.value;
        const type = props.name;
        return(
            <div className="filt-row">
                <div>{key}</div>
                <div className="filt-numbox-row">
                    {value}
                    <div id={key} 
                        className={`filt-square-box ${getFilterOptionByName[type].list.includes(key)?'filled':''}`} 
                        onClick={() => clickOnBox(key, type)}
                    />
                </div>
            </div>
        )
    }

    const setupEnv = async() => {
        let baz = await bazWrite.deployed()
        let s = await scratchWrite.deployed()
        await baz.setBazaarWallet(account, {from: account})
        await baz.setTokenContract(s.address, 1, {from: account})
        await baz.setShopMaster(account, true, {from: account})
        await baz.setWL(1, [account], true, {from: account})
        await baz.setActiveState(true, {from: account})
        let item1 = {price: 650, stock: 100, ti: 1, wli: 0}
        let item2 = {price: 150, stock: 1000, ti: 1, wli: 0}
        // let item3 = {price: 3, stock: 33, ti: 0, wli: 0}
        // let item4 = {price: 11, stock: 111, ti: 1, wli: 1}
        await baz.listItem(item1.price, item1.stock, item1.ti, item1.wli, {from: account})
        await baz.listItem(item2.price, item2.stock, item2.ti, item2.wli, {from: account})
        // await baz.listItem(item3.price, item3.stock, item3.ti, item3.wli, {from: account})
        // await baz.listItem(item1.price, item1.stock, item1.ti, item1.wli, {from: account})
        // await baz.listItem(item2.price, item2.stock, item2.ti, item2.wli, {from: account})
        // await baz.listItem(item3.price, item3.stock, item3.ti, item3.wli, {from: account})
        // await baz.listItem(item4.price, item4.stock, item4.ti, item4.wli, {from: account})
    }

    useEffect(() => {
        //hard code price, get stock info when clicking on individual items
    })
    

    useEffect(()=>{

        if(sortOption === 'Time: Most Recent'){
            let temp = []
            temp = Array.from(bazaarTestObj)
            temp.reverse()
            setSortedList(temp)

        }
        if(sortOption === 'Price: Low to High'){
            let temp1 = []
            temp1 = Array.from(bazaarTestObj)
            temp1.sort((a, b) => (a.price > b.price) ? 1 : -1)
            setSortedList(temp1)
        }
        if(sortOption === 'Price: High to Low'){
            let temp2 = []
            temp2 = Array.from(bazaarTestObj)
            temp2.sort((a, b) => (b.price > a.price) ? 1 : -1)
            setSortedList(temp2)
        }

    }, [sortOption])

    useEffect(()=>{
        let allFilterOptions = [allP,allT,allW,allC,allS,]

        let isFiltered = false
        for(let i in allFilterOptions){
            if(allFilterOptions[i].length > 0){
                isFiltered = true;
            }
        }
        if(isFiltered){
            let tempList = [];
            for(let i in sortedList){
                const item = sortedList[i]
                // const stock = item.stock
                const currency = item.currency
                const project = item.project
                const type = item.type
                const WLIndex = item.WLIndex;
                if(!allP.includes(project) && allP.length > 0){
                    continue
                }
                if(!allT.includes(type) && allT.length > 0){
                    continue
                }
                if(((allW.includes('Required') && WLIndex === '') || (allW.includes('Not Required') && WLIndex !== '')) && allW.length < 2){
                    continue
                }
                if(!allC.includes(currency) && allC.length > 0){
                    continue
                }
                // if(((allS.includes('In Stock') && stock === 0) || (allS.includes('Out of Stock') && stock > 0)) && allS.length < 2){
                //     continue
                // }
                tempList.push(item)
            }
            setFilteredList(tempList)
        } else {
            setFilteredList(sortedList)
        }
        
        
    },[allP, allT, allW, allC, allS, sortedList])

    return (
        <div className="bazaar-main">
            <ToastContainer position="top-center"/>
            <img className='banner' style={{width: '100%'}} src={webStorage + '/bazaar/bazaar_temp_banner2.png.png'} alt=""/>
            {/* <div className='flex-and-center flex-column' style={{width: '100%', color: 'black'}}>
                <div>
                    Admin Panel
                </div>
                <button onClick={() => setupEnv()}>Setup Env</button>
            </div> */}
            <div className={checkNotMobile(screenWidth)?'bazaar-top':'mobile-bazaar-top'}>
                <Link to='./inventory'><div className='sort-dropdown small-shadow-button' style={{width: '340px', textAlign: 'center'}}>
                        Ship Items
                </div></Link>
                <div>
                    <SortBox />
                </div>
                {
                    checkNotMobile(screenWidth)?
                    <></>:
                    <>
                        <div className="m-show-filters-button small-shadow-button" onClick={() => setFiltersOn(true)}>
                            Show Filters
                        </div>
                        <div className='mobile-filter-container' style={{left: filtersOn?'0px':'-360px'}}>
                            <div className='mobile-filter-container-x' onClick={() => setFiltersOn(false)}>
                                X
                            </div>
                            <div className="m-bazaar-filter-container">
                                <Filters list={bazaarTestObj}/>
                            </div>
                        </div>
                    </>

                }
            </div>
            <div className='bazaar-layout'>
                {
                    checkNotMobile(screenWidth)?
                    <>
                        <div className="bazaar-filter-container">
                            <Filters list={bazaarTestObj}/>
                        </div>
                    </>:<></>
                }

                <div className="bazaar-items-container">
                    <BazaarItems list={filteredList} />
                </div>
            </div>
        </div>
    )
}