import React, { useState } from "react";
import axios from "axios";
import Spinner from "../components/spinner";

export const NewsletterSubsctiption = () => {

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)

  const subscribeEmail = async () => {
    setLoading(true)
    try {
      const response = await axios.post("https://api.boomersquad.io/newsletter_subscribe", { email: email });
      setMessage(response.data.message);
      setError("");
    } catch (err: any) {
      setError(err.response.data.error);
      setMessage("");
    }
    setLoading(false)
  };

  const unsubscribeEmail = async () => {
    setLoading2(true)
    try {
      const response = await axios.post("https://api.boomersquad.io/newsletter_unsubscribe", { email: email });
      setMessage(response.data.message);
      setError("");
    } catch (err: any) {
      setError(err.response.data.error);
      setMessage("");
    }
    setLoading2(false)
  };

    return(
      <div>
        <div style={{color: 'white', fontFamily: 'nowbold', marginTop: '100px', fontSize: '20px'}}>
          Subscribe to Newsletter
        </div>
        <div className="flex-and-center" style={{width: '100%', gap: '5px'}}>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
            />
            <button type="submit" className="news-sub-button" onClick={() => subscribeEmail()}>{loading?<Spinner size={20} loading={loading} />:'Subscribe'}</button>
            <button type="submit" className="news-sub-button" onClick={() => unsubscribeEmail()}>{loading2?<Spinner size={20} loading={loading2} />:'Unsubscribe'}</button>
            {message && <div style={{ color: "green" }}>{message}</div>}
            {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
      </div>

    )
}