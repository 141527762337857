/**
 * 
 * PROD ENVIRONMENT
 * 
 */

 export const boomroomBaseURI: string = 'https://boomersquad-boomroom-metadata.s3.amazonaws.com/v1.0/'
 export const windowBaseURI: string = 'https://boomersquad-boomroom-components-metadata.s3.amazonaws.com/v1.0/Window/'
 export const doorBaseURI: string = 'https://boomersquad-boomroom-components-metadata.s3.amazonaws.com/v1.0/Door/'
 export const bedBaseURI: string = 'https://boomersquad-boomroom-components-metadata.s3.amazonaws.com/v1.0/Bed/'
 export const buildingBaseURI: string = 'https://boomersquad-boomroom-components-metadata.s3.amazonaws.com/v1.0/Building/'
 export const tableBaseURI: string = 'https://boomersquad-boomroom-components-metadata.s3.amazonaws.com/v1.0/Table/'
 export const deskBaseURI: string = 'https://boomersquad-boomroom-components-metadata.s3.amazonaws.com/v1.0/Desk/'
 export const entertainmentBaseURI: string = 'https://boomersquad-boomroom-components-metadata.s3.amazonaws.com/v1.0/Entertainment/'
 export const couchBaseURI: string = 'https://boomersquad-boomroom-components-metadata.s3.amazonaws.com/v1.0/Couch/'
 export const petBaseURI: string = 'https://boomersquad-boomroom-components-metadata.s3.amazonaws.com/v1.0/Pet/'
 export const activityBaseURI: string = 'https://boomersquad-boomroom-components-metadata.s3.amazonaws.com/v1.0/Activity/'

export const cosmeticImageBaseURI: string = 'https://boomersquad-boomroom-cosmetic-cover-images.s3.amazonaws.com/'
export const componentImagesBaseURI: string = 'https://boomersquad-boomroom-components-images.s3.amazonaws.com/'

export const contractAddresses = {
    boom_room: '0x8Dd9975c78423480a6188F55B0767fbF4478b001',
    activity: '0x8Bff49Fc633e0A3eB879449B67500812B27a97B7',
    bed: '0x995d38004314836E48485D5fE328ebC17046FE68',
    building: '0xc6a1f1034235F3B389bAb28B75719B3475Edf4AD',
    cosmetic: '0x4AA9F792b51954A8903967375e854Ed7EE4bCBf0',
    couch: '0xf27205535932c7490F227F5bED7e5f7212b455bb',
    desk: '0xEa71F7AFA9eEEee5De16ee64b76255E216952511',
    door: '0xAE40d175F2cf9C6777c0E0830E17211c4F5A66D0',
    entertainment: '0x18550Cc44779EA72D6Fe6389D08D8Ab234f754CB',
    pet: '0x48D20305Ad156e44A0f93c3312A876E56c0e9f7E',
    table: '0xb7109F93d780FD3f01eB7EbB0FE9436449F0D8EF',
    window: '0xc5B294B557402f8706aED487e155773756A9c400'
}

/**
 * 
 * TEST ENVIRONMENT 
 * 
 */
// const buildVer = 'BetaBuildv3'

// export const boomroomBaseURI: string = 'https://boomersquad-boomroom-metadata-test.s3.amazonaws.com/'+buildVer+'/'
// export const windowBaseURI: string = 'https://boomersquad-boomroom-components-metadata-test.s3.amazonaws.com/'+buildVer+'/Window/'
// export const doorBaseURI: string = 'https://boomersquad-boomroom-components-metadata-test.s3.amazonaws.com/'+buildVer+'/Door/'
// export const bedBaseURI: string = 'https://boomersquad-boomroom-components-metadata-test.s3.amazonaws.com/'+buildVer+'/Bed/'
// export const buildingBaseURI: string = 'https://boomersquad-boomroom-components-metadata-test.s3.amazonaws.com/'+buildVer+'/Building/'
// export const tableBaseURI: string = 'https://boomersquad-boomroom-components-metadata-test.s3.amazonaws.com/'+buildVer+'/Table/'
// export const deskBaseURI: string = 'https://boomersquad-boomroom-components-metadata-test.s3.amazonaws.com/'+buildVer+'/Desk/'
// export const entertainmentBaseURI: string = 'https://boomersquad-boomroom-components-metadata-test.s3.amazonaws.com/'+buildVer+'/Entertainment/'
// export const couchBaseURI: string = 'https://boomersquad-boomroom-components-metadata-test.s3.amazonaws.com/'+buildVer+'/Couch/'
// export const petBaseURI: string = 'https://boomersquad-boomroom-components-metadata-test.s3.amazonaws.com/'+buildVer+'/Pet/'
// export const activityBaseURI: string = 'https://boomersquad-boomroom-components-metadata-test.s3.amazonaws.com/'+buildVer+'/Activity/'



// export const componentImagesBaseURI: string = 'https://boomersquad-boomroom-components-images.s3.amazonaws.com/'