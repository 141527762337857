
import { useState, useContext, useEffect } from 'react';
import ConnectedContext from './../context/connectedcontext';
import {styles} from './../styles';
import Web3Context from './../context/web3context';
import toContract from './../types/truffle_contracts';
import BoomerSquad from './../contracts/BoomerSquad.json';
import BoomerScratchDistribution from './../contracts/BoomerScratchDistribution.json';
import Web3 from 'web3';
import { Card, Row, Col } from 'antd';
import './myboomers.css'
import ScreenWidthContext from './../context/screenwidthcontext';
import Spinner from './../components/spinner'
import {getTraitRarity, getBoomerRank} from './../utils'
import BoomerDistribution from './../contracts/BoomerDistribution.json';
import { bs_metadata } from './../components/bs_metadata';
import Web3ContractReadWriteContext from './../context/web3ContractReadWriteContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import checkNotMobile from './../components/logic/mobile';
import { webStorage } from './../config';
import { Link } from 'react-router-dom';

function MyBoomers () {

  const royalties: number = 0.09;
  //set object type for getting NFT JSON objects
  interface NFTMetadata {
    name: string;
    description: string;
    image: string;
    dna: string;
    id: number;
    date: number;
    attributes: Array<{trait_type: string, value: string}>;
  }

  //object type for NFT Sale info JSON objects
  interface NFTSaleData {
    isForSale: Boolean;
    tokenId: number;
    seller: string;
  }
    
  //Get web3 and the account (metamask public key) to place it on our button text once user is CONNECTED
  const {account, setAccount, balance, setBalance, chainID, setChainID} = useContext(Web3Context) //ommiting web3 and creating it locally because of having to set up contract

  //this is the context to verify if the user WANTS to be connected ot the site, IE if they have clicked the connect button yet, again we cant DISCONNECT the user's metamask, only give the illusion of NOT being connected
  const {connected, setConnected} = useContext(ConnectedContext)

  //Screen Width context
  const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)

  // contract read and write providers context
  const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)

  const [owedMap, setOwedMap] = useState(new Map())
  const [tokenIDArray, setTokenIDArray] = useState<Array<Number>>([])
  const [totalVDOwed, setTotalVDOwed] = useState('0')
  const [totalSVDOwed, setTotalSVDOwed] = useState('0')

  //list of all NFTs combined with their sale info
  const [fullNFTInfoList, setFullNFTInfoList] = useState<Array<any>>([])
  const [amountOwnedByAccount, setAmountOwnedByAccount] = useState(0)

  // global VD hooks
  const [globalVDDeposited, setGlobalVDDeposited] = useState('')
  const [globalVDWithdrawn, setGlobalVDWithdrawn] = useState('')

  const [globalSVDDeposited, setGlobalSVDDeposited] = useState('')
  const [globalSVDWithdrawn, setGlobalSVDWithdrawn] = useState('')

 
  const contract = toContract(BoomerSquad)
  const contractWrite = toContract(BoomerSquad)

   //set up VD
   const contract4 = toContract(BoomerDistribution)
   const BSDRead = toContract(BoomerScratchDistribution)
   const BSDWrite = toContract(BoomerScratchDistribution)

  //set up contract
  if(contractWriteProviders){
    const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
    contract.setProvider(contractReadProviders)
    contractWrite.setProvider(contractWriteProviders)
    contract4.setProvider(contractWriteProviders)

    BSDRead.setProvider(contractReadProviders)
    BSDWrite.setProvider(contractWriteProviders)
  }
  

 
  

  const multiRelease = async() => {
      const bdContract = await contract4.deployed()
      await toast.promise(
        bdContract.multiRelease(tokenIDArray, account, {from: account}),
      {
        pending: 'Withdrawing from Mattress Fund...',
        success: {
          render(){
            return <div>Success! now refreshing Browser</div>
          },
          // other options
          icon: "🟢",
        },
        error: 'Failed to Withdraw.'
      }
    ).catch((e) => {console.log('something went wrong :(', e); return})
  setTimeout((() => window.location.reload()), 5500)
  }

  const multiReleaseScratch = async() => {
    const bsd = await BSDWrite.deployed()
        await toast.promise(
          bsd.multiRelease(tokenIDArray, account, {from: account}),
        {
          pending: 'Withdrawing from Mattress Fund...',
          success: {
            render(){
              return <div>Success! now refreshing Browser</div>
            },
            // other options
            icon: "🟢",
          },
          error: 'Failed to Withdraw.'
        }
      ).catch((e) => {console.log('something went wrong :(', e); return})
    setTimeout((() => window.location.reload()), 5500)
} 

  const checkVDOwed = async(tokenIDs: Number[]) => {
      const bdContract = await contract4.deployed()
      let owed = await bdContract.mymultiPAYOUT(tokenIDs)
      let owedString = owed.toString()
      return owedString
  }

  //NFT Card Modals for more info on clicking on them
  const [isModalVisible, setIsModalVisible] = useState(false);

  function showMyBoomerModal(token: NFTMetadata){

    setIsModalVisible(true);
    setModalToken(token);

  }

  //used to populate model data when clicking on a card to display sell or cancel sell option
  const [modalToken, setModalToken] = useState<any>({
    name: 'name',
    description: 'description',
    image: 'image',
    id: 0,
    attributes: [
      {trait_type: 'N/A', value: 'N/A'},
      {trait_type: 'N/A', value: 'N/A'},
      {trait_type: 'N/A', value: 'N/A'},
      {trait_type: 'N/A', value: 'N/A'},
      {trait_type: 'N/A', value: 'N/A'},
      {trait_type: 'N/A', value: 'N/A'},
      {trait_type: 'N/A', value: 'N/A'},
      {trait_type: 'N/A', value: 'N/A'},
      {trait_type: 'N/A', value: 'N/A'},
    ]
  })

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  let imagesLoading: number = 0;

  const imageLoadedorError = () => {
      imagesLoading++
  }

  let CronosLogo = () => {
    return(
      <img className='cronoslogo' src={webStorage + '/myboomers/cronosLogo.png'}></img>
    )
  }

  let ScratchLogo = () => {
    return(
      <img className='cronoslogo' src={webStorage + '/myboomers/scratchLogo.png'}></img>
    )
  }

  const VDStats = () => {
    return(
      checkNotMobile(screenWidth)?
      <>
        <Row justify='center'>
          <div className='VDStats-box flex-column'>
            <div className='VDStats-box-title'>
              Global Mattress Fund Stats
            </div>
              <div className='VDStats-box-splitsection'>
                <div className='VDStats-box-half'>
                  <div className='VDStats-box-subtitle deposited'>
                    Deposited
                  </div>
                  <div className='VDStats-box-line'>
                    {Number(Web3.utils.fromWei(globalVDDeposited, 'ether')).toFixed(0)}&nbsp;<CronosLogo />
                  </div>
                  <div className='VDStats-box-line'>
                    {Number(Web3.utils.fromWei(globalSVDDeposited, 'ether')).toFixed(0)}&nbsp;<ScratchLogo />
                  </div>
                </div>
                <div className='VDStats-box-half'>
                  <div className='VDStats-box-subtitle withdrawn'>
                    Withdrawn
                  </div>
                  <div className='VDStats-box-line'>
                    {Number(Web3.utils.fromWei(globalVDWithdrawn, 'ether')).toFixed(0)}&nbsp;<CronosLogo />
                  </div>
                  <div className='VDStats-box-line'>
                    {Number(Web3.utils.fromWei(globalSVDWithdrawn, 'ether')).toFixed(0)}&nbsp;<ScratchLogo />
                  </div>
                </div>
            </div>
            <div className='VDStats-box-title'>
              Personal Funds Owed
            </div>
              <div className='VDStats-box-splitsection'>
                <div className='VDStats-box-half'>
                  <div className='VDStats-box-line'>
                    {Number(Web3.utils.fromWei(totalVDOwed, 'ether')).toFixed(2)}&nbsp;<CronosLogo />
                  </div>
                  <Row align='middle' justify='center'><div className='withdraw-balance shadow-button' onClick={multiRelease}>Withdraw All&nbsp;<CronosLogo /></div></Row>
                </div>
                <div className='VDStats-box-half'>
                  <div className='VDStats-box-line'>
                    {Number(Web3.utils.fromWei(totalSVDOwed, 'ether')).toFixed(2)}&nbsp;<ScratchLogo />
                  </div>
                  <Row align='middle' justify='center'><div className='withdraw-balance shadow-button' onClick={multiReleaseScratch}>Withdraw All&nbsp;<ScratchLogo /></div></Row>
                </div>
            </div>
          </div>
        </Row>
      </>

      : // MOBILE STARTS HERE
      <>
        <Row justify='center'>
          <div className='mobile-VDStats-box flex-column'>
            <div className='mobile-VDStats-box-title'>
              Global Mattress Fund Stats
            </div>
              <div className='VDStats-box-splitsection'>
                <div className='VDStats-box-half'>
                  <div className='mobile-VDStats-box-subtitle deposited'>
                    Deposited
                  </div>
                  <div className='mobile-VDStats-box-line'>
                    {Number(Web3.utils.fromWei(globalVDDeposited, 'ether')).toFixed(0)}&nbsp;<CronosLogo />
                  </div>
                  <div className='mobile-VDStats-box-line'>
                    {Number(Web3.utils.fromWei(globalSVDDeposited, 'ether')).toFixed(0)}&nbsp;<ScratchLogo />
                  </div>
                </div>
                <div className='VDStats-box-half'>
                  <div className='mobile-VDStats-box-subtitle withdrawn'>
                    Withdrawn
                  </div>
                  <div className='mobile-VDStats-box-line'>
                    {Number(Web3.utils.fromWei(globalVDWithdrawn, 'ether')).toFixed(0)}&nbsp;<CronosLogo />
                  </div>
                  <div className='mobile-VDStats-box-line'>
                    {Number(Web3.utils.fromWei(globalSVDWithdrawn, 'ether')).toFixed(0)}&nbsp;<ScratchLogo />
                  </div>
                </div>
            </div>
            <div className='mobile-VDStats-box-title'>
              Personal Funds Owed
            </div>
              <div className='VDStats-box-splitsection'>
                <div className='VDStats-box-half'>
                  <div className='mobile-VDStats-box-line'>
                    {Number(Web3.utils.fromWei(totalVDOwed, 'ether')).toFixed(2)}&nbsp;<CronosLogo />
                  </div>
                  <Row align='middle' justify='center'><div className='mobile-withdraw-balance shadow-button' onClick={multiRelease}>Withdraw All&nbsp;<CronosLogo /></div></Row>
                </div>
                <div className='VDStats-box-half'>
                  <div className='mobile-VDStats-box-line'>
                    {Number(Web3.utils.fromWei(totalSVDOwed, 'ether')).toFixed(2)}&nbsp;<ScratchLogo />
                  </div>
                  <Row align='middle' justify='center'><div className='mobile-withdraw-balance shadow-button' onClick={multiReleaseScratch}>Withdraw All&nbsp;<ScratchLogo /></div></Row>
                </div>
            </div>
          </div>
        </Row>
      </>
    )
  }

  //Call initTable to ultimately set the state or uriList
  useEffect(() => {
    const initTable = async() => {
      const bs = await contract.deployed()
      const bsd = await BSDRead.deployed()
      const bdContract = await contract4.deployed()
      const web3 = new Web3(contractReadProviders)
      
      const balance = await bs.balanceOf(account)
      setAmountOwnedByAccount(balance.toString())
      const tokenIDPromises: any[] = []
      for (let i = 0; i < balance; i++){
        tokenIDPromises.push(bs.tokenOfOwnerByIndex(account, i))
      }
      const tokenIDs = await Promise.all(tokenIDPromises)

      const jsonList: any[] = []
      for(let i = 0; i < tokenIDs.length; i++){
        jsonList.push(bs_metadata[tokenIDs[i]-1])
      }

      let owed = await checkVDOwed(tokenIDs)
      let scratchOwed = await bsd.mymultiPAYOUT(tokenIDs)
      let bdReleased = await bdContract.totalReleased()

      let scratchReleased = await bsd.totalReleased()
      let totalScratchDeposited = await bsd.totalscratchreceived()

      let currentBalance = await web3.eth.getBalance('0xf0EA472fB04dA831106940f58928b0e90e085BbB')

      setGlobalVDDeposited((BigInt(bdReleased.toString()) + BigInt(currentBalance.toString())).toString())
      setGlobalVDWithdrawn(bdReleased.toString())

      setGlobalSVDDeposited(totalScratchDeposited.toString()) 
      setGlobalSVDWithdrawn(scratchReleased.toString())

      setFullNFTInfoList(jsonList)
      setTokenIDArray(tokenIDs)
      setTotalVDOwed(owed.toString())
      setTotalSVDOwed(scratchOwed.toString())
    }
      initTable()
  },[connected, account])

  const NFTCard = (NFT) => {
    let image = NFT.value.image
    let name = NFT.value.name
    let id = (NFT.value.id).toString()
    return (
      <Link to={`/boomer/${id}`} key={id}>
        <Card className='marketplace-card'>
            <img className='marketplace-card-image' src={image} alt='new' onLoad={imageLoadedorError} onError={imageLoadedorError}/>
            <Row className='marketplace-card-row' justify='center' style={{marginTop:'5px', marginBottom: '5px'}}>{name}</Row>
            <Row className='marketplace-card-line'></Row>
            <div className='marketplace-card-rank-container'><div className='marketplace-card-rank'>Rank {getBoomerRank(id)}</div></div> 
        </Card>
      </Link>
    )
  }
  const BoomerList = (props) => {
      const NFTList = props.NFTList
      const NFTItems = NFTList.map((NFT, index) =>
          <NFTCard key={index} value={NFT} />
      )
      return (
          <Row className='boomer-display-grid'>
              {NFTItems}
          </Row>
      )
  }

  return(
    screenWidth>1279?
      connected && (chainID === 25 || chainID === 338 || chainID ===  1337)?
        amountOwnedByAccount>0?
          fullNFTInfoList.length>0?
          <div>
            <div className="container2"><img className='banner' src={webStorage + '/myboomers/myboomersbanner.png'} alt="myboomersbanner"/></div>
            <ToastContainer position="top-center"/>
            <VDStats />
            <Row align='middle' justify='center'><img className='myboomers-boomertext' src={webStorage + '/myboomers/boomers.png'} alt="myboomersbanner"/></Row>
            <Row align='middle' justify='center'><img className='myboomers-curlywave' src={webStorage + '/myboomers/grey-curly-trim2.png'} alt="foo"/></Row>
            <Row align='middle' justify='center'><div className='myboomers-boomercounttext margin-20'>Boomer Count: {amountOwnedByAccount}</div></Row>
            <BoomerList NFTList={fullNFTInfoList} />
          </div>
            :
            <div>
              <div className="container2"><img className='banner' src={webStorage + '/myboomers/myboomersbanner.png'} alt="myboomersbanner"/></div>
              <Row align='middle' justify='center'><img className='myboomers-boomertext' src={webStorage + '/myboomers/boomers.png'} alt="myboomersbanner"/></Row> 
              <Row align='middle' justify='center'><img className='myboomers-curlywave' src={webStorage + '/myboomers/grey-curly-trim2.png'} alt="foo"/></Row>
              <Row align='middle' justify='center'><div className='myboomers-boomercounttext'>Boomer Count: {amountOwnedByAccount}</div></Row>
              <Row className='spinner-zone-temp' align={'middle'}>
              
              <Spinner loading={true} />
             
              </Row>
            </div>
          :
          <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img className='banner' src={webStorage + '/myboomers/myboomersbanner.png'} alt="myboomersbanner"/></div>
            <div className='centered-x-and-y'><img className='no-boomers-found-image'src={webStorage + '/myboomers/noboomersfound.png'} alt='no boomers found'/></div>
          </div>
        : 
        <div>
          <div className='centered-x-and-y'><img className='banner' src={webStorage + '/myboomers/myboomersbanner.png'} alt="foo"/></div>
          <div className='centered-x-and-y'><img className='please-connect-wallet-image'src={webStorage + '/pleaseconnectwallet.png'} alt='please connect wallet'/></div>
        </div>
        :
/////MOBILE==================MOBILE/////MOBILE==================MOBILE/////MOBILE==================MOBILE/////MOBILE==================MOBILE/////MOBILE==================MOBILE/////MOBILE==================MOBILE/////MOBILE==        
////////////////////////MOBILE==================MOBILE/////MOBILE==================MOBILE/////MOBILE==================MOBILE/////MOBILE==================MOBILE/////MOBILE==================MOBILE/////MOBILE==================MOBILE////
        connected && (chainID === 25 || chainID === 338 || chainID ===  1337)?
        amountOwnedByAccount>0?
          fullNFTInfoList.length>0?
          <div>
            <ToastContainer position="top-center"/>
            <div className="container2"><img className='banner' src={webStorage + '/myboomers/myboomersbanner.png'} alt="myboomersbanner"/></div>
            <VDStats />
            <Row align='middle' justify='center'><img className='mobile-myboomers-boomertext' src={webStorage + '/myboomers/boomers.png'} alt="myboomersbanner"/></Row>
            <Row align='middle' justify='center'><img className='mobile-myboomers-curlywave' src={webStorage + '/myboomers/grey-curly-trim2.png'} alt="foo"/></Row>
            <Row align='middle' justify='center'><div className='mobile-myboomers-boomercounttext margin-20'>Boomer Count: {amountOwnedByAccount}</div></Row>
            <BoomerList NFTList={fullNFTInfoList} />
          </div>
            :
            <div>{/**Used to create invisible row that pushes content below header as to not be hidden by its stickyness and z position */}
              <div className="container2"><img className='banner' src={webStorage + '/myboomersbanner.png'} alt="myboomersbanner"/></div>
              <Row align='middle' justify='center'><img className='mobile-myboomers-boomertext' src={webStorage + '/myboomers/boomers.png'} alt="myboomersbanner"/></Row>
              <Row align='middle' justify='center'><img className='mobile-myboomers-curlywave' src={webStorage + '/myboomers/grey-curly-trim2.png'} alt="foo"/></Row>
              <Row align='middle' justify='center'><div className='mobile-myboomers-boomercounttext'>Boomer Count: {amountOwnedByAccount}</div></Row>
              <Row>
              <div className='sk-fading-circle'>
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              </Row>
            </div>
          :
          <div>{/**Used to create invisible row that pushes content below header as to not be hidden by its stickyness and z position */}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img className='banner' src={webStorage + '/myboomers/myboomersbanner.png'} alt="myboomersbanner"/></div>
            <div className='centered-x-and-y'><img className='mobile-no-boomers-found-image'src={webStorage + '/myboomers/noboomersfound.png'} alt='no boomers found'/></div>
          </div>
        : 
        <div>{/**Used to create invisible row that pushes content below header as to not be hidden by its stickyness and z position */}
          <div className='centered-x-and-y'><img className='banner' src={webStorage + '/myboomers/myboomersbanner.png'} alt="foo"/></div>
          <div className='centered-x-and-y'><img className='mobile-no-boomers-found-image'src={webStorage + '/pleaseconnectwallet.png'} alt='please connect wallet'/></div>
        </div>
  )
}
export default MyBoomers
