import { InputNumber, Row } from 'antd'
import { useEffect, useState, useContext } from 'react';
import ScreenWidthContext from '../context/screenwidthcontext';
import '../styles.css'
import checkNotMobile from '../components/logic/mobile';
import RaffleContract from '../contracts/BoomerRaffle.json'
import ConnectedContext from '../context/connectedcontext';
import Web3Context from '../context/web3context';
import toContract from '../types/truffle_contracts';
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';
import Web3 from 'web3';
import { Raffle } from '../pawn_shop/pawnshop_types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../components/spinner';
import { webStorage } from '../config';

export const BoomerRaffle = () => {

    //Screen Width context
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    // Get web3 and the account (metamask public key) to place it on our button text once user is CONNECTED
    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)
    // context to verify if the user WANTS to be connected ot the site, IE if they have clicked the connect button yet, again we cant DISCONNECT the user's metamask, only give the illusion of NOT being connected
    const {connected, setConnected} = useContext(ConnectedContext)

    // contract read and write providers context
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    
    const raffleWrite = toContract(RaffleContract)
    const raffleRead = toContract(RaffleContract)
    

    if(contractWriteProviders){
        const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
        raffleWrite.setProvider(contractWriteProviders)
        raffleRead.setProvider(contractReadProviders)
    }

    const [raffles, setRaffles] = useState([] as Raffle[])
    const [rafflesLoading, setRafflesLoading] = useState(true)
    const [loading, setLoading] = useState(false)
    const [giftLoading, setGiftLoading] = useState(false)
    const [raffleExtraInfo, setRaffleExtraInfo] = useState({})

    let placement = [
        '1st Prize',
        '2nd Prize',
        '3rd Prize',
        '4th Prize',
        '5th Prize',
        '6th Prize',
        '7th Prize',
        '8th Prize',
        '9th Prize',
        '10th Prize'
    ]
    /**
     * 0 = COIN
     * 1 = IRL
     * 2 = ACCESS
     * 3 = MERCH
     * 4 = NFT
     */

    const Notice = () => {
        let m: string = ''
        let mobile: boolean
        mobile = checkNotMobile(screenWidth)
        if(!mobile){
            m = 'm-'
        }
        return(
            <Row justify='center' align='middle'>
                <div className={`${m}raffle-notice`}>
                    Raffles will be drawn at random after the timer expires and the winner(s) wallet will be displayed on the raffle. It is the responsibility of the winner(s) to come forward to claim their prize within 2 weeks. To claim simply open a ticket on the official <a href='https://discord.gg/boomer-squad-nft-939820143486320650' target='_blank'>Boomer Squad Discord.</a> Any raffles that have not been claimed after 2 weeks will  be redrawn.
                </div>
            </Row>
        )
    }

    const buyRaffleTickets = async(raffleID: string, amount: number) => {
        setLoading(true)
        let r = await raffleWrite.deployed()
        // function buyRaffleTickets(uint256 _raffleID, uint256 _amount, address _add) public {
        await toast.promise(
            r.buyRaffleTickets(raffleID, amount, account, {from: account}),
            {
              pending: 'Buying Raffle Tickets...',
              success: {
                render(){
                  return <div>Success! Now refreshing browser.</div>
                },
                // other options
                icon: "🟢",
              },
              error: 'Something went wrong! :x.'
            }
        ).catch(()=> {setLoading(false); return})
        setLoading(false)
        setTimeout((() => window.location.reload()), 5500)
    }

    const giftRaffleTickets = async(raffleID: string, amount: number, id: string) => {
        let r = await raffleWrite.deployed()
        let add = (document.getElementById(id) as HTMLInputElement).value;
        let isValid = Web3.utils.isAddress(add)
        console.log(document.getElementById(id) as HTMLInputElement)
        console.log(document.getElementById(id))
        console.log(add)
        console.log(id)
        //check if address is valid
        if(!isValid){
            toast.error('Please enter a valid 0x address.', {autoClose: 5000})
            setGiftLoading(false)
            return
        }
        // function buyRaffleTickets(uint256 _raffleID, uint256 _amount, address _add) public {
        await toast.promise(
            r.buyRaffleTickets(raffleID, amount, add, {from: account}),
            {
              pending: 'Gifting Raffle Tickets...',
              success: {
                render(){
                  return <div>Success! Now refreshing browser.</div>
                },
                // other options
                icon: "🟢",
              },
              error: 'Something went wrong! :x.'
            }
            ).catch(()=> {setLoading(false); return})
        setGiftLoading(false)
        setTimeout((() => window.location.reload()), 5500)
    }

    const getMyTickets = async(raffleID: string) => {
        let r = await raffleRead.deployed()
        let amount = await r.getRaffleTicketsOwned(account, raffleID)
        return amount.toString()
    }

    const RaffleList = (props) => {
        const raffleList = props.list
        const raffleItems = raffleList.map((raffle, index) => <RaffleCard key={index} value={raffle} />)
        raffleItems.reverse()
        return (
            <>
              {raffleItems}
            </>
          )
      }

    const RaffleCard = (raffle) => {


        const [myTickets, setMyTickets] = useState('0')
        const [amount, setAmount] = useState(1)
        const onChange = (value: number) => {
            setAmount(value)
        }

        let raffleID = raffle.value.raffleID.toString()
        let startDate = raffle.value.startDate
        let timeLimit = raffle.value.timeLimit
        let ticketCost = raffle.value.ticketCost
        let ticketIndex = raffle.value.ticketIndex
        let winners = raffle.value.winners
        let prizes = raffle.value.prizes


        let start = new Date(Number(startDate)*1000)
        let endTime = (Number(startDate)+Number(timeLimit))
        let end = new Date(endTime*1000)

        let currentTime = (Math.floor(Date.now() / 1000))


        useEffect(()=>{
            let on = true;
            if(on && currentTime < endTime){
                getMyTickets(raffleID).then((result) => {
                    setMyTickets(result)
                })
            }
            return () => {
                on = false
            }

        },[])
        getMyTickets(raffleID).then((result) => {
            setMyTickets(result)
        })

        if(!raffleExtraInfo[raffleID] || raffleExtraInfo[raffleID] === undefined){
            return(<></>)
        }

        let raffleType = raffleExtraInfo[raffleID].raffleType
        let description = raffleExtraInfo[raffleID].raffleDescription
        let image = raffleExtraInfo[raffleID].raffleImage

        let place = 0

        return(
            checkNotMobile(screenWidth)?
            <div className='flex-and-center flex-column pawnshop-raffle-card'>
                <img style={{width: '100%', borderRadius: '10px 10px 0px 0px'}} src={webStorage + '/pawnshop/'+raffleType+'.png'} />
                <div className='flex-and-center flex-column' style={{padding: '30px', width: '100%'}}>
                    <div style={{color: 'white', fontSize: '40px', marginBottom: '20px'}}>Boomer Squad Raffle #{raffleID}</div>
                    <div className='pawnshop-raffle-card-row'>Start:<div className='span'/>{start.toLocaleDateString()+" "+start.getHours()+":"+start.getMinutes()}</div>
                    <div className='pawnshop-raffle-card-row'>Ends:<div className='span'/>{end.toLocaleDateString()+" "+end.getHours()+":"+end.getMinutes()}</div>
                    <div className='pawnshop-raffle-card-row'>Ticket Cost<div className='span'/>{ticketCost}&nbsp;$SCRATCH</div>
                    <div className='pawnshop-raffle-card-row'>Total Tickets in Raffle<div className='span'/>{ticketIndex}</div>
                    {
                        currentTime < endTime?
                            <>
                                <div className='pawnshop-raffle-card-row'>My Tickets<div className='span'/>{myTickets}</div>
                            </>:<></>
                    }
                    <div className='pawnshop-raffle-card-prizes' style={{fontSize: '25px', marginTop: '20px'}}>
                        <div className='flex-and-center flex-column' style={{gap: '20px'}}>
                            {prizes.map((prize)=>{
                                return(
                                <div style={{width: '100%'}}>
                                    <div className='pawnshop-raffle-card-row'>{placement[place]}<div className='span'/>{prize}</div>
                                    <div className='pawnshop-raffle-card-row'>{'Winner'}<div className='span'/>
                                    <div style={{fontSize: '15px'}}>{winners[place] || 'TBD'}</div></div>
                                    <script>{place++}</script>
                                </div>
                                    
                                )
                            })}
                        </div>
                    </div>
                    <div className='flex-and-center' style={{marginTop: '20px', gap: '20px'}}>
                        {
                            image !== ''?<img style={{height: '200px', marginTop: '20px', borderRadius: '10px'}} src={webStorage + '/raffles/' + raffleID + '/' + image}/>:<></>
                        }
                        {description}
                    </div>
                    {
                        account !== ''?
                        currentTime < endTime?
                        <>
                            <div className='flex-and-center' style={{width: '100%', height:'150px', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'space-between', height: '90%', marginTop: '20px'}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <label htmlFor="amount1" className='raffle-label'>Amount:</label>
                                        <InputNumber id='amount1' className='' size="large" min={1} max={100} defaultValue={1} onChange={onChange}/>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                                        <div>
                                            <label htmlFor={"address"+raffleID} className='raffle-label'>Gift Address:</label>
                                            <input id={"address"+raffleID} placeholder='0x...' className='raffle-address-input' />
                                        </div>

                                        <div>
                                            *Required for GIFT ONLY
                                        </div>
                                    </div>
                                </div>

                                <div className='flex-and-center flex-column' style={{justifyContent: 'space-between', height: '100%'}}>
                                    <div className='pawnshop-raffle-tickets-button buy shadow-button' onClick={()=>buyRaffleTickets(raffleID, amount)}>
                                    <Spinner loading={loading}/>
                                    {loading?'':'Buy for Myself'}
                                    </div>
                                    <div className='pawnshop-raffle-tickets-button gift shadow-button' onClick={()=>giftRaffleTickets(raffleID, amount, "address"+raffleID)}>
                                    <Spinner loading={giftLoading}/>
                                    {giftLoading?'':'Buy as Gift'}
                                    </div>
                                </div>

                            </div>
                        </>
                        :
                        <>
                        <img style={{width: '70%', marginTop: '20px'}} src={webStorage + '/pawnshop/raffleover.png'}/>
                        </>
                        :<></>
                    }
 
                </div>
            </div>
            :
            <div className='flex-and-center flex-column mobile-pawnshop-raffle-card'>
            <img style={{width: '100%', borderRadius: '10px 10px 0px 0px'}} src={webStorage + '/pawnshop/'+raffleType+'.png'} />
            <div className='flex-and-center flex-column' style={{padding: '5px', width: '100%'}}>
                <div style={{color: 'white', fontSize: '22px', marginBottom: '20px'}}>Boomer Squad Raffle #{raffleID}</div>
                <div className='mobile-pawnshop-raffle-card-row'>Start:<div className='span'/>{start.toLocaleDateString()+" "+start.getHours()+":"+start.getMinutes()}</div>
                <div className='mobile-pawnshop-raffle-card-row'>Ends:<div className='span'/>{end.toLocaleDateString()+" "+end.getHours()+":"+end.getMinutes()}</div>
                <div className='mobile-pawnshop-raffle-card-row'>Ticket Cost<div className='span'/>{ticketCost}&nbsp;$SCRATCH</div>
                <div className='mobile-pawnshop-raffle-card-row'>Total Tickets in Raffle<div className='span'/>{ticketIndex}</div>
                <div className='mobile-pawnshop-raffle-card-row'>My Tickets<div className='span'/>{myTickets}</div>
                <div className='mobile-pawnshop-raffle-card-prizes' style={{marginTop: '20px'}}>
                    <div className='flex-and-center flex-column' style={{gap: '20px', fontSize: '10px'}}>
                        {prizes.map((prize)=>{
                            return(
                            <div style={{width: '100%'}}>
                                <div className='mobile-pawnshop-raffle-card-row'>{placement[place]}<div className='span'/>{prize}</div>
                                <div className='mobile-pawnshop-raffle-card-row'>{'Winner'}<div className='span'/>
                                <div style={{fontSize: '8px'}}>{winners[place] || 'TBD'}</div></div>
                                <script>{place++}</script>
                            </div>
                                
                            )
                        })}
                    </div>
                </div>
                <div className='flex-and-center' style={{marginTop: '20px', gap: '20px'}}>
                        {
                            image !== ''?<img style={{maxHeight: '150px', maxWidth:'120px', marginTop: '20px', borderRadius: '10px'}} src={webStorage + '/raffles/' + raffleID + '/' + image}/>:<></>
                        }
                        {description}
                </div>
                {
                    account !== ''?
                    currentTime < endTime?
                    <div className='flex-and-center' style={{width: '100%', marginBottom:'20px', flexDirection: 'column', justifyContent: 'space-between', gap: '20px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'space-between', height: '90%', marginTop: '20px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <label htmlFor="amount1" className='raffle-label'>Amount:</label>
                            <InputNumber id='amount1' className='' size="large" min={1} max={100} defaultValue={1} onChange={onChange}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <div>
                                <label htmlFor={"address"+raffleID} className='raffle-label'>Gift Address:</label>
                                <input id={"address"+raffleID} placeholder='0x...' className='raffle-address-input' />
                            </div>

                            <div>
                                *Required for GIFT ONLY
                            </div>
                        </div>
                    </div>

                        <div className='pawnshop-raffle-tickets-button buy shadow-button' onClick={()=>buyRaffleTickets(raffleID, amount)}>
                        <Spinner loading={loading}/>
                        {loading?'':'Buy for Myself'}
                        </div>
                        <div className='pawnshop-raffle-tickets-button gift shadow-button' onClick={()=>giftRaffleTickets(raffleID, amount, "address"+raffleID)}>
                        <Spinner loading={giftLoading}/>
                        {giftLoading?'':'Buy as Gift'}
                        </div>
                   

                </div>
                    :
                    <>
                    <img style={{width: '70%', marginTop: '20px'}} src={webStorage + '/pawnshop/raffleover.png'}/>
                    </>
                    :<></>
                }
                </div>
            </div>
        )
    }

    useEffect(()=>{
        let subed = true
        const getRaffles = async() => {
            setRafflesLoading(true)
            let res = await fetch('https://api.boomersquad.io/raffles')
            let raffles = await res.json()
            return raffles
        }
        const getRaffleExtraInfo = async() => {
            let res = await fetch('https://boomersquad-web-images.s3.amazonaws.com/raffles/raffleInfo')
            let raffles = await res.json()
            return raffles
        }
        if (subed) {
            Promise.all([getRaffleExtraInfo(), getRaffles()])
                .then(([raffleExtraInfoResult, rafflesResult]) => {
                    setRaffleExtraInfo(raffleExtraInfoResult);
                    setRaffles(rafflesResult);
                })
                .finally(() => {
                    setRafflesLoading(false);
                });
        }        
        return () => {subed = false}
    },[])

    return(
        checkNotMobile(screenWidth)?
        <>
        <div className="flex-and-center flex-column"><img className='banner' style={{width: '100%', marginBottom: '50px'}} src={webStorage + '/pawnshop/rafflebanner.png'} alt="myboomersbanner"/></div>
        <div className='flex-and-center flex-column pawnshop-background'>
            <ToastContainer position="top-center"/>
            <Notice />
            <Row className='flex-and-center' style={{margin: '30px', gap: '80px'}}>
                <Spinner loading={raffles.length === 0? true: false} size={250} />
                <RaffleList list={raffles} />
            </Row>
        </div>
        </>


        :
        <>
        <div className="flex-and-center"><img className='banner' style={{width: '100%'}} src={webStorage + '/pawnshop/rafflebanner.png'} alt="myboomersbanner"/></div>
        <div className='flex-and-center flex-column pawnshop-background'>
            <ToastContainer position="top-center"/>
            <div className='flex-and-center flex-column' style={{margin: '30px', gap: '80px'}}>
                <Notice />
                <Spinner loading={raffles.length === 0? true: false} size={250} />
                <RaffleList list={raffles} />
            </div>
        </div>
        </>
    )
}