
import { useState, useContext, useEffect } from 'react';
import ConnectedContext from './../context/connectedcontext';
import Web3Context from './../context/web3context';
import toContract from './../types/truffle_contracts';
import Web3 from 'web3';
import { Row, InputNumber } from 'antd';
import ScreenWidthContext from './../context/screenwidthcontext';
import Spinner from './../components/spinner'
import connectedAndGoodChain from './../components/logic/connectedandgoodchain';
import Web3ContractReadWriteContext from './../context/web3ContractReadWriteContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { webStorage } from './../config';
import checkNotMobile from './../components/logic/mobile';
import ScratchContract from './../contracts/Scratch.json'
import { Link, useParams } from "react-router-dom";
import { getScratchBalance } from '../components/utils/scratch-functions';
import ComseticMinter from './../contracts/CosmeticMinter.json'
import { approveERC20, ethToWei, getERC20Allowance, getERC20Balance, multiplyBigInts } from '../components/utils/web3Utils';
import { getNameFromAddress } from './cosmetic_minter_utils';

export const CosmeticMinter = () => {

    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)
    const {connected, setConnected} = useContext(ConnectedContext)
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    
    const contractWrite = toContract(ComseticMinter)

    const { id } = useParams();

    // set up contract
    if(contractWriteProviders){
        const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
        contractWrite.setProvider(contractWriteProviders)
    }

    interface CurrencyInfo {
        address: string,
        publicMintPrice: string,
        wlMintPrice: string,
        allowance: string
    }


    // hooks for use throughout the mint page
    const cosmeticMinterAddress = '0x833a8be904918d594f67843e9BA5fa0740131D76'
    const [wlIndex, setWlIndex] = useState(999)
    const [currentSupply, setCurrentSupply] = useState('')
    const [amountToMint, setAmountToMint] = useState(1)
    const [publicMintPrice, setPublicMintPrice] = useState('');
    const [wlMintPrice, setWlMintPrice] = useState('');
    const [loading, setLoading] = useState(false)
    const [infoLoading, setInfoLoading] = useState(true)
    const [mintState, setMintState] = useState(false)
    const [onWl, setOnWl] = useState(false)
    const [currencies, setCurrencies] = useState<CurrencyInfo[]>([])
    const maxSupply ='427'

    // 1000000000000000000 = 1 cro

    /**
     * 
     * Skeleton Variables
     * 
     */

    const description: string = `
    This is a cosmetic Boom Room item. These cosmetics do not increase the point value of your Boom Rooms. There is only 1 in the collection and it will unlock all 5 room sizes depending on what Room you use it in. 

    This cosmetic was brought to you by the team over at Brain Dead Monkey this 3D cosmetic was designed to give you the look and feel of a Boom Room decorated by a Brain Dead Monkey.
    `


    const MintButtons = ({currencies}) => {
        const web3 = new Web3(contractWriteProviders);
        let buttons = currencies.map((currency, index) => {
            let currencyName = getNameFromAddress[currency.address]
            let currencyPrice = onWl?currency.wlMintPrice:currency.publicMintPrice
            let allowance = currency.allowance
            let totalPrice = multiplyBigInts(amountToMint.toString(), currencyPrice.toString())
            let isToken = currency.address !== '0x0000000000000000000000000000000000000000'
            return(
                !isToken?
                <div className='lp-button lp-blue bigger-on-hover text-shadow shadow-button-w green' onClick={() => onMint(index)}>
                <Spinner loading={loading} size={75}/>
                {loading?'':`Mint ${Web3.utils.fromWei(currencyPrice, "ether")} ${currencyName}`}
                </div>
                :
                    BigInt(allowance.toString()) >= BigInt(totalPrice.toString())?
                    <div className='lp-button lp-blue bigger-on-hover text-shadow shadow-button-w lb' onClick={() => onMint(index)}>
                    <Spinner loading={loading} size={75}/>
                    {loading?'':`Mint ${Web3.utils.fromWei(currencyPrice, "ether")} ${currencyName}`}
                    </div>
                    :
                    // export const approveERC20 = async(web3: Web3, tokenContractAddress: string, spenderAddress: string, account: string) => {
                    <div className='lp-button lp-blue bigger-on-hover text-shadow shadow-button-w pink' onClick={() => approveERC20(web3, currency.address, cosmeticMinterAddress, account)}>
                    <Spinner loading={loading} size={75}/>
                    {loading?'':`APPROVE ${currencyName}`}
                    </div>
            )
        })

        return(
            <>
            {buttons}
            </>
        )
    }

    const refreshInfo = async() => {
        setInfoLoading(true)
        const web3 = new Web3(contractWriteProviders)
        // update CRO
        let croBalance = await web3.eth.getBalance(account)
        setBalance(web3.utils.fromWei(croBalance, "ether"))
        // update Scratch
        let scratchBalance = await getScratchBalance(Web3.givenProvider || "ws://localhost:8545", account)
        setScratchBalance(scratchBalance as string)
        // get pool info
        await getPoolInfo(Number(id))
        setInfoLoading(false)
    }

    /**
     * 
     * Smart Contract Functions
     * 
     */

    const onMint = async(currencyIndex: number) => {
        setLoading(true)
        let instance = await contractWrite.deployed()
        const web3 = new Web3(contractWriteProviders)
        if(!mintState){
            toast.error('Mint is not active.', {autoClose: 5000});
            setLoading(false)
            return
        }
        let totalCost: string = multiplyBigInts(amountToMint.toString(), currencies[currencyIndex].publicMintPrice)
        if(onWl){
            totalCost = multiplyBigInts(amountToMint.toString(), currencies[currencyIndex].wlMintPrice)
        }

        let userBalance = ethToWei(balance)
        // export const getERC20Balance = async (web3: Web3, contractAddress: string, accountAddress: string) => {
        if(currencyIndex !== 0){
            userBalance = await getERC20Balance(web3, currencies[currencyIndex].address, account)
        }

        if(BigInt(totalCost) > BigInt(userBalance)){
            toast.error('Not Enough Balance.', {autoClose: 5000});
            setLoading(false)
            return 
        }

        let data;
        if(currencyIndex !== 0){
            data = {from: account}
        } else {
            data = {from: account, value: totalCost}
        }
        // function mintFromPool(uint256 _pID, uint256 _amount, uint256 _currencyIndex) public payable{
        await toast.promise(
            instance.mintFromPool(id, amountToMint, currencyIndex, data),
            {
              pending: 'Minting...',
              success: {render(){return <div>Success!</div>},icon: "🟢",},
              error: 'Failed to mint.'
            }
        )
        .then((result: any) => {
            setLoading(false)
        })
        .catch(()=> setLoading(false))

        await refreshInfo()
        setLoading(false)
    }

    // update the value in the input number mint box
    const onChange = (value: number) => {
        setAmountToMint(value)
    }

    // load info on user web3 connections
    useEffect(() => {
        const load = async() => {
            if(account !== ''){
                refreshInfo()
            }
        }
        load()
    },[account, contractWriteProviders])

    const getPoolInfo = async(id: number) => {
        const web3 = new Web3(contractWriteProviders)
        let instance = await contractWrite.deployed()
        let pool = await instance.viewPool(id)
        let tempCurrencies = [] as CurrencyInfo[]
        for(let i = 0; i < pool.currencyAddresses.length; i++){
            //get currency allowance
            // export const getERC20Allowance = async (web3: Web3, tokenContractAddress: string, accountAddress: string, spenderAddress: string) => {
            let allowance = pool.currencyAddresses[i].toString() === '0x0000000000000000000000000000000000000000'?
                            '9999999999999999999999000000000000000000':
                            await getERC20Allowance(web3, pool.currencyAddresses[i], account, contractWrite.address)
            let currency: CurrencyInfo = {
                address: pool.currencyAddresses[i].toString(),
                publicMintPrice: pool.currencyPublicPrices[i].toString(),
                wlMintPrice: pool.currencyWhitelistPrices[i].toString(),
                allowance: allowance.toString()
            }
            tempCurrencies.push(currency)
        }
        let onwl = await instance.whitelists(pool.whitelistIndex.toString(), account)
        setOnWl(onwl)
        setCurrencies(tempCurrencies)
        setMintState(pool.mintActive)
        setWlIndex(pool.whitelistIndex.toString())
        setCurrentSupply(pool.tokenIDs.length.toString())
    }

    const inputNumber = 
    <Row justify='center' style={{marginBottom: '20px'}}>
        <div className='lp-mintdescription-fast'>{'Amount to Mint: '}&nbsp;</div><InputNumber className='lp-mint-inputnumber' size="large" min={1} max={20} defaultValue={1} onChange={onChange}/>
    </Row>

    const mintDetails = 
    <Row justify='center'>
        <div className='lp-mintdescription-fast flex-and-center flex-column'>
            <div className='lp-mintdescription-row'>{'Current Supply: '}{currentSupply}</div>
            <div className='lp-mintdescription-row'>{'Whitelisted: '}{onWl?'Yes':'No'}</div>
        </div>
    </Row>

    const connectWalletComp = 
    <>
        {
            connectedAndGoodChain(connected, chainID)?
            !infoLoading?
                <>
                    {mintDetails}
                    {inputNumber}
                    <Row justify='center' style={{display: 'flex', margin: '0px 0px 40px 0px'}}>
                        <MintButtons currencies={currencies}/>
                    </Row>
                </>
                :
                <Row justify='center' style={{display: 'flex', margin: '40px 0px 40px 0px'}}>
                    <Spinner loading={true} size={100}/>
                </Row>
                :
                <>
                    <Row justify='center'>
                        <div className='lp-mintdescription-fast'>
                            <div className='lp-mintdescription-connectwallet'>{'Connect Wallet To Mint!'}</div>
                        </div>
                    </Row>
                </>
        }
    
    </>



    return (
        <>
            <ToastContainer position="top-center"/>
        {
            checkNotMobile(screenWidth)?
            <div>
                <div className="container2"><img className='banner' src={webStorage + '/boomrooms/boomroombanner.png'} alt="myboomersbanner"/></div>
                <div className='lp-nftbanner-line'/>
                <Row justify='center'>
                    <div className='lp-description'>
                        {description}
                    </div>
                </Row>
                <Row justify='center'>
                    <img className='lp-mintgif' src={webStorage + '/cosmeticMinter/'+id+'.png'} alt=''/>
                </Row>
                {connectWalletComp}
            </div>

            :
        //MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================
            <div>
                <div className="container2"><img className='banner' src={webStorage + '/boomrooms/boomroombanner.png'} alt="myboomersbanner"/></div>
                <div className='mobile-lp-nftbanner-line'/>
                <Row justify='center'>
                    <div className='mobile-lp-description'>
                        {description}
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='mobile-lp-mintrow flex-and-center flex-column'>
                        <img className='mobile-lp-mintgif' src={webStorage + '/cosmeticMinter/'+id+'.png'} alt=''/>
                    </div>
                </Row>
                {connectWalletComp}
            </div>
        }
        </>

    )
}