import Scratch from "../contracts/Scratch.json"
import ZoomersBonusTrait from "../contracts/ZoomersBonusTrait.json"
import toContract from '../types/truffle_contracts';
import Web3 from 'web3';

const scratch = toContract(Scratch)
const zoomersBonusTrait = toContract(ZoomersBonusTrait)


export const rollTrait = async(provider: any, account: string, tokenID: string) => {
    zoomersBonusTrait.setProvider(provider)
    let zbt = await zoomersBonusTrait.deployed()
    let tx = await zbt.rollBonusTrait(tokenID, {from: account})
    return tx
}

export const approveScratchContract = async(provider: any, account: string) => {
    scratch.setProvider(provider)
    zoomersBonusTrait.setProvider(provider)
    let zbt = await zoomersBonusTrait.deployed()
    let sc = await scratch.deployed()
    await sc.approve(zbt.address, '100000000000000000000000', {from: account})
}

export const checkAllowance = async (provider: any, account: string) => {
    scratch.setProvider(provider)
    zoomersBonusTrait.setProvider(provider)
    let zbt = await zoomersBonusTrait.deployed()
    let sc = await scratch.deployed()
    let allowance = await sc.allowance(account, zbt.address)
    return allowance.toString()
}