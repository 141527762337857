import { boomroomBaseURI, windowBaseURI, doorBaseURI, bedBaseURI, buildingBaseURI, tableBaseURI, deskBaseURI, entertainmentBaseURI, couchBaseURI, petBaseURI, activityBaseURI } from './boomroom_config';

export const componentTraitNames = ['Window', 'Door', 'Bed', 'Building', 'Table', 'Desk', 'Entertainment', 'Couch', 'Pet', 'Activity']
export const componentBaseURIs = [windowBaseURI, doorBaseURI, bedBaseURI, buildingBaseURI, tableBaseURI, deskBaseURI, entertainmentBaseURI, couchBaseURI, petBaseURI, activityBaseURI]

export const getContractTokens = async(contract: any, baseURI: string, account: string) => { 
    let instance = await contract.deployed()
    const balance = await instance.balanceOf(account)
    const tokenIDPromises: any[] = []
    for (let i = 0; i < balance; i++){
      tokenIDPromises.push(instance.tokenOfOwnerByIndex(account, i))
    }
    const tokenIDs = await Promise.all(tokenIDPromises)
    const responseList: any[] = []
    for(let i = 0; i < tokenIDs.length; i++){
        responseList.push((fetch(baseURI+tokenIDs[i].toString())).catch(()=> console.log('failed to load')))
    }
    const fullList = await Promise.all(responseList)
    let fullJSONPromises: any[] = []
    for (let i in fullList){
      fullJSONPromises.push(((fullList[i].json()).catch(()=> console.log('failed to load'))))
    }
    const fullJSONList = await Promise.all(fullJSONPromises)

    return fullJSONList
}

export const brokenRoomMetadata = {id: '0', name: 'Broken Metadata', image: 'broken_metadata.png', attributes: [{trait_type: 'NA', value: 'NA'},{trait_type: 'NA', value: 'NA'},{trait_type: 'NA', value: 'NA'},{trait_type: 'NA', value: 'NA'}]}

export const getBoomRoomMetaDataFromContract = async(contract: any, tokenID: string) => {
    const instance = await contract.deployed()
    let metadata = await instance.getTokenMeta(tokenID)
    return metadata
}

export const getBoomRoomCosmetics = async(contract: any, tokenID: string) => {
  const instance = await contract.deployed()
  let metadata = await instance.getTokenMeta(tokenID)
  let cosmetics = metadata[3]
  return cosmetics
}