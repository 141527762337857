import axios from 'axios';

const BOOMER_API_URL = 'https://api.boomersquad.io';

export const removeDiscordFromWeb3 = async (username: string) => {
    // const response = await axios.post('https://api.boomersquad.io/chainchecker/discord/add_user', {
    const response = await axios.post(BOOMER_API_URL+'/chainchecker/discord/remove_user', {
        username: username
        },
    )
    return response
}

// Add this function in your component or in a separate utilities file
export const saveDiscordToWeb3 = async (authCode: string, web3Address: string) => {
    try {
    // Make a request to boomer API with auth payload for Discord user
    // const response = await axios.post('https://api.boomersquad.io/chainchecker/discord/add_user', {
    const response = await axios.post(BOOMER_API_URL+'/chainchecker/discord/add_user', {
        authCode: authCode,
        web3Address: web3Address
        },
    )
    return response;
    } catch (error) {
    console.error('Error fetching Discord user:', error);
    }
};

export const getDiscordUsername = async (web3Address: string) => {
    const response = await axios.post(BOOMER_API_URL+'/chainchecker/bot/users/get_username', {web3Address: web3Address})
    return response;
}