import { Layout } from 'antd';
import NavBar from './navbar';
import Footer from './footer'
import 'antd/dist/antd.css'
import { Content } from 'antd/lib/layout/layout';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import ConnectedContext from './context/connectedcontext';
import ModalVisibilityContext from './context/modalvisiblitycontext';
import Web3Context from './context/web3context';
import ScreenWidthContext from './context/screenwidthcontext';
// import Web3ProviderContext from './context/web3ProviderContext';
import Web3ContractReadWriteContext from './context/web3ContractReadWriteContext';
import GoToTop from './GoToTop'; //Used to scroll to top of page on navigation
import { AntiPhishBanner } from './security/antiphish';
import './global.css'


function App() {


  //***********************contexts*****************************
  //************************************************************

  ///creating a context for the app logical operator to know if 
  //"connected" or "not". This is purely for a better user experience
  // and ease of use. Metamask and other providers cannot ACTUALLY 
  //be disconnected from the html/js side. AKA our side.
  const [connected, setConnected] = useState(false)
  const connectedValue = { connected, setConnected }

  //create context for modal visibility throughout the app
  const [modalVisibility, setModalVisibility] = useState(false)
  const modalVisibilityValue = { modalVisibility, setModalVisibility }

  //set up for web3, eth balance and account context throughout the app
  const [account, setAccount] = useState('')
  const [balance, setBalance] = useState('')
  const [scratchBalance, setScratchBalance] = useState('')
  const [chainID, setChainID] = useState(0)

  const web3Value = { account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance }

  //set up for size of screen, used to dictacte between desktop and mobile view
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const screenWidthValue = { screenWidth, setScreenWidth }

  // set up contract read and write providers
  const [contractReadProviders, setContractReadProviders] = useState(null)
  const [contractWriteProviders, setContractWriteProviders] = useState(null)

  const contractProvidersValue = { contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders }


  //***********************end of contexts**********************
  //************************************************************

  return (
    <Web3ContractReadWriteContext.Provider value={contractProvidersValue}>
      <ScreenWidthContext.Provider value={screenWidthValue}>
        <ConnectedContext.Provider value={connectedValue}>
          <ModalVisibilityContext.Provider value={modalVisibilityValue}>
            <Web3Context.Provider value={web3Value}>
              <Layout className='website-body'>
                <AntiPhishBanner />
                <NavBar />
                <Content>
                  <GoToTop />
                  <Outlet />
                </Content>
                <Footer />
              </Layout>
            </Web3Context.Provider>
          </ModalVisibilityContext.Provider>
        </ConnectedContext.Provider>
      </ScreenWidthContext.Provider>
    </Web3ContractReadWriteContext.Provider>
  )
}
export default App;
