import { useState, useContext, useEffect, useCallback } from 'react';
import ConnectedContext from '../context/connectedcontext';
import Web3Context from '../context/web3context';
import toContract from '../types/truffle_contracts';

import Web3 from 'web3';
import { Row, InputNumber } from 'antd';
import ScreenWidthContext from '../context/screenwidthcontext';
import Spinner from '../components/spinner'
import connectedAndGoodChain from '../components/logic/connectedandgoodchain';
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';
import checkNotMobile from '../components/logic/mobile';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BoomRoom from '../contracts/BoomRoom.json'
import { YoutubeVideo } from '../components/youtubevideo/youtubevideo';
import { webStorage } from '../config';

function BoomRoomComponentMint () {

    // Get web3 and the account (metamask public key) to place it on our button text once user is CONNECTED
    const {account, setAccount, balance, setBalance, chainID, setChainID} = useContext(Web3Context) //ommiting web3 and creating it locally because of having to set up contract
    // context to verify if the user WANTS to be connected ot the site, IE if they have clicked the connect button yet, again we cant DISCONNECT the user's metamask, only give the illusion of NOT being connected
    const {connected, setConnected} = useContext(ConnectedContext)
    // Screen Width context
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    // contract read and write providers context
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    
    const contractRead = toContract(BoomRoom)
    const contractWrite = toContract(BoomRoom)

    // set up contract
    if(contractWriteProviders){
      const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
      contractRead.setProvider(contractReadProviders)
      contractWrite.setProvider(contractWriteProviders)
    }

  /**
   * General hooks
   */

    // hooks for use throughout the mint page
    const [currentSupply, setCurrentSupply] = useState('')
    const [amountToMint, setAmountToMint] = useState(1)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [wlClaimAmount, setWlClaimAmount] = useState('')

    const maxSupply ='198000'
    const mintPrice = '35000000000000000000'
  
  //linked to InputNumber antd component, used to update the value in the input number box - aka how many boomers to mint
  const onChange = (value: number) => {
    setAmountToMint(value)
  }

  // update current supply
  const updateCurSup = async() => {
    const instance = await contractRead.deployed()
    let remainingSup = await instance.remainingSupplyOfComponents()
    let maxSup = 198000
    let curSup = maxSup - Number(remainingSup.toString())
    setCurrentSupply(curSup.toString())
  }

  // check if account is on WL
  const checkOnWL = async() => {
    let brContract = await contractRead.deployed()

    let onWL = await brContract.getWLInfo(account)
    if(onWL[1].toString() === '0'){
        setWlClaimAmount(onWL[1].toString())
    } else {
        setWlClaimAmount(onWL[1].toString())
    }
  }

  const updateBalance = async() => {
    // TODO refresh balance on successful mint
    const web3 = new Web3(contractWriteProviders)
    web3.eth.getBalance(account, function(err, result) {
      if (err) {
          console.log(err)
      } else {
          console.log(web3.utils.fromWei(result, "ether") + " ETH")
          setBalance(web3.utils.fromWei(result, "ether"))
      }
  })
  }

  const onPublicMint = async() => {
    // TODO mint function smart contract
    setLoading3(true)
    const web3 = new Web3(contractWriteProviders)
    let brContract = await contractWrite.deployed()
    await toast.promise(
        brContract.mintComponent(amountToMint, {from: account, value: (BigInt(amountToMint)*BigInt(mintPrice)).toString()}),
        {
          pending: 'Public Minting Component...',
          success: {
            render(){
              return <div>Success!</div>
            },
            // other options
            icon: "🟢",
          },
          error: 'Failed to mint.'
        }
    ).catch(()=> setLoading3(false))
    setLoading3(false)
    updateCurSup()
    updateBalance()
  }

  const onWLMint = async() => {
    // TODO mint function smart contract
    setLoading2(true)
    const web3 = new Web3(contractWriteProviders)
    let brContract = await contractWrite.deployed()

    await toast.promise(
        brContract.WLMintComponent(amountToMint, {from: account}),
        {
          pending: 'WL Minting Component...',
          success: {
            render(){
              return <div>Success!</div>
            },
            // other options
            icon: "🟢",
          },
          error: 'Failed to mint.'
        }
    ).catch(()=> setLoading2(false))
    updateBalance()
    setLoading2(false)
    updateCurSup()
  }

    // load info on user web3 connections
    useEffect(() => {
      const load = async() => {
          updateCurSup()
          checkOnWL()
          updateBalance()
      }
      load()
    },[connected])

    const MintDetails = () => {
      return(
        <div className={`${checkNotMobile(screenWidth)?'lp-mintdescription':'mobile-lp-mintdescription'}`}>
          <div className='lp-mintdescription-row'>{'Mint Tiers: WL Mint, Public Mint'}</div>
          <div className='lp-mintdescription-row'>{'Public Price: 35 CRO'}</div>
          <div className='lp-mintdescription-row'>{'WL Price: Free'}</div>
          <div className='lp-mintdescription-row'>{'Lifetime Supply: '+maxSupply}</div>
          <div className='lp-mintdescription-row'>{'Max Public mint per wallet: No Limit'}</div>
        </div>
      )
    }


  return (

      // check if user on mobile
      checkNotMobile(screenWidth)?
      <>
          <ToastContainer position="top-center"/>
          <YoutubeVideo videoURL="https://www.youtube.com/embed/p1VaQ-mrf3w" />
          <div className='neon-text neon-blue br-page-title'>
            Mint Component
          </div>
          <div className='neon-line neon-blue'></div>
            <Row justify='center'>
                <div className='br-mintrow'>
                    <div>
                        <img className='br-mintimage neon-border-blue' src={webStorage + '/boomrooms/component_mint_image.png'} alt=""/>
                    </div>
                    <MintDetails />
                </div>
            </Row>
            {connectedAndGoodChain(connected, chainID)?
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast flex-and-center flex-column'>
                        <div className='lp-mintdescription-row br-textmid'>{'Current Supply: '}{currentSupply}{' / '+maxSupply}</div>
                        <div className='lp-mintdescription-row br-textmid'>{'Whitelist allocation: '}{wlClaimAmount}</div>
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-button lp-blue bigger-on-hover text-shadow' onClick={onWLMint}>
                    <Spinner loading={loading2}/>
                    {loading2?'':'WL Mint'}
                    </div>
                    <div className='lp-button lp-pink bigger-on-hover text-shadow' onClick={onPublicMint}>
                    <Spinner loading={loading3}/>
                    {loading3?'':'Public Mint'}
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>{'Amount to Mint: '}</div><InputNumber className='lp-mint-inputnumber' size="large" min={1} max={25} defaultValue={1} onChange={onChange}/>
                </Row>
            </>
            :
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>
                        <div className='lp-mintdescription-connectwallet'>{'Connect Wallet To Mint!'}</div>
                    </div>
                </Row>
            </>}
        </>

      // mobile
      :
      <>
          <ToastContainer position="top-center"/>
          <YoutubeVideo videoURL="https://www.youtube.com/embed/p1VaQ-mrf3w" />
          <div className='neon-text neon-blue mobile-br-page-title flex-and-center'>
            {'Mint Component'}
          </div>
          <div className='neon-line neon-blue'></div>
          <Row justify='center'>
                <div className='mobile-lp-mintrow flex-and-center flex-column'>
                    <div>
                        <img className='mobile-lp-mintgif neon-border-gray' src={webStorage + '/boomrooms/component_mint_image.png'} alt=""/>
                    </div>
                    <MintDetails />
                </div>
            </Row>
            {connectedAndGoodChain(connected, chainID)?
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast flex-and-center flex-column'>
                        <div className='lp-mintdescription-row br-textmid'>{'Current Supply: '}{currentSupply}{' / '+maxSupply}</div>
                        <div className='lp-mintdescription-row br-textmid'>{'Whitelist allocation: '}{wlClaimAmount}</div>
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-button lp-blue bigger-on-hover text-shadow' onClick={onWLMint}>
                    <Spinner loading={loading2}/>
                    {loading2?'':'WL Mint'}
                    </div>
                    <div className='lp-button lp-pink bigger-on-hover text-shadow' onClick={onPublicMint}>
                    <Spinner loading={loading3}/>
                    {loading3?'':'Public Mint'}
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>{'Amount to Mint: '}</div><InputNumber className='lp-mint-inputnumber' size="large" min={1} max={25} defaultValue={1} onChange={onChange}/>
                </Row>
            </>
            :
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>
                        <div className='lp-mintdescription-connectwallet'>{'Connect Wallet To Mint!'}</div>
                    </div>
                </Row>
            </>}
        </>


  )
}

export default BoomRoomComponentMint