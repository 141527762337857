import { webStorage } from "../config"

interface LaunchpadObject {
    name: string,
    image: string,
    link: string,
    status: number
    // 1: upcomming
    // 2: currently minting
    // 3: sold out
}

export const launchpadObjs: LaunchpadObject[] = [
    {
        name: `SOU-SOU BEAR DEN`,
        image: webStorage + '/launchpad/sousou/sousou promo.png',
        link: '/launchpad/sousou',
        status: 2
    },
    {
        name: 'MiCROchipsNFT',
        image: webStorage + '/launchpad/microchips/mc_website_card.png',
        link: '/launchpad/microchips',
        status: 3
    },
    {
        name: 'Cronos Fossils Gen 2',
        image: webStorage + '/launchpad/cronosfossils/NFT1.png',
        link: '/launchpad/cronosfossils/',
        status: 3
    },
    {
        name: `Bob's Adventures`,
        image: webStorage + '/launchpad/bobsadventure/BANNER.jpg',
        link: '/launchpad/bobsadventure/',
        status: 3
    },
    {
        name: `Aristo Dog Houses`,
        image: webStorage + '/launchpad/artisandoghouse/BANNER.png',
        link: '/launchpad/aristodoghouse',
        status: 3
    },
    {
        name: `Zoomers`,
        image: webStorage + '/launchpad/zoomer1.png',
        link: '/zoomermint',
        status: 3
    },
    // {
    //     name: `Ultra Savage St`,
    //     image: webStorage + '/launchpad/USS/USS_MINT_IMAGE.png',
    //     link: '/launchpad/ultrasavagest',
    //     status: 2
    // },
    {
        name: `B.D.M. Cosmetic`,
        image: 'https://boomersquad-boomroom-cosmetic-cover-images.s3.amazonaws.com/coverBDMv1.png',
        link: '/cosmeticminter/1',
        status: 3
    }
]