import { useState, useContext, useEffect } from 'react';
import ConnectedContext from '../context/connectedcontext';
import Web3Context from '../context/web3context';
import toContract from '../types/truffle_contracts';

import Web3 from 'web3';
import { Row } from 'antd';
import ScreenWidthContext from '../context/screenwidthcontext';
import Spinner from '../components/spinner'
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';
import checkNotMobile from '../components/logic/mobile';

import { BoomRoomComponents, ComponentNoScore } from "./boomroom_interfaces"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BoomRoomContract from '../contracts/BoomRoom.json' //boom room contract
import { contractAddresses } from './boomroom_config';
import { brokenRoomMetadata } from './boomroom_utils';
import { YoutubeVideo } from '../components/youtubevideo/youtubevideo';
import { fetchMetadata, getBoomRoomComponents } from '../components/utils/api_calls';
import { webStorage } from '../config';
const axios = require('axios').default; // axios.<method> will now provide autocomplete and parameter typings



function BoomRoomConstruction () {

    // Get web3 and the account (metamask public key) to place it on our button text once user is CONNECTED
    const {account, setAccount, balance, setBalance, chainID, setChainID} = useContext(Web3Context) //ommiting web3 and creating it locally because of having to set up contract
    // context to verify if the user WANTS to be connected ot the site, IE if they have clicked the connect button yet, again we cant DISCONNECT the user's metamask, only give the illusion of NOT being connected
    const {connected, setConnected} = useContext(ConnectedContext)
    // Screen Width context
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    // contract read and write providers context
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    
    const contractRead = toContract(BoomRoomContract)
    const contractWrite = toContract(BoomRoomContract)

    // set up contract
    if(contractWriteProviders){
        const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
        contractRead.setProvider(contractReadProviders)
        contractWrite.setProvider(contractWriteProviders)
    }
    /**
     * 
     * IMPORTANT - COMPONENT ORDERING
     * 0. WINDOW  
     * 1. DOOR
     * 2. BED
     * 3. BUILDING
     * 4. TABLE
     * 5. DESK
     * 6. ENTERTAINMENT
     * 7. COUCH
     * 8. PET
     * 9. ACTIVITY
     * 
     */


  // select mode
  const [activeSort, setActiveSort] = useState('All')
  const [mode, setMode] = useState('SelectRoom')

  const [boomRoomMetadataList, setBoomRoomMetadataList] = useState([] as any[])

  // extract mode
  const [constructSelection, setConstructSelection] = useState({} as any)

  // construct mode

  // current components
  const [currentWINDOW, setCurrentWINDOW] = useState({} as ComponentNoScore)
  const [currentDOOR, setCurrentDOOR] = useState({} as ComponentNoScore)
  const [currentBED, setCurrentBED] = useState({} as ComponentNoScore)
  const [currentBUILDING, setCurrentBUILDING] = useState({} as ComponentNoScore)
  const [currentTABLE, setCurrentTABLE] = useState({} as ComponentNoScore)
  const [currentDESK, setCurrentDESK] = useState({} as ComponentNoScore)
  const [currentENTERTAINMENT, setCurrentENTERTAINMENT] = useState({} as ComponentNoScore)
  const [currentCOUCH, setCurrentCOUCH] = useState({} as ComponentNoScore)
  const [currentPET, setCurrentPET] = useState({} as ComponentNoScore)
  const [currentACTIVITY, setCurrentACTIVITY] = useState({} as ComponentNoScore)

  // updated components
  const [updatedWINDOW, setUpdatedWINDOW] = useState({} as ComponentNoScore)
  const [updatedDOOR, setUpdatedDOOR] = useState({} as ComponentNoScore)
  const [updatedBED, setUpdatedBED] = useState({} as ComponentNoScore)
  const [updatedBUILDING, setUpdatedBUILDING] = useState({} as ComponentNoScore)
  const [updatedTABLE, setUpdatedTABLE] = useState({} as ComponentNoScore)
  const [updatedDESK, setUpdatedDESK] = useState({} as ComponentNoScore)
  const [updatedENTERTAINMENT, setUpdatedENTERTAINMENT] = useState({} as ComponentNoScore)
  const [updatedCOUCH, setUpdatedCOUCH] = useState({} as ComponentNoScore)
  const [updatedPET, setUpdatedPET] = useState({} as ComponentNoScore)
  const [updatedACTIVITY, setUpdatedACTIVITY] = useState({} as ComponentNoScore)

  const updatedComponentsList = [updatedWINDOW, updatedDOOR, updatedBED, updatedBUILDING, updatedTABLE, updatedDESK, updatedENTERTAINMENT, updatedCOUCH, updatedPET, updatedACTIVITY]
  const currentComponentsList = [currentWINDOW, currentDOOR, currentBED, currentBUILDING, currentTABLE, currentDESK, currentENTERTAINMENT, currentCOUCH, currentPET, currentACTIVITY]

  const updatedFreeComps = [updatedTABLE, updatedDESK, updatedENTERTAINMENT, updatedCOUCH, updatedPET, updatedACTIVITY]
  const updatedFixedComps = [updatedWINDOW, updatedDOOR, updatedBED, updatedBUILDING]

  const [cTotalScore, setCTotalScore] = useState('0')
  const [uTotalScore, setUTotalScore] = useState('0')

  const [componentsMode, setComponentsMode] = useState('scoreComponents')

  // component switch mode
  const [componentSort, setComponentSort] = useState('')

  const componentTraitNames = ['Window', 'Door', 'Bed', 'Building', 'Table', 'Desk', 'Entertainment', 'Couch', 'Pet', 'Activity']

  const [roomsLoading, setRoomsLoading] = useState(true)
  const [maxFixed, setRemainingFixed] = useState(0)
  const [maxFree, setRemainingFree] = useState(0)

  const [compAddJsons, setCompAddJsons] = useState([] as any[])

  const [loading, setLoading] = useState(true)

  const roomSizingChart = {
    "Single": {
      fixed: 4,
      free: 2
    },
    "Studio": {
      fixed: 4,
      free: 2
    },
    "Double": {
      fixed: 4,
      free: 3
    },
    "Loft": {
      fixed: 4,
      free: 4
    },
    'Penthouse': {
      fixed: 4,
      free: 5
    },
  }

  /**
  * General functions
  */

  const selectRoom = async(boomroom) => {

    setConstructSelection(boomroom)
    setRemainingFixed(roomSizingChart[boomroom.attributes[0].value].fixed)
    setRemainingFree(roomSizingChart[boomroom.attributes[0].value].free)

    let pinnedComponents: BoomRoomComponents = await getBoomRoomComponents(boomroom)
    console.log(pinnedComponents)

    // setupAllCosmetics(pinnedCosmetics)

    setCurrentWINDOW(pinnedComponents['Window'])
    setCurrentDOOR(pinnedComponents['Door'])
    setCurrentBED(pinnedComponents['Bed'])
    setCurrentBUILDING(pinnedComponents['Building'])
    setCurrentTABLE(pinnedComponents['Table'])
    setCurrentDESK(pinnedComponents['Desk'])
    setCurrentENTERTAINMENT(pinnedComponents['Entertainment'])
    setCurrentCOUCH(pinnedComponents['Couch'])
    setCurrentPET(pinnedComponents['Pet'])
    setCurrentACTIVITY(pinnedComponents['Activity'])

    setUpdatedWINDOW(pinnedComponents['Window'])
    setUpdatedDOOR(pinnedComponents['Door'])
    setUpdatedBED(pinnedComponents['Bed'])
    setUpdatedBUILDING(pinnedComponents['Building'])
    setUpdatedTABLE(pinnedComponents['Table'])
    setUpdatedDESK(pinnedComponents['Desk'])
    setUpdatedENTERTAINMENT(pinnedComponents['Entertainment'])
    setUpdatedCOUCH(pinnedComponents['Couch'])
    setUpdatedPET(pinnedComponents['Pet'])
    setUpdatedACTIVITY(pinnedComponents['Activity'])
  }

  const createBlankComp = (index: number): ComponentNoScore => {
    return({
      component_type: componentTraitNames[index], 
      component_score: '0',
      component_id: '0',
      component_image: '0',
      component_traits: []
    })
  }

  const fixedComponents = ['Window', 'Door', 'Bed', 'Building']
  const freeComponents = ['Table', 'Desk', 'Entertainment', 'Couch', 'Pet', 'Activity']

  const updateCurrentTotalScore = () => {
    let score: Number = (
      Number(currentWINDOW.component_score) + 
      Number(currentDOOR.component_score) +
      Number(currentBED.component_score) +
      Number(currentBUILDING.component_score) +
      Number(currentTABLE.component_score) +
      Number(currentDESK.component_score) +
      Number(currentENTERTAINMENT.component_score) +
      Number(currentCOUCH.component_score) +
      Number(currentPET.component_score) +
      Number(currentACTIVITY.component_score)
    )
    setCTotalScore(score.toString())
  }

  const updateUpdatedTotalScore = () => {
    let score: Number = (
      Number(updatedWINDOW.component_score) + 
      Number(updatedDOOR.component_score) +
      Number(updatedBED.component_score) +
      Number(updatedBUILDING.component_score) +
      Number(updatedTABLE.component_score) +
      Number(updatedDESK.component_score) +
      Number(updatedENTERTAINMENT.component_score) +
      Number(updatedCOUCH.component_score) +
      Number(updatedPET.component_score) +
      Number(updatedACTIVITY.component_score)
    )
    setUTotalScore(score.toString())
  }

  const removeComponent = (component_type: string) => {
    if(component_type === 'Window') {
      setUpdatedWINDOW(createBlankComp(0))
    }
    if(component_type === 'Door') {
      setUpdatedDOOR(createBlankComp(1))
    }
    if(component_type === 'Bed') {
      setUpdatedBED(createBlankComp(2))
    }
    if(component_type === 'Building') {
      setUpdatedBUILDING(createBlankComp(3))
    }
    if(component_type === 'Table') {
      setUpdatedTABLE(createBlankComp(4))
    }
    if(component_type === 'Desk') {
      setUpdatedDESK(createBlankComp(5))
    }
    if(component_type === 'Entertainment') {
      setUpdatedENTERTAINMENT(createBlankComp(6))
    }
    if(component_type === 'Couch') {
      setUpdatedCOUCH(createBlankComp(7))
    }
    if(component_type === 'Pet') {
      setUpdatedPET(createBlankComp(8))
    }
    if(component_type === 'Activity') {
      setUpdatedACTIVITY(createBlankComp(9))
    }
  }

  const countPinnedComponents = (compArray):number => {
    let pinned = 0
    for(let i in compArray){
      if(compArray[i].component_id !== '0'){
        pinned++
      }
    }
    return pinned
  }

  const addComponent = (component: any) => {
    let componentTypes = component.attributes[0].value
    let compToAdd: ComponentNoScore = {
      component_type: component.attributes[0].value, 
      component_score: component.attributes[1].value,
      component_id: component.id,
      component_image: component.image,
      component_traits: [
        {trait_type: component.attributes[2].trait_type as string, trait_value: component.attributes[2].value as string},
        {trait_type: component.attributes[3].trait_type as string, trait_value: component.attributes[3].value as string},
        {trait_type: component.attributes[4].trait_type as string, trait_value: component.attributes[4].value as string},
        {trait_type: component.attributes[5].trait_type as string, trait_value: component.attributes[5].value as string},
      ]
    }
    if(componentTypes === 'Window') {
      setUpdatedWINDOW(compToAdd)
    }
    if(componentTypes === 'Door') {
      setUpdatedDOOR(compToAdd)
    }
    if(componentTypes === 'Bed') {
      setUpdatedBED(compToAdd)
    }
    if(componentTypes === 'Building'){
      setUpdatedBUILDING(compToAdd)
    }
    if(componentTypes === 'Table') {
      setUpdatedTABLE(compToAdd)
    }
    if(componentTypes === 'Desk') {
      setUpdatedDESK(compToAdd)
    }
    if(componentTypes === 'Entertainment') {
      setUpdatedENTERTAINMENT(compToAdd)
    }
    if(componentTypes === 'Couch') {
      setUpdatedCOUCH(compToAdd)
    }
    if(componentTypes === 'Pet') {
      setUpdatedPET(compToAdd)
    }
    if(componentTypes === 'Activity') {
      setUpdatedACTIVITY(compToAdd)
    }
  }

  const compareScore = (component: ComponentNoScore) => {

    let currentComponent: ComponentNoScore

    for (let i in currentComponentsList){
      if (currentComponentsList[i].component_type === component.component_type){
        currentComponent = currentComponentsList[i]
        if(Number(currentComponent.component_score) > Number(component.component_score)){
          return 'less'
        } else if (Number(currentComponent.component_score) < Number(component.component_score)){
          return 'more'
        } else if (Number(currentComponent.component_score) === Number(component.component_score)){
          return 'even'
        }
      }
    }
  }

  const checkIfCanAddMore = (addType: string) => {
    let free = countPinnedComponents(updatedFreeComps)
    let fixed = countPinnedComponents(updatedFixedComps)

    if(fixedComponents.includes(addType)){
      if(fixed === maxFixed){
        toast.error('FREE Component limit reached!', {autoClose: 5000});
      }else{
        setMode('ComponentAdd')
      }
    }
    if(freeComponents.includes(addType)){
      if(free === maxFree){
        toast.error('FIXED Component limit reached!', {autoClose: 5000});
      }else{
        setMode('ComponentAdd')
      }
    }
  }

  const backToRoomSelection = () => {
    setMode('SelectRoom')
  }

  const updateRoom = async() => {
    const web3 = new Web3(contractWriteProviders)
    let brContract = await contractWrite.deployed()

    let compTokenIdArray: string[] = []
    for(let i in updatedComponentsList){
      compTokenIdArray.push(updatedComponentsList[i].component_id)
    }

    // last empty array is cosmetics, will change when contract is live
    await toast.promise(
        brContract.pinComponentsToRoom(constructSelection.id, compTokenIdArray, {from: account}),
        {
          pending: 'Pinning Components To Room...',
          success: {
            render(){
              return <div>Success! Now refreshing browser.</div>
            },
            // other options
            icon: "🟢",
          },
          error: 'Failed to Pin.'
        }
    ).catch((e) => {console.log('something went wrong :(', e); return})
    setTimeout((() => window.location.reload()), 5500)
  }


  /**
   * Components
   */
  const BoomRoomList = (props) => {
    const boomroomlist = props.boomroomlist
    let roomList = []
    for(let i in boomroomlist){
      if(boomroomlist[i] !== undefined && boomroomlist[i] !== null){
        if(boomroomlist[i].attributes[0].value === activeSort || activeSort ==='All'){
          roomList.push(boomroomlist[i])
        }
      } else {
        roomList.push(brokenRoomMetadata)
      }
    }
    const boomroomItems = roomList.map((boomroom, index) => <BoomRoomCard key={index} value={boomroom} />)
    return (
        boomroomItems.length > 0?
        <Row justify='center'>
          {boomroomItems}
        </Row>
        :
        <>
          <img src={webStorage + '/boomrooms/noroomsfound.png'} style={{marginTop: '60px', maxWidth: '800px', width: '90%'}}/>
        </>
      )
  }

  const BoomRoomCard = (boomroom) => {
    const id = boomroom.value.id
    const name = boomroom.value.name
    const type = boomroom.value.attributes[0].value
    const multiplier = boomroom.value.attributes[1].value
    const score = boomroom.value.attributes[2].value
    const image = boomroom.value.image
    const openCompSlots = boomroom.value.attributes[3].value
    let anySeen = 0
    return(
           <div className='flex-and-center flex-column bigger-on-hover' onClick={id!=='0'?() => {setMode('RoomConstruct'); selectRoom(boomroom.value)}:()=>''}>
            <div className='br-boomroomcard neon-border-yellow'>
              <img className='br-boomroomcard-image' src={image}/>
              <div className='br-blackline'/>
              <div className='br-boomroomcard-bottombox'>
                <div className='flex-and-center flex-column'>
                  <div>{name}</div>
                  <div>{'Open Component Slots: ' + openCompSlots}</div>
                  <div>{'Multiplier: ' + multiplier}</div>
                  <div>{'Total Score: ' + score}</div>
                </div>
              </div>

            </div>
           </div>  
    )
  }

  const ComponentList = (props) => {
    const componentList = props.componentList
    const componentItems = componentList.map((component, index) => <ComponentSlot key={index} value={component} />)
    return (<Row justify='center' className='br-construction-complist'>{componentItems}</Row>)
  }

  const ComponentSlot = (component) => {
    const component_type = component.value.component_type
    const component_score = component.value.component_score
    const component_id = component.value.component_id
    const component_image = component.value.component_image
    let colour = 'lblue'
    if(fixedComponents.includes(component_type)){
      colour = 'yellow'
    }
    return (
      component_id != '0'?
      <>
        <div className='flex justify-center flex-column'>
          <div className={`neon-text neon-${colour} font-35`}>{component_type}</div>
          <div className={`br-roomconstruct-componentcard-container neon-border-${colour}`}>
            <img className='br-roomconstruct-componentcard-image' src={component_image}/>
            <div className='br-blackline' />
            <div className='br-roomconstruct-componentcard-bottombox flex-and-center flex-column'>
              <div>{'Component #' + component_id}</div>
              <div className='br-blackline-half'/>
              <div>{'Score: ' + component_score}</div>
            </div>
            <div className='br-roomconstruct-componentcard-buttoncontainer flex justify-around'>
              <div className='neon-text neon-red bigger-on-hover font-30' onClick={() => {removeComponent(component_type)}}>Remove</div>
            </div>
          </div>
        </div>
      </>

      :
      <>
        <div className='flex justify-center flex-column'>
          <div className={`neon-text neon-${colour} font-35`}>{component_type}</div>
          <div className={`br-roomconstruct-componentcard-container neon-border-${colour}`}>
            <div className='bg-black br-roomconstruct-componentcard-empty'/>
            <div className='br-blackline' />
            <div className='br-roomconstruct-componentcard-bottombox flex-and-center flex-column'>
            </div>
            <div className='br-roomconstruct-componentcard-buttoncontainer flex-and-center'>
              <div className='neon-text neon-green bigger-on-hover font-30' onClick={() => {checkIfCanAddMore(component_type); setComponentSort(component_type)}}>Add</div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const AddComponentList = (props) => {
    const componentList = props.componentList
    const sortType = props.sortType
    const componentItems = componentList.map((component, index) => <AddComponent key={index} value={component} sortType={sortType}/>)
    return (
    componentList.length > 0?
    <Row justify='center'>
      {componentItems}
    </Row>
    :
    <>
      <img src={webStorage + '/boomrooms/nocompfound.png'} style={{marginTop: '20px', maxWidth: '1000px', width: '95%'}}/>
    </>
    )
  }

  const AddComponent = (component) => {
    if(component.value === undefined || component.value === null){
      return(
        <div className='flex justify-center flex-column'>
        <div className={`br-roomconstruct-componentcard-container neon-border-red`}>
          <img className='br-roomconstruct-componentcard-image' src={webStorage + '/boomrooms/broken_metadata.png'}/>
          <div className='br-blackline' />
          <div className='br-roomconstruct-componentcard-bottombox flex-and-center flex-column'>
            <div>{'Metadata Error'}</div>
          </div>
          <div className='br-roomconstruct-componentcard-buttoncontainer flex justify-around'>
          </div>
        </div>
      </div>
        
      )
    }
    const component_type = component.value.attributes[0].value
    const component_score = component.value.attributes[1].value
    const component_id = component.value.id
    const component_image = component.value.image
    let colour = 'lblue'
    if(fixedComponents.includes(component_type)){
      colour = 'yellow'
    }

    const sortType = component.sortType
    return(

        <div className='flex justify-center flex-column'>
            <div className={`br-roomconstruct-componentcard-container neon-border-${colour}`}>
              <img className='br-roomconstruct-componentcard-image' src={component_image}/>
              <div className='br-blackline' />
              <div className='br-roomconstruct-componentcard-bottombox flex-and-center flex-column'>
                <div>{'Component #' + component_id}</div>
                <div className='br-blackline-half'/>
                <div>{'Score: ' + component_score}</div>
              </div>
              <div className='br-roomconstruct-componentcard-buttoncontainer flex justify-around'>
                <div className='neon-text neon-green bigger-on-hover font-30' onClick={() => {setMode('RoomConstruct'); setComponentSort('None'); addComponent(component.value)}}>Add</div>
              </div>
            </div>
          </div>
    )
  }

  const ComponentSquareList = (props) => {
    const componentList = props.componentList
    const componentItems = componentList.map((component, index) => <ComponentSquare key={index} value={component} />)
    return (<Row justify='center'>{componentItems}</Row>)
  }
  
  const ComponentSquare = (component) => {
    const component_type = component.value.component_type
    const component_score = component.value.component_score
    const component_id = component.value.component_id
    return(
      component_id !== undefined?
      component_id != '0'?
      <div className={`br-roomconstruct-component-box filled nowbold margin-3 
      ${compareScore(component.value) === 'even'?'neon-border-yellow':''}
      ${compareScore(component.value) === 'more'?'neon-border-green':''}
      ${compareScore(component.value) === 'less'?'neon-border-red':''}`}>
        <div className='br-roomconstruct-component-box-ct neon-text'>{component_type}</div>
        <div className='br-roomconstruct-component-box-cn'>{'Comp. # ' + component_id}</div>
        <div className={
          `br-roomconstruct-component-box-cs neon-text 
          ${compareScore(component.value) === 'even'?'neon-yellow':''}
          ${compareScore(component.value) === 'more'?'neon-green':''}
          ${compareScore(component.value) === 'less'?'neon-red':''}`
        }>{'Score: ' + component_score}</div>
      </div>
      :
      <div className='br-roomconstruct-component-box neon-border-grey nowbold margin-3'>
        <div className='br-roomconstruct-component-box-ct neon-text'>{component_type}</div>
        <div className='br-roomconstruct-component-box-e neon-text neon-red'>{'EMPTY'}</div>
      </div>
      :
      <div className='br-roomconstruct-component-box neon-border-grey nowbold margin-3 flex-and-center'>
        <Spinner loading={true} size={50}/>
      </div>
    )
  }

  /**
   * 
   * 
   * useEffects to get user room data
   * 
   */

  let contractBasedOnComponentSort = {
    'Window': contractAddresses.window,
    'Door': contractAddresses.door,
    'Bed': contractAddresses.bed,
    'Building': contractAddresses.building,
    'Table': contractAddresses.table,
    'Desk': contractAddresses.desk,
    'Entertainment': contractAddresses.entertainment,
    'Couch': contractAddresses.couch,
    'Pet': contractAddresses.pet,
    'Activity': contractAddresses.activity,
  }

    // update total scores
    useEffect(() => {
      updateCurrentTotalScore()
      updateUpdatedTotalScore()
    });

    // load windows
    useEffect(() => {
      let isApiSubscribed = true
      const loadBasedOnSort = async(componentSort) => {
        setLoading(true)
        let contractAddress = contractBasedOnComponentSort[componentSort]
        fetchMetadata(account, contractAddress).then((allJsons) => {
          setCompAddJsons(allJsons)
          setLoading(false)
          console.log('Pulling metadata....')
        })
      }
      if(isApiSubscribed){
        loadBasedOnSort(componentSort)
      }
      return () => {
        // cancel the subscription
        isApiSubscribed = false;
      }
      },[componentSort])

    //Call initTable to ultimately set the state or uriList
    useEffect(() => {
      let isApiSubscribed = true;

      if(isApiSubscribed && account !== ''){
        fetchMetadata(account, contractAddresses.boom_room).then((fullBoomRooms)=>{
            setBoomRoomMetadataList(fullBoomRooms)
            setRoomsLoading(false)
        })
      }
      return () => {
        // cancel the subscription
        isApiSubscribed = false;
      };
    },[account])

    return (

        // check if user on mobile
        checkNotMobile(screenWidth)?
        <>
        <ToastContainer position="top-center"/>
        <YoutubeVideo videoURL="https://www.youtube.com/embed/LYQjYuv6Ipc" />
        <div className='neon-text neon-yellow br-page-title'>Room Construction</div>
        <div className='neon-line neon-yellow'></div>
        {
          mode==='SelectRoom'?
          <>
            <div className='br-subtitle'>Select Room Type</div>
            <Row justify='center' className='br-roomconstruction-sortrow'>
              <div className={`neon-button-simple neon-yellow bigger-on-hover br-construction-select-buttons ${activeSort==='All'?'active': ''}`} onClick={() => setActiveSort('All')}>All</div>
              <div className={`neon-button-simple neon-yellow bigger-on-hover br-construction-select-buttons ${activeSort==='Single'?'active': ''}`} onClick={() => setActiveSort('Single')}>Single</div>
              <div className={`neon-button-simple neon-yellow bigger-on-hover br-construction-select-buttons ${activeSort==='Studio'?'active': ''}`} onClick={() => setActiveSort('Studio')}>Studio</div>
              <div className={`neon-button-simple neon-yellow bigger-on-hover br-construction-select-buttons ${activeSort==='Double'?'active': ''}`} onClick={() => setActiveSort('Double')}>Double</div>
              <div className={`neon-button-simple neon-yellow bigger-on-hover br-construction-select-buttons ${activeSort==='Loft'?'active': ''}`} onClick={() => setActiveSort('Loft')}>Loft</div>
              <div className={`neon-button-simple neon-yellow bigger-on-hover br-construction-select-buttons ${activeSort==='Penthouse'?'active': ''}`} onClick={() => setActiveSort('Penthouse')}>Penthouse</div>
            </Row>
            {
              roomsLoading?
              <div className='br-construction-spinner-box flex-and-center'>
                <Spinner loading={true} size={200}/>
              </div>
              :
              <>
                <BoomRoomList boomroomlist={boomRoomMetadataList}/>
              </>
            }
            
          </>

          :
          <>
          </>
        }
        {
          mode === 'RoomConstruct'?
          <>
            <div className='flex-and-center flex-column'>
              <Row justify='center'>
                <div className='flex flex-column justify-between margin-20'>
                  <div className='br-subtitle flex-and-center'>{constructSelection.name}</div>
                  <img className='br-roomconstruct-image neon-border-yellow' src={constructSelection.image}/>
                  <div className='br-subsubtitle neon-text neon-yellow'>{'Multiplier: ' + constructSelection.attributes[1].value}&nbsp;-&nbsp;{'Total Score: ' + (Number((constructSelection.attributes[1].value).substring(0, 1))*Number(cTotalScore))}</div>
                  <div className='br-subsubtitle neon-text neon-yellow'>{'Open Slots Left: '+constructSelection.attributes[3].value}</div>
                </div>
                <div className='flex flex-column br-roomconstruct-stats justify-center align-center margin-20'>
                  {
                    componentsMode === 'scoreComponents'?
                    <>
                      <div>
                        <div className='br-subtitle margin-20'>Score Components</div>
                        <div className='br-subsubtitle neon-text neon-yellow'>{'Base Score: ' + cTotalScore}</div>
                        <Row justify='center'>
                          <ComponentSquareList componentList={currentComponentsList} />
                        </Row>
                      </div>
                      <div className='neon-line neon-yellow width-100 margin-20' />
                      <div>
                        <div className={`br-subsubtitle neon-text 
                        ${Number(cTotalScore) > Number(uTotalScore)?'neon-red':''}
                        ${Number(cTotalScore) < Number(uTotalScore)?'neon-green':''}
                        ${Number(cTotalScore) === Number(uTotalScore)?'neon-yellow':''}`
                        }>{'Updated Base Score: ' + uTotalScore}</div>
                        <Row justify='center'>
                          <ComponentSquareList componentList={updatedComponentsList} />
                        </Row>
                      </div>
                    </>
                    
                    :
                    <>
                    </>
                  }
                </div>
              </Row>
             
              <Row justify='center' className='margin-50'>
                <div className={`neon-button-simple width-250 margin-20 ${true?'neon-green neon-hover-green bigger-on-hover':'neon-dgreen'}`} onClick={updateRoom}>Update Room</div>
                <div className={`neon-button-simple width-250 margin-20 neon-red neon-hover-red bigger-on-hover`} onClick={backToRoomSelection}>Back</div>
                <div className={`neon-button-simple width-250 margin-20 neon-yellow neon-hover-yellow bigger-on-hover`} onClick={() => selectRoom(constructSelection)}>Reset</div>
                {
                  componentsMode === 'scoreComponents'?
                  <>
                  </>
                  
                  :
                  <>
                    <div className={`neon-button-simple margin-20 neon-lblue neon-hover-lblue bigger-on-hover`} onClick={() => setComponentsMode('scoreComponents')}>Score View</div>
                  </>
                }
                
              </Row>
              <div className='br-subtitle flex-and-center'>
                Add or Remove components below!
              </div>

              {
                componentsMode === 'scoreComponents'?
                <>
                  <div className='neon-text neon-yellow font-30'>
                    MAX FIXED Component slots: {maxFixed}
                  </div>
                  <div className='neon-text neon-lblue font-30'>
                    MAX FREE Component slots: {maxFree}
                  </div>
                  <div className='margin-top-20'>
                    <ComponentList componentList={updatedComponentsList} />
                  </div>
                </>

                :
                <>
                  {/* <div className='margin-top-20'>
                    <CosmeticList cosmeticList={selectUpdatedCosmeticList(constructSelection.attributes[0].value)} />
                  </div> */}
                </>
              }
              
            </div>
          </>
          :
          <>
          </>
        }
        {
          mode === 'ComponentAdd'?
          <>
            <div className={`neon-button-simple width-250 margin-20 neon-red neon-hover-red bigger-on-hover`} onClick={() => {setMode('RoomConstruct')}}>Back</div>
            <Row justify='center'>
              {loading?
                <div className='br-componentbuilder-spinner-box flex-and-center'>
                  <Spinner loading={true} size={200}/></div>
                  :
                  <>
                   <AddComponentList componentList={compAddJsons} sortType={componentSort} />
                  </>}
              
            </Row>
          </>
          :
          <>
          </>
        }
        {
          mode === 'CosmeticAdd'?
          <>
            <div className={`neon-button-simple margin-20 neon-red neon-hover-red bigger-on-hover`} onClick={() => setMode('RoomConstruct')}>Back</div>
            {/* <Row justify='center'>
              <AddCosmeticList cosmeticList={cosmeticMetadataList} />
            </Row> */}
          </>
          :
          <>
          </>
        }
        </>

// MOBILE STARTS HERE MOBILE STARTS HERE MOBILE STARTS HERE MOBILE STARTS HERE MOBILE STARTS HERE MOBILE STARTS HERE MOBILE STARTS HERE MOBILE STARTS HERE
        :
        <>
        <ToastContainer position="top-center"/>
        <YoutubeVideo videoURL="https://www.youtube.com/embed/LYQjYuv6Ipc" />
        <div className='neon-text neon-yellow mobile-br-page-title flex-and-center'>
          Room Construction
        </div>
        <div className='neon-line neon-yellow'></div>
       {
          mode==='SelectRoom'?
          <>
            <div className='mobile-br-subtitle'>Select Room Type</div>
            <Row justify='center' className='br-roomconstruction-sortrow'>
              <div className={`neon-button-simple neon-yellow bigger-on-hover br-construction-select-buttons ${activeSort==='All'?'active': ''}`} onClick={() => setActiveSort('All')}>All</div>
              <div className={`neon-button-simple neon-yellow bigger-on-hover br-construction-select-buttons ${activeSort==='Single'?'active': ''}`} onClick={() => setActiveSort('Single')}>Single</div>
              <div className={`neon-button-simple neon-yellow bigger-on-hover br-construction-select-buttons ${activeSort==='Studio'?'active': ''}`} onClick={() => setActiveSort('Studio')}>Studio</div>
              <div className={`neon-button-simple neon-yellow bigger-on-hover br-construction-select-buttons ${activeSort==='Double'?'active': ''}`} onClick={() => setActiveSort('Double')}>Double</div>
              <div className={`neon-button-simple neon-yellow bigger-on-hover br-construction-select-buttons ${activeSort==='Loft'?'active': ''}`} onClick={() => setActiveSort('Loft')}>Loft</div>
              <div className={`neon-button-simple neon-yellow bigger-on-hover br-construction-select-buttons ${activeSort==='Penthouse'?'active': ''}`} onClick={() => setActiveSort('Penthouse')}>Penthouse</div>
            </Row>
            {
              roomsLoading?
              <div className='mobile-br-construction-spinner-box flex-and-center'>
                <Spinner loading={true} size={100}/>
              </div>
              :
              <>
                <BoomRoomList boomroomlist={boomRoomMetadataList}/>
              </>
            }
          </>
          :
          <>
          </>
        }
        {
          mode === 'RoomConstruct'?
          <>
            <div className='flex-and-center flex-column'>
              <div className='mobile-br-subtitle flex-and-center'>{constructSelection.name}</div>
              <img className='mobile-br-roomconstruct-image neon-border-yellow' src={constructSelection.image}/>
              <div className='br-subsubtitle neon-text neon-yellow font-20'>
                {'Multiplier: ' + constructSelection.attributes[1].value}&nbsp;-&nbsp;{'Total Score: ' + (Number((constructSelection.attributes[1].value).substring(0, 1))*Number(cTotalScore))}
              </div>
              <div className='br-subsubtitle neon-text neon-yellow font-20'>{'Open Slots Left: '+constructSelection.attributes[3].value}</div>
                <div className='flex flex-column mobile-br-roomconstruct-stats justify-center align-center'>
                  {
                    componentsMode === 'scoreComponents'?
                    <>
                      <div>
                        <div className='mobile-br-subtitle'>Score Components</div>
                        <div className='mobile-br-subsubtitle neon-text neon-yellow'>{'Base Score: ' + cTotalScore}</div>
                        <Row justify='center'>
                          <ComponentSquareList componentList={currentComponentsList} />
                        </Row>
                      </div>
                      <div className='neon-line neon-yellow width-100 margin-20' />
                      <div>
                        <div className={`mobile-br-subsubtitle neon-text 
                        ${Number(cTotalScore) > Number(uTotalScore)?'neon-red':''}
                        ${Number(cTotalScore) < Number(uTotalScore)?'neon-green':''}
                        ${Number(cTotalScore) === Number(uTotalScore)?'neon-yellow':''}`
                        }>{'Updated Base Score: ' + uTotalScore}</div>
                        <Row justify='center'>
                          <ComponentSquareList componentList={updatedComponentsList} />
                        </Row>
                      </div>
                    </>
                    
                    :
                    <>

                    </>
                  }
                </div>
             
              <Row justify='center' className='margin-20'>
                <div className={`neon-button-simple width-250 margin-10 ${true?'neon-green neon-hover-green bigger-on-hover':'neon-dgreen'}`} onClick={updateRoom}>Update Room</div>
                <div className={`neon-button-simple width-250 margin-10 neon-red neon-hover-red bigger-on-hover`} onClick={backToRoomSelection}>Back</div>
                <div className={`neon-button-simple width-250 margin-10 neon-yellow neon-hover-yellow bigger-on-hover`} onClick={() => selectRoom(constructSelection)}>Reset</div>
                {
                  componentsMode === 'scoreComponents'?
                  <>
                  </>
                  
                  :
                  <>
                    <div className={`neon-button-simple margin-20 neon-yellow neon-hover-yellow bigger-on-hover`} onClick={() => setComponentsMode('scoreComponents')}>Score View</div>
                  </>
                }
                
              </Row>
              <div className='mobile-br-subtitle flex-and-center flex-column'>
                Add or Remove components below!
                <div className='neon-text neon-yellow font-20'>
                MAX FIXED Component slots: {maxFixed}
              </div>
              <div className='neon-text neon-lblue font-20'>
                MAX FREE Component slots: {maxFree}
              </div>
              </div>
              
              {
                componentsMode === 'scoreComponents'?
                <>
                    <ComponentList componentList={updatedComponentsList} />
                </>
                :
                <>

                </>
              }
              
            </div>
          </>
          :
          <>
          </>
        }
        {
          mode === 'ComponentAdd'?
          <>
            <div className={`neon-button-simple width-250 margin-20 neon-red neon-hover-red bigger-on-hover`} onClick={() => setMode('RoomConstruct')}>Back</div>
            <Row justify='center'>
              {loading?
                <div className='br-componentbuilder-spinner-box flex-and-center'>
                  <Spinner loading={true} size={150}/></div>
                  :
                  <>
                   <AddComponentList componentList={compAddJsons} sortType={componentSort} />
                  </>}
              
            </Row>
          </>
          :
          <>
          </>
        }
        </>

    )
}

export default BoomRoomConstruction