import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import './news.css'
import { NewsObject, newsObjects } from "./newsObjs";
import { useParams } from 'react-router-dom';
import { NewsletterSubsctiption } from "./newslettersubscription";
import checkNotMobile from "../components/logic/mobile";
import ScreenWidthContext from "../context/screenwidthcontext";

export const News = () => {

    const { id } = useParams();

    const [activeArticle, setActiveArticle] = useState({} as NewsObject)

    //Screen Width context
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)

    const NavTitles = (props) => {
        let arr = props.list;
        let reversed = [...arr].reverse()
        const list = reversed.map((item, key)=>{return<NavTitle key={key} value={item}/>})
        return(<>{list}</>)
    }

    const NavTitle = (props) => {
        const title: string = props.value.title
        const date: string = props.value.date
        return(
            <div className='news-nav-container' onClick={() => setActiveArticle(props.value)}>
                <div className='news-nav-title'>
                    {title}
                </div>
                <div className='news-nav-date'>
                    {date}
                </div>
            </div>
        )
    }

    useEffect(() => {
        const isValid = (id) => {
            if(newsObjects[id] === undefined){return false}
            return true
        }
        isValid(id) && setActiveArticle(newsObjects[id])
        !isValid(id) && setActiveArticle(newsObjects[0])
    },[])

    return(
        <>
            <div className="flex-and-center">
                <NewsletterSubsctiption />
            </div>
            <div className='news-container'>
                {
                    checkNotMobile(screenWidth) &&
                    <div className='news-section navigation'>
                        <NavTitles list={newsObjects.reverse()}/>
                    </div>
                }
                    <div className='news-section article'>
                        <div className="news-article-title">{activeArticle.title}</div>
                        <div className="news-article-date">{activeArticle.date}</div>
                        <div>{activeArticle.article}</div>
                    </div>
                {
                    checkNotMobile(screenWidth) &&
                    <div className='news-section tweets'>
                    </div>
                }

            </div>
        </>

    )
}