import { useState, useContext } from 'react';
import ConnectedContext from '../context/connectedcontext';
import Web3Context from '../context/web3context';
import { Row } from 'antd';
import ScreenWidthContext from '../context/screenwidthcontext';
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';
import checkNotMobile from '../components/logic/mobile';
import { Link, Outlet } from 'react-router-dom';
import '../mobilestyles.css'
import '../styles.css'
import './boomroom.css'
import './boomroom-mobile.css'
import { NewSticker } from '../components/notifications/newsticker';
import { webStorage } from '../config';

function BoomRoomGame () {

  //Get web3 and the account (metamask public key) to place it on our button text once user is CONNECTED
  const {account, setAccount, balance, setBalance, chainID, setChainID} = useContext(Web3Context) //ommiting web3 and creating it locally because of having to set up contract

  //this is the context to verify if the user WANTS to be connected ot the site, IE if they have clicked the connect button yet, again we cant DISCONNECT the user's metamask, only give the illusion of NOT being connected
  const {connected, setConnected} = useContext(ConnectedContext)

  //Screen Width context
  const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)

  // contract read and write providers context
  const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)

  /**
   * 
   * Hooks
   * 
   */

  const [mode, setMode] = useState('')

  /**
   * 
   * General functions
   * 
   */

    /**
   * 
   * Component functions
   * 
   */

    const HowToPlay = () => {
        return(
            <div className={`br-howtoplay ${checkNotMobile(screenWidth)?'font-20':'font-15'}`}>
                <Row align='middle' className='br-howtoplay-row'>Mint Components in<div className='neon-text neon-blue'>&nbsp;{'Mint Component'}</div></Row>
                <Row align='middle' className='br-howtoplay-row'><div className='neon-text neon-lblue'>&nbsp;{'OPTIONAL'}&nbsp;</div>Use<div className='neon-text neon-green'>&nbsp;{'$SCRATCH'}&nbsp;</div>to combine components in<div className='neon-text neon-lblue'>&nbsp;{'Component Builder'}</div></Row>
                <Row align='middle' className='br-howtoplay-row'>Lock Components to your Boom Room in<div className='neon-text neon-yellow'>&nbsp;{'Room Construction'}&nbsp;</div>to increase<div className='neon-text neon-green'>&nbsp;{'$SCRATCH'}&nbsp;</div>generation</Row>
                <Row align='middle' className='br-howtoplay-row'><div className='neon-text neon-pink'>&nbsp;{'OPTIONAL'}&nbsp;</div>Decorate your Boom Rooms in<div className='neon-text neon-pink'>&nbsp;{'Room Decorator'}</div></Row>
                <Row align='middle' className='br-howtoplay-row'>Stake your Boom Rooms in<div className='neon-text neon-orange'>&nbsp;{'Staking Grounds'}&nbsp;</div>to earn<div className='neon-text neon-green'>&nbsp;{'$SCRATCH'}</div></Row>
            </div>
        )
    }

    const MenuButtons = () => {
        return(
            <Row className='br-menu'>
                <Link to="/boomrooms/mintcomponent" onClick={() => setMode('componentMint')}><div className={`neon-button neon-blue bigger-on-hover ${mode==='componentMint'?'active':''} ${checkNotMobile(screenWidth)?'br-menu-button':'mobile-br-menu-button'}`}>Mint Component</div></Link>
                <Link to="/boomrooms/componentbuilder" onClick={() => setMode('componenetBuilder')}><div className={`neon-button neon-lblue bigger-on-hover ${mode==='componenetBuilder'?'active':''} ${checkNotMobile(screenWidth)?'br-menu-button':'mobile-br-menu-button'}`}>Component Builder</div></Link>
                <Link to="/boomrooms/roomconstruction" onClick={() => setMode('roomConstruction')}><div className={`neon-button neon-yellow bigger-on-hover ${mode==='roomConstruction'?'active':''} ${checkNotMobile(screenWidth)?'br-menu-button':'mobile-br-menu-button'}`}>Room Construction</div></Link>
                <Link to="/boomrooms/roomdecorator" onClick={() => setMode('roomDecorator')}><div className={`neon-button neon-pink bigger-on-hover ${mode==='roomDecorator'?'active':''} ${checkNotMobile(screenWidth)?'br-menu-button':'mobile-br-menu-button'}`}>Room Decorator</div></Link>
                <Link to="/boomrooms/stakinggrounds" onClick={() => setMode('stakingGrounds')}><div className={`neon-button-off bigger-on-hover ${mode==='stakingGrounds'?'active':''} ${checkNotMobile(screenWidth)?'br-menu-button':'mobile-br-menu-button'}`}>Staking Grounds V1</div></Link>
                <Link to="/boomrooms/stakinggroundsv2" onClick={() => setMode('stakingGroundsv2')}><div className={`neon-button neon-orange bigger-on-hover ${mode==='stakingGroundsv2'?'active':''} ${checkNotMobile(screenWidth)?'br-menu-button':'mobile-br-menu-button'}`}>Staking Grounds V2<NewSticker width={100} top={-45} right={-55}/></div></Link>
            </Row>
        )
    }

  /**
   * 
   * Front End
   * 
   */
    return (

        // // check if user on mobile
        checkNotMobile(screenWidth)?
            // check if user connected to web3 and on Cronos chain
            <div>
                <div className="container2"><img className='banner' src={webStorage + '/boomrooms/boomroombanner.png'} alt="myboomersbanner"/></div>
                <div className='mcolour padded-big center-everything flex-column'>
                    <img className='boomroom-logo' src={webStorage + '/boomrooms/BoomRoomLogo.png'} />

                    {/**How to play */}
                    <div className='br-subtitle'>How to play</div>
                    <HowToPlay />

                    {/**Menu */}
                    <div className='br-subtitle'>Menu</div>
                    <MenuButtons />

                    <Outlet />
                </div>
            </div>

        
        // mobile
        :
            <>
                <div className="container2"><img className='banner' src={webStorage + '/boomrooms/boomroombanner.png'} alt="myboomersbanner"/></div>
                <div className='mcolour padded-big center-everything flex-column'>
                    <img className='mobile-boomroom-logo' src={webStorage + '/boomrooms/BoomRoomLogo.png'} />

                    {/**How to play */}
                    <div className='br-subtitle'>How to play</div>
                    <HowToPlay />

                    {/**Menu */}
                    <div className='br-subtitle'>Menu</div>
                    <MenuButtons />

                    <Outlet />
                </div>
            </>

    )
}

export default BoomRoomGame