
import { useState, useContext, useEffect } from 'react';
import ConnectedContext from './../../context/connectedcontext';
import Web3Context from './../../context/web3context';
import Web3 from 'web3';
import { Row, InputNumber } from 'antd';
import ScreenWidthContext from './../../context/screenwidthcontext';
import Spinner from './../../components/spinner'
import connectedAndGoodChain from './../../components/logic/connectedandgoodchain';
import Web3ContractReadWriteContext from './../../context/web3ContractReadWriteContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { webStorage } from './../../config';
import checkNotMobile from './../../components/logic/mobile';
import { weiToEthWithDecimals } from '../../utils';
import { PGMNavButtons } from './PGMNavButtons';
import { getPGMContractTotalSupply, getPGMMintState, mintLife } from './PGMWeb3Calls';
import { approveERC20, getERC20Allowance, getERC20Balance } from '../../components/utils/web3Utils';
import { PGMConfig } from './PGMConfig';
import CustomIcon from '../../components/CustomIcon/CustomIcon';

export type MemeCoin = 'PUUSH' | 'MERY' | 'RYOSHI'

// function to simplify big numbers keeping 1 decimal place, e.g. 1100000 -> 1.1M
// account for K, M, B, T
export const simplifyBigNumberString = (num: string): string => {
    let n = BigInt(num)
    let s = n.toString()
    let l = s.length
    if (l < 4) {
        return s
    }
    if (l < 7) {
        return s.slice(0, l - 3) + '.' + s.slice(l - 3, l - 2) + 'K'
    }
    if (l < 10) {
        return s.slice(0, l - 6) + '.' + s.slice(l - 6, l - 5) + 'M'
    }
    if (l < 13) {
        return s.slice(0, l - 9) + '.' + s.slice(l - 9, l - 8) + 'B'
    }
    if (l < 16) {
        return s.slice(0, l - 12) + '.' + s.slice(l - 12, l - 11) + 'T'
    }
    return s
};

export const simplifyAndDisplayWeiToEth = (wei: string | bigint): string => {
    // Eth amount
    let eth = weiToEthWithDecimals(wei.toString(), 0)
    return simplifyBigNumberString(eth)
};

export const PGMMint = () => {

    const { account, balance, setBalance, chainID } = useContext(Web3Context)
    const { connected } = useContext(ConnectedContext)
    const { screenWidth } = useContext(ScreenWidthContext)
    const { contractWriteProviders } = useContext(Web3ContractReadWriteContext)

    // hooks for use throughout the mint page
    const [currentSupply, setCurrentSupply] = useState('')
    const [amountToMint, setAmountToMint] = useState(1)
    const [loading1, setLoading1] = useState(false)
    const [mintState, setMintState] = useState(false)

    // Meme specific variables
    // const [puushBalance, setPuushBalance] = useState('0')
    // const [meryBalance, setMeryBalance] = useState('0')
    // const [ryoshiBalance, setRyoshiBalance] = useState('0')

    const [puushAllowance, setPuushAllowance] = useState('0')
    const [meryAllowance, setMeryAllowance] = useState('0')
    const [ryoshiAllowance, setRyoshiAllowance] = useState('0')

    // 1000000000000000000 = 1 cro

    /**
     * 
     * Skeleton Variables
     * 
     */

    const description: string = `
    Welcome to Playground Mini Game! 
    Mint LIVES to enter the Playground and face off against other players. Every round, LIVES in the Playground will be eliminated and sent to the trash can. 
    The game continues until only one LIFE remains. Have the last LIFE standing and win the Grand Prize! Each round you can standby and keep your LIFE in the game or 
    Chick'n it out to gain rewards.
    `

    const maxSupply = '15000'
    const puushMintPriceWei = '33000000000000000000000000'
    const meryMintPriceWei = '180000000000000000000000'
    const ryoshiMintPriceWei = '1700000000000000000000000000'
    const puushMintPriceEthString = weiToEthWithDecimals(puushMintPriceWei, 0)
    const meryMintPriceEthString = weiToEthWithDecimals(meryMintPriceWei, 0)
    const ryoshiMintPriceEthString = weiToEthWithDecimals(ryoshiMintPriceWei, 0)
    const mintStartDate = 'May 17'
    const mindEndDate = 'May 20'

    const MintDescription = () => {
        let m = ''
        if (!checkNotMobile(screenWidth)) {
            m = 'm-'
        }
        return (
            <>
                <div className='lp-mintdescription-row'>{'Mint Start Date: '}<div className={m + 'row-answer'}>{mintStartDate}</div></div>
                <div className='lp-mintdescription-row'>{'Mint End Date: '}<div className={m + 'row-answer'}>{mindEndDate}</div></div>
                <div className='lp-mintdescription-row'>{'Mint Coins:'}<div className={m + 'row-answer'}>{'PUUSH, MERY, RYOSHI'}</div></div>
                <div className='lp-mintdescription-row'>{'PUUSH Mint Price:'}<div className={m + 'row-answer'}>{simplifyBigNumberString(puushMintPriceEthString)}</div></div>
                <div className='lp-mintdescription-row'>{'MERY Mint Price:'}<div className={m + 'row-answer'}>{simplifyBigNumberString(meryMintPriceEthString)}</div></div>
                <div className='lp-mintdescription-row'>{'RYOSHI Mint Price:'}<div className={m + 'row-answer'}>{simplifyBigNumberString(ryoshiMintPriceEthString)}</div></div>
                <div className='lp-mintdescription-row'>{'Max Supply:'}<div className={m + 'row-answer'}>{maxSupply}</div></div>
            </>
        )
    }

    const multiplyBigInts = (a, b): string => {
        return (BigInt(a.toString()) * BigInt(b.toString())).toString()
    }

    const MintButtons = () => {
        return (
            <>
                {MintButton('PUUSH', 'lp-red')}
                {MintButton('MERY', 'lp-blue')}
                {MintButton('RYOSHI', 'lp-orange')}
            </>
        )
    }

    const MintButton = (memeCoin: MemeCoin, color: string) => {
        const hadEnoughAllowance = hasEnoughAllowance(memeCoin)
        return (
            <>
                {
                    hadEnoughAllowance ?
                        <div className={`lp-button ${color} bigger-on-hover text-shadow shadow-button-w`} onClick={() => onMint(memeCoin)}>
                            <Spinner loading={loading1} size={75} />
                            {loading1 ? '' : <div><div>MINT</div><div>with</div><div className='flex-and-center' style={{ gap: '5px' }}><CustomIcon name={memeCoin} size={30} />{memeCoin}</div></div>}
                        </div>
                        :
                        <div className={`lp-button lp-green bigger-on-hover text-shadow shadow-button-w`} onClick={() => onApprove(memeCoin)}>
                            <Spinner loading={loading1} size={75} />
                            {loading1 ? '' : <div><div>APPROVE</div><div className='flex-and-center' style={{ gap: '5px' }}><CustomIcon name={memeCoin} size={30} />{memeCoin}</div></div>}
                        </div>
                }
            </>
        )
    }

    const refreshInfo = async () => {
        const web3 = new Web3(contractWriteProviders)

        // update current supply
        const totalSupply = await getPGMContractTotalSupply(contractWriteProviders)

        // update states
        let mintState: boolean = await getPGMMintState(contractWriteProviders)

        // // Get balances
        // const puushBalance = await getERC20Balance(web3, PGMConfig.PuushContractAddress, account)
        // const meryBalance = await getERC20Balance(web3, PGMConfig.MeryContractAddress, account)
        // const ryoshiBalance = await getERC20Balance(web3, PGMConfig.RyoshiContractAddress, account)

        // Get allowances
        const puushAllowance = await getERC20Allowance(web3, PGMConfig.PuushContractAddress, account, PGMConfig.PGMContractAddress)
        const meryAllowance = await getERC20Allowance(web3, PGMConfig.MeryContractAddress, account, PGMConfig.PGMContractAddress)
        const ryoshiAllowance = await getERC20Allowance(web3, PGMConfig.RyoshiContractAddress, account, PGMConfig.PGMContractAddress)

        // Balance and Allowance states
        // setPuushBalance(puushBalance)
        // setMeryBalance(meryBalance)
        // setRyoshiBalance(ryoshiBalance)

        setPuushAllowance(puushAllowance)
        setMeryAllowance(meryAllowance)
        setRyoshiAllowance(ryoshiAllowance)

        // Mint state
        setMintState(mintState)
        setCurrentSupply(totalSupply.toString())
    }

    // const getMemeTokenBalanceForDisplay = (balance: string): string => {
    //     // Convert balance to ETH and simplify
    //     let balanceEth = weiToEthWithDecimals(balance, 0)
    //     return simplifyBigNumberString(balanceEth)
    // };

    const hasEnoughAllowance = (memeCoin: MemeCoin): boolean => {
        let allowance: string = '0';
        let totalMintCost = '0';
        switch (memeCoin) {
            case 'PUUSH':
                allowance = puushAllowance;
                totalMintCost = multiplyBigInts(puushMintPriceWei, amountToMint);
                break;
            case 'MERY':
                allowance = meryAllowance;
                totalMintCost = multiplyBigInts(meryMintPriceWei, amountToMint);
                break;
            case 'RYOSHI':
                allowance = ryoshiAllowance;
                totalMintCost = multiplyBigInts(ryoshiMintPriceWei, amountToMint);
                break;
            default:
                allowance = '0';
                break;
        };
        return BigInt(allowance) >= BigInt(totalMintCost)
    };

    const onMint = async (memeCoin: MemeCoin) => {
        setLoading1(true)
        // If account is null or '' then return
        if (account === '') {
            toast.error('Connect Wallet to Mint!', { autoClose: 5000 });
            setLoading1(false)
            return
        }
        try {
            let totalCost: string = '0';
            let memeCoinBalance: string = '0';
            const web3 = new Web3(contractWriteProviders)
            switch (memeCoin) {
                case 'PUUSH':
                    totalCost = multiplyBigInts(puushMintPriceWei, amountToMint);
                    memeCoinBalance = await getERC20Balance(web3, PGMConfig.PuushContractAddress, account);
                    break;
                case 'MERY':
                    totalCost = multiplyBigInts(meryMintPriceWei, amountToMint);
                    memeCoinBalance = await getERC20Balance(web3, PGMConfig.MeryContractAddress, account);
                    break;
                case 'RYOSHI':
                    totalCost = multiplyBigInts(ryoshiMintPriceWei, amountToMint);
                    memeCoinBalance = await getERC20Balance(web3, PGMConfig.RyoshiContractAddress, account);
                    break;
                default:
                    totalCost = '0';
                    memeCoinBalance = '0';
                    break;
            };
            if (!mintState) {
                toast.error('Mint is not active.', { autoClose: 5000 });
                setLoading1(false)
                return
            }
            if (BigInt(totalCost) > BigInt(memeCoinBalance)) {
                toast.error(`Insufficient ${memeCoin} balance`, { autoClose: 5000 });
                setLoading1(false)
                return
            }
            await mintLife(contractWriteProviders, account, amountToMint, memeCoin)
            await refreshInfo()
            setLoading1(false)
        } catch (e) {
            console.log(`Error minting: ${e}`)
            setLoading1(false)
        }
    }

    const onApprove = async (memeCoin: MemeCoin) => {
        const web3 = new Web3(contractWriteProviders)
        let contractAddress = ''
        switch (memeCoin) {
            case 'PUUSH':
                contractAddress = PGMConfig.PuushContractAddress;
                break;
            case 'MERY':
                contractAddress = PGMConfig.MeryContractAddress;
                break;
            case 'RYOSHI':
                contractAddress = PGMConfig.RyoshiContractAddress;
                break;
            default:
                contractAddress = '';
                break;
        };
        await approveERC20(web3, contractAddress, PGMConfig.PGMContractAddress, account)
    };

    // update the value in the input number mint box
    const onChange = (value: number) => {
        setAmountToMint(value)
    }

    // load info on user web3 connections
    useEffect(() => {
        if (!connected) return
        if (account === '') return
        if (!contractWriteProviders) return
        const load = async () => {
            refreshInfo()
        }
        load()
    }, [connected, account, contractWriteProviders])

    const inputNumber =
        <Row justify='center' style={{ marginBottom: '20px' }}>
            <div className='lp-mintdescription-fast'>{'Amount to Mint: '}&nbsp;</div><InputNumber className='lp-mint-inputnumber' size="large" min={1} max={100} defaultValue={1} onChange={onChange} />
        </Row>

    const mintDetails =
        <Row justify='center'>
            <div className='lp-mintdescription-fast flex-and-center flex-column'>
                <div className='lp-mintdescription-row'>{'Current Supply: '}{currentSupply}</div>
            </div>
        </Row>

    const connectWalletComp =
        <>
            {
                connectedAndGoodChain(connected, chainID) ?
                    <>
                        <PGMNavButtons />
                        {mintDetails}
                        {inputNumber}
                        <Row justify='center' style={{ display: 'flex', margin: '0px 0px 40px 0px' }}>
                            <MintButtons />
                        </Row>
                    </>
                    :
                    <>
                        <Row justify='center'>
                            <div className='lp-mintdescription-fast'>
                                <div className='lp-mintdescription-connectwallet'>{'Connect Wallet To Mint!'}</div>
                            </div>
                        </Row>
                    </>
            }

        </>



    return (
        <>
            <ToastContainer position="top-center" />
            {
                checkNotMobile(screenWidth) ?
                    <div>
                        <div className='centered-x-and-y'><img className='banner2' src={webStorage + '/playground/meme/playground_meme_banner.png'} alt='' /></div>
                        <div className='lp-nftbanner-line' />
                        <Row justify='center'>
                            <div className='lp-description'>
                                {description}
                            </div>
                        </Row>
                        <Row justify='center'>
                            <div className='lp-mintrow'>
                                <div>
                                    <img className='lp-mintgif' src={webStorage + '/playground/meme/playground_meme_mint_info.png'} alt='' />
                                </div>
                                <div className='lp-mintdescription'>
                                    <MintDescription />
                                </div>
                            </div>
                        </Row>
                        {connectWalletComp}
                    </div>

                    :
                    //MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================
                    <div>
                        <div className='centered-x-and-y'><img className='banner2' src={webStorage + '/playground/meme/playground_meme_banner.png'} alt='' /></div>
                        <div className='mobile-lp-nftbanner-line' />
                        <Row justify='center'>
                            <div className='mobile-lp-description'>
                                {description}
                            </div>
                        </Row>
                        <Row justify='center'>
                            <div className='mobile-lp-mintrow flex-and-center flex-column'>
                                <div>
                                    <img className='mobile-lp-mintgif' src={webStorage + '/playground/meme/playground_meme_mint_info.png'} alt='' />
                                </div>
                                <div className='mobile-lp-mintdescription'>
                                    <MintDescription />
                                </div>
                            </div>
                        </Row>
                        {connectWalletComp}
                    </div>
            }
        </>

    )
}