import Web3 from 'web3';
import { toast } from 'react-toastify';
import { PGMConfig } from './PGMConfig';
import { MemeCoin } from './PGMMint';

const PGM_CONTRACT_ADDRESS = PGMConfig.PGMContractAddress;

export const getCoinPrizePoolData = async (contractWriteProviders: any, address: string): Promise<string> => {
    const web3 = new Web3(contractWriteProviders);

    const coinPrizePoolSig = "CoinPrizePoolMap(address)";
    const coinPrizePoolSelector = web3.eth.abi.encodeFunctionSignature(coinPrizePoolSig);

    try {
        const coinPrizePoolEncodedReturnData = await web3.eth.call({
            to: PGM_CONTRACT_ADDRESS,
            data: coinPrizePoolSelector + web3.eth.abi.encodeParameter('address', address).slice(2)
        });

        // Decode the return data
        const coinPrizePool = web3.eth.abi.decodeParameter('uint256', coinPrizePoolEncodedReturnData);

        // Type check string
        if (typeof coinPrizePool !== 'string') {
            throw new Error('Decoded coinPrizePool is not a string');
        }

        return coinPrizePool;
    } catch (error) {
        console.error('Error fetching coin prize pool:', error);
        throw error;
    }
};

export const getLastRoundStartTimeData = async (contractWriteProviders: any): Promise<string> => {
    const web3 = new Web3(contractWriteProviders);

    const lastRoundStartTimeSig = "lastRoundStartTime()";
    const lastRoundStartTimeSelector = web3.eth.abi.encodeFunctionSignature(lastRoundStartTimeSig);

    try {
        const lastRoundStartTimeEncodedReturnData = await web3.eth.call({
            to: PGM_CONTRACT_ADDRESS,
            data: lastRoundStartTimeSelector
        });

        // Decode the return data
        const lastRoundStartTime = web3.eth.abi.decodeParameter('uint256', lastRoundStartTimeEncodedReturnData);

        // Type check string
        if (typeof lastRoundStartTime !== 'string') {
            throw new Error('Decoded lastRoundStartTime is not a string');
        }

        return lastRoundStartTime;
    } catch (error) {
        console.error('Error fetching last round start time:', error);
        throw error;
    }
};

export const getRoundCountData = async (contractWriteProviders: any): Promise<string> => {
    const web3 = new Web3(contractWriteProviders);

    const roundCountSig = "roundCount()";
    const roundCountSelector = web3.eth.abi.encodeFunctionSignature(roundCountSig);

    try {
        const roundCountEncodedReturnData = await web3.eth.call({
            to: PGM_CONTRACT_ADDRESS,
            data: roundCountSelector
        });

        // Decode the return data
        const roundCount = web3.eth.abi.decodeParameter('uint256', roundCountEncodedReturnData);

        // Type check string
        if (typeof roundCount !== 'string') {
            throw new Error('Decoded roundCount is not a string');
        }

        return roundCount;
    } catch (error) {
        console.error('Error fetching round count:', error);
        throw error;
    }
};

export const getViewPlayerRewardsData = async (contractWriteProviders: any): Promise<string[]> => {
    const web3 = new Web3(contractWriteProviders);

    const viewPlayerRewardsSig = "viewPlayerRewards()";
    const viewPlayerRewardsSelector = web3.eth.abi.encodeFunctionSignature(viewPlayerRewardsSig);

    try {
        const viewPlayerRewardsEncodedReturnData = await web3.eth.call({
            to: PGM_CONTRACT_ADDRESS,
            data: viewPlayerRewardsSelector
        });

        // Decode viewPlayerRewards return data
        const playerRewardsReturnValues = web3.eth.abi.decodeParameters(
            ['uint256[]'],
            viewPlayerRewardsEncodedReturnData
        );

        // Extract and verify decoded values
        const playerRewardsList = playerRewardsReturnValues[0]

        // Type checks
        if (!Array.isArray(playerRewardsList) || !playerRewardsList.every(item => typeof item === 'string')) {
            throw new Error('Decoded playerRewardsList is not an array of strings or numbers');
        }

        return playerRewardsList
    } catch (error) {
        console.error('Error fetching PGM contract data:', error);
        return null;
    }
};

export const getViewCanExitData = async (contractWriteProviders: any) => {
    const web3 = new Web3(contractWriteProviders);

    const viewCanExitSig = "viewCanExit()";
    const viewCanExitSelector = web3.eth.abi.encodeFunctionSignature(viewCanExitSig);

    try {
        const viewCanExitEncodedReturnData = await web3.eth.call({
            to: PGM_CONTRACT_ADDRESS,
            data: viewCanExitSelector
        });

        // Decode viewCanExit return data
        const canExitReturnValue = web3.eth.abi.decodeParameter(
            'bool',
            viewCanExitEncodedReturnData
        );

        // Extract and verify decoded values
        const canExit = canExitReturnValue;

        // Type checks
        if (typeof canExit !== 'boolean') {
            throw new Error('Decoded canExit is not a boolean');
        }

        return canExit
    } catch (error) {
        console.error('Error fetching PGM contract data:', error);
        return null;
    }
};

export const exitPlayground = async (tokenIDs: string[], account: string, contractWriteProviders: any) => {
    const web3 = new Web3(contractWriteProviders);

    try {
        // turn tokenIDs and pNums into numbers
        const numTokenIDs = tokenIDs.map((id) => Number(id));

        // 1. Encode the function parameters
        const encodedParams = web3.eth.abi.encodeParameters(['uint256[]'], [numTokenIDs]);

        // 2. Encode the function signature to its corresponding bytes4 representation
        const functionSignature = 'exitPlayground(uint256[])';
        const functionSelector = web3.eth.abi.encodeFunctionSignature(functionSignature);

        // 3. Construct the raw transaction data
        const txData = functionSelector + encodedParams.substring(2);

        // Try to estimate gas
        const gas = await web3.eth.estimateGas({
            from: account,
            to: PGM_CONTRACT_ADDRESS,
            data: txData
        });

        await toast.promise(
            web3.eth.sendTransaction({
                from: account,
                to: PGM_CONTRACT_ADDRESS,
                data: txData,
            }),
            {
                pending: 'Attempting to Exit lives...',
                success: 'Exit successful!',
                error: 'Failed to exit lives.'
            }
        );
    } catch (error) {
        console.error('Failed to exit lives:', error);
        toast.error('Error exiting.', { autoClose: 5000 });
    }
};

export const mintLife = async (contractWriteProviders: any, account: string, lifeAmount: number, memeCoin: MemeCoin) => {
    const web3 = new Web3(contractWriteProviders);

    let coinAddress: string = '';

    switch (memeCoin) {
        case "PUUSH":
            coinAddress = PGMConfig.PuushContractAddress;
            break;
        case "MERY":
            coinAddress = PGMConfig.MeryContractAddress;
            break;
        case "RYOSHI":
            coinAddress = PGMConfig.RyoshiContractAddress;
            break;
        default:
            break;
    };

    try {
        // 1. Encode the function parameters
        const encodedParams = web3.eth.abi.encodeParameters(['address', 'address', 'uint256'], [account, coinAddress, lifeAmount]);

        // 2. Encode the function signature to its corresponding bytes4 representation
        const functionSignature = 'mintLife(address,address,uint256)';
        const functionSelector = web3.eth.abi.encodeFunctionSignature(functionSignature);

        // 3. Construct the raw transaction data
        const txData = functionSelector + encodedParams.substring(2);

        // Try to estimate gas
        const gas = await web3.eth.estimateGas({
            from: account,
            to: PGM_CONTRACT_ADDRESS,
            data: txData
        });

        await toast.promise(
            web3.eth.sendTransaction({
                from: account,
                to: PGM_CONTRACT_ADDRESS,
                data: txData,
            }),
            {
                pending: `Minting lives with ${memeCoin}...`,
                success: `Mint with ${memeCoin} successful!`,
                error: 'Failed to mint lives.'
            }
        );
    } catch (error) {
        console.error('Failed to mint lives:', error);
        toast.error('Error minting.', { autoClose: 5000 });
    }
};

export const getPGMContractTotalSupply = async (contractWriteProviders: any) => {
    const web3 = new Web3(contractWriteProviders);

    const totalSupplySig = "totalSupply()";
    const totalSupplySelector = web3.eth.abi.encodeFunctionSignature(totalSupplySig);

    try {
        const totalSupplyEncodedReturnData = await web3.eth.call({
            to: PGM_CONTRACT_ADDRESS,
            data: totalSupplySelector
        });

        const totalSupply = web3.eth.abi.decodeParameter('uint256', totalSupplyEncodedReturnData);

        return totalSupply;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getPGMMintState = async (contractWriteProviders: any): Promise<boolean> => {
    const web3 = new Web3(contractWriteProviders);

    const mintStateSig = "mintState()";
    const mintStateSelector = web3.eth.abi.encodeFunctionSignature(mintStateSig);

    try {
        const mintStateEncodedReturnData = await web3.eth.call({
            to: PGM_CONTRACT_ADDRESS,
            data: mintStateSelector
        });

        const mintState = web3.eth.abi.decodeParameter('bool', mintStateEncodedReturnData);

        // Check if mintState is indeed a boolean
        if (typeof mintState !== 'boolean') {
            throw new Error('Decoded mintState is not a boolean');
        }

        return mintState;
    } catch (error) {
        console.error(error);
        return null;
    }
};