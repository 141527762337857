import { webStorage } from "../config"
import { useState, useContext, useEffect} from 'react';
import ConnectedContext from '../context/connectedcontext';
import Web3Context from '../context/web3context';
import ScreenWidthContext from '../context/screenwidthcontext';
import { fetchMetadata } from "../components/utils/api_calls";

import './myZoomers.css'
import { Link } from "react-router-dom";
import Spinner from "../components/spinner";
import { zoomerRank } from "./zoomerTraitPercentages";

export const MyZoomers = () => {

    const {account, setAccount, balance, setBalance, chainID, setChainID} = useContext(Web3Context)
    const {connected, setConnected} = useContext(ConnectedContext)
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)

    const [loading, setLoading] = useState(true)

    const zoomerContractAddress = '0x3D03292D428fd7d6C81a6E76b4A333A3d8a0E296'
    const [zoomerMeta, setZoomerMeta] = useState([] as any[])

    const ZoomerNFTs = (props) => {
        let list = props.list;
        let listItems = list.map((item) => {
            return(
                <Link to={`/zoomer/${item.id}`}>
                    <div className="zoomer-card bigger-on-hover">
                        <div className="zoomer-card-image-container">
                            <img className='zoomer-card-image' src={item.image} />
                        </div>
                        <div className='zoomer-card-bottom'>
                            <div>{item.name}</div>
                            <div style={{fontStyle:'italic', fontSize: '15px'}}>{'Rank '+zoomerRank[item.id]}</div>
                        </div>
                    </div>
                </Link>
            )
        })
        return listItems
    }

    const checkLoading = (element, loading: boolean, width: number) => {
        return loading? <Spinner loading={true} size={width} /> : element
    }
    useEffect(() => {
        setLoading(false)
      },[zoomerMeta])

    useEffect(() => {
        const getMeta = async() => {
            setLoading(true)
            let metadata = await fetchMetadata(account, zoomerContractAddress)
            setZoomerMeta(metadata)
        }
        let isApiSubscribed = true;
        if(isApiSubscribed && account !== ''){
            getMeta()
        }
        return () => {isApiSubscribed = false;};
      },[account])

    return(
        <>
            <div className='centered-x-and-y'><img className='banner' src={webStorage + '/zoomers/zoomer_mint_banner.png'} alt="foo"/></div>
            <div className="ro">
                <div className='zoomer-top-box'>
                    <div>Inventory</div><div className="z-span"/>{checkLoading(<div style={{fontStyle: 'italic', color: 'rgb(211, 211, 211)'}}>{zoomerMeta.length}</div>, loading, 20)}
                </div>
            </div>
            <div style={{minHeight: '800px'}}>
                <div className='zoomers-container'>
                    {checkLoading(<ZoomerNFTs list={zoomerMeta} />, loading, 200)}
                </div>
            </div>

        </>
    )
}