import { Row } from "antd"
import { Link } from "react-router-dom"

export const PGMNavButtons = () => {
    return (
        <Row justify={'center'} style={{ marginBottom: '20px', display: 'flex', gap: '20px', fontWeight: 'bold' }}>
            {/* <Link to='/PlaygroundMemeMint' >
                <div className='playground-button shadow-button-w' style={{ textAlign: 'center', backgroundColor: 'lightblue', color: 'black', width: '190px' }}>
                    MINT PAGE
                </div>
            </Link> */}
            <Link to='/PlaygroundMeme' >
                <div className='playground-button shadow-button-w' style={{ textAlign: 'center', backgroundColor: 'lightblue', color: 'black', width: '190px' }}>
                    PLAYGROUND PAGE
                </div>
            </Link>
            <Link to='/PlaygroundMemeStats' >
                <div className='playground-button shadow-button-w' style={{ textAlign: 'center', backgroundColor: 'lightblue', color: 'black', width: '190px' }}>
                    STATS PAGE
                </div>
            </Link>
        </Row>
    )
}
