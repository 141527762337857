import Scratch from "./../../contracts/Scratch.json"
import toContract from './../../types/truffle_contracts';
import Web3 from 'web3';

const scratch = toContract(Scratch)

export const getScratchBalance = async(provider: any, account: string): Promise<String> => {
    scratch.setProvider(provider)
    let scratchBalance = '0'
    let sc = await scratch.deployed()
    let balanceWei = await sc.balanceOf(account)
    scratchBalance = Web3.utils.fromWei(balanceWei.toString(), 'ether').toString()

    return scratchBalance
}