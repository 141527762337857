interface ExploreCard {
    linkTo: string;
    background: string;
    title: string;
    subtitle: string;
}

export const exploreObjects: ExploreCard[] = [
    {
        linkTo: '/myboomers',
        background: '/frontpage/boomerBanner1.png',
        title: 'My Boomers',
        subtitle: 'View your Boomer gen 0 NFTs and withdraw from mattress fund'
    },
    {
        linkTo: '/myzoomers',
        background: '/frontpage/zoomers_banner1.png',
        title: 'My Zoomers',
        subtitle: 'View your collection, change variants, and more'
    },
    {
        linkTo: '/boomrooms',
        background: '/frontpage/roomBanner1.png',
        title: 'Boom Rooms',
        subtitle: 'Construct, Decorate, and Stake your rooms'
    },
    {
        linkTo: '/launchpad',
        background: '/frontpage/launchpadBanner2.png',
        title: 'Launchpad',
        subtitle: 'Mint the latest NFTs released on our launchpad'
    },
    {
        linkTo: '/bazaar',
        background: '/frontpage/bazaar_banner1.png',
        title: 'Shop',
        subtitle: 'Buy with crypto'
    },
    {
        linkTo: '/pawnshop',
        background: '/frontpage/pawnshopBanner1.png',
        title: 'Mini games',
        subtitle: 'Play Bingo and more'
    },
    {
        linkTo: '/news/0',
        background: '/frontpage/news_banner1.png',
        title: 'News',
        subtitle: 'Latest News'
    },
    {
        linkTo: '/about',
        background: '/frontpage/roadmapBanner2.png',
        title: 'About Us',
        subtitle: 'Past, Present, and Future of Boomer Squad'
    },
    {
        linkTo: '/comics',
        background: '/frontpage/storyBanner2.png',
        title: 'Comics',
        subtitle: 'Read about the story behind Boomers and Zoomers'
    },
]

export const newExploreObjects: ExploreCard[] = [
    {
        linkTo: '/PlaygroundMeme',
        background: '/playground/meme/playground_meme_button.png',
        title: 'Playground Meme',
        subtitle: 'Mint lives using your favorite Meme Coins'
    },
    {
        linkTo: '/launchpad/sousou',
        background: '/launchpad/sousou/sousou_button.png',
        title: 'Mint Sou-Sou Bear Den',
        subtitle: 'Mint the latest launchpad project'
    },
    {
        linkTo: 'https://boomerarcade.io/',
        background: '/arcade/BA_LOGO.png',
        title: 'Boomer Arcade',
        subtitle: 'Stake, Play, and Earn'
    },
    {
        linkTo: '/raffles',
        background: '/frontpage/frontpage-button-croraffle.png',
        title: 'Raffles',
        subtitle: 'Buy Raffle tickets to win prizes'
    },
    {
        linkTo: '/merch',
        background: '/frontpage/merch_front_banner.png',
        title: 'Merch',
        subtitle: 'Buy Boomer Squad Merch'
    }
]