import { Link } from 'react-router-dom';
import { webStorage } from '../config';
import './raffles.css'

export const RafflesMain = () => {
    return (
        <div className='raffles-main'>
            <Link to='/croraffle' className='flex-and-center'>
                <img className='pawnshop-menu-button shadow-button' src={webStorage + '/pawnshop/banner_croraffle.png'}/>
            </Link>
            <Link to='/boomerraffle' className='flex-and-center'>
                <img className='pawnshop-menu-button shadow-button' src={webStorage + '/pawnshop/rafflebanner.png'}/>
            </Link>
        </div>
    )
}