import './../styles.css'
import { useState, useEffect } from 'react';

function Spinner (props) {

    const loading = props.loading
    let colour = props.colour
    const size = props.size

    colour = colour?colour:'white'

  let list = [
    "sk-circle1",
    "sk-circle2",
    "sk-circle3",
    "sk-circle4",
    "sk-circle5",
    "sk-circle6",
    "sk-circle7",
    "sk-circle8",
    "sk-circle9",
    "sk-circle10",
    "sk-circle11",
    "sk-circle12"
  ]
  useEffect(()=>{
    for(let i in list){
      if(document.querySelector(`.${list[i]}`) !== null){
        var style = (document.querySelector(`.${list[i]}`) as HTMLElement).style;
        style.setProperty('--spiner-bgc', `${colour}`);
      }
    }
  },[])

  return(
      loading?

    <div className={`sk-fading-circle`} style={{width: size, height: size}}>
        <div className={`sk-circle1 sk-circle `}></div>
        <div className={`sk-circle2 sk-circle `}></div>
        <div className={`sk-circle3 sk-circle  `}></div>
        <div className={`sk-circle4 sk-circle  `}></div>
        <div className={`sk-circle5 sk-circle  `}></div>
        <div className={`sk-circle6 sk-circle  `}></div>
        <div className={`sk-circle7 sk-circle  `}></div>
        <div className={`sk-circle8 sk-circle  `}></div>
        <div className={`sk-circle9 sk-circle  `}></div>
        <div className={`sk-circle10 sk-circle `}></div>
        <div className={`sk-circle11 sk-circle `}></div>
        <div className={`sk-circle12 sk-circle `}></div>
    </div>
    :
    <>
    </>
  )
}
export default Spinner