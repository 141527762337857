import { CosmeticCollection } from "./boomroom_interfaces"

export const getCosmeticInfo = (tokenID: number, roomSize: string) => {
    let id = tokenID

    // big ass diamond
    if(id < 640){
        return {
            imageCode: '600001',
            collectionName: 'Boomer Squad', 
            editionName: 'Big Ass Diamond', 
            coverImage: 'coverbigassdiamond', 
            roomImage: 'roombigassdiamond',
            description: 'The first ever drop for the Boomer Squad Boom Room cosmetic collection. This NFT was given to those from the beginning as a sign of appreciation for the dedication to the project. Although all members of our Boomer Squad community are important we wanted to give this as a thank you to those who minted a Gen 0 Boomer and still own one at the time of the drop 6 months later.'
        } as CosmeticCollection
    }

    //boomer patrol
    if(id < 650){
        return {
            imageCode: '600002',
            collectionName: 'Boomer Squad', 
            editionName: 'Boomer Patrol', 
            coverImage: 'coverboomerpatrol', 
            roomImage: 'roomboomerpatrol',
            description: 'Given to the members of our Boomer Patrol, which is the role given to those who operate as our mods in our discord server.'
        } as CosmeticCollection
    }

    // ambassador
    if(id < 670){
        return {
            imageCode: '600003',
            collectionName: 'Boomer Squad', 
            editionName: 'Ambassador', 
            coverImage: 'coverambassador', 
            roomImage: 'roomambassador',
            description: 'Given to the members of our Ambassador program which consists of comunity members who go above and beyond to represent the Boomer Squad community.'
        } as CosmeticCollection
    }

    // battle of boomers
    if(id < 671){
        return {
            imageCode: '600004',
            collectionName: 'Boomer Squad', 
            editionName: 'Battle of the Boomers V1 Winner', 
            coverImage: 'coverbattleoftheboomersseason1', 
            roomImage: 'roombattleoftheboomersseason1',
            description: 'Awarded to the winner of our first Battle of the Boomers which was a bracketed tournament where the community voted on their favorite 1 of 1 ledgendary in the Gen 0 collection'
        } as CosmeticCollection
    }

    // poker boomer
    if(id < 672){
        return {
            imageCode: '600005',
            collectionName: 'Boomer Squad', 
            editionName: 'Poker dood', 
            coverImage: 'coverpokerdood', 
            roomImage: 'roompokerdood',
            description: 'Created to commemorate the dominance of our very own community member dood winning back to back cross community poker events representing representing Boomer Squad'
        } as CosmeticCollection
    }

    // chowtime achievements
    if(id < 673){
        return {
            imageCode: '600006',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Gold Hungry Boomer', 
            coverImage: 'coverhungryhungryboomergold', 
            roomImage: 'roomhungryhungryboomergold',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 674){
        return {
            imageCode: '600007',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Silver Hungry Boomer', 
            coverImage: 'coverhungryhungryboomersilver', 
            roomImage: 'roomhungryhungryboomersilver',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 675){
        return {
            imageCode: '600008',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Bronze Hungry Boomer', 
            coverImage: 'coverhungryhungryboomerbronze', 
            roomImage: 'roomhungryhungryboomerbronze',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 676){
        return {
            imageCode: '600009',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Gold Carrot Top', 
            coverImage: 'covercarrottopgold', 
            roomImage: 'roomcarrottopgold',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 677){
        return {
            imageCode: '600010',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Silver Carrot Top', 
            coverImage: 'covercarrottopsilver', 
            roomImage: 'roomcarrottopsilver',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 678){
        return {
            imageCode: '600011',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Bronze Carrot Top', 
            coverImage: 'covercarrottopbronze', 
            roomImage: 'roomcarrottopbronze',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 679){
        return {
            imageCode: '600012',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Gold King of the Roost', 
            coverImage: 'coverkingoftheroostgold', 
            roomImage: 'roomkingoftheroostgold',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 680){
        return {
            imageCode: '600013',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Silver King of the Roost', 
            coverImage: 'coverkingoftheroostsilver', 
            roomImage: 'roomkingoftheroostsilver',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 681){
        return {
            imageCode: '600014',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Bronze King of the Roost', 
            coverImage: 'coverkingoftheroostbronze', 
            roomImage: 'roomkingoftheroostbronze',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 682){
        return {
            imageCode: '600015',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Gold Town Drunk', 
            coverImage: 'covertowndrunkgold', 
            roomImage: 'roomtowndrunkgold',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 683){
        return {
            imageCode: '600016',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Silver Town Drunk', 
            coverImage: 'covertowndrunksilver', 
            roomImage: 'roomtowndrunksilver',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 684){
        return {
            imageCode: '600017',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Bronze Town Drunk', 
            coverImage: 'covertowndrunkbronze', 
            roomImage: 'roomtowndrunkbronze',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 685){
        return {
            imageCode: '600018',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Gold Dentures Not Required', 
            coverImage: 'coverdenturesnotrequiredgold', 
            roomImage: 'roomdenturesnotrequiredgold',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 686){
        return {
            imageCode: '600019',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Silver Dentures Not Required', 
            coverImage: 'coverdenturesnotrequiredsilver', 
            roomImage: 'roomdenturesnotrequiredsilver',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 687){
        return {
            imageCode: '600020',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Bronze Dentures Not Required', 
            coverImage: 'coverdenturesnotrequiredbronze', 
            roomImage: 'roomdenturesnotrequiredbronze',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 688){
        return {
            imageCode: '600021',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Gold Taco Tuesday', 
            coverImage: 'covertacotuesdaygold', 
            roomImage: 'roomtacotuesdaygold',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 689){
        return {
            imageCode: '600022',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Silver Taco Tuesday', 
            coverImage: 'covertacotuesdaysilver', 
            roomImage: 'roomtacotuesdaysilver',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 690){
        return {
            imageCode: '600023',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Bronze Taco Tuesday', 
            coverImage: 'covertacotuesdaybronze', 
            roomImage: 'roomtacotuesdaybronze',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 691){
        return {
            imageCode: '600024',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Gold Early Bird Special', 
            coverImage: 'coverearlybirdspecialgold', 
            roomImage: 'roomearlybirdspecialgold',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 692){
        return {
            imageCode: '600025',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Silver Early Bird Special', 
            coverImage: 'coverearlybirdspecialsilver', 
            roomImage: 'roomearlybirdspecialsilver',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }
    if(id < 693){
        return {
            imageCode: '600026',
            collectionName: 'Boomer Squad - Chow Time V1 Awards', 
            editionName: 'Bronze Early Bird Special', 
            coverImage: 'coverearlybirdspecialbronze', 
            roomImage: 'roomearlybirdspecialbronze',
            description: 'Chow time was Boomer Squads very first on-chain game which included awards to those that went above the rest in specific categories of the game. These cosmetics were created to commemorate those acheivements.'
        } as CosmeticCollection
    }

    // CronoBallz 
    if(id < 806){
        return {
            imageCode: '600027',
            collectionName: 'CronoBallz', 
            editionName: 'CronoBallz Boy', 
            coverImage: 'covercronoballzboy', 
            roomImage: 'roomcronoballzboy',
            description: "In support of the CronoBallz project built on Cronos everyone who minted a Cronoballz was airdropped a free Boom Room cosmetic. Cronoballz is a charity project geared to helping kids in need by giving them the financial support needed to play orginized sports that they wouldn't normally have access to."
        } as CosmeticCollection
    }
    if(id < 919){
        return {
            imageCode: '600028',
            collectionName: 'CronoBallz', 
            editionName: 'CronoBallz Girl', 
            coverImage: 'covercronoballzgirl', 
            roomImage: 'roomcronoballzgirl',
            description: "In support of the CronoBallz project built on Cronos everyone who minted a Cronoballz was airdropped a free Boom Room cosmetic. Cronoballz is a charity project geared to helping kids in need by giving them the financial support needed to play orginized sports that they wouldn't normally have access to."
        } as CosmeticCollection
    }
    if(id < 1031){
        return {
            imageCode: '600029',
            collectionName: 'CronoBallz', 
            editionName: 'CronoBallz Helmet', 
            coverImage: 'covercronoballzhelmet',
            roomImage: 'roomcronoballzhelmet',
            description: "In support of the CronoBallz project built on Cronos everyone who minted a Cronoballz was airdropped a free Boom Room cosmetic. Cronoballz is a charity project geared to helping kids in need by giving them the financial support needed to play orginized sports that they wouldn't normally have access to."
        } as CosmeticCollection
    }
    if(id < 1100){
        return {
            imageCode: '600030',
            collectionName: 'Cronos Fossils Gen 2', 
            editionName: 'Cronos Fossils v1',
            coverImage: 'covercronosfossilsgen2v1',
            roomImage: 'roomcronosfossilsgen2v1',
            description: "The first ever project to launch on the Boomer Squad Launchpad. These cosmetics commemorate the Cronos Fossils Gen 2 collection of 600 Fossils designed and created by a team of Geologists."
        } as CosmeticCollection
    }
    if(id < 1169){
        return {
            imageCode: '600031',
            collectionName: 'Cronos Fossils Gen 2', 
            editionName: 'Cronos Fossils v2',
            coverImage: 'covercronosfossilsgen2v2',
            roomImage: 'roomcronosfossilsgen2v2',
            description: "The first ever project to launch on the Boomer Squad Launchpad. These cosmetics commemorate the Cronos Fossils Gen 2 collection of 600 Fossils designed and created by a team of Geologists."
        } as CosmeticCollection
    }
    if(id < 1238){
        return {
            imageCode: '600032',
            collectionName: 'Cronos Fossils Gen 2', 
            editionName: 'Cronos Fossils v3',
            coverImage: 'covercronosfossilsgen2v3',
            roomImage: 'roomcronosfossilsgen2v3',
            description: "The first ever project to launch on the Boomer Squad Launchpad. These cosmetics commemorate the Cronos Fossils Gen 2 collection of 600 Fossils designed and created by a team of Geologists."
        } as CosmeticCollection
    }
    if(id < 1307){
        return {
            imageCode: '600033',
            collectionName: 'Cronos Fossils Gen 2', 
            editionName: 'Cronos Fossils v4',
            coverImage: 'covercronosfossilsgen2v4',
            roomImage: 'roomcronosfossilsgen2v4',
            description: "The first ever project to launch on the Boomer Squad Launchpad. These cosmetics commemorate the Cronos Fossils Gen 2 collection of 600 Fossils designed and created by a team of Geologists."
        } as CosmeticCollection
    }
    if(id < 1376){
        return {
            imageCode: '600034',
            collectionName: 'Cronos Fossils Gen 2', 
            editionName: 'Cronos Fossils v5',
            coverImage: 'covercronosfossilsgen2v5',
            roomImage: 'roomcronosfossilsgen2v5',
            description: "The first ever project to launch on the Boomer Squad Launchpad. These cosmetics commemorate the Cronos Fossils Gen 2 collection of 600 Fossils designed and created by a team of Geologists."
        } as CosmeticCollection
    }
    if(id < 1445){
        return {
            imageCode: '600035',
            collectionName: 'Cronos Fossils Gen 2', 
            editionName: 'Cronos Fossils v6',
            coverImage: 'covercronosfossilsgen2v6',
            roomImage: 'roomcronosfossilsgen2v6',
            description: "The first ever project to launch on the Boomer Squad Launchpad. These cosmetics commemorate the Cronos Fossils Gen 2 collection of 600 Fossils designed and created by a team of Geologists."
        } as CosmeticCollection
    }
    if(id < 1513){
        return {
            imageCode: '600036',
            collectionName: 'Cronos Fossils Gen 2', 
            editionName: 'Cronos Fossils v7',
            coverImage: 'covercronosfossilsgen2v7',
            roomImage: 'roomcronosfossilsgen2v7',
            description: "The first ever project to launch on the Boomer Squad Launchpad. These cosmetics commemorate the Cronos Fossils Gen 2 collection of 600 Fossils designed and created by a team of Geologists."
        } as CosmeticCollection
    }
    if(id < 1581){
        return {
            imageCode: '600037',
            collectionName: 'Cronos Fossils Gen 2', 
            editionName: 'Cronos Fossils v8',
            coverImage: 'covercronosfossilsgen2v8',
            roomImage: 'roomcronosfossilsgen2v8',
            description: "The first ever project to launch on the Boomer Squad Launchpad. These cosmetics commemorate the Cronos Fossils Gen 2 collection of 600 Fossils designed and created by a team of Geologists."
        } as CosmeticCollection
    }
    if(id < 1649){
        return {
            imageCode: '600038',
            collectionName: 'Cronos Fossils Gen 2', 
            editionName: 'Cronos Fossils v9',
            coverImage: 'covercronosfossilsgen2v9',
            roomImage: 'roomcronosfossilsgen2v9',
            description: "The first ever project to launch on the Boomer Squad Launchpad. These cosmetics commemorate the Cronos Fossils Gen 2 collection of 600 Fossils designed and created by a team of Geologists."
        } as CosmeticCollection
    }
    if(id < 1985){
        return {
            imageCode: '600039',
            collectionName: "Bob's Adventure Gen 1", 
            editionName: "Bob's Adventure v1",
            coverImage: 'coverbobsadventurev1',
            roomImage: 'roombobsadventurev1',
            description: "Launched on the Boomer Squad Launchpad. These cosmetics commemorate the Bob's adventure Gen 1 collection of 666 hand drawn Humans, Cyborgs and Reptiles."
        } as CosmeticCollection
    }
    if(id < 2321){
        return {
            imageCode: '600040',
            collectionName: "Bob's Adventure Gen 1", 
            editionName: "Bob's Adventure v2",
            coverImage: 'coverbobsadventurev2',
            roomImage: 'roombobsadventurev2',
            description: "Launched on the Boomer Squad Launchpad. These cosmetics commemorate the Bob's adventure Gen 1 collection of 666 hand drawn Humans, Cyborgs and Reptiles."
        } as CosmeticCollection
    }
    if(id < 3323){
        return {
            imageCode: '600041',
            collectionName: "Gen 0 Customs",
            editionName: "Santa Boomer",
            coverImage: 'coversantaboomer',
            roomImage: 'roomsantaboomer',
            description: "Part of the Gen 0 Customs collection. This Santa Boomer was airdropped to all Boomer Squad Gen 0 holders for the 2022 Holiday Season! Created by Wonderman."
        } as CosmeticCollection
    }
    if(id < 3324){
        return {
            imageCode: '600042',
            collectionName: "Gen 0 Customs",
            editionName: "Kez Custom",
            coverImage: 'coverkezboomer',
            roomImage: 'roomkezboomer',
            description: "Winner of a custom cosmetic raffle created by Wonderman. Kez and his best friend Kobe!"
        } as CosmeticCollection
    }
    if(id < 3824){
        return {
            imageCode:  roomSize==='Single'?'600043': 
                        roomSize==='Studio'?'600044':
                        roomSize==='Double'?'600045':
                        roomSize==='Loft'?'600046':
                        roomSize==='Penthouse'?'600047':
                        '600043', // in case of error, use single
            collectionName: "BDM Cosmetic Drops",
            editionName: "V1",
            coverImage: 'coverBDMv1',
            roomImage: 'roomBDMv1',
            description: "Brought to you by the team behind BDM this 3D cosmetic was designed to give you the look and feel of a Boom Room decorated by a Brain Dead Monkey."
        } as CosmeticCollection
    }
    if(id < 4828){
        return {
            imageCode:  '600048',
            collectionName: "Gen 0 Customs",
            editionName: "1 Year Anniversary",
            coverImage: 'coveranniversary1',
            roomImage: 'roomanniversary1',
            description: "Part of the Gen 0 Customs collection. This Anniversary Boomer was airdropped to all Boomer Squad Gen 0 holders for the 1 Year Anniversary of minting Gen 0. Created by Wonderman."
        } as CosmeticCollection
    }
    return {
        imageCode: '600000',
        collectionName: 'error',
        editionName: 'error', 
        coverImage: 'error',
        roomImage: 'error',
        description: "error"
    } as CosmeticCollection
}