export const bs_metadata = [
    {
     "name": "Boomer Squad #1",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1.png",
     "id": 1,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2.png",
     "id": 2,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3.png",
     "id": 3,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4.png",
     "id": 4,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5.png",
     "id": 5,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #6",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6.png",
     "id": 6,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7.png",
     "id": 7,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #8",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/8.png",
     "id": 8,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #9",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/9.png",
     "id": 9,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #10",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/10.png",
     "id": 10,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #11",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/11.png",
     "id": 11,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #12",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/12.png",
     "id": 12,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #13",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/13.png",
     "id": 13,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #14",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/14.png",
     "id": 14,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #15",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/15.png",
     "id": 15,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #16",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/16.png",
     "id": 16,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #17",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/17.png",
     "id": 17,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #18",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/18.png",
     "id": 18,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Ancient Boomer"
      },
      {
       "trait_type": "Skin",
       "value": "Ancient Boomer"
      },
      {
       "trait_type": "Crypto",
       "value": "Ancient Boomer"
      },
      {
       "trait_type": "Activity",
       "value": "Ancient Boomer"
      },
      {
       "trait_type": "Outfit",
       "value": "Ancient Boomer"
      },
      {
       "trait_type": "Transportation",
       "value": "Ancient Boomer"
      },
      {
       "trait_type": "Head",
       "value": "Ancient Boomer"
      },
      {
       "trait_type": "Mouth",
       "value": "Ancient Boomer"
      },
      {
       "trait_type": "Eyes",
       "value": "Ancient Boomer"
      }
     ]
    },
    {
     "name": "Boomer Squad #19",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/19.png",
     "id": 19,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #20",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/20.png",
     "id": 20,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #21",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/21.png",
     "id": 21,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #22",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/22.png",
     "id": 22,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #23",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/23.png",
     "id": 23,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #24",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/24.png",
     "id": 24,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #25",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/25.png",
     "id": 25,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #26",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/26.png",
     "id": 26,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #27",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/27.png",
     "id": 27,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #28",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/28.png",
     "id": 28,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #29",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/29.png",
     "id": 29,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #30",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/30.png",
     "id": 30,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #31",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/31.png",
     "id": 31,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #32",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/32.png",
     "id": 32,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #33",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/33.png",
     "id": 33,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #34",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/34.png",
     "id": 34,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #35",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/35.png",
     "id": 35,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #36",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/36.png",
     "id": 36,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #37",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/37.png",
     "id": 37,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #38",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/38.png",
     "id": 38,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #39",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/39.png",
     "id": 39,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #40",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/40.png",
     "id": 40,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #41",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/41.png",
     "id": 41,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #42",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/42.png",
     "id": 42,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #43",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/43.png",
     "id": 43,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #44",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/44.png",
     "id": 44,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #45",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/45.png",
     "id": 45,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #46",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/46.png",
     "id": 46,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #47",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/47.png",
     "id": 47,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #48",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/48.png",
     "id": 48,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #49",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/49.png",
     "id": 49,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #50",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/50.png",
     "id": 50,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #51",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/51.png",
     "id": 51,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #52",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/52.png",
     "id": 52,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #53",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/53.png",
     "id": 53,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #54",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/54.png",
     "id": 54,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #55",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/55.png",
     "id": 55,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #56",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/56.png",
     "id": 56,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #57",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/57.png",
     "id": 57,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #58",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/58.png",
     "id": 58,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #59",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/59.png",
     "id": 59,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #60",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/60.png",
     "id": 60,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #61",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/61.png",
     "id": 61,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #62",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/62.png",
     "id": 62,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #63",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/63.png",
     "id": 63,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #64",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/64.png",
     "id": 64,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #65",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/65.png",
     "id": 65,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #66",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/66.png",
     "id": 66,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #67",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/67.png",
     "id": 67,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #68",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/68.png",
     "id": 68,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #69",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/69.png",
     "id": 69,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #70",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/70.png",
     "id": 70,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #71",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/71.png",
     "id": 71,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #72",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/72.png",
     "id": 72,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #73",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/73.png",
     "id": 73,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #74",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/74.png",
     "id": 74,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #75",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/75.png",
     "id": 75,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #76",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/76.png",
     "id": 76,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #77",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/77.png",
     "id": 77,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #78",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/78.png",
     "id": 78,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #79",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/79.png",
     "id": 79,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #80",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/80.png",
     "id": 80,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #81",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/81.png",
     "id": 81,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #82",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/82.png",
     "id": 82,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #83",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/83.png",
     "id": 83,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #84",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/84.png",
     "id": 84,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #85",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/85.png",
     "id": 85,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #86",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/86.png",
     "id": 86,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #87",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/87.png",
     "id": 87,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #88",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/88.png",
     "id": 88,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #89",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/89.png",
     "id": 89,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #90",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/90.png",
     "id": 90,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #91",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/91.png",
     "id": 91,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #92",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/92.png",
     "id": 92,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #93",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/93.png",
     "id": 93,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #94",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/94.png",
     "id": 94,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #95",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/95.png",
     "id": 95,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #96",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/96.png",
     "id": 96,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #97",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/97.png",
     "id": 97,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #98",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/98.png",
     "id": 98,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #99",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/99.png",
     "id": 99,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #100",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/100.png",
     "id": 100,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #101",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/101.png",
     "id": 101,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #102",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/102.png",
     "id": 102,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #103",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/103.png",
     "id": 103,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #104",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/104.png",
     "id": 104,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #105",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/105.png",
     "id": 105,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #106",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/106.png",
     "id": 106,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #107",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/107.png",
     "id": 107,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #108",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/108.png",
     "id": 108,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #109",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/109.png",
     "id": 109,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #110",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/110.png",
     "id": 110,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #111",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/111.png",
     "id": 111,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #112",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/112.png",
     "id": 112,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #113",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/113.png",
     "id": 113,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #114",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/114.png",
     "id": 114,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #115",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/115.png",
     "id": 115,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #116",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/116.png",
     "id": 116,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #117",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/117.png",
     "id": 117,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #118",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/118.png",
     "id": 118,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #119",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/119.png",
     "id": 119,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #120",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/120.png",
     "id": 120,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #121",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/121.png",
     "id": 121,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #122",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/122.png",
     "id": 122,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #123",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/123.png",
     "id": 123,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #124",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/124.png",
     "id": 124,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #125",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/125.png",
     "id": 125,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #126",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/126.png",
     "id": 126,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #127",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/127.png",
     "id": 127,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #128",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/128.png",
     "id": 128,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #129",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/129.png",
     "id": 129,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #130",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/130.png",
     "id": 130,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #131",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/131.png",
     "id": 131,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #132",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/132.png",
     "id": 132,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #133",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/133.png",
     "id": 133,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #134",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/134.png",
     "id": 134,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #135",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/135.png",
     "id": 135,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #136",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/136.png",
     "id": 136,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #137",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/137.png",
     "id": 137,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #138",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/138.png",
     "id": 138,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #139",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/139.png",
     "id": 139,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #140",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/140.png",
     "id": 140,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #141",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/141.png",
     "id": 141,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #142",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/142.png",
     "id": 142,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #143",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/143.png",
     "id": 143,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #144",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/144.png",
     "id": 144,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #145",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/145.png",
     "id": 145,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #146",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/146.png",
     "id": 146,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #147",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/147.png",
     "id": 147,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #148",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/148.png",
     "id": 148,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #149",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/149.png",
     "id": 149,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #150",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/150.png",
     "id": 150,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #151",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/151.png",
     "id": 151,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #152",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/152.png",
     "id": 152,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #153",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/153.png",
     "id": 153,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #154",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/154.png",
     "id": 154,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #155",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/155.png",
     "id": 155,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #156",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/156.png",
     "id": 156,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #157",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/157.png",
     "id": 157,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #158",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/158.png",
     "id": 158,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #159",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/159.png",
     "id": 159,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #160",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/160.png",
     "id": 160,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #161",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/161.png",
     "id": 161,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #162",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/162.png",
     "id": 162,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #163",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/163.png",
     "id": 163,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #164",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/164.png",
     "id": 164,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #165",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/165.png",
     "id": 165,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #166",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/166.png",
     "id": 166,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #167",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/167.png",
     "id": 167,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #168",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/168.png",
     "id": 168,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #169",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/169.png",
     "id": 169,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #170",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/170.png",
     "id": 170,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #171",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/171.png",
     "id": 171,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #172",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/172.png",
     "id": 172,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #173",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/173.png",
     "id": 173,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #174",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/174.png",
     "id": 174,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #175",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/175.png",
     "id": 175,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #176",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/176.png",
     "id": 176,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #177",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/177.png",
     "id": 177,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #178",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/178.png",
     "id": 178,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #179",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/179.png",
     "id": 179,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #180",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/180.png",
     "id": 180,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #181",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/181.png",
     "id": 181,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #182",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/182.png",
     "id": 182,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #183",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/183.png",
     "id": 183,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #184",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/184.png",
     "id": 184,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #185",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/185.png",
     "id": 185,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #186",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/186.png",
     "id": 186,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #187",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/187.png",
     "id": 187,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #188",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/188.png",
     "id": 188,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #189",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/189.png",
     "id": 189,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #190",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/190.png",
     "id": 190,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #191",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/191.png",
     "id": 191,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #192",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/192.png",
     "id": 192,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #193",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/193.png",
     "id": 193,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #194",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/194.png",
     "id": 194,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #195",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/195.png",
     "id": 195,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #196",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/196.png",
     "id": 196,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #197",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/197.png",
     "id": 197,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #198",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/198.png",
     "id": 198,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #199",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/199.png",
     "id": 199,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #200",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/200.png",
     "id": 200,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #201",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/201.png",
     "id": 201,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #202",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/202.png",
     "id": 202,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #203",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/203.png",
     "id": 203,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #204",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/204.png",
     "id": 204,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #205",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/205.png",
     "id": 205,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #206",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/206.png",
     "id": 206,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #207",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/207.png",
     "id": 207,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #208",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/208.png",
     "id": 208,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #209",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/209.png",
     "id": 209,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #210",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/210.png",
     "id": 210,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #211",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/211.png",
     "id": 211,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #212",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/212.png",
     "id": 212,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #213",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/213.png",
     "id": 213,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #214",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/214.png",
     "id": 214,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #215",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/215.png",
     "id": 215,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #216",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/216.png",
     "id": 216,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #217",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/217.png",
     "id": 217,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #218",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/218.png",
     "id": 218,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #219",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/219.png",
     "id": 219,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #220",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/220.png",
     "id": 220,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #221",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/221.png",
     "id": 221,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #222",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/222.png",
     "id": 222,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #223",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/223.png",
     "id": 223,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #224",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/224.png",
     "id": 224,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #225",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/225.png",
     "id": 225,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #226",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/226.png",
     "id": 226,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #227",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/227.png",
     "id": 227,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #228",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/228.png",
     "id": 228,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #229",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/229.png",
     "id": 229,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #230",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/230.png",
     "id": 230,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #231",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/231.png",
     "id": 231,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #232",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/232.png",
     "id": 232,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #233",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/233.png",
     "id": 233,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #234",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/234.png",
     "id": 234,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #235",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/235.png",
     "id": 235,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #236",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/236.png",
     "id": 236,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #237",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/237.png",
     "id": 237,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #238",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/238.png",
     "id": 238,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #239",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/239.png",
     "id": 239,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #240",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/240.png",
     "id": 240,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #241",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/241.png",
     "id": 241,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #242",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/242.png",
     "id": 242,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #243",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/243.png",
     "id": 243,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #244",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/244.png",
     "id": 244,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #245",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/245.png",
     "id": 245,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #246",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/246.png",
     "id": 246,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #247",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/247.png",
     "id": 247,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #248",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/248.png",
     "id": 248,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #249",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/249.png",
     "id": 249,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #250",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/250.png",
     "id": 250,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #251",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/251.png",
     "id": 251,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #252",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/252.png",
     "id": 252,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #253",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/253.png",
     "id": 253,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #254",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/254.png",
     "id": 254,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #255",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/255.png",
     "id": 255,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #256",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/256.png",
     "id": 256,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #257",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/257.png",
     "id": 257,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #258",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/258.png",
     "id": 258,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #259",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/259.png",
     "id": 259,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #260",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/260.png",
     "id": 260,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #261",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/261.png",
     "id": 261,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #262",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/262.png",
     "id": 262,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #263",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/263.png",
     "id": 263,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #264",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/264.png",
     "id": 264,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #265",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/265.png",
     "id": 265,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #266",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/266.png",
     "id": 266,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #267",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/267.png",
     "id": 267,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #268",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/268.png",
     "id": 268,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #269",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/269.png",
     "id": 269,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #270",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/270.png",
     "id": 270,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #271",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/271.png",
     "id": 271,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #272",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/272.png",
     "id": 272,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #273",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/273.png",
     "id": 273,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #274",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/274.png",
     "id": 274,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #275",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/275.png",
     "id": 275,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #276",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/276.png",
     "id": 276,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #277",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/277.png",
     "id": 277,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #278",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/278.png",
     "id": 278,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #279",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/279.png",
     "id": 279,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #280",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/280.png",
     "id": 280,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #281",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/281.png",
     "id": 281,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #282",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/282.png",
     "id": 282,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #283",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/283.png",
     "id": 283,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #284",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/284.png",
     "id": 284,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #285",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/285.png",
     "id": 285,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #286",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/286.png",
     "id": 286,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #287",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/287.png",
     "id": 287,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #288",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/288.png",
     "id": 288,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #289",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/289.png",
     "id": 289,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #290",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/290.png",
     "id": 290,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #291",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/291.png",
     "id": 291,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #292",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/292.png",
     "id": 292,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #293",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/293.png",
     "id": 293,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #294",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/294.png",
     "id": 294,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #295",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/295.png",
     "id": 295,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #296",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/296.png",
     "id": 296,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #297",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/297.png",
     "id": 297,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #298",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/298.png",
     "id": 298,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #299",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/299.png",
     "id": 299,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #300",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/300.png",
     "id": 300,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #301",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/301.png",
     "id": 301,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #302",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/302.png",
     "id": 302,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #303",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/303.png",
     "id": 303,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #304",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/304.png",
     "id": 304,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #305",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/305.png",
     "id": 305,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #306",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/306.png",
     "id": 306,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #307",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/307.png",
     "id": 307,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #308",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/308.png",
     "id": 308,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #309",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/309.png",
     "id": 309,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #310",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/310.png",
     "id": 310,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #311",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/311.png",
     "id": 311,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #312",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/312.png",
     "id": 312,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #313",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/313.png",
     "id": 313,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #314",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/314.png",
     "id": 314,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #315",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/315.png",
     "id": 315,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #316",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/316.png",
     "id": 316,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #317",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/317.png",
     "id": 317,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #318",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/318.png",
     "id": 318,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #319",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/319.png",
     "id": 319,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #320",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/320.png",
     "id": 320,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #321",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/321.png",
     "id": 321,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #322",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/322.png",
     "id": 322,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #323",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/323.png",
     "id": 323,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #324",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/324.png",
     "id": 324,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #325",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/325.png",
     "id": 325,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #326",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/326.png",
     "id": 326,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #327",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/327.png",
     "id": 327,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #328",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/328.png",
     "id": 328,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #329",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/329.png",
     "id": 329,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #330",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/330.png",
     "id": 330,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #331",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/331.png",
     "id": 331,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #332",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/332.png",
     "id": 332,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #333",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/333.png",
     "id": 333,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #334",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/334.png",
     "id": 334,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #335",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/335.png",
     "id": 335,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #336",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/336.png",
     "id": 336,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #337",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/337.png",
     "id": 337,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #338",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/338.png",
     "id": 338,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #339",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/339.png",
     "id": 339,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #340",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/340.png",
     "id": 340,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #341",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/341.png",
     "id": 341,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #342",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/342.png",
     "id": 342,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #343",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/343.png",
     "id": 343,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #344",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/344.png",
     "id": 344,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #345",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/345.png",
     "id": 345,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #346",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/346.png",
     "id": 346,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #347",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/347.png",
     "id": 347,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #348",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/348.png",
     "id": 348,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #349",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/349.png",
     "id": 349,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #350",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/350.png",
     "id": 350,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #351",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/351.png",
     "id": 351,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #352",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/352.png",
     "id": 352,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #353",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/353.png",
     "id": 353,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #354",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/354.png",
     "id": 354,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #355",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/355.png",
     "id": 355,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #356",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/356.png",
     "id": 356,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #357",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/357.png",
     "id": 357,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #358",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/358.png",
     "id": 358,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #359",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/359.png",
     "id": 359,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #360",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/360.png",
     "id": 360,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #361",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/361.png",
     "id": 361,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #362",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/362.png",
     "id": 362,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #363",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/363.png",
     "id": 363,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #364",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/364.png",
     "id": 364,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #365",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/365.png",
     "id": 365,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #366",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/366.png",
     "id": 366,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #367",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/367.png",
     "id": 367,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #368",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/368.png",
     "id": 368,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #369",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/369.png",
     "id": 369,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #370",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/370.png",
     "id": 370,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #371",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/371.png",
     "id": 371,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #372",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/372.png",
     "id": 372,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #373",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/373.png",
     "id": 373,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #374",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/374.png",
     "id": 374,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #375",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/375.png",
     "id": 375,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #376",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/376.png",
     "id": 376,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #377",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/377.png",
     "id": 377,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #378",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/378.png",
     "id": 378,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #379",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/379.png",
     "id": 379,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #380",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/380.png",
     "id": 380,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #381",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/381.png",
     "id": 381,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #382",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/382.png",
     "id": 382,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #383",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/383.png",
     "id": 383,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #384",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/384.png",
     "id": 384,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #385",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/385.png",
     "id": 385,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #386",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/386.png",
     "id": 386,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #387",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/387.png",
     "id": 387,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #388",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/388.png",
     "id": 388,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #389",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/389.png",
     "id": 389,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #390",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/390.png",
     "id": 390,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #391",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/391.png",
     "id": 391,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #392",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/392.png",
     "id": 392,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #393",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/393.png",
     "id": 393,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #394",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/394.png",
     "id": 394,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #395",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/395.png",
     "id": 395,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #396",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/396.png",
     "id": 396,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #397",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/397.png",
     "id": 397,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #398",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/398.png",
     "id": 398,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #399",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/399.png",
     "id": 399,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #400",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/400.png",
     "id": 400,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #401",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/401.png",
     "id": 401,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #402",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/402.png",
     "id": 402,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #403",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/403.png",
     "id": 403,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #404",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/404.png",
     "id": 404,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #405",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/405.png",
     "id": 405,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #406",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/406.png",
     "id": 406,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #407",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/407.png",
     "id": 407,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #408",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/408.png",
     "id": 408,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #409",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/409.png",
     "id": 409,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #410",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/410.png",
     "id": 410,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #411",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/411.png",
     "id": 411,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #412",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/412.png",
     "id": 412,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #413",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/413.png",
     "id": 413,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #414",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/414.png",
     "id": 414,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #415",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/415.png",
     "id": 415,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #416",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/416.png",
     "id": 416,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #417",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/417.png",
     "id": 417,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #418",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/418.png",
     "id": 418,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #419",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/419.png",
     "id": 419,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #420",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/420.png",
     "id": 420,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #421",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/421.png",
     "id": 421,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #422",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/422.png",
     "id": 422,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #423",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/423.png",
     "id": 423,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #424",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/424.png",
     "id": 424,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #425",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/425.png",
     "id": 425,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #426",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/426.png",
     "id": 426,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #427",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/427.png",
     "id": 427,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #428",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/428.png",
     "id": 428,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #429",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/429.png",
     "id": 429,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #430",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/430.png",
     "id": 430,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #431",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/431.png",
     "id": 431,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #432",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/432.png",
     "id": 432,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #433",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/433.png",
     "id": 433,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #434",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/434.png",
     "id": 434,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #435",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/435.png",
     "id": 435,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #436",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/436.png",
     "id": 436,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #437",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/437.png",
     "id": 437,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #438",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/438.png",
     "id": 438,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #439",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/439.png",
     "id": 439,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #440",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/440.png",
     "id": 440,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #441",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/441.png",
     "id": 441,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #442",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/442.png",
     "id": 442,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #443",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/443.png",
     "id": 443,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #444",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/444.png",
     "id": 444,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #445",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/445.png",
     "id": 445,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #446",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/446.png",
     "id": 446,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #447",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/447.png",
     "id": 447,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #448",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/448.png",
     "id": 448,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #449",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/449.png",
     "id": 449,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #450",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/450.png",
     "id": 450,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #451",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/451.png",
     "id": 451,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #452",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/452.png",
     "id": 452,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #453",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/453.png",
     "id": 453,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #454",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/454.png",
     "id": 454,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #455",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/455.png",
     "id": 455,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #456",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/456.png",
     "id": 456,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #457",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/457.png",
     "id": 457,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #458",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/458.png",
     "id": 458,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #459",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/459.png",
     "id": 459,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #460",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/460.png",
     "id": 460,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #461",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/461.png",
     "id": 461,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #462",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/462.png",
     "id": 462,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #463",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/463.png",
     "id": 463,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #464",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/464.png",
     "id": 464,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #465",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/465.png",
     "id": 465,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #466",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/466.png",
     "id": 466,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #467",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/467.png",
     "id": 467,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #468",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/468.png",
     "id": 468,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #469",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/469.png",
     "id": 469,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #470",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/470.png",
     "id": 470,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #471",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/471.png",
     "id": 471,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #472",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/472.png",
     "id": 472,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #473",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/473.png",
     "id": 473,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #474",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/474.png",
     "id": 474,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #475",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/475.png",
     "id": 475,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #476",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/476.png",
     "id": 476,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #477",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/477.png",
     "id": 477,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #478",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/478.png",
     "id": 478,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #479",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/479.png",
     "id": 479,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #480",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/480.png",
     "id": 480,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #481",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/481.png",
     "id": 481,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #482",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/482.png",
     "id": 482,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #483",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/483.png",
     "id": 483,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #484",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/484.png",
     "id": 484,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #485",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/485.png",
     "id": 485,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #486",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/486.png",
     "id": 486,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #487",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/487.png",
     "id": 487,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #488",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/488.png",
     "id": 488,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #489",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/489.png",
     "id": 489,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #490",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/490.png",
     "id": 490,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #491",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/491.png",
     "id": 491,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #492",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/492.png",
     "id": 492,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #493",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/493.png",
     "id": 493,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #494",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/494.png",
     "id": 494,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #495",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/495.png",
     "id": 495,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #496",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/496.png",
     "id": 496,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #497",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/497.png",
     "id": 497,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #498",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/498.png",
     "id": 498,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #499",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/499.png",
     "id": 499,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #500",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/500.png",
     "id": 500,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #501",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/501.png",
     "id": 501,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #502",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/502.png",
     "id": 502,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #503",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/503.png",
     "id": 503,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #504",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/504.png",
     "id": 504,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #505",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/505.png",
     "id": 505,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #506",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/506.png",
     "id": 506,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #507",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/507.png",
     "id": 507,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #508",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/508.png",
     "id": 508,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #509",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/509.png",
     "id": 509,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #510",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/510.png",
     "id": 510,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #511",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/511.png",
     "id": 511,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #512",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/512.png",
     "id": 512,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #513",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/513.png",
     "id": 513,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #514",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/514.png",
     "id": 514,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #515",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/515.png",
     "id": 515,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #516",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/516.png",
     "id": 516,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #517",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/517.png",
     "id": 517,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #518",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/518.png",
     "id": 518,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #519",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/519.png",
     "id": 519,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #520",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/520.png",
     "id": 520,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #521",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/521.png",
     "id": 521,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #522",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/522.png",
     "id": 522,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #523",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/523.png",
     "id": 523,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #524",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/524.png",
     "id": 524,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #525",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/525.png",
     "id": 525,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #526",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/526.png",
     "id": 526,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #527",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/527.png",
     "id": 527,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #528",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/528.png",
     "id": 528,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #529",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/529.png",
     "id": 529,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #530",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/530.png",
     "id": 530,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #531",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/531.png",
     "id": 531,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #532",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/532.png",
     "id": 532,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #533",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/533.png",
     "id": 533,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #534",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/534.png",
     "id": 534,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #535",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/535.png",
     "id": 535,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #536",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/536.png",
     "id": 536,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #537",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/537.png",
     "id": 537,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #538",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/538.png",
     "id": 538,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #539",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/539.png",
     "id": 539,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #540",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/540.png",
     "id": 540,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #541",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/541.png",
     "id": 541,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #542",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/542.png",
     "id": 542,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #543",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/543.png",
     "id": 543,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #544",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/544.png",
     "id": 544,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #545",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/545.png",
     "id": 545,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #546",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/546.png",
     "id": 546,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #547",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/547.png",
     "id": 547,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #548",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/548.png",
     "id": 548,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #549",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/549.png",
     "id": 549,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #550",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/550.png",
     "id": 550,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #551",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/551.png",
     "id": 551,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #552",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/552.png",
     "id": 552,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #553",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/553.png",
     "id": 553,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #554",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/554.png",
     "id": 554,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #555",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/555.png",
     "id": 555,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #556",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/556.png",
     "id": 556,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #557",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/557.png",
     "id": 557,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #558",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/558.png",
     "id": 558,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #559",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/559.png",
     "id": 559,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #560",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/560.png",
     "id": 560,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #561",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/561.png",
     "id": 561,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #562",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/562.png",
     "id": 562,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #563",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/563.png",
     "id": 563,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #564",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/564.png",
     "id": 564,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #565",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/565.png",
     "id": 565,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #566",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/566.png",
     "id": 566,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #567",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/567.png",
     "id": 567,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #568",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/568.png",
     "id": 568,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #569",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/569.png",
     "id": 569,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #570",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/570.png",
     "id": 570,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #571",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/571.png",
     "id": 571,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #572",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/572.png",
     "id": 572,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #573",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/573.png",
     "id": 573,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #574",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/574.png",
     "id": 574,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #575",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/575.png",
     "id": 575,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #576",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/576.png",
     "id": 576,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #577",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/577.png",
     "id": 577,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #578",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/578.png",
     "id": 578,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #579",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/579.png",
     "id": 579,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #580",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/580.png",
     "id": 580,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #581",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/581.png",
     "id": 581,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #582",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/582.png",
     "id": 582,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #583",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/583.png",
     "id": 583,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #584",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/584.png",
     "id": 584,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #585",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/585.png",
     "id": 585,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #586",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/586.png",
     "id": 586,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #587",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/587.png",
     "id": 587,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #588",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/588.png",
     "id": 588,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #589",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/589.png",
     "id": 589,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #590",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/590.png",
     "id": 590,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #591",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/591.png",
     "id": 591,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #592",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/592.png",
     "id": 592,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #593",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/593.png",
     "id": 593,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #594",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/594.png",
     "id": 594,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #595",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/595.png",
     "id": 595,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #596",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/596.png",
     "id": 596,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #597",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/597.png",
     "id": 597,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #598",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/598.png",
     "id": 598,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #599",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/599.png",
     "id": 599,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #600",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/600.png",
     "id": 600,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #601",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/601.png",
     "id": 601,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #602",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/602.png",
     "id": 602,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #603",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/603.png",
     "id": 603,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #604",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/604.png",
     "id": 604,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #605",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/605.png",
     "id": 605,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #606",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/606.png",
     "id": 606,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #607",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/607.png",
     "id": 607,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #608",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/608.png",
     "id": 608,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #609",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/609.png",
     "id": 609,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #610",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/610.png",
     "id": 610,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #611",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/611.png",
     "id": 611,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #612",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/612.png",
     "id": 612,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #613",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/613.png",
     "id": 613,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #614",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/614.png",
     "id": 614,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #615",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/615.png",
     "id": 615,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #616",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/616.png",
     "id": 616,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #617",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/617.png",
     "id": 617,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #618",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/618.png",
     "id": 618,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #619",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/619.png",
     "id": 619,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #620",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/620.png",
     "id": 620,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #621",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/621.png",
     "id": 621,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #622",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/622.png",
     "id": 622,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #623",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/623.png",
     "id": 623,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #624",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/624.png",
     "id": 624,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #625",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/625.png",
     "id": 625,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #626",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/626.png",
     "id": 626,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #627",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/627.png",
     "id": 627,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #628",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/628.png",
     "id": 628,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #629",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/629.png",
     "id": 629,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #630",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/630.png",
     "id": 630,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #631",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/631.png",
     "id": 631,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #632",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/632.png",
     "id": 632,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #633",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/633.png",
     "id": 633,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #634",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/634.png",
     "id": 634,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #635",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/635.png",
     "id": 635,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #636",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/636.png",
     "id": 636,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #637",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/637.png",
     "id": 637,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #638",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/638.png",
     "id": 638,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #639",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/639.png",
     "id": 639,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #640",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/640.png",
     "id": 640,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #641",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/641.png",
     "id": 641,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #642",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/642.png",
     "id": 642,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #643",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/643.png",
     "id": 643,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #644",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/644.png",
     "id": 644,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #645",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/645.png",
     "id": 645,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #646",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/646.png",
     "id": 646,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #647",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/647.png",
     "id": 647,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #648",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/648.png",
     "id": 648,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #649",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/649.png",
     "id": 649,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #650",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/650.png",
     "id": 650,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #651",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/651.png",
     "id": 651,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #652",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/652.png",
     "id": 652,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #653",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/653.png",
     "id": 653,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #654",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/654.png",
     "id": 654,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #655",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/655.png",
     "id": 655,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #656",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/656.png",
     "id": 656,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #657",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/657.png",
     "id": 657,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #658",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/658.png",
     "id": 658,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #659",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/659.png",
     "id": 659,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #660",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/660.png",
     "id": 660,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #661",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/661.png",
     "id": 661,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #662",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/662.png",
     "id": 662,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #663",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/663.png",
     "id": 663,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #664",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/664.png",
     "id": 664,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #665",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/665.png",
     "id": 665,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #666",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/666.png",
     "id": 666,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #667",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/667.png",
     "id": 667,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #668",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/668.png",
     "id": 668,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #669",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/669.png",
     "id": 669,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #670",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/670.png",
     "id": 670,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #671",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/671.png",
     "id": 671,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #672",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/672.png",
     "id": 672,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #673",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/673.png",
     "id": 673,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #674",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/674.png",
     "id": 674,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #675",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/675.png",
     "id": 675,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #676",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/676.png",
     "id": 676,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #677",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/677.png",
     "id": 677,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #678",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/678.png",
     "id": 678,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #679",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/679.png",
     "id": 679,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #680",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/680.png",
     "id": 680,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #681",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/681.png",
     "id": 681,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #682",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/682.png",
     "id": 682,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #683",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/683.png",
     "id": 683,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #684",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/684.png",
     "id": 684,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #685",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/685.png",
     "id": 685,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #686",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/686.png",
     "id": 686,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #687",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/687.png",
     "id": 687,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #688",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/688.png",
     "id": 688,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #689",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/689.png",
     "id": 689,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #690",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/690.png",
     "id": 690,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #691",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/691.png",
     "id": 691,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #692",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/692.png",
     "id": 692,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #693",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/693.png",
     "id": 693,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #694",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/694.png",
     "id": 694,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #695",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/695.png",
     "id": 695,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #696",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/696.png",
     "id": 696,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #697",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/697.png",
     "id": 697,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #698",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/698.png",
     "id": 698,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #699",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/699.png",
     "id": 699,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #700",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/700.png",
     "id": 700,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #701",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/701.png",
     "id": 701,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #702",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/702.png",
     "id": 702,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #703",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/703.png",
     "id": 703,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #704",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/704.png",
     "id": 704,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #705",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/705.png",
     "id": 705,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #706",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/706.png",
     "id": 706,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #707",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/707.png",
     "id": 707,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #708",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/708.png",
     "id": 708,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #709",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/709.png",
     "id": 709,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #710",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/710.png",
     "id": 710,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #711",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/711.png",
     "id": 711,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #712",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/712.png",
     "id": 712,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #713",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/713.png",
     "id": 713,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #714",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/714.png",
     "id": 714,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #715",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/715.png",
     "id": 715,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #716",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/716.png",
     "id": 716,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #717",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/717.png",
     "id": 717,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #718",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/718.png",
     "id": 718,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Plumber"
      },
      {
       "trait_type": "Skin",
       "value": "Plumber"
      },
      {
       "trait_type": "Crypto",
       "value": "Plumber"
      },
      {
       "trait_type": "Activity",
       "value": "Plumber"
      },
      {
       "trait_type": "Outfit",
       "value": "Plumber"
      },
      {
       "trait_type": "Transportation",
       "value": "Plumber"
      },
      {
       "trait_type": "Head",
       "value": "Plumber"
      },
      {
       "trait_type": "Mouth",
       "value": "Plumber"
      },
      {
       "trait_type": "Eyes",
       "value": "Plumber"
      }
     ]
    },
    {
     "name": "Boomer Squad #719",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/719.png",
     "id": 719,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #720",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/720.png",
     "id": 720,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #721",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/721.png",
     "id": 721,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #722",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/722.png",
     "id": 722,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #723",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/723.png",
     "id": 723,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #724",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/724.png",
     "id": 724,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #725",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/725.png",
     "id": 725,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #726",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/726.png",
     "id": 726,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #727",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/727.png",
     "id": 727,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #728",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/728.png",
     "id": 728,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #729",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/729.png",
     "id": 729,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #730",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/730.png",
     "id": 730,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #731",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/731.png",
     "id": 731,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #732",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/732.png",
     "id": 732,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #733",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/733.png",
     "id": 733,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #734",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/734.png",
     "id": 734,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #735",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/735.png",
     "id": 735,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #736",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/736.png",
     "id": 736,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #737",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/737.png",
     "id": 737,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #738",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/738.png",
     "id": 738,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #739",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/739.png",
     "id": 739,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #740",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/740.png",
     "id": 740,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #741",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/741.png",
     "id": 741,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #742",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/742.png",
     "id": 742,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #743",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/743.png",
     "id": 743,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #744",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/744.png",
     "id": 744,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #745",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/745.png",
     "id": 745,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #746",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/746.png",
     "id": 746,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #747",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/747.png",
     "id": 747,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #748",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/748.png",
     "id": 748,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #749",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/749.png",
     "id": 749,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #750",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/750.png",
     "id": 750,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #751",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/751.png",
     "id": 751,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #752",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/752.png",
     "id": 752,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #753",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/753.png",
     "id": 753,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #754",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/754.png",
     "id": 754,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #755",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/755.png",
     "id": 755,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #756",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/756.png",
     "id": 756,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #757",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/757.png",
     "id": 757,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #758",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/758.png",
     "id": 758,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #759",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/759.png",
     "id": 759,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #760",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/760.png",
     "id": 760,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #761",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/761.png",
     "id": 761,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #762",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/762.png",
     "id": 762,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #763",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/763.png",
     "id": 763,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #764",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/764.png",
     "id": 764,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #765",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/765.png",
     "id": 765,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #766",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/766.png",
     "id": 766,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #767",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/767.png",
     "id": 767,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #768",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/768.png",
     "id": 768,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #769",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/769.png",
     "id": 769,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #770",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/770.png",
     "id": 770,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #771",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/771.png",
     "id": 771,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #772",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/772.png",
     "id": 772,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #773",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/773.png",
     "id": 773,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #774",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/774.png",
     "id": 774,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #775",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/775.png",
     "id": 775,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #776",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/776.png",
     "id": 776,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #777",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/777.png",
     "id": 777,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #778",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/778.png",
     "id": 778,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #779",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/779.png",
     "id": 779,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #780",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/780.png",
     "id": 780,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #781",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/781.png",
     "id": 781,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #782",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/782.png",
     "id": 782,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #783",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/783.png",
     "id": 783,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #784",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/784.png",
     "id": 784,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #785",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/785.png",
     "id": 785,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #786",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/786.png",
     "id": 786,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #787",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/787.png",
     "id": 787,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #788",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/788.png",
     "id": 788,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #789",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/789.png",
     "id": 789,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #790",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/790.png",
     "id": 790,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #791",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/791.png",
     "id": 791,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #792",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/792.png",
     "id": 792,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #793",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/793.png",
     "id": 793,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #794",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/794.png",
     "id": 794,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #795",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/795.png",
     "id": 795,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #796",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/796.png",
     "id": 796,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #797",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/797.png",
     "id": 797,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #798",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/798.png",
     "id": 798,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #799",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/799.png",
     "id": 799,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #800",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/800.png",
     "id": 800,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #801",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/801.png",
     "id": 801,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #802",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/802.png",
     "id": 802,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #803",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/803.png",
     "id": 803,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #804",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/804.png",
     "id": 804,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #805",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/805.png",
     "id": 805,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #806",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/806.png",
     "id": 806,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #807",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/807.png",
     "id": 807,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #808",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/808.png",
     "id": 808,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #809",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/809.png",
     "id": 809,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #810",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/810.png",
     "id": 810,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #811",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/811.png",
     "id": 811,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #812",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/812.png",
     "id": 812,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #813",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/813.png",
     "id": 813,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #814",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/814.png",
     "id": 814,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #815",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/815.png",
     "id": 815,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #816",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/816.png",
     "id": 816,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #817",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/817.png",
     "id": 817,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #818",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/818.png",
     "id": 818,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #819",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/819.png",
     "id": 819,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #820",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/820.png",
     "id": 820,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #821",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/821.png",
     "id": 821,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #822",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/822.png",
     "id": 822,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #823",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/823.png",
     "id": 823,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #824",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/824.png",
     "id": 824,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #825",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/825.png",
     "id": 825,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #826",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/826.png",
     "id": 826,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #827",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/827.png",
     "id": 827,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #828",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/828.png",
     "id": 828,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #829",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/829.png",
     "id": 829,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #830",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/830.png",
     "id": 830,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #831",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/831.png",
     "id": 831,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #832",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/832.png",
     "id": 832,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #833",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/833.png",
     "id": 833,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #834",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/834.png",
     "id": 834,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #835",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/835.png",
     "id": 835,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #836",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/836.png",
     "id": 836,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #837",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/837.png",
     "id": 837,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #838",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/838.png",
     "id": 838,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #839",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/839.png",
     "id": 839,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #840",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/840.png",
     "id": 840,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #841",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/841.png",
     "id": 841,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #842",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/842.png",
     "id": 842,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #843",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/843.png",
     "id": 843,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #844",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/844.png",
     "id": 844,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #845",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/845.png",
     "id": 845,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #846",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/846.png",
     "id": 846,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #847",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/847.png",
     "id": 847,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #848",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/848.png",
     "id": 848,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #849",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/849.png",
     "id": 849,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #850",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/850.png",
     "id": 850,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #851",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/851.png",
     "id": 851,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #852",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/852.png",
     "id": 852,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #853",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/853.png",
     "id": 853,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #854",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/854.png",
     "id": 854,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #855",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/855.png",
     "id": 855,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #856",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/856.png",
     "id": 856,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #857",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/857.png",
     "id": 857,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #858",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/858.png",
     "id": 858,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #859",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/859.png",
     "id": 859,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #860",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/860.png",
     "id": 860,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #861",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/861.png",
     "id": 861,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #862",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/862.png",
     "id": 862,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #863",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/863.png",
     "id": 863,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #864",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/864.png",
     "id": 864,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #865",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/865.png",
     "id": 865,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #866",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/866.png",
     "id": 866,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #867",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/867.png",
     "id": 867,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #868",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/868.png",
     "id": 868,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #869",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/869.png",
     "id": 869,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #870",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/870.png",
     "id": 870,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #871",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/871.png",
     "id": 871,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #872",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/872.png",
     "id": 872,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #873",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/873.png",
     "id": 873,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #874",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/874.png",
     "id": 874,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #875",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/875.png",
     "id": 875,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #876",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/876.png",
     "id": 876,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #877",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/877.png",
     "id": 877,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #878",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/878.png",
     "id": 878,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #879",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/879.png",
     "id": 879,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #880",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/880.png",
     "id": 880,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #881",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/881.png",
     "id": 881,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #882",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/882.png",
     "id": 882,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #883",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/883.png",
     "id": 883,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #884",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/884.png",
     "id": 884,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #885",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/885.png",
     "id": 885,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #886",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/886.png",
     "id": 886,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #887",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/887.png",
     "id": 887,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #888",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/888.png",
     "id": 888,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #889",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/889.png",
     "id": 889,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #890",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/890.png",
     "id": 890,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #891",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/891.png",
     "id": 891,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #892",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/892.png",
     "id": 892,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #893",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/893.png",
     "id": 893,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #894",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/894.png",
     "id": 894,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #895",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/895.png",
     "id": 895,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #896",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/896.png",
     "id": 896,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #897",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/897.png",
     "id": 897,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #898",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/898.png",
     "id": 898,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #899",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/899.png",
     "id": 899,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #900",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/900.png",
     "id": 900,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #901",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/901.png",
     "id": 901,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #902",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/902.png",
     "id": 902,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #903",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/903.png",
     "id": 903,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #904",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/904.png",
     "id": 904,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #905",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/905.png",
     "id": 905,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #906",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/906.png",
     "id": 906,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #907",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/907.png",
     "id": 907,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #908",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/908.png",
     "id": 908,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #909",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/909.png",
     "id": 909,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #910",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/910.png",
     "id": 910,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #911",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/911.png",
     "id": 911,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #912",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/912.png",
     "id": 912,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #913",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/913.png",
     "id": 913,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #914",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/914.png",
     "id": 914,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #915",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/915.png",
     "id": 915,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #916",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/916.png",
     "id": 916,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #917",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/917.png",
     "id": 917,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #918",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/918.png",
     "id": 918,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #919",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/919.png",
     "id": 919,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #920",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/920.png",
     "id": 920,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #921",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/921.png",
     "id": 921,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #922",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/922.png",
     "id": 922,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #923",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/923.png",
     "id": 923,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #924",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/924.png",
     "id": 924,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #925",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/925.png",
     "id": 925,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #926",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/926.png",
     "id": 926,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #927",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/927.png",
     "id": 927,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #928",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/928.png",
     "id": 928,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #929",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/929.png",
     "id": 929,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #930",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/930.png",
     "id": 930,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #931",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/931.png",
     "id": 931,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #932",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/932.png",
     "id": 932,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #933",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/933.png",
     "id": 933,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #934",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/934.png",
     "id": 934,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #935",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/935.png",
     "id": 935,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #936",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/936.png",
     "id": 936,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #937",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/937.png",
     "id": 937,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #938",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/938.png",
     "id": 938,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #939",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/939.png",
     "id": 939,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #940",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/940.png",
     "id": 940,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #941",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/941.png",
     "id": 941,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #942",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/942.png",
     "id": 942,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #943",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/943.png",
     "id": 943,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #944",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/944.png",
     "id": 944,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #945",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/945.png",
     "id": 945,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #946",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/946.png",
     "id": 946,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #947",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/947.png",
     "id": 947,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #948",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/948.png",
     "id": 948,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #949",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/949.png",
     "id": 949,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #950",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/950.png",
     "id": 950,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #951",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/951.png",
     "id": 951,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #952",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/952.png",
     "id": 952,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #953",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/953.png",
     "id": 953,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #954",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/954.png",
     "id": 954,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #955",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/955.png",
     "id": 955,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #956",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/956.png",
     "id": 956,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #957",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/957.png",
     "id": 957,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #958",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/958.png",
     "id": 958,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #959",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/959.png",
     "id": 959,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #960",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/960.png",
     "id": 960,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #961",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/961.png",
     "id": 961,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #962",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/962.png",
     "id": 962,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #963",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/963.png",
     "id": 963,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #964",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/964.png",
     "id": 964,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #965",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/965.png",
     "id": 965,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #966",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/966.png",
     "id": 966,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #967",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/967.png",
     "id": 967,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #968",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/968.png",
     "id": 968,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #969",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/969.png",
     "id": 969,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #970",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/970.png",
     "id": 970,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #971",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/971.png",
     "id": 971,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #972",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/972.png",
     "id": 972,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #973",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/973.png",
     "id": 973,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #974",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/974.png",
     "id": 974,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #975",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/975.png",
     "id": 975,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #976",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/976.png",
     "id": 976,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #977",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/977.png",
     "id": 977,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #978",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/978.png",
     "id": 978,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #979",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/979.png",
     "id": 979,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #980",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/980.png",
     "id": 980,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #981",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/981.png",
     "id": 981,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #982",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/982.png",
     "id": 982,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #983",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/983.png",
     "id": 983,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #984",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/984.png",
     "id": 984,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #985",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/985.png",
     "id": 985,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #986",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/986.png",
     "id": 986,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #987",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/987.png",
     "id": 987,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #988",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/988.png",
     "id": 988,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #989",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/989.png",
     "id": 989,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #990",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/990.png",
     "id": 990,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #991",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/991.png",
     "id": 991,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #992",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/992.png",
     "id": 992,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #993",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/993.png",
     "id": 993,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #994",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/994.png",
     "id": 994,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #995",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/995.png",
     "id": 995,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #996",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/996.png",
     "id": 996,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #997",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/997.png",
     "id": 997,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #998",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/998.png",
     "id": 998,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #999",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/999.png",
     "id": 999,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1000",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1000.png",
     "id": 1000,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1001",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1001.png",
     "id": 1001,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1002",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1002.png",
     "id": 1002,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1003",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1003.png",
     "id": 1003,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1004",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1004.png",
     "id": 1004,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1005",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1005.png",
     "id": 1005,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1006",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1006.png",
     "id": 1006,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1007",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1007.png",
     "id": 1007,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1008",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1008.png",
     "id": 1008,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1009",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1009.png",
     "id": 1009,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1010",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1010.png",
     "id": 1010,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1011",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1011.png",
     "id": 1011,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1012",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1012.png",
     "id": 1012,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1013",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1013.png",
     "id": 1013,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1014",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1014.png",
     "id": 1014,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1015",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1015.png",
     "id": 1015,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1016",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1016.png",
     "id": 1016,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1017",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1017.png",
     "id": 1017,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1018",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1018.png",
     "id": 1018,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1019",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1019.png",
     "id": 1019,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #1020",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1020.png",
     "id": 1020,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1021",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1021.png",
     "id": 1021,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1022",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1022.png",
     "id": 1022,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1023",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1023.png",
     "id": 1023,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1024",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1024.png",
     "id": 1024,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1025",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1025.png",
     "id": 1025,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1026",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1026.png",
     "id": 1026,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1027",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1027.png",
     "id": 1027,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1028",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1028.png",
     "id": 1028,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1029",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1029.png",
     "id": 1029,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1030",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1030.png",
     "id": 1030,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1031",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1031.png",
     "id": 1031,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1032",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1032.png",
     "id": 1032,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1033",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1033.png",
     "id": 1033,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1034",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1034.png",
     "id": 1034,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1035",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1035.png",
     "id": 1035,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1036",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1036.png",
     "id": 1036,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1037",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1037.png",
     "id": 1037,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1038",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1038.png",
     "id": 1038,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #1039",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1039.png",
     "id": 1039,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1040",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1040.png",
     "id": 1040,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1041",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1041.png",
     "id": 1041,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1042",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1042.png",
     "id": 1042,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1043",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1043.png",
     "id": 1043,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1044",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1044.png",
     "id": 1044,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1045",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1045.png",
     "id": 1045,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1046",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1046.png",
     "id": 1046,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1047",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1047.png",
     "id": 1047,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1048",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1048.png",
     "id": 1048,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1049",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1049.png",
     "id": 1049,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1050",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1050.png",
     "id": 1050,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1051",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1051.png",
     "id": 1051,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1052",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1052.png",
     "id": 1052,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1053",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1053.png",
     "id": 1053,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1054",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1054.png",
     "id": 1054,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1055",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1055.png",
     "id": 1055,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1056",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1056.png",
     "id": 1056,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1057",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1057.png",
     "id": 1057,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1058",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1058.png",
     "id": 1058,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1059",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1059.png",
     "id": 1059,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1060",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1060.png",
     "id": 1060,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1061",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1061.png",
     "id": 1061,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1062",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1062.png",
     "id": 1062,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1063",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1063.png",
     "id": 1063,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1064",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1064.png",
     "id": 1064,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1065",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1065.png",
     "id": 1065,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1066",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1066.png",
     "id": 1066,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1067",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1067.png",
     "id": 1067,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1068",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1068.png",
     "id": 1068,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1069",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1069.png",
     "id": 1069,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1070",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1070.png",
     "id": 1070,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1071",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1071.png",
     "id": 1071,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1072",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1072.png",
     "id": 1072,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1073",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1073.png",
     "id": 1073,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1074",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1074.png",
     "id": 1074,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1075",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1075.png",
     "id": 1075,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1076",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1076.png",
     "id": 1076,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1077",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1077.png",
     "id": 1077,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1078",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1078.png",
     "id": 1078,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1079",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1079.png",
     "id": 1079,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1080",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1080.png",
     "id": 1080,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1081",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1081.png",
     "id": 1081,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1082",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1082.png",
     "id": 1082,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1083",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1083.png",
     "id": 1083,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1084",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1084.png",
     "id": 1084,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1085",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1085.png",
     "id": 1085,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1086",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1086.png",
     "id": 1086,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1087",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1087.png",
     "id": 1087,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1088",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1088.png",
     "id": 1088,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1089",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1089.png",
     "id": 1089,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1090",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1090.png",
     "id": 1090,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1091",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1091.png",
     "id": 1091,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1092",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1092.png",
     "id": 1092,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1093",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1093.png",
     "id": 1093,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1094",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1094.png",
     "id": 1094,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1095",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1095.png",
     "id": 1095,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1096",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1096.png",
     "id": 1096,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1097",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1097.png",
     "id": 1097,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1098",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1098.png",
     "id": 1098,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1099",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1099.png",
     "id": 1099,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1100",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1100.png",
     "id": 1100,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1101",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1101.png",
     "id": 1101,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1102",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1102.png",
     "id": 1102,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1103",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1103.png",
     "id": 1103,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1104",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1104.png",
     "id": 1104,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1105",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1105.png",
     "id": 1105,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1106",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1106.png",
     "id": 1106,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1107",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1107.png",
     "id": 1107,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1108",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1108.png",
     "id": 1108,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1109",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1109.png",
     "id": 1109,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "C-1109"
      },
      {
       "trait_type": "Skin",
       "value": "C-1109"
      },
      {
       "trait_type": "Crypto",
       "value": "C-1109"
      },
      {
       "trait_type": "Activity",
       "value": "C-1109"
      },
      {
       "trait_type": "Outfit",
       "value": "C-1109"
      },
      {
       "trait_type": "Transportation",
       "value": "C-1109"
      },
      {
       "trait_type": "Head",
       "value": "C-1109"
      },
      {
       "trait_type": "Mouth",
       "value": "C-1109"
      },
      {
       "trait_type": "Eyes",
       "value": "C-1109"
      }
     ]
    },
    {
     "name": "Boomer Squad #1110",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1110.png",
     "id": 1110,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1111",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1111.png",
     "id": 1111,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1112",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1112.png",
     "id": 1112,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1113",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1113.png",
     "id": 1113,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1114",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1114.png",
     "id": 1114,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1115",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1115.png",
     "id": 1115,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1116",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1116.png",
     "id": 1116,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1117",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1117.png",
     "id": 1117,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1118",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1118.png",
     "id": 1118,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1119",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1119.png",
     "id": 1119,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1120",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1120.png",
     "id": 1120,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1121",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1121.png",
     "id": 1121,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1122",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1122.png",
     "id": 1122,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1123",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1123.png",
     "id": 1123,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1124",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1124.png",
     "id": 1124,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1125",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1125.png",
     "id": 1125,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1126",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1126.png",
     "id": 1126,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1127",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1127.png",
     "id": 1127,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1128",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1128.png",
     "id": 1128,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1129",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1129.png",
     "id": 1129,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1130",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1130.png",
     "id": 1130,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1131",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1131.png",
     "id": 1131,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1132",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1132.png",
     "id": 1132,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1133",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1133.png",
     "id": 1133,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1134",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1134.png",
     "id": 1134,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1135",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1135.png",
     "id": 1135,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1136",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1136.png",
     "id": 1136,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1137",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1137.png",
     "id": 1137,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1138",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1138.png",
     "id": 1138,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1139",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1139.png",
     "id": 1139,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1140",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1140.png",
     "id": 1140,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1141",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1141.png",
     "id": 1141,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1142",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1142.png",
     "id": 1142,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1143",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1143.png",
     "id": 1143,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1144",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1144.png",
     "id": 1144,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1145",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1145.png",
     "id": 1145,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1146",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1146.png",
     "id": 1146,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1147",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1147.png",
     "id": 1147,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1148",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1148.png",
     "id": 1148,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1149",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1149.png",
     "id": 1149,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1150",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1150.png",
     "id": 1150,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1151",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1151.png",
     "id": 1151,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1152",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1152.png",
     "id": 1152,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1153",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1153.png",
     "id": 1153,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1154",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1154.png",
     "id": 1154,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1155",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1155.png",
     "id": 1155,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1156",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1156.png",
     "id": 1156,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1157",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1157.png",
     "id": 1157,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1158",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1158.png",
     "id": 1158,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1159",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1159.png",
     "id": 1159,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1160",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1160.png",
     "id": 1160,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1161",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1161.png",
     "id": 1161,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1162",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1162.png",
     "id": 1162,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1163",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1163.png",
     "id": 1163,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1164",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1164.png",
     "id": 1164,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1165",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1165.png",
     "id": 1165,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1166",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1166.png",
     "id": 1166,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1167",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1167.png",
     "id": 1167,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1168",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1168.png",
     "id": 1168,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1169",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1169.png",
     "id": 1169,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1170",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1170.png",
     "id": 1170,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1171",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1171.png",
     "id": 1171,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1172",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1172.png",
     "id": 1172,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1173",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1173.png",
     "id": 1173,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1174",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1174.png",
     "id": 1174,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1175",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1175.png",
     "id": 1175,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1176",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1176.png",
     "id": 1176,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1177",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1177.png",
     "id": 1177,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1178",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1178.png",
     "id": 1178,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1179",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1179.png",
     "id": 1179,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1180",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1180.png",
     "id": 1180,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1181",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1181.png",
     "id": 1181,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1182",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1182.png",
     "id": 1182,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1183",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1183.png",
     "id": 1183,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1184",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1184.png",
     "id": 1184,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1185",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1185.png",
     "id": 1185,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1186",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1186.png",
     "id": 1186,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1187",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1187.png",
     "id": 1187,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1188",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1188.png",
     "id": 1188,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1189",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1189.png",
     "id": 1189,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1190",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1190.png",
     "id": 1190,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1191",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1191.png",
     "id": 1191,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1192",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1192.png",
     "id": 1192,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1193",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1193.png",
     "id": 1193,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1194",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1194.png",
     "id": 1194,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1195",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1195.png",
     "id": 1195,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1196",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1196.png",
     "id": 1196,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1197",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1197.png",
     "id": 1197,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1198",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1198.png",
     "id": 1198,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1199",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1199.png",
     "id": 1199,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1200",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1200.png",
     "id": 1200,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1201",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1201.png",
     "id": 1201,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1202",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1202.png",
     "id": 1202,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1203",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1203.png",
     "id": 1203,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1204",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1204.png",
     "id": 1204,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1205",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1205.png",
     "id": 1205,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1206",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1206.png",
     "id": 1206,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1207",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1207.png",
     "id": 1207,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1208",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1208.png",
     "id": 1208,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1209",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1209.png",
     "id": 1209,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1210",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1210.png",
     "id": 1210,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1211",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1211.png",
     "id": 1211,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #1212",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1212.png",
     "id": 1212,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1213",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1213.png",
     "id": 1213,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1214",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1214.png",
     "id": 1214,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1215",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1215.png",
     "id": 1215,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1216",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1216.png",
     "id": 1216,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1217",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1217.png",
     "id": 1217,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1218",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1218.png",
     "id": 1218,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1219",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1219.png",
     "id": 1219,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1220",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1220.png",
     "id": 1220,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #1221",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1221.png",
     "id": 1221,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1222",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1222.png",
     "id": 1222,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1223",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1223.png",
     "id": 1223,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1224",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1224.png",
     "id": 1224,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1225",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1225.png",
     "id": 1225,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1226",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1226.png",
     "id": 1226,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1227",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1227.png",
     "id": 1227,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1228",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1228.png",
     "id": 1228,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1229",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1229.png",
     "id": 1229,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1230",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1230.png",
     "id": 1230,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1231",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1231.png",
     "id": 1231,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1232",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1232.png",
     "id": 1232,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1233",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1233.png",
     "id": 1233,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1234",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1234.png",
     "id": 1234,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1235",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1235.png",
     "id": 1235,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1236",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1236.png",
     "id": 1236,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1237",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1237.png",
     "id": 1237,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1238",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1238.png",
     "id": 1238,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1239",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1239.png",
     "id": 1239,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #1240",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1240.png",
     "id": 1240,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1241",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1241.png",
     "id": 1241,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1242",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1242.png",
     "id": 1242,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1243",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1243.png",
     "id": 1243,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1244",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1244.png",
     "id": 1244,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1245",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1245.png",
     "id": 1245,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1246",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1246.png",
     "id": 1246,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1247",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1247.png",
     "id": 1247,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1248",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1248.png",
     "id": 1248,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1249",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1249.png",
     "id": 1249,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1250",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1250.png",
     "id": 1250,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1251",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1251.png",
     "id": 1251,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1252",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1252.png",
     "id": 1252,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1253",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1253.png",
     "id": 1253,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1254",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1254.png",
     "id": 1254,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1255",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1255.png",
     "id": 1255,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1256",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1256.png",
     "id": 1256,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1257",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1257.png",
     "id": 1257,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1258",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1258.png",
     "id": 1258,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1259",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1259.png",
     "id": 1259,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1260",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1260.png",
     "id": 1260,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1261",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1261.png",
     "id": 1261,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1262",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1262.png",
     "id": 1262,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1263",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1263.png",
     "id": 1263,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1264",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1264.png",
     "id": 1264,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1265",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1265.png",
     "id": 1265,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1266",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1266.png",
     "id": 1266,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1267",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1267.png",
     "id": 1267,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1268",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1268.png",
     "id": 1268,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1269",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1269.png",
     "id": 1269,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1270",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1270.png",
     "id": 1270,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1271",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1271.png",
     "id": 1271,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1272",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1272.png",
     "id": 1272,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1273",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1273.png",
     "id": 1273,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1274",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1274.png",
     "id": 1274,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1275",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1275.png",
     "id": 1275,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1276",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1276.png",
     "id": 1276,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1277",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1277.png",
     "id": 1277,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1278",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1278.png",
     "id": 1278,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1279",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1279.png",
     "id": 1279,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1280",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1280.png",
     "id": 1280,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1281",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1281.png",
     "id": 1281,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1282",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1282.png",
     "id": 1282,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1283",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1283.png",
     "id": 1283,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1284",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1284.png",
     "id": 1284,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1285",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1285.png",
     "id": 1285,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1286",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1286.png",
     "id": 1286,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1287",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1287.png",
     "id": 1287,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1288",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1288.png",
     "id": 1288,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1289",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1289.png",
     "id": 1289,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1290",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1290.png",
     "id": 1290,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1291",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1291.png",
     "id": 1291,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1292",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1292.png",
     "id": 1292,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1293",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1293.png",
     "id": 1293,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1294",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1294.png",
     "id": 1294,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1295",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1295.png",
     "id": 1295,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1296",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1296.png",
     "id": 1296,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1297",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1297.png",
     "id": 1297,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1298",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1298.png",
     "id": 1298,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1299",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1299.png",
     "id": 1299,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1300",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1300.png",
     "id": 1300,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1301",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1301.png",
     "id": 1301,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1302",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1302.png",
     "id": 1302,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1303",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1303.png",
     "id": 1303,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1304",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1304.png",
     "id": 1304,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1305",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1305.png",
     "id": 1305,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1306",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1306.png",
     "id": 1306,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1307",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1307.png",
     "id": 1307,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1308",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1308.png",
     "id": 1308,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1309",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1309.png",
     "id": 1309,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1310",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1310.png",
     "id": 1310,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1311",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1311.png",
     "id": 1311,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1312",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1312.png",
     "id": 1312,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1313",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1313.png",
     "id": 1313,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1314",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1314.png",
     "id": 1314,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1315",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1315.png",
     "id": 1315,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1316",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1316.png",
     "id": 1316,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1317",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1317.png",
     "id": 1317,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1318",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1318.png",
     "id": 1318,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1319",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1319.png",
     "id": 1319,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1320",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1320.png",
     "id": 1320,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1321",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1321.png",
     "id": 1321,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1322",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1322.png",
     "id": 1322,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1323",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1323.png",
     "id": 1323,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1324",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1324.png",
     "id": 1324,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1325",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1325.png",
     "id": 1325,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1326",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1326.png",
     "id": 1326,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1327",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1327.png",
     "id": 1327,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1328",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1328.png",
     "id": 1328,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1329",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1329.png",
     "id": 1329,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1330",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1330.png",
     "id": 1330,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1331",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1331.png",
     "id": 1331,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1332",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1332.png",
     "id": 1332,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1333",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1333.png",
     "id": 1333,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1334",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1334.png",
     "id": 1334,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1335",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1335.png",
     "id": 1335,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1336",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1336.png",
     "id": 1336,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1337",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1337.png",
     "id": 1337,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1338",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1338.png",
     "id": 1338,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1339",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1339.png",
     "id": 1339,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1340",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1340.png",
     "id": 1340,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1341",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1341.png",
     "id": 1341,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1342",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1342.png",
     "id": 1342,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1343",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1343.png",
     "id": 1343,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1344",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1344.png",
     "id": 1344,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1345",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1345.png",
     "id": 1345,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #1346",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1346.png",
     "id": 1346,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1347",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1347.png",
     "id": 1347,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1348",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1348.png",
     "id": 1348,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1349",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1349.png",
     "id": 1349,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1350",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1350.png",
     "id": 1350,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1351",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1351.png",
     "id": 1351,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1352",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1352.png",
     "id": 1352,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1353",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1353.png",
     "id": 1353,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1354",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1354.png",
     "id": 1354,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1355",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1355.png",
     "id": 1355,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1356",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1356.png",
     "id": 1356,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1357",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1357.png",
     "id": 1357,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1358",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1358.png",
     "id": 1358,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1359",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1359.png",
     "id": 1359,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1360",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1360.png",
     "id": 1360,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1361",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1361.png",
     "id": 1361,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1362",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1362.png",
     "id": 1362,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1363",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1363.png",
     "id": 1363,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1364",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1364.png",
     "id": 1364,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1365",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1365.png",
     "id": 1365,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1366",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1366.png",
     "id": 1366,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1367",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1367.png",
     "id": 1367,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1368",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1368.png",
     "id": 1368,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1369",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1369.png",
     "id": 1369,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1370",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1370.png",
     "id": 1370,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1371",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1371.png",
     "id": 1371,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1372",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1372.png",
     "id": 1372,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1373",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1373.png",
     "id": 1373,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1374",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1374.png",
     "id": 1374,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1375",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1375.png",
     "id": 1375,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1376",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1376.png",
     "id": 1376,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1377",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1377.png",
     "id": 1377,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1378",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1378.png",
     "id": 1378,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1379",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1379.png",
     "id": 1379,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1380",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1380.png",
     "id": 1380,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1381",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1381.png",
     "id": 1381,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1382",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1382.png",
     "id": 1382,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1383",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1383.png",
     "id": 1383,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1384",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1384.png",
     "id": 1384,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1385",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1385.png",
     "id": 1385,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1386",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1386.png",
     "id": 1386,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1387",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1387.png",
     "id": 1387,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1388",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1388.png",
     "id": 1388,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1389",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1389.png",
     "id": 1389,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1390",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1390.png",
     "id": 1390,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1391",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1391.png",
     "id": 1391,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1392",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1392.png",
     "id": 1392,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1393",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1393.png",
     "id": 1393,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1394",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1394.png",
     "id": 1394,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1395",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1395.png",
     "id": 1395,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1396",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1396.png",
     "id": 1396,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1397",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1397.png",
     "id": 1397,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1398",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1398.png",
     "id": 1398,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1399",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1399.png",
     "id": 1399,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1400",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1400.png",
     "id": 1400,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1401",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1401.png",
     "id": 1401,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1402",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1402.png",
     "id": 1402,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1403",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1403.png",
     "id": 1403,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1404",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1404.png",
     "id": 1404,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1405",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1405.png",
     "id": 1405,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1406",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1406.png",
     "id": 1406,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1407",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1407.png",
     "id": 1407,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1408",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1408.png",
     "id": 1408,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1409",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1409.png",
     "id": 1409,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1410",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1410.png",
     "id": 1410,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1411",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1411.png",
     "id": 1411,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1412",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1412.png",
     "id": 1412,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1413",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1413.png",
     "id": 1413,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1414",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1414.png",
     "id": 1414,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1415",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1415.png",
     "id": 1415,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1416",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1416.png",
     "id": 1416,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1417",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1417.png",
     "id": 1417,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1418",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1418.png",
     "id": 1418,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1419",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1419.png",
     "id": 1419,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1420",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1420.png",
     "id": 1420,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1421",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1421.png",
     "id": 1421,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1422",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1422.png",
     "id": 1422,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1423",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1423.png",
     "id": 1423,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1424",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1424.png",
     "id": 1424,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1425",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1425.png",
     "id": 1425,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1426",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1426.png",
     "id": 1426,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1427",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1427.png",
     "id": 1427,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1428",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1428.png",
     "id": 1428,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1429",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1429.png",
     "id": 1429,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1430",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1430.png",
     "id": 1430,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1431",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1431.png",
     "id": 1431,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1432",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1432.png",
     "id": 1432,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1433",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1433.png",
     "id": 1433,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1434",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1434.png",
     "id": 1434,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1435",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1435.png",
     "id": 1435,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1436",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1436.png",
     "id": 1436,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1437",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1437.png",
     "id": 1437,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1438",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1438.png",
     "id": 1438,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1439",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1439.png",
     "id": 1439,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1440",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1440.png",
     "id": 1440,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1441",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1441.png",
     "id": 1441,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1442",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1442.png",
     "id": 1442,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1443",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1443.png",
     "id": 1443,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1444",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1444.png",
     "id": 1444,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1445",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1445.png",
     "id": 1445,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1446",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1446.png",
     "id": 1446,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1447",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1447.png",
     "id": 1447,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1448",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1448.png",
     "id": 1448,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1449",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1449.png",
     "id": 1449,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1450",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1450.png",
     "id": 1450,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1451",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1451.png",
     "id": 1451,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1452",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1452.png",
     "id": 1452,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1453",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1453.png",
     "id": 1453,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1454",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1454.png",
     "id": 1454,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1455",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1455.png",
     "id": 1455,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1456",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1456.png",
     "id": 1456,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1457",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1457.png",
     "id": 1457,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1458",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1458.png",
     "id": 1458,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1459",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1459.png",
     "id": 1459,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1460",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1460.png",
     "id": 1460,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1461",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1461.png",
     "id": 1461,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1462",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1462.png",
     "id": 1462,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1463",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1463.png",
     "id": 1463,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1464",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1464.png",
     "id": 1464,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1465",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1465.png",
     "id": 1465,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1466",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1466.png",
     "id": 1466,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1467",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1467.png",
     "id": 1467,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1468",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1468.png",
     "id": 1468,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1469",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1469.png",
     "id": 1469,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1470",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1470.png",
     "id": 1470,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1471",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1471.png",
     "id": 1471,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1472",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1472.png",
     "id": 1472,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1473",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1473.png",
     "id": 1473,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1474",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1474.png",
     "id": 1474,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1475",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1475.png",
     "id": 1475,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1476",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1476.png",
     "id": 1476,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1477",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1477.png",
     "id": 1477,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1478",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1478.png",
     "id": 1478,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1479",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1479.png",
     "id": 1479,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1480",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1480.png",
     "id": 1480,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1481",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1481.png",
     "id": 1481,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1482",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1482.png",
     "id": 1482,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1483",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1483.png",
     "id": 1483,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1484",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1484.png",
     "id": 1484,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1485",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1485.png",
     "id": 1485,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1486",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1486.png",
     "id": 1486,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1487",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1487.png",
     "id": 1487,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1488",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1488.png",
     "id": 1488,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1489",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1489.png",
     "id": 1489,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1490",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1490.png",
     "id": 1490,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1491",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1491.png",
     "id": 1491,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1492",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1492.png",
     "id": 1492,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1493",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1493.png",
     "id": 1493,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1494",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1494.png",
     "id": 1494,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1495",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1495.png",
     "id": 1495,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1496",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1496.png",
     "id": 1496,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1497",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1497.png",
     "id": 1497,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1498",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1498.png",
     "id": 1498,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1499",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1499.png",
     "id": 1499,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1500",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1500.png",
     "id": 1500,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1501",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1501.png",
     "id": 1501,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1502",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1502.png",
     "id": 1502,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1503",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1503.png",
     "id": 1503,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1504",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1504.png",
     "id": 1504,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1505",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1505.png",
     "id": 1505,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1506",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1506.png",
     "id": 1506,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1507",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1507.png",
     "id": 1507,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1508",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1508.png",
     "id": 1508,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1509",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1509.png",
     "id": 1509,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1510",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1510.png",
     "id": 1510,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1511",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1511.png",
     "id": 1511,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1512",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1512.png",
     "id": 1512,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1513",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1513.png",
     "id": 1513,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1514",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1514.png",
     "id": 1514,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1515",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1515.png",
     "id": 1515,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1516",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1516.png",
     "id": 1516,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1517",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1517.png",
     "id": 1517,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1518",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1518.png",
     "id": 1518,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1519",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1519.png",
     "id": 1519,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1520",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1520.png",
     "id": 1520,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1521",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1521.png",
     "id": 1521,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1522",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1522.png",
     "id": 1522,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1523",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1523.png",
     "id": 1523,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1524",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1524.png",
     "id": 1524,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1525",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1525.png",
     "id": 1525,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1526",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1526.png",
     "id": 1526,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1527",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1527.png",
     "id": 1527,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1528",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1528.png",
     "id": 1528,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1529",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1529.png",
     "id": 1529,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1530",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1530.png",
     "id": 1530,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1531",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1531.png",
     "id": 1531,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1532",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1532.png",
     "id": 1532,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1533",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1533.png",
     "id": 1533,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1534",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1534.png",
     "id": 1534,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1535",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1535.png",
     "id": 1535,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1536",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1536.png",
     "id": 1536,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1537",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1537.png",
     "id": 1537,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1538",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1538.png",
     "id": 1538,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1539",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1539.png",
     "id": 1539,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1540",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1540.png",
     "id": 1540,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1541",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1541.png",
     "id": 1541,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1542",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1542.png",
     "id": 1542,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1543",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1543.png",
     "id": 1543,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1544",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1544.png",
     "id": 1544,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1545",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1545.png",
     "id": 1545,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1546",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1546.png",
     "id": 1546,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1547",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1547.png",
     "id": 1547,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1548",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1548.png",
     "id": 1548,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1549",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1549.png",
     "id": 1549,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1550",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1550.png",
     "id": 1550,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1551",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1551.png",
     "id": 1551,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1552",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1552.png",
     "id": 1552,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1553",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1553.png",
     "id": 1553,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "623P"
      },
      {
       "trait_type": "Skin",
       "value": "623P"
      },
      {
       "trait_type": "Crypto",
       "value": "623P"
      },
      {
       "trait_type": "Activity",
       "value": "623P"
      },
      {
       "trait_type": "Outfit",
       "value": "623P"
      },
      {
       "trait_type": "Transportation",
       "value": "623P"
      },
      {
       "trait_type": "Head",
       "value": "623P"
      },
      {
       "trait_type": "Mouth",
       "value": "623P"
      },
      {
       "trait_type": "Eyes",
       "value": "623P"
      }
     ]
    },
    {
     "name": "Boomer Squad #1554",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1554.png",
     "id": 1554,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1555",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1555.png",
     "id": 1555,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1556",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1556.png",
     "id": 1556,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1557",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1557.png",
     "id": 1557,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1558",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1558.png",
     "id": 1558,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1559",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1559.png",
     "id": 1559,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1560",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1560.png",
     "id": 1560,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1561",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1561.png",
     "id": 1561,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1562",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1562.png",
     "id": 1562,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1563",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1563.png",
     "id": 1563,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1564",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1564.png",
     "id": 1564,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1565",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1565.png",
     "id": 1565,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1566",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1566.png",
     "id": 1566,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1567",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1567.png",
     "id": 1567,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1568",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1568.png",
     "id": 1568,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1569",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1569.png",
     "id": 1569,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1570",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1570.png",
     "id": 1570,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1571",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1571.png",
     "id": 1571,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1572",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1572.png",
     "id": 1572,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1573",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1573.png",
     "id": 1573,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1574",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1574.png",
     "id": 1574,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1575",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1575.png",
     "id": 1575,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1576",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1576.png",
     "id": 1576,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1577",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1577.png",
     "id": 1577,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1578",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1578.png",
     "id": 1578,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1579",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1579.png",
     "id": 1579,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1580",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1580.png",
     "id": 1580,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1581",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1581.png",
     "id": 1581,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1582",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1582.png",
     "id": 1582,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1583",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1583.png",
     "id": 1583,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1584",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1584.png",
     "id": 1584,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1585",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1585.png",
     "id": 1585,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1586",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1586.png",
     "id": 1586,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1587",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1587.png",
     "id": 1587,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1588",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1588.png",
     "id": 1588,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1589",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1589.png",
     "id": 1589,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1590",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1590.png",
     "id": 1590,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1591",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1591.png",
     "id": 1591,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1592",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1592.png",
     "id": 1592,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1593",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1593.png",
     "id": 1593,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1594",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1594.png",
     "id": 1594,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1595",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1595.png",
     "id": 1595,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1596",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1596.png",
     "id": 1596,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1597",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1597.png",
     "id": 1597,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1598",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1598.png",
     "id": 1598,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1599",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1599.png",
     "id": 1599,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1600",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1600.png",
     "id": 1600,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1601",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1601.png",
     "id": 1601,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1602",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1602.png",
     "id": 1602,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1603",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1603.png",
     "id": 1603,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1604",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1604.png",
     "id": 1604,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1605",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1605.png",
     "id": 1605,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1606",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1606.png",
     "id": 1606,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1607",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1607.png",
     "id": 1607,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1608",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1608.png",
     "id": 1608,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1609",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1609.png",
     "id": 1609,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1610",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1610.png",
     "id": 1610,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1611",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1611.png",
     "id": 1611,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1612",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1612.png",
     "id": 1612,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1613",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1613.png",
     "id": 1613,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1614",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1614.png",
     "id": 1614,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1615",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1615.png",
     "id": 1615,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1616",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1616.png",
     "id": 1616,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1617",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1617.png",
     "id": 1617,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1618",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1618.png",
     "id": 1618,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1619",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1619.png",
     "id": 1619,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1620",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1620.png",
     "id": 1620,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1621",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1621.png",
     "id": 1621,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1622",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1622.png",
     "id": 1622,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1623",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1623.png",
     "id": 1623,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1624",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1624.png",
     "id": 1624,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1625",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1625.png",
     "id": 1625,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1626",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1626.png",
     "id": 1626,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1627",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1627.png",
     "id": 1627,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1628",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1628.png",
     "id": 1628,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1629",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1629.png",
     "id": 1629,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #1630",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1630.png",
     "id": 1630,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1631",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1631.png",
     "id": 1631,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1632",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1632.png",
     "id": 1632,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1633",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1633.png",
     "id": 1633,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1634",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1634.png",
     "id": 1634,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1635",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1635.png",
     "id": 1635,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1636",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1636.png",
     "id": 1636,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1637",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1637.png",
     "id": 1637,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1638",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1638.png",
     "id": 1638,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1639",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1639.png",
     "id": 1639,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1640",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1640.png",
     "id": 1640,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1641",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1641.png",
     "id": 1641,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1642",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1642.png",
     "id": 1642,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1643",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1643.png",
     "id": 1643,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1644",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1644.png",
     "id": 1644,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1645",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1645.png",
     "id": 1645,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1646",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1646.png",
     "id": 1646,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1647",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1647.png",
     "id": 1647,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1648",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1648.png",
     "id": 1648,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1649",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1649.png",
     "id": 1649,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1650",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1650.png",
     "id": 1650,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #1651",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1651.png",
     "id": 1651,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1652",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1652.png",
     "id": 1652,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1653",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1653.png",
     "id": 1653,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1654",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1654.png",
     "id": 1654,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1655",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1655.png",
     "id": 1655,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1656",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1656.png",
     "id": 1656,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1657",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1657.png",
     "id": 1657,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1658",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1658.png",
     "id": 1658,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1659",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1659.png",
     "id": 1659,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1660",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1660.png",
     "id": 1660,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1661",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1661.png",
     "id": 1661,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1662",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1662.png",
     "id": 1662,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1663",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1663.png",
     "id": 1663,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1664",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1664.png",
     "id": 1664,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1665",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1665.png",
     "id": 1665,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1666",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1666.png",
     "id": 1666,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1667",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1667.png",
     "id": 1667,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1668",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1668.png",
     "id": 1668,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1669",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1669.png",
     "id": 1669,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1670",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1670.png",
     "id": 1670,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1671",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1671.png",
     "id": 1671,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1672",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1672.png",
     "id": 1672,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1673",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1673.png",
     "id": 1673,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1674",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1674.png",
     "id": 1674,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1675",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1675.png",
     "id": 1675,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1676",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1676.png",
     "id": 1676,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1677",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1677.png",
     "id": 1677,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1678",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1678.png",
     "id": 1678,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1679",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1679.png",
     "id": 1679,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1680",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1680.png",
     "id": 1680,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1681",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1681.png",
     "id": 1681,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1682",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1682.png",
     "id": 1682,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1683",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1683.png",
     "id": 1683,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1684",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1684.png",
     "id": 1684,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1685",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1685.png",
     "id": 1685,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1686",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1686.png",
     "id": 1686,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1687",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1687.png",
     "id": 1687,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1688",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1688.png",
     "id": 1688,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1689",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1689.png",
     "id": 1689,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1690",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1690.png",
     "id": 1690,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1691",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1691.png",
     "id": 1691,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1692",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1692.png",
     "id": 1692,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1693",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1693.png",
     "id": 1693,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1694",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1694.png",
     "id": 1694,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1695",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1695.png",
     "id": 1695,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1696",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1696.png",
     "id": 1696,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1697",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1697.png",
     "id": 1697,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1698",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1698.png",
     "id": 1698,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1699",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1699.png",
     "id": 1699,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1700",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1700.png",
     "id": 1700,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1701",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1701.png",
     "id": 1701,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1702",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1702.png",
     "id": 1702,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1703",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1703.png",
     "id": 1703,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1704",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1704.png",
     "id": 1704,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1705",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1705.png",
     "id": 1705,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1706",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1706.png",
     "id": 1706,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1707",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1707.png",
     "id": 1707,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #1708",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1708.png",
     "id": 1708,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1709",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1709.png",
     "id": 1709,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1710",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1710.png",
     "id": 1710,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1711",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1711.png",
     "id": 1711,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1712",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1712.png",
     "id": 1712,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1713",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1713.png",
     "id": 1713,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1714",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1714.png",
     "id": 1714,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1715",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1715.png",
     "id": 1715,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1716",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1716.png",
     "id": 1716,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1717",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1717.png",
     "id": 1717,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1718",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1718.png",
     "id": 1718,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1719",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1719.png",
     "id": 1719,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1720",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1720.png",
     "id": 1720,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1721",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1721.png",
     "id": 1721,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1722",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1722.png",
     "id": 1722,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1723",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1723.png",
     "id": 1723,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1724",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1724.png",
     "id": 1724,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1725",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1725.png",
     "id": 1725,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1726",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1726.png",
     "id": 1726,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1727",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1727.png",
     "id": 1727,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1728",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1728.png",
     "id": 1728,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1729",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1729.png",
     "id": 1729,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1730",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1730.png",
     "id": 1730,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1731",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1731.png",
     "id": 1731,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1732",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1732.png",
     "id": 1732,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1733",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1733.png",
     "id": 1733,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1734",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1734.png",
     "id": 1734,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1735",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1735.png",
     "id": 1735,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1736",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1736.png",
     "id": 1736,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1737",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1737.png",
     "id": 1737,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1738",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1738.png",
     "id": 1738,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1739",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1739.png",
     "id": 1739,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1740",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1740.png",
     "id": 1740,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1741",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1741.png",
     "id": 1741,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1742",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1742.png",
     "id": 1742,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1743",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1743.png",
     "id": 1743,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1744",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1744.png",
     "id": 1744,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1745",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1745.png",
     "id": 1745,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1746",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1746.png",
     "id": 1746,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1747",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1747.png",
     "id": 1747,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1748",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1748.png",
     "id": 1748,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1749",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1749.png",
     "id": 1749,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1750",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1750.png",
     "id": 1750,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1751",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1751.png",
     "id": 1751,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1752",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1752.png",
     "id": 1752,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1753",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1753.png",
     "id": 1753,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1754",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1754.png",
     "id": 1754,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1755",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1755.png",
     "id": 1755,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1756",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1756.png",
     "id": 1756,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1757",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1757.png",
     "id": 1757,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1758",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1758.png",
     "id": 1758,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1759",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1759.png",
     "id": 1759,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1760",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1760.png",
     "id": 1760,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1761",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1761.png",
     "id": 1761,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1762",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1762.png",
     "id": 1762,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1763",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1763.png",
     "id": 1763,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1764",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1764.png",
     "id": 1764,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1765",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1765.png",
     "id": 1765,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1766",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1766.png",
     "id": 1766,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1767",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1767.png",
     "id": 1767,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1768",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1768.png",
     "id": 1768,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1769",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1769.png",
     "id": 1769,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1770",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1770.png",
     "id": 1770,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1771",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1771.png",
     "id": 1771,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1772",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1772.png",
     "id": 1772,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1773",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1773.png",
     "id": 1773,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1774",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1774.png",
     "id": 1774,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #1775",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1775.png",
     "id": 1775,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1776",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1776.png",
     "id": 1776,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1777",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1777.png",
     "id": 1777,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1778",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1778.png",
     "id": 1778,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1779",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1779.png",
     "id": 1779,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1780",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1780.png",
     "id": 1780,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1781",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1781.png",
     "id": 1781,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1782",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1782.png",
     "id": 1782,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1783",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1783.png",
     "id": 1783,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1784",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1784.png",
     "id": 1784,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1785",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1785.png",
     "id": 1785,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1786",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1786.png",
     "id": 1786,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1787",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1787.png",
     "id": 1787,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #1788",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1788.png",
     "id": 1788,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1789",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1789.png",
     "id": 1789,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1790",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1790.png",
     "id": 1790,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1791",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1791.png",
     "id": 1791,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1792",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1792.png",
     "id": 1792,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1793",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1793.png",
     "id": 1793,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1794",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1794.png",
     "id": 1794,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1795",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1795.png",
     "id": 1795,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1796",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1796.png",
     "id": 1796,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1797",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1797.png",
     "id": 1797,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1798",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1798.png",
     "id": 1798,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1799",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1799.png",
     "id": 1799,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1800",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1800.png",
     "id": 1800,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1801",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1801.png",
     "id": 1801,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1802",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1802.png",
     "id": 1802,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1803",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1803.png",
     "id": 1803,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1804",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1804.png",
     "id": 1804,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1805",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1805.png",
     "id": 1805,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1806",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1806.png",
     "id": 1806,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1807",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1807.png",
     "id": 1807,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1808",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1808.png",
     "id": 1808,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1809",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1809.png",
     "id": 1809,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1810",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1810.png",
     "id": 1810,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1811",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1811.png",
     "id": 1811,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1812",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1812.png",
     "id": 1812,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1813",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1813.png",
     "id": 1813,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1814",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1814.png",
     "id": 1814,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1815",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1815.png",
     "id": 1815,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1816",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1816.png",
     "id": 1816,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1817",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1817.png",
     "id": 1817,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1818",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1818.png",
     "id": 1818,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1819",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1819.png",
     "id": 1819,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1820",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1820.png",
     "id": 1820,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1821",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1821.png",
     "id": 1821,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1822",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1822.png",
     "id": 1822,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1823",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1823.png",
     "id": 1823,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1824",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1824.png",
     "id": 1824,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1825",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1825.png",
     "id": 1825,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1826",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1826.png",
     "id": 1826,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1827",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1827.png",
     "id": 1827,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1828",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1828.png",
     "id": 1828,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1829",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1829.png",
     "id": 1829,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1830",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1830.png",
     "id": 1830,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1831",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1831.png",
     "id": 1831,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1832",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1832.png",
     "id": 1832,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1833",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1833.png",
     "id": 1833,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1834",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1834.png",
     "id": 1834,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1835",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1835.png",
     "id": 1835,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1836",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1836.png",
     "id": 1836,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1837",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1837.png",
     "id": 1837,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1838",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1838.png",
     "id": 1838,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Captain Tendies"
      },
      {
       "trait_type": "Skin",
       "value": "Captain Tendies"
      },
      {
       "trait_type": "Crypto",
       "value": "Captain Tendies"
      },
      {
       "trait_type": "Activity",
       "value": "Captain Tendies"
      },
      {
       "trait_type": "Outfit",
       "value": "Captain Tendies"
      },
      {
       "trait_type": "Transportation",
       "value": "Captain Tendies"
      },
      {
       "trait_type": "Head",
       "value": "Captain Tendies"
      },
      {
       "trait_type": "Mouth",
       "value": "Captain Tendies"
      },
      {
       "trait_type": "Eyes",
       "value": "Captain Tendies"
      }
     ]
    },
    {
     "name": "Boomer Squad #1839",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1839.png",
     "id": 1839,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1840",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1840.png",
     "id": 1840,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1841",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1841.png",
     "id": 1841,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1842",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1842.png",
     "id": 1842,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1843",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1843.png",
     "id": 1843,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1844",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1844.png",
     "id": 1844,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1845",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1845.png",
     "id": 1845,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1846",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1846.png",
     "id": 1846,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1847",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1847.png",
     "id": 1847,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1848",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1848.png",
     "id": 1848,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1849",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1849.png",
     "id": 1849,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1850",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1850.png",
     "id": 1850,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1851",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1851.png",
     "id": 1851,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1852",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1852.png",
     "id": 1852,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #1853",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1853.png",
     "id": 1853,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1854",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1854.png",
     "id": 1854,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1855",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1855.png",
     "id": 1855,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1856",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1856.png",
     "id": 1856,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1857",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1857.png",
     "id": 1857,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1858",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1858.png",
     "id": 1858,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1859",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1859.png",
     "id": 1859,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1860",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1860.png",
     "id": 1860,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1861",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1861.png",
     "id": 1861,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1862",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1862.png",
     "id": 1862,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1863",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1863.png",
     "id": 1863,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1864",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1864.png",
     "id": 1864,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1865",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1865.png",
     "id": 1865,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1866",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1866.png",
     "id": 1866,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1867",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1867.png",
     "id": 1867,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1868",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1868.png",
     "id": 1868,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1869",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1869.png",
     "id": 1869,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1870",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1870.png",
     "id": 1870,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1871",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1871.png",
     "id": 1871,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1872",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1872.png",
     "id": 1872,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1873",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1873.png",
     "id": 1873,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1874",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1874.png",
     "id": 1874,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1875",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1875.png",
     "id": 1875,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1876",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1876.png",
     "id": 1876,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1877",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1877.png",
     "id": 1877,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1878",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1878.png",
     "id": 1878,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1879",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1879.png",
     "id": 1879,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1880",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1880.png",
     "id": 1880,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1881",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1881.png",
     "id": 1881,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1882",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1882.png",
     "id": 1882,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1883",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1883.png",
     "id": 1883,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1884",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1884.png",
     "id": 1884,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1885",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1885.png",
     "id": 1885,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1886",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1886.png",
     "id": 1886,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1887",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1887.png",
     "id": 1887,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1888",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1888.png",
     "id": 1888,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1889",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1889.png",
     "id": 1889,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1890",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1890.png",
     "id": 1890,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1891",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1891.png",
     "id": 1891,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1892",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1892.png",
     "id": 1892,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1893",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1893.png",
     "id": 1893,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1894",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1894.png",
     "id": 1894,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1895",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1895.png",
     "id": 1895,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1896",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1896.png",
     "id": 1896,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1897",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1897.png",
     "id": 1897,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1898",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1898.png",
     "id": 1898,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1899",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1899.png",
     "id": 1899,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1900",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1900.png",
     "id": 1900,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1901",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1901.png",
     "id": 1901,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1902",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1902.png",
     "id": 1902,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1903",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1903.png",
     "id": 1903,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1904",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1904.png",
     "id": 1904,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1905",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1905.png",
     "id": 1905,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1906",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1906.png",
     "id": 1906,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1907",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1907.png",
     "id": 1907,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1908",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1908.png",
     "id": 1908,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1909",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1909.png",
     "id": 1909,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1910",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1910.png",
     "id": 1910,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1911",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1911.png",
     "id": 1911,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1912",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1912.png",
     "id": 1912,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1913",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1913.png",
     "id": 1913,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1914",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1914.png",
     "id": 1914,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1915",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1915.png",
     "id": 1915,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1916",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1916.png",
     "id": 1916,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1917",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1917.png",
     "id": 1917,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1918",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1918.png",
     "id": 1918,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1919",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1919.png",
     "id": 1919,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1920",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1920.png",
     "id": 1920,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #1921",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1921.png",
     "id": 1921,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1922",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1922.png",
     "id": 1922,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1923",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1923.png",
     "id": 1923,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1924",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1924.png",
     "id": 1924,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1925",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1925.png",
     "id": 1925,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1926",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1926.png",
     "id": 1926,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1927",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1927.png",
     "id": 1927,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1928",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1928.png",
     "id": 1928,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1929",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1929.png",
     "id": 1929,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1930",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1930.png",
     "id": 1930,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1931",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1931.png",
     "id": 1931,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1932",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1932.png",
     "id": 1932,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1933",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1933.png",
     "id": 1933,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #1934",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1934.png",
     "id": 1934,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1935",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1935.png",
     "id": 1935,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1936",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1936.png",
     "id": 1936,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1937",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1937.png",
     "id": 1937,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1938",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1938.png",
     "id": 1938,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1939",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1939.png",
     "id": 1939,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1940",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1940.png",
     "id": 1940,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1941",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1941.png",
     "id": 1941,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1942",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1942.png",
     "id": 1942,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1943",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1943.png",
     "id": 1943,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1944",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1944.png",
     "id": 1944,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1945",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1945.png",
     "id": 1945,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1946",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1946.png",
     "id": 1946,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1947",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1947.png",
     "id": 1947,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1948",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1948.png",
     "id": 1948,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1949",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1949.png",
     "id": 1949,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1950",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1950.png",
     "id": 1950,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1951",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1951.png",
     "id": 1951,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1952",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1952.png",
     "id": 1952,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1953",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1953.png",
     "id": 1953,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1954",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1954.png",
     "id": 1954,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1955",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1955.png",
     "id": 1955,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1956",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1956.png",
     "id": 1956,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1957",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1957.png",
     "id": 1957,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1958",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1958.png",
     "id": 1958,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1959",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1959.png",
     "id": 1959,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1960",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1960.png",
     "id": 1960,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1961",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1961.png",
     "id": 1961,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #1962",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1962.png",
     "id": 1962,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1963",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1963.png",
     "id": 1963,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1964",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1964.png",
     "id": 1964,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1965",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1965.png",
     "id": 1965,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #1966",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1966.png",
     "id": 1966,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #1967",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1967.png",
     "id": 1967,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1968",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1968.png",
     "id": 1968,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1969",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1969.png",
     "id": 1969,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #1970",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1970.png",
     "id": 1970,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #1971",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1971.png",
     "id": 1971,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #1972",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1972.png",
     "id": 1972,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1973",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1973.png",
     "id": 1973,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1974",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1974.png",
     "id": 1974,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1975",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1975.png",
     "id": 1975,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1976",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1976.png",
     "id": 1976,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1977",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1977.png",
     "id": 1977,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1978",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1978.png",
     "id": 1978,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1979",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1979.png",
     "id": 1979,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1980",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1980.png",
     "id": 1980,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #1981",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1981.png",
     "id": 1981,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #1982",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1982.png",
     "id": 1982,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #1983",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1983.png",
     "id": 1983,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1984",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1984.png",
     "id": 1984,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #1985",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1985.png",
     "id": 1985,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #1986",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1986.png",
     "id": 1986,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #1987",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1987.png",
     "id": 1987,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1988",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1988.png",
     "id": 1988,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #1989",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1989.png",
     "id": 1989,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #1990",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1990.png",
     "id": 1990,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #1991",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1991.png",
     "id": 1991,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #1992",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1992.png",
     "id": 1992,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #1993",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1993.png",
     "id": 1993,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #1994",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1994.png",
     "id": 1994,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1995",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1995.png",
     "id": 1995,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #1996",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1996.png",
     "id": 1996,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #1997",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1997.png",
     "id": 1997,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #1998",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1998.png",
     "id": 1998,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #1999",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/1999.png",
     "id": 1999,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2000",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2000.png",
     "id": 2000,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2001",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2001.png",
     "id": 2001,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2002",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2002.png",
     "id": 2002,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2003",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2003.png",
     "id": 2003,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2004",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2004.png",
     "id": 2004,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2005",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2005.png",
     "id": 2005,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2006",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2006.png",
     "id": 2006,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2007",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2007.png",
     "id": 2007,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2008",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2008.png",
     "id": 2008,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2009",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2009.png",
     "id": 2009,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2010",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2010.png",
     "id": 2010,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2011",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2011.png",
     "id": 2011,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2012",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2012.png",
     "id": 2012,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2013",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2013.png",
     "id": 2013,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2014",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2014.png",
     "id": 2014,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2015",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2015.png",
     "id": 2015,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2016",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2016.png",
     "id": 2016,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2017",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2017.png",
     "id": 2017,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2018",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2018.png",
     "id": 2018,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2019",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2019.png",
     "id": 2019,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2020",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2020.png",
     "id": 2020,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2021",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2021.png",
     "id": 2021,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2022",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2022.png",
     "id": 2022,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2023",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2023.png",
     "id": 2023,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2024",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2024.png",
     "id": 2024,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2025",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2025.png",
     "id": 2025,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2026",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2026.png",
     "id": 2026,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2027",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2027.png",
     "id": 2027,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2028",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2028.png",
     "id": 2028,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2029",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2029.png",
     "id": 2029,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2030",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2030.png",
     "id": 2030,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2031",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2031.png",
     "id": 2031,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2032",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2032.png",
     "id": 2032,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2033",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2033.png",
     "id": 2033,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2034",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2034.png",
     "id": 2034,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2035",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2035.png",
     "id": 2035,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2036",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2036.png",
     "id": 2036,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2037",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2037.png",
     "id": 2037,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2038",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2038.png",
     "id": 2038,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2039",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2039.png",
     "id": 2039,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2040",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2040.png",
     "id": 2040,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2041",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2041.png",
     "id": 2041,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2042",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2042.png",
     "id": 2042,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2043",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2043.png",
     "id": 2043,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2044",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2044.png",
     "id": 2044,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2045",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2045.png",
     "id": 2045,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2046",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2046.png",
     "id": 2046,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2047",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2047.png",
     "id": 2047,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2048",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2048.png",
     "id": 2048,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2049",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2049.png",
     "id": 2049,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2050",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2050.png",
     "id": 2050,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2051",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2051.png",
     "id": 2051,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2052",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2052.png",
     "id": 2052,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2053",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2053.png",
     "id": 2053,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2054",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2054.png",
     "id": 2054,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2055",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2055.png",
     "id": 2055,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2056",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2056.png",
     "id": 2056,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2057",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2057.png",
     "id": 2057,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2058",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2058.png",
     "id": 2058,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2059",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2059.png",
     "id": 2059,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2060",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2060.png",
     "id": 2060,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2061",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2061.png",
     "id": 2061,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2062",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2062.png",
     "id": 2062,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #2063",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2063.png",
     "id": 2063,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2064",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2064.png",
     "id": 2064,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2065",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2065.png",
     "id": 2065,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2066",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2066.png",
     "id": 2066,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2067",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2067.png",
     "id": 2067,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2068",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2068.png",
     "id": 2068,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2069",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2069.png",
     "id": 2069,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2070",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2070.png",
     "id": 2070,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2071",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2071.png",
     "id": 2071,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2072",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2072.png",
     "id": 2072,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2073",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2073.png",
     "id": 2073,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2074",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2074.png",
     "id": 2074,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2075",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2075.png",
     "id": 2075,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2076",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2076.png",
     "id": 2076,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2077",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2077.png",
     "id": 2077,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2078",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2078.png",
     "id": 2078,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2079",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2079.png",
     "id": 2079,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2080",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2080.png",
     "id": 2080,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2081",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2081.png",
     "id": 2081,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2082",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2082.png",
     "id": 2082,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #2083",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2083.png",
     "id": 2083,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2084",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2084.png",
     "id": 2084,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2085",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2085.png",
     "id": 2085,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2086",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2086.png",
     "id": 2086,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2087",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2087.png",
     "id": 2087,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2088",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2088.png",
     "id": 2088,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2089",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2089.png",
     "id": 2089,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2090",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2090.png",
     "id": 2090,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2091",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2091.png",
     "id": 2091,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2092",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2092.png",
     "id": 2092,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2093",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2093.png",
     "id": 2093,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2094",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2094.png",
     "id": 2094,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2095",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2095.png",
     "id": 2095,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2096",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2096.png",
     "id": 2096,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2097",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2097.png",
     "id": 2097,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2098",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2098.png",
     "id": 2098,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2099",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2099.png",
     "id": 2099,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #2100",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2100.png",
     "id": 2100,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2101",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2101.png",
     "id": 2101,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2102",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2102.png",
     "id": 2102,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #2103",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2103.png",
     "id": 2103,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2104",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2104.png",
     "id": 2104,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2105",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2105.png",
     "id": 2105,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2106",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2106.png",
     "id": 2106,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2107",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2107.png",
     "id": 2107,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2108",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2108.png",
     "id": 2108,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2109",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2109.png",
     "id": 2109,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2110",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2110.png",
     "id": 2110,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2111",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2111.png",
     "id": 2111,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2112",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2112.png",
     "id": 2112,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2113",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2113.png",
     "id": 2113,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2114",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2114.png",
     "id": 2114,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2115",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2115.png",
     "id": 2115,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2116",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2116.png",
     "id": 2116,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2117",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2117.png",
     "id": 2117,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2118",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2118.png",
     "id": 2118,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2119",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2119.png",
     "id": 2119,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2120",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2120.png",
     "id": 2120,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2121",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2121.png",
     "id": 2121,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2122",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2122.png",
     "id": 2122,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2123",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2123.png",
     "id": 2123,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2124",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2124.png",
     "id": 2124,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2125",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2125.png",
     "id": 2125,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2126",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2126.png",
     "id": 2126,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2127",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2127.png",
     "id": 2127,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2128",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2128.png",
     "id": 2128,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2129",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2129.png",
     "id": 2129,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2130",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2130.png",
     "id": 2130,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2131",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2131.png",
     "id": 2131,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2132",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2132.png",
     "id": 2132,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2133",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2133.png",
     "id": 2133,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2134",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2134.png",
     "id": 2134,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2135",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2135.png",
     "id": 2135,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2136",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2136.png",
     "id": 2136,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2137",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2137.png",
     "id": 2137,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2138",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2138.png",
     "id": 2138,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2139",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2139.png",
     "id": 2139,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2140",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2140.png",
     "id": 2140,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2141",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2141.png",
     "id": 2141,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2142",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2142.png",
     "id": 2142,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2143",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2143.png",
     "id": 2143,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2144",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2144.png",
     "id": 2144,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2145",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2145.png",
     "id": 2145,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2146",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2146.png",
     "id": 2146,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2147",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2147.png",
     "id": 2147,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2148",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2148.png",
     "id": 2148,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2149",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2149.png",
     "id": 2149,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2150",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2150.png",
     "id": 2150,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2151",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2151.png",
     "id": 2151,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2152",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2152.png",
     "id": 2152,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #2153",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2153.png",
     "id": 2153,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2154",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2154.png",
     "id": 2154,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2155",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2155.png",
     "id": 2155,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2156",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2156.png",
     "id": 2156,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2157",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2157.png",
     "id": 2157,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2158",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2158.png",
     "id": 2158,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2159",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2159.png",
     "id": 2159,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2160",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2160.png",
     "id": 2160,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2161",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2161.png",
     "id": 2161,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2162",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2162.png",
     "id": 2162,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2163",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2163.png",
     "id": 2163,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2164",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2164.png",
     "id": 2164,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2165",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2165.png",
     "id": 2165,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2166",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2166.png",
     "id": 2166,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2167",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2167.png",
     "id": 2167,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2168",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2168.png",
     "id": 2168,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2169",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2169.png",
     "id": 2169,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2170",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2170.png",
     "id": 2170,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2171",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2171.png",
     "id": 2171,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2172",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2172.png",
     "id": 2172,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2173",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2173.png",
     "id": 2173,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2174",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2174.png",
     "id": 2174,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2175",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2175.png",
     "id": 2175,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2176",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2176.png",
     "id": 2176,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2177",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2177.png",
     "id": 2177,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2178",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2178.png",
     "id": 2178,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2179",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2179.png",
     "id": 2179,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2180",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2180.png",
     "id": 2180,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2181",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2181.png",
     "id": 2181,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2182",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2182.png",
     "id": 2182,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2183",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2183.png",
     "id": 2183,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2184",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2184.png",
     "id": 2184,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2185",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2185.png",
     "id": 2185,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Coin Raider"
      },
      {
       "trait_type": "Skin",
       "value": "Coin Raider"
      },
      {
       "trait_type": "Crypto",
       "value": "Coin Raider"
      },
      {
       "trait_type": "Activity",
       "value": "Coin Raider"
      },
      {
       "trait_type": "Outfit",
       "value": "Coin Raider"
      },
      {
       "trait_type": "Transportation",
       "value": "Coin Raider"
      },
      {
       "trait_type": "Head",
       "value": "Coin Raider"
      },
      {
       "trait_type": "Mouth",
       "value": "Coin Raider"
      },
      {
       "trait_type": "Eyes",
       "value": "Coin Raider"
      }
     ]
    },
    {
     "name": "Boomer Squad #2186",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2186.png",
     "id": 2186,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2187",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2187.png",
     "id": 2187,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2188",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2188.png",
     "id": 2188,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2189",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2189.png",
     "id": 2189,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2190",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2190.png",
     "id": 2190,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2191",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2191.png",
     "id": 2191,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2192",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2192.png",
     "id": 2192,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2193",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2193.png",
     "id": 2193,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2194",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2194.png",
     "id": 2194,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2195",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2195.png",
     "id": 2195,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2196",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2196.png",
     "id": 2196,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2197",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2197.png",
     "id": 2197,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2198",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2198.png",
     "id": 2198,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2199",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2199.png",
     "id": 2199,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2200",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2200.png",
     "id": 2200,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2201",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2201.png",
     "id": 2201,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2202",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2202.png",
     "id": 2202,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2203",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2203.png",
     "id": 2203,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2204",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2204.png",
     "id": 2204,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2205",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2205.png",
     "id": 2205,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2206",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2206.png",
     "id": 2206,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2207",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2207.png",
     "id": 2207,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2208",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2208.png",
     "id": 2208,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2209",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2209.png",
     "id": 2209,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2210",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2210.png",
     "id": 2210,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2211",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2211.png",
     "id": 2211,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2212",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2212.png",
     "id": 2212,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2213",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2213.png",
     "id": 2213,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2214",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2214.png",
     "id": 2214,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2215",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2215.png",
     "id": 2215,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2216",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2216.png",
     "id": 2216,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2217",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2217.png",
     "id": 2217,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2218",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2218.png",
     "id": 2218,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #2219",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2219.png",
     "id": 2219,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2220",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2220.png",
     "id": 2220,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2221",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2221.png",
     "id": 2221,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2222",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2222.png",
     "id": 2222,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2223",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2223.png",
     "id": 2223,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2224",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2224.png",
     "id": 2224,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2225",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2225.png",
     "id": 2225,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2226",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2226.png",
     "id": 2226,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2227",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2227.png",
     "id": 2227,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2228",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2228.png",
     "id": 2228,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2229",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2229.png",
     "id": 2229,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2230",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2230.png",
     "id": 2230,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2231",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2231.png",
     "id": 2231,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2232",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2232.png",
     "id": 2232,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2233",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2233.png",
     "id": 2233,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2234",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2234.png",
     "id": 2234,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2235",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2235.png",
     "id": 2235,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2236",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2236.png",
     "id": 2236,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2237",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2237.png",
     "id": 2237,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2238",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2238.png",
     "id": 2238,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2239",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2239.png",
     "id": 2239,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2240",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2240.png",
     "id": 2240,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2241",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2241.png",
     "id": 2241,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2242",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2242.png",
     "id": 2242,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2243",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2243.png",
     "id": 2243,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2244",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2244.png",
     "id": 2244,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2245",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2245.png",
     "id": 2245,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2246",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2246.png",
     "id": 2246,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2247",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2247.png",
     "id": 2247,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2248",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2248.png",
     "id": 2248,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2249",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2249.png",
     "id": 2249,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2250",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2250.png",
     "id": 2250,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2251",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2251.png",
     "id": 2251,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2252",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2252.png",
     "id": 2252,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2253",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2253.png",
     "id": 2253,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2254",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2254.png",
     "id": 2254,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2255",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2255.png",
     "id": 2255,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2256",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2256.png",
     "id": 2256,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2257",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2257.png",
     "id": 2257,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2258",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2258.png",
     "id": 2258,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2259",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2259.png",
     "id": 2259,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2260",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2260.png",
     "id": 2260,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2261",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2261.png",
     "id": 2261,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2262",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2262.png",
     "id": 2262,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2263",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2263.png",
     "id": 2263,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2264",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2264.png",
     "id": 2264,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2265",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2265.png",
     "id": 2265,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2266",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2266.png",
     "id": 2266,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2267",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2267.png",
     "id": 2267,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2268",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2268.png",
     "id": 2268,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2269",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2269.png",
     "id": 2269,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2270",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2270.png",
     "id": 2270,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #2271",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2271.png",
     "id": 2271,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2272",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2272.png",
     "id": 2272,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2273",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2273.png",
     "id": 2273,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2274",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2274.png",
     "id": 2274,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2275",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2275.png",
     "id": 2275,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2276",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2276.png",
     "id": 2276,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2277",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2277.png",
     "id": 2277,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2278",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2278.png",
     "id": 2278,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2279",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2279.png",
     "id": 2279,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2280",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2280.png",
     "id": 2280,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2281",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2281.png",
     "id": 2281,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2282",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2282.png",
     "id": 2282,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2283",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2283.png",
     "id": 2283,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2284",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2284.png",
     "id": 2284,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2285",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2285.png",
     "id": 2285,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2286",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2286.png",
     "id": 2286,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2287",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2287.png",
     "id": 2287,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2288",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2288.png",
     "id": 2288,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2289",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2289.png",
     "id": 2289,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2290",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2290.png",
     "id": 2290,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2291",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2291.png",
     "id": 2291,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2292",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2292.png",
     "id": 2292,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2293",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2293.png",
     "id": 2293,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2294",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2294.png",
     "id": 2294,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2295",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2295.png",
     "id": 2295,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2296",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2296.png",
     "id": 2296,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2297",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2297.png",
     "id": 2297,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2298",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2298.png",
     "id": 2298,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2299",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2299.png",
     "id": 2299,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2300",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2300.png",
     "id": 2300,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2301",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2301.png",
     "id": 2301,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2302",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2302.png",
     "id": 2302,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2303",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2303.png",
     "id": 2303,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2304",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2304.png",
     "id": 2304,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2305",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2305.png",
     "id": 2305,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2306",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2306.png",
     "id": 2306,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2307",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2307.png",
     "id": 2307,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2308",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2308.png",
     "id": 2308,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2309",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2309.png",
     "id": 2309,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2310",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2310.png",
     "id": 2310,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2311",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2311.png",
     "id": 2311,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2312",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2312.png",
     "id": 2312,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2313",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2313.png",
     "id": 2313,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2314",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2314.png",
     "id": 2314,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2315",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2315.png",
     "id": 2315,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2316",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2316.png",
     "id": 2316,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2317",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2317.png",
     "id": 2317,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #2318",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2318.png",
     "id": 2318,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2319",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2319.png",
     "id": 2319,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #2320",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2320.png",
     "id": 2320,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2321",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2321.png",
     "id": 2321,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2322",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2322.png",
     "id": 2322,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2323",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2323.png",
     "id": 2323,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2324",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2324.png",
     "id": 2324,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2325",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2325.png",
     "id": 2325,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2326",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2326.png",
     "id": 2326,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2327",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2327.png",
     "id": 2327,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2328",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2328.png",
     "id": 2328,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2329",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2329.png",
     "id": 2329,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2330",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2330.png",
     "id": 2330,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2331",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2331.png",
     "id": 2331,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2332",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2332.png",
     "id": 2332,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2333",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2333.png",
     "id": 2333,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2334",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2334.png",
     "id": 2334,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2335",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2335.png",
     "id": 2335,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2336",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2336.png",
     "id": 2336,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2337",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2337.png",
     "id": 2337,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2338",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2338.png",
     "id": 2338,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2339",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2339.png",
     "id": 2339,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2340",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2340.png",
     "id": 2340,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2341",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2341.png",
     "id": 2341,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2342",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2342.png",
     "id": 2342,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2343",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2343.png",
     "id": 2343,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2344",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2344.png",
     "id": 2344,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2345",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2345.png",
     "id": 2345,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2346",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2346.png",
     "id": 2346,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2347",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2347.png",
     "id": 2347,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2348",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2348.png",
     "id": 2348,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2349",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2349.png",
     "id": 2349,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2350",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2350.png",
     "id": 2350,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2351",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2351.png",
     "id": 2351,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2352",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2352.png",
     "id": 2352,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2353",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2353.png",
     "id": 2353,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2354",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2354.png",
     "id": 2354,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2355",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2355.png",
     "id": 2355,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2356",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2356.png",
     "id": 2356,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2357",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2357.png",
     "id": 2357,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2358",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2358.png",
     "id": 2358,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2359",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2359.png",
     "id": 2359,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2360",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2360.png",
     "id": 2360,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2361",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2361.png",
     "id": 2361,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2362",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2362.png",
     "id": 2362,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2363",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2363.png",
     "id": 2363,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2364",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2364.png",
     "id": 2364,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2365",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2365.png",
     "id": 2365,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2366",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2366.png",
     "id": 2366,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2367",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2367.png",
     "id": 2367,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2368",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2368.png",
     "id": 2368,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2369",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2369.png",
     "id": 2369,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2370",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2370.png",
     "id": 2370,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2371",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2371.png",
     "id": 2371,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2372",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2372.png",
     "id": 2372,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2373",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2373.png",
     "id": 2373,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2374",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2374.png",
     "id": 2374,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2375",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2375.png",
     "id": 2375,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2376",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2376.png",
     "id": 2376,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2377",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2377.png",
     "id": 2377,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2378",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2378.png",
     "id": 2378,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2379",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2379.png",
     "id": 2379,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2380",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2380.png",
     "id": 2380,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2381",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2381.png",
     "id": 2381,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2382",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2382.png",
     "id": 2382,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2383",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2383.png",
     "id": 2383,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2384",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2384.png",
     "id": 2384,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2385",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2385.png",
     "id": 2385,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2386",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2386.png",
     "id": 2386,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2387",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2387.png",
     "id": 2387,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2388",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2388.png",
     "id": 2388,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2389",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2389.png",
     "id": 2389,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2390",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2390.png",
     "id": 2390,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2391",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2391.png",
     "id": 2391,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2392",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2392.png",
     "id": 2392,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2393",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2393.png",
     "id": 2393,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2394",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2394.png",
     "id": 2394,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2395",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2395.png",
     "id": 2395,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2396",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2396.png",
     "id": 2396,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2397",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2397.png",
     "id": 2397,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2398",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2398.png",
     "id": 2398,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2399",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2399.png",
     "id": 2399,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2400",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2400.png",
     "id": 2400,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2401",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2401.png",
     "id": 2401,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2402",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2402.png",
     "id": 2402,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2403",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2403.png",
     "id": 2403,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2404",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2404.png",
     "id": 2404,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2405",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2405.png",
     "id": 2405,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2406",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2406.png",
     "id": 2406,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2407",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2407.png",
     "id": 2407,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2408",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2408.png",
     "id": 2408,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2409",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2409.png",
     "id": 2409,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2410",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2410.png",
     "id": 2410,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2411",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2411.png",
     "id": 2411,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2412",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2412.png",
     "id": 2412,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2413",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2413.png",
     "id": 2413,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2414",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2414.png",
     "id": 2414,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2415",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2415.png",
     "id": 2415,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2416",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2416.png",
     "id": 2416,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2417",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2417.png",
     "id": 2417,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2418",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2418.png",
     "id": 2418,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2419",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2419.png",
     "id": 2419,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2420",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2420.png",
     "id": 2420,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2421",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2421.png",
     "id": 2421,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2422",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2422.png",
     "id": 2422,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2423",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2423.png",
     "id": 2423,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2424",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2424.png",
     "id": 2424,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2425",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2425.png",
     "id": 2425,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2426",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2426.png",
     "id": 2426,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2427",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2427.png",
     "id": 2427,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2428",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2428.png",
     "id": 2428,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2429",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2429.png",
     "id": 2429,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2430",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2430.png",
     "id": 2430,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2431",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2431.png",
     "id": 2431,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2432",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2432.png",
     "id": 2432,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2433",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2433.png",
     "id": 2433,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2434",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2434.png",
     "id": 2434,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2435",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2435.png",
     "id": 2435,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2436",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2436.png",
     "id": 2436,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2437",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2437.png",
     "id": 2437,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2438",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2438.png",
     "id": 2438,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2439",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2439.png",
     "id": 2439,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2440",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2440.png",
     "id": 2440,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2441",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2441.png",
     "id": 2441,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2442",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2442.png",
     "id": 2442,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2443",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2443.png",
     "id": 2443,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2444",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2444.png",
     "id": 2444,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2445",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2445.png",
     "id": 2445,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2446",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2446.png",
     "id": 2446,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2447",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2447.png",
     "id": 2447,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2448",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2448.png",
     "id": 2448,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2449",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2449.png",
     "id": 2449,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2450",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2450.png",
     "id": 2450,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2451",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2451.png",
     "id": 2451,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2452",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2452.png",
     "id": 2452,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2453",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2453.png",
     "id": 2453,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2454",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2454.png",
     "id": 2454,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2455",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2455.png",
     "id": 2455,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2456",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2456.png",
     "id": 2456,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2457",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2457.png",
     "id": 2457,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2458",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2458.png",
     "id": 2458,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2459",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2459.png",
     "id": 2459,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2460",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2460.png",
     "id": 2460,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2461",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2461.png",
     "id": 2461,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2462",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2462.png",
     "id": 2462,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2463",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2463.png",
     "id": 2463,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2464",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2464.png",
     "id": 2464,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2465",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2465.png",
     "id": 2465,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2466",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2466.png",
     "id": 2466,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2467",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2467.png",
     "id": 2467,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2468",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2468.png",
     "id": 2468,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2469",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2469.png",
     "id": 2469,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2470",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2470.png",
     "id": 2470,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2471",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2471.png",
     "id": 2471,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2472",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2472.png",
     "id": 2472,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2473",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2473.png",
     "id": 2473,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2474",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2474.png",
     "id": 2474,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2475",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2475.png",
     "id": 2475,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2476",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2476.png",
     "id": 2476,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2477",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2477.png",
     "id": 2477,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2478",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2478.png",
     "id": 2478,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2479",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2479.png",
     "id": 2479,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2480",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2480.png",
     "id": 2480,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2481",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2481.png",
     "id": 2481,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2482",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2482.png",
     "id": 2482,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2483",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2483.png",
     "id": 2483,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2484",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2484.png",
     "id": 2484,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2485",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2485.png",
     "id": 2485,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2486",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2486.png",
     "id": 2486,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2487",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2487.png",
     "id": 2487,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2488",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2488.png",
     "id": 2488,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2489",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2489.png",
     "id": 2489,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2490",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2490.png",
     "id": 2490,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2491",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2491.png",
     "id": 2491,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2492",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2492.png",
     "id": 2492,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2493",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2493.png",
     "id": 2493,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2494",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2494.png",
     "id": 2494,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2495",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2495.png",
     "id": 2495,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2496",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2496.png",
     "id": 2496,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2497",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2497.png",
     "id": 2497,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2498",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2498.png",
     "id": 2498,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2499",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2499.png",
     "id": 2499,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2500",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2500.png",
     "id": 2500,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2501",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2501.png",
     "id": 2501,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2502",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2502.png",
     "id": 2502,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2503",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2503.png",
     "id": 2503,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2504",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2504.png",
     "id": 2504,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2505",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2505.png",
     "id": 2505,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2506",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2506.png",
     "id": 2506,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2507",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2507.png",
     "id": 2507,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2508",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2508.png",
     "id": 2508,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2509",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2509.png",
     "id": 2509,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2510",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2510.png",
     "id": 2510,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2511",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2511.png",
     "id": 2511,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2512",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2512.png",
     "id": 2512,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2513",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2513.png",
     "id": 2513,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2514",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2514.png",
     "id": 2514,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2515",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2515.png",
     "id": 2515,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2516",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2516.png",
     "id": 2516,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2517",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2517.png",
     "id": 2517,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2518",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2518.png",
     "id": 2518,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2519",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2519.png",
     "id": 2519,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2520",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2520.png",
     "id": 2520,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2521",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2521.png",
     "id": 2521,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2522",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2522.png",
     "id": 2522,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2523",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2523.png",
     "id": 2523,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2524",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2524.png",
     "id": 2524,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2525",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2525.png",
     "id": 2525,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2526",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2526.png",
     "id": 2526,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2527",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2527.png",
     "id": 2527,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2528",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2528.png",
     "id": 2528,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2529",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2529.png",
     "id": 2529,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2530",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2530.png",
     "id": 2530,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2531",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2531.png",
     "id": 2531,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2532",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2532.png",
     "id": 2532,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2533",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2533.png",
     "id": 2533,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2534",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2534.png",
     "id": 2534,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2535",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2535.png",
     "id": 2535,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2536",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2536.png",
     "id": 2536,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2537",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2537.png",
     "id": 2537,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2538",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2538.png",
     "id": 2538,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2539",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2539.png",
     "id": 2539,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2540",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2540.png",
     "id": 2540,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2541",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2541.png",
     "id": 2541,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2542",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2542.png",
     "id": 2542,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2543",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2543.png",
     "id": 2543,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2544",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2544.png",
     "id": 2544,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2545",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2545.png",
     "id": 2545,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2546",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2546.png",
     "id": 2546,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2547",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2547.png",
     "id": 2547,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2548",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2548.png",
     "id": 2548,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2549",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2549.png",
     "id": 2549,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2550",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2550.png",
     "id": 2550,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2551",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2551.png",
     "id": 2551,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2552",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2552.png",
     "id": 2552,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2553",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2553.png",
     "id": 2553,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2554",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2554.png",
     "id": 2554,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2555",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2555.png",
     "id": 2555,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2556",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2556.png",
     "id": 2556,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #2557",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2557.png",
     "id": 2557,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2558",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2558.png",
     "id": 2558,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2559",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2559.png",
     "id": 2559,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2560",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2560.png",
     "id": 2560,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2561",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2561.png",
     "id": 2561,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2562",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2562.png",
     "id": 2562,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2563",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2563.png",
     "id": 2563,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2564",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2564.png",
     "id": 2564,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2565",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2565.png",
     "id": 2565,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2566",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2566.png",
     "id": 2566,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2567",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2567.png",
     "id": 2567,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2568",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2568.png",
     "id": 2568,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2569",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2569.png",
     "id": 2569,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2570",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2570.png",
     "id": 2570,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2571",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2571.png",
     "id": 2571,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2572",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2572.png",
     "id": 2572,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2573",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2573.png",
     "id": 2573,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2574",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2574.png",
     "id": 2574,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2575",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2575.png",
     "id": 2575,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2576",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2576.png",
     "id": 2576,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2577",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2577.png",
     "id": 2577,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2578",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2578.png",
     "id": 2578,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2579",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2579.png",
     "id": 2579,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2580",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2580.png",
     "id": 2580,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2581",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2581.png",
     "id": 2581,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2582",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2582.png",
     "id": 2582,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2583",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2583.png",
     "id": 2583,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #2584",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2584.png",
     "id": 2584,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2585",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2585.png",
     "id": 2585,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2586",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2586.png",
     "id": 2586,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2587",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2587.png",
     "id": 2587,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2588",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2588.png",
     "id": 2588,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2589",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2589.png",
     "id": 2589,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2590",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2590.png",
     "id": 2590,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2591",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2591.png",
     "id": 2591,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2592",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2592.png",
     "id": 2592,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2593",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2593.png",
     "id": 2593,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2594",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2594.png",
     "id": 2594,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2595",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2595.png",
     "id": 2595,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2596",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2596.png",
     "id": 2596,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2597",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2597.png",
     "id": 2597,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2598",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2598.png",
     "id": 2598,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2599",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2599.png",
     "id": 2599,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2600",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2600.png",
     "id": 2600,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2601",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2601.png",
     "id": 2601,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2602",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2602.png",
     "id": 2602,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #2603",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2603.png",
     "id": 2603,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2604",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2604.png",
     "id": 2604,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2605",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2605.png",
     "id": 2605,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2606",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2606.png",
     "id": 2606,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2607",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2607.png",
     "id": 2607,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2608",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2608.png",
     "id": 2608,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2609",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2609.png",
     "id": 2609,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2610",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2610.png",
     "id": 2610,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2611",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2611.png",
     "id": 2611,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2612",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2612.png",
     "id": 2612,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2613",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2613.png",
     "id": 2613,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2614",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2614.png",
     "id": 2614,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2615",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2615.png",
     "id": 2615,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2616",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2616.png",
     "id": 2616,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2617",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2617.png",
     "id": 2617,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2618",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2618.png",
     "id": 2618,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2619",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2619.png",
     "id": 2619,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2620",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2620.png",
     "id": 2620,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2621",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2621.png",
     "id": 2621,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2622",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2622.png",
     "id": 2622,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2623",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2623.png",
     "id": 2623,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2624",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2624.png",
     "id": 2624,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2625",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2625.png",
     "id": 2625,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2626",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2626.png",
     "id": 2626,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2627",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2627.png",
     "id": 2627,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2628",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2628.png",
     "id": 2628,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2629",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2629.png",
     "id": 2629,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2630",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2630.png",
     "id": 2630,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2631",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2631.png",
     "id": 2631,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2632",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2632.png",
     "id": 2632,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2633",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2633.png",
     "id": 2633,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2634",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2634.png",
     "id": 2634,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2635",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2635.png",
     "id": 2635,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2636",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2636.png",
     "id": 2636,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2637",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2637.png",
     "id": 2637,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2638",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2638.png",
     "id": 2638,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2639",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2639.png",
     "id": 2639,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2640",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2640.png",
     "id": 2640,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2641",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2641.png",
     "id": 2641,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2642",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2642.png",
     "id": 2642,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2643",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2643.png",
     "id": 2643,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2644",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2644.png",
     "id": 2644,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2645",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2645.png",
     "id": 2645,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2646",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2646.png",
     "id": 2646,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2647",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2647.png",
     "id": 2647,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2648",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2648.png",
     "id": 2648,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2649",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2649.png",
     "id": 2649,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2650",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2650.png",
     "id": 2650,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2651",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2651.png",
     "id": 2651,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2652",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2652.png",
     "id": 2652,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2653",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2653.png",
     "id": 2653,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2654",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2654.png",
     "id": 2654,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2655",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2655.png",
     "id": 2655,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2656",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2656.png",
     "id": 2656,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2657",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2657.png",
     "id": 2657,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2658",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2658.png",
     "id": 2658,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2659",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2659.png",
     "id": 2659,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2660",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2660.png",
     "id": 2660,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2661",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2661.png",
     "id": 2661,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2662",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2662.png",
     "id": 2662,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2663",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2663.png",
     "id": 2663,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2664",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2664.png",
     "id": 2664,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2665",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2665.png",
     "id": 2665,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2666",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2666.png",
     "id": 2666,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2667",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2667.png",
     "id": 2667,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2668",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2668.png",
     "id": 2668,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2669",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2669.png",
     "id": 2669,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2670",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2670.png",
     "id": 2670,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2671",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2671.png",
     "id": 2671,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2672",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2672.png",
     "id": 2672,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2673",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2673.png",
     "id": 2673,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2674",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2674.png",
     "id": 2674,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2675",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2675.png",
     "id": 2675,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2676",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2676.png",
     "id": 2676,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2677",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2677.png",
     "id": 2677,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2678",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2678.png",
     "id": 2678,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2679",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2679.png",
     "id": 2679,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2680",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2680.png",
     "id": 2680,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2681",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2681.png",
     "id": 2681,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2682",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2682.png",
     "id": 2682,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2683",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2683.png",
     "id": 2683,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2684",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2684.png",
     "id": 2684,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2685",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2685.png",
     "id": 2685,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2686",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2686.png",
     "id": 2686,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2687",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2687.png",
     "id": 2687,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2688",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2688.png",
     "id": 2688,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2689",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2689.png",
     "id": 2689,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2690",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2690.png",
     "id": 2690,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2691",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2691.png",
     "id": 2691,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2692",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2692.png",
     "id": 2692,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2693",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2693.png",
     "id": 2693,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2694",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2694.png",
     "id": 2694,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2695",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2695.png",
     "id": 2695,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2696",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2696.png",
     "id": 2696,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #2697",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2697.png",
     "id": 2697,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2698",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2698.png",
     "id": 2698,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2699",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2699.png",
     "id": 2699,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2700",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2700.png",
     "id": 2700,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2701",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2701.png",
     "id": 2701,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2702",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2702.png",
     "id": 2702,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2703",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2703.png",
     "id": 2703,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2704",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2704.png",
     "id": 2704,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2705",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2705.png",
     "id": 2705,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2706",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2706.png",
     "id": 2706,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2707",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2707.png",
     "id": 2707,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2708",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2708.png",
     "id": 2708,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2709",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2709.png",
     "id": 2709,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2710",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2710.png",
     "id": 2710,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2711",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2711.png",
     "id": 2711,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2712",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2712.png",
     "id": 2712,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2713",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2713.png",
     "id": 2713,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2714",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2714.png",
     "id": 2714,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2715",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2715.png",
     "id": 2715,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2716",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2716.png",
     "id": 2716,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2717",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2717.png",
     "id": 2717,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2718",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2718.png",
     "id": 2718,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2719",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2719.png",
     "id": 2719,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2720",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2720.png",
     "id": 2720,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2721",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2721.png",
     "id": 2721,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2722",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2722.png",
     "id": 2722,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2723",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2723.png",
     "id": 2723,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2724",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2724.png",
     "id": 2724,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2725",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2725.png",
     "id": 2725,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2726",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2726.png",
     "id": 2726,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2727",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2727.png",
     "id": 2727,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2728",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2728.png",
     "id": 2728,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2729",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2729.png",
     "id": 2729,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2730",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2730.png",
     "id": 2730,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2731",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2731.png",
     "id": 2731,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2732",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2732.png",
     "id": 2732,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2733",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2733.png",
     "id": 2733,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2734",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2734.png",
     "id": 2734,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2735",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2735.png",
     "id": 2735,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2736",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2736.png",
     "id": 2736,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2737",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2737.png",
     "id": 2737,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2738",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2738.png",
     "id": 2738,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2739",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2739.png",
     "id": 2739,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2740",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2740.png",
     "id": 2740,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2741",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2741.png",
     "id": 2741,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2742",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2742.png",
     "id": 2742,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2743",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2743.png",
     "id": 2743,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2744",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2744.png",
     "id": 2744,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2745",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2745.png",
     "id": 2745,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2746",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2746.png",
     "id": 2746,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2747",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2747.png",
     "id": 2747,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2748",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2748.png",
     "id": 2748,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2749",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2749.png",
     "id": 2749,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2750",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2750.png",
     "id": 2750,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2751",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2751.png",
     "id": 2751,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2752",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2752.png",
     "id": 2752,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2753",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2753.png",
     "id": 2753,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2754",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2754.png",
     "id": 2754,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2755",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2755.png",
     "id": 2755,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2756",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2756.png",
     "id": 2756,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2757",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2757.png",
     "id": 2757,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2758",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2758.png",
     "id": 2758,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2759",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2759.png",
     "id": 2759,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2760",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2760.png",
     "id": 2760,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2761",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2761.png",
     "id": 2761,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2762",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2762.png",
     "id": 2762,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2763",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2763.png",
     "id": 2763,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2764",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2764.png",
     "id": 2764,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2765",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2765.png",
     "id": 2765,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2766",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2766.png",
     "id": 2766,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2767",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2767.png",
     "id": 2767,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2768",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2768.png",
     "id": 2768,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2769",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2769.png",
     "id": 2769,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2770",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2770.png",
     "id": 2770,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2771",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2771.png",
     "id": 2771,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2772",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2772.png",
     "id": 2772,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2773",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2773.png",
     "id": 2773,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2774",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2774.png",
     "id": 2774,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2775",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2775.png",
     "id": 2775,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2776",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2776.png",
     "id": 2776,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2777",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2777.png",
     "id": 2777,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2778",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2778.png",
     "id": 2778,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2779",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2779.png",
     "id": 2779,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2780",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2780.png",
     "id": 2780,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2781",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2781.png",
     "id": 2781,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2782",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2782.png",
     "id": 2782,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2783",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2783.png",
     "id": 2783,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2784",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2784.png",
     "id": 2784,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2785",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2785.png",
     "id": 2785,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2786",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2786.png",
     "id": 2786,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2787",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2787.png",
     "id": 2787,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2788",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2788.png",
     "id": 2788,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2789",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2789.png",
     "id": 2789,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2790",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2790.png",
     "id": 2790,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2791",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2791.png",
     "id": 2791,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2792",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2792.png",
     "id": 2792,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2793",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2793.png",
     "id": 2793,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2794",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2794.png",
     "id": 2794,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2795",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2795.png",
     "id": 2795,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2796",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2796.png",
     "id": 2796,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2797",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2797.png",
     "id": 2797,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2798",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2798.png",
     "id": 2798,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2799",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2799.png",
     "id": 2799,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2800",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2800.png",
     "id": 2800,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2801",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2801.png",
     "id": 2801,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2802",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2802.png",
     "id": 2802,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2803",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2803.png",
     "id": 2803,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2804",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2804.png",
     "id": 2804,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2805",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2805.png",
     "id": 2805,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2806",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2806.png",
     "id": 2806,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2807",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2807.png",
     "id": 2807,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2808",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2808.png",
     "id": 2808,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2809",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2809.png",
     "id": 2809,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2810",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2810.png",
     "id": 2810,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2811",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2811.png",
     "id": 2811,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2812",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2812.png",
     "id": 2812,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2813",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2813.png",
     "id": 2813,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2814",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2814.png",
     "id": 2814,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2815",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2815.png",
     "id": 2815,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2816",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2816.png",
     "id": 2816,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2817",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2817.png",
     "id": 2817,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2818",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2818.png",
     "id": 2818,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2819",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2819.png",
     "id": 2819,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2820",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2820.png",
     "id": 2820,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2821",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2821.png",
     "id": 2821,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2822",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2822.png",
     "id": 2822,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2823",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2823.png",
     "id": 2823,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2824",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2824.png",
     "id": 2824,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2825",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2825.png",
     "id": 2825,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2826",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2826.png",
     "id": 2826,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2827",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2827.png",
     "id": 2827,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2828",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2828.png",
     "id": 2828,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2829",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2829.png",
     "id": 2829,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2830",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2830.png",
     "id": 2830,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2831",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2831.png",
     "id": 2831,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2832",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2832.png",
     "id": 2832,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2833",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2833.png",
     "id": 2833,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2834",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2834.png",
     "id": 2834,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2835",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2835.png",
     "id": 2835,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2836",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2836.png",
     "id": 2836,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2837",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2837.png",
     "id": 2837,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2838",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2838.png",
     "id": 2838,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2839",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2839.png",
     "id": 2839,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2840",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2840.png",
     "id": 2840,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2841",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2841.png",
     "id": 2841,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2842",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2842.png",
     "id": 2842,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2843",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2843.png",
     "id": 2843,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2844",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2844.png",
     "id": 2844,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2845",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2845.png",
     "id": 2845,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2846",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2846.png",
     "id": 2846,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2847",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2847.png",
     "id": 2847,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2848",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2848.png",
     "id": 2848,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2849",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2849.png",
     "id": 2849,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2850",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2850.png",
     "id": 2850,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2851",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2851.png",
     "id": 2851,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2852",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2852.png",
     "id": 2852,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2853",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2853.png",
     "id": 2853,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2854",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2854.png",
     "id": 2854,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2855",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2855.png",
     "id": 2855,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2856",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2856.png",
     "id": 2856,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2857",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2857.png",
     "id": 2857,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2858",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2858.png",
     "id": 2858,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2859",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2859.png",
     "id": 2859,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2860",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2860.png",
     "id": 2860,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2861",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2861.png",
     "id": 2861,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2862",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2862.png",
     "id": 2862,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2863",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2863.png",
     "id": 2863,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2864",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2864.png",
     "id": 2864,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2865",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2865.png",
     "id": 2865,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2866",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2866.png",
     "id": 2866,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2867",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2867.png",
     "id": 2867,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2868",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2868.png",
     "id": 2868,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2869",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2869.png",
     "id": 2869,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2870",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2870.png",
     "id": 2870,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2871",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2871.png",
     "id": 2871,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2872",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2872.png",
     "id": 2872,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2873",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2873.png",
     "id": 2873,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2874",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2874.png",
     "id": 2874,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2875",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2875.png",
     "id": 2875,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2876",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2876.png",
     "id": 2876,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2877",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2877.png",
     "id": 2877,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2878",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2878.png",
     "id": 2878,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2879",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2879.png",
     "id": 2879,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2880",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2880.png",
     "id": 2880,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2881",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2881.png",
     "id": 2881,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2882",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2882.png",
     "id": 2882,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2883",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2883.png",
     "id": 2883,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2884",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2884.png",
     "id": 2884,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2885",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2885.png",
     "id": 2885,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2886",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2886.png",
     "id": 2886,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2887",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2887.png",
     "id": 2887,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2888",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2888.png",
     "id": 2888,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2889",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2889.png",
     "id": 2889,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2890",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2890.png",
     "id": 2890,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2891",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2891.png",
     "id": 2891,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2892",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2892.png",
     "id": 2892,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2893",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2893.png",
     "id": 2893,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2894",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2894.png",
     "id": 2894,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2895",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2895.png",
     "id": 2895,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2896",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2896.png",
     "id": 2896,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2897",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2897.png",
     "id": 2897,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2898",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2898.png",
     "id": 2898,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2899",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2899.png",
     "id": 2899,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2900",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2900.png",
     "id": 2900,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2901",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2901.png",
     "id": 2901,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2902",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2902.png",
     "id": 2902,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2903",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2903.png",
     "id": 2903,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2904",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2904.png",
     "id": 2904,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2905",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2905.png",
     "id": 2905,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2906",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2906.png",
     "id": 2906,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2907",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2907.png",
     "id": 2907,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2908",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2908.png",
     "id": 2908,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2909",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2909.png",
     "id": 2909,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2910",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2910.png",
     "id": 2910,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2911",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2911.png",
     "id": 2911,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2912",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2912.png",
     "id": 2912,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2913",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2913.png",
     "id": 2913,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2914",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2914.png",
     "id": 2914,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2915",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2915.png",
     "id": 2915,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2916",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2916.png",
     "id": 2916,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2917",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2917.png",
     "id": 2917,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2918",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2918.png",
     "id": 2918,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2919",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2919.png",
     "id": 2919,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2920",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2920.png",
     "id": 2920,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2921",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2921.png",
     "id": 2921,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2922",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2922.png",
     "id": 2922,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2923",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2923.png",
     "id": 2923,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2924",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2924.png",
     "id": 2924,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2925",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2925.png",
     "id": 2925,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2926",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2926.png",
     "id": 2926,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2927",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2927.png",
     "id": 2927,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2928",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2928.png",
     "id": 2928,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2929",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2929.png",
     "id": 2929,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #2930",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2930.png",
     "id": 2930,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2931",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2931.png",
     "id": 2931,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2932",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2932.png",
     "id": 2932,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2933",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2933.png",
     "id": 2933,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2934",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2934.png",
     "id": 2934,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2935",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2935.png",
     "id": 2935,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "OG Boomer"
      },
      {
       "trait_type": "Skin",
       "value": "OG Boomer"
      },
      {
       "trait_type": "Crypto",
       "value": "OG Boomer"
      },
      {
       "trait_type": "Activity",
       "value": "OG Boomer"
      },
      {
       "trait_type": "Outfit",
       "value": "OG Boomer"
      },
      {
       "trait_type": "Transportation",
       "value": "OG Boomer"
      },
      {
       "trait_type": "Head",
       "value": "OG Boomer"
      },
      {
       "trait_type": "Mouth",
       "value": "OG Boomer"
      },
      {
       "trait_type": "Eyes",
       "value": "OG Boomer"
      }
     ]
    },
    {
     "name": "Boomer Squad #2936",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2936.png",
     "id": 2936,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2937",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2937.png",
     "id": 2937,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2938",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2938.png",
     "id": 2938,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2939",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2939.png",
     "id": 2939,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2940",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2940.png",
     "id": 2940,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2941",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2941.png",
     "id": 2941,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2942",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2942.png",
     "id": 2942,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2943",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2943.png",
     "id": 2943,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2944",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2944.png",
     "id": 2944,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2945",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2945.png",
     "id": 2945,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2946",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2946.png",
     "id": 2946,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2947",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2947.png",
     "id": 2947,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #2948",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2948.png",
     "id": 2948,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2949",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2949.png",
     "id": 2949,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2950",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2950.png",
     "id": 2950,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #2951",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2951.png",
     "id": 2951,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2952",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2952.png",
     "id": 2952,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2953",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2953.png",
     "id": 2953,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2954",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2954.png",
     "id": 2954,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2955",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2955.png",
     "id": 2955,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2956",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2956.png",
     "id": 2956,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2957",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2957.png",
     "id": 2957,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2958",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2958.png",
     "id": 2958,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #2959",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2959.png",
     "id": 2959,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2960",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2960.png",
     "id": 2960,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2961",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2961.png",
     "id": 2961,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #2962",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2962.png",
     "id": 2962,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2963",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2963.png",
     "id": 2963,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2964",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2964.png",
     "id": 2964,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #2965",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2965.png",
     "id": 2965,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #2966",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2966.png",
     "id": 2966,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #2967",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2967.png",
     "id": 2967,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2968",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2968.png",
     "id": 2968,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2969",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2969.png",
     "id": 2969,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #2970",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2970.png",
     "id": 2970,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2971",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2971.png",
     "id": 2971,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2972",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2972.png",
     "id": 2972,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2973",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2973.png",
     "id": 2973,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2974",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2974.png",
     "id": 2974,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2975",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2975.png",
     "id": 2975,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2976",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2976.png",
     "id": 2976,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2977",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2977.png",
     "id": 2977,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2978",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2978.png",
     "id": 2978,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #2979",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2979.png",
     "id": 2979,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2980",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2980.png",
     "id": 2980,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2981",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2981.png",
     "id": 2981,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #2982",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2982.png",
     "id": 2982,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #2983",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2983.png",
     "id": 2983,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #2984",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2984.png",
     "id": 2984,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #2985",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2985.png",
     "id": 2985,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #2986",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2986.png",
     "id": 2986,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #2987",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2987.png",
     "id": 2987,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #2988",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2988.png",
     "id": 2988,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #2989",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2989.png",
     "id": 2989,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2990",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2990.png",
     "id": 2990,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2991",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2991.png",
     "id": 2991,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2992",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2992.png",
     "id": 2992,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2993",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2993.png",
     "id": 2993,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #2994",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2994.png",
     "id": 2994,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #2995",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2995.png",
     "id": 2995,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #2996",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2996.png",
     "id": 2996,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2997",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2997.png",
     "id": 2997,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #2998",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2998.png",
     "id": 2998,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #2999",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/2999.png",
     "id": 2999,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3000",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3000.png",
     "id": 3000,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3001",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3001.png",
     "id": 3001,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3002",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3002.png",
     "id": 3002,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3003",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3003.png",
     "id": 3003,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3004",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3004.png",
     "id": 3004,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3005",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3005.png",
     "id": 3005,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3006",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3006.png",
     "id": 3006,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3007",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3007.png",
     "id": 3007,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3008",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3008.png",
     "id": 3008,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3009",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3009.png",
     "id": 3009,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3010",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3010.png",
     "id": 3010,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3011",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3011.png",
     "id": 3011,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3012",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3012.png",
     "id": 3012,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3013",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3013.png",
     "id": 3013,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3014",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3014.png",
     "id": 3014,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3015",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3015.png",
     "id": 3015,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3016",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3016.png",
     "id": 3016,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3017",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3017.png",
     "id": 3017,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3018",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3018.png",
     "id": 3018,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3019",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3019.png",
     "id": 3019,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3020",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3020.png",
     "id": 3020,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3021",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3021.png",
     "id": 3021,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3022",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3022.png",
     "id": 3022,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3023",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3023.png",
     "id": 3023,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3024",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3024.png",
     "id": 3024,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3025",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3025.png",
     "id": 3025,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3026",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3026.png",
     "id": 3026,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3027",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3027.png",
     "id": 3027,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3028",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3028.png",
     "id": 3028,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3029",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3029.png",
     "id": 3029,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3030",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3030.png",
     "id": 3030,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3031",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3031.png",
     "id": 3031,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3032",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3032.png",
     "id": 3032,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3033",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3033.png",
     "id": 3033,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3034",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3034.png",
     "id": 3034,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3035",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3035.png",
     "id": 3035,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3036",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3036.png",
     "id": 3036,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3037",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3037.png",
     "id": 3037,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3038",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3038.png",
     "id": 3038,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3039",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3039.png",
     "id": 3039,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3040",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3040.png",
     "id": 3040,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3041",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3041.png",
     "id": 3041,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3042",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3042.png",
     "id": 3042,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3043",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3043.png",
     "id": 3043,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3044",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3044.png",
     "id": 3044,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3045",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3045.png",
     "id": 3045,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3046",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3046.png",
     "id": 3046,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3047",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3047.png",
     "id": 3047,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3048",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3048.png",
     "id": 3048,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3049",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3049.png",
     "id": 3049,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3050",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3050.png",
     "id": 3050,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3051",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3051.png",
     "id": 3051,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3052",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3052.png",
     "id": 3052,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3053",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3053.png",
     "id": 3053,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3054",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3054.png",
     "id": 3054,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3055",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3055.png",
     "id": 3055,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #3056",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3056.png",
     "id": 3056,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3057",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3057.png",
     "id": 3057,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3058",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3058.png",
     "id": 3058,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3059",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3059.png",
     "id": 3059,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3060",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3060.png",
     "id": 3060,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3061",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3061.png",
     "id": 3061,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3062",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3062.png",
     "id": 3062,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3063",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3063.png",
     "id": 3063,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3064",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3064.png",
     "id": 3064,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3065",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3065.png",
     "id": 3065,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3066",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3066.png",
     "id": 3066,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3067",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3067.png",
     "id": 3067,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3068",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3068.png",
     "id": 3068,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3069",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3069.png",
     "id": 3069,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3070",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3070.png",
     "id": 3070,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3071",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3071.png",
     "id": 3071,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3072",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3072.png",
     "id": 3072,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3073",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3073.png",
     "id": 3073,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3074",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3074.png",
     "id": 3074,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #3075",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3075.png",
     "id": 3075,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3076",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3076.png",
     "id": 3076,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3077",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3077.png",
     "id": 3077,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3078",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3078.png",
     "id": 3078,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3079",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3079.png",
     "id": 3079,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3080",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3080.png",
     "id": 3080,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3081",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3081.png",
     "id": 3081,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3082",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3082.png",
     "id": 3082,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3083",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3083.png",
     "id": 3083,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3084",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3084.png",
     "id": 3084,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3085",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3085.png",
     "id": 3085,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3086",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3086.png",
     "id": 3086,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3087",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3087.png",
     "id": 3087,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3088",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3088.png",
     "id": 3088,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #3089",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3089.png",
     "id": 3089,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3090",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3090.png",
     "id": 3090,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3091",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3091.png",
     "id": 3091,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3092",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3092.png",
     "id": 3092,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3093",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3093.png",
     "id": 3093,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3094",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3094.png",
     "id": 3094,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3095",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3095.png",
     "id": 3095,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3096",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3096.png",
     "id": 3096,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3097",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3097.png",
     "id": 3097,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3098",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3098.png",
     "id": 3098,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3099",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3099.png",
     "id": 3099,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3100",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3100.png",
     "id": 3100,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3101",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3101.png",
     "id": 3101,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3102",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3102.png",
     "id": 3102,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3103",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3103.png",
     "id": 3103,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3104",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3104.png",
     "id": 3104,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3105",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3105.png",
     "id": 3105,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3106",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3106.png",
     "id": 3106,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3107",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3107.png",
     "id": 3107,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3108",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3108.png",
     "id": 3108,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3109",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3109.png",
     "id": 3109,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3110",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3110.png",
     "id": 3110,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3111",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3111.png",
     "id": 3111,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3112",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3112.png",
     "id": 3112,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3113",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3113.png",
     "id": 3113,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3114",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3114.png",
     "id": 3114,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3115",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3115.png",
     "id": 3115,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3116",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3116.png",
     "id": 3116,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3117",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3117.png",
     "id": 3117,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3118",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3118.png",
     "id": 3118,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3119",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3119.png",
     "id": 3119,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3120",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3120.png",
     "id": 3120,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3121",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3121.png",
     "id": 3121,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3122",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3122.png",
     "id": 3122,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3123",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3123.png",
     "id": 3123,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3124",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3124.png",
     "id": 3124,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3125",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3125.png",
     "id": 3125,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3126",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3126.png",
     "id": 3126,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3127",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3127.png",
     "id": 3127,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3128",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3128.png",
     "id": 3128,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3129",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3129.png",
     "id": 3129,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3130",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3130.png",
     "id": 3130,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3131",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3131.png",
     "id": 3131,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3132",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3132.png",
     "id": 3132,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3133",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3133.png",
     "id": 3133,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3134",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3134.png",
     "id": 3134,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3135",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3135.png",
     "id": 3135,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3136",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3136.png",
     "id": 3136,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3137",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3137.png",
     "id": 3137,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3138",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3138.png",
     "id": 3138,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3139",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3139.png",
     "id": 3139,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3140",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3140.png",
     "id": 3140,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3141",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3141.png",
     "id": 3141,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3142",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3142.png",
     "id": 3142,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3143",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3143.png",
     "id": 3143,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3144",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3144.png",
     "id": 3144,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3145",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3145.png",
     "id": 3145,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3146",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3146.png",
     "id": 3146,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3147",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3147.png",
     "id": 3147,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3148",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3148.png",
     "id": 3148,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3149",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3149.png",
     "id": 3149,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3150",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3150.png",
     "id": 3150,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3151",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3151.png",
     "id": 3151,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3152",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3152.png",
     "id": 3152,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3153",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3153.png",
     "id": 3153,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3154",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3154.png",
     "id": 3154,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3155",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3155.png",
     "id": 3155,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3156",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3156.png",
     "id": 3156,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3157",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3157.png",
     "id": 3157,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3158",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3158.png",
     "id": 3158,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3159",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3159.png",
     "id": 3159,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3160",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3160.png",
     "id": 3160,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3161",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3161.png",
     "id": 3161,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3162",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3162.png",
     "id": 3162,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3163",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3163.png",
     "id": 3163,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3164",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3164.png",
     "id": 3164,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Senior Beverly"
      },
      {
       "trait_type": "Skin",
       "value": "Senior Beverly"
      },
      {
       "trait_type": "Crypto",
       "value": "Senior Beverly"
      },
      {
       "trait_type": "Activity",
       "value": "Senior Beverly"
      },
      {
       "trait_type": "Outfit",
       "value": "Senior Beverly"
      },
      {
       "trait_type": "Transportation",
       "value": "Senior Beverly"
      },
      {
       "trait_type": "Head",
       "value": "Senior Beverly"
      },
      {
       "trait_type": "Mouth",
       "value": "Senior Beverly"
      },
      {
       "trait_type": "Eyes",
       "value": "Senior Beverly"
      }
     ]
    },
    {
     "name": "Boomer Squad #3165",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3165.png",
     "id": 3165,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3166",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3166.png",
     "id": 3166,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3167",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3167.png",
     "id": 3167,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3168",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3168.png",
     "id": 3168,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3169",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3169.png",
     "id": 3169,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3170",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3170.png",
     "id": 3170,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3171",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3171.png",
     "id": 3171,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3172",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3172.png",
     "id": 3172,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3173",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3173.png",
     "id": 3173,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3174",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3174.png",
     "id": 3174,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3175",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3175.png",
     "id": 3175,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3176",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3176.png",
     "id": 3176,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3177",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3177.png",
     "id": 3177,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3178",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3178.png",
     "id": 3178,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3179",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3179.png",
     "id": 3179,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3180",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3180.png",
     "id": 3180,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3181",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3181.png",
     "id": 3181,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3182",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3182.png",
     "id": 3182,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3183",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3183.png",
     "id": 3183,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3184",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3184.png",
     "id": 3184,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3185",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3185.png",
     "id": 3185,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3186",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3186.png",
     "id": 3186,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3187",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3187.png",
     "id": 3187,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3188",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3188.png",
     "id": 3188,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3189",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3189.png",
     "id": 3189,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3190",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3190.png",
     "id": 3190,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3191",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3191.png",
     "id": 3191,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3192",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3192.png",
     "id": 3192,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3193",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3193.png",
     "id": 3193,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3194",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3194.png",
     "id": 3194,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3195",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3195.png",
     "id": 3195,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3196",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3196.png",
     "id": 3196,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3197",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3197.png",
     "id": 3197,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3198",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3198.png",
     "id": 3198,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3199",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3199.png",
     "id": 3199,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3200",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3200.png",
     "id": 3200,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3201",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3201.png",
     "id": 3201,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3202",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3202.png",
     "id": 3202,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3203",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3203.png",
     "id": 3203,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3204",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3204.png",
     "id": 3204,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3205",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3205.png",
     "id": 3205,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3206",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3206.png",
     "id": 3206,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3207",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3207.png",
     "id": 3207,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3208",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3208.png",
     "id": 3208,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3209",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3209.png",
     "id": 3209,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3210",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3210.png",
     "id": 3210,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3211",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3211.png",
     "id": 3211,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3212",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3212.png",
     "id": 3212,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3213",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3213.png",
     "id": 3213,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3214",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3214.png",
     "id": 3214,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3215",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3215.png",
     "id": 3215,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3216",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3216.png",
     "id": 3216,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3217",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3217.png",
     "id": 3217,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3218",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3218.png",
     "id": 3218,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3219",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3219.png",
     "id": 3219,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3220",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3220.png",
     "id": 3220,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3221",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3221.png",
     "id": 3221,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3222",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3222.png",
     "id": 3222,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3223",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3223.png",
     "id": 3223,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3224",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3224.png",
     "id": 3224,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3225",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3225.png",
     "id": 3225,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3226",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3226.png",
     "id": 3226,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3227",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3227.png",
     "id": 3227,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3228",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3228.png",
     "id": 3228,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3229",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3229.png",
     "id": 3229,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3230",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3230.png",
     "id": 3230,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3231",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3231.png",
     "id": 3231,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3232",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3232.png",
     "id": 3232,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3233",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3233.png",
     "id": 3233,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3234",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3234.png",
     "id": 3234,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3235",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3235.png",
     "id": 3235,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3236",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3236.png",
     "id": 3236,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3237",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3237.png",
     "id": 3237,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3238",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3238.png",
     "id": 3238,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3239",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3239.png",
     "id": 3239,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3240",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3240.png",
     "id": 3240,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3241",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3241.png",
     "id": 3241,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3242",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3242.png",
     "id": 3242,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3243",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3243.png",
     "id": 3243,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3244",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3244.png",
     "id": 3244,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3245",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3245.png",
     "id": 3245,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3246",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3246.png",
     "id": 3246,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3247",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3247.png",
     "id": 3247,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3248",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3248.png",
     "id": 3248,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3249",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3249.png",
     "id": 3249,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3250",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3250.png",
     "id": 3250,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3251",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3251.png",
     "id": 3251,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3252",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3252.png",
     "id": 3252,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3253",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3253.png",
     "id": 3253,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3254",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3254.png",
     "id": 3254,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3255",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3255.png",
     "id": 3255,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3256",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3256.png",
     "id": 3256,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3257",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3257.png",
     "id": 3257,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3258",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3258.png",
     "id": 3258,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3259",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3259.png",
     "id": 3259,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3260",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3260.png",
     "id": 3260,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3261",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3261.png",
     "id": 3261,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3262",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3262.png",
     "id": 3262,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3263",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3263.png",
     "id": 3263,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3264",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3264.png",
     "id": 3264,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3265",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3265.png",
     "id": 3265,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3266",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3266.png",
     "id": 3266,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3267",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3267.png",
     "id": 3267,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3268",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3268.png",
     "id": 3268,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3269",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3269.png",
     "id": 3269,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3270",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3270.png",
     "id": 3270,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3271",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3271.png",
     "id": 3271,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3272",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3272.png",
     "id": 3272,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3273",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3273.png",
     "id": 3273,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3274",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3274.png",
     "id": 3274,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3275",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3275.png",
     "id": 3275,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3276",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3276.png",
     "id": 3276,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3277",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3277.png",
     "id": 3277,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3278",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3278.png",
     "id": 3278,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3279",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3279.png",
     "id": 3279,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3280",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3280.png",
     "id": 3280,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3281",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3281.png",
     "id": 3281,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3282",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3282.png",
     "id": 3282,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3283",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3283.png",
     "id": 3283,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3284",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3284.png",
     "id": 3284,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3285",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3285.png",
     "id": 3285,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3286",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3286.png",
     "id": 3286,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3287",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3287.png",
     "id": 3287,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3288",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3288.png",
     "id": 3288,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3289",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3289.png",
     "id": 3289,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3290",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3290.png",
     "id": 3290,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3291",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3291.png",
     "id": 3291,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3292",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3292.png",
     "id": 3292,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3293",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3293.png",
     "id": 3293,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3294",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3294.png",
     "id": 3294,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3295",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3295.png",
     "id": 3295,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3296",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3296.png",
     "id": 3296,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3297",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3297.png",
     "id": 3297,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3298",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3298.png",
     "id": 3298,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3299",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3299.png",
     "id": 3299,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3300",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3300.png",
     "id": 3300,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3301",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3301.png",
     "id": 3301,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3302",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3302.png",
     "id": 3302,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3303",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3303.png",
     "id": 3303,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3304",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3304.png",
     "id": 3304,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3305",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3305.png",
     "id": 3305,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3306",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3306.png",
     "id": 3306,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3307",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3307.png",
     "id": 3307,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3308",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3308.png",
     "id": 3308,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3309",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3309.png",
     "id": 3309,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3310",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3310.png",
     "id": 3310,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3311",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3311.png",
     "id": 3311,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3312",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3312.png",
     "id": 3312,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3313",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3313.png",
     "id": 3313,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3314",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3314.png",
     "id": 3314,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3315",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3315.png",
     "id": 3315,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3316",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3316.png",
     "id": 3316,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3317",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3317.png",
     "id": 3317,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3318",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3318.png",
     "id": 3318,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3319",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3319.png",
     "id": 3319,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3320",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3320.png",
     "id": 3320,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3321",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3321.png",
     "id": 3321,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3322",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3322.png",
     "id": 3322,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3323",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3323.png",
     "id": 3323,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3324",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3324.png",
     "id": 3324,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3325",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3325.png",
     "id": 3325,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3326",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3326.png",
     "id": 3326,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3327",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3327.png",
     "id": 3327,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3328",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3328.png",
     "id": 3328,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3329",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3329.png",
     "id": 3329,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3330",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3330.png",
     "id": 3330,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3331",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3331.png",
     "id": 3331,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3332",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3332.png",
     "id": 3332,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3333",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3333.png",
     "id": 3333,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3334",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3334.png",
     "id": 3334,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3335",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3335.png",
     "id": 3335,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3336",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3336.png",
     "id": 3336,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3337",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3337.png",
     "id": 3337,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3338",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3338.png",
     "id": 3338,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3339",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3339.png",
     "id": 3339,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3340",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3340.png",
     "id": 3340,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3341",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3341.png",
     "id": 3341,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #3342",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3342.png",
     "id": 3342,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3343",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3343.png",
     "id": 3343,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3344",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3344.png",
     "id": 3344,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3345",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3345.png",
     "id": 3345,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3346",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3346.png",
     "id": 3346,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3347",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3347.png",
     "id": 3347,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3348",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3348.png",
     "id": 3348,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3349",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3349.png",
     "id": 3349,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3350",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3350.png",
     "id": 3350,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3351",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3351.png",
     "id": 3351,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3352",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3352.png",
     "id": 3352,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3353",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3353.png",
     "id": 3353,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3354",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3354.png",
     "id": 3354,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3355",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3355.png",
     "id": 3355,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3356",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3356.png",
     "id": 3356,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3357",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3357.png",
     "id": 3357,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3358",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3358.png",
     "id": 3358,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3359",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3359.png",
     "id": 3359,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3360",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3360.png",
     "id": 3360,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3361",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3361.png",
     "id": 3361,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3362",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3362.png",
     "id": 3362,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3363",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3363.png",
     "id": 3363,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3364",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3364.png",
     "id": 3364,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3365",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3365.png",
     "id": 3365,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3366",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3366.png",
     "id": 3366,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3367",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3367.png",
     "id": 3367,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3368",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3368.png",
     "id": 3368,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3369",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3369.png",
     "id": 3369,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3370",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3370.png",
     "id": 3370,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3371",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3371.png",
     "id": 3371,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3372",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3372.png",
     "id": 3372,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3373",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3373.png",
     "id": 3373,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3374",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3374.png",
     "id": 3374,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3375",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3375.png",
     "id": 3375,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3376",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3376.png",
     "id": 3376,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3377",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3377.png",
     "id": 3377,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3378",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3378.png",
     "id": 3378,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #3379",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3379.png",
     "id": 3379,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3380",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3380.png",
     "id": 3380,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3381",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3381.png",
     "id": 3381,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3382",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3382.png",
     "id": 3382,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3383",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3383.png",
     "id": 3383,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3384",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3384.png",
     "id": 3384,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3385",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3385.png",
     "id": 3385,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3386",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3386.png",
     "id": 3386,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3387",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3387.png",
     "id": 3387,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3388",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3388.png",
     "id": 3388,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3389",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3389.png",
     "id": 3389,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3390",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3390.png",
     "id": 3390,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3391",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3391.png",
     "id": 3391,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3392",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3392.png",
     "id": 3392,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3393",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3393.png",
     "id": 3393,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3394",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3394.png",
     "id": 3394,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3395",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3395.png",
     "id": 3395,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3396",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3396.png",
     "id": 3396,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3397",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3397.png",
     "id": 3397,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3398",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3398.png",
     "id": 3398,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3399",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3399.png",
     "id": 3399,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3400",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3400.png",
     "id": 3400,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3401",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3401.png",
     "id": 3401,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3402",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3402.png",
     "id": 3402,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3403",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3403.png",
     "id": 3403,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3404",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3404.png",
     "id": 3404,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3405",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3405.png",
     "id": 3405,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3406",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3406.png",
     "id": 3406,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3407",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3407.png",
     "id": 3407,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3408",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3408.png",
     "id": 3408,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3409",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3409.png",
     "id": 3409,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3410",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3410.png",
     "id": 3410,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3411",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3411.png",
     "id": 3411,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3412",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3412.png",
     "id": 3412,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3413",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3413.png",
     "id": 3413,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3414",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3414.png",
     "id": 3414,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3415",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3415.png",
     "id": 3415,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3416",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3416.png",
     "id": 3416,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3417",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3417.png",
     "id": 3417,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3418",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3418.png",
     "id": 3418,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3419",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3419.png",
     "id": 3419,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3420",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3420.png",
     "id": 3420,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3421",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3421.png",
     "id": 3421,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3422",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3422.png",
     "id": 3422,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3423",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3423.png",
     "id": 3423,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3424",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3424.png",
     "id": 3424,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3425",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3425.png",
     "id": 3425,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3426",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3426.png",
     "id": 3426,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3427",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3427.png",
     "id": 3427,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3428",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3428.png",
     "id": 3428,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3429",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3429.png",
     "id": 3429,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3430",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3430.png",
     "id": 3430,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3431",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3431.png",
     "id": 3431,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3432",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3432.png",
     "id": 3432,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3433",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3433.png",
     "id": 3433,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3434",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3434.png",
     "id": 3434,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3435",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3435.png",
     "id": 3435,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3436",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3436.png",
     "id": 3436,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3437",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3437.png",
     "id": 3437,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3438",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3438.png",
     "id": 3438,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3439",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3439.png",
     "id": 3439,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3440",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3440.png",
     "id": 3440,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3441",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3441.png",
     "id": 3441,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3442",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3442.png",
     "id": 3442,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3443",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3443.png",
     "id": 3443,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3444",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3444.png",
     "id": 3444,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3445",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3445.png",
     "id": 3445,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3446",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3446.png",
     "id": 3446,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3447",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3447.png",
     "id": 3447,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3448",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3448.png",
     "id": 3448,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3449",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3449.png",
     "id": 3449,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3450",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3450.png",
     "id": 3450,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #3451",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3451.png",
     "id": 3451,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3452",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3452.png",
     "id": 3452,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3453",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3453.png",
     "id": 3453,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3454",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3454.png",
     "id": 3454,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3455",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3455.png",
     "id": 3455,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3456",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3456.png",
     "id": 3456,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3457",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3457.png",
     "id": 3457,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3458",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3458.png",
     "id": 3458,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3459",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3459.png",
     "id": 3459,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3460",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3460.png",
     "id": 3460,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3461",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3461.png",
     "id": 3461,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3462",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3462.png",
     "id": 3462,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3463",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3463.png",
     "id": 3463,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3464",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3464.png",
     "id": 3464,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3465",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3465.png",
     "id": 3465,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3466",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3466.png",
     "id": 3466,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3467",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3467.png",
     "id": 3467,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3468",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3468.png",
     "id": 3468,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3469",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3469.png",
     "id": 3469,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3470",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3470.png",
     "id": 3470,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3471",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3471.png",
     "id": 3471,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3472",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3472.png",
     "id": 3472,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3473",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3473.png",
     "id": 3473,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3474",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3474.png",
     "id": 3474,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3475",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3475.png",
     "id": 3475,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3476",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3476.png",
     "id": 3476,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3477",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3477.png",
     "id": 3477,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3478",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3478.png",
     "id": 3478,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3479",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3479.png",
     "id": 3479,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3480",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3480.png",
     "id": 3480,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3481",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3481.png",
     "id": 3481,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3482",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3482.png",
     "id": 3482,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3483",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3483.png",
     "id": 3483,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3484",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3484.png",
     "id": 3484,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3485",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3485.png",
     "id": 3485,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3486",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3486.png",
     "id": 3486,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3487",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3487.png",
     "id": 3487,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3488",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3488.png",
     "id": 3488,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3489",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3489.png",
     "id": 3489,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3490",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3490.png",
     "id": 3490,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3491",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3491.png",
     "id": 3491,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3492",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3492.png",
     "id": 3492,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3493",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3493.png",
     "id": 3493,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3494",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3494.png",
     "id": 3494,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3495",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3495.png",
     "id": 3495,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3496",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3496.png",
     "id": 3496,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3497",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3497.png",
     "id": 3497,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3498",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3498.png",
     "id": 3498,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3499",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3499.png",
     "id": 3499,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3500",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3500.png",
     "id": 3500,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3501",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3501.png",
     "id": 3501,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3502",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3502.png",
     "id": 3502,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3503",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3503.png",
     "id": 3503,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3504",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3504.png",
     "id": 3504,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3505",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3505.png",
     "id": 3505,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3506",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3506.png",
     "id": 3506,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3507",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3507.png",
     "id": 3507,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3508",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3508.png",
     "id": 3508,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3509",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3509.png",
     "id": 3509,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3510",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3510.png",
     "id": 3510,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3511",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3511.png",
     "id": 3511,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3512",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3512.png",
     "id": 3512,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3513",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3513.png",
     "id": 3513,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3514",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3514.png",
     "id": 3514,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3515",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3515.png",
     "id": 3515,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3516",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3516.png",
     "id": 3516,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3517",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3517.png",
     "id": 3517,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3518",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3518.png",
     "id": 3518,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3519",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3519.png",
     "id": 3519,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3520",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3520.png",
     "id": 3520,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3521",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3521.png",
     "id": 3521,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3522",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3522.png",
     "id": 3522,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #3523",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3523.png",
     "id": 3523,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3524",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3524.png",
     "id": 3524,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3525",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3525.png",
     "id": 3525,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3526",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3526.png",
     "id": 3526,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3527",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3527.png",
     "id": 3527,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3528",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3528.png",
     "id": 3528,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3529",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3529.png",
     "id": 3529,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3530",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3530.png",
     "id": 3530,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3531",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3531.png",
     "id": 3531,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3532",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3532.png",
     "id": 3532,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3533",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3533.png",
     "id": 3533,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3534",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3534.png",
     "id": 3534,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3535",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3535.png",
     "id": 3535,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3536",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3536.png",
     "id": 3536,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3537",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3537.png",
     "id": 3537,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3538",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3538.png",
     "id": 3538,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3539",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3539.png",
     "id": 3539,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3540",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3540.png",
     "id": 3540,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3541",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3541.png",
     "id": 3541,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3542",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3542.png",
     "id": 3542,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3543",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3543.png",
     "id": 3543,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3544",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3544.png",
     "id": 3544,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3545",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3545.png",
     "id": 3545,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3546",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3546.png",
     "id": 3546,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3547",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3547.png",
     "id": 3547,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3548",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3548.png",
     "id": 3548,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3549",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3549.png",
     "id": 3549,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #3550",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3550.png",
     "id": 3550,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3551",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3551.png",
     "id": 3551,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3552",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3552.png",
     "id": 3552,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3553",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3553.png",
     "id": 3553,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3554",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3554.png",
     "id": 3554,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3555",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3555.png",
     "id": 3555,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3556",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3556.png",
     "id": 3556,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3557",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3557.png",
     "id": 3557,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3558",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3558.png",
     "id": 3558,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3559",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3559.png",
     "id": 3559,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3560",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3560.png",
     "id": 3560,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3561",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3561.png",
     "id": 3561,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3562",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3562.png",
     "id": 3562,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3563",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3563.png",
     "id": 3563,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3564",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3564.png",
     "id": 3564,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3565",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3565.png",
     "id": 3565,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3566",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3566.png",
     "id": 3566,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3567",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3567.png",
     "id": 3567,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #3568",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3568.png",
     "id": 3568,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3569",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3569.png",
     "id": 3569,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3570",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3570.png",
     "id": 3570,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3571",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3571.png",
     "id": 3571,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3572",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3572.png",
     "id": 3572,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3573",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3573.png",
     "id": 3573,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3574",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3574.png",
     "id": 3574,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3575",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3575.png",
     "id": 3575,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3576",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3576.png",
     "id": 3576,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3577",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3577.png",
     "id": 3577,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3578",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3578.png",
     "id": 3578,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3579",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3579.png",
     "id": 3579,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3580",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3580.png",
     "id": 3580,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3581",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3581.png",
     "id": 3581,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3582",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3582.png",
     "id": 3582,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3583",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3583.png",
     "id": 3583,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3584",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3584.png",
     "id": 3584,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3585",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3585.png",
     "id": 3585,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3586",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3586.png",
     "id": 3586,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3587",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3587.png",
     "id": 3587,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3588",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3588.png",
     "id": 3588,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3589",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3589.png",
     "id": 3589,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3590",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3590.png",
     "id": 3590,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3591",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3591.png",
     "id": 3591,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3592",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3592.png",
     "id": 3592,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3593",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3593.png",
     "id": 3593,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3594",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3594.png",
     "id": 3594,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3595",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3595.png",
     "id": 3595,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3596",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3596.png",
     "id": 3596,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3597",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3597.png",
     "id": 3597,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3598",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3598.png",
     "id": 3598,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3599",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3599.png",
     "id": 3599,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3600",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3600.png",
     "id": 3600,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3601",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3601.png",
     "id": 3601,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3602",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3602.png",
     "id": 3602,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3603",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3603.png",
     "id": 3603,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3604",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3604.png",
     "id": 3604,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3605",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3605.png",
     "id": 3605,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3606",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3606.png",
     "id": 3606,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3607",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3607.png",
     "id": 3607,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3608",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3608.png",
     "id": 3608,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3609",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3609.png",
     "id": 3609,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3610",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3610.png",
     "id": 3610,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3611",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3611.png",
     "id": 3611,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3612",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3612.png",
     "id": 3612,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3613",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3613.png",
     "id": 3613,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3614",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3614.png",
     "id": 3614,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3615",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3615.png",
     "id": 3615,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3616",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3616.png",
     "id": 3616,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3617",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3617.png",
     "id": 3617,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3618",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3618.png",
     "id": 3618,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3619",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3619.png",
     "id": 3619,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3620",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3620.png",
     "id": 3620,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3621",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3621.png",
     "id": 3621,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3622",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3622.png",
     "id": 3622,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3623",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3623.png",
     "id": 3623,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3624",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3624.png",
     "id": 3624,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3625",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3625.png",
     "id": 3625,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3626",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3626.png",
     "id": 3626,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3627",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3627.png",
     "id": 3627,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3628",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3628.png",
     "id": 3628,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3629",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3629.png",
     "id": 3629,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3630",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3630.png",
     "id": 3630,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3631",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3631.png",
     "id": 3631,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3632",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3632.png",
     "id": 3632,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3633",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3633.png",
     "id": 3633,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3634",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3634.png",
     "id": 3634,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3635",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3635.png",
     "id": 3635,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3636",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3636.png",
     "id": 3636,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3637",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3637.png",
     "id": 3637,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3638",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3638.png",
     "id": 3638,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3639",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3639.png",
     "id": 3639,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3640",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3640.png",
     "id": 3640,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3641",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3641.png",
     "id": 3641,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3642",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3642.png",
     "id": 3642,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3643",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3643.png",
     "id": 3643,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3644",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3644.png",
     "id": 3644,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3645",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3645.png",
     "id": 3645,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3646",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3646.png",
     "id": 3646,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3647",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3647.png",
     "id": 3647,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3648",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3648.png",
     "id": 3648,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3649",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3649.png",
     "id": 3649,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3650",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3650.png",
     "id": 3650,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3651",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3651.png",
     "id": 3651,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3652",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3652.png",
     "id": 3652,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3653",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3653.png",
     "id": 3653,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3654",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3654.png",
     "id": 3654,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3655",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3655.png",
     "id": 3655,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3656",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3656.png",
     "id": 3656,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3657",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3657.png",
     "id": 3657,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3658",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3658.png",
     "id": 3658,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3659",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3659.png",
     "id": 3659,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3660",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3660.png",
     "id": 3660,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3661",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3661.png",
     "id": 3661,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3662",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3662.png",
     "id": 3662,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3663",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3663.png",
     "id": 3663,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3664",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3664.png",
     "id": 3664,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3665",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3665.png",
     "id": 3665,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3666",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3666.png",
     "id": 3666,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3667",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3667.png",
     "id": 3667,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3668",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3668.png",
     "id": 3668,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #3669",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3669.png",
     "id": 3669,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #3670",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3670.png",
     "id": 3670,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3671",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3671.png",
     "id": 3671,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3672",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3672.png",
     "id": 3672,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Bulk Boomin"
      },
      {
       "trait_type": "Skin",
       "value": "Bulk Boomin"
      },
      {
       "trait_type": "Crypto",
       "value": "Bulk Boomin"
      },
      {
       "trait_type": "Activity",
       "value": "Bulk Boomin"
      },
      {
       "trait_type": "Outfit",
       "value": "Bulk Boomin"
      },
      {
       "trait_type": "Transportation",
       "value": "Bulk Boomin"
      },
      {
       "trait_type": "Head",
       "value": "Bulk Boomin"
      },
      {
       "trait_type": "Mouth",
       "value": "Bulk Boomin"
      },
      {
       "trait_type": "Eyes",
       "value": "Bulk Boomin"
      }
     ]
    },
    {
     "name": "Boomer Squad #3673",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3673.png",
     "id": 3673,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3674",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3674.png",
     "id": 3674,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3675",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3675.png",
     "id": 3675,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3676",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3676.png",
     "id": 3676,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3677",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3677.png",
     "id": 3677,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3678",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3678.png",
     "id": 3678,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3679",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3679.png",
     "id": 3679,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3680",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3680.png",
     "id": 3680,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3681",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3681.png",
     "id": 3681,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3682",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3682.png",
     "id": 3682,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3683",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3683.png",
     "id": 3683,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3684",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3684.png",
     "id": 3684,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3685",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3685.png",
     "id": 3685,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3686",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3686.png",
     "id": 3686,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3687",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3687.png",
     "id": 3687,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3688",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3688.png",
     "id": 3688,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3689",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3689.png",
     "id": 3689,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3690",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3690.png",
     "id": 3690,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3691",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3691.png",
     "id": 3691,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3692",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3692.png",
     "id": 3692,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3693",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3693.png",
     "id": 3693,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3694",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3694.png",
     "id": 3694,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3695",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3695.png",
     "id": 3695,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3696",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3696.png",
     "id": 3696,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3697",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3697.png",
     "id": 3697,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3698",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3698.png",
     "id": 3698,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3699",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3699.png",
     "id": 3699,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3700",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3700.png",
     "id": 3700,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3701",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3701.png",
     "id": 3701,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3702",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3702.png",
     "id": 3702,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3703",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3703.png",
     "id": 3703,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3704",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3704.png",
     "id": 3704,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3705",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3705.png",
     "id": 3705,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3706",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3706.png",
     "id": 3706,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3707",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3707.png",
     "id": 3707,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3708",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3708.png",
     "id": 3708,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3709",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3709.png",
     "id": 3709,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3710",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3710.png",
     "id": 3710,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3711",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3711.png",
     "id": 3711,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3712",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3712.png",
     "id": 3712,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3713",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3713.png",
     "id": 3713,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3714",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3714.png",
     "id": 3714,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3715",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3715.png",
     "id": 3715,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3716",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3716.png",
     "id": 3716,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3717",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3717.png",
     "id": 3717,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3718",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3718.png",
     "id": 3718,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3719",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3719.png",
     "id": 3719,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3720",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3720.png",
     "id": 3720,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3721",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3721.png",
     "id": 3721,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3722",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3722.png",
     "id": 3722,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3723",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3723.png",
     "id": 3723,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3724",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3724.png",
     "id": 3724,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3725",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3725.png",
     "id": 3725,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3726",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3726.png",
     "id": 3726,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3727",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3727.png",
     "id": 3727,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3728",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3728.png",
     "id": 3728,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3729",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3729.png",
     "id": 3729,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3730",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3730.png",
     "id": 3730,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3731",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3731.png",
     "id": 3731,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3732",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3732.png",
     "id": 3732,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3733",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3733.png",
     "id": 3733,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3734",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3734.png",
     "id": 3734,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3735",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3735.png",
     "id": 3735,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3736",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3736.png",
     "id": 3736,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3737",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3737.png",
     "id": 3737,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3738",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3738.png",
     "id": 3738,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3739",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3739.png",
     "id": 3739,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3740",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3740.png",
     "id": 3740,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3741",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3741.png",
     "id": 3741,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3742",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3742.png",
     "id": 3742,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3743",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3743.png",
     "id": 3743,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3744",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3744.png",
     "id": 3744,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3745",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3745.png",
     "id": 3745,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3746",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3746.png",
     "id": 3746,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3747",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3747.png",
     "id": 3747,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3748",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3748.png",
     "id": 3748,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3749",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3749.png",
     "id": 3749,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3750",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3750.png",
     "id": 3750,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3751",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3751.png",
     "id": 3751,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3752",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3752.png",
     "id": 3752,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3753",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3753.png",
     "id": 3753,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3754",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3754.png",
     "id": 3754,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3755",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3755.png",
     "id": 3755,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3756",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3756.png",
     "id": 3756,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3757",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3757.png",
     "id": 3757,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3758",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3758.png",
     "id": 3758,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3759",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3759.png",
     "id": 3759,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3760",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3760.png",
     "id": 3760,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3761",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3761.png",
     "id": 3761,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3762",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3762.png",
     "id": 3762,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3763",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3763.png",
     "id": 3763,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3764",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3764.png",
     "id": 3764,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3765",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3765.png",
     "id": 3765,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3766",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3766.png",
     "id": 3766,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3767",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3767.png",
     "id": 3767,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3768",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3768.png",
     "id": 3768,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3769",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3769.png",
     "id": 3769,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3770",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3770.png",
     "id": 3770,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3771",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3771.png",
     "id": 3771,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3772",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3772.png",
     "id": 3772,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3773",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3773.png",
     "id": 3773,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3774",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3774.png",
     "id": 3774,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3775",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3775.png",
     "id": 3775,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3776",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3776.png",
     "id": 3776,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3777",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3777.png",
     "id": 3777,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3778",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3778.png",
     "id": 3778,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3779",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3779.png",
     "id": 3779,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #3780",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3780.png",
     "id": 3780,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3781",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3781.png",
     "id": 3781,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3782",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3782.png",
     "id": 3782,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3783",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3783.png",
     "id": 3783,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3784",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3784.png",
     "id": 3784,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3785",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3785.png",
     "id": 3785,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3786",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3786.png",
     "id": 3786,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3787",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3787.png",
     "id": 3787,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3788",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3788.png",
     "id": 3788,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #3789",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3789.png",
     "id": 3789,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3790",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3790.png",
     "id": 3790,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3791",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3791.png",
     "id": 3791,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3792",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3792.png",
     "id": 3792,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3793",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3793.png",
     "id": 3793,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3794",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3794.png",
     "id": 3794,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3795",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3795.png",
     "id": 3795,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3796",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3796.png",
     "id": 3796,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3797",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3797.png",
     "id": 3797,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3798",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3798.png",
     "id": 3798,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3799",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3799.png",
     "id": 3799,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3800",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3800.png",
     "id": 3800,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3801",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3801.png",
     "id": 3801,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3802",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3802.png",
     "id": 3802,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3803",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3803.png",
     "id": 3803,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3804",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3804.png",
     "id": 3804,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3805",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3805.png",
     "id": 3805,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3806",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3806.png",
     "id": 3806,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3807",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3807.png",
     "id": 3807,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3808",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3808.png",
     "id": 3808,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3809",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3809.png",
     "id": 3809,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3810",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3810.png",
     "id": 3810,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3811",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3811.png",
     "id": 3811,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3812",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3812.png",
     "id": 3812,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3813",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3813.png",
     "id": 3813,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3814",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3814.png",
     "id": 3814,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3815",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3815.png",
     "id": 3815,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3816",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3816.png",
     "id": 3816,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3817",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3817.png",
     "id": 3817,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3818",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3818.png",
     "id": 3818,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3819",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3819.png",
     "id": 3819,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3820",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3820.png",
     "id": 3820,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3821",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3821.png",
     "id": 3821,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3822",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3822.png",
     "id": 3822,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3823",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3823.png",
     "id": 3823,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3824",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3824.png",
     "id": 3824,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3825",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3825.png",
     "id": 3825,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3826",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3826.png",
     "id": 3826,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3827",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3827.png",
     "id": 3827,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3828",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3828.png",
     "id": 3828,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3829",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3829.png",
     "id": 3829,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3830",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3830.png",
     "id": 3830,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3831",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3831.png",
     "id": 3831,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3832",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3832.png",
     "id": 3832,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3833",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3833.png",
     "id": 3833,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3834",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3834.png",
     "id": 3834,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3835",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3835.png",
     "id": 3835,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3836",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3836.png",
     "id": 3836,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3837",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3837.png",
     "id": 3837,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3838",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3838.png",
     "id": 3838,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3839",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3839.png",
     "id": 3839,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3840",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3840.png",
     "id": 3840,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3841",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3841.png",
     "id": 3841,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3842",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3842.png",
     "id": 3842,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3843",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3843.png",
     "id": 3843,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3844",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3844.png",
     "id": 3844,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3845",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3845.png",
     "id": 3845,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3846",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3846.png",
     "id": 3846,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3847",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3847.png",
     "id": 3847,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3848",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3848.png",
     "id": 3848,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3849",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3849.png",
     "id": 3849,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3850",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3850.png",
     "id": 3850,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3851",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3851.png",
     "id": 3851,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3852",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3852.png",
     "id": 3852,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3853",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3853.png",
     "id": 3853,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3854",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3854.png",
     "id": 3854,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3855",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3855.png",
     "id": 3855,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3856",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3856.png",
     "id": 3856,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3857",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3857.png",
     "id": 3857,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3858",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3858.png",
     "id": 3858,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3859",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3859.png",
     "id": 3859,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3860",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3860.png",
     "id": 3860,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3861",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3861.png",
     "id": 3861,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3862",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3862.png",
     "id": 3862,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3863",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3863.png",
     "id": 3863,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3864",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3864.png",
     "id": 3864,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3865",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3865.png",
     "id": 3865,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3866",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3866.png",
     "id": 3866,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3867",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3867.png",
     "id": 3867,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3868",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3868.png",
     "id": 3868,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3869",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3869.png",
     "id": 3869,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3870",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3870.png",
     "id": 3870,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3871",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3871.png",
     "id": 3871,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3872",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3872.png",
     "id": 3872,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #3873",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3873.png",
     "id": 3873,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3874",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3874.png",
     "id": 3874,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3875",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3875.png",
     "id": 3875,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3876",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3876.png",
     "id": 3876,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3877",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3877.png",
     "id": 3877,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3878",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3878.png",
     "id": 3878,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3879",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3879.png",
     "id": 3879,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3880",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3880.png",
     "id": 3880,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3881",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3881.png",
     "id": 3881,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3882",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3882.png",
     "id": 3882,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3883",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3883.png",
     "id": 3883,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3884",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3884.png",
     "id": 3884,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3885",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3885.png",
     "id": 3885,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3886",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3886.png",
     "id": 3886,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3887",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3887.png",
     "id": 3887,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3888",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3888.png",
     "id": 3888,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3889",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3889.png",
     "id": 3889,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3890",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3890.png",
     "id": 3890,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3891",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3891.png",
     "id": 3891,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3892",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3892.png",
     "id": 3892,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3893",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3893.png",
     "id": 3893,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3894",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3894.png",
     "id": 3894,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3895",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3895.png",
     "id": 3895,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3896",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3896.png",
     "id": 3896,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3897",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3897.png",
     "id": 3897,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3898",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3898.png",
     "id": 3898,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3899",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3899.png",
     "id": 3899,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3900",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3900.png",
     "id": 3900,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3901",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3901.png",
     "id": 3901,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3902",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3902.png",
     "id": 3902,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3903",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3903.png",
     "id": 3903,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3904",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3904.png",
     "id": 3904,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3905",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3905.png",
     "id": 3905,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3906",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3906.png",
     "id": 3906,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3907",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3907.png",
     "id": 3907,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3908",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3908.png",
     "id": 3908,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3909",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3909.png",
     "id": 3909,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3910",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3910.png",
     "id": 3910,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3911",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3911.png",
     "id": 3911,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3912",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3912.png",
     "id": 3912,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3913",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3913.png",
     "id": 3913,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3914",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3914.png",
     "id": 3914,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3915",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3915.png",
     "id": 3915,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3916",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3916.png",
     "id": 3916,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3917",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3917.png",
     "id": 3917,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3918",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3918.png",
     "id": 3918,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3919",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3919.png",
     "id": 3919,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3920",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3920.png",
     "id": 3920,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3921",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3921.png",
     "id": 3921,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3922",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3922.png",
     "id": 3922,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3923",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3923.png",
     "id": 3923,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3924",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3924.png",
     "id": 3924,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3925",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3925.png",
     "id": 3925,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3926",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3926.png",
     "id": 3926,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3927",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3927.png",
     "id": 3927,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3928",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3928.png",
     "id": 3928,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3929",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3929.png",
     "id": 3929,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3930",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3930.png",
     "id": 3930,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3931",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3931.png",
     "id": 3931,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3932",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3932.png",
     "id": 3932,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3933",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3933.png",
     "id": 3933,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3934",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3934.png",
     "id": 3934,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3935",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3935.png",
     "id": 3935,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3936",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3936.png",
     "id": 3936,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3937",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3937.png",
     "id": 3937,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3938",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3938.png",
     "id": 3938,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3939",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3939.png",
     "id": 3939,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3940",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3940.png",
     "id": 3940,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3941",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3941.png",
     "id": 3941,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3942",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3942.png",
     "id": 3942,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3943",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3943.png",
     "id": 3943,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3944",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3944.png",
     "id": 3944,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3945",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3945.png",
     "id": 3945,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #3946",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3946.png",
     "id": 3946,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3947",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3947.png",
     "id": 3947,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3948",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3948.png",
     "id": 3948,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3949",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3949.png",
     "id": 3949,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3950",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3950.png",
     "id": 3950,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3951",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3951.png",
     "id": 3951,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3952",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3952.png",
     "id": 3952,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #3953",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3953.png",
     "id": 3953,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3954",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3954.png",
     "id": 3954,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3955",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3955.png",
     "id": 3955,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3956",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3956.png",
     "id": 3956,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3957",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3957.png",
     "id": 3957,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3958",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3958.png",
     "id": 3958,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3959",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3959.png",
     "id": 3959,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3960",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3960.png",
     "id": 3960,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3961",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3961.png",
     "id": 3961,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3962",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3962.png",
     "id": 3962,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3963",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3963.png",
     "id": 3963,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3964",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3964.png",
     "id": 3964,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3965",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3965.png",
     "id": 3965,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3966",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3966.png",
     "id": 3966,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3967",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3967.png",
     "id": 3967,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3968",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3968.png",
     "id": 3968,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #3969",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3969.png",
     "id": 3969,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3970",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3970.png",
     "id": 3970,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #3971",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3971.png",
     "id": 3971,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #3972",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3972.png",
     "id": 3972,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3973",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3973.png",
     "id": 3973,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #3974",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3974.png",
     "id": 3974,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3975",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3975.png",
     "id": 3975,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3976",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3976.png",
     "id": 3976,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3977",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3977.png",
     "id": 3977,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3978",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3978.png",
     "id": 3978,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3979",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3979.png",
     "id": 3979,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #3980",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3980.png",
     "id": 3980,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3981",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3981.png",
     "id": 3981,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #3982",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3982.png",
     "id": 3982,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #3983",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3983.png",
     "id": 3983,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #3984",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3984.png",
     "id": 3984,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #3985",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3985.png",
     "id": 3985,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #3986",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3986.png",
     "id": 3986,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #3987",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3987.png",
     "id": 3987,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #3988",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3988.png",
     "id": 3988,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3989",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3989.png",
     "id": 3989,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #3990",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3990.png",
     "id": 3990,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #3991",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3991.png",
     "id": 3991,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #3992",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3992.png",
     "id": 3992,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3993",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3993.png",
     "id": 3993,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #3994",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3994.png",
     "id": 3994,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3995",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3995.png",
     "id": 3995,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #3996",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3996.png",
     "id": 3996,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #3997",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3997.png",
     "id": 3997,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #3998",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3998.png",
     "id": 3998,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #3999",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/3999.png",
     "id": 3999,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4000",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4000.png",
     "id": 4000,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4001",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4001.png",
     "id": 4001,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4002",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4002.png",
     "id": 4002,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4003",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4003.png",
     "id": 4003,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4004",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4004.png",
     "id": 4004,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4005",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4005.png",
     "id": 4005,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4006",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4006.png",
     "id": 4006,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4007",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4007.png",
     "id": 4007,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4008",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4008.png",
     "id": 4008,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4009",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4009.png",
     "id": 4009,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4010",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4010.png",
     "id": 4010,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4011",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4011.png",
     "id": 4011,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4012",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4012.png",
     "id": 4012,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4013",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4013.png",
     "id": 4013,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4014",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4014.png",
     "id": 4014,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4015",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4015.png",
     "id": 4015,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4016",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4016.png",
     "id": 4016,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4017",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4017.png",
     "id": 4017,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4018",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4018.png",
     "id": 4018,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4019",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4019.png",
     "id": 4019,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4020",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4020.png",
     "id": 4020,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4021",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4021.png",
     "id": 4021,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4022",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4022.png",
     "id": 4022,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4023",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4023.png",
     "id": 4023,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4024",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4024.png",
     "id": 4024,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4025",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4025.png",
     "id": 4025,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4026",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4026.png",
     "id": 4026,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4027",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4027.png",
     "id": 4027,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4028",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4028.png",
     "id": 4028,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4029",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4029.png",
     "id": 4029,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4030",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4030.png",
     "id": 4030,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4031",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4031.png",
     "id": 4031,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4032",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4032.png",
     "id": 4032,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4033",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4033.png",
     "id": 4033,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4034",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4034.png",
     "id": 4034,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4035",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4035.png",
     "id": 4035,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4036",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4036.png",
     "id": 4036,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4037",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4037.png",
     "id": 4037,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4038",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4038.png",
     "id": 4038,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4039",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4039.png",
     "id": 4039,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4040",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4040.png",
     "id": 4040,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4041",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4041.png",
     "id": 4041,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4042",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4042.png",
     "id": 4042,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4043",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4043.png",
     "id": 4043,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4044",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4044.png",
     "id": 4044,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4045",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4045.png",
     "id": 4045,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4046",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4046.png",
     "id": 4046,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4047",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4047.png",
     "id": 4047,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4048",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4048.png",
     "id": 4048,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4049",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4049.png",
     "id": 4049,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4050",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4050.png",
     "id": 4050,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4051",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4051.png",
     "id": 4051,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4052",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4052.png",
     "id": 4052,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4053",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4053.png",
     "id": 4053,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4054",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4054.png",
     "id": 4054,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4055",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4055.png",
     "id": 4055,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4056",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4056.png",
     "id": 4056,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4057",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4057.png",
     "id": 4057,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4058",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4058.png",
     "id": 4058,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4059",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4059.png",
     "id": 4059,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #4060",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4060.png",
     "id": 4060,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4061",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4061.png",
     "id": 4061,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4062",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4062.png",
     "id": 4062,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4063",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4063.png",
     "id": 4063,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4064",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4064.png",
     "id": 4064,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4065",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4065.png",
     "id": 4065,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4066",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4066.png",
     "id": 4066,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4067",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4067.png",
     "id": 4067,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4068",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4068.png",
     "id": 4068,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4069",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4069.png",
     "id": 4069,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4070",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4070.png",
     "id": 4070,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4071",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4071.png",
     "id": 4071,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4072",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4072.png",
     "id": 4072,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4073",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4073.png",
     "id": 4073,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4074",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4074.png",
     "id": 4074,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4075",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4075.png",
     "id": 4075,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4076",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4076.png",
     "id": 4076,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4077",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4077.png",
     "id": 4077,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4078",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4078.png",
     "id": 4078,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4079",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4079.png",
     "id": 4079,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4080",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4080.png",
     "id": 4080,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4081",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4081.png",
     "id": 4081,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4082",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4082.png",
     "id": 4082,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4083",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4083.png",
     "id": 4083,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4084",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4084.png",
     "id": 4084,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4085",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4085.png",
     "id": 4085,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4086",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4086.png",
     "id": 4086,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4087",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4087.png",
     "id": 4087,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4088",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4088.png",
     "id": 4088,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4089",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4089.png",
     "id": 4089,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4090",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4090.png",
     "id": 4090,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4091",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4091.png",
     "id": 4091,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4092",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4092.png",
     "id": 4092,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4093",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4093.png",
     "id": 4093,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4094",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4094.png",
     "id": 4094,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4095",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4095.png",
     "id": 4095,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4096",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4096.png",
     "id": 4096,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4097",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4097.png",
     "id": 4097,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4098",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4098.png",
     "id": 4098,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4099",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4099.png",
     "id": 4099,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4100",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4100.png",
     "id": 4100,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4101",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4101.png",
     "id": 4101,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4102",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4102.png",
     "id": 4102,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4103",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4103.png",
     "id": 4103,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4104",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4104.png",
     "id": 4104,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4105",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4105.png",
     "id": 4105,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4106",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4106.png",
     "id": 4106,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4107",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4107.png",
     "id": 4107,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4108",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4108.png",
     "id": 4108,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #4109",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4109.png",
     "id": 4109,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4110",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4110.png",
     "id": 4110,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4111",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4111.png",
     "id": 4111,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4112",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4112.png",
     "id": 4112,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4113",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4113.png",
     "id": 4113,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4114",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4114.png",
     "id": 4114,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4115",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4115.png",
     "id": 4115,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4116",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4116.png",
     "id": 4116,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4117",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4117.png",
     "id": 4117,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4118",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4118.png",
     "id": 4118,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4119",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4119.png",
     "id": 4119,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4120",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4120.png",
     "id": 4120,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4121",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4121.png",
     "id": 4121,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4122",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4122.png",
     "id": 4122,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4123",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4123.png",
     "id": 4123,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Justice"
      },
      {
       "trait_type": "Skin",
       "value": "Justice"
      },
      {
       "trait_type": "Crypto",
       "value": "Justice"
      },
      {
       "trait_type": "Activity",
       "value": "Justice"
      },
      {
       "trait_type": "Outfit",
       "value": "Justice"
      },
      {
       "trait_type": "Transportation",
       "value": "Justice"
      },
      {
       "trait_type": "Head",
       "value": "Justice"
      },
      {
       "trait_type": "Mouth",
       "value": "Justice"
      },
      {
       "trait_type": "Eyes",
       "value": "Justice"
      }
     ]
    },
    {
     "name": "Boomer Squad #4124",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4124.png",
     "id": 4124,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4125",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4125.png",
     "id": 4125,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4126",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4126.png",
     "id": 4126,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #4127",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4127.png",
     "id": 4127,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4128",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4128.png",
     "id": 4128,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4129",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4129.png",
     "id": 4129,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4130",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4130.png",
     "id": 4130,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4131",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4131.png",
     "id": 4131,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4132",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4132.png",
     "id": 4132,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4133",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4133.png",
     "id": 4133,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4134",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4134.png",
     "id": 4134,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4135",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4135.png",
     "id": 4135,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4136",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4136.png",
     "id": 4136,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4137",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4137.png",
     "id": 4137,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4138",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4138.png",
     "id": 4138,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4139",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4139.png",
     "id": 4139,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4140",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4140.png",
     "id": 4140,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4141",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4141.png",
     "id": 4141,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4142",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4142.png",
     "id": 4142,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4143",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4143.png",
     "id": 4143,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4144",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4144.png",
     "id": 4144,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4145",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4145.png",
     "id": 4145,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4146",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4146.png",
     "id": 4146,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4147",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4147.png",
     "id": 4147,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4148",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4148.png",
     "id": 4148,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4149",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4149.png",
     "id": 4149,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4150",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4150.png",
     "id": 4150,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4151",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4151.png",
     "id": 4151,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4152",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4152.png",
     "id": 4152,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4153",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4153.png",
     "id": 4153,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4154",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4154.png",
     "id": 4154,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4155",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4155.png",
     "id": 4155,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4156",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4156.png",
     "id": 4156,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4157",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4157.png",
     "id": 4157,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4158",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4158.png",
     "id": 4158,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4159",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4159.png",
     "id": 4159,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4160",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4160.png",
     "id": 4160,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4161",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4161.png",
     "id": 4161,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4162",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4162.png",
     "id": 4162,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4163",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4163.png",
     "id": 4163,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4164",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4164.png",
     "id": 4164,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4165",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4165.png",
     "id": 4165,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4166",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4166.png",
     "id": 4166,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4167",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4167.png",
     "id": 4167,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4168",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4168.png",
     "id": 4168,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4169",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4169.png",
     "id": 4169,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4170",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4170.png",
     "id": 4170,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4171",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4171.png",
     "id": 4171,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4172",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4172.png",
     "id": 4172,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4173",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4173.png",
     "id": 4173,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4174",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4174.png",
     "id": 4174,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4175",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4175.png",
     "id": 4175,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4176",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4176.png",
     "id": 4176,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4177",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4177.png",
     "id": 4177,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4178",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4178.png",
     "id": 4178,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4179",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4179.png",
     "id": 4179,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4180",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4180.png",
     "id": 4180,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4181",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4181.png",
     "id": 4181,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4182",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4182.png",
     "id": 4182,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4183",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4183.png",
     "id": 4183,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4184",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4184.png",
     "id": 4184,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4185",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4185.png",
     "id": 4185,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4186",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4186.png",
     "id": 4186,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4187",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4187.png",
     "id": 4187,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4188",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4188.png",
     "id": 4188,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4189",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4189.png",
     "id": 4189,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4190",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4190.png",
     "id": 4190,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4191",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4191.png",
     "id": 4191,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4192",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4192.png",
     "id": 4192,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4193",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4193.png",
     "id": 4193,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4194",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4194.png",
     "id": 4194,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4195",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4195.png",
     "id": 4195,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4196",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4196.png",
     "id": 4196,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4197",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4197.png",
     "id": 4197,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4198",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4198.png",
     "id": 4198,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4199",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4199.png",
     "id": 4199,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4200",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4200.png",
     "id": 4200,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4201",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4201.png",
     "id": 4201,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4202",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4202.png",
     "id": 4202,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4203",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4203.png",
     "id": 4203,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4204",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4204.png",
     "id": 4204,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4205",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4205.png",
     "id": 4205,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4206",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4206.png",
     "id": 4206,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4207",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4207.png",
     "id": 4207,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4208",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4208.png",
     "id": 4208,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4209",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4209.png",
     "id": 4209,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4210",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4210.png",
     "id": 4210,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4211",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4211.png",
     "id": 4211,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #4212",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4212.png",
     "id": 4212,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4213",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4213.png",
     "id": 4213,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4214",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4214.png",
     "id": 4214,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4215",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4215.png",
     "id": 4215,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4216",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4216.png",
     "id": 4216,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4217",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4217.png",
     "id": 4217,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4218",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4218.png",
     "id": 4218,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4219",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4219.png",
     "id": 4219,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4220",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4220.png",
     "id": 4220,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4221",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4221.png",
     "id": 4221,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4222",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4222.png",
     "id": 4222,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4223",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4223.png",
     "id": 4223,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4224",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4224.png",
     "id": 4224,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4225",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4225.png",
     "id": 4225,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4226",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4226.png",
     "id": 4226,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4227",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4227.png",
     "id": 4227,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4228",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4228.png",
     "id": 4228,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4229",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4229.png",
     "id": 4229,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4230",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4230.png",
     "id": 4230,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4231",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4231.png",
     "id": 4231,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4232",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4232.png",
     "id": 4232,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4233",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4233.png",
     "id": 4233,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4234",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4234.png",
     "id": 4234,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4235",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4235.png",
     "id": 4235,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4236",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4236.png",
     "id": 4236,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4237",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4237.png",
     "id": 4237,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4238",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4238.png",
     "id": 4238,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4239",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4239.png",
     "id": 4239,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4240",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4240.png",
     "id": 4240,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4241",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4241.png",
     "id": 4241,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4242",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4242.png",
     "id": 4242,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4243",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4243.png",
     "id": 4243,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4244",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4244.png",
     "id": 4244,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4245",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4245.png",
     "id": 4245,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4246",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4246.png",
     "id": 4246,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4247",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4247.png",
     "id": 4247,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4248",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4248.png",
     "id": 4248,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4249",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4249.png",
     "id": 4249,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4250",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4250.png",
     "id": 4250,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4251",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4251.png",
     "id": 4251,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4252",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4252.png",
     "id": 4252,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4253",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4253.png",
     "id": 4253,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4254",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4254.png",
     "id": 4254,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4255",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4255.png",
     "id": 4255,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4256",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4256.png",
     "id": 4256,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4257",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4257.png",
     "id": 4257,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4258",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4258.png",
     "id": 4258,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4259",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4259.png",
     "id": 4259,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4260",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4260.png",
     "id": 4260,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4261",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4261.png",
     "id": 4261,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4262",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4262.png",
     "id": 4262,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4263",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4263.png",
     "id": 4263,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4264",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4264.png",
     "id": 4264,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4265",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4265.png",
     "id": 4265,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4266",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4266.png",
     "id": 4266,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4267",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4267.png",
     "id": 4267,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4268",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4268.png",
     "id": 4268,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4269",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4269.png",
     "id": 4269,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4270",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4270.png",
     "id": 4270,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4271",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4271.png",
     "id": 4271,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4272",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4272.png",
     "id": 4272,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4273",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4273.png",
     "id": 4273,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4274",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4274.png",
     "id": 4274,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4275",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4275.png",
     "id": 4275,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4276",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4276.png",
     "id": 4276,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4277",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4277.png",
     "id": 4277,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4278",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4278.png",
     "id": 4278,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4279",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4279.png",
     "id": 4279,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4280",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4280.png",
     "id": 4280,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4281",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4281.png",
     "id": 4281,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4282",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4282.png",
     "id": 4282,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4283",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4283.png",
     "id": 4283,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4284",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4284.png",
     "id": 4284,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4285",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4285.png",
     "id": 4285,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4286",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4286.png",
     "id": 4286,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4287",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4287.png",
     "id": 4287,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4288",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4288.png",
     "id": 4288,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4289",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4289.png",
     "id": 4289,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4290",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4290.png",
     "id": 4290,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4291",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4291.png",
     "id": 4291,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4292",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4292.png",
     "id": 4292,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4293",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4293.png",
     "id": 4293,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4294",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4294.png",
     "id": 4294,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4295",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4295.png",
     "id": 4295,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4296",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4296.png",
     "id": 4296,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4297",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4297.png",
     "id": 4297,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4298",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4298.png",
     "id": 4298,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4299",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4299.png",
     "id": 4299,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4300",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4300.png",
     "id": 4300,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4301",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4301.png",
     "id": 4301,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4302",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4302.png",
     "id": 4302,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4303",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4303.png",
     "id": 4303,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4304",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4304.png",
     "id": 4304,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4305",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4305.png",
     "id": 4305,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4306",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4306.png",
     "id": 4306,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4307",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4307.png",
     "id": 4307,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4308",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4308.png",
     "id": 4308,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4309",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4309.png",
     "id": 4309,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4310",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4310.png",
     "id": 4310,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4311",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4311.png",
     "id": 4311,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4312",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4312.png",
     "id": 4312,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4313",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4313.png",
     "id": 4313,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4314",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4314.png",
     "id": 4314,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4315",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4315.png",
     "id": 4315,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4316",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4316.png",
     "id": 4316,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4317",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4317.png",
     "id": 4317,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4318",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4318.png",
     "id": 4318,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4319",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4319.png",
     "id": 4319,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4320",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4320.png",
     "id": 4320,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4321",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4321.png",
     "id": 4321,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4322",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4322.png",
     "id": 4322,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4323",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4323.png",
     "id": 4323,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4324",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4324.png",
     "id": 4324,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4325",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4325.png",
     "id": 4325,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4326",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4326.png",
     "id": 4326,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4327",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4327.png",
     "id": 4327,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4328",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4328.png",
     "id": 4328,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4329",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4329.png",
     "id": 4329,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4330",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4330.png",
     "id": 4330,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4331",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4331.png",
     "id": 4331,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4332",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4332.png",
     "id": 4332,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4333",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4333.png",
     "id": 4333,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4334",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4334.png",
     "id": 4334,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4335",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4335.png",
     "id": 4335,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4336",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4336.png",
     "id": 4336,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4337",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4337.png",
     "id": 4337,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4338",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4338.png",
     "id": 4338,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4339",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4339.png",
     "id": 4339,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4340",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4340.png",
     "id": 4340,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4341",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4341.png",
     "id": 4341,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4342",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4342.png",
     "id": 4342,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4343",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4343.png",
     "id": 4343,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4344",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4344.png",
     "id": 4344,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4345",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4345.png",
     "id": 4345,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4346",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4346.png",
     "id": 4346,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4347",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4347.png",
     "id": 4347,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4348",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4348.png",
     "id": 4348,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4349",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4349.png",
     "id": 4349,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4350",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4350.png",
     "id": 4350,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4351",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4351.png",
     "id": 4351,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4352",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4352.png",
     "id": 4352,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4353",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4353.png",
     "id": 4353,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4354",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4354.png",
     "id": 4354,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4355",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4355.png",
     "id": 4355,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4356",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4356.png",
     "id": 4356,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4357",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4357.png",
     "id": 4357,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4358",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4358.png",
     "id": 4358,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4359",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4359.png",
     "id": 4359,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4360",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4360.png",
     "id": 4360,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4361",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4361.png",
     "id": 4361,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4362",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4362.png",
     "id": 4362,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4363",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4363.png",
     "id": 4363,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4364",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4364.png",
     "id": 4364,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4365",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4365.png",
     "id": 4365,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4366",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4366.png",
     "id": 4366,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4367",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4367.png",
     "id": 4367,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4368",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4368.png",
     "id": 4368,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4369",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4369.png",
     "id": 4369,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4370",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4370.png",
     "id": 4370,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4371",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4371.png",
     "id": 4371,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4372",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4372.png",
     "id": 4372,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4373",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4373.png",
     "id": 4373,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4374",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4374.png",
     "id": 4374,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4375",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4375.png",
     "id": 4375,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4376",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4376.png",
     "id": 4376,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4377",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4377.png",
     "id": 4377,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4378",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4378.png",
     "id": 4378,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4379",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4379.png",
     "id": 4379,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4380",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4380.png",
     "id": 4380,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4381",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4381.png",
     "id": 4381,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4382",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4382.png",
     "id": 4382,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4383",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4383.png",
     "id": 4383,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4384",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4384.png",
     "id": 4384,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4385",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4385.png",
     "id": 4385,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4386",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4386.png",
     "id": 4386,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4387",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4387.png",
     "id": 4387,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4388",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4388.png",
     "id": 4388,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4389",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4389.png",
     "id": 4389,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4390",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4390.png",
     "id": 4390,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4391",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4391.png",
     "id": 4391,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4392",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4392.png",
     "id": 4392,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4393",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4393.png",
     "id": 4393,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4394",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4394.png",
     "id": 4394,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4395",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4395.png",
     "id": 4395,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4396",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4396.png",
     "id": 4396,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4397",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4397.png",
     "id": 4397,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4398",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4398.png",
     "id": 4398,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4399",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4399.png",
     "id": 4399,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4400",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4400.png",
     "id": 4400,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4401",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4401.png",
     "id": 4401,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4402",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4402.png",
     "id": 4402,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4403",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4403.png",
     "id": 4403,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4404",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4404.png",
     "id": 4404,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4405",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4405.png",
     "id": 4405,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4406",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4406.png",
     "id": 4406,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4407",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4407.png",
     "id": 4407,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4408",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4408.png",
     "id": 4408,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4409",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4409.png",
     "id": 4409,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4410",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4410.png",
     "id": 4410,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4411",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4411.png",
     "id": 4411,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4412",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4412.png",
     "id": 4412,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4413",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4413.png",
     "id": 4413,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4414",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4414.png",
     "id": 4414,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4415",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4415.png",
     "id": 4415,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4416",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4416.png",
     "id": 4416,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4417",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4417.png",
     "id": 4417,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4418",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4418.png",
     "id": 4418,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4419",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4419.png",
     "id": 4419,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4420",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4420.png",
     "id": 4420,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4421",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4421.png",
     "id": 4421,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4422",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4422.png",
     "id": 4422,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4423",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4423.png",
     "id": 4423,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4424",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4424.png",
     "id": 4424,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4425",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4425.png",
     "id": 4425,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4426",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4426.png",
     "id": 4426,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4427",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4427.png",
     "id": 4427,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4428",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4428.png",
     "id": 4428,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4429",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4429.png",
     "id": 4429,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4430",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4430.png",
     "id": 4430,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4431",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4431.png",
     "id": 4431,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4432",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4432.png",
     "id": 4432,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4433",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4433.png",
     "id": 4433,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4434",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4434.png",
     "id": 4434,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4435",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4435.png",
     "id": 4435,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4436",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4436.png",
     "id": 4436,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4437",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4437.png",
     "id": 4437,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4438",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4438.png",
     "id": 4438,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4439",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4439.png",
     "id": 4439,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4440",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4440.png",
     "id": 4440,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4441",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4441.png",
     "id": 4441,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4442",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4442.png",
     "id": 4442,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4443",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4443.png",
     "id": 4443,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4444",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4444.png",
     "id": 4444,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4445",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4445.png",
     "id": 4445,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4446",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4446.png",
     "id": 4446,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4447",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4447.png",
     "id": 4447,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4448",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4448.png",
     "id": 4448,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4449",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4449.png",
     "id": 4449,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4450",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4450.png",
     "id": 4450,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4451",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4451.png",
     "id": 4451,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4452",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4452.png",
     "id": 4452,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4453",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4453.png",
     "id": 4453,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4454",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4454.png",
     "id": 4454,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4455",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4455.png",
     "id": 4455,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4456",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4456.png",
     "id": 4456,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4457",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4457.png",
     "id": 4457,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4458",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4458.png",
     "id": 4458,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4459",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4459.png",
     "id": 4459,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4460",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4460.png",
     "id": 4460,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4461",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4461.png",
     "id": 4461,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4462",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4462.png",
     "id": 4462,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4463",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4463.png",
     "id": 4463,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4464",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4464.png",
     "id": 4464,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4465",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4465.png",
     "id": 4465,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4466",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4466.png",
     "id": 4466,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4467",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4467.png",
     "id": 4467,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #4468",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4468.png",
     "id": 4468,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4469",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4469.png",
     "id": 4469,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4470",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4470.png",
     "id": 4470,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4471",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4471.png",
     "id": 4471,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4472",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4472.png",
     "id": 4472,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4473",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4473.png",
     "id": 4473,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4474",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4474.png",
     "id": 4474,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4475",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4475.png",
     "id": 4475,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4476",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4476.png",
     "id": 4476,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4477",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4477.png",
     "id": 4477,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4478",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4478.png",
     "id": 4478,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4479",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4479.png",
     "id": 4479,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4480",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4480.png",
     "id": 4480,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4481",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4481.png",
     "id": 4481,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4482",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4482.png",
     "id": 4482,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4483",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4483.png",
     "id": 4483,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4484",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4484.png",
     "id": 4484,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4485",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4485.png",
     "id": 4485,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4486",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4486.png",
     "id": 4486,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4487",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4487.png",
     "id": 4487,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4488",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4488.png",
     "id": 4488,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4489",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4489.png",
     "id": 4489,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4490",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4490.png",
     "id": 4490,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4491",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4491.png",
     "id": 4491,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4492",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4492.png",
     "id": 4492,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4493",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4493.png",
     "id": 4493,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4494",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4494.png",
     "id": 4494,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4495",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4495.png",
     "id": 4495,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4496",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4496.png",
     "id": 4496,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4497",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4497.png",
     "id": 4497,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4498",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4498.png",
     "id": 4498,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4499",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4499.png",
     "id": 4499,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4500",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4500.png",
     "id": 4500,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4501",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4501.png",
     "id": 4501,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4502",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4502.png",
     "id": 4502,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4503",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4503.png",
     "id": 4503,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4504",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4504.png",
     "id": 4504,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4505",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4505.png",
     "id": 4505,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4506",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4506.png",
     "id": 4506,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4507",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4507.png",
     "id": 4507,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4508",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4508.png",
     "id": 4508,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4509",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4509.png",
     "id": 4509,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4510",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4510.png",
     "id": 4510,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4511",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4511.png",
     "id": 4511,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4512",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4512.png",
     "id": 4512,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4513",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4513.png",
     "id": 4513,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4514",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4514.png",
     "id": 4514,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4515",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4515.png",
     "id": 4515,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4516",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4516.png",
     "id": 4516,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4517",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4517.png",
     "id": 4517,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4518",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4518.png",
     "id": 4518,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4519",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4519.png",
     "id": 4519,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4520",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4520.png",
     "id": 4520,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4521",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4521.png",
     "id": 4521,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4522",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4522.png",
     "id": 4522,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4523",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4523.png",
     "id": 4523,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4524",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4524.png",
     "id": 4524,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4525",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4525.png",
     "id": 4525,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4526",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4526.png",
     "id": 4526,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4527",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4527.png",
     "id": 4527,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4528",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4528.png",
     "id": 4528,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4529",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4529.png",
     "id": 4529,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4530",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4530.png",
     "id": 4530,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4531",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4531.png",
     "id": 4531,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4532",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4532.png",
     "id": 4532,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4533",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4533.png",
     "id": 4533,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4534",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4534.png",
     "id": 4534,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4535",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4535.png",
     "id": 4535,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4536",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4536.png",
     "id": 4536,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4537",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4537.png",
     "id": 4537,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4538",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4538.png",
     "id": 4538,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4539",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4539.png",
     "id": 4539,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4540",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4540.png",
     "id": 4540,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4541",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4541.png",
     "id": 4541,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4542",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4542.png",
     "id": 4542,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4543",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4543.png",
     "id": 4543,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4544",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4544.png",
     "id": 4544,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4545",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4545.png",
     "id": 4545,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4546",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4546.png",
     "id": 4546,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4547",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4547.png",
     "id": 4547,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4548",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4548.png",
     "id": 4548,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4549",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4549.png",
     "id": 4549,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4550",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4550.png",
     "id": 4550,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4551",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4551.png",
     "id": 4551,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4552",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4552.png",
     "id": 4552,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4553",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4553.png",
     "id": 4553,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4554",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4554.png",
     "id": 4554,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4555",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4555.png",
     "id": 4555,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4556",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4556.png",
     "id": 4556,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4557",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4557.png",
     "id": 4557,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4558",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4558.png",
     "id": 4558,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4559",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4559.png",
     "id": 4559,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4560",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4560.png",
     "id": 4560,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4561",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4561.png",
     "id": 4561,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4562",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4562.png",
     "id": 4562,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4563",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4563.png",
     "id": 4563,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4564",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4564.png",
     "id": 4564,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4565",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4565.png",
     "id": 4565,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4566",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4566.png",
     "id": 4566,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4567",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4567.png",
     "id": 4567,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4568",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4568.png",
     "id": 4568,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4569",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4569.png",
     "id": 4569,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4570",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4570.png",
     "id": 4570,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4571",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4571.png",
     "id": 4571,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4572",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4572.png",
     "id": 4572,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Waka Waka"
      },
      {
       "trait_type": "Skin",
       "value": "Waka Waka"
      },
      {
       "trait_type": "Crypto",
       "value": "Waka Waka"
      },
      {
       "trait_type": "Activity",
       "value": "Waka Waka"
      },
      {
       "trait_type": "Outfit",
       "value": "Waka Waka"
      },
      {
       "trait_type": "Transportation",
       "value": "Waka Waka"
      },
      {
       "trait_type": "Head",
       "value": "Waka Waka"
      },
      {
       "trait_type": "Mouth",
       "value": "Waka Waka"
      },
      {
       "trait_type": "Eyes",
       "value": "Waka Waka"
      }
     ]
    },
    {
     "name": "Boomer Squad #4573",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4573.png",
     "id": 4573,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4574",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4574.png",
     "id": 4574,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4575",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4575.png",
     "id": 4575,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4576",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4576.png",
     "id": 4576,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4577",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4577.png",
     "id": 4577,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4578",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4578.png",
     "id": 4578,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4579",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4579.png",
     "id": 4579,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4580",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4580.png",
     "id": 4580,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4581",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4581.png",
     "id": 4581,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4582",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4582.png",
     "id": 4582,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4583",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4583.png",
     "id": 4583,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4584",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4584.png",
     "id": 4584,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4585",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4585.png",
     "id": 4585,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4586",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4586.png",
     "id": 4586,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4587",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4587.png",
     "id": 4587,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4588",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4588.png",
     "id": 4588,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4589",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4589.png",
     "id": 4589,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4590",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4590.png",
     "id": 4590,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4591",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4591.png",
     "id": 4591,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4592",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4592.png",
     "id": 4592,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4593",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4593.png",
     "id": 4593,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4594",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4594.png",
     "id": 4594,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4595",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4595.png",
     "id": 4595,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4596",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4596.png",
     "id": 4596,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4597",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4597.png",
     "id": 4597,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4598",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4598.png",
     "id": 4598,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4599",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4599.png",
     "id": 4599,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4600",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4600.png",
     "id": 4600,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4601",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4601.png",
     "id": 4601,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4602",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4602.png",
     "id": 4602,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4603",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4603.png",
     "id": 4603,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4604",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4604.png",
     "id": 4604,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4605",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4605.png",
     "id": 4605,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4606",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4606.png",
     "id": 4606,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4607",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4607.png",
     "id": 4607,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4608",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4608.png",
     "id": 4608,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4609",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4609.png",
     "id": 4609,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4610",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4610.png",
     "id": 4610,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4611",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4611.png",
     "id": 4611,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4612",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4612.png",
     "id": 4612,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4613",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4613.png",
     "id": 4613,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4614",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4614.png",
     "id": 4614,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4615",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4615.png",
     "id": 4615,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4616",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4616.png",
     "id": 4616,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4617",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4617.png",
     "id": 4617,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4618",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4618.png",
     "id": 4618,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4619",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4619.png",
     "id": 4619,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4620",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4620.png",
     "id": 4620,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4621",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4621.png",
     "id": 4621,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4622",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4622.png",
     "id": 4622,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4623",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4623.png",
     "id": 4623,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4624",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4624.png",
     "id": 4624,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4625",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4625.png",
     "id": 4625,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4626",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4626.png",
     "id": 4626,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4627",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4627.png",
     "id": 4627,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4628",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4628.png",
     "id": 4628,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4629",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4629.png",
     "id": 4629,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4630",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4630.png",
     "id": 4630,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4631",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4631.png",
     "id": 4631,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4632",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4632.png",
     "id": 4632,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4633",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4633.png",
     "id": 4633,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4634",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4634.png",
     "id": 4634,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4635",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4635.png",
     "id": 4635,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4636",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4636.png",
     "id": 4636,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4637",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4637.png",
     "id": 4637,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4638",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4638.png",
     "id": 4638,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4639",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4639.png",
     "id": 4639,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4640",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4640.png",
     "id": 4640,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4641",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4641.png",
     "id": 4641,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4642",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4642.png",
     "id": 4642,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4643",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4643.png",
     "id": 4643,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4644",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4644.png",
     "id": 4644,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4645",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4645.png",
     "id": 4645,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4646",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4646.png",
     "id": 4646,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4647",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4647.png",
     "id": 4647,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4648",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4648.png",
     "id": 4648,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4649",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4649.png",
     "id": 4649,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4650",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4650.png",
     "id": 4650,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4651",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4651.png",
     "id": 4651,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4652",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4652.png",
     "id": 4652,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4653",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4653.png",
     "id": 4653,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4654",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4654.png",
     "id": 4654,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4655",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4655.png",
     "id": 4655,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4656",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4656.png",
     "id": 4656,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4657",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4657.png",
     "id": 4657,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4658",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4658.png",
     "id": 4658,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4659",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4659.png",
     "id": 4659,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4660",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4660.png",
     "id": 4660,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4661",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4661.png",
     "id": 4661,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4662",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4662.png",
     "id": 4662,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4663",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4663.png",
     "id": 4663,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4664",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4664.png",
     "id": 4664,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4665",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4665.png",
     "id": 4665,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4666",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4666.png",
     "id": 4666,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4667",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4667.png",
     "id": 4667,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4668",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4668.png",
     "id": 4668,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4669",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4669.png",
     "id": 4669,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4670",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4670.png",
     "id": 4670,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4671",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4671.png",
     "id": 4671,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4672",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4672.png",
     "id": 4672,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4673",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4673.png",
     "id": 4673,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4674",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4674.png",
     "id": 4674,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4675",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4675.png",
     "id": 4675,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4676",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4676.png",
     "id": 4676,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4677",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4677.png",
     "id": 4677,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4678",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4678.png",
     "id": 4678,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4679",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4679.png",
     "id": 4679,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4680",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4680.png",
     "id": 4680,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4681",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4681.png",
     "id": 4681,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4682",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4682.png",
     "id": 4682,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4683",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4683.png",
     "id": 4683,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4684",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4684.png",
     "id": 4684,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4685",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4685.png",
     "id": 4685,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4686",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4686.png",
     "id": 4686,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4687",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4687.png",
     "id": 4687,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4688",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4688.png",
     "id": 4688,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4689",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4689.png",
     "id": 4689,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4690",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4690.png",
     "id": 4690,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4691",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4691.png",
     "id": 4691,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4692",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4692.png",
     "id": 4692,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4693",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4693.png",
     "id": 4693,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4694",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4694.png",
     "id": 4694,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4695",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4695.png",
     "id": 4695,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4696",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4696.png",
     "id": 4696,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4697",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4697.png",
     "id": 4697,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4698",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4698.png",
     "id": 4698,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4699",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4699.png",
     "id": 4699,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4700",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4700.png",
     "id": 4700,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4701",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4701.png",
     "id": 4701,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4702",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4702.png",
     "id": 4702,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4703",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4703.png",
     "id": 4703,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4704",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4704.png",
     "id": 4704,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4705",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4705.png",
     "id": 4705,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4706",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4706.png",
     "id": 4706,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4707",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4707.png",
     "id": 4707,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4708",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4708.png",
     "id": 4708,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4709",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4709.png",
     "id": 4709,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4710",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4710.png",
     "id": 4710,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4711",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4711.png",
     "id": 4711,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4712",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4712.png",
     "id": 4712,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4713",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4713.png",
     "id": 4713,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4714",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4714.png",
     "id": 4714,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4715",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4715.png",
     "id": 4715,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4716",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4716.png",
     "id": 4716,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4717",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4717.png",
     "id": 4717,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4718",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4718.png",
     "id": 4718,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4719",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4719.png",
     "id": 4719,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4720",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4720.png",
     "id": 4720,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4721",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4721.png",
     "id": 4721,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4722",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4722.png",
     "id": 4722,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4723",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4723.png",
     "id": 4723,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4724",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4724.png",
     "id": 4724,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4725",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4725.png",
     "id": 4725,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4726",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4726.png",
     "id": 4726,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4727",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4727.png",
     "id": 4727,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4728",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4728.png",
     "id": 4728,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4729",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4729.png",
     "id": 4729,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4730",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4730.png",
     "id": 4730,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4731",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4731.png",
     "id": 4731,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4732",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4732.png",
     "id": 4732,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4733",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4733.png",
     "id": 4733,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #4734",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4734.png",
     "id": 4734,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4735",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4735.png",
     "id": 4735,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4736",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4736.png",
     "id": 4736,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4737",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4737.png",
     "id": 4737,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4738",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4738.png",
     "id": 4738,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4739",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4739.png",
     "id": 4739,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4740",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4740.png",
     "id": 4740,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4741",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4741.png",
     "id": 4741,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4742",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4742.png",
     "id": 4742,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4743",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4743.png",
     "id": 4743,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4744",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4744.png",
     "id": 4744,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4745",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4745.png",
     "id": 4745,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4746",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4746.png",
     "id": 4746,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4747",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4747.png",
     "id": 4747,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4748",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4748.png",
     "id": 4748,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4749",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4749.png",
     "id": 4749,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4750",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4750.png",
     "id": 4750,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4751",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4751.png",
     "id": 4751,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4752",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4752.png",
     "id": 4752,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4753",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4753.png",
     "id": 4753,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4754",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4754.png",
     "id": 4754,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4755",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4755.png",
     "id": 4755,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4756",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4756.png",
     "id": 4756,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4757",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4757.png",
     "id": 4757,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4758",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4758.png",
     "id": 4758,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4759",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4759.png",
     "id": 4759,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4760",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4760.png",
     "id": 4760,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4761",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4761.png",
     "id": 4761,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4762",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4762.png",
     "id": 4762,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4763",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4763.png",
     "id": 4763,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4764",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4764.png",
     "id": 4764,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4765",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4765.png",
     "id": 4765,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4766",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4766.png",
     "id": 4766,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4767",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4767.png",
     "id": 4767,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4768",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4768.png",
     "id": 4768,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4769",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4769.png",
     "id": 4769,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4770",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4770.png",
     "id": 4770,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4771",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4771.png",
     "id": 4771,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4772",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4772.png",
     "id": 4772,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4773",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4773.png",
     "id": 4773,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4774",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4774.png",
     "id": 4774,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4775",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4775.png",
     "id": 4775,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4776",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4776.png",
     "id": 4776,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4777",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4777.png",
     "id": 4777,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4778",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4778.png",
     "id": 4778,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #4779",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4779.png",
     "id": 4779,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4780",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4780.png",
     "id": 4780,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4781",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4781.png",
     "id": 4781,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4782",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4782.png",
     "id": 4782,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4783",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4783.png",
     "id": 4783,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4784",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4784.png",
     "id": 4784,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4785",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4785.png",
     "id": 4785,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4786",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4786.png",
     "id": 4786,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4787",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4787.png",
     "id": 4787,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4788",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4788.png",
     "id": 4788,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4789",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4789.png",
     "id": 4789,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4790",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4790.png",
     "id": 4790,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4791",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4791.png",
     "id": 4791,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4792",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4792.png",
     "id": 4792,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4793",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4793.png",
     "id": 4793,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4794",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4794.png",
     "id": 4794,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4795",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4795.png",
     "id": 4795,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4796",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4796.png",
     "id": 4796,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4797",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4797.png",
     "id": 4797,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4798",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4798.png",
     "id": 4798,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4799",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4799.png",
     "id": 4799,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4800",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4800.png",
     "id": 4800,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4801",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4801.png",
     "id": 4801,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4802",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4802.png",
     "id": 4802,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4803",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4803.png",
     "id": 4803,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4804",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4804.png",
     "id": 4804,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4805",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4805.png",
     "id": 4805,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4806",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4806.png",
     "id": 4806,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4807",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4807.png",
     "id": 4807,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4808",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4808.png",
     "id": 4808,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4809",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4809.png",
     "id": 4809,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4810",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4810.png",
     "id": 4810,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4811",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4811.png",
     "id": 4811,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4812",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4812.png",
     "id": 4812,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4813",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4813.png",
     "id": 4813,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4814",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4814.png",
     "id": 4814,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4815",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4815.png",
     "id": 4815,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "El Hefe"
      },
      {
       "trait_type": "Skin",
       "value": "El Hefe"
      },
      {
       "trait_type": "Crypto",
       "value": "El Hefe"
      },
      {
       "trait_type": "Activity",
       "value": "El Hefe"
      },
      {
       "trait_type": "Outfit",
       "value": "El Hefe"
      },
      {
       "trait_type": "Transportation",
       "value": "El Hefe"
      },
      {
       "trait_type": "Head",
       "value": "El Hefe"
      },
      {
       "trait_type": "Mouth",
       "value": "El Hefe"
      },
      {
       "trait_type": "Eyes",
       "value": "El Hefe"
      }
     ]
    },
    {
     "name": "Boomer Squad #4816",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4816.png",
     "id": 4816,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4817",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4817.png",
     "id": 4817,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4818",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4818.png",
     "id": 4818,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4819",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4819.png",
     "id": 4819,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4820",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4820.png",
     "id": 4820,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4821",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4821.png",
     "id": 4821,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4822",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4822.png",
     "id": 4822,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4823",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4823.png",
     "id": 4823,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4824",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4824.png",
     "id": 4824,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4825",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4825.png",
     "id": 4825,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4826",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4826.png",
     "id": 4826,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4827",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4827.png",
     "id": 4827,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4828",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4828.png",
     "id": 4828,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4829",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4829.png",
     "id": 4829,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4830",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4830.png",
     "id": 4830,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4831",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4831.png",
     "id": 4831,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4832",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4832.png",
     "id": 4832,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4833",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4833.png",
     "id": 4833,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4834",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4834.png",
     "id": 4834,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4835",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4835.png",
     "id": 4835,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4836",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4836.png",
     "id": 4836,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4837",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4837.png",
     "id": 4837,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4838",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4838.png",
     "id": 4838,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4839",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4839.png",
     "id": 4839,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4840",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4840.png",
     "id": 4840,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4841",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4841.png",
     "id": 4841,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4842",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4842.png",
     "id": 4842,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4843",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4843.png",
     "id": 4843,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4844",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4844.png",
     "id": 4844,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #4845",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4845.png",
     "id": 4845,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4846",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4846.png",
     "id": 4846,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4847",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4847.png",
     "id": 4847,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4848",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4848.png",
     "id": 4848,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4849",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4849.png",
     "id": 4849,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4850",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4850.png",
     "id": 4850,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4851",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4851.png",
     "id": 4851,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4852",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4852.png",
     "id": 4852,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4853",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4853.png",
     "id": 4853,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4854",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4854.png",
     "id": 4854,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4855",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4855.png",
     "id": 4855,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4856",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4856.png",
     "id": 4856,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4857",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4857.png",
     "id": 4857,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4858",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4858.png",
     "id": 4858,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4859",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4859.png",
     "id": 4859,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4860",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4860.png",
     "id": 4860,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4861",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4861.png",
     "id": 4861,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4862",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4862.png",
     "id": 4862,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4863",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4863.png",
     "id": 4863,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4864",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4864.png",
     "id": 4864,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4865",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4865.png",
     "id": 4865,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4866",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4866.png",
     "id": 4866,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4867",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4867.png",
     "id": 4867,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4868",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4868.png",
     "id": 4868,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4869",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4869.png",
     "id": 4869,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4870",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4870.png",
     "id": 4870,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4871",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4871.png",
     "id": 4871,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4872",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4872.png",
     "id": 4872,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4873",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4873.png",
     "id": 4873,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4874",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4874.png",
     "id": 4874,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4875",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4875.png",
     "id": 4875,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4876",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4876.png",
     "id": 4876,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4877",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4877.png",
     "id": 4877,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4878",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4878.png",
     "id": 4878,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4879",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4879.png",
     "id": 4879,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4880",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4880.png",
     "id": 4880,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4881",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4881.png",
     "id": 4881,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4882",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4882.png",
     "id": 4882,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4883",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4883.png",
     "id": 4883,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4884",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4884.png",
     "id": 4884,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4885",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4885.png",
     "id": 4885,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4886",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4886.png",
     "id": 4886,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4887",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4887.png",
     "id": 4887,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4888",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4888.png",
     "id": 4888,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4889",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4889.png",
     "id": 4889,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4890",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4890.png",
     "id": 4890,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4891",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4891.png",
     "id": 4891,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4892",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4892.png",
     "id": 4892,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4893",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4893.png",
     "id": 4893,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4894",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4894.png",
     "id": 4894,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4895",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4895.png",
     "id": 4895,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4896",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4896.png",
     "id": 4896,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4897",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4897.png",
     "id": 4897,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4898",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4898.png",
     "id": 4898,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #4899",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4899.png",
     "id": 4899,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4900",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4900.png",
     "id": 4900,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4901",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4901.png",
     "id": 4901,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4902",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4902.png",
     "id": 4902,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4903",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4903.png",
     "id": 4903,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4904",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4904.png",
     "id": 4904,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4905",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4905.png",
     "id": 4905,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4906",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4906.png",
     "id": 4906,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4907",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4907.png",
     "id": 4907,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4908",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4908.png",
     "id": 4908,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4909",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4909.png",
     "id": 4909,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4910",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4910.png",
     "id": 4910,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4911",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4911.png",
     "id": 4911,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4912",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4912.png",
     "id": 4912,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4913",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4913.png",
     "id": 4913,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4914",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4914.png",
     "id": 4914,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4915",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4915.png",
     "id": 4915,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4916",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4916.png",
     "id": 4916,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4917",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4917.png",
     "id": 4917,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4918",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4918.png",
     "id": 4918,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4919",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4919.png",
     "id": 4919,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4920",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4920.png",
     "id": 4920,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4921",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4921.png",
     "id": 4921,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #4922",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4922.png",
     "id": 4922,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4923",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4923.png",
     "id": 4923,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4924",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4924.png",
     "id": 4924,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4925",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4925.png",
     "id": 4925,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4926",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4926.png",
     "id": 4926,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #4927",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4927.png",
     "id": 4927,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #4928",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4928.png",
     "id": 4928,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4929",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4929.png",
     "id": 4929,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4930",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4930.png",
     "id": 4930,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4931",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4931.png",
     "id": 4931,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4932",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4932.png",
     "id": 4932,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4933",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4933.png",
     "id": 4933,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4934",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4934.png",
     "id": 4934,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4935",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4935.png",
     "id": 4935,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4936",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4936.png",
     "id": 4936,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4937",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4937.png",
     "id": 4937,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4938",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4938.png",
     "id": 4938,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4939",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4939.png",
     "id": 4939,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4940",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4940.png",
     "id": 4940,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4941",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4941.png",
     "id": 4941,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4942",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4942.png",
     "id": 4942,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4943",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4943.png",
     "id": 4943,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4944",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4944.png",
     "id": 4944,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4945",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4945.png",
     "id": 4945,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4946",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4946.png",
     "id": 4946,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4947",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4947.png",
     "id": 4947,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4948",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4948.png",
     "id": 4948,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4949",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4949.png",
     "id": 4949,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4950",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4950.png",
     "id": 4950,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4951",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4951.png",
     "id": 4951,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4952",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4952.png",
     "id": 4952,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4953",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4953.png",
     "id": 4953,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4954",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4954.png",
     "id": 4954,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #4955",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4955.png",
     "id": 4955,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4956",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4956.png",
     "id": 4956,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4957",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4957.png",
     "id": 4957,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #4958",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4958.png",
     "id": 4958,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4959",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4959.png",
     "id": 4959,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4960",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4960.png",
     "id": 4960,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4961",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4961.png",
     "id": 4961,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4962",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4962.png",
     "id": 4962,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4963",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4963.png",
     "id": 4963,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4964",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4964.png",
     "id": 4964,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4965",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4965.png",
     "id": 4965,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4966",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4966.png",
     "id": 4966,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4967",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4967.png",
     "id": 4967,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #4968",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4968.png",
     "id": 4968,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4969",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4969.png",
     "id": 4969,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #4970",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4970.png",
     "id": 4970,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #4971",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4971.png",
     "id": 4971,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4972",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4972.png",
     "id": 4972,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4973",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4973.png",
     "id": 4973,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #4974",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4974.png",
     "id": 4974,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4975",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4975.png",
     "id": 4975,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4976",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4976.png",
     "id": 4976,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4977",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4977.png",
     "id": 4977,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4978",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4978.png",
     "id": 4978,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #4979",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4979.png",
     "id": 4979,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #4980",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4980.png",
     "id": 4980,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #4981",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4981.png",
     "id": 4981,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4982",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4982.png",
     "id": 4982,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4983",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4983.png",
     "id": 4983,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #4984",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4984.png",
     "id": 4984,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4985",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4985.png",
     "id": 4985,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #4986",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4986.png",
     "id": 4986,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #4987",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4987.png",
     "id": 4987,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4988",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4988.png",
     "id": 4988,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #4989",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4989.png",
     "id": 4989,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #4990",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4990.png",
     "id": 4990,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4991",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4991.png",
     "id": 4991,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4992",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4992.png",
     "id": 4992,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #4993",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4993.png",
     "id": 4993,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4994",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4994.png",
     "id": 4994,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #4995",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4995.png",
     "id": 4995,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #4996",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4996.png",
     "id": 4996,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #4997",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4997.png",
     "id": 4997,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #4998",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4998.png",
     "id": 4998,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #4999",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/4999.png",
     "id": 4999,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5000",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5000.png",
     "id": 5000,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5001",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5001.png",
     "id": 5001,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5002",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5002.png",
     "id": 5002,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5003",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5003.png",
     "id": 5003,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5004",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5004.png",
     "id": 5004,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5005",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5005.png",
     "id": 5005,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5006",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5006.png",
     "id": 5006,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5007",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5007.png",
     "id": 5007,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5008",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5008.png",
     "id": 5008,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5009",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5009.png",
     "id": 5009,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5010",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5010.png",
     "id": 5010,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5011",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5011.png",
     "id": 5011,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5012",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5012.png",
     "id": 5012,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5013",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5013.png",
     "id": 5013,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5014",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5014.png",
     "id": 5014,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5015",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5015.png",
     "id": 5015,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5016",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5016.png",
     "id": 5016,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5017",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5017.png",
     "id": 5017,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5018",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5018.png",
     "id": 5018,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5019",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5019.png",
     "id": 5019,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5020",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5020.png",
     "id": 5020,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5021",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5021.png",
     "id": 5021,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5022",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5022.png",
     "id": 5022,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5023",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5023.png",
     "id": 5023,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5024",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5024.png",
     "id": 5024,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5025",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5025.png",
     "id": 5025,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5026",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5026.png",
     "id": 5026,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5027",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5027.png",
     "id": 5027,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5028",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5028.png",
     "id": 5028,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5029",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5029.png",
     "id": 5029,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5030",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5030.png",
     "id": 5030,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5031",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5031.png",
     "id": 5031,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5032",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5032.png",
     "id": 5032,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5033",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5033.png",
     "id": 5033,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5034",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5034.png",
     "id": 5034,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5035",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5035.png",
     "id": 5035,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5036",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5036.png",
     "id": 5036,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5037",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5037.png",
     "id": 5037,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5038",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5038.png",
     "id": 5038,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5039",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5039.png",
     "id": 5039,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5040",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5040.png",
     "id": 5040,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5041",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5041.png",
     "id": 5041,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5042",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5042.png",
     "id": 5042,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5043",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5043.png",
     "id": 5043,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5044",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5044.png",
     "id": 5044,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5045",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5045.png",
     "id": 5045,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5046",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5046.png",
     "id": 5046,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5047",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5047.png",
     "id": 5047,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5048",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5048.png",
     "id": 5048,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5049",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5049.png",
     "id": 5049,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5050",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5050.png",
     "id": 5050,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5051",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5051.png",
     "id": 5051,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5052",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5052.png",
     "id": 5052,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5053",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5053.png",
     "id": 5053,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5054",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5054.png",
     "id": 5054,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5055",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5055.png",
     "id": 5055,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5056",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5056.png",
     "id": 5056,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5057",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5057.png",
     "id": 5057,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5058",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5058.png",
     "id": 5058,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5059",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5059.png",
     "id": 5059,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5060",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5060.png",
     "id": 5060,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5061",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5061.png",
     "id": 5061,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5062",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5062.png",
     "id": 5062,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5063",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5063.png",
     "id": 5063,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5064",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5064.png",
     "id": 5064,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5065",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5065.png",
     "id": 5065,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5066",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5066.png",
     "id": 5066,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5067",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5067.png",
     "id": 5067,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5068",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5068.png",
     "id": 5068,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5069",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5069.png",
     "id": 5069,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5070",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5070.png",
     "id": 5070,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5071",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5071.png",
     "id": 5071,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #5072",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5072.png",
     "id": 5072,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5073",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5073.png",
     "id": 5073,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5074",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5074.png",
     "id": 5074,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5075",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5075.png",
     "id": 5075,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5076",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5076.png",
     "id": 5076,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5077",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5077.png",
     "id": 5077,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5078",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5078.png",
     "id": 5078,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5079",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5079.png",
     "id": 5079,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5080",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5080.png",
     "id": 5080,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5081",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5081.png",
     "id": 5081,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5082",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5082.png",
     "id": 5082,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5083",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5083.png",
     "id": 5083,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5084",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5084.png",
     "id": 5084,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5085",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5085.png",
     "id": 5085,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5086",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5086.png",
     "id": 5086,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5087",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5087.png",
     "id": 5087,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5088",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5088.png",
     "id": 5088,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5089",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5089.png",
     "id": 5089,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5090",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5090.png",
     "id": 5090,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5091",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5091.png",
     "id": 5091,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5092",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5092.png",
     "id": 5092,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5093",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5093.png",
     "id": 5093,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5094",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5094.png",
     "id": 5094,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5095",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5095.png",
     "id": 5095,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5096",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5096.png",
     "id": 5096,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5097",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5097.png",
     "id": 5097,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5098",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5098.png",
     "id": 5098,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5099",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5099.png",
     "id": 5099,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5100",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5100.png",
     "id": 5100,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5101",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5101.png",
     "id": 5101,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5102",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5102.png",
     "id": 5102,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5103",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5103.png",
     "id": 5103,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5104",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5104.png",
     "id": 5104,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5105",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5105.png",
     "id": 5105,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5106",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5106.png",
     "id": 5106,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #5107",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5107.png",
     "id": 5107,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5108",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5108.png",
     "id": 5108,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5109",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5109.png",
     "id": 5109,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5110",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5110.png",
     "id": 5110,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5111",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5111.png",
     "id": 5111,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5112",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5112.png",
     "id": 5112,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5113",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5113.png",
     "id": 5113,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5114",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5114.png",
     "id": 5114,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5115",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5115.png",
     "id": 5115,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5116",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5116.png",
     "id": 5116,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5117",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5117.png",
     "id": 5117,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5118",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5118.png",
     "id": 5118,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5119",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5119.png",
     "id": 5119,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5120",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5120.png",
     "id": 5120,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5121",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5121.png",
     "id": 5121,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5122",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5122.png",
     "id": 5122,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5123",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5123.png",
     "id": 5123,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5124",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5124.png",
     "id": 5124,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5125",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5125.png",
     "id": 5125,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5126",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5126.png",
     "id": 5126,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5127",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5127.png",
     "id": 5127,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5128",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5128.png",
     "id": 5128,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5129",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5129.png",
     "id": 5129,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5130",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5130.png",
     "id": 5130,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5131",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5131.png",
     "id": 5131,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5132",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5132.png",
     "id": 5132,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5133",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5133.png",
     "id": 5133,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5134",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5134.png",
     "id": 5134,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5135",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5135.png",
     "id": 5135,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5136",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5136.png",
     "id": 5136,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5137",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5137.png",
     "id": 5137,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5138",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5138.png",
     "id": 5138,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5139",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5139.png",
     "id": 5139,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5140",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5140.png",
     "id": 5140,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5141",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5141.png",
     "id": 5141,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5142",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5142.png",
     "id": 5142,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5143",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5143.png",
     "id": 5143,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5144",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5144.png",
     "id": 5144,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5145",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5145.png",
     "id": 5145,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5146",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5146.png",
     "id": 5146,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5147",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5147.png",
     "id": 5147,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5148",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5148.png",
     "id": 5148,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5149",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5149.png",
     "id": 5149,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5150",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5150.png",
     "id": 5150,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5151",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5151.png",
     "id": 5151,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5152",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5152.png",
     "id": 5152,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5153",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5153.png",
     "id": 5153,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5154",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5154.png",
     "id": 5154,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5155",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5155.png",
     "id": 5155,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5156",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5156.png",
     "id": 5156,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5157",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5157.png",
     "id": 5157,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5158",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5158.png",
     "id": 5158,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5159",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5159.png",
     "id": 5159,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5160",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5160.png",
     "id": 5160,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5161",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5161.png",
     "id": 5161,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5162",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5162.png",
     "id": 5162,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5163",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5163.png",
     "id": 5163,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5164",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5164.png",
     "id": 5164,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5165",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5165.png",
     "id": 5165,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5166",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5166.png",
     "id": 5166,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5167",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5167.png",
     "id": 5167,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5168",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5168.png",
     "id": 5168,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #5169",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5169.png",
     "id": 5169,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5170",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5170.png",
     "id": 5170,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5171",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5171.png",
     "id": 5171,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5172",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5172.png",
     "id": 5172,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5173",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5173.png",
     "id": 5173,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5174",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5174.png",
     "id": 5174,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5175",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5175.png",
     "id": 5175,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5176",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5176.png",
     "id": 5176,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5177",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5177.png",
     "id": 5177,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5178",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5178.png",
     "id": 5178,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5179",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5179.png",
     "id": 5179,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5180",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5180.png",
     "id": 5180,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5181",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5181.png",
     "id": 5181,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5182",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5182.png",
     "id": 5182,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5183",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5183.png",
     "id": 5183,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5184",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5184.png",
     "id": 5184,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5185",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5185.png",
     "id": 5185,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5186",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5186.png",
     "id": 5186,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5187",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5187.png",
     "id": 5187,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5188",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5188.png",
     "id": 5188,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5189",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5189.png",
     "id": 5189,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5190",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5190.png",
     "id": 5190,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5191",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5191.png",
     "id": 5191,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5192",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5192.png",
     "id": 5192,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5193",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5193.png",
     "id": 5193,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5194",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5194.png",
     "id": 5194,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5195",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5195.png",
     "id": 5195,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5196",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5196.png",
     "id": 5196,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5197",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5197.png",
     "id": 5197,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5198",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5198.png",
     "id": 5198,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5199",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5199.png",
     "id": 5199,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5200",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5200.png",
     "id": 5200,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5201",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5201.png",
     "id": 5201,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5202",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5202.png",
     "id": 5202,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5203",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5203.png",
     "id": 5203,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5204",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5204.png",
     "id": 5204,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5205",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5205.png",
     "id": 5205,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5206",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5206.png",
     "id": 5206,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5207",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5207.png",
     "id": 5207,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5208",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5208.png",
     "id": 5208,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5209",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5209.png",
     "id": 5209,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Mahou Shoujo"
      },
      {
       "trait_type": "Skin",
       "value": "Mahou Shoujo"
      },
      {
       "trait_type": "Crypto",
       "value": "Mahou Shoujo"
      },
      {
       "trait_type": "Activity",
       "value": "Mahou Shoujo"
      },
      {
       "trait_type": "Outfit",
       "value": "Mahou Shoujo"
      },
      {
       "trait_type": "Transportation",
       "value": "Mahou Shoujo"
      },
      {
       "trait_type": "Head",
       "value": "Mahou Shoujo"
      },
      {
       "trait_type": "Mouth",
       "value": "Mahou Shoujo"
      },
      {
       "trait_type": "Eyes",
       "value": "Mahou Shoujo"
      }
     ]
    },
    {
     "name": "Boomer Squad #5210",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5210.png",
     "id": 5210,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5211",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5211.png",
     "id": 5211,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5212",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5212.png",
     "id": 5212,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5213",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5213.png",
     "id": 5213,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5214",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5214.png",
     "id": 5214,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5215",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5215.png",
     "id": 5215,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5216",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5216.png",
     "id": 5216,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5217",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5217.png",
     "id": 5217,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5218",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5218.png",
     "id": 5218,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5219",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5219.png",
     "id": 5219,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5220",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5220.png",
     "id": 5220,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5221",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5221.png",
     "id": 5221,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5222",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5222.png",
     "id": 5222,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5223",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5223.png",
     "id": 5223,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5224",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5224.png",
     "id": 5224,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5225",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5225.png",
     "id": 5225,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5226",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5226.png",
     "id": 5226,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5227",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5227.png",
     "id": 5227,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5228",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5228.png",
     "id": 5228,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5229",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5229.png",
     "id": 5229,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5230",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5230.png",
     "id": 5230,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5231",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5231.png",
     "id": 5231,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5232",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5232.png",
     "id": 5232,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5233",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5233.png",
     "id": 5233,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5234",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5234.png",
     "id": 5234,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5235",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5235.png",
     "id": 5235,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5236",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5236.png",
     "id": 5236,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5237",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5237.png",
     "id": 5237,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5238",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5238.png",
     "id": 5238,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5239",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5239.png",
     "id": 5239,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5240",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5240.png",
     "id": 5240,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5241",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5241.png",
     "id": 5241,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5242",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5242.png",
     "id": 5242,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5243",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5243.png",
     "id": 5243,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5244",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5244.png",
     "id": 5244,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5245",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5245.png",
     "id": 5245,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5246",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5246.png",
     "id": 5246,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5247",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5247.png",
     "id": 5247,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5248",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5248.png",
     "id": 5248,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5249",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5249.png",
     "id": 5249,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5250",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5250.png",
     "id": 5250,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5251",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5251.png",
     "id": 5251,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5252",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5252.png",
     "id": 5252,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5253",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5253.png",
     "id": 5253,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5254",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5254.png",
     "id": 5254,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5255",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5255.png",
     "id": 5255,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5256",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5256.png",
     "id": 5256,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5257",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5257.png",
     "id": 5257,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5258",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5258.png",
     "id": 5258,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5259",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5259.png",
     "id": 5259,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5260",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5260.png",
     "id": 5260,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5261",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5261.png",
     "id": 5261,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5262",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5262.png",
     "id": 5262,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5263",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5263.png",
     "id": 5263,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5264",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5264.png",
     "id": 5264,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5265",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5265.png",
     "id": 5265,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5266",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5266.png",
     "id": 5266,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5267",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5267.png",
     "id": 5267,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5268",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5268.png",
     "id": 5268,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5269",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5269.png",
     "id": 5269,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5270",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5270.png",
     "id": 5270,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5271",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5271.png",
     "id": 5271,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #5272",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5272.png",
     "id": 5272,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5273",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5273.png",
     "id": 5273,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5274",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5274.png",
     "id": 5274,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5275",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5275.png",
     "id": 5275,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5276",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5276.png",
     "id": 5276,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5277",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5277.png",
     "id": 5277,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5278",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5278.png",
     "id": 5278,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5279",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5279.png",
     "id": 5279,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5280",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5280.png",
     "id": 5280,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5281",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5281.png",
     "id": 5281,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5282",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5282.png",
     "id": 5282,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5283",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5283.png",
     "id": 5283,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5284",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5284.png",
     "id": 5284,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5285",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5285.png",
     "id": 5285,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5286",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5286.png",
     "id": 5286,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5287",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5287.png",
     "id": 5287,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5288",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5288.png",
     "id": 5288,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5289",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5289.png",
     "id": 5289,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5290",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5290.png",
     "id": 5290,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5291",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5291.png",
     "id": 5291,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5292",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5292.png",
     "id": 5292,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5293",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5293.png",
     "id": 5293,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5294",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5294.png",
     "id": 5294,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5295",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5295.png",
     "id": 5295,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5296",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5296.png",
     "id": 5296,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5297",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5297.png",
     "id": 5297,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5298",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5298.png",
     "id": 5298,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5299",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5299.png",
     "id": 5299,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5300",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5300.png",
     "id": 5300,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5301",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5301.png",
     "id": 5301,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5302",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5302.png",
     "id": 5302,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5303",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5303.png",
     "id": 5303,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5304",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5304.png",
     "id": 5304,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5305",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5305.png",
     "id": 5305,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5306",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5306.png",
     "id": 5306,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5307",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5307.png",
     "id": 5307,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5308",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5308.png",
     "id": 5308,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5309",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5309.png",
     "id": 5309,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5310",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5310.png",
     "id": 5310,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5311",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5311.png",
     "id": 5311,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5312",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5312.png",
     "id": 5312,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5313",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5313.png",
     "id": 5313,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5314",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5314.png",
     "id": 5314,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5315",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5315.png",
     "id": 5315,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5316",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5316.png",
     "id": 5316,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5317",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5317.png",
     "id": 5317,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5318",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5318.png",
     "id": 5318,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5319",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5319.png",
     "id": 5319,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5320",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5320.png",
     "id": 5320,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5321",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5321.png",
     "id": 5321,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5322",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5322.png",
     "id": 5322,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5323",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5323.png",
     "id": 5323,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5324",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5324.png",
     "id": 5324,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5325",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5325.png",
     "id": 5325,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5326",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5326.png",
     "id": 5326,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5327",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5327.png",
     "id": 5327,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5328",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5328.png",
     "id": 5328,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5329",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5329.png",
     "id": 5329,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5330",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5330.png",
     "id": 5330,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5331",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5331.png",
     "id": 5331,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5332",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5332.png",
     "id": 5332,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5333",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5333.png",
     "id": 5333,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5334",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5334.png",
     "id": 5334,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5335",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5335.png",
     "id": 5335,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5336",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5336.png",
     "id": 5336,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5337",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5337.png",
     "id": 5337,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5338",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5338.png",
     "id": 5338,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5339",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5339.png",
     "id": 5339,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5340",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5340.png",
     "id": 5340,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5341",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5341.png",
     "id": 5341,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5342",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5342.png",
     "id": 5342,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5343",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5343.png",
     "id": 5343,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5344",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5344.png",
     "id": 5344,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5345",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5345.png",
     "id": 5345,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5346",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5346.png",
     "id": 5346,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5347",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5347.png",
     "id": 5347,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5348",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5348.png",
     "id": 5348,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5349",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5349.png",
     "id": 5349,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5350",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5350.png",
     "id": 5350,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5351",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5351.png",
     "id": 5351,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5352",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5352.png",
     "id": 5352,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5353",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5353.png",
     "id": 5353,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5354",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5354.png",
     "id": 5354,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5355",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5355.png",
     "id": 5355,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5356",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5356.png",
     "id": 5356,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5357",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5357.png",
     "id": 5357,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5358",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5358.png",
     "id": 5358,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5359",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5359.png",
     "id": 5359,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5360",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5360.png",
     "id": 5360,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5361",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5361.png",
     "id": 5361,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5362",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5362.png",
     "id": 5362,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5363",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5363.png",
     "id": 5363,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5364",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5364.png",
     "id": 5364,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5365",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5365.png",
     "id": 5365,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5366",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5366.png",
     "id": 5366,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5367",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5367.png",
     "id": 5367,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5368",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5368.png",
     "id": 5368,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5369",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5369.png",
     "id": 5369,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5370",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5370.png",
     "id": 5370,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5371",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5371.png",
     "id": 5371,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5372",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5372.png",
     "id": 5372,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5373",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5373.png",
     "id": 5373,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5374",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5374.png",
     "id": 5374,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5375",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5375.png",
     "id": 5375,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5376",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5376.png",
     "id": 5376,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #5377",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5377.png",
     "id": 5377,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5378",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5378.png",
     "id": 5378,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5379",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5379.png",
     "id": 5379,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5380",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5380.png",
     "id": 5380,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5381",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5381.png",
     "id": 5381,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5382",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5382.png",
     "id": 5382,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5383",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5383.png",
     "id": 5383,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5384",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5384.png",
     "id": 5384,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5385",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5385.png",
     "id": 5385,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5386",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5386.png",
     "id": 5386,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5387",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5387.png",
     "id": 5387,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5388",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5388.png",
     "id": 5388,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5389",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5389.png",
     "id": 5389,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5390",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5390.png",
     "id": 5390,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5391",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5391.png",
     "id": 5391,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5392",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5392.png",
     "id": 5392,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5393",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5393.png",
     "id": 5393,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5394",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5394.png",
     "id": 5394,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5395",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5395.png",
     "id": 5395,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5396",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5396.png",
     "id": 5396,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5397",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5397.png",
     "id": 5397,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5398",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5398.png",
     "id": 5398,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5399",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5399.png",
     "id": 5399,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5400",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5400.png",
     "id": 5400,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5401",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5401.png",
     "id": 5401,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5402",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5402.png",
     "id": 5402,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5403",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5403.png",
     "id": 5403,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5404",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5404.png",
     "id": 5404,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5405",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5405.png",
     "id": 5405,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5406",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5406.png",
     "id": 5406,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5407",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5407.png",
     "id": 5407,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5408",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5408.png",
     "id": 5408,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5409",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5409.png",
     "id": 5409,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5410",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5410.png",
     "id": 5410,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5411",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5411.png",
     "id": 5411,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5412",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5412.png",
     "id": 5412,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5413",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5413.png",
     "id": 5413,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5414",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5414.png",
     "id": 5414,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5415",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5415.png",
     "id": 5415,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5416",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5416.png",
     "id": 5416,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5417",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5417.png",
     "id": 5417,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5418",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5418.png",
     "id": 5418,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5419",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5419.png",
     "id": 5419,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5420",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5420.png",
     "id": 5420,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5421",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5421.png",
     "id": 5421,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5422",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5422.png",
     "id": 5422,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5423",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5423.png",
     "id": 5423,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5424",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5424.png",
     "id": 5424,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5425",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5425.png",
     "id": 5425,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5426",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5426.png",
     "id": 5426,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5427",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5427.png",
     "id": 5427,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5428",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5428.png",
     "id": 5428,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5429",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5429.png",
     "id": 5429,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5430",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5430.png",
     "id": 5430,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5431",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5431.png",
     "id": 5431,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5432",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5432.png",
     "id": 5432,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5433",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5433.png",
     "id": 5433,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5434",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5434.png",
     "id": 5434,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5435",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5435.png",
     "id": 5435,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5436",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5436.png",
     "id": 5436,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5437",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5437.png",
     "id": 5437,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5438",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5438.png",
     "id": 5438,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5439",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5439.png",
     "id": 5439,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5440",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5440.png",
     "id": 5440,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5441",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5441.png",
     "id": 5441,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5442",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5442.png",
     "id": 5442,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5443",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5443.png",
     "id": 5443,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5444",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5444.png",
     "id": 5444,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5445",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5445.png",
     "id": 5445,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5446",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5446.png",
     "id": 5446,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5447",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5447.png",
     "id": 5447,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5448",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5448.png",
     "id": 5448,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5449",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5449.png",
     "id": 5449,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5450",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5450.png",
     "id": 5450,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5451",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5451.png",
     "id": 5451,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5452",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5452.png",
     "id": 5452,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5453",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5453.png",
     "id": 5453,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5454",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5454.png",
     "id": 5454,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #5455",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5455.png",
     "id": 5455,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5456",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5456.png",
     "id": 5456,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5457",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5457.png",
     "id": 5457,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5458",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5458.png",
     "id": 5458,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5459",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5459.png",
     "id": 5459,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5460",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5460.png",
     "id": 5460,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5461",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5461.png",
     "id": 5461,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5462",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5462.png",
     "id": 5462,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5463",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5463.png",
     "id": 5463,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5464",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5464.png",
     "id": 5464,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5465",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5465.png",
     "id": 5465,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5466",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5466.png",
     "id": 5466,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5467",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5467.png",
     "id": 5467,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5468",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5468.png",
     "id": 5468,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5469",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5469.png",
     "id": 5469,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5470",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5470.png",
     "id": 5470,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5471",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5471.png",
     "id": 5471,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5472",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5472.png",
     "id": 5472,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5473",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5473.png",
     "id": 5473,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5474",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5474.png",
     "id": 5474,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5475",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5475.png",
     "id": 5475,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5476",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5476.png",
     "id": 5476,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5477",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5477.png",
     "id": 5477,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #5478",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5478.png",
     "id": 5478,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5479",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5479.png",
     "id": 5479,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5480",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5480.png",
     "id": 5480,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5481",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5481.png",
     "id": 5481,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5482",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5482.png",
     "id": 5482,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5483",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5483.png",
     "id": 5483,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5484",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5484.png",
     "id": 5484,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5485",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5485.png",
     "id": 5485,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5486",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5486.png",
     "id": 5486,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5487",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5487.png",
     "id": 5487,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5488",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5488.png",
     "id": 5488,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5489",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5489.png",
     "id": 5489,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5490",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5490.png",
     "id": 5490,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5491",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5491.png",
     "id": 5491,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5492",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5492.png",
     "id": 5492,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5493",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5493.png",
     "id": 5493,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5494",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5494.png",
     "id": 5494,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5495",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5495.png",
     "id": 5495,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5496",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5496.png",
     "id": 5496,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5497",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5497.png",
     "id": 5497,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5498",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5498.png",
     "id": 5498,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5499",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5499.png",
     "id": 5499,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5500",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5500.png",
     "id": 5500,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5501",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5501.png",
     "id": 5501,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5502",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5502.png",
     "id": 5502,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5503",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5503.png",
     "id": 5503,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5504",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5504.png",
     "id": 5504,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5505",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5505.png",
     "id": 5505,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5506",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5506.png",
     "id": 5506,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5507",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5507.png",
     "id": 5507,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5508",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5508.png",
     "id": 5508,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5509",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5509.png",
     "id": 5509,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5510",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5510.png",
     "id": 5510,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5511",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5511.png",
     "id": 5511,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5512",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5512.png",
     "id": 5512,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5513",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5513.png",
     "id": 5513,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5514",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5514.png",
     "id": 5514,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5515",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5515.png",
     "id": 5515,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5516",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5516.png",
     "id": 5516,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5517",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5517.png",
     "id": 5517,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5518",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5518.png",
     "id": 5518,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5519",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5519.png",
     "id": 5519,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5520",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5520.png",
     "id": 5520,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5521",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5521.png",
     "id": 5521,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5522",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5522.png",
     "id": 5522,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5523",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5523.png",
     "id": 5523,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5524",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5524.png",
     "id": 5524,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5525",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5525.png",
     "id": 5525,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5526",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5526.png",
     "id": 5526,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5527",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5527.png",
     "id": 5527,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5528",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5528.png",
     "id": 5528,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5529",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5529.png",
     "id": 5529,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5530",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5530.png",
     "id": 5530,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5531",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5531.png",
     "id": 5531,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5532",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5532.png",
     "id": 5532,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5533",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5533.png",
     "id": 5533,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5534",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5534.png",
     "id": 5534,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5535",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5535.png",
     "id": 5535,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5536",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5536.png",
     "id": 5536,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5537",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5537.png",
     "id": 5537,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5538",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5538.png",
     "id": 5538,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5539",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5539.png",
     "id": 5539,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5540",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5540.png",
     "id": 5540,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5541",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5541.png",
     "id": 5541,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5542",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5542.png",
     "id": 5542,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5543",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5543.png",
     "id": 5543,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5544",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5544.png",
     "id": 5544,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5545",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5545.png",
     "id": 5545,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5546",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5546.png",
     "id": 5546,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5547",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5547.png",
     "id": 5547,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5548",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5548.png",
     "id": 5548,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5549",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5549.png",
     "id": 5549,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5550",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5550.png",
     "id": 5550,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5551",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5551.png",
     "id": 5551,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5552",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5552.png",
     "id": 5552,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5553",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5553.png",
     "id": 5553,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5554",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5554.png",
     "id": 5554,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5555",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5555.png",
     "id": 5555,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5556",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5556.png",
     "id": 5556,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5557",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5557.png",
     "id": 5557,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5558",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5558.png",
     "id": 5558,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5559",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5559.png",
     "id": 5559,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5560",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5560.png",
     "id": 5560,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5561",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5561.png",
     "id": 5561,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5562",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5562.png",
     "id": 5562,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5563",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5563.png",
     "id": 5563,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5564",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5564.png",
     "id": 5564,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5565",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5565.png",
     "id": 5565,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5566",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5566.png",
     "id": 5566,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5567",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5567.png",
     "id": 5567,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5568",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5568.png",
     "id": 5568,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5569",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5569.png",
     "id": 5569,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5570",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5570.png",
     "id": 5570,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5571",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5571.png",
     "id": 5571,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5572",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5572.png",
     "id": 5572,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5573",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5573.png",
     "id": 5573,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5574",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5574.png",
     "id": 5574,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5575",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5575.png",
     "id": 5575,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5576",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5576.png",
     "id": 5576,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5577",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5577.png",
     "id": 5577,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5578",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5578.png",
     "id": 5578,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5579",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5579.png",
     "id": 5579,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5580",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5580.png",
     "id": 5580,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5581",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5581.png",
     "id": 5581,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5582",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5582.png",
     "id": 5582,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5583",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5583.png",
     "id": 5583,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5584",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5584.png",
     "id": 5584,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5585",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5585.png",
     "id": 5585,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5586",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5586.png",
     "id": 5586,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5587",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5587.png",
     "id": 5587,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5588",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5588.png",
     "id": 5588,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5589",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5589.png",
     "id": 5589,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5590",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5590.png",
     "id": 5590,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5591",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5591.png",
     "id": 5591,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5592",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5592.png",
     "id": 5592,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5593",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5593.png",
     "id": 5593,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5594",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5594.png",
     "id": 5594,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5595",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5595.png",
     "id": 5595,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5596",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5596.png",
     "id": 5596,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5597",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5597.png",
     "id": 5597,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5598",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5598.png",
     "id": 5598,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5599",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5599.png",
     "id": 5599,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5600",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5600.png",
     "id": 5600,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #5601",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5601.png",
     "id": 5601,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5602",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5602.png",
     "id": 5602,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5603",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5603.png",
     "id": 5603,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5604",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5604.png",
     "id": 5604,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5605",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5605.png",
     "id": 5605,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #5606",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5606.png",
     "id": 5606,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5607",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5607.png",
     "id": 5607,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5608",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5608.png",
     "id": 5608,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5609",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5609.png",
     "id": 5609,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5610",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5610.png",
     "id": 5610,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5611",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5611.png",
     "id": 5611,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5612",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5612.png",
     "id": 5612,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5613",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5613.png",
     "id": 5613,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5614",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5614.png",
     "id": 5614,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5615",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5615.png",
     "id": 5615,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5616",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5616.png",
     "id": 5616,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5617",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5617.png",
     "id": 5617,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5618",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5618.png",
     "id": 5618,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5619",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5619.png",
     "id": 5619,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5620",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5620.png",
     "id": 5620,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5621",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5621.png",
     "id": 5621,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5622",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5622.png",
     "id": 5622,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5623",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5623.png",
     "id": 5623,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5624",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5624.png",
     "id": 5624,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5625",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5625.png",
     "id": 5625,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5626",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5626.png",
     "id": 5626,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5627",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5627.png",
     "id": 5627,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5628",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5628.png",
     "id": 5628,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5629",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5629.png",
     "id": 5629,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5630",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5630.png",
     "id": 5630,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5631",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5631.png",
     "id": 5631,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5632",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5632.png",
     "id": 5632,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5633",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5633.png",
     "id": 5633,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #5634",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5634.png",
     "id": 5634,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5635",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5635.png",
     "id": 5635,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5636",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5636.png",
     "id": 5636,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5637",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5637.png",
     "id": 5637,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5638",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5638.png",
     "id": 5638,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5639",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5639.png",
     "id": 5639,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5640",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5640.png",
     "id": 5640,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5641",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5641.png",
     "id": 5641,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5642",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5642.png",
     "id": 5642,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5643",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5643.png",
     "id": 5643,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5644",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5644.png",
     "id": 5644,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5645",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5645.png",
     "id": 5645,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5646",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5646.png",
     "id": 5646,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5647",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5647.png",
     "id": 5647,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5648",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5648.png",
     "id": 5648,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5649",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5649.png",
     "id": 5649,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5650",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5650.png",
     "id": 5650,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5651",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5651.png",
     "id": 5651,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5652",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5652.png",
     "id": 5652,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5653",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5653.png",
     "id": 5653,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5654",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5654.png",
     "id": 5654,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5655",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5655.png",
     "id": 5655,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5656",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5656.png",
     "id": 5656,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5657",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5657.png",
     "id": 5657,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5658",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5658.png",
     "id": 5658,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5659",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5659.png",
     "id": 5659,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5660",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5660.png",
     "id": 5660,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5661",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5661.png",
     "id": 5661,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "B008"
      },
      {
       "trait_type": "Skin",
       "value": "B008"
      },
      {
       "trait_type": "Crypto",
       "value": "B008"
      },
      {
       "trait_type": "Activity",
       "value": "B008"
      },
      {
       "trait_type": "Outfit",
       "value": "B008"
      },
      {
       "trait_type": "Transportation",
       "value": "B008"
      },
      {
       "trait_type": "Head",
       "value": "B008"
      },
      {
       "trait_type": "Mouth",
       "value": "B008"
      },
      {
       "trait_type": "Eyes",
       "value": "B008"
      }
     ]
    },
    {
     "name": "Boomer Squad #5662",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5662.png",
     "id": 5662,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5663",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5663.png",
     "id": 5663,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5664",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5664.png",
     "id": 5664,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5665",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5665.png",
     "id": 5665,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5666",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5666.png",
     "id": 5666,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5667",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5667.png",
     "id": 5667,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5668",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5668.png",
     "id": 5668,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5669",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5669.png",
     "id": 5669,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #5670",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5670.png",
     "id": 5670,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5671",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5671.png",
     "id": 5671,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5672",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5672.png",
     "id": 5672,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5673",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5673.png",
     "id": 5673,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5674",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5674.png",
     "id": 5674,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5675",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5675.png",
     "id": 5675,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5676",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5676.png",
     "id": 5676,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5677",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5677.png",
     "id": 5677,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5678",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5678.png",
     "id": 5678,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5679",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5679.png",
     "id": 5679,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5680",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5680.png",
     "id": 5680,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5681",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5681.png",
     "id": 5681,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5682",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5682.png",
     "id": 5682,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5683",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5683.png",
     "id": 5683,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5684",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5684.png",
     "id": 5684,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5685",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5685.png",
     "id": 5685,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5686",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5686.png",
     "id": 5686,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5687",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5687.png",
     "id": 5687,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5688",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5688.png",
     "id": 5688,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #5689",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5689.png",
     "id": 5689,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5690",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5690.png",
     "id": 5690,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #5691",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5691.png",
     "id": 5691,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5692",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5692.png",
     "id": 5692,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5693",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5693.png",
     "id": 5693,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5694",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5694.png",
     "id": 5694,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5695",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5695.png",
     "id": 5695,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5696",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5696.png",
     "id": 5696,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5697",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5697.png",
     "id": 5697,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5698",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5698.png",
     "id": 5698,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5699",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5699.png",
     "id": 5699,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5700",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5700.png",
     "id": 5700,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5701",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5701.png",
     "id": 5701,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5702",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5702.png",
     "id": 5702,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5703",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5703.png",
     "id": 5703,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5704",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5704.png",
     "id": 5704,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5705",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5705.png",
     "id": 5705,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5706",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5706.png",
     "id": 5706,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5707",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5707.png",
     "id": 5707,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5708",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5708.png",
     "id": 5708,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5709",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5709.png",
     "id": 5709,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5710",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5710.png",
     "id": 5710,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5711",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5711.png",
     "id": 5711,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5712",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5712.png",
     "id": 5712,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5713",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5713.png",
     "id": 5713,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5714",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5714.png",
     "id": 5714,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5715",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5715.png",
     "id": 5715,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5716",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5716.png",
     "id": 5716,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5717",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5717.png",
     "id": 5717,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5718",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5718.png",
     "id": 5718,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5719",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5719.png",
     "id": 5719,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5720",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5720.png",
     "id": 5720,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5721",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5721.png",
     "id": 5721,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5722",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5722.png",
     "id": 5722,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5723",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5723.png",
     "id": 5723,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5724",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5724.png",
     "id": 5724,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5725",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5725.png",
     "id": 5725,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5726",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5726.png",
     "id": 5726,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5727",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5727.png",
     "id": 5727,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5728",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5728.png",
     "id": 5728,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5729",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5729.png",
     "id": 5729,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5730",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5730.png",
     "id": 5730,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5731",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5731.png",
     "id": 5731,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5732",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5732.png",
     "id": 5732,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5733",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5733.png",
     "id": 5733,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5734",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5734.png",
     "id": 5734,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5735",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5735.png",
     "id": 5735,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5736",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5736.png",
     "id": 5736,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5737",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5737.png",
     "id": 5737,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5738",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5738.png",
     "id": 5738,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5739",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5739.png",
     "id": 5739,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5740",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5740.png",
     "id": 5740,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5741",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5741.png",
     "id": 5741,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5742",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5742.png",
     "id": 5742,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5743",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5743.png",
     "id": 5743,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5744",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5744.png",
     "id": 5744,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5745",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5745.png",
     "id": 5745,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5746",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5746.png",
     "id": 5746,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5747",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5747.png",
     "id": 5747,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #5748",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5748.png",
     "id": 5748,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5749",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5749.png",
     "id": 5749,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5750",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5750.png",
     "id": 5750,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5751",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5751.png",
     "id": 5751,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5752",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5752.png",
     "id": 5752,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5753",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5753.png",
     "id": 5753,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #5754",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5754.png",
     "id": 5754,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5755",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5755.png",
     "id": 5755,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5756",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5756.png",
     "id": 5756,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5757",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5757.png",
     "id": 5757,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5758",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5758.png",
     "id": 5758,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5759",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5759.png",
     "id": 5759,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5760",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5760.png",
     "id": 5760,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5761",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5761.png",
     "id": 5761,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5762",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5762.png",
     "id": 5762,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5763",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5763.png",
     "id": 5763,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5764",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5764.png",
     "id": 5764,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #5765",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5765.png",
     "id": 5765,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5766",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5766.png",
     "id": 5766,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5767",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5767.png",
     "id": 5767,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5768",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5768.png",
     "id": 5768,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5769",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5769.png",
     "id": 5769,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5770",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5770.png",
     "id": 5770,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5771",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5771.png",
     "id": 5771,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5772",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5772.png",
     "id": 5772,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5773",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5773.png",
     "id": 5773,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5774",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5774.png",
     "id": 5774,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5775",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5775.png",
     "id": 5775,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5776",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5776.png",
     "id": 5776,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5777",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5777.png",
     "id": 5777,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5778",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5778.png",
     "id": 5778,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5779",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5779.png",
     "id": 5779,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5780",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5780.png",
     "id": 5780,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5781",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5781.png",
     "id": 5781,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5782",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5782.png",
     "id": 5782,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5783",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5783.png",
     "id": 5783,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5784",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5784.png",
     "id": 5784,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5785",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5785.png",
     "id": 5785,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5786",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5786.png",
     "id": 5786,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5787",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5787.png",
     "id": 5787,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5788",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5788.png",
     "id": 5788,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5789",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5789.png",
     "id": 5789,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5790",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5790.png",
     "id": 5790,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5791",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5791.png",
     "id": 5791,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5792",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5792.png",
     "id": 5792,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5793",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5793.png",
     "id": 5793,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5794",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5794.png",
     "id": 5794,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5795",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5795.png",
     "id": 5795,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5796",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5796.png",
     "id": 5796,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5797",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5797.png",
     "id": 5797,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5798",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5798.png",
     "id": 5798,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5799",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5799.png",
     "id": 5799,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5800",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5800.png",
     "id": 5800,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5801",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5801.png",
     "id": 5801,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5802",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5802.png",
     "id": 5802,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5803",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5803.png",
     "id": 5803,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5804",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5804.png",
     "id": 5804,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5805",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5805.png",
     "id": 5805,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5806",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5806.png",
     "id": 5806,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #5807",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5807.png",
     "id": 5807,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5808",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5808.png",
     "id": 5808,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5809",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5809.png",
     "id": 5809,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5810",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5810.png",
     "id": 5810,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5811",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5811.png",
     "id": 5811,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5812",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5812.png",
     "id": 5812,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5813",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5813.png",
     "id": 5813,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5814",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5814.png",
     "id": 5814,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5815",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5815.png",
     "id": 5815,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5816",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5816.png",
     "id": 5816,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5817",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5817.png",
     "id": 5817,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5818",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5818.png",
     "id": 5818,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5819",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5819.png",
     "id": 5819,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5820",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5820.png",
     "id": 5820,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5821",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5821.png",
     "id": 5821,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5822",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5822.png",
     "id": 5822,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #5823",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5823.png",
     "id": 5823,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5824",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5824.png",
     "id": 5824,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5825",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5825.png",
     "id": 5825,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5826",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5826.png",
     "id": 5826,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5827",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5827.png",
     "id": 5827,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5828",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5828.png",
     "id": 5828,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5829",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5829.png",
     "id": 5829,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5830",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5830.png",
     "id": 5830,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5831",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5831.png",
     "id": 5831,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5832",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5832.png",
     "id": 5832,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5833",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5833.png",
     "id": 5833,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5834",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5834.png",
     "id": 5834,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5835",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5835.png",
     "id": 5835,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5836",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5836.png",
     "id": 5836,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5837",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5837.png",
     "id": 5837,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5838",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5838.png",
     "id": 5838,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5839",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5839.png",
     "id": 5839,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5840",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5840.png",
     "id": 5840,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5841",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5841.png",
     "id": 5841,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5842",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5842.png",
     "id": 5842,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5843",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5843.png",
     "id": 5843,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5844",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5844.png",
     "id": 5844,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5845",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5845.png",
     "id": 5845,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5846",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5846.png",
     "id": 5846,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5847",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5847.png",
     "id": 5847,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5848",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5848.png",
     "id": 5848,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5849",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5849.png",
     "id": 5849,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5850",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5850.png",
     "id": 5850,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5851",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5851.png",
     "id": 5851,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5852",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5852.png",
     "id": 5852,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5853",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5853.png",
     "id": 5853,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5854",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5854.png",
     "id": 5854,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5855",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5855.png",
     "id": 5855,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5856",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5856.png",
     "id": 5856,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5857",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5857.png",
     "id": 5857,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5858",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5858.png",
     "id": 5858,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5859",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5859.png",
     "id": 5859,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5860",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5860.png",
     "id": 5860,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5861",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5861.png",
     "id": 5861,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5862",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5862.png",
     "id": 5862,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5863",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5863.png",
     "id": 5863,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5864",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5864.png",
     "id": 5864,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Rock N Boom"
      },
      {
       "trait_type": "Skin",
       "value": "Rock N Boom"
      },
      {
       "trait_type": "Crypto",
       "value": "Rock N Boom"
      },
      {
       "trait_type": "Activity",
       "value": "Rock N Boom"
      },
      {
       "trait_type": "Outfit",
       "value": "Rock N Boom"
      },
      {
       "trait_type": "Transportation",
       "value": "Rock N Boom"
      },
      {
       "trait_type": "Head",
       "value": "Rock N Boom"
      },
      {
       "trait_type": "Mouth",
       "value": "Rock N Boom"
      },
      {
       "trait_type": "Eyes",
       "value": "Rock N Boom"
      }
     ]
    },
    {
     "name": "Boomer Squad #5865",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5865.png",
     "id": 5865,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5866",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5866.png",
     "id": 5866,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5867",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5867.png",
     "id": 5867,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5868",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5868.png",
     "id": 5868,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5869",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5869.png",
     "id": 5869,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5870",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5870.png",
     "id": 5870,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5871",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5871.png",
     "id": 5871,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5872",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5872.png",
     "id": 5872,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5873",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5873.png",
     "id": 5873,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5874",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5874.png",
     "id": 5874,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5875",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5875.png",
     "id": 5875,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5876",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5876.png",
     "id": 5876,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5877",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5877.png",
     "id": 5877,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5878",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5878.png",
     "id": 5878,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5879",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5879.png",
     "id": 5879,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5880",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5880.png",
     "id": 5880,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5881",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5881.png",
     "id": 5881,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5882",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5882.png",
     "id": 5882,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5883",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5883.png",
     "id": 5883,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5884",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5884.png",
     "id": 5884,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5885",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5885.png",
     "id": 5885,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5886",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5886.png",
     "id": 5886,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5887",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5887.png",
     "id": 5887,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5888",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5888.png",
     "id": 5888,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5889",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5889.png",
     "id": 5889,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5890",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5890.png",
     "id": 5890,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5891",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5891.png",
     "id": 5891,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5892",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5892.png",
     "id": 5892,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5893",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5893.png",
     "id": 5893,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5894",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5894.png",
     "id": 5894,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5895",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5895.png",
     "id": 5895,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #5896",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5896.png",
     "id": 5896,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5897",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5897.png",
     "id": 5897,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5898",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5898.png",
     "id": 5898,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5899",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5899.png",
     "id": 5899,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5900",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5900.png",
     "id": 5900,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5901",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5901.png",
     "id": 5901,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5902",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5902.png",
     "id": 5902,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5903",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5903.png",
     "id": 5903,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5904",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5904.png",
     "id": 5904,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5905",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5905.png",
     "id": 5905,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5906",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5906.png",
     "id": 5906,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5907",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5907.png",
     "id": 5907,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5908",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5908.png",
     "id": 5908,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5909",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5909.png",
     "id": 5909,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5910",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5910.png",
     "id": 5910,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5911",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5911.png",
     "id": 5911,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5912",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5912.png",
     "id": 5912,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5913",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5913.png",
     "id": 5913,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #5914",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5914.png",
     "id": 5914,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5915",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5915.png",
     "id": 5915,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5916",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5916.png",
     "id": 5916,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5917",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5917.png",
     "id": 5917,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5918",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5918.png",
     "id": 5918,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5919",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5919.png",
     "id": 5919,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5920",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5920.png",
     "id": 5920,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5921",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5921.png",
     "id": 5921,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5922",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5922.png",
     "id": 5922,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5923",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5923.png",
     "id": 5923,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5924",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5924.png",
     "id": 5924,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5925",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5925.png",
     "id": 5925,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5926",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5926.png",
     "id": 5926,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5927",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5927.png",
     "id": 5927,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5928",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5928.png",
     "id": 5928,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5929",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5929.png",
     "id": 5929,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5930",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5930.png",
     "id": 5930,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5931",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5931.png",
     "id": 5931,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5932",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5932.png",
     "id": 5932,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5933",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5933.png",
     "id": 5933,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5934",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5934.png",
     "id": 5934,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5935",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5935.png",
     "id": 5935,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5936",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5936.png",
     "id": 5936,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5937",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5937.png",
     "id": 5937,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5938",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5938.png",
     "id": 5938,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #5939",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5939.png",
     "id": 5939,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5940",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5940.png",
     "id": 5940,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5941",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5941.png",
     "id": 5941,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5942",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5942.png",
     "id": 5942,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5943",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5943.png",
     "id": 5943,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5944",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5944.png",
     "id": 5944,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5945",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5945.png",
     "id": 5945,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5946",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5946.png",
     "id": 5946,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5947",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5947.png",
     "id": 5947,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5948",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5948.png",
     "id": 5948,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5949",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5949.png",
     "id": 5949,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5950",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5950.png",
     "id": 5950,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5951",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5951.png",
     "id": 5951,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5952",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5952.png",
     "id": 5952,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5953",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5953.png",
     "id": 5953,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #5954",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5954.png",
     "id": 5954,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5955",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5955.png",
     "id": 5955,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5956",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5956.png",
     "id": 5956,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5957",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5957.png",
     "id": 5957,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5958",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5958.png",
     "id": 5958,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #5959",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5959.png",
     "id": 5959,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5960",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5960.png",
     "id": 5960,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5961",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5961.png",
     "id": 5961,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #5962",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5962.png",
     "id": 5962,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5963",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5963.png",
     "id": 5963,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5964",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5964.png",
     "id": 5964,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5965",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5965.png",
     "id": 5965,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5966",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5966.png",
     "id": 5966,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5967",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5967.png",
     "id": 5967,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5968",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5968.png",
     "id": 5968,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5969",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5969.png",
     "id": 5969,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5970",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5970.png",
     "id": 5970,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5971",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5971.png",
     "id": 5971,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5972",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5972.png",
     "id": 5972,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #5973",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5973.png",
     "id": 5973,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5974",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5974.png",
     "id": 5974,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5975",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5975.png",
     "id": 5975,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #5976",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5976.png",
     "id": 5976,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #5977",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5977.png",
     "id": 5977,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #5978",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5978.png",
     "id": 5978,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5979",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5979.png",
     "id": 5979,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5980",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5980.png",
     "id": 5980,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5981",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5981.png",
     "id": 5981,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5982",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5982.png",
     "id": 5982,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #5983",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5983.png",
     "id": 5983,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5984",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5984.png",
     "id": 5984,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #5985",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5985.png",
     "id": 5985,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #5986",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5986.png",
     "id": 5986,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5987",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5987.png",
     "id": 5987,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #5988",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5988.png",
     "id": 5988,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #5989",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5989.png",
     "id": 5989,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5990",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5990.png",
     "id": 5990,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #5991",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5991.png",
     "id": 5991,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #5992",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5992.png",
     "id": 5992,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #5993",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5993.png",
     "id": 5993,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #5994",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5994.png",
     "id": 5994,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #5995",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5995.png",
     "id": 5995,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5996",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5996.png",
     "id": 5996,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #5997",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5997.png",
     "id": 5997,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #5998",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5998.png",
     "id": 5998,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #5999",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/5999.png",
     "id": 5999,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6000",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6000.png",
     "id": 6000,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6001",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6001.png",
     "id": 6001,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6002",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6002.png",
     "id": 6002,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6003",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6003.png",
     "id": 6003,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6004",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6004.png",
     "id": 6004,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6005",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6005.png",
     "id": 6005,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6006",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6006.png",
     "id": 6006,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6007",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6007.png",
     "id": 6007,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6008",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6008.png",
     "id": 6008,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6009",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6009.png",
     "id": 6009,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6010",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6010.png",
     "id": 6010,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6011",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6011.png",
     "id": 6011,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6012",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6012.png",
     "id": 6012,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6013",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6013.png",
     "id": 6013,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6014",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6014.png",
     "id": 6014,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6015",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6015.png",
     "id": 6015,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6016",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6016.png",
     "id": 6016,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6017",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6017.png",
     "id": 6017,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6018",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6018.png",
     "id": 6018,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6019",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6019.png",
     "id": 6019,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6020",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6020.png",
     "id": 6020,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6021",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6021.png",
     "id": 6021,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6022",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6022.png",
     "id": 6022,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6023",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6023.png",
     "id": 6023,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6024",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6024.png",
     "id": 6024,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6025",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6025.png",
     "id": 6025,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6026",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6026.png",
     "id": 6026,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6027",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6027.png",
     "id": 6027,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6028",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6028.png",
     "id": 6028,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6029",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6029.png",
     "id": 6029,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6030",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6030.png",
     "id": 6030,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6031",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6031.png",
     "id": 6031,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6032",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6032.png",
     "id": 6032,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6033",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6033.png",
     "id": 6033,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6034",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6034.png",
     "id": 6034,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6035",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6035.png",
     "id": 6035,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6036",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6036.png",
     "id": 6036,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6037",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6037.png",
     "id": 6037,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6038",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6038.png",
     "id": 6038,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6039",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6039.png",
     "id": 6039,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6040",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6040.png",
     "id": 6040,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6041",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6041.png",
     "id": 6041,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6042",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6042.png",
     "id": 6042,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6043",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6043.png",
     "id": 6043,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6044",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6044.png",
     "id": 6044,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6045",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6045.png",
     "id": 6045,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6046",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6046.png",
     "id": 6046,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6047",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6047.png",
     "id": 6047,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6048",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6048.png",
     "id": 6048,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6049",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6049.png",
     "id": 6049,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6050",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6050.png",
     "id": 6050,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6051",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6051.png",
     "id": 6051,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6052",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6052.png",
     "id": 6052,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6053",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6053.png",
     "id": 6053,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6054",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6054.png",
     "id": 6054,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6055",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6055.png",
     "id": 6055,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6056",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6056.png",
     "id": 6056,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6057",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6057.png",
     "id": 6057,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6058",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6058.png",
     "id": 6058,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6059",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6059.png",
     "id": 6059,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6060",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6060.png",
     "id": 6060,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6061",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6061.png",
     "id": 6061,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6062",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6062.png",
     "id": 6062,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6063",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6063.png",
     "id": 6063,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6064",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6064.png",
     "id": 6064,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6065",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6065.png",
     "id": 6065,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6066",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6066.png",
     "id": 6066,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6067",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6067.png",
     "id": 6067,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6068",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6068.png",
     "id": 6068,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6069",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6069.png",
     "id": 6069,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6070",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6070.png",
     "id": 6070,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6071",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6071.png",
     "id": 6071,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6072",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6072.png",
     "id": 6072,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6073",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6073.png",
     "id": 6073,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6074",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6074.png",
     "id": 6074,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6075",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6075.png",
     "id": 6075,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6076",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6076.png",
     "id": 6076,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #6077",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6077.png",
     "id": 6077,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6078",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6078.png",
     "id": 6078,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6079",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6079.png",
     "id": 6079,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6080",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6080.png",
     "id": 6080,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6081",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6081.png",
     "id": 6081,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6082",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6082.png",
     "id": 6082,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6083",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6083.png",
     "id": 6083,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6084",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6084.png",
     "id": 6084,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6085",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6085.png",
     "id": 6085,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6086",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6086.png",
     "id": 6086,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6087",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6087.png",
     "id": 6087,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6088",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6088.png",
     "id": 6088,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6089",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6089.png",
     "id": 6089,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6090",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6090.png",
     "id": 6090,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6091",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6091.png",
     "id": 6091,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6092",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6092.png",
     "id": 6092,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6093",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6093.png",
     "id": 6093,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6094",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6094.png",
     "id": 6094,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6095",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6095.png",
     "id": 6095,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6096",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6096.png",
     "id": 6096,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6097",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6097.png",
     "id": 6097,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6098",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6098.png",
     "id": 6098,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6099",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6099.png",
     "id": 6099,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6100",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6100.png",
     "id": 6100,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6101",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6101.png",
     "id": 6101,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6102",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6102.png",
     "id": 6102,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6103",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6103.png",
     "id": 6103,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6104",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6104.png",
     "id": 6104,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6105",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6105.png",
     "id": 6105,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6106",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6106.png",
     "id": 6106,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6107",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6107.png",
     "id": 6107,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6108",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6108.png",
     "id": 6108,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6109",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6109.png",
     "id": 6109,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6110",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6110.png",
     "id": 6110,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6111",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6111.png",
     "id": 6111,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6112",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6112.png",
     "id": 6112,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6113",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6113.png",
     "id": 6113,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6114",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6114.png",
     "id": 6114,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6115",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6115.png",
     "id": 6115,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6116",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6116.png",
     "id": 6116,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6117",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6117.png",
     "id": 6117,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6118",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6118.png",
     "id": 6118,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6119",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6119.png",
     "id": 6119,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6120",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6120.png",
     "id": 6120,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6121",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6121.png",
     "id": 6121,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6122",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6122.png",
     "id": 6122,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6123",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6123.png",
     "id": 6123,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6124",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6124.png",
     "id": 6124,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6125",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6125.png",
     "id": 6125,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6126",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6126.png",
     "id": 6126,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6127",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6127.png",
     "id": 6127,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6128",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6128.png",
     "id": 6128,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6129",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6129.png",
     "id": 6129,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6130",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6130.png",
     "id": 6130,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6131",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6131.png",
     "id": 6131,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6132",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6132.png",
     "id": 6132,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6133",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6133.png",
     "id": 6133,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6134",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6134.png",
     "id": 6134,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6135",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6135.png",
     "id": 6135,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6136",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6136.png",
     "id": 6136,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6137",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6137.png",
     "id": 6137,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6138",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6138.png",
     "id": 6138,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6139",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6139.png",
     "id": 6139,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6140",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6140.png",
     "id": 6140,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6141",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6141.png",
     "id": 6141,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6142",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6142.png",
     "id": 6142,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6143",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6143.png",
     "id": 6143,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6144",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6144.png",
     "id": 6144,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6145",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6145.png",
     "id": 6145,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6146",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6146.png",
     "id": 6146,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6147",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6147.png",
     "id": 6147,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6148",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6148.png",
     "id": 6148,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6149",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6149.png",
     "id": 6149,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6150",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6150.png",
     "id": 6150,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6151",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6151.png",
     "id": 6151,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6152",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6152.png",
     "id": 6152,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6153",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6153.png",
     "id": 6153,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6154",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6154.png",
     "id": 6154,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6155",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6155.png",
     "id": 6155,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6156",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6156.png",
     "id": 6156,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6157",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6157.png",
     "id": 6157,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6158",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6158.png",
     "id": 6158,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6159",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6159.png",
     "id": 6159,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6160",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6160.png",
     "id": 6160,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Best Boss"
      },
      {
       "trait_type": "Skin",
       "value": "Best Boss"
      },
      {
       "trait_type": "Crypto",
       "value": "Best Boss"
      },
      {
       "trait_type": "Activity",
       "value": "Best Boss"
      },
      {
       "trait_type": "Outfit",
       "value": "Best Boss"
      },
      {
       "trait_type": "Transportation",
       "value": "Best Boss"
      },
      {
       "trait_type": "Head",
       "value": "Best Boss"
      },
      {
       "trait_type": "Mouth",
       "value": "Best Boss"
      },
      {
       "trait_type": "Eyes",
       "value": "Best Boss"
      }
     ]
    },
    {
     "name": "Boomer Squad #6161",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6161.png",
     "id": 6161,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6162",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6162.png",
     "id": 6162,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6163",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6163.png",
     "id": 6163,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6164",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6164.png",
     "id": 6164,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6165",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6165.png",
     "id": 6165,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6166",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6166.png",
     "id": 6166,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6167",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6167.png",
     "id": 6167,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6168",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6168.png",
     "id": 6168,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6169",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6169.png",
     "id": 6169,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6170",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6170.png",
     "id": 6170,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6171",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6171.png",
     "id": 6171,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6172",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6172.png",
     "id": 6172,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6173",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6173.png",
     "id": 6173,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6174",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6174.png",
     "id": 6174,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6175",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6175.png",
     "id": 6175,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6176",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6176.png",
     "id": 6176,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6177",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6177.png",
     "id": 6177,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6178",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6178.png",
     "id": 6178,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6179",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6179.png",
     "id": 6179,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6180",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6180.png",
     "id": 6180,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6181",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6181.png",
     "id": 6181,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6182",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6182.png",
     "id": 6182,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6183",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6183.png",
     "id": 6183,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6184",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6184.png",
     "id": 6184,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6185",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6185.png",
     "id": 6185,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6186",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6186.png",
     "id": 6186,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6187",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6187.png",
     "id": 6187,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6188",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6188.png",
     "id": 6188,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6189",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6189.png",
     "id": 6189,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6190",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6190.png",
     "id": 6190,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6191",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6191.png",
     "id": 6191,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6192",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6192.png",
     "id": 6192,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6193",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6193.png",
     "id": 6193,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6194",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6194.png",
     "id": 6194,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6195",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6195.png",
     "id": 6195,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6196",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6196.png",
     "id": 6196,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6197",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6197.png",
     "id": 6197,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6198",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6198.png",
     "id": 6198,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #6199",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6199.png",
     "id": 6199,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6200",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6200.png",
     "id": 6200,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6201",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6201.png",
     "id": 6201,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6202",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6202.png",
     "id": 6202,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6203",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6203.png",
     "id": 6203,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6204",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6204.png",
     "id": 6204,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6205",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6205.png",
     "id": 6205,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6206",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6206.png",
     "id": 6206,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6207",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6207.png",
     "id": 6207,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6208",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6208.png",
     "id": 6208,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #6209",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6209.png",
     "id": 6209,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6210",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6210.png",
     "id": 6210,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6211",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6211.png",
     "id": 6211,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6212",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6212.png",
     "id": 6212,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6213",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6213.png",
     "id": 6213,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6214",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6214.png",
     "id": 6214,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6215",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6215.png",
     "id": 6215,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6216",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6216.png",
     "id": 6216,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6217",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6217.png",
     "id": 6217,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6218",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6218.png",
     "id": 6218,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6219",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6219.png",
     "id": 6219,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6220",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6220.png",
     "id": 6220,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6221",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6221.png",
     "id": 6221,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6222",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6222.png",
     "id": 6222,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6223",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6223.png",
     "id": 6223,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6224",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6224.png",
     "id": 6224,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6225",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6225.png",
     "id": 6225,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6226",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6226.png",
     "id": 6226,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6227",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6227.png",
     "id": 6227,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6228",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6228.png",
     "id": 6228,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6229",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6229.png",
     "id": 6229,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6230",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6230.png",
     "id": 6230,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6231",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6231.png",
     "id": 6231,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6232",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6232.png",
     "id": 6232,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6233",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6233.png",
     "id": 6233,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6234",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6234.png",
     "id": 6234,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6235",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6235.png",
     "id": 6235,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6236",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6236.png",
     "id": 6236,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6237",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6237.png",
     "id": 6237,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6238",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6238.png",
     "id": 6238,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6239",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6239.png",
     "id": 6239,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6240",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6240.png",
     "id": 6240,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6241",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6241.png",
     "id": 6241,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6242",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6242.png",
     "id": 6242,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6243",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6243.png",
     "id": 6243,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6244",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6244.png",
     "id": 6244,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6245",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6245.png",
     "id": 6245,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6246",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6246.png",
     "id": 6246,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6247",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6247.png",
     "id": 6247,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6248",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6248.png",
     "id": 6248,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6249",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6249.png",
     "id": 6249,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6250",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6250.png",
     "id": 6250,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6251",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6251.png",
     "id": 6251,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6252",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6252.png",
     "id": 6252,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6253",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6253.png",
     "id": 6253,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6254",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6254.png",
     "id": 6254,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6255",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6255.png",
     "id": 6255,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6256",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6256.png",
     "id": 6256,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6257",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6257.png",
     "id": 6257,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6258",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6258.png",
     "id": 6258,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6259",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6259.png",
     "id": 6259,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6260",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6260.png",
     "id": 6260,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6261",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6261.png",
     "id": 6261,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6262",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6262.png",
     "id": 6262,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6263",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6263.png",
     "id": 6263,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6264",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6264.png",
     "id": 6264,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6265",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6265.png",
     "id": 6265,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6266",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6266.png",
     "id": 6266,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6267",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6267.png",
     "id": 6267,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6268",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6268.png",
     "id": 6268,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6269",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6269.png",
     "id": 6269,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6270",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6270.png",
     "id": 6270,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6271",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6271.png",
     "id": 6271,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6272",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6272.png",
     "id": 6272,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6273",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6273.png",
     "id": 6273,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6274",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6274.png",
     "id": 6274,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6275",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6275.png",
     "id": 6275,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6276",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6276.png",
     "id": 6276,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6277",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6277.png",
     "id": 6277,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6278",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6278.png",
     "id": 6278,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6279",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6279.png",
     "id": 6279,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6280",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6280.png",
     "id": 6280,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6281",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6281.png",
     "id": 6281,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6282",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6282.png",
     "id": 6282,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6283",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6283.png",
     "id": 6283,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6284",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6284.png",
     "id": 6284,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6285",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6285.png",
     "id": 6285,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6286",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6286.png",
     "id": 6286,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6287",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6287.png",
     "id": 6287,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6288",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6288.png",
     "id": 6288,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6289",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6289.png",
     "id": 6289,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6290",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6290.png",
     "id": 6290,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6291",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6291.png",
     "id": 6291,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6292",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6292.png",
     "id": 6292,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6293",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6293.png",
     "id": 6293,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6294",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6294.png",
     "id": 6294,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6295",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6295.png",
     "id": 6295,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6296",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6296.png",
     "id": 6296,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6297",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6297.png",
     "id": 6297,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6298",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6298.png",
     "id": 6298,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6299",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6299.png",
     "id": 6299,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6300",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6300.png",
     "id": 6300,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6301",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6301.png",
     "id": 6301,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6302",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6302.png",
     "id": 6302,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6303",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6303.png",
     "id": 6303,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6304",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6304.png",
     "id": 6304,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6305",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6305.png",
     "id": 6305,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6306",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6306.png",
     "id": 6306,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6307",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6307.png",
     "id": 6307,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6308",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6308.png",
     "id": 6308,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6309",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6309.png",
     "id": 6309,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6310",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6310.png",
     "id": 6310,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6311",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6311.png",
     "id": 6311,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6312",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6312.png",
     "id": 6312,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6313",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6313.png",
     "id": 6313,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6314",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6314.png",
     "id": 6314,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6315",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6315.png",
     "id": 6315,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6316",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6316.png",
     "id": 6316,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6317",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6317.png",
     "id": 6317,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6318",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6318.png",
     "id": 6318,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6319",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6319.png",
     "id": 6319,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6320",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6320.png",
     "id": 6320,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6321",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6321.png",
     "id": 6321,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6322",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6322.png",
     "id": 6322,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6323",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6323.png",
     "id": 6323,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6324",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6324.png",
     "id": 6324,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6325",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6325.png",
     "id": 6325,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6326",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6326.png",
     "id": 6326,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6327",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6327.png",
     "id": 6327,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6328",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6328.png",
     "id": 6328,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6329",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6329.png",
     "id": 6329,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6330",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6330.png",
     "id": 6330,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6331",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6331.png",
     "id": 6331,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6332",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6332.png",
     "id": 6332,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6333",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6333.png",
     "id": 6333,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6334",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6334.png",
     "id": 6334,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6335",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6335.png",
     "id": 6335,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6336",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6336.png",
     "id": 6336,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6337",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6337.png",
     "id": 6337,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6338",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6338.png",
     "id": 6338,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6339",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6339.png",
     "id": 6339,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6340",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6340.png",
     "id": 6340,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6341",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6341.png",
     "id": 6341,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6342",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6342.png",
     "id": 6342,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6343",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6343.png",
     "id": 6343,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6344",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6344.png",
     "id": 6344,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6345",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6345.png",
     "id": 6345,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6346",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6346.png",
     "id": 6346,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6347",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6347.png",
     "id": 6347,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6348",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6348.png",
     "id": 6348,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6349",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6349.png",
     "id": 6349,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6350",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6350.png",
     "id": 6350,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6351",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6351.png",
     "id": 6351,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6352",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6352.png",
     "id": 6352,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6353",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6353.png",
     "id": 6353,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6354",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6354.png",
     "id": 6354,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6355",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6355.png",
     "id": 6355,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6356",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6356.png",
     "id": 6356,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6357",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6357.png",
     "id": 6357,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6358",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6358.png",
     "id": 6358,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6359",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6359.png",
     "id": 6359,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6360",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6360.png",
     "id": 6360,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6361",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6361.png",
     "id": 6361,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6362",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6362.png",
     "id": 6362,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6363",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6363.png",
     "id": 6363,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6364",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6364.png",
     "id": 6364,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6365",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6365.png",
     "id": 6365,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6366",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6366.png",
     "id": 6366,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6367",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6367.png",
     "id": 6367,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6368",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6368.png",
     "id": 6368,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6369",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6369.png",
     "id": 6369,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6370",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6370.png",
     "id": 6370,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6371",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6371.png",
     "id": 6371,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6372",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6372.png",
     "id": 6372,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6373",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6373.png",
     "id": 6373,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6374",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6374.png",
     "id": 6374,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6375",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6375.png",
     "id": 6375,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6376",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6376.png",
     "id": 6376,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6377",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6377.png",
     "id": 6377,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6378",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6378.png",
     "id": 6378,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6379",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6379.png",
     "id": 6379,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6380",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6380.png",
     "id": 6380,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6381",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6381.png",
     "id": 6381,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6382",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6382.png",
     "id": 6382,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6383",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6383.png",
     "id": 6383,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6384",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6384.png",
     "id": 6384,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6385",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6385.png",
     "id": 6385,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6386",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6386.png",
     "id": 6386,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6387",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6387.png",
     "id": 6387,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6388",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6388.png",
     "id": 6388,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6389",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6389.png",
     "id": 6389,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6390",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6390.png",
     "id": 6390,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6391",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6391.png",
     "id": 6391,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6392",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6392.png",
     "id": 6392,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6393",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6393.png",
     "id": 6393,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6394",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6394.png",
     "id": 6394,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6395",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6395.png",
     "id": 6395,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6396",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6396.png",
     "id": 6396,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6397",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6397.png",
     "id": 6397,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6398",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6398.png",
     "id": 6398,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6399",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6399.png",
     "id": 6399,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6400",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6400.png",
     "id": 6400,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6401",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6401.png",
     "id": 6401,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6402",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6402.png",
     "id": 6402,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6403",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6403.png",
     "id": 6403,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6404",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6404.png",
     "id": 6404,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6405",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6405.png",
     "id": 6405,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6406",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6406.png",
     "id": 6406,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6407",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6407.png",
     "id": 6407,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6408",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6408.png",
     "id": 6408,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6409",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6409.png",
     "id": 6409,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6410",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6410.png",
     "id": 6410,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6411",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6411.png",
     "id": 6411,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6412",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6412.png",
     "id": 6412,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6413",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6413.png",
     "id": 6413,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6414",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6414.png",
     "id": 6414,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6415",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6415.png",
     "id": 6415,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6416",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6416.png",
     "id": 6416,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6417",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6417.png",
     "id": 6417,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6418",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6418.png",
     "id": 6418,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6419",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6419.png",
     "id": 6419,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6420",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6420.png",
     "id": 6420,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6421",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6421.png",
     "id": 6421,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6422",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6422.png",
     "id": 6422,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6423",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6423.png",
     "id": 6423,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6424",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6424.png",
     "id": 6424,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6425",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6425.png",
     "id": 6425,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6426",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6426.png",
     "id": 6426,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6427",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6427.png",
     "id": 6427,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6428",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6428.png",
     "id": 6428,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6429",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6429.png",
     "id": 6429,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6430",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6430.png",
     "id": 6430,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6431",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6431.png",
     "id": 6431,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6432",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6432.png",
     "id": 6432,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6433",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6433.png",
     "id": 6433,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6434",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6434.png",
     "id": 6434,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6435",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6435.png",
     "id": 6435,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6436",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6436.png",
     "id": 6436,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6437",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6437.png",
     "id": 6437,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6438",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6438.png",
     "id": 6438,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6439",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6439.png",
     "id": 6439,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6440",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6440.png",
     "id": 6440,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6441",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6441.png",
     "id": 6441,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6442",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6442.png",
     "id": 6442,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6443",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6443.png",
     "id": 6443,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6444",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6444.png",
     "id": 6444,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6445",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6445.png",
     "id": 6445,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6446",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6446.png",
     "id": 6446,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6447",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6447.png",
     "id": 6447,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6448",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6448.png",
     "id": 6448,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6449",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6449.png",
     "id": 6449,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6450",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6450.png",
     "id": 6450,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6451",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6451.png",
     "id": 6451,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6452",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6452.png",
     "id": 6452,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6453",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6453.png",
     "id": 6453,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6454",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6454.png",
     "id": 6454,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6455",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6455.png",
     "id": 6455,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6456",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6456.png",
     "id": 6456,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6457",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6457.png",
     "id": 6457,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6458",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6458.png",
     "id": 6458,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6459",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6459.png",
     "id": 6459,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6460",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6460.png",
     "id": 6460,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6461",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6461.png",
     "id": 6461,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6462",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6462.png",
     "id": 6462,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6463",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6463.png",
     "id": 6463,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6464",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6464.png",
     "id": 6464,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6465",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6465.png",
     "id": 6465,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6466",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6466.png",
     "id": 6466,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6467",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6467.png",
     "id": 6467,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6468",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6468.png",
     "id": 6468,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6469",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6469.png",
     "id": 6469,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6470",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6470.png",
     "id": 6470,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6471",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6471.png",
     "id": 6471,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6472",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6472.png",
     "id": 6472,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6473",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6473.png",
     "id": 6473,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6474",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6474.png",
     "id": 6474,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6475",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6475.png",
     "id": 6475,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #6476",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6476.png",
     "id": 6476,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6477",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6477.png",
     "id": 6477,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6478",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6478.png",
     "id": 6478,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6479",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6479.png",
     "id": 6479,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6480",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6480.png",
     "id": 6480,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6481",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6481.png",
     "id": 6481,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6482",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6482.png",
     "id": 6482,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6483",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6483.png",
     "id": 6483,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6484",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6484.png",
     "id": 6484,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6485",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6485.png",
     "id": 6485,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6486",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6486.png",
     "id": 6486,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6487",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6487.png",
     "id": 6487,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6488",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6488.png",
     "id": 6488,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6489",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6489.png",
     "id": 6489,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6490",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6490.png",
     "id": 6490,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6491",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6491.png",
     "id": 6491,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6492",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6492.png",
     "id": 6492,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6493",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6493.png",
     "id": 6493,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6494",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6494.png",
     "id": 6494,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6495",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6495.png",
     "id": 6495,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6496",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6496.png",
     "id": 6496,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #6497",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6497.png",
     "id": 6497,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6498",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6498.png",
     "id": 6498,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6499",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6499.png",
     "id": 6499,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6500",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6500.png",
     "id": 6500,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6501",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6501.png",
     "id": 6501,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6502",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6502.png",
     "id": 6502,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6503",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6503.png",
     "id": 6503,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6504",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6504.png",
     "id": 6504,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6505",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6505.png",
     "id": 6505,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6506",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6506.png",
     "id": 6506,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6507",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6507.png",
     "id": 6507,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6508",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6508.png",
     "id": 6508,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6509",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6509.png",
     "id": 6509,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6510",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6510.png",
     "id": 6510,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6511",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6511.png",
     "id": 6511,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6512",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6512.png",
     "id": 6512,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6513",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6513.png",
     "id": 6513,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6514",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6514.png",
     "id": 6514,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6515",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6515.png",
     "id": 6515,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6516",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6516.png",
     "id": 6516,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6517",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6517.png",
     "id": 6517,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6518",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6518.png",
     "id": 6518,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6519",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6519.png",
     "id": 6519,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6520",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6520.png",
     "id": 6520,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6521",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6521.png",
     "id": 6521,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6522",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6522.png",
     "id": 6522,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6523",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6523.png",
     "id": 6523,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6524",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6524.png",
     "id": 6524,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6525",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6525.png",
     "id": 6525,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6526",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6526.png",
     "id": 6526,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6527",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6527.png",
     "id": 6527,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6528",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6528.png",
     "id": 6528,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6529",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6529.png",
     "id": 6529,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6530",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6530.png",
     "id": 6530,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6531",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6531.png",
     "id": 6531,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6532",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6532.png",
     "id": 6532,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6533",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6533.png",
     "id": 6533,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6534",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6534.png",
     "id": 6534,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6535",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6535.png",
     "id": 6535,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6536",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6536.png",
     "id": 6536,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6537",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6537.png",
     "id": 6537,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6538",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6538.png",
     "id": 6538,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6539",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6539.png",
     "id": 6539,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6540",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6540.png",
     "id": 6540,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6541",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6541.png",
     "id": 6541,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6542",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6542.png",
     "id": 6542,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6543",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6543.png",
     "id": 6543,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6544",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6544.png",
     "id": 6544,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6545",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6545.png",
     "id": 6545,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6546",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6546.png",
     "id": 6546,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6547",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6547.png",
     "id": 6547,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6548",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6548.png",
     "id": 6548,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6549",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6549.png",
     "id": 6549,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6550",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6550.png",
     "id": 6550,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6551",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6551.png",
     "id": 6551,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6552",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6552.png",
     "id": 6552,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6553",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6553.png",
     "id": 6553,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6554",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6554.png",
     "id": 6554,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6555",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6555.png",
     "id": 6555,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6556",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6556.png",
     "id": 6556,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6557",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6557.png",
     "id": 6557,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6558",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6558.png",
     "id": 6558,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6559",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6559.png",
     "id": 6559,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6560",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6560.png",
     "id": 6560,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6561",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6561.png",
     "id": 6561,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6562",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6562.png",
     "id": 6562,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6563",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6563.png",
     "id": 6563,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6564",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6564.png",
     "id": 6564,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6565",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6565.png",
     "id": 6565,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6566",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6566.png",
     "id": 6566,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6567",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6567.png",
     "id": 6567,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6568",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6568.png",
     "id": 6568,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6569",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6569.png",
     "id": 6569,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6570",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6570.png",
     "id": 6570,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6571",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6571.png",
     "id": 6571,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6572",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6572.png",
     "id": 6572,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6573",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6573.png",
     "id": 6573,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6574",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6574.png",
     "id": 6574,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6575",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6575.png",
     "id": 6575,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6576",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6576.png",
     "id": 6576,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6577",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6577.png",
     "id": 6577,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6578",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6578.png",
     "id": 6578,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6579",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6579.png",
     "id": 6579,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6580",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6580.png",
     "id": 6580,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6581",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6581.png",
     "id": 6581,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6582",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6582.png",
     "id": 6582,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6583",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6583.png",
     "id": 6583,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6584",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6584.png",
     "id": 6584,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6585",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6585.png",
     "id": 6585,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6586",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6586.png",
     "id": 6586,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6587",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6587.png",
     "id": 6587,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6588",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6588.png",
     "id": 6588,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6589",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6589.png",
     "id": 6589,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6590",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6590.png",
     "id": 6590,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6591",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6591.png",
     "id": 6591,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6592",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6592.png",
     "id": 6592,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6593",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6593.png",
     "id": 6593,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6594",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6594.png",
     "id": 6594,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6595",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6595.png",
     "id": 6595,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6596",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6596.png",
     "id": 6596,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6597",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6597.png",
     "id": 6597,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6598",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6598.png",
     "id": 6598,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6599",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6599.png",
     "id": 6599,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6600",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6600.png",
     "id": 6600,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6601",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6601.png",
     "id": 6601,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6602",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6602.png",
     "id": 6602,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #6603",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6603.png",
     "id": 6603,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6604",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6604.png",
     "id": 6604,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6605",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6605.png",
     "id": 6605,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6606",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6606.png",
     "id": 6606,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6607",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6607.png",
     "id": 6607,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6608",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6608.png",
     "id": 6608,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6609",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6609.png",
     "id": 6609,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6610",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6610.png",
     "id": 6610,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6611",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6611.png",
     "id": 6611,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6612",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6612.png",
     "id": 6612,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6613",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6613.png",
     "id": 6613,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6614",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6614.png",
     "id": 6614,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6615",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6615.png",
     "id": 6615,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6616",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6616.png",
     "id": 6616,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6617",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6617.png",
     "id": 6617,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6618",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6618.png",
     "id": 6618,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6619",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6619.png",
     "id": 6619,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6620",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6620.png",
     "id": 6620,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6621",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6621.png",
     "id": 6621,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6622",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6622.png",
     "id": 6622,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6623",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6623.png",
     "id": 6623,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6624",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6624.png",
     "id": 6624,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6625",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6625.png",
     "id": 6625,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6626",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6626.png",
     "id": 6626,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6627",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6627.png",
     "id": 6627,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6628",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6628.png",
     "id": 6628,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6629",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6629.png",
     "id": 6629,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6630",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6630.png",
     "id": 6630,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6631",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6631.png",
     "id": 6631,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6632",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6632.png",
     "id": 6632,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6633",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6633.png",
     "id": 6633,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6634",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6634.png",
     "id": 6634,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6635",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6635.png",
     "id": 6635,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6636",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6636.png",
     "id": 6636,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6637",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6637.png",
     "id": 6637,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6638",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6638.png",
     "id": 6638,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6639",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6639.png",
     "id": 6639,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6640",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6640.png",
     "id": 6640,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6641",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6641.png",
     "id": 6641,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6642",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6642.png",
     "id": 6642,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6643",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6643.png",
     "id": 6643,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6644",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6644.png",
     "id": 6644,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #6645",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6645.png",
     "id": 6645,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6646",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6646.png",
     "id": 6646,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6647",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6647.png",
     "id": 6647,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6648",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6648.png",
     "id": 6648,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6649",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6649.png",
     "id": 6649,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6650",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6650.png",
     "id": 6650,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6651",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6651.png",
     "id": 6651,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6652",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6652.png",
     "id": 6652,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6653",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6653.png",
     "id": 6653,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6654",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6654.png",
     "id": 6654,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6655",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6655.png",
     "id": 6655,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6656",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6656.png",
     "id": 6656,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6657",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6657.png",
     "id": 6657,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6658",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6658.png",
     "id": 6658,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6659",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6659.png",
     "id": 6659,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6660",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6660.png",
     "id": 6660,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6661",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6661.png",
     "id": 6661,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6662",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6662.png",
     "id": 6662,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6663",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6663.png",
     "id": 6663,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6664",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6664.png",
     "id": 6664,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Ghost Dusters"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost Dusters"
      },
      {
       "trait_type": "Crypto",
       "value": "Ghost Dusters"
      },
      {
       "trait_type": "Activity",
       "value": "Ghost Dusters"
      },
      {
       "trait_type": "Outfit",
       "value": "Ghost Dusters"
      },
      {
       "trait_type": "Transportation",
       "value": "Ghost Dusters"
      },
      {
       "trait_type": "Head",
       "value": "Ghost Dusters"
      },
      {
       "trait_type": "Mouth",
       "value": "Ghost Dusters"
      },
      {
       "trait_type": "Eyes",
       "value": "Ghost Dusters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6665",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6665.png",
     "id": 6665,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6666",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6666.png",
     "id": 6666,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6667",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6667.png",
     "id": 6667,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6668",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6668.png",
     "id": 6668,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6669",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6669.png",
     "id": 6669,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6670",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6670.png",
     "id": 6670,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6671",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6671.png",
     "id": 6671,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6672",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6672.png",
     "id": 6672,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6673",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6673.png",
     "id": 6673,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6674",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6674.png",
     "id": 6674,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6675",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6675.png",
     "id": 6675,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6676",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6676.png",
     "id": 6676,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6677",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6677.png",
     "id": 6677,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6678",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6678.png",
     "id": 6678,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6679",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6679.png",
     "id": 6679,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6680",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6680.png",
     "id": 6680,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6681",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6681.png",
     "id": 6681,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6682",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6682.png",
     "id": 6682,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6683",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6683.png",
     "id": 6683,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6684",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6684.png",
     "id": 6684,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6685",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6685.png",
     "id": 6685,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6686",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6686.png",
     "id": 6686,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6687",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6687.png",
     "id": 6687,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6688",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6688.png",
     "id": 6688,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6689",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6689.png",
     "id": 6689,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6690",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6690.png",
     "id": 6690,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6691",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6691.png",
     "id": 6691,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6692",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6692.png",
     "id": 6692,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6693",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6693.png",
     "id": 6693,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6694",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6694.png",
     "id": 6694,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6695",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6695.png",
     "id": 6695,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6696",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6696.png",
     "id": 6696,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6697",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6697.png",
     "id": 6697,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6698",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6698.png",
     "id": 6698,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6699",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6699.png",
     "id": 6699,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6700",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6700.png",
     "id": 6700,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6701",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6701.png",
     "id": 6701,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6702",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6702.png",
     "id": 6702,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6703",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6703.png",
     "id": 6703,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6704",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6704.png",
     "id": 6704,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6705",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6705.png",
     "id": 6705,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6706",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6706.png",
     "id": 6706,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6707",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6707.png",
     "id": 6707,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6708",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6708.png",
     "id": 6708,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6709",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6709.png",
     "id": 6709,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6710",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6710.png",
     "id": 6710,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6711",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6711.png",
     "id": 6711,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6712",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6712.png",
     "id": 6712,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #6713",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6713.png",
     "id": 6713,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6714",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6714.png",
     "id": 6714,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #6715",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6715.png",
     "id": 6715,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6716",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6716.png",
     "id": 6716,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6717",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6717.png",
     "id": 6717,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6718",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6718.png",
     "id": 6718,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6719",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6719.png",
     "id": 6719,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6720",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6720.png",
     "id": 6720,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6721",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6721.png",
     "id": 6721,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6722",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6722.png",
     "id": 6722,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6723",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6723.png",
     "id": 6723,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6724",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6724.png",
     "id": 6724,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6725",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6725.png",
     "id": 6725,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6726",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6726.png",
     "id": 6726,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6727",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6727.png",
     "id": 6727,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6728",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6728.png",
     "id": 6728,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6729",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6729.png",
     "id": 6729,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6730",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6730.png",
     "id": 6730,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6731",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6731.png",
     "id": 6731,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6732",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6732.png",
     "id": 6732,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6733",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6733.png",
     "id": 6733,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6734",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6734.png",
     "id": 6734,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6735",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6735.png",
     "id": 6735,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6736",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6736.png",
     "id": 6736,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6737",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6737.png",
     "id": 6737,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6738",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6738.png",
     "id": 6738,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6739",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6739.png",
     "id": 6739,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6740",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6740.png",
     "id": 6740,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6741",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6741.png",
     "id": 6741,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6742",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6742.png",
     "id": 6742,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6743",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6743.png",
     "id": 6743,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6744",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6744.png",
     "id": 6744,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6745",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6745.png",
     "id": 6745,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6746",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6746.png",
     "id": 6746,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6747",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6747.png",
     "id": 6747,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6748",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6748.png",
     "id": 6748,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6749",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6749.png",
     "id": 6749,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6750",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6750.png",
     "id": 6750,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6751",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6751.png",
     "id": 6751,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6752",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6752.png",
     "id": 6752,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6753",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6753.png",
     "id": 6753,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6754",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6754.png",
     "id": 6754,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6755",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6755.png",
     "id": 6755,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6756",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6756.png",
     "id": 6756,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6757",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6757.png",
     "id": 6757,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6758",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6758.png",
     "id": 6758,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6759",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6759.png",
     "id": 6759,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6760",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6760.png",
     "id": 6760,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6761",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6761.png",
     "id": 6761,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6762",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6762.png",
     "id": 6762,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6763",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6763.png",
     "id": 6763,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6764",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6764.png",
     "id": 6764,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6765",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6765.png",
     "id": 6765,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6766",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6766.png",
     "id": 6766,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6767",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6767.png",
     "id": 6767,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6768",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6768.png",
     "id": 6768,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6769",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6769.png",
     "id": 6769,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6770",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6770.png",
     "id": 6770,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6771",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6771.png",
     "id": 6771,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6772",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6772.png",
     "id": 6772,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6773",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6773.png",
     "id": 6773,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6774",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6774.png",
     "id": 6774,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6775",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6775.png",
     "id": 6775,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6776",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6776.png",
     "id": 6776,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6777",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6777.png",
     "id": 6777,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6778",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6778.png",
     "id": 6778,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6779",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6779.png",
     "id": 6779,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6780",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6780.png",
     "id": 6780,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6781",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6781.png",
     "id": 6781,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6782",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6782.png",
     "id": 6782,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6783",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6783.png",
     "id": 6783,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6784",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6784.png",
     "id": 6784,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6785",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6785.png",
     "id": 6785,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6786",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6786.png",
     "id": 6786,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6787",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6787.png",
     "id": 6787,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6788",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6788.png",
     "id": 6788,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6789",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6789.png",
     "id": 6789,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6790",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6790.png",
     "id": 6790,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6791",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6791.png",
     "id": 6791,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6792",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6792.png",
     "id": 6792,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6793",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6793.png",
     "id": 6793,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6794",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6794.png",
     "id": 6794,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6795",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6795.png",
     "id": 6795,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6796",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6796.png",
     "id": 6796,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6797",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6797.png",
     "id": 6797,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6798",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6798.png",
     "id": 6798,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6799",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6799.png",
     "id": 6799,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6800",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6800.png",
     "id": 6800,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6801",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6801.png",
     "id": 6801,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6802",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6802.png",
     "id": 6802,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6803",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6803.png",
     "id": 6803,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6804",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6804.png",
     "id": 6804,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6805",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6805.png",
     "id": 6805,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6806",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6806.png",
     "id": 6806,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6807",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6807.png",
     "id": 6807,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6808",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6808.png",
     "id": 6808,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6809",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6809.png",
     "id": 6809,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6810",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6810.png",
     "id": 6810,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6811",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6811.png",
     "id": 6811,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6812",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6812.png",
     "id": 6812,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6813",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6813.png",
     "id": 6813,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6814",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6814.png",
     "id": 6814,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6815",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6815.png",
     "id": 6815,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6816",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6816.png",
     "id": 6816,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6817",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6817.png",
     "id": 6817,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6818",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6818.png",
     "id": 6818,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6819",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6819.png",
     "id": 6819,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6820",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6820.png",
     "id": 6820,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6821",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6821.png",
     "id": 6821,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6822",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6822.png",
     "id": 6822,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6823",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6823.png",
     "id": 6823,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6824",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6824.png",
     "id": 6824,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6825",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6825.png",
     "id": 6825,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6826",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6826.png",
     "id": 6826,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6827",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6827.png",
     "id": 6827,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6828",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6828.png",
     "id": 6828,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6829",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6829.png",
     "id": 6829,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6830",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6830.png",
     "id": 6830,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6831",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6831.png",
     "id": 6831,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6832",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6832.png",
     "id": 6832,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6833",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6833.png",
     "id": 6833,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6834",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6834.png",
     "id": 6834,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6835",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6835.png",
     "id": 6835,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6836",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6836.png",
     "id": 6836,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6837",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6837.png",
     "id": 6837,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6838",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6838.png",
     "id": 6838,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6839",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6839.png",
     "id": 6839,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6840",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6840.png",
     "id": 6840,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6841",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6841.png",
     "id": 6841,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6842",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6842.png",
     "id": 6842,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6843",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6843.png",
     "id": 6843,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6844",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6844.png",
     "id": 6844,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6845",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6845.png",
     "id": 6845,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6846",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6846.png",
     "id": 6846,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6847",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6847.png",
     "id": 6847,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6848",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6848.png",
     "id": 6848,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6849",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6849.png",
     "id": 6849,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6850",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6850.png",
     "id": 6850,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6851",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6851.png",
     "id": 6851,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6852",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6852.png",
     "id": 6852,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6853",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6853.png",
     "id": 6853,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6854",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6854.png",
     "id": 6854,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6855",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6855.png",
     "id": 6855,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6856",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6856.png",
     "id": 6856,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6857",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6857.png",
     "id": 6857,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6858",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6858.png",
     "id": 6858,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6859",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6859.png",
     "id": 6859,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6860",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6860.png",
     "id": 6860,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6861",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6861.png",
     "id": 6861,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6862",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6862.png",
     "id": 6862,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6863",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6863.png",
     "id": 6863,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6864",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6864.png",
     "id": 6864,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6865",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6865.png",
     "id": 6865,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6866",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6866.png",
     "id": 6866,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6867",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6867.png",
     "id": 6867,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6868",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6868.png",
     "id": 6868,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6869",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6869.png",
     "id": 6869,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6870",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6870.png",
     "id": 6870,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6871",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6871.png",
     "id": 6871,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6872",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6872.png",
     "id": 6872,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6873",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6873.png",
     "id": 6873,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6874",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6874.png",
     "id": 6874,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6875",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6875.png",
     "id": 6875,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #6876",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6876.png",
     "id": 6876,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #6877",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6877.png",
     "id": 6877,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6878",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6878.png",
     "id": 6878,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6879",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6879.png",
     "id": 6879,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6880",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6880.png",
     "id": 6880,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6881",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6881.png",
     "id": 6881,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6882",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6882.png",
     "id": 6882,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6883",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6883.png",
     "id": 6883,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6884",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6884.png",
     "id": 6884,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6885",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6885.png",
     "id": 6885,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6886",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6886.png",
     "id": 6886,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6887",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6887.png",
     "id": 6887,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6888",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6888.png",
     "id": 6888,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6889",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6889.png",
     "id": 6889,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6890",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6890.png",
     "id": 6890,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6891",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6891.png",
     "id": 6891,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6892",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6892.png",
     "id": 6892,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6893",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6893.png",
     "id": 6893,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6894",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6894.png",
     "id": 6894,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #6895",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6895.png",
     "id": 6895,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6896",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6896.png",
     "id": 6896,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6897",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6897.png",
     "id": 6897,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6898",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6898.png",
     "id": 6898,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6899",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6899.png",
     "id": 6899,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6900",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6900.png",
     "id": 6900,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6901",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6901.png",
     "id": 6901,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6902",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6902.png",
     "id": 6902,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6903",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6903.png",
     "id": 6903,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6904",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6904.png",
     "id": 6904,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6905",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6905.png",
     "id": 6905,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6906",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6906.png",
     "id": 6906,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6907",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6907.png",
     "id": 6907,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6908",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6908.png",
     "id": 6908,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6909",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6909.png",
     "id": 6909,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6910",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6910.png",
     "id": 6910,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6911",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6911.png",
     "id": 6911,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6912",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6912.png",
     "id": 6912,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6913",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6913.png",
     "id": 6913,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6914",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6914.png",
     "id": 6914,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6915",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6915.png",
     "id": 6915,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6916",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6916.png",
     "id": 6916,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6917",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6917.png",
     "id": 6917,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6918",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6918.png",
     "id": 6918,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6919",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6919.png",
     "id": 6919,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6920",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6920.png",
     "id": 6920,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6921",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6921.png",
     "id": 6921,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6922",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6922.png",
     "id": 6922,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6923",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6923.png",
     "id": 6923,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6924",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6924.png",
     "id": 6924,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #6925",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6925.png",
     "id": 6925,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6926",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6926.png",
     "id": 6926,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6927",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6927.png",
     "id": 6927,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6928",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6928.png",
     "id": 6928,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6929",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6929.png",
     "id": 6929,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6930",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6930.png",
     "id": 6930,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6931",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6931.png",
     "id": 6931,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6932",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6932.png",
     "id": 6932,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6933",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6933.png",
     "id": 6933,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6934",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6934.png",
     "id": 6934,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6935",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6935.png",
     "id": 6935,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6936",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6936.png",
     "id": 6936,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6937",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6937.png",
     "id": 6937,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #6938",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6938.png",
     "id": 6938,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6939",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6939.png",
     "id": 6939,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #6940",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6940.png",
     "id": 6940,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6941",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6941.png",
     "id": 6941,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6942",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6942.png",
     "id": 6942,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6943",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6943.png",
     "id": 6943,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #6944",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6944.png",
     "id": 6944,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6945",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6945.png",
     "id": 6945,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6946",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6946.png",
     "id": 6946,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #6947",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6947.png",
     "id": 6947,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6948",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6948.png",
     "id": 6948,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #6949",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6949.png",
     "id": 6949,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6950",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6950.png",
     "id": 6950,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6951",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6951.png",
     "id": 6951,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6952",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6952.png",
     "id": 6952,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6953",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6953.png",
     "id": 6953,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6954",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6954.png",
     "id": 6954,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6955",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6955.png",
     "id": 6955,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6956",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6956.png",
     "id": 6956,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #6957",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6957.png",
     "id": 6957,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6958",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6958.png",
     "id": 6958,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6959",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6959.png",
     "id": 6959,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6960",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6960.png",
     "id": 6960,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6961",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6961.png",
     "id": 6961,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6962",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6962.png",
     "id": 6962,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6963",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6963.png",
     "id": 6963,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6964",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6964.png",
     "id": 6964,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #6965",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6965.png",
     "id": 6965,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6966",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6966.png",
     "id": 6966,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #6967",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6967.png",
     "id": 6967,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6968",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6968.png",
     "id": 6968,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6969",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6969.png",
     "id": 6969,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6970",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6970.png",
     "id": 6970,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6971",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6971.png",
     "id": 6971,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6972",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6972.png",
     "id": 6972,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #6973",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6973.png",
     "id": 6973,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6974",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6974.png",
     "id": 6974,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #6975",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6975.png",
     "id": 6975,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6976",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6976.png",
     "id": 6976,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6977",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6977.png",
     "id": 6977,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6978",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6978.png",
     "id": 6978,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #6979",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6979.png",
     "id": 6979,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6980",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6980.png",
     "id": 6980,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #6981",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6981.png",
     "id": 6981,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #6982",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6982.png",
     "id": 6982,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6983",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6983.png",
     "id": 6983,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6984",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6984.png",
     "id": 6984,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #6985",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6985.png",
     "id": 6985,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #6986",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6986.png",
     "id": 6986,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6987",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6987.png",
     "id": 6987,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6988",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6988.png",
     "id": 6988,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #6989",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6989.png",
     "id": 6989,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6990",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6990.png",
     "id": 6990,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #6991",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6991.png",
     "id": 6991,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #6992",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6992.png",
     "id": 6992,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6993",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6993.png",
     "id": 6993,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #6994",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6994.png",
     "id": 6994,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6995",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6995.png",
     "id": 6995,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #6996",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6996.png",
     "id": 6996,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #6997",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6997.png",
     "id": 6997,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #6998",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6998.png",
     "id": 6998,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #6999",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/6999.png",
     "id": 6999,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7000",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7000.png",
     "id": 7000,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7001",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7001.png",
     "id": 7001,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7002",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7002.png",
     "id": 7002,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7003",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7003.png",
     "id": 7003,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7004",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7004.png",
     "id": 7004,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7005",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7005.png",
     "id": 7005,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7006",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7006.png",
     "id": 7006,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7007",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7007.png",
     "id": 7007,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7008",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7008.png",
     "id": 7008,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7009",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7009.png",
     "id": 7009,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7010",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7010.png",
     "id": 7010,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7011",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7011.png",
     "id": 7011,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7012",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7012.png",
     "id": 7012,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7013",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7013.png",
     "id": 7013,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7014",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7014.png",
     "id": 7014,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7015",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7015.png",
     "id": 7015,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7016",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7016.png",
     "id": 7016,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7017",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7017.png",
     "id": 7017,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7018",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7018.png",
     "id": 7018,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7019",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7019.png",
     "id": 7019,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7020",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7020.png",
     "id": 7020,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7021",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7021.png",
     "id": 7021,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7022",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7022.png",
     "id": 7022,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7023",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7023.png",
     "id": 7023,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7024",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7024.png",
     "id": 7024,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #7025",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7025.png",
     "id": 7025,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7026",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7026.png",
     "id": 7026,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7027",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7027.png",
     "id": 7027,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7028",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7028.png",
     "id": 7028,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #7029",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7029.png",
     "id": 7029,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7030",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7030.png",
     "id": 7030,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7031",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7031.png",
     "id": 7031,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7032",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7032.png",
     "id": 7032,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7033",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7033.png",
     "id": 7033,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7034",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7034.png",
     "id": 7034,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7035",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7035.png",
     "id": 7035,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7036",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7036.png",
     "id": 7036,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7037",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7037.png",
     "id": 7037,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7038",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7038.png",
     "id": 7038,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7039",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7039.png",
     "id": 7039,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "BoomX"
      },
      {
       "trait_type": "Skin",
       "value": "BoomX"
      },
      {
       "trait_type": "Crypto",
       "value": "BoomX"
      },
      {
       "trait_type": "Activity",
       "value": "BoomX"
      },
      {
       "trait_type": "Outfit",
       "value": "BoomX"
      },
      {
       "trait_type": "Transportation",
       "value": "BoomX"
      },
      {
       "trait_type": "Head",
       "value": "BoomX"
      },
      {
       "trait_type": "Mouth",
       "value": "BoomX"
      },
      {
       "trait_type": "Eyes",
       "value": "BoomX"
      }
     ]
    },
    {
     "name": "Boomer Squad #7040",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7040.png",
     "id": 7040,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7041",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7041.png",
     "id": 7041,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7042",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7042.png",
     "id": 7042,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7043",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7043.png",
     "id": 7043,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7044",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7044.png",
     "id": 7044,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7045",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7045.png",
     "id": 7045,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7046",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7046.png",
     "id": 7046,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7047",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7047.png",
     "id": 7047,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7048",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7048.png",
     "id": 7048,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7049",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7049.png",
     "id": 7049,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7050",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7050.png",
     "id": 7050,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7051",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7051.png",
     "id": 7051,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7052",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7052.png",
     "id": 7052,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7053",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7053.png",
     "id": 7053,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7054",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7054.png",
     "id": 7054,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7055",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7055.png",
     "id": 7055,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7056",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7056.png",
     "id": 7056,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7057",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7057.png",
     "id": 7057,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7058",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7058.png",
     "id": 7058,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7059",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7059.png",
     "id": 7059,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7060",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7060.png",
     "id": 7060,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7061",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7061.png",
     "id": 7061,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7062",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7062.png",
     "id": 7062,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7063",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7063.png",
     "id": 7063,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7064",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7064.png",
     "id": 7064,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7065",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7065.png",
     "id": 7065,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7066",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7066.png",
     "id": 7066,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7067",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7067.png",
     "id": 7067,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7068",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7068.png",
     "id": 7068,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7069",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7069.png",
     "id": 7069,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7070",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7070.png",
     "id": 7070,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7071",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7071.png",
     "id": 7071,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7072",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7072.png",
     "id": 7072,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7073",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7073.png",
     "id": 7073,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7074",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7074.png",
     "id": 7074,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7075",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7075.png",
     "id": 7075,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7076",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7076.png",
     "id": 7076,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7077",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7077.png",
     "id": 7077,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7078",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7078.png",
     "id": 7078,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7079",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7079.png",
     "id": 7079,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7080",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7080.png",
     "id": 7080,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7081",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7081.png",
     "id": 7081,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #7082",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7082.png",
     "id": 7082,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7083",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7083.png",
     "id": 7083,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7084",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7084.png",
     "id": 7084,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7085",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7085.png",
     "id": 7085,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7086",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7086.png",
     "id": 7086,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7087",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7087.png",
     "id": 7087,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7088",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7088.png",
     "id": 7088,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7089",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7089.png",
     "id": 7089,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7090",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7090.png",
     "id": 7090,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7091",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7091.png",
     "id": 7091,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7092",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7092.png",
     "id": 7092,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7093",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7093.png",
     "id": 7093,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7094",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7094.png",
     "id": 7094,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7095",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7095.png",
     "id": 7095,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7096",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7096.png",
     "id": 7096,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7097",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7097.png",
     "id": 7097,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7098",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7098.png",
     "id": 7098,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7099",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7099.png",
     "id": 7099,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7100",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7100.png",
     "id": 7100,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7101",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7101.png",
     "id": 7101,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7102",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7102.png",
     "id": 7102,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7103",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7103.png",
     "id": 7103,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7104",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7104.png",
     "id": 7104,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7105",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7105.png",
     "id": 7105,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7106",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7106.png",
     "id": 7106,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7107",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7107.png",
     "id": 7107,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7108",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7108.png",
     "id": 7108,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7109",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7109.png",
     "id": 7109,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7110",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7110.png",
     "id": 7110,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7111",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7111.png",
     "id": 7111,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7112",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7112.png",
     "id": 7112,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7113",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7113.png",
     "id": 7113,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7114",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7114.png",
     "id": 7114,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7115",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7115.png",
     "id": 7115,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7116",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7116.png",
     "id": 7116,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7117",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7117.png",
     "id": 7117,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7118",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7118.png",
     "id": 7118,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7119",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7119.png",
     "id": 7119,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7120",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7120.png",
     "id": 7120,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7121",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7121.png",
     "id": 7121,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7122",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7122.png",
     "id": 7122,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7123",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7123.png",
     "id": 7123,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7124",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7124.png",
     "id": 7124,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7125",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7125.png",
     "id": 7125,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7126",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7126.png",
     "id": 7126,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7127",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7127.png",
     "id": 7127,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7128",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7128.png",
     "id": 7128,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7129",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7129.png",
     "id": 7129,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7130",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7130.png",
     "id": 7130,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7131",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7131.png",
     "id": 7131,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7132",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7132.png",
     "id": 7132,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7133",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7133.png",
     "id": 7133,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7134",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7134.png",
     "id": 7134,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7135",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7135.png",
     "id": 7135,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7136",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7136.png",
     "id": 7136,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7137",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7137.png",
     "id": 7137,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7138",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7138.png",
     "id": 7138,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7139",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7139.png",
     "id": 7139,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7140",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7140.png",
     "id": 7140,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7141",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7141.png",
     "id": 7141,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7142",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7142.png",
     "id": 7142,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7143",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7143.png",
     "id": 7143,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7144",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7144.png",
     "id": 7144,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7145",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7145.png",
     "id": 7145,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7146",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7146.png",
     "id": 7146,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7147",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7147.png",
     "id": 7147,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7148",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7148.png",
     "id": 7148,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7149",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7149.png",
     "id": 7149,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7150",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7150.png",
     "id": 7150,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7151",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7151.png",
     "id": 7151,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7152",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7152.png",
     "id": 7152,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7153",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7153.png",
     "id": 7153,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7154",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7154.png",
     "id": 7154,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7155",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7155.png",
     "id": 7155,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7156",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7156.png",
     "id": 7156,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7157",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7157.png",
     "id": 7157,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7158",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7158.png",
     "id": 7158,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7159",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7159.png",
     "id": 7159,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7160",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7160.png",
     "id": 7160,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7161",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7161.png",
     "id": 7161,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7162",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7162.png",
     "id": 7162,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7163",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7163.png",
     "id": 7163,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7164",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7164.png",
     "id": 7164,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7165",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7165.png",
     "id": 7165,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7166",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7166.png",
     "id": 7166,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7167",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7167.png",
     "id": 7167,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7168",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7168.png",
     "id": 7168,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7169",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7169.png",
     "id": 7169,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7170",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7170.png",
     "id": 7170,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7171",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7171.png",
     "id": 7171,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7172",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7172.png",
     "id": 7172,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7173",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7173.png",
     "id": 7173,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7174",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7174.png",
     "id": 7174,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7175",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7175.png",
     "id": 7175,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7176",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7176.png",
     "id": 7176,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7177",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7177.png",
     "id": 7177,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7178",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7178.png",
     "id": 7178,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7179",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7179.png",
     "id": 7179,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7180",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7180.png",
     "id": 7180,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #7181",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7181.png",
     "id": 7181,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7182",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7182.png",
     "id": 7182,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7183",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7183.png",
     "id": 7183,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7184",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7184.png",
     "id": 7184,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7185",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7185.png",
     "id": 7185,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7186",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7186.png",
     "id": 7186,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7187",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7187.png",
     "id": 7187,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7188",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7188.png",
     "id": 7188,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7189",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7189.png",
     "id": 7189,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7190",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7190.png",
     "id": 7190,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7191",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7191.png",
     "id": 7191,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7192",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7192.png",
     "id": 7192,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7193",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7193.png",
     "id": 7193,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7194",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7194.png",
     "id": 7194,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7195",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7195.png",
     "id": 7195,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7196",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7196.png",
     "id": 7196,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7197",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7197.png",
     "id": 7197,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7198",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7198.png",
     "id": 7198,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7199",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7199.png",
     "id": 7199,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7200",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7200.png",
     "id": 7200,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7201",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7201.png",
     "id": 7201,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7202",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7202.png",
     "id": 7202,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7203",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7203.png",
     "id": 7203,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7204",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7204.png",
     "id": 7204,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7205",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7205.png",
     "id": 7205,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7206",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7206.png",
     "id": 7206,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7207",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7207.png",
     "id": 7207,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7208",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7208.png",
     "id": 7208,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7209",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7209.png",
     "id": 7209,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7210",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7210.png",
     "id": 7210,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #7211",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7211.png",
     "id": 7211,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7212",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7212.png",
     "id": 7212,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7213",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7213.png",
     "id": 7213,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7214",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7214.png",
     "id": 7214,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7215",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7215.png",
     "id": 7215,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7216",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7216.png",
     "id": 7216,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7217",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7217.png",
     "id": 7217,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7218",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7218.png",
     "id": 7218,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7219",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7219.png",
     "id": 7219,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7220",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7220.png",
     "id": 7220,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7221",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7221.png",
     "id": 7221,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7222",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7222.png",
     "id": 7222,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7223",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7223.png",
     "id": 7223,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7224",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7224.png",
     "id": 7224,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7225",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7225.png",
     "id": 7225,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7226",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7226.png",
     "id": 7226,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7227",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7227.png",
     "id": 7227,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7228",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7228.png",
     "id": 7228,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7229",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7229.png",
     "id": 7229,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7230",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7230.png",
     "id": 7230,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7231",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7231.png",
     "id": 7231,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7232",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7232.png",
     "id": 7232,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7233",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7233.png",
     "id": 7233,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7234",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7234.png",
     "id": 7234,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7235",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7235.png",
     "id": 7235,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7236",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7236.png",
     "id": 7236,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7237",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7237.png",
     "id": 7237,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7238",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7238.png",
     "id": 7238,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7239",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7239.png",
     "id": 7239,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7240",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7240.png",
     "id": 7240,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7241",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7241.png",
     "id": 7241,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7242",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7242.png",
     "id": 7242,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7243",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7243.png",
     "id": 7243,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7244",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7244.png",
     "id": 7244,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7245",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7245.png",
     "id": 7245,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7246",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7246.png",
     "id": 7246,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7247",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7247.png",
     "id": 7247,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7248",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7248.png",
     "id": 7248,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7249",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7249.png",
     "id": 7249,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7250",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7250.png",
     "id": 7250,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7251",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7251.png",
     "id": 7251,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7252",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7252.png",
     "id": 7252,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7253",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7253.png",
     "id": 7253,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7254",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7254.png",
     "id": 7254,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7255",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7255.png",
     "id": 7255,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7256",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7256.png",
     "id": 7256,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7257",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7257.png",
     "id": 7257,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7258",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7258.png",
     "id": 7258,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7259",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7259.png",
     "id": 7259,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7260",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7260.png",
     "id": 7260,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7261",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7261.png",
     "id": 7261,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7262",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7262.png",
     "id": 7262,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7263",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7263.png",
     "id": 7263,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7264",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7264.png",
     "id": 7264,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7265",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7265.png",
     "id": 7265,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7266",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7266.png",
     "id": 7266,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7267",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7267.png",
     "id": 7267,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7268",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7268.png",
     "id": 7268,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7269",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7269.png",
     "id": 7269,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7270",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7270.png",
     "id": 7270,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7271",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7271.png",
     "id": 7271,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7272",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7272.png",
     "id": 7272,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7273",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7273.png",
     "id": 7273,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7274",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7274.png",
     "id": 7274,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7275",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7275.png",
     "id": 7275,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7276",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7276.png",
     "id": 7276,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7277",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7277.png",
     "id": 7277,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7278",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7278.png",
     "id": 7278,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7279",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7279.png",
     "id": 7279,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7280",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7280.png",
     "id": 7280,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7281",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7281.png",
     "id": 7281,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7282",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7282.png",
     "id": 7282,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7283",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7283.png",
     "id": 7283,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7284",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7284.png",
     "id": 7284,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7285",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7285.png",
     "id": 7285,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7286",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7286.png",
     "id": 7286,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7287",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7287.png",
     "id": 7287,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7288",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7288.png",
     "id": 7288,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7289",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7289.png",
     "id": 7289,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7290",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7290.png",
     "id": 7290,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7291",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7291.png",
     "id": 7291,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7292",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7292.png",
     "id": 7292,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7293",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7293.png",
     "id": 7293,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7294",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7294.png",
     "id": 7294,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7295",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7295.png",
     "id": 7295,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7296",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7296.png",
     "id": 7296,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7297",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7297.png",
     "id": 7297,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7298",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7298.png",
     "id": 7298,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7299",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7299.png",
     "id": 7299,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7300",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7300.png",
     "id": 7300,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7301",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7301.png",
     "id": 7301,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7302",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7302.png",
     "id": 7302,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7303",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7303.png",
     "id": 7303,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7304",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7304.png",
     "id": 7304,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7305",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7305.png",
     "id": 7305,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7306",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7306.png",
     "id": 7306,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7307",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7307.png",
     "id": 7307,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7308",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7308.png",
     "id": 7308,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7309",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7309.png",
     "id": 7309,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7310",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7310.png",
     "id": 7310,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7311",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7311.png",
     "id": 7311,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7312",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7312.png",
     "id": 7312,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7313",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7313.png",
     "id": 7313,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7314",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7314.png",
     "id": 7314,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7315",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7315.png",
     "id": 7315,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7316",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7316.png",
     "id": 7316,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7317",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7317.png",
     "id": 7317,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7318",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7318.png",
     "id": 7318,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7319",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7319.png",
     "id": 7319,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7320",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7320.png",
     "id": 7320,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7321",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7321.png",
     "id": 7321,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7322",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7322.png",
     "id": 7322,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7323",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7323.png",
     "id": 7323,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7324",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7324.png",
     "id": 7324,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7325",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7325.png",
     "id": 7325,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7326",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7326.png",
     "id": 7326,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7327",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7327.png",
     "id": 7327,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7328",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7328.png",
     "id": 7328,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7329",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7329.png",
     "id": 7329,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7330",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7330.png",
     "id": 7330,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7331",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7331.png",
     "id": 7331,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7332",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7332.png",
     "id": 7332,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7333",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7333.png",
     "id": 7333,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7334",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7334.png",
     "id": 7334,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7335",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7335.png",
     "id": 7335,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7336",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7336.png",
     "id": 7336,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7337",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7337.png",
     "id": 7337,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7338",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7338.png",
     "id": 7338,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7339",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7339.png",
     "id": 7339,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7340",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7340.png",
     "id": 7340,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7341",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7341.png",
     "id": 7341,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7342",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7342.png",
     "id": 7342,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7343",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7343.png",
     "id": 7343,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7344",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7344.png",
     "id": 7344,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7345",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7345.png",
     "id": 7345,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7346",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7346.png",
     "id": 7346,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7347",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7347.png",
     "id": 7347,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7348",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7348.png",
     "id": 7348,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7349",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7349.png",
     "id": 7349,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7350",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7350.png",
     "id": 7350,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7351",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7351.png",
     "id": 7351,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7352",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7352.png",
     "id": 7352,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7353",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7353.png",
     "id": 7353,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7354",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7354.png",
     "id": 7354,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7355",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7355.png",
     "id": 7355,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7356",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7356.png",
     "id": 7356,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7357",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7357.png",
     "id": 7357,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7358",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7358.png",
     "id": 7358,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7359",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7359.png",
     "id": 7359,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7360",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7360.png",
     "id": 7360,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7361",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7361.png",
     "id": 7361,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7362",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7362.png",
     "id": 7362,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7363",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7363.png",
     "id": 7363,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7364",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7364.png",
     "id": 7364,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7365",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7365.png",
     "id": 7365,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7366",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7366.png",
     "id": 7366,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7367",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7367.png",
     "id": 7367,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7368",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7368.png",
     "id": 7368,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7369",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7369.png",
     "id": 7369,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7370",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7370.png",
     "id": 7370,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7371",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7371.png",
     "id": 7371,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7372",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7372.png",
     "id": 7372,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7373",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7373.png",
     "id": 7373,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7374",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7374.png",
     "id": 7374,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7375",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7375.png",
     "id": 7375,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7376",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7376.png",
     "id": 7376,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7377",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7377.png",
     "id": 7377,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7378",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7378.png",
     "id": 7378,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7379",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7379.png",
     "id": 7379,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7380",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7380.png",
     "id": 7380,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7381",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7381.png",
     "id": 7381,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7382",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7382.png",
     "id": 7382,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7383",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7383.png",
     "id": 7383,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7384",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7384.png",
     "id": 7384,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7385",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7385.png",
     "id": 7385,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7386",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7386.png",
     "id": 7386,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7387",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7387.png",
     "id": 7387,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7388",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7388.png",
     "id": 7388,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7389",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7389.png",
     "id": 7389,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7390",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7390.png",
     "id": 7390,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7391",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7391.png",
     "id": 7391,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7392",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7392.png",
     "id": 7392,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7393",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7393.png",
     "id": 7393,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7394",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7394.png",
     "id": 7394,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7395",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7395.png",
     "id": 7395,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7396",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7396.png",
     "id": 7396,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7397",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7397.png",
     "id": 7397,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7398",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7398.png",
     "id": 7398,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7399",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7399.png",
     "id": 7399,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7400",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7400.png",
     "id": 7400,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7401",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7401.png",
     "id": 7401,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7402",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7402.png",
     "id": 7402,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7403",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7403.png",
     "id": 7403,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7404",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7404.png",
     "id": 7404,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7405",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7405.png",
     "id": 7405,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7406",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7406.png",
     "id": 7406,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7407",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7407.png",
     "id": 7407,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7408",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7408.png",
     "id": 7408,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7409",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7409.png",
     "id": 7409,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7410",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7410.png",
     "id": 7410,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7411",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7411.png",
     "id": 7411,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7412",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7412.png",
     "id": 7412,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7413",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7413.png",
     "id": 7413,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7414",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7414.png",
     "id": 7414,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7415",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7415.png",
     "id": 7415,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7416",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7416.png",
     "id": 7416,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7417",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7417.png",
     "id": 7417,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7418",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7418.png",
     "id": 7418,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "To the Moon"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7419",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7419.png",
     "id": 7419,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7420",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7420.png",
     "id": 7420,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7421",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7421.png",
     "id": 7421,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7422",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7422.png",
     "id": 7422,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7423",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7423.png",
     "id": 7423,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7424",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7424.png",
     "id": 7424,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7425",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7425.png",
     "id": 7425,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7426",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7426.png",
     "id": 7426,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7427",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7427.png",
     "id": 7427,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7428",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7428.png",
     "id": 7428,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7429",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7429.png",
     "id": 7429,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7430",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7430.png",
     "id": 7430,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7431",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7431.png",
     "id": 7431,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7432",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7432.png",
     "id": 7432,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7433",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7433.png",
     "id": 7433,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7434",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7434.png",
     "id": 7434,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7435",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7435.png",
     "id": 7435,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7436",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7436.png",
     "id": 7436,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7437",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7437.png",
     "id": 7437,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7438",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7438.png",
     "id": 7438,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7439",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7439.png",
     "id": 7439,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7440",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7440.png",
     "id": 7440,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7441",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7441.png",
     "id": 7441,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7442",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7442.png",
     "id": 7442,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7443",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7443.png",
     "id": 7443,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7444",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7444.png",
     "id": 7444,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #7445",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7445.png",
     "id": 7445,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7446",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7446.png",
     "id": 7446,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7447",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7447.png",
     "id": 7447,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7448",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7448.png",
     "id": 7448,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7449",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7449.png",
     "id": 7449,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7450",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7450.png",
     "id": 7450,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7451",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7451.png",
     "id": 7451,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7452",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7452.png",
     "id": 7452,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7453",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7453.png",
     "id": 7453,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7454",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7454.png",
     "id": 7454,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7455",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7455.png",
     "id": 7455,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7456",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7456.png",
     "id": 7456,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7457",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7457.png",
     "id": 7457,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7458",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7458.png",
     "id": 7458,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7459",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7459.png",
     "id": 7459,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7460",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7460.png",
     "id": 7460,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7461",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7461.png",
     "id": 7461,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7462",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7462.png",
     "id": 7462,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7463",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7463.png",
     "id": 7463,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7464",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7464.png",
     "id": 7464,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7465",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7465.png",
     "id": 7465,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7466",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7466.png",
     "id": 7466,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7467",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7467.png",
     "id": 7467,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7468",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7468.png",
     "id": 7468,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7469",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7469.png",
     "id": 7469,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7470",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7470.png",
     "id": 7470,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7471",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7471.png",
     "id": 7471,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7472",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7472.png",
     "id": 7472,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7473",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7473.png",
     "id": 7473,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7474",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7474.png",
     "id": 7474,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7475",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7475.png",
     "id": 7475,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7476",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7476.png",
     "id": 7476,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7477",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7477.png",
     "id": 7477,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7478",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7478.png",
     "id": 7478,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7479",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7479.png",
     "id": 7479,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7480",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7480.png",
     "id": 7480,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7481",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7481.png",
     "id": 7481,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7482",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7482.png",
     "id": 7482,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7483",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7483.png",
     "id": 7483,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7484",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7484.png",
     "id": 7484,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7485",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7485.png",
     "id": 7485,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7486",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7486.png",
     "id": 7486,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7487",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7487.png",
     "id": 7487,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7488",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7488.png",
     "id": 7488,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7489",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7489.png",
     "id": 7489,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7490",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7490.png",
     "id": 7490,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7491",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7491.png",
     "id": 7491,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7492",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7492.png",
     "id": 7492,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7493",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7493.png",
     "id": 7493,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7494",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7494.png",
     "id": 7494,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7495",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7495.png",
     "id": 7495,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7496",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7496.png",
     "id": 7496,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7497",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7497.png",
     "id": 7497,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7498",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7498.png",
     "id": 7498,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7499",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7499.png",
     "id": 7499,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7500",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7500.png",
     "id": 7500,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7501",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7501.png",
     "id": 7501,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7502",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7502.png",
     "id": 7502,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7503",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7503.png",
     "id": 7503,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7504",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7504.png",
     "id": 7504,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7505",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7505.png",
     "id": 7505,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7506",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7506.png",
     "id": 7506,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7507",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7507.png",
     "id": 7507,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7508",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7508.png",
     "id": 7508,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7509",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7509.png",
     "id": 7509,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7510",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7510.png",
     "id": 7510,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7511",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7511.png",
     "id": 7511,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7512",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7512.png",
     "id": 7512,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7513",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7513.png",
     "id": 7513,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7514",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7514.png",
     "id": 7514,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7515",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7515.png",
     "id": 7515,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7516",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7516.png",
     "id": 7516,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7517",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7517.png",
     "id": 7517,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #7518",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7518.png",
     "id": 7518,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7519",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7519.png",
     "id": 7519,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7520",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7520.png",
     "id": 7520,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7521",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7521.png",
     "id": 7521,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7522",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7522.png",
     "id": 7522,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7523",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7523.png",
     "id": 7523,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7524",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7524.png",
     "id": 7524,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7525",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7525.png",
     "id": 7525,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7526",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7526.png",
     "id": 7526,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7527",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7527.png",
     "id": 7527,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7528",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7528.png",
     "id": 7528,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7529",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7529.png",
     "id": 7529,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7530",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7530.png",
     "id": 7530,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7531",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7531.png",
     "id": 7531,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7532",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7532.png",
     "id": 7532,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7533",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7533.png",
     "id": 7533,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7534",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7534.png",
     "id": 7534,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7535",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7535.png",
     "id": 7535,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7536",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7536.png",
     "id": 7536,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7537",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7537.png",
     "id": 7537,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7538",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7538.png",
     "id": 7538,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7539",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7539.png",
     "id": 7539,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7540",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7540.png",
     "id": 7540,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7541",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7541.png",
     "id": 7541,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7542",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7542.png",
     "id": 7542,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7543",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7543.png",
     "id": 7543,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7544",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7544.png",
     "id": 7544,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7545",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7545.png",
     "id": 7545,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7546",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7546.png",
     "id": 7546,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7547",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7547.png",
     "id": 7547,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7548",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7548.png",
     "id": 7548,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7549",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7549.png",
     "id": 7549,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7550",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7550.png",
     "id": 7550,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7551",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7551.png",
     "id": 7551,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7552",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7552.png",
     "id": 7552,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7553",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7553.png",
     "id": 7553,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7554",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7554.png",
     "id": 7554,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7555",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7555.png",
     "id": 7555,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7556",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7556.png",
     "id": 7556,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7557",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7557.png",
     "id": 7557,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7558",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7558.png",
     "id": 7558,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7559",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7559.png",
     "id": 7559,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7560",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7560.png",
     "id": 7560,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7561",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7561.png",
     "id": 7561,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7562",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7562.png",
     "id": 7562,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7563",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7563.png",
     "id": 7563,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #7564",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7564.png",
     "id": 7564,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7565",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7565.png",
     "id": 7565,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7566",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7566.png",
     "id": 7566,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7567",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7567.png",
     "id": 7567,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7568",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7568.png",
     "id": 7568,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7569",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7569.png",
     "id": 7569,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7570",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7570.png",
     "id": 7570,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #7571",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7571.png",
     "id": 7571,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7572",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7572.png",
     "id": 7572,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7573",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7573.png",
     "id": 7573,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7574",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7574.png",
     "id": 7574,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7575",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7575.png",
     "id": 7575,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7576",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7576.png",
     "id": 7576,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7577",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7577.png",
     "id": 7577,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7578",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7578.png",
     "id": 7578,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7579",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7579.png",
     "id": 7579,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7580",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7580.png",
     "id": 7580,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7581",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7581.png",
     "id": 7581,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7582",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7582.png",
     "id": 7582,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7583",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7583.png",
     "id": 7583,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7584",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7584.png",
     "id": 7584,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7585",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7585.png",
     "id": 7585,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7586",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7586.png",
     "id": 7586,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7587",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7587.png",
     "id": 7587,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7588",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7588.png",
     "id": 7588,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7589",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7589.png",
     "id": 7589,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7590",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7590.png",
     "id": 7590,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7591",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7591.png",
     "id": 7591,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7592",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7592.png",
     "id": 7592,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7593",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7593.png",
     "id": 7593,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7594",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7594.png",
     "id": 7594,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7595",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7595.png",
     "id": 7595,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7596",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7596.png",
     "id": 7596,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7597",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7597.png",
     "id": 7597,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7598",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7598.png",
     "id": 7598,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7599",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7599.png",
     "id": 7599,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7600",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7600.png",
     "id": 7600,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7601",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7601.png",
     "id": 7601,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7602",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7602.png",
     "id": 7602,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7603",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7603.png",
     "id": 7603,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7604",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7604.png",
     "id": 7604,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7605",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7605.png",
     "id": 7605,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #7606",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7606.png",
     "id": 7606,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7607",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7607.png",
     "id": 7607,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7608",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7608.png",
     "id": 7608,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7609",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7609.png",
     "id": 7609,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7610",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7610.png",
     "id": 7610,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7611",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7611.png",
     "id": 7611,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7612",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7612.png",
     "id": 7612,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7613",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7613.png",
     "id": 7613,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7614",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7614.png",
     "id": 7614,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7615",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7615.png",
     "id": 7615,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7616",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7616.png",
     "id": 7616,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7617",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7617.png",
     "id": 7617,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7618",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7618.png",
     "id": 7618,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7619",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7619.png",
     "id": 7619,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7620",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7620.png",
     "id": 7620,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7621",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7621.png",
     "id": 7621,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7622",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7622.png",
     "id": 7622,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Arrr!"
      }
     ]
    },
    {
     "name": "Boomer Squad #7623",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7623.png",
     "id": 7623,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7624",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7624.png",
     "id": 7624,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7625",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7625.png",
     "id": 7625,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7626",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7626.png",
     "id": 7626,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7627",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7627.png",
     "id": 7627,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7628",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7628.png",
     "id": 7628,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7629",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7629.png",
     "id": 7629,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7630",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7630.png",
     "id": 7630,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7631",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7631.png",
     "id": 7631,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7632",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7632.png",
     "id": 7632,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7633",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7633.png",
     "id": 7633,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7634",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7634.png",
     "id": 7634,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7635",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7635.png",
     "id": 7635,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7636",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7636.png",
     "id": 7636,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7637",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7637.png",
     "id": 7637,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7638",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7638.png",
     "id": 7638,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7639",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7639.png",
     "id": 7639,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7640",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7640.png",
     "id": 7640,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7641",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7641.png",
     "id": 7641,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7642",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7642.png",
     "id": 7642,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7643",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7643.png",
     "id": 7643,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7644",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7644.png",
     "id": 7644,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7645",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7645.png",
     "id": 7645,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7646",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7646.png",
     "id": 7646,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7647",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7647.png",
     "id": 7647,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7648",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7648.png",
     "id": 7648,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7649",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7649.png",
     "id": 7649,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7650",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7650.png",
     "id": 7650,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7651",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7651.png",
     "id": 7651,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7652",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7652.png",
     "id": 7652,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7653",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7653.png",
     "id": 7653,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7654",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7654.png",
     "id": 7654,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7655",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7655.png",
     "id": 7655,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7656",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7656.png",
     "id": 7656,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7657",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7657.png",
     "id": 7657,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7658",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7658.png",
     "id": 7658,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7659",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7659.png",
     "id": 7659,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7660",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7660.png",
     "id": 7660,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7661",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7661.png",
     "id": 7661,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7662",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7662.png",
     "id": 7662,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7663",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7663.png",
     "id": 7663,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7664",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7664.png",
     "id": 7664,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7665",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7665.png",
     "id": 7665,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7666",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7666.png",
     "id": 7666,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7667",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7667.png",
     "id": 7667,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7668",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7668.png",
     "id": 7668,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7669",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7669.png",
     "id": 7669,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7670",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7670.png",
     "id": 7670,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7671",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7671.png",
     "id": 7671,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7672",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7672.png",
     "id": 7672,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7673",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7673.png",
     "id": 7673,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7674",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7674.png",
     "id": 7674,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7675",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7675.png",
     "id": 7675,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7676",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7676.png",
     "id": 7676,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7677",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7677.png",
     "id": 7677,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7678",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7678.png",
     "id": 7678,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7679",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7679.png",
     "id": 7679,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #7680",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7680.png",
     "id": 7680,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7681",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7681.png",
     "id": 7681,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7682",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7682.png",
     "id": 7682,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7683",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7683.png",
     "id": 7683,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7684",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7684.png",
     "id": 7684,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7685",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7685.png",
     "id": 7685,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7686",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7686.png",
     "id": 7686,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7687",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7687.png",
     "id": 7687,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7688",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7688.png",
     "id": 7688,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7689",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7689.png",
     "id": 7689,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7690",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7690.png",
     "id": 7690,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7691",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7691.png",
     "id": 7691,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7692",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7692.png",
     "id": 7692,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7693",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7693.png",
     "id": 7693,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7694",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7694.png",
     "id": 7694,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7695",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7695.png",
     "id": 7695,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7696",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7696.png",
     "id": 7696,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7697",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7697.png",
     "id": 7697,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7698",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7698.png",
     "id": 7698,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7699",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7699.png",
     "id": 7699,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7700",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7700.png",
     "id": 7700,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7701",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7701.png",
     "id": 7701,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7702",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7702.png",
     "id": 7702,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7703",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7703.png",
     "id": 7703,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7704",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7704.png",
     "id": 7704,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7705",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7705.png",
     "id": 7705,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7706",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7706.png",
     "id": 7706,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "King of Boom"
      },
      {
       "trait_type": "Skin",
       "value": "King of Boom"
      },
      {
       "trait_type": "Crypto",
       "value": "King of Boom"
      },
      {
       "trait_type": "Activity",
       "value": "King of Boom"
      },
      {
       "trait_type": "Outfit",
       "value": "King of Boom"
      },
      {
       "trait_type": "Transportation",
       "value": "King of Boom"
      },
      {
       "trait_type": "Head",
       "value": "King of Boom"
      },
      {
       "trait_type": "Mouth",
       "value": "King of Boom"
      },
      {
       "trait_type": "Eyes",
       "value": "King of Boom"
      }
     ]
    },
    {
     "name": "Boomer Squad #7707",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7707.png",
     "id": 7707,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7708",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7708.png",
     "id": 7708,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7709",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7709.png",
     "id": 7709,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7710",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7710.png",
     "id": 7710,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7711",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7711.png",
     "id": 7711,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7712",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7712.png",
     "id": 7712,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7713",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7713.png",
     "id": 7713,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7714",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7714.png",
     "id": 7714,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7715",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7715.png",
     "id": 7715,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7716",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7716.png",
     "id": 7716,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7717",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7717.png",
     "id": 7717,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7718",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7718.png",
     "id": 7718,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7719",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7719.png",
     "id": 7719,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7720",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7720.png",
     "id": 7720,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7721",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7721.png",
     "id": 7721,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7722",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7722.png",
     "id": 7722,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7723",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7723.png",
     "id": 7723,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7724",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7724.png",
     "id": 7724,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7725",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7725.png",
     "id": 7725,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7726",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7726.png",
     "id": 7726,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7727",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7727.png",
     "id": 7727,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7728",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7728.png",
     "id": 7728,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7729",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7729.png",
     "id": 7729,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7730",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7730.png",
     "id": 7730,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7731",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7731.png",
     "id": 7731,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7732",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7732.png",
     "id": 7732,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7733",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7733.png",
     "id": 7733,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7734",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7734.png",
     "id": 7734,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7735",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7735.png",
     "id": 7735,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7736",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7736.png",
     "id": 7736,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7737",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7737.png",
     "id": 7737,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7738",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7738.png",
     "id": 7738,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7739",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7739.png",
     "id": 7739,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7740",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7740.png",
     "id": 7740,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7741",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7741.png",
     "id": 7741,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7742",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7742.png",
     "id": 7742,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7743",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7743.png",
     "id": 7743,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7744",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7744.png",
     "id": 7744,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7745",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7745.png",
     "id": 7745,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7746",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7746.png",
     "id": 7746,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Halo"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7747",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7747.png",
     "id": 7747,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7748",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7748.png",
     "id": 7748,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7749",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7749.png",
     "id": 7749,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7750",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7750.png",
     "id": 7750,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7751",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7751.png",
     "id": 7751,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7752",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7752.png",
     "id": 7752,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7753",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7753.png",
     "id": 7753,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7754",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7754.png",
     "id": 7754,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7755",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7755.png",
     "id": 7755,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7756",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7756.png",
     "id": 7756,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7757",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7757.png",
     "id": 7757,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7758",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7758.png",
     "id": 7758,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7759",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7759.png",
     "id": 7759,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7760",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7760.png",
     "id": 7760,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7761",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7761.png",
     "id": 7761,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7762",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7762.png",
     "id": 7762,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7763",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7763.png",
     "id": 7763,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7764",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7764.png",
     "id": 7764,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7765",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7765.png",
     "id": 7765,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7766",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7766.png",
     "id": 7766,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7767",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7767.png",
     "id": 7767,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7768",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7768.png",
     "id": 7768,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7769",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7769.png",
     "id": 7769,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7770",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7770.png",
     "id": 7770,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7771",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7771.png",
     "id": 7771,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7772",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7772.png",
     "id": 7772,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7773",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7773.png",
     "id": 7773,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7774",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7774.png",
     "id": 7774,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7775",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7775.png",
     "id": 7775,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7776",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7776.png",
     "id": 7776,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pot of Gold"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7777",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7777.png",
     "id": 7777,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7778",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7778.png",
     "id": 7778,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7779",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7779.png",
     "id": 7779,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7780",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7780.png",
     "id": 7780,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7781",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7781.png",
     "id": 7781,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7782",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7782.png",
     "id": 7782,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7783",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7783.png",
     "id": 7783,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7784",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7784.png",
     "id": 7784,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7785",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7785.png",
     "id": 7785,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7786",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7786.png",
     "id": 7786,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7787",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7787.png",
     "id": 7787,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7788",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7788.png",
     "id": 7788,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7789",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7789.png",
     "id": 7789,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7790",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7790.png",
     "id": 7790,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7791",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7791.png",
     "id": 7791,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7792",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7792.png",
     "id": 7792,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7793",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7793.png",
     "id": 7793,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7794",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7794.png",
     "id": 7794,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7795",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7795.png",
     "id": 7795,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7796",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7796.png",
     "id": 7796,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7797",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7797.png",
     "id": 7797,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7798",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7798.png",
     "id": 7798,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7799",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7799.png",
     "id": 7799,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7800",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7800.png",
     "id": 7800,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7801",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7801.png",
     "id": 7801,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Holo"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7802",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7802.png",
     "id": 7802,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7803",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7803.png",
     "id": 7803,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7804",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7804.png",
     "id": 7804,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7805",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7805.png",
     "id": 7805,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7806",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7806.png",
     "id": 7806,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7807",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7807.png",
     "id": 7807,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7808",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7808.png",
     "id": 7808,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7809",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7809.png",
     "id": 7809,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7810",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7810.png",
     "id": 7810,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7811",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7811.png",
     "id": 7811,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7812",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7812.png",
     "id": 7812,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7813",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7813.png",
     "id": 7813,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7814",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7814.png",
     "id": 7814,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7815",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7815.png",
     "id": 7815,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7816",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7816.png",
     "id": 7816,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7817",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7817.png",
     "id": 7817,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7818",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7818.png",
     "id": 7818,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7819",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7819.png",
     "id": 7819,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7820",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7820.png",
     "id": 7820,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7821",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7821.png",
     "id": 7821,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7822",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7822.png",
     "id": 7822,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7823",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7823.png",
     "id": 7823,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7824",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7824.png",
     "id": 7824,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7825",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7825.png",
     "id": 7825,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7826",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7826.png",
     "id": 7826,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7827",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7827.png",
     "id": 7827,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7828",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7828.png",
     "id": 7828,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7829",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7829.png",
     "id": 7829,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7830",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7830.png",
     "id": 7830,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7831",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7831.png",
     "id": 7831,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7832",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7832.png",
     "id": 7832,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7833",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7833.png",
     "id": 7833,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Top Hat"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7834",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7834.png",
     "id": 7834,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7835",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7835.png",
     "id": 7835,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7836",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7836.png",
     "id": 7836,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7837",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7837.png",
     "id": 7837,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7838",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7838.png",
     "id": 7838,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7839",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7839.png",
     "id": 7839,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7840",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7840.png",
     "id": 7840,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7841",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7841.png",
     "id": 7841,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "HODL"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7842",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7842.png",
     "id": 7842,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7843",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7843.png",
     "id": 7843,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7844",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7844.png",
     "id": 7844,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7845",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7845.png",
     "id": 7845,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7846",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7846.png",
     "id": 7846,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7847",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7847.png",
     "id": 7847,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Mane"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7848",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7848.png",
     "id": 7848,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Jackpot"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7849",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7849.png",
     "id": 7849,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7850",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7850.png",
     "id": 7850,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7851",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7851.png",
     "id": 7851,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7852",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7852.png",
     "id": 7852,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Pinwheel"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7853",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7853.png",
     "id": 7853,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7854",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7854.png",
     "id": 7854,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7855",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7855.png",
     "id": 7855,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7856",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7856.png",
     "id": 7856,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7857",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7857.png",
     "id": 7857,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7858",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7858.png",
     "id": 7858,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7859",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7859.png",
     "id": 7859,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7860",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7860.png",
     "id": 7860,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7861",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7861.png",
     "id": 7861,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Prestigious Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7862",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7862.png",
     "id": 7862,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7863",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7863.png",
     "id": 7863,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7864",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7864.png",
     "id": 7864,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7865",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7865.png",
     "id": 7865,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7866",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7866.png",
     "id": 7866,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "NOS Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7867",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7867.png",
     "id": 7867,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7868",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7868.png",
     "id": 7868,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7869",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7869.png",
     "id": 7869,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7870",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7870.png",
     "id": 7870,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7871",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7871.png",
     "id": 7871,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7872",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7872.png",
     "id": 7872,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7873",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7873.png",
     "id": 7873,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7874",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7874.png",
     "id": 7874,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7875",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7875.png",
     "id": 7875,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7876",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7876.png",
     "id": 7876,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7877",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7877.png",
     "id": 7877,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7878",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7878.png",
     "id": 7878,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7879",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7879.png",
     "id": 7879,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7880",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7880.png",
     "id": 7880,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7881",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7881.png",
     "id": 7881,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7882",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7882.png",
     "id": 7882,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7883",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7883.png",
     "id": 7883,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7884",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7884.png",
     "id": 7884,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7885",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7885.png",
     "id": 7885,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7886",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7886.png",
     "id": 7886,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7887",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7887.png",
     "id": 7887,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7888",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7888.png",
     "id": 7888,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #7889",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7889.png",
     "id": 7889,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7890",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7890.png",
     "id": 7890,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7891",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7891.png",
     "id": 7891,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7892",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7892.png",
     "id": 7892,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7893",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7893.png",
     "id": 7893,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7894",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7894.png",
     "id": 7894,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7895",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7895.png",
     "id": 7895,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7896",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7896.png",
     "id": 7896,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7897",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7897.png",
     "id": 7897,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Prestigous"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7898",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7898.png",
     "id": 7898,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7899",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7899.png",
     "id": 7899,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7900",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7900.png",
     "id": 7900,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7901",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7901.png",
     "id": 7901,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7902",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7902.png",
     "id": 7902,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7903",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7903.png",
     "id": 7903,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7904",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7904.png",
     "id": 7904,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7905",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7905.png",
     "id": 7905,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7906",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7906.png",
     "id": 7906,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golden Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7907",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7907.png",
     "id": 7907,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7908",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7908.png",
     "id": 7908,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7909",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7909.png",
     "id": 7909,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7910",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7910.png",
     "id": 7910,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7911",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7911.png",
     "id": 7911,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7912",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7912.png",
     "id": 7912,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Stars"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7913",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7913.png",
     "id": 7913,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7914",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7914.png",
     "id": 7914,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7915",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7915.png",
     "id": 7915,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #7916",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7916.png",
     "id": 7916,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Gamer"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #7917",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7917.png",
     "id": 7917,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7918",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7918.png",
     "id": 7918,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7919",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7919.png",
     "id": 7919,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7920",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7920.png",
     "id": 7920,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7921",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7921.png",
     "id": 7921,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Staking"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7922",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7922.png",
     "id": 7922,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7923",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7923.png",
     "id": 7923,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7924",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7924.png",
     "id": 7924,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7925",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7925.png",
     "id": 7925,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7926",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7926.png",
     "id": 7926,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7927",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7927.png",
     "id": 7927,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7928",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7928.png",
     "id": 7928,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7929",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7929.png",
     "id": 7929,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7930",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7930.png",
     "id": 7930,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7931",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7931.png",
     "id": 7931,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7932",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7932.png",
     "id": 7932,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #7933",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7933.png",
     "id": 7933,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7934",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7934.png",
     "id": 7934,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7935",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7935.png",
     "id": 7935,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Cowboy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7936",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7936.png",
     "id": 7936,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7937",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7937.png",
     "id": 7937,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7938",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7938.png",
     "id": 7938,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Uh Oh!"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Lion"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7939",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7939.png",
     "id": 7939,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #7940",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7940.png",
     "id": 7940,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Money Bags"
      }
     ]
    },
    {
     "name": "Boomer Squad #7941",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7941.png",
     "id": 7941,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7942",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7942.png",
     "id": 7942,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Diamond"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Mah Lazer"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7943",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7943.png",
     "id": 7943,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Trippy"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bunny Ears"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7944",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7944.png",
     "id": 7944,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7945",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7945.png",
     "id": 7945,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7946",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7946.png",
     "id": 7946,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7947",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7947.png",
     "id": 7947,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7948",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7948.png",
     "id": 7948,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "BTC"
      },
      {
       "trait_type": "Activity",
       "value": "Utility"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7949",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7949.png",
     "id": 7949,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7950",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7950.png",
     "id": 7950,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7951",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7951.png",
     "id": 7951,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7952",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7952.png",
     "id": 7952,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "DYOR"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Beep Boop"
      }
     ]
    },
    {
     "name": "Boomer Squad #7953",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7953.png",
     "id": 7953,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Alien"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7954",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7954.png",
     "id": 7954,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Pirate"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7955",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7955.png",
     "id": 7955,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Deal With It"
      }
     ]
    },
    {
     "name": "Boomer Squad #7956",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7956.png",
     "id": 7956,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Cronos Booster"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7957",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7957.png",
     "id": 7957,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Shocked"
      },
      {
       "trait_type": "Eyes",
       "value": "Melted"
      }
     ]
    },
    {
     "name": "Boomer Squad #7958",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7958.png",
     "id": 7958,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Crying"
      }
     ]
    },
    {
     "name": "Boomer Squad #7959",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7959.png",
     "id": 7959,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Blunt"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7960",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7960.png",
     "id": 7960,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7961",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7961.png",
     "id": 7961,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Angry"
      }
     ]
    },
    {
     "name": "Boomer Squad #7962",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7962.png",
     "id": 7962,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Horsey"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Aviators"
      }
     ]
    },
    {
     "name": "Boomer Squad #7963",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7963.png",
     "id": 7963,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Degen"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7964",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7964.png",
     "id": 7964,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Red"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7965",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7965.png",
     "id": 7965,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7966",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7966.png",
     "id": 7966,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Boob Tube"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Whitepaper"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "Blue Walker"
      },
      {
       "trait_type": "Head",
       "value": "Scally Cap"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7967",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7967.png",
     "id": 7967,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7968",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7968.png",
     "id": 7968,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sleeping"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7969",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7969.png",
     "id": 7969,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7970",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7970.png",
     "id": 7970,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7971",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7971.png",
     "id": 7971,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #7972",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7972.png",
     "id": 7972,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Snow Day"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Mined"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Curly Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7973",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7973.png",
     "id": 7973,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7974",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7974.png",
     "id": 7974,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "Checkered"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7975",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7975.png",
     "id": 7975,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Dark Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Shaved"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    },
    {
     "name": "Boomer Squad #7976",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7976.png",
     "id": 7976,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Diamond Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Neutral"
      },
      {
       "trait_type": "Eyes",
       "value": "Scuba"
      }
     ]
    },
    {
     "name": "Boomer Squad #7977",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7977.png",
     "id": 7977,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Goggles"
      }
     ]
    },
    {
     "name": "Boomer Squad #7978",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7978.png",
     "id": 7978,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Cowboy"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Grey Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7979",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7979.png",
     "id": 7979,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Sunset"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7980",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7980.png",
     "id": 7980,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Purple"
      },
      {
       "trait_type": "Skin",
       "value": "Ghost"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Curly Blonde"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7981",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7981.png",
     "id": 7981,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Pink"
      },
      {
       "trait_type": "Skin",
       "value": "Gold"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Golf"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7982",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7982.png",
     "id": 7982,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Mint"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pink Cane"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #7983",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7983.png",
     "id": 7983,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Pink Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Cover Ups"
      }
     ]
    },
    {
     "name": "Boomer Squad #7984",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7984.png",
     "id": 7984,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Bag Carrier"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Angry"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7985",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7985.png",
     "id": 7985,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Safu"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Hustler"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7986",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7986.png",
     "id": 7986,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Lemon"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #7987",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7987.png",
     "id": 7987,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Blueberry"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Projection"
      },
      {
       "trait_type": "Outfit",
       "value": "Doctor"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7988",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7988.png",
     "id": 7988,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Happy"
      },
      {
       "trait_type": "Eyes",
       "value": "X_X"
      }
     ]
    },
    {
     "name": "Boomer Squad #7989",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7989.png",
     "id": 7989,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Yellow"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Send"
      },
      {
       "trait_type": "Outfit",
       "value": "White Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Punk"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7990",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7990.png",
     "id": 7990,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Cold Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Far Out"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Crown"
      },
      {
       "trait_type": "Mouth",
       "value": "Crying"
      },
      {
       "trait_type": "Eyes",
       "value": "Frustrated"
      }
     ]
    },
    {
     "name": "Boomer Squad #7991",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7991.png",
     "id": 7991,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Sloppy"
      },
      {
       "trait_type": "Transportation",
       "value": "Amber Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7992",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7992.png",
     "id": 7992,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Aqua"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Frustrated"
      },
      {
       "trait_type": "Eyes",
       "value": "Kawaii"
      }
     ]
    },
    {
     "name": "Boomer Squad #7993",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7993.png",
     "id": 7993,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Startup"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Black Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "New Years"
      },
      {
       "trait_type": "Eyes",
       "value": "Tired"
      }
     ]
    },
    {
     "name": "Boomer Squad #7994",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7994.png",
     "id": 7994,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Deep Sea"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "FOMO"
      },
      {
       "trait_type": "Outfit",
       "value": "Red Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Black Walker"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #7995",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7995.png",
     "id": 7995,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Gingerbread"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Tupe"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7996",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7996.png",
     "id": 7996,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "MMF"
      },
      {
       "trait_type": "Activity",
       "value": "Bubble Pop"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Sweater"
      },
      {
       "trait_type": "Transportation",
       "value": "Scooter"
      },
      {
       "trait_type": "Head",
       "value": "Curly Orange"
      },
      {
       "trait_type": "Mouth",
       "value": "Cigar"
      },
      {
       "trait_type": "Eyes",
       "value": "Hollywood"
      }
     ]
    },
    {
     "name": "Boomer Squad #7997",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7997.png",
     "id": 7997,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Green"
      },
      {
       "trait_type": "Skin",
       "value": "Orange"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Ride it out"
      },
      {
       "trait_type": "Outfit",
       "value": "Green BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Burgandy"
      },
      {
       "trait_type": "Mouth",
       "value": "Stoned"
      },
      {
       "trait_type": "Eyes",
       "value": "Estatic"
      }
     ]
    },
    {
     "name": "Boomer Squad #7998",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7998.png",
     "id": 7998,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Pay 2 Play"
      },
      {
       "trait_type": "Outfit",
       "value": "Hospital Gown"
      },
      {
       "trait_type": "Transportation",
       "value": "White Walker"
      },
      {
       "trait_type": "Head",
       "value": "Bald"
      },
      {
       "trait_type": "Mouth",
       "value": "Tired"
      },
      {
       "trait_type": "Eyes",
       "value": "Sour"
      }
     ]
    },
    {
     "name": "Boomer Squad #7999",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/7999.png",
     "id": 7999,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Hot Wallet"
      },
      {
       "trait_type": "Outfit",
       "value": "Bow Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Bindle"
      },
      {
       "trait_type": "Head",
       "value": "Mohawk"
      },
      {
       "trait_type": "Mouth",
       "value": "X"
      },
      {
       "trait_type": "Eyes",
       "value": "Cheaters"
      }
     ]
    },
    {
     "name": "Boomer Squad #8000",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/8000.png",
     "id": 8000,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Matrix"
      },
      {
       "trait_type": "Skin",
       "value": "Grape"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Chow Time"
      },
      {
       "trait_type": "Outfit",
       "value": "Judge"
      },
      {
       "trait_type": "Transportation",
       "value": "Red Cane"
      },
      {
       "trait_type": "Head",
       "value": "Long Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #8001",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/8001.png",
     "id": 8001,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Zoomer Spaces"
      },
      {
       "trait_type": "Outfit",
       "value": "Hustler"
      },
      {
       "trait_type": "Transportation",
       "value": "Brown Cane"
      },
      {
       "trait_type": "Head",
       "value": "Short Black"
      },
      {
       "trait_type": "Mouth",
       "value": "Ice Cream"
      },
      {
       "trait_type": "Eyes",
       "value": "Shocked"
      }
     ]
    },
    {
     "name": "Boomer Squad #8002",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/8002.png",
     "id": 8002,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Zombie"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Green Momo"
      },
      {
       "trait_type": "Transportation",
       "value": "Lion Cane"
      },
      {
       "trait_type": "Head",
       "value": "Rainbow Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #8003",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/8003.png",
     "id": 8003,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Salmon"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Buying JPEGs"
      },
      {
       "trait_type": "Outfit",
       "value": "White Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Long Gray"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #8004",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/8004.png",
     "id": 8004,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Blue"
      },
      {
       "trait_type": "Skin",
       "value": "Peach"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Black Tie"
      },
      {
       "trait_type": "Transportation",
       "value": "Pogo Stick"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Moustache"
      },
      {
       "trait_type": "Eyes",
       "value": "Neutral"
      }
     ]
    },
    {
     "name": "Boomer Squad #8005",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/8005.png",
     "id": 8005,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cotton Candy"
      },
      {
       "trait_type": "Skin",
       "value": "Beep Boop"
      },
      {
       "trait_type": "Crypto",
       "value": "DOGE"
      },
      {
       "trait_type": "Activity",
       "value": "Curated"
      },
      {
       "trait_type": "Outfit",
       "value": "Red BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Wizard Staff"
      },
      {
       "trait_type": "Head",
       "value": "Pirate"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Happy"
      }
     ]
    },
    {
     "name": "Boomer Squad #8006",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/8006.png",
     "id": 8006,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Cosmos"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "ETH"
      },
      {
       "trait_type": "Activity",
       "value": "Play 2 Earn"
      },
      {
       "trait_type": "Outfit",
       "value": "Black BoomSuit"
      },
      {
       "trait_type": "Transportation",
       "value": "Hustler Cane"
      },
      {
       "trait_type": "Head",
       "value": "Curlers"
      },
      {
       "trait_type": "Mouth",
       "value": "Full Beard"
      },
      {
       "trait_type": "Eyes",
       "value": "Laser Eyes"
      }
     ]
    },
    {
     "name": "Boomer Squad #8007",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/8007.png",
     "id": 8007,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Peanut"
      },
      {
       "trait_type": "Crypto",
       "value": "CRO"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Robe"
      },
      {
       "trait_type": "Transportation",
       "value": "Skateboard"
      },
      {
       "trait_type": "Head",
       "value": "Short Brown"
      },
      {
       "trait_type": "Mouth",
       "value": "Estatic"
      },
      {
       "trait_type": "Eyes",
       "value": "Sleeping"
      }
     ]
    },
    {
     "name": "Boomer Squad #8008",
     "description": "Collection of 8008 unique PFP's featuring Boomers with various aspects of blockchain technology",
     "image": "https://boomersquad.mypinata.cloud/ipfs/QmcoMVHnpjMpwcBwUL89QSn1WuwkS2UYd6yknz5sg81QVJ/8008.png",
     "id": 8008,
     "attributes": [
      {
       "trait_type": "Background",
       "value": "Orange"
      },
      {
       "trait_type": "Skin",
       "value": "Silver"
      },
      {
       "trait_type": "Crypto",
       "value": "SCRATCH"
      },
      {
       "trait_type": "Activity",
       "value": "Gwei"
      },
      {
       "trait_type": "Outfit",
       "value": "Blue Button Up"
      },
      {
       "trait_type": "Transportation",
       "value": "Headlight Walker"
      },
      {
       "trait_type": "Head",
       "value": "Orange Afro"
      },
      {
       "trait_type": "Mouth",
       "value": "Sour"
      },
      {
       "trait_type": "Eyes",
       "value": "Blazed"
      }
     ]
    }
]