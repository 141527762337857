export interface BazaarItem {
    id: string,
    stock: number,
    name: string,
    price: string,
    currency: string,
    images: string[],
    project: string,
    type: string,
    WLIndex: string,
    extraInfo: any[],
    technicalDetails: {
        productDimensions: string,
        itemWeight: string,
        manufactuer: string
    }
}

export interface ContractInventoryItem {
    item_id: string
    item_amount: number
}

export interface UserInventoryItem {
    item_id: string
    item_amount: number
    name: string
    image: string
    project: string
    type: string
}

export interface UserAddress {
    street_address: string,
    unit_number: string,
    city: string,
    state: string,
    zip_code: string,
    country: string
 }

export interface Item {
    item_id: number
    item_amount: number
 }


export const bazaarTestObj: BazaarItem[] = [
    {
        id: '1',
        stock: 100,
        name: 'Boomer Squad Official Trucker Hat',
        price: '1000',
        currency: 'SCRATCH',
        images: ['1','2','3','4','5'],
        project: 'Boomer Squad',
        type: 'Merch',
        WLIndex: '0',
        extraInfo: [
            "SHIPPING NOT INCLUDED",
            "Part of the first merch drop designed by the Boomer Squad team.",
            "This navy blue snap back trucker hat comes with the BS logo on the front of the hat, a Boomer matrix underbill, and a number of other easter eggs and nods to the collection and Cronos throughout the design."
        ],
        technicalDetails: {
            productDimensions: '9 x 7 x 5 inches',
            itemWeight: '12oz',
            manufactuer: 'N/A'
        }
    },
    {
        id: '2',
        stock: 1000,
        name: 'Boomer Squad Official Coin',
        price: '200',
        currency: 'SCRATCH',
        images: ['1','2','3','4','5'],
        project: 'Boomer Squad',
        type: 'Collectible',
        WLIndex: '0',
        extraInfo: [
            "SHIPPING INCLUDED",
            "Part of the first merch drop designed by the Boomer Squad team.",
            "This two tone coin has the Boomer man logo on one side and the BS logo on the other. With the weight and size close to a half dollar this is a great collectable for any Boomer collection."
        ],
        technicalDetails: {
            productDimensions: '1.5 x 1.5 x 0.2 inches',
            itemWeight: '30g',
            manufactuer: 'N/A'
        }
    },
    {
        id: '3',
        stock: 5,
        name: 'Cronos Gold by Cronos Fossils',
        price: '2000',
        currency: 'CRO',
        images: ['1','2'],
        project: 'Cronos Fossil',
        type: 'Collectible',
        WLIndex: '0',
        extraInfo: [
            "Your very own piece of CRONOS GOLD! Real Gold alluvial nuggets sourced from the outback of Western Australia by the Geologist duo founders of the Cronos Rocks Project.",
            "Each nugget is unique, near pure with traces of platinum and silver. Each CRONOS GOLD nugget is shipped in a black display box as shown."
        ],
        technicalDetails: {
            productDimensions: '38mm x 38mm x 20mm',
            itemWeight: '0.4g',
            manufactuer: 'N/A'
        }
    },
    {
        id: '4',
        stock: 50,
        name: 'Boomer Squad Sticker Pack',
        price: '150',
        currency: 'CRO',
        images: ['1'],
        project: 'Boomer Squad',
        type: 'Merch',
        WLIndex: '0',
        extraInfo: [
            "SHIPPING INCLUDED",
            "A total of 9 stickers, 3 of each kind. This is a different run of stickers then what was given in the free merch drop as these are bigger in size."
        ],
        technicalDetails: {
            productDimensions: '1 in. x 1 in.',
            itemWeight: '1 oz.',
            manufactuer: 'N/A'
        }
    },
    {
        id: '5',
        stock: 35,
        name: 'Zoomer Squad Beanie Hat',
        price: '1000',
        currency: 'SCRATCH',
        images: ['1','2'],
        project: 'Zoomer Squad',
        type: 'Merch',
        WLIndex: '0',
        extraInfo: [
            "SHIPPING NOT INCLUDED",
            "First limited run of Zoomer Squad Merch. This Gray Beanie comes with a Zoomer Squad Logo Patch on the front."
        ],
        technicalDetails: {
            productDimensions: '8 in. x 8 in.',
            itemWeight: '12 oz.',
            manufactuer: 'N/A'
        }
    },
    {
        id: '6',
        stock: 45,
        name: 'Cr00ts Official Beanie Hat',
        price: '240',
        currency: 'CRO',
        images: ['1','2'],
        project: 'Cr00ts',
        type: 'Merch',
        WLIndex: '1',
        extraInfo: [
            "SHIPPING NOT INCLUDED",
            "This official Cr00ts Beanie is exclusive to Cr00ts holders. Comes in the signature brown with the Cr00ts Logo patched on just like you can see on the collection. Shipping is not included."
        ],
        technicalDetails: {
            productDimensions: '8 in. x 8 in.',
            itemWeight: '12 oz.',
            manufactuer: 'N/A'
        }
    },
    {
        id: '7',
        stock: 28,
        name: 'Boomer Squad Summer Merch Kit',
        price: '6600',
        currency: 'SCRATCH',
        images: ['1','2'],
        project: 'Boomer Squad',
        type: 'Merch',
        WLIndex: '0',
        extraInfo: ["Boomer Squad Summer Merch Kit! This limited loot box contains all you need to take your drinks on the go while you are out touching grass. The backpack is big enough to nearly hold an entire 24 case of cans, or whatever other food and drink you want to keep cool on the go. The kit includes all of the following items:",
        "1 19x29x42cm Cooler Backpack",
        "1 Iron on Boomer Squad Patch (for backpack or anything else you like)",
        "1 17oz Boomer Stainless Steel Water Bottle",
        "1 Boomer Bottle Opener",
        "4 Boomer/Zoomer Themed foldable Koozies",
        "2 Zoomer Sunglasses",
        "1 55CM x 55CM BS Bandana",
        "Shipping is not included - EST 14 USD US / 28 USD Domestic *Pricing not exact"],
        technicalDetails: {
            productDimensions: '12x12x6in',
            itemWeight: '1.5 LBS',
            manufactuer: 'N/A'
        }
    },
    {
        id: '8',
        stock: 100,
        name: 'House Of Skulls Sticker Pack (3)',
        price: '3.48',
        currency: 'USDC',
        images: ['1'],
        project: 'House Of Skulls',
        type: 'Merch',
        WLIndex: '0',
        extraInfo: [
            "House of Skulls sticker pack. This comes with 3 total stickers, 1 of each design. These stickers are ~2.2in in diameter and shipping is included with this purchase"
        ],
        technicalDetails: {
            productDimensions: '1in x 1in',
            itemWeight: '30g',
            manufactuer: 'N/A'
        }
    },
    {
        id: '9',
        stock: 25,
        name: 'Boomer Squad Koozie 4 Pack',
        price: '250',
        currency: 'CRO',
        images: ['1', '2'],
        project: 'Boomer Squad',
        type: 'Merch',
        WLIndex: '0',
        extraInfo: [
            "Boomer Squad Koozie 4 Pack comes with 4 different foldable drink Koozies perfect for any 12 fl OZ can. This is also included in the Summer Merch Kit.", "SHIPPING NOT INCLUDED"
        ],
        technicalDetails: {
            productDimensions: '5-1/4″ x 3-7/8″',
            itemWeight: '2oz',
            manufactuer: 'N/A'
        }
    },
    {
        id: '10',
        stock: 30,
        name: 'Boomer Squad Bandana',
        price: '150',
        currency: 'CRO',
        images: ['1', '2'],
        project: 'Boomer Squad',
        type: 'Merch',
        WLIndex: '0',
        extraInfo: [
            "Boomer Squad Bandana is made with 100% cotton and is 55x55cm in size.", "SHIPPING NOT INCLUDED"
        ],
        technicalDetails: {
            productDimensions: '55x55cm',
            itemWeight: '1oz',
            manufactuer: 'N/A'
        }
    },
    {
        id: '11',
        stock: 30,
        name: 'Boomer Squad Water Bottle',
        price: '325',
        currency: 'CRO',
        images: ['1'],
        project: 'Boomer Squad',
        type: 'Merch',
        WLIndex: '0',
        extraInfo: [
            "Boomer Squad 500ml 17oz Stainless Steel Insulated Water Bottle.", "SHIPPING NOT INCLUDED"
        ],
        technicalDetails: {
            productDimensions: 'N/A',
            itemWeight: 'N/A',
            manufactuer: 'N/A'
        }
    }
]