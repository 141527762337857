import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import About from './about';
import MyBoomers from './boomers/myboomers'
import CronosFossilsLaunchpad from './launchpad/cronosfossils/cronosfossils';
import BobsAdventureLaunchpad from './launchpad/bobsadventure/bobsadventure';
import BoomRoomGame from './boom_rooms/boomroom_game';
import BoomRoomComponentMint from './boom_rooms/boomroom_componentmint';
import BoomRoomComponentBuilder from './boom_rooms/boomroom_componentbuilder';
import BoomRoomConstruction from './boom_rooms/boomroom_construction';
import BoomRoomDecorator from './boom_rooms/boomroom_roomdecorator';
import BoomRoomStaking from './boom_rooms/boomroom_staking';
import { Comics } from './comics/comics';
import { PawnShop } from './pawn_shop/pawnshop';
import { BoomerRaffle } from './raffles/boomerraffle';
import { BoomerBingo } from './pawn_shop/boomerbingo';
import BoomRoomStakingS2 from './boom_rooms/boomroom_stakingS2';
import ArtisanDoghouse from './launchpad/artisandoghouse/artisandoghouse';
import { FrontPage } from './frontpage/frontpage';
import { Bazaar } from './bazaar/bazaar';
import { BazaarItemFocus } from './bazaar/bazaarItemFocus';
import { BazaarUserInventory } from './bazaar/bazaarUserInventory';
import { News } from './newsletter/news';
import { Merch } from './merch/merch';
import { Zoomer } from './zoomers/zoomer';
import { MyZoomers } from './zoomers/myZoomers';
import { ZoomerMint } from './zoomers/zoomerMint';
import { LaunchpadFront } from './launchpad/launchpadfront';
import { SingleToken } from './tokens/singletoken';
import { AllTokens } from './tokens/alltokens';
import { CosmeticMinter } from './launchpad/cosmeticminter';
import { Boomer } from './boomers/boomer';
import { ChainCheckerVerify } from './chainchecker/chaincheckerverify';
import { CroRaffle } from './raffles/croraffle';
import { MintMicrochips } from './launchpad/microchips/microchips';
import { RafflesMain } from './raffles/raffles_main';
import { Sousou } from './launchpad/sousou/sousou';
import { PGMMint } from './playground/meme/PGMMint';
import { PGMPlayground } from './playground/meme/PGMPlayground';
import { PGMStats } from './playground/meme/PGMStats';

// used to auto generate the service worker, used to refresh cache automatically
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          {/* nested routes so they all render with the navbar without needing to duplicate navbar on each component */}
          {/* need both / and /home  */}
          <Route path="/" element={<FrontPage />} />
          <Route path="/frontpage" element={<FrontPage />} />
          {/* <Route path='/profile/:userAddress' element={<ProfileMain />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/comics" element={<Comics />} />
          <Route path="/myboomers" element={<MyBoomers />} />
          <Route path="/pawnshop" element={<PawnShop />} />
          <Route path="/boomerraffle" element={<BoomerRaffle />} />
          <Route path="/raffles" element={<RafflesMain />} />
          <Route path='/croraffle' element={<CroRaffle />} />
          <Route path="/boomerbingo" element={<BoomerBingo />} />
          {/* <Route path="/chowtime2" element={<ChowTime2 />} /> */}
          <Route path='/merch' element={<Merch />} />
          <Route path='/bazaar' element={<Bazaar />} />
          <Route path='/bazaar/:id' element={<BazaarItemFocus />} />
          <Route path='/bazaar/inventory' element={<BazaarUserInventory />} />
          <Route path='/launchpad' element={<LaunchpadFront />} />
          <Route path='/launchpad/cronosfossils' element={<CronosFossilsLaunchpad />} />
          <Route path='/launchpad/bobsadventure' element={<BobsAdventureLaunchpad />} />
          <Route path='/launchpad/aristodoghouse' element={<ArtisanDoghouse />} />
          {/* <Route path='/launchpad/ultrasavagest' element={<USS />} /> */}
          <Route path='/launchpad/microchips' element={<MintMicrochips />} />
          <Route path='/launchpad/sousou' element={<Sousou />} />
          <Route path='/boomrooms' element={<BoomRoomGame />}>
            <Route path='/boomrooms/roomconstruction' element={<BoomRoomConstruction />} />
            <Route path='/boomrooms/mintcomponent' element={<BoomRoomComponentMint />} />
            <Route path='/boomrooms/componentbuilder' element={<BoomRoomComponentBuilder />} />
            <Route path='/boomrooms/roomdecorator' element={<BoomRoomDecorator />} />
            <Route path='/boomrooms/stakinggrounds' element={<BoomRoomStaking />} />
            <Route path='/boomrooms/stakinggroundsv2' element={<BoomRoomStakingS2 />} />
          </Route>
          <Route path='/news/:id' element={<News />} />
          <Route path='token/:contractAddress/:id' element={<SingleToken />} />
          <Route path='cosmeticminter/:id' element={<CosmeticMinter />} />
          <Route path='collection/:contractAddress' element={<AllTokens />} />
          <Route path='/boomer/:id' element={<Boomer />} />
          <Route path='/zoomer/:id' element={<Zoomer />} />
          <Route path='/myzoomers' element={<MyZoomers />} />
          <Route path='/zoomermint' element={<ZoomerMint />} />
          {/* <Route path='/boomersquares' element={<FootballSquares />} />
          <Route path='boomersquares_how_to_play' element={<FootballSquaresGuide />} /> */}
          {/* <Route path ='/mintpgts' element={<MintPogs />} /> */}
          {/* <Route path ='/mintpgts2' element={<MintPGTs2 />} /> */}

          {/**Playground # Collab */}
          {/* <Route path='/mintpgtscollab' element={<MintPGTsCollab />} />
          <Route path='/viewpgtscollab' element={<ViewPGTsCollab />} />
          <Route path='theplaygroundcollab' element={<ThePlaygroundCollab />} />
          <Route path='/theplaygroundstatscollab' element={<PlaygroundStatsCollab />} /> */}

          {/**Playground # Collab2 */}
          {/* <Route path='/mintpgtscollab2' element={<MintPGTsCollab2 />} />
          <Route path='/viewpgtscollab2' element={<ViewPGTsCollab2 />} />
          <Route path='/theplaygroundcollab2' element={<ThePlaygroundCollab2 />} />
          <Route path='/theplaygroundstatscollab2' element={<PlaygroundStatsCollab2 />} /> */}

          {/**Playground # 2 */}


          {/**Playground # 2 */}
          {/* <Route path='/viewpgts2' element={<ViewPGTs2 />} />
          <Route path='theplayground2' element={<ThePlayground2 />} />
          <Route path='/theplaygroundstats2' element={<PlaygroundStats2 />} /> */}
          {/**Playground # 2 */}

          {/**Playground # 3 */}
          {/* <Route path='/playground3mintpgts' element={<MintPGTs3 />} />
          <Route path='/playground3viewpgts' element={<ViewPGTs3 />} />
          <Route path='/playground3' element={<ThePlayground3 />} />
          <Route path='/playground3stats' element={<PlaygroundStats3 />} /> */}
          {/**Playground # 3 */}

          {/**Playground # 4 */}
          {/* <Route path='/Playground4Deposit' element={<ViewPGTs4 />} /> */}
          {/* <Route path='/Playground4' element={<ThePlayground4 />} />
          <Route path='/Playground4Stats' element={<PlaygroundStats4 />} /> */}
          {/**Playground # 4 */}

          {/**Playground # 1 */}
          {/* <Route path='/viewpgts' element={<ViewPogs />} />
          <Route path='/theplayground' element={<ThePlayground />} />
          <Route path='/theplaygroundstats' element={<PlaygroundStats />} /> */}
          {/**Playground # 1 */}

          {/**Playground Meme */}
          {/* <Route path='/PlaygroundMemeMint' element={<PGMMint />} /> */}
          <Route path='/PlaygroundMeme' element={<PGMPlayground />} />
          <Route path='/PlaygroundMemeStats' element={<PGMStats />} />
          {/**Playground Meme */}

          <Route path='/chaincheckerverify' element={<ChainCheckerVerify />} />
          {/* <Route path='/swap' element={<Amm />} /> */}
          {/* <Route path ='/mintbdmc' element={<MintBDMC />} /> */}
          <Route path="*" element={<FrontPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
