import { Row } from 'antd';
import { useContext } from 'react';
import ScreenWidthContext from './context/screenwidthcontext';
import './mobilestyles.css'
import { webStorage } from './config';


function Footer() {

    //Screen Width context
    const { screenWidth, setScreenWidth } = useContext(ScreenWidthContext)

    const getCurrentYear = new Date().getFullYear()

    const helpText = `Questions or concerns, please email boomersquadnft@gmail.com or open a support ticket in our discord to speak directly with the team.`
    const addressText = `Boomer Squad LLC 1552 South Route 59 #1213 Naperville, IL 60564`
    const phoneText = `+1 (331)-758-4630`
    const legalText = `© Boomer Squad LLC ${getCurrentYear}. All rights reserved. Content and information provided by Boomer Squad LLC and its members does not, 
    and is not intended to constitute legal or financial advice. 
    You are responsible for doing your own research and investing at your own risk. Boomer Squad LLC is not responsible for the content provided by any third parties that link to its content. 
    This statement and the Boomer Squad LLC website do not constitute an offer to sell, a solicitation of an offer to buy, or a recommendation of any NFT, digital collectible, 
    cryptocurrency, security, or any other product or service by Boomer Squad, or any other third party regardless of whether such NFT, digital collectible, cryptocurrency, security, 
    product or service is referenced in this video or on the Boomer Squad website and documentation.

    Furthermore, this statement is not intended to provide tax, legal, or investment advice, and nothing in any published content, 
    videos, posts or Boomer Squad website should be construed as a recommendation to buy, sell, or hold any NFT, digital collectible, 
    cryptocurrency, investment or security, nor to engage in any collection strategy, investment strategy or transaction.
    
    Boomer Squad does not represent that the NFTs, digital collectibles, cryptocurrencies, securities, products are suitable for any 
    particular collector or investor. You are solely responsible for determining whether any NFT, investment, investment strategy, 
    security or related transaction is appropriate for you based on your personal NFT collection or investment objectives, financial 
    circumstances and risk tolerance. You should consult your business advisor, attorney, or tax and accounting advisor regarding your 
    specific business, legal or tax situation.© Boomer Squad LLC 2022. All rights reserved. Content and information provided by Boomer 
    Squad LLC and its members does not, and is not intended to constitute legal or financial advice. You are responsible for doing your own 
    research and investing at your own risk. Boomer Squad LLC is not responsible for the content provided by any third parties that link to its content.`

    return (

        screenWidth > 1279

            ?
            <div className='onepager-footer'>
                <div className='horizontal-teal-line-footer' />

                <Row justify='center' align='middle'>
                    <a href='https://discord.gg/boomer-squad-nft-939820143486320650' target='_blank'><img className='footer-socials-logo' src={webStorage + '/navbar/discordlogo.png'} alt="foo" /></a>
                    <a href='https://twitter.com/BoomerSquadNFT1' target="_blank"><img className='footer-socials-logo' src={webStorage + '/navbar/twitterlogo.png'} alt="foo" /></a>
                    <a href='https://medium.com/@BoomerSquadNFT' target="_blank"><img className='footer-socials-logo' src={webStorage + '/navbar/mediumlogo.png'} alt="foo" /></a>
                </Row>
                <Row justify='center' align='middle'>
                    <a href='https://boomer-squad.gitbook.io/boomer-squad-nft/' target='_blank'><div className='footer-navbar-extras-link'>Boomer Docs</div></a>
                    <a href='https://forms.gle/iwn5yLYaM978jmkm9' target='_blank'><div className='footer-navbar-extras-link'>Launchpad Application Form</div></a>
                </Row>
                <Row justify='center' align='middle'>
                    <div className='footer-legal font-15'>
                        {helpText}
                        <br></br>
                        {addressText}
                        <br></br>
                        {phoneText}
                    </div>
                </Row>
                <Row justify='center' align='middle'>
                    <div className='footer-legal'>
                        {legalText}
                    </div>
                </Row>
                <div style={{ height: '100%', backgroundColor: '#01060c' }}></div>
            </div>


            :
            <>
                <div className='mobile-horizontal-teal-line-footer' />
                <div className='mobile-onepager-footer'>
                    <Row justify='center' align='middle'>
                        <a href='https://discord.gg/boomer-squad-nft-939820143486320650' target='_blank'><img className='mobile-footer-socials-logo' src={webStorage + '/navbar/discordlogo.png'} alt="foo" /></a>
                        <a href='https://twitter.com/BoomerSquadNFT1' target="_blank"><img className='mobile-footer-socials-logo' src={webStorage + '/navbar/twitterlogo.png'} alt="foo" /></a>
                        <a href='https://medium.com/@BoomerSquadNFT' target="_blank"><img className='mobile-footer-socials-logo' src={webStorage + '/navbar/mediumlogo.png'} alt="foo" /></a>
                    </Row>
                    <Row justify='center' align='middle'>
                        <a href='https://boomer-squad.gitbook.io/boomer-squad-nft/' target='_blank'><div className='mobile-footer-extras-link'>Boomer Docs</div></a>
                        <a href='https://forms.gle/iwn5yLYaM978jmkm9' target='_blank'><div className='mobile-footer-extras-link'>Launchpad Application Form</div></a>
                    </Row>
                    <Row justify='center' align='middle'>
                        <div className='mobile-footer-legal font-15'>
                            {helpText}
                            <br></br>
                            {addressText}
                            <br></br>
                            {phoneText}
                        </div>
                    </Row>
                    <Row justify='center' align='middle'>
                        <div className='mobile-footer-legal'>
                            {legalText}
                        </div>
                    </Row>
                </div>
            </>
    )
}


export default Footer