import { useEffect, useState } from "react"
import { webStorage } from "../../config"
import { Row } from "antd"
import { truncateAddress } from "../../utils"
import { Pagination } from "../../components/pagination"
import { PGMNavButtons } from "./PGMNavButtons"
import { getPGMApiData } from "./PGMApiCalls"

export const PGMStats = () => {

    const [playgroundData, setPlaygroundData] = useState([] as any[])

    const YourComponent = ({ list }) => {

        // for sorting
        const [sort, setSort] = useState('Most Remaining');

        const objectsList = list;
        const objectsArray = Object.values(objectsList);

        const [currentPage, setCurrentPage] = useState(1);
        const itemsPerPage = 100;

        const handlePageChange = (newPage) => {
            setCurrentPage(newPage);
        };

        const handleSortMethodChange = (event) => {
            setSort(event.target.value);
        };

        const start = (currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        const totalPages = Math.ceil(list.length / itemsPerPage);

        const sortObjectsArray = () => {
            switch (sort) {
                case "Most Registered":
                    return objectsArray.sort((a: any, b: any) => b.registered.length - a.registered.length);
                case "Most Trashed":
                    return objectsArray.sort((a: any, b: any) => b.destroyed.length - a.destroyed.length);
                case "Most Chickened Out":
                    return objectsArray.sort((a: any, b: any) => b.removed.length - a.removed.length);
                case "Team":
                    return objectsArray.sort((a: any, b: any) => a.team.localeCompare(b.team));
                case "Most Remaining":
                default:
                    return objectsArray.sort((a: any, b: any) =>
                        (b.registered.length - b.destroyed.length - b.removed.length)
                        - (a.registered.length - a.destroyed.length - a.removed.length))
            }
        };

        const paginatedList = sortObjectsArray().slice(start, end);
        // replace paginated List below to get pages back
        const renderedList = paginatedList.map((item: any, index) => {
            const totalRegistered = item.registered.length;
            const totalRemoved = item.removed.length;
            const totalDestroyed = item.destroyed.length;
            const totalRemaining = totalRegistered - totalRemoved - totalDestroyed;

            return (
                <div key={index} className="playgroundstats-bar-row">

                    <div className="playtable">
                        <div className="playrow">
                            <div className="playcell">{start + index + 1}</div>
                            <div className="playcell">{truncateAddress(item.address, 3)}</div>
                            <div className="playcell" style={{ backgroundColor: sort === 'Most Remaining' ? '#98FB98' : '' }}>{totalRemaining}</div>
                            <div className="playcell" style={{ backgroundColor: sort === 'Most Registered' ? '#98FB98' : '' }}>{totalRegistered}</div>
                            <div className="playcell" style={{ backgroundColor: sort === 'Most Chickened Out' ? '#98FB98' : '' }}>{totalRemoved}</div>
                            <div className="playcell" style={{ backgroundColor: sort === 'Most Trashed' ? '#98FB98' : '' }}>{totalDestroyed}</div>
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <div className="playgroundstats-container">
                <div style={{ marginBottom: '20px', fontSize: '20px' }}>Playground Statistics</div>
                <div style={{ fontFamily: 'nowbold' }}>
                    <label htmlFor="sortMethod">Sort by: </label>
                    <select
                        name="sortMethod"
                        id="sortMethod"
                        value={sort}
                        onChange={handleSortMethodChange}
                    >
                        <option value="Most Remaining">Most Remaining</option>
                        <option value="Most Registered">Most Registered</option>
                        <option value="Most Chickened Out">Most Chickened Out</option>
                        <option value="Most Trashed">Most Trashed</option>
                    </select>
                </div>
                <div className="flex-and-center" style={{ width: '100%' }}>
                    <div className="playtable">
                        <div className="playrow">
                            <div className="playcell">Pos.</div>
                            <div className="playcell">Address</div>
                            <div className="playcell">Remain</div>
                            <div className="playcell">Regist.</div>
                            <div className="playcell">Chick'd.</div>
                            <div className="playcell">Trashed</div>
                        </div>
                    </div>
                </div>

                {renderedList}
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>
        )
    }

    type AddressData = {
        registered: string[];
        removed: string[];
        destroyed: string[];
    };

    type AddressEntry = {
        address: string;
        registered: string[];
        removed: string[];
        destroyed: string[];
    };
    useEffect(() => {
        const getPlaygroundPogs = async () => {
            let playgroundData = await getPGMApiData();
            const originalData: Record<string, AddressData> = playgroundData.addresses;
            const objectsArray = Object.entries(originalData).map(([address, item]) => ({
                address,
                destroyed: item.destroyed,
                registered: item.registered,
                removed: item.removed,
            }) as AddressEntry);
            return objectsArray;
        };
        getPlaygroundPogs().then((result) => {
            setPlaygroundData(result)
        })
    }, [])

    return (
        <div className='playground-background-cover'>
            <div className='centered-x-and-y'><img className='banner2' src={webStorage + '/playground/meme/playground_meme_banner.png'} alt='' /></div>
            <div style={{ marginTop: '20px' }}></div>
            <PGMNavButtons />
            <Row justify='center' style={{ gap: '10px' }}>
                <YourComponent list={playgroundData} />
            </Row>
        </div>
    )
}