
import { useState, useContext, useEffect } from 'react';
import { bazaarTestObj, UserInventoryItem } from "./bazaarTestObj"
import './bazaarUserInventory.css'
import checkNotMobile from "../components/logic/mobile";
import ScreenWidthContext from '../context/screenwidthcontext';
import Web3Context from '../context/web3context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { webStorage } from '../config';
import { BazaarOrder, checkValidAddressInfo, getUserInventory, shipItems, Item } from "./bazaarUtils";
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';

import { Loader } from '@googlemaps/js-api-loader';

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const axios = require('axios').default; // axios.<method> will now provide autocomplete and parameter typings


export const BazaarUserInventory = () => {


    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)


    const [userInventoryItems, setUserInventoryItems] = useState([] as UserInventoryItem[])
    const [indexes, setIndexes] = useState([] as number[])
    const [shipReqConfirmed, setShipReqConfirmed] = useState(false)

    // 1. select items to ship

    // 2. enter address and user information

    // 3. make contract tx

    // 4. post request to API with tx info + all user info


    // ADDRESS STARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTARTSTART
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [ext, setExt] = useState('')
    const [address, setAddress] = useState("")
    const [address2, setAddress2] = useState("")
    const [postal, setPostal] = useState('')
    const [tempEmail, setTempEmail] = useState('')
    const [email, setEmail] = useState("")
    const [validEmail, setValidEmail] = useState(false)
    const [discordTag, setDiscordTag] = useState("")

    const [predictions, setPredictions] = useState([] as string[])
    const loader = new Loader({
       apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
       version: "weekly",
       libraries: ["places"]
    });

    useEffect(() => {
        loader.load().then(async (google) => {
           const autocompleteService = new google.maps.places.AutocompleteService();
           autocompleteService.getPlacePredictions({ input: address }, (predics, status) => {
             if (status !== google.maps.places.PlacesServiceStatus.OK) {
               console.error(status);
               return;
             }
             let tempPredictions = predics.map(item => item.description)
             setPredictions(tempPredictions)
           });
         });
      }, [address])
  
     const changeEmail = (value) => {
        setTempEmail(value)
        if(isValidEmail(value)){
           setEmail(value)
           setValidEmail(true)
        } else {
           setValidEmail(false)
           setEmail('')
        }
     }
     const isValidEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return(re.test(email))
     }
  
     // Address prediction visibility
     const [visible, setVisible] = useState(false)
     const doStuffon = () => {setVisible(true)}
     const doStuffoff = () => {setVisible(false)}

     let addressInputs = (
            <div style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div className="user-info-input-form-container">
            <input type="text" placeholder="First Name" className="pretty-input name" value={firstName} onChange={(e) => {setFirstName(e.target.value)}}/>
            <input type="text" placeholder="Last Name" className="pretty-input name" value={lastName} onChange={(e) => {setLastName(e.target.value)}}/>
            <div className='address-field-container'>
                <input type="text" placeholder="Address Line 1" className={`pretty-input-address ${visible?'visible':''}`} value={address} onFocus={() => doStuffon()} onBlur={() => doStuffoff()} onChange={(e) => setAddress(e.target.value)}/>
                <div style={{position: 'absolute'}}>
                {predictions.map((prediction, key) => {
                    return(
                        <>{prediction !== address?
                            <div className={`prediction-address ${visible?'visible':'invisible'}`} onMouseDown={() => setAddress(prediction)}>
                                {prediction}
                            </div>:<></>
                        }</>
                    )
                    })}
                </div>
            </div>
                <input type="text" placeholder="*Address Line 2" className="pretty-input-address" value={address2} onChange={(e) => {setAddress2(e.target.value)}}/>
                <input type="text" placeholder="Postal Code" className="pretty-input name" value={postal} onChange={(e) => {setPostal(e.target.value)}}/>
                {/* <input type="text" placeholder="Phone Number" className="pretty-input name" value={phoneNumber} onChange={(e) => {setPhoneNumber(e.target.value.replace(/[^0-9]/g, ""))}}/> */}
                <input type="text" placeholder="*Ext" className="pretty-input name" value={ext} onChange={(e) => {setExt(e.target.value.replace(/[^0-9]/g, ""))}}/>
                <PhoneInput
                    country={"us"}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e)}
                />
                <div className="confirm-line"/>
                <div style={{color: 'white', fontSize: '20px'}}>
                    Prefered contact method **Requires at least one
                </div>
                <input type="email" placeholder="**Email" className={`pretty-input name ${validEmail?'valid':'invalid'}`} value={tempEmail} onChange={(e) => {changeEmail(e.target.value)}}/>
                <input type="text" placeholder="**Discord Tag" className="pretty-input name" value={discordTag} onChange={(e) => {setDiscordTag(e.target.value)}}/>
            </div>
            </div>
        )

    // ADDRESS END ENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDEND

    const InventoryCards = (props) => {
        let arr = props.list
        let index = 0
        let listItems = arr.map((item) => {return(
            <>
                <InventoryCard i={index} value={item}/>
                <script>{index++}</script>
            </>
        )
        })
        return(<div className="bazaar-inventory-container">{listItems}</div>)
    }

    const InventoryCard = (props) => {
        let item = props.value
        let id = item.item_id
        let item_amount = item.item_amount
        let name = item.name
        let image = item.image
        let project = item.project
        let type = item.type
        let index = props.i

        let divID = "bazaarInventoryCard"+index
        let m = ''
        if(!checkNotMobile(screenWidth)){
            m = 'm-'
        }
        const clickOn = (id) => {
            let tempIndexes = [] as number[]
            if(indexes.includes(id)){
                for(let i in indexes){
                    indexes[i] !== id && tempIndexes.push(indexes[i])
                }
                setIndexes(tempIndexes)
            }

            if(!indexes.includes(id)){
                setIndexes([].concat(...indexes, [id]))
            }
        }
        return(
            <div id={divID} className={`bazaar-inventory-card ${indexes.includes(index)?'clicked':''}`} onClick={() => clickOn(index)}>
                <div className={m+'bazaar-inventory-card-image-container'}>
                    <img className={m+'bazaar-inventory-card-image'} src={webStorage+ '/bazaar/item_images/' + id + '/' + image + '.png'}/>
                </div>
                    <div className={m+"bazaar-inventory-card-info-container"}>
                        <div className={m+'bazaar-item-info-name'}>{m===''?name:name.substr(0, 50)+"..."}</div>
                        <div className={m+'bazaar-item-info-brand'}>by {project}</div>
                        <div className={m+'bazaar-item-info-type'}>{type}</div>
                        <div className={'bazaar-inventory-card-info-owned'}>amount - {item_amount}</div>
                    </div>
            </div>
        )
    }

    const InventoryConfirms = (props) => {

        let arr = props.list
        let listItems = arr.map((item) => {return(
            <>
                <InventoryConfirm value={item}/>
            </>
        )
        })
        return(<div className="bazaar-confirm-container">{listItems}</div>)
    }

    const InventoryConfirm = (props) => {
        let item = props.value
        let item_amount = item.item_amount
        let name = item.name
        let m = ''
        if(!checkNotMobile(screenWidth)){
            m = 'm-'
        }
        return(
            <div className="br-scoreboard-row">
                <div className={'confirm-item-name'}>{m===''?name:name.substr(0, 50)+"..."}</div>
                <div className="confirm-span"/>
                <div className={'confirm-item-amount'}>x{item_amount}</div>
            </div>
        )
    }

    const steps = [
        {
          title: '1',
          title2: 'Select Items to Ship',
          content:
            <div className="item-inventory-container">
                <InventoryCards list={userInventoryItems} />
            </div>
        },
        {
          title: '2',
          title2: 'Enter Shipping Info',
          content: 
            <>
                {addressInputs}
            </>,
        },
        {
          title: '3',
          title2: 'Confirm Contract Transaction',
          content: 
          <div className="flex-and-center flex-column" style={{gap: '20px', padding: '20px', color: 'white'}}>
            <div style={{fontSize: '20px', textAlign: 'left', width: '100%'}}>
                Items
                <div className="confirm-line"/>
            </div>
            <InventoryConfirms list={Array.from(indexes, (index) => userInventoryItems[index])} />
            <div style={{fontSize: '20px', textAlign: 'left', width: '100%'}}>
                Shipping Address
                <div className="confirm-line"/>
                <div className="confirm-address">
                    <div>
                        {firstName}{' '}{lastName}
                    </div>
                    <div>
                        {address}
                    </div>
                    <div>
                        {postal}
                    </div>
                </div>
            </div>
            <div style={{fontSize: '20px', textAlign: 'left', width: '100%'}}>
                Contact Info
                <div className="confirm-line"/>
                <div className="confirm-address">
                    <div>
                        {phoneNumber}
                    </div>
                    <div>
                        {email}
                    </div>
                    <div>
                        {discordTag}
                    </div>
                </div>
            </div>
          </div>,
        },
        {
            title: '4',
            title2: 'Pay Shipping Fees',
            content: 
              <div className='pay-shipping-options-container'>
                  <div style={{fontSize: '30px', marginBottom: '30px'}}>Receive Shipping Payment Info Based on Contact Method</div>
                  <div style={{textDecoration: 'underline'}}>
                    EMAIL
                  </div>
                  <div>
                    We will email you all information regarding shipping to the email provided as your preferred contact method. Please wait up to 24 hrs to recieve the email.
                  </div>
                  <div className="confirm-line"/>
                  <div style={{textDecoration: 'underline'}}>
                    DISCORD
                  </div>
                  <div>
                    Go to our <a href='https://discord.com/channels/939820143486320650/952729971619659866'>discord and create a ticket here</a> including the tx hash of your shipping order.
                  </div>
              </div>,
        }
      ];

      const StepLines = (props) => {
        let index = 0
        const items = steps.map((item) => {
            let cn = index <= current?"steplines-circle current":"steplines-circle"
            let sn = index <= current?"steps-line-seperator current":"steps-line-seperator"
            index++
            return(
                <>
                    <div className={cn}>{item.title}</div>
                    {index !== steps.length &&<div className={sn}></div>}
                </>
            )
        })

        return(
            <div className="steplines-container">
                {items}
            </div>
        )
      }

      const [current, setCurrent] = useState(0);
    
      const next = () => {
        setCurrent(current + 1);
      };
    
      const prev = () => {
        setCurrent(current - 1);
      };

    const shipIt = async() => {
        //contract transaction
        let tx: any = await shipItems(contractWriteProviders, account, indexes)
        setShipReqConfirmed(true)
        next()

        //prepare payload
        let items: Item[] = [];
        for(let i = 0; i < tx.receipt.logs[0].args.shippingItems.length; i++){
            items.push(
                {
                    item_id: tx.receipt.logs[0].args.shippingItems[i].toString(),
                    item_amount: tx.receipt.logs[0].args.shippingAmounts[i].toString()
                }
            )
        }
        
        let payload: BazaarOrder = {
            tx: tx.tx,
            items: items,
            address_1: address,
            address_2: address2,
            postal: postal,
            name: firstName+' '+lastName,
            email: email,
            phone_number: phoneNumber,
            phone_ext: ext,
            discord_tag: discordTag
        }

        const headers = {
            'x-api-key': process.env.REACT_APP_BOOMER_API_AUTH,
            'Content-Type': 'application/json', // specify the content type of your request
          };

        //on confirmation, send request to backend server
        try {
            const response = await axios.post("https://api.boomersquad.io/bazaar_order", payload, {headers: headers});
            console.log(response)
          } catch (err: any) {
            console.log(err)
          }
    }

    // Get user item objects from contract and compare to objects from database
    // TODO -> move to API
    useEffect(()=>{
        const getUserInventoryItems = async() => {
            let contractItems = await getUserInventory(contractWriteProviders, account)
            let mergedContractItems = []
            for(let i = 0; i < contractItems[0].length; i++){
                mergedContractItems.push({
                    itemID: contractItems[0][i].toString(),
                    amount: contractItems[1][i].toString(),
                })
            }
            // item info from DB
            let offChainItemInfo = bazaarTestObj
            // combine both
            let tempUserInventoryItems = [] as UserInventoryItem[]
            for(let i in mergedContractItems){
                let result = offChainItemInfo.filter(item => item.id === mergedContractItems[i].itemID)
                let tempInventoryItem: UserInventoryItem = {
                    item_id: mergedContractItems[i].itemID,
                    item_amount: mergedContractItems[i].amount,
                    name: result[0].name,
                    image: result[0].images[0],
                    project: result[0].project,
                    type: result[0].type
                }
                tempUserInventoryItems.push(tempInventoryItem)
            }
            return tempUserInventoryItems
        }
        getUserInventoryItems()
        .then(
            (result) => {
                setUserInventoryItems(result)
            }
        )
    },[contractWriteProviders])


    return(
        <>
            <ToastContainer position="top-center"/>
            <img className='banner' style={{width: '100%'}} src={webStorage + '/bazaar/bazaar_temp_banner2.png.png'} alt=""/>
            <div className='flex-and-center' style={{width: '100%', padding: '20px 0px'}}>
                <div className={`${checkNotMobile(screenWidth)?'':'mobile-'}steps-container`}>
                    <StepLines />
                    <div style={{fontFamily: 'nowbold', fontSize: '30px'}}>
                        {steps[current].title2}
                    </div>
                    <div className="steps-content-container">
                        {steps[current].content}
                    </div>
                    <div className="steps-button-container">
                        {current > 0 && current !== 3 && (
                        <button className={'steps-button prev'} onClick={() => prev()}>
                            Previous
                        </button>
                        )}
                        {current === 0 && (
                        <div className={`steps-button next ${indexes.length === 0?'inactive':''}`} 
                            onClick={() => {if(indexes.length === 0){} else {next();}}
                        }>
                            Next
                        </div>
                        )}
                        {current === 1 && (
                        <div className={`steps-button next ${
                            checkValidAddressInfo(
                                firstName,
                                lastName,
                                phoneNumber,
                                address,
                                postal,
                                email,
                                discordTag
                            )?'':'inactive'}`}
                            onClick={() => {if(!checkValidAddressInfo(
                                firstName,
                                lastName,
                                phoneNumber,
                                address,
                                postal,
                                email,
                                discordTag
                            )){} else {next();}}
                        }>
                            Next
                        </div>
                        )}
                        {current === 2 && (
                        <div className={`steps-button next ${shipReqConfirmed?'inactive':''}`} onClick={() => {if(!shipReqConfirmed){shipIt()}}}>
                            Confirm
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}