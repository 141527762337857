import { useParams } from 'react-router-dom';
import { webStorage } from "../config"
import { useState, useContext, useEffect } from 'react';
import ConnectedContext from '../context/connectedcontext';
import Web3Context from '../context/web3context';
import ScreenWidthContext from '../context/screenwidthcontext';
import { fetchMetadata } from "../components/utils/api_calls";
import { Link } from "react-router-dom";
import Spinner from "../components/spinner";
import { convertIPFSUrl } from '../utils';

export const AllTokens = () => {

    const { account, setAccount, balance, setBalance, chainID, setChainID } = useContext(Web3Context)
    const { connected, setConnected } = useContext(ConnectedContext)
    const { screenWidth, setScreenWidth } = useContext(ScreenWidthContext)

    const [loading, setLoading] = useState(true)

    const { contractAddress } = useParams();
    const [collectionMeta, setCollectionMeta] = useState([] as any[])

    const ZoomerNFTs = (props) => {
        let list = props.list;
        let listItems = list.map((item) => {
            return (
                <Link to={`/token/${contractAddress}/${item.id || item.edition}`}>
                    <div className="zoomer-card bigger-on-hover">
                        <div className="zoomer-card-image-container">
                            <img className='zoomer-card-image' src={item.image ? item.image.startsWith('ipfs://') ? convertIPFSUrl(item.image) : item.image : ''} />
                        </div>
                        <div className='zoomer-card-bottom'>
                            <div>{item.name}</div>
                        </div>
                    </div>
                </Link>
            )
        })
        return listItems
    }

    const checkLoading = (element, loading: boolean, width: number) => {
        return loading ? <Spinner loading={true} size={width} /> : element
    }
    useEffect(() => {
        setLoading(false)
    }, [collectionMeta])

    useEffect(() => {
        const getMeta = async () => {
            setLoading(true)
            let metadata = await fetchMetadata(account, contractAddress)
            console.log('metadata', metadata)
            setCollectionMeta(metadata)
        }
        let isApiSubscribed = true;
        if (isApiSubscribed && account !== '') {
            getMeta()
        }
        return () => { isApiSubscribed = false; };
    }, [account])

    return (
        <>
            {/* <div className='centered-x-and-y'><img className='banner' src={webStorage + '/zoomers/zoomer_mint_banner.png'} alt="foo"/></div> */}
            <div className="ro" style={{ marginTop: '100px' }}>
                <div className='zoomer-top-box'>
                    <div>Inventory</div><div className="z-span" />{checkLoading(<div style={{ fontStyle: 'italic', color: 'rgb(211, 211, 211)' }}>{collectionMeta.length}</div>, loading, 20)}
                </div>
            </div>
            <div style={{ minHeight: '800px' }}>
                <div className='zoomers-container'>
                    {checkLoading(<ZoomerNFTs list={collectionMeta} />, loading, 200)}
                </div>
            </div>

        </>
    )
}