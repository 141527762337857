
import React from 'react';
import Web3 from 'web3'
 
const Web3ContractReadWriteContext = React.createContext({
    contractReadProviders: null,
    setContractReadProviders: (contractReadProviders: any) => {},

    contractWriteProviders: null,
    setContractWriteProviders: (contractWriteProviders: any) => {},
  })

export default Web3ContractReadWriteContext