import React, { useState } from 'react';
import './antiphish.css';

export const AntiPhishBanner = () => {
    const [isHidden, setIsHidden] = useState(false);

    const handleClose = () => {
      setIsHidden(true);
    };

    return (
        <div className={`anti-phishing-banner${isHidden ? ' hidden' : ''}`}>
          <div className='anti-phishing-banner-text-area'>
            <div>
              Please make sure you are on our official website{' '}
              <a href="https://www.boomersquad.io/" target="_blank" rel="noopener noreferrer">
                https://www.boomersquad.io/
              </a>{' '}
              to avoid phishing scams. If you see
              any suspicious activity, please contact us.
            </div>
          </div>

          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
        </div>
      );
    };