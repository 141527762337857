import Web3 from "web3";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getNameFromAddress } from "../../launchpad/cosmetic_minter_utils";
import React from "react";

let ERC20 = require('./../../contracts/ERC20.json');

export const ethToWei = (eth: string) => {
    return Web3.utils.toWei(eth, 'ether');
}

export const weiToEth = (wei: string) => {
    return Web3.utils.fromWei(wei, 'ether');
}

// multiply BigInts
export const multiplyBigInts = (a: string, b: string) => {
    return Web3.utils.toBN(a).mul(Web3.utils.toBN(b)).toString();
}

export const addBigInts = (a: string, b: string) => {
    return Web3.utils.toBN(a).add(Web3.utils.toBN(b)).toString();
}

export const getERC20Balance = async (web3: Web3, contractAddress: string, accountAddress: string) => {
    const tokenContract = new web3.eth.Contract(ERC20.abi, contractAddress);
    const balance = await tokenContract.methods.balanceOf(accountAddress).call();
    return balance.toString();
}

export const getERC20Allowance = async (web3: Web3, tokenContractAddress: string, accountAddress: string, spenderAddress: string) => {
    const tokenContract = new web3.eth.Contract(ERC20.abi, tokenContractAddress);
    const allowance = await tokenContract.methods.allowance(accountAddress, spenderAddress).call();
    return allowance.toString();
}

export const approveERC20 = async (web3: Web3, tokenContractAddress: string, spenderAddress: string, account: string) => {
    const tokenContract = new web3.eth.Contract(ERC20.abi, tokenContractAddress);
    let tokenName = getNameFromAddress[tokenContractAddress];
    let contractName = getNameFromAddress[spenderAddress];
    await toast.promise(
        tokenContract.methods.approve(spenderAddress, '100000000000000000000000000000').send({ from: account }),
        {
            pending: `Approving ${contractName} Contract to spend your ${tokenName}`,
            success: {
                render() {
                    return <div>Success! Now refreshing browser</div>
                },
                // other options
                icon: "🟢",
            },
            error: 'Something went wrong! :x.'
        }
    ).then(
        () => setTimeout((() => window.location.reload()), 5500)
    )
}

export const AddScratchToMetaMask: React.FC = () => {
    const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

    const handleAddToken = async () => {
        try {
            // Replace with your token details
            const tokenAddress = '0x14fB0e7640e7fb7765CFA87cEc973ff5465d1c66';
            const tokenSymbol = 'SCRATCH';
            const tokenDecimals = 18;
            const tokenImage = 'https://boomersquad-web-images.s3.amazonaws.com/boomrooms/SCRATCH.svg';

            // Check if MetaMask is installed
            if (window.ethereum) {
                // Request to add the token
                const wasAdded = await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: tokenAddress,
                            symbol: tokenSymbol,
                            decimals: tokenDecimals,
                            image: tokenImage,
                        },
                    },
                });

                if (wasAdded) {
                    console.log('Token added!');
                } else {
                    console.log('Token addition rejected!');
                }
            } else {
                console.log('Web3 not detected!');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    return (
        <button
            className="add-scratch-to-metamask-btn bigger-on-hover"
            onClick={handleAddToken}
        >
            Add SCRATCH address to Wallet
        </button>
    );
};
