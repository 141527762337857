import { Row, Col } from 'antd'
import { useState, useContext } from 'react';
import checkNotMobile from '../components/logic/mobile';
import ScreenWidthContext from '../context/screenwidthcontext';
import '../styles.css'
import { webStorage } from '../config';
export const Comics = () => {

    /******************************************************************************************************************************************************************CONTEXT */    

    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)

        //Display all story images once all images have been loaded
        const [loading, setLoading] = useState(true)
    
        //if all 18 story images have been loaded, then the story will be fully displayed, until then spinner will show. Logic is implemented in story image class using (loading) to determine if images show or not.
        let imagesLoaded: number = 0;
        let totalStoryImages: number = 18;
        const imageLoadedorError = () => {
            imagesLoaded++
            if (imagesLoaded === totalStoryImages) { //18 total story images to load on button press
              setLoading(false)
          }
        }

        const ZoomStory = () => {
            return(
                checkNotMobile(screenWidth)?
                    <div className='zoomer-story'>
                        <div style={{marginTop: '120px'}}> </div>
                        <div className="title">Zooomer Story</div>
                        <Row className='story-row' justify='center'>
                            <Row className='story-image-row'><img className='story_img' src={webStorage + '/story/zoomer/zoom1.png'} /></Row>
                            <Row className='story-image-row'><img className='story_img' src={webStorage + '/story/zoomer/zoom2.png'} /></Row>
                            <Row className='story-image-row'><img className='story_img' src={webStorage + '/story/zoomer/zoom3.png'} /></Row>
                            <Row className='story-image-row'><img className='story_img' src={webStorage + '/story/zoomer/zoom4.png'} /></Row>
                        </Row>
                    </div>

                    :
                    <div style={{marginTop: '80px'}}>
                        <div className="mobile-title">Zooomer Story</div>
                        <Row className='mobile-story-image-row'><img className='mobile-story_img' src={webStorage + '/story/zoomer/zoom1.png'} /></Row>
                        <Row className='mobile-story-image-row'><img className='mobile-story_img' src={webStorage + '/story/zoomer/zoom2.png'} /></Row>
                        <Row className='mobile-story-image-row'><img className='mobile-story_img' src={webStorage + '/story/zoomer/zoom3.png'} /></Row>
                        <Row className='mobile-story-image-row'><img className='mobile-story_img' src={webStorage + '/story/zoomer/zoom4.png'} /></Row>
                    </div>
            )
        }

        const Story = () => {
        
            return (
      
                <> 
                <Row justify='center'>
                        <div className="title">Booomer Story
                            <Row className='story-row'>
                                <Col className='story-image-col'>
                                    <Row className='story-image-row'>
                                        <img src={webStorage + '/story/01.png'} className="story_img" alt="Old grandpa Boomer from Boomer Squad NFT retirement home sitting on a bench in the park with timmy, taking in all of the NFT shenanigans that the zoomers are taking part in around him. Zoomers are
                                        waiting in line to mint hype NFTs, they are puking rainbows, and bots are overlooking the skies waiting to snag the mints." />
                                    </Row>
    
                                    {/**Loading indicator Below */}
                                    <Row className={`loading-container ${loading ? '' : 'none'}`}>
                                        <div className={`sk-fading-circle ${loading ? '' : 'none'}`}>
                                            <div className="sk-circle1 sk-circle"></div>
                                            <div className="sk-circle2 sk-circle"></div>
                                            <div className="sk-circle3 sk-circle"></div>
                                            <div className="sk-circle4 sk-circle"></div>
                                            <div className="sk-circle5 sk-circle"></div>
                                            <div className="sk-circle6 sk-circle"></div>
                                            <div className="sk-circle7 sk-circle"></div>
                                            <div className="sk-circle8 sk-circle"></div>
                                            <div className="sk-circle9 sk-circle"></div>
                                            <div className="sk-circle10 sk-circle"></div>
                                            <div className="sk-circle11 sk-circle"></div>
                                            <div className="sk-circle12 sk-circle"></div>
                                        </div>
                                    </Row>
    
                                    <Row className='story-image-row'>
                                        <Col flex='1'><img src={webStorage + '/story/02.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Boomer sitting on a bench getting visibly irrate about the shenanigans happening around him in the park." onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/03.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Boomer angry at the bots flying around his head snagging the NFTs for mint. A whale is wheeling in a full load of crypto, most likely taken from the zoomers." onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        
                                    </Row>
                                    <Row className='story-image-row'>
                                        <Col flex='1'><img src={webStorage + '/story/04.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Timmy shows grandpa Boomer an NFT and explains to him what they are." onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/05.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Grandpa Boomer takes in the information, plotting a scheme of his own." onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/06.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Grandpa Boomer gets up and walks away from timmy still sitting on the bench." onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>  
                                    </Row>
                                    <Row className='story-image-row'>
                                        <img src={webStorage + '/story/07.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Vehicles are lined up at a Metagas station, fighting eachother for a spot at the pump. The price of gwei is ever increasing as shown by the billboard. 
                                        All the while, planes are aidropping NFTs out of the sky. Grandpa Boomer enters his old and crooked retirement home" onLoad={imageLoadedorError} onError={imageLoadedorError}/>
                                    </Row>
                                    <Row className='story-image-row'>
                                        <Col flex='1'><img src={webStorage + '/story/08.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Grandpa Boomer is in the retirement home looking over at his boomer peers." onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/09.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Grandpa Boomer exclaims and gets the attention of all boomers." onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        
                                    </Row>
                                    <Row className='story-image-row'>
                                        <Col flex='1'><img src={webStorage + '/story/10.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Grandpa Boomer explains to the Boomer Squad retirement home residents what is happening outside." onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/11.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Grandpa Boomer shares his idea with Boomer Squad residents." onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        
                                    </Row>
                                    <Row className='story-image-row'>
                                        <img src={webStorage + '/story/12.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Timmy is sitting on a bench in an empty park that used to be filled with energy and other zoomers. The park is a mess, NFTs are left on the floor." onLoad={imageLoadedorError} onError={imageLoadedorError}/>
                                    </Row>
                                    <Row className='story-image-row'>
                                        <Col flex='1'><img src={webStorage + '/story/14.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Timmy looks over at another zoomer carrying bags of coins and crying rainbows." onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/15.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Timmy looks over at another zoomer carrying a giant bag of NFTs." onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/16.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Timmy gets up from the park bench visibly confused as to what is happening. Other zoomer has now dropped his bag and his NFT and is leaving the park." onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>  
                                    </Row>
                                    <Row className='story-image-row'>
                                        <img src={webStorage + '/story/17.png'} className={`story_img ${loading ? 'none' : ''}`} alt="An empty metagas station is seen surrounded by coins that have been thrown away. planes are still airdropping NFTs from the sky. Zoomers are seen holding bags on the street. The Boomer Squad retirement home is no longer old and crooked, but it is now built with gold and marble." onLoad={imageLoadedorError} onError={imageLoadedorError}/>
                                    </Row>
                                    <Row className='story-image-row'>
                                        <Col flex='1'>
                                            <Row><img src={webStorage + '/story/18.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Row>
                                        </Col>
                                        <Col flex='1'>
                                            <Row><img src={webStorage + '/story/19.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Row>
                                        </Col>
                                    </Row>
                                    <Row className='story-image-row'>
                                        <img src={webStorage + '/story/20.png'} className={`story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </>
            )
         }
    
         const StoryMobile = () => {
            
            return (

                <Row justify='center'>
                        <div className="mobile-title">Boomer Story
          
                            <Row className='story-row'>
                                <Col className='mobile-story-image-col'>
                                    <Row className='mobile-story-image-row'>
                                        <img src={webStorage + '/story/01.png'} className="mobile-story_img" alt="Old grandpa Boomer from Boomer Squad NFT retirement home sitting on a bench in the park with timmy, taking in all of the NFT shenanigans that the zoomers are taking part in around him. Zoomers are
                                        waiting in line to mint hype NFTs, they are puking rainbows, and bots are overlooking the skies waiting to snag the mints." />
                                    </Row>
    
                                    {/**Loading indicator Below */}
                                    <Row className={`loading-container ${loading ? '' : 'none'}`}>
                                        <div className={`sk-fading-circle ${loading ? '' : 'none'}`}>
                                            <div className="sk-circle1 sk-circle"></div>
                                            <div className="sk-circle2 sk-circle"></div>
                                            <div className="sk-circle3 sk-circle"></div>
                                            <div className="sk-circle4 sk-circle"></div>
                                            <div className="sk-circle5 sk-circle"></div>
                                            <div className="sk-circle6 sk-circle"></div>
                                            <div className="sk-circle7 sk-circle"></div>
                                            <div className="sk-circle8 sk-circle"></div>
                                            <div className="sk-circle9 sk-circle"></div>
                                            <div className="sk-circle10 sk-circle"></div>
                                            <div className="sk-circle11 sk-circle"></div>
                                            <div className="sk-circle12 sk-circle"></div>
                                        </div>
                                    </Row>
    
                                    <Row className='mobile-story-image-row'>
                                        <Col flex='1'><img src={webStorage + '/story/02.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/03.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        
                                    </Row>
                                    <Row className='mobile-story-image-row'>
                                        <Col flex='1'><img src={webStorage + '/story/04.png'}className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/05.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/06.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                    </Row>
                                    <Row className='mobile-story-image-row'>
                                        <img src={webStorage + '/story/07.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/>
                                    </Row>
                                    <Row className='mobile-story-image-row'>
                                        <Col flex='1'><img src={webStorage + '/story/08.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/09.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        
                                    </Row>
                                    <Row className='mobile-story-image-row'>
                                        <Col flex='1'><img src={webStorage + '/story/10.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/11.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        
                                    </Row>
                                    <Row className='mobile-story-image-row'>
                                        <img src={webStorage + '/story/12.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/>
                                    </Row>
                                    <Row className='mobile-story-image-row'>
                                        <Col flex='1'><img src={webStorage + '/story/14.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/15.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>
                                        <Col flex='1'><img src={webStorage + '/story/16.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Col>  
                                    </Row>
                                    <Row className='mobile-story-image-row'>
                                        <img src={webStorage + '/story/17.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/>
                                    </Row>
                                    <Row className='mobile-story-image-row'>
                                        <Col flex='1'>
                                            <Row><img src={webStorage + '/story/18.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Row>
                                        </Col>
                                        <Col flex='1'>
                                            <Row><img src={webStorage + '/story/19.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/></Row>
                                        </Col>
                                    </Row>
                                    <Row className='mobile-story-image-row'>
                                        <img src={webStorage + '/story/20.png'} className={`mobile-story_img ${loading ? 'none' : ''}`} alt="Image 1" onLoad={imageLoadedorError} onError={imageLoadedorError}/>
                                    </Row>

                                </Col>
                            </Row>
                        </div>
                    </Row>
            )
         }
    return (
        checkNotMobile(screenWidth)?
        <>
            <div style={{marginTop: '100px'}}></div>
            <Story />
            <ZoomStory />
        </>
        :
        <>
            <StoryMobile />
            <ZoomStory />
        </>
    )
}