
import { useState, useContext, useEffect } from 'react';

import ConnectedContext from '../context/connectedcontext';
import Web3Context from '../context/web3context';
import toContract from '../types/truffle_contracts';
import Web3 from 'web3';
import { Row, InputNumber } from 'antd';
import ScreenWidthContext from '../context/screenwidthcontext';
import Spinner from '../components/spinner'
import connectedAndGoodChain from '../components/logic/connectedandgoodchain';
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';
import Zoomers from '../contracts/Zoomers.json'
import '../launchpad/launchpad.css'
import '../launchpad/launchpadmobile.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { webStorage } from '../config';
import checkNotMobile from '../components/logic/mobile';
import { getScratchBalance } from '../components/utils/scratch-functions';
import ScratchContract from '../contracts/Scratch.json'


export const ZoomerMint = () => {

    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)
    const {connected, setConnected} = useContext(ConnectedContext)
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    
    const contractWrite = toContract(Zoomers)
    const scratchWrite = toContract(ScratchContract)

    interface ScratchBuyCard {
        linkTo: string;
        background: string;
        title: string;
    }

    const scratchBuyObjects: ScratchBuyCard[] = [
        {
            linkTo: 'https://cronosmm.finance/swap?outputCurrency=0x14fb0e7640e7fb7765cfa87cec973ff5465d1c66',
            background: '/pawnshop/mmfbutton.png',
            title: 'MMF'
        },
        {
            linkTo: 'https://candycity.finance/swap?chainId=25&outputCurrency=0x14fB0e7640e7fb7765CFA87cEc973ff5465d1c66',
            background: '/pawnshop/candycitybutton.png',
            title: 'CANDY CITY'
        },
    ]

    // set up contract
    if(contractWriteProviders){
        const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
        contractWrite.setProvider(contractWriteProviders)
        scratchWrite.setProvider(contractWriteProviders)
    }

    // hooks for use throughout the mint page
    const [currentSupply, setCurrentSupply] = useState('')
    const [amountToMint, setAmountToMint] = useState(1)

    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(false)

    const [publicState, setPublicState] = useState(false)
    const [WLState, setWLState] = useState(false)
    const [freeState, setFreeState] = useState(false)

    const [freeAmount, setFreeAmount] = useState('0')
    const [WLAmount, setWLAmount] = useState('0')

    // 1000000000000000000 = 1 cro

    /**
     * 
     * Skeleton Variables
     * 
     */

    const description: string = `
    Zoomers are the latest collection in the Boomer Squad Ecosystem.
    With a max supply of 7007, each NFT is uniquely generated and features a unique Variant system consisting of 4 different versions of each character. 
    The $Scratch token will be heavily involved in the Zoomers ecosystem, allowing users to acquire extra traits through the "Cover" feature, as well as switching between variants at no cost. 
    Zoomers have 16 semi-evenly distributed 'Game Activity' traits, enabling users to form teams for future events and games.
    `

    const maxSupply ='7007'
    const mintPrice = '585000000000000000000'
    const mintPriceScratch = '1000000000000000000000'

    const MintDescription = () => {
        let m = ''
        if(!checkNotMobile(screenWidth)){
            m='m-'
        }
        return(
            <>
                <div className='lp-mintdescription-row'>{'WL Mint Date: '}<div className={m+'row-answer'}>{'March 24 6:30 PM UTC'}</div></div>
                <div className='lp-mintdescription-row'>{'Public Mint Date:'}<div className={m+'row-answer'}>{'March 25 6:30 PM UTC'}</div></div>
                <div className='lp-mintdescription-row'>{'SCRATCH Mint Tiers:'}<div className={m+'row-answer'}>{'WL, Public'}</div></div>
                <div className='lp-mintdescription-row'>{'CRO Mint Tiers:'}<div className={m+'row-answer'}>{'Public'}</div></div>
                <div className='lp-mintdescription-row'>{'SCRATCH Mint Price:'}<div className={m+'row-answer'}>{'1000'}</div></div>
                <div className='lp-mintdescription-row'>{'CRO Mint Price:'}<div className={m+'row-answer'}>{'585'}</div></div>
                <div className='lp-mintdescription-row'>{'Max Supply:'}<div className={m+'row-answer'}>{maxSupply}</div></div>
                <div className='lp-mintdescription-row'>{'Max mint per wallet:'}<div className={m+'row-answer'}>{'No Limit'}</div></div>
            </>
        )
    }

    const Disclaimer = () => {
        return(
            <Row justify={'center'}>
            <div className='dis-disclaimer'>
                <div style={{fontSize: '30px', fontFamily: 'nowbold'}}>
                DISCLAIMER
                </div>
                <div>
                Metamask updated how it approves contracts. After pressing on the APPROVE CONTRACT button, Metamask may ask you to put in an amount, we suggest to click on **use default value** which should be near the right side of the Metamask approval screen in blue text. If you are still having issues, please make sure to refresh your browser as well as clear its cache and cookies.
                </div>
            </div>
            </Row>
        )
    }

    const multiplyBigInts = (a, b) => {
        return (BigInt(a.toString()) * BigInt(b.toString()))
    }

    const MintButtons = () => {
        return(
            <>
                <div className='lp-button lp-blue bigger-on-hover text-shadow shadow-button-w' onClick={onFreeMint}>
                <Spinner loading={loading1} size={75}/>
                {loading1?'':'Free Mint FREE'}
                </div>
                {
                    BigInt(allowance) >= multiplyBigInts(amountToMint, mintPriceScratch)?
                        <div className='lp-button lp-orange bigger-on-hover text-shadow shadow-button-w' onClick={onWLMint}>
                        <Spinner loading={loading2} size={75}/>
                        {loading2?'':'WL Mint SCRATCH'}
                        </div>
                    :
                        <div className='lp-button lp-orange bigger-on-hover text-shadow shadow-button-w' onClick={approveContract}>
                        {'APPROVE CONTRACT'}
                        </div>
                }
                {
                    BigInt(allowance) >= multiplyBigInts(amountToMint, mintPriceScratch)?
                        <div className='lp-button lp-pink bigger-on-hover text-shadow shadow-button-w' onClick={onPublicMintScratch}>
                        <Spinner loading={loading3} size={75}/>
                        {loading3?'':'Public Mint SCRATCH'}
                        </div>
                    :
                        <div className='lp-button lp-pink bigger-on-hover text-shadow shadow-button-w' onClick={approveContract}>
                        {'APPROVE CONTRACT'}
                        </div>
                }
                <div className='lp-button lp-green bigger-on-hover text-shadow shadow-button-w' onClick={onPublicMintCro}>
                <Spinner loading={loading4} size={75}/>
                {loading4?'':'Public Mint CRO'}
                </div>
            </>
        )
    }

    const refreshInfo = async() => {
        const web3 = new Web3(contractWriteProviders)

        // update CRO
        let croBalance = await web3.eth.getBalance(account)
        setBalance(web3.utils.fromWei(croBalance, "ether"))

        // update Scratch
        let scratchBalance = await getScratchBalance(Web3.givenProvider || "ws://localhost:8545", account)
        setScratchBalance(scratchBalance as string)

        // update current supply
        let zoomC = await contractWrite.deployed()
        let curSup = await zoomC.totalSupply()
        setCurrentSupply(curSup.toString())

        // update free mint claim
        let airdropClaim = await zoomC.airdropList(account)
        setFreeAmount(airdropClaim.toString())


        // update WL claim
        let wlClaim = await zoomC._whitelist(account)
        setWLAmount(wlClaim.toString())


        // update states
        let pubState = await zoomC.publicSaleState()
        let whiteState = await zoomC.whitelistSaleState()
        let freeState = await zoomC.airdropClaimState()

        setPublicState(pubState)
        setWLState(whiteState)
        setFreeState(freeState)
    }


    /**
     * 
     * Smart Contract Functions
     * 
     */

    const onFreeMint = async() => {
        setLoading1(true)
        let cfContract = await contractWrite.deployed()
        
        if(!freeState){
            toast.error('Free mint is not active.', {autoClose: 5000});
            setLoading1(false)
            return
        }
        if(Number(freeAmount) < amountToMint){
            toast.error('Not enough Free Mint allocation.', {autoClose: 5000});
            setLoading1(false)
            return
        }

        await toast.promise(
            cfContract.airdropMint(amountToMint, {from: account}),
            {
              pending: 'Free Minting...',
              success: {render(){return <div>Success!</div>}, icon: "🟢",},
              error: 'Failed to mint.'
            }
        ).catch(()=> setLoading1(false))

        await refreshInfo()
        setLoading1(false)
    }

    const onWLMint = async() => {
        setLoading2(true)
        let cfContract = await contractWrite.deployed()

        if(!WLState){
            toast.error('WL mint is not active.', {autoClose: 5000});
            setLoading2(false)
            return
        }
        if(Number(WLAmount) === 0){
            toast.error('Sorry, you are not on the Whitelist!', {autoClose: 5000});
            setLoading2(false)
            return
        }

        await toast.promise(
            cfContract.whitelistScratchMint(amountToMint, {from: account}),
            {
              pending: 'WL Minting...',
              success: {render(){return <div>Success!</div>},icon: "🟢",},
              error: 'Failed to mint.'
            }
        ).catch(()=> setLoading2(false))

        await refreshInfo()
        setLoading2(false)

    }

    const onPublicMintScratch = async() => {
        setLoading3(true)
        let cfContract = await contractWrite.deployed()
        if(!publicState){
            toast.error('Public mint is not active.', {autoClose: 5000});
            setLoading3(false)
            return
        }
        let totalCost = (BigInt(amountToMint)*BigInt(mintPriceScratch)).toString()
        if(BigInt(totalCost) > BigInt(Web3.utils.toWei(scratchBalance, 'ether'))){
            toast.error('Not enough SCRATCH.', {autoClose: 5000});
            setLoading3(false)
            return 
        }
        await toast.promise(
            cfContract.publicScratchMint(amountToMint, {from: account}),
            {
              pending: 'Public Minting with SCRATCH...',
              success: {render(){return <div>Success!</div>},icon: "🟢",},
              error: 'Failed to mint.'
            }
        ).catch(()=> setLoading3(false))

        await refreshInfo()
        setLoading3(false)

    }

    const onPublicMintCro = async() => {
        setLoading4(true)
        let cfContract = await contractWrite.deployed()
        if(!publicState){
            toast.error('Public mint is not active.', {autoClose: 5000});
            setLoading4(false)
            return
        }
        let totalCost = (BigInt(amountToMint)*BigInt(mintPrice)).toString()
        if(BigInt(totalCost) > BigInt(Web3.utils.toWei(balance, 'ether'))){
            toast.error('Not enough CRO.', {autoClose: 5000});
            setLoading4(false)
            return 
        }
        await toast.promise(
            cfContract.publicCroMint(amountToMint, {from: account, value: totalCost}),
            {
              pending: 'Public Minting with CRO...',
              success: {render(){return <div>Success!</div>},icon: "🟢",},
              error: 'Failed to mint.'
            }
        ).catch(()=> setLoading4(false))

        await refreshInfo()
        setLoading4(false)
    }

    const ScratchBuyList = (props) => {
        const arr = props.list
        const items = arr.map((item, index) =>
            <ScratchBuyCard key={index} value={item} />
        )
        let mybool = checkNotMobile(screenWidth)
        return (
            <Row justify={mybool?'start':'center'} align='middle' style={{gap: '80px'}}>
                {items}
            </Row>
        )
    }

    const ScratchBuyCard = (props) => {
        const linkTo = props.value.linkTo
        const background = props.value.background
        const title = props.value.title
        return (
            <a href={linkTo} target='_blank'>
                <div className='scratchbuy-card-container'>
                    <img className='scratchbuy-card-image shadow-button' src={webStorage + background}/>
                    <div className='scratchbuy-card-title'>
                        {title}
                    </div>
                </div>
            </a>
        )   
    }



    // update the value in the input number mint box
    const onChange = (value: number) => {
        setAmountToMint(value)
    }

    // load info on user web3 connections
    useEffect(() => {
        const load = async() => {
            refreshInfo()
        }

        load()
    },[connected])

    const approveContract = async() => {
        let fs = await contractWrite.deployed()
        let s = await scratchWrite.deployed()
        await toast.promise(
            s.approve(fs.address, '1000000000000000000000000000', {from: account}),
            {
              pending: 'Approving Zoomers Contract...',
              success: {
                render(){
                  return <div>Success! Now refreshing browser</div>
                },
                // other options
                icon: "🟢",
              },
              error: 'Something went wrong! :x.'
            }
        ).then(
            () => setTimeout((() => window.location.reload()), 5500)
        )
    }

    // check allowance
    const [allowance, setAllowance] = useState('0')
    useEffect(() => {
        let on = true
        const checkAllowance = async() => {
            let zoomC = await contractWrite.deployed()
            let s = await scratchWrite.deployed()
            let allowance = await s.allowance(account, zoomC.address)
            setAllowance(allowance.toString())
        }
        if(on){
            checkAllowance()
        }
        return () => {on = false}
    },[account])


    return (

    checkNotMobile(screenWidth)?
        <div>
            <ToastContainer position="top-center"/>
            <div className='centered-x-and-y'><img className='banner' src={webStorage + '/zoomers/zoomer_mint_banner.png'} alt="foo"/></div>
            <div className='lp-nftbanner-line'/>
            <Row justify='center'>
                <div className='lp-description'>
                    {description}
                </div>
            </Row>
            <Row justify='center'>
                <div className='lp-mintrow'>
                    <div>
                        <img className='lp-mintgif' src={'https://zoomers-unrevealed.s3.amazonaws.com/images/unrevealed_zoomer.gif'} alt=""/>
                    </div>
                    <div className='lp-mintdescription'>
                        <MintDescription/>
                    </div>
                </div>
            </Row>
            <Row justify='center'>
                <img className='lp-soldout' src={webStorage + '/stickers/soldout.png'}/>
            </Row>
            {/* {connectedAndGoodChain(connected, chainID)?
            <>

                <Row justify='center'>
                    <div className='lp-mintdescription-fast flex-and-center flex-column'>
                        <div className='lp-mintdescription-row'>{'Current Supply: '}{currentSupply}{' / '+maxSupply}</div>
                        <div className='lp-mintdescription-row'>{'Free Claims: '}{freeAmount}</div>
                        <div className='lp-mintdescription-row'>{'WL Claims: '}{WLAmount}</div>
                    </div>
                </Row>
                
                <Row justify='center' style={{display: 'flex', gap: '20px', margin: '60px 0px 40px 0px'}}>
                    <MintButtons />
                </Row>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>{'Amount to Mint: '}</div><InputNumber className='lp-mint-inputnumber' size="large" min={1} max={10} defaultValue={1} onChange={onChange}/>
                </Row>
                <Disclaimer />
                <Row justify='center' style={{width: '100%'}}>

                    <div className='flex-and-center flex-column' style={{marginBottom: '20px'}}>
                        <div className='frontpage-section-title'>
                                BUY&nbsp;{<img src={webStorage + '/boomrooms/SCRATCH.svg'} style={{height: '1em'}}/>}$SCRATCH
                            </div>

                                <ScratchBuyList list={scratchBuyObjects} />

                    </div>
                </Row>

            </>
            :
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>
                        <div className='lp-mintdescription-connectwallet'>{'Connect Wallet To Mint!'}</div>
                    </div>
                </Row>
            </>} */}
        </div>

        :
//MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================
        <div>
            <ToastContainer position="top-center"/>
            <div className='centered-x-and-y'><img className='banner' src={webStorage + '/zoomers/zoomer_mint_banner.png'} alt="foo"/></div>
            <div className='mobile-lp-nftbanner-line'/>
            <Row justify='center'>
                <div className='mobile-lp-description'>
                    {description}
                </div>
            </Row>
            <Row justify='center'>
                <div className='mobile-lp-mintrow flex-and-center flex-column'>
                    <div>
                        <img className='mobile-lp-mintgif' src={'https://zoomers-unrevealed.s3.amazonaws.com/images/unrevealed_zoomer.gif'} alt=""/>
                    </div>
                    <div className='mobile-lp-mintdescription'>
                        <MintDescription/>
                    </div>
                </div>
            </Row>
            <Row justify='center'>
                <img className='mobile-lp-soldout' src={webStorage + '/stickers/soldout.png'}/>
            </Row>
            {/* {connectedAndGoodChain(connected, chainID)?
            <>

                <Row justify='center'>
                    <div className='lp-mintdescription-fast flex-and-center flex-column'>
                        <div className='lp-mintdescription-row' style={{width: '330px'}}>{'Current Supply:'}<div className='m-row-answer'>{currentSupply}{' / '+maxSupply}</div></div>
                        <div className='lp-mintdescription-row'>{'Free Claims: '}{freeAmount}</div>
                        <div className='lp-mintdescription-row'>{'WL Claims: '}{WLAmount}</div>
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>{'Amount to Mint: '}</div><InputNumber className='lp-mint-inputnumber' size="large" min={1} max={10} defaultValue={1} onChange={onChange}/>
                </Row>
                
                <Row justify='center' style={{gap: '0px', marginBottom: '40px'}}>
                    <MintButtons />
                </Row>
                <Disclaimer />
                <Row justify='center' style={{width: '100%'}}>
                    <div className='mobile-frontpage-section-container'>
                        <Row className='mobile-frontpage-section-title'>
                            BUY&nbsp;{<img src={webStorage + '/boomrooms/SCRATCH.svg'} style={{height: '1em'}}/>}$SCRATCH
                        </Row>
                        <div className='frontpage-list'>
                            <ScratchBuyList list={scratchBuyObjects} />
                        </div>
                    </div>
                </Row>

            </>
            :
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>
                        <div className='lp-mintdescription-row'>{'Connect Wallet To Mint!'}</div>
                    </div>
                </Row>
            </>} */}
        </div>
    )
}