import { Row } from 'antd';
import { useEffect, useState, useContext } from 'react';
import { webStorage } from '../config';
import { Link } from 'react-router-dom';
import { exploreObjects, newExploreObjects } from './frontObjs';
import checkNotMobile from '../components/logic/mobile';
import ScreenWidthContext from '../context/screenwidthcontext';

export const FrontPage = () => {


    //Screen Width context
    const { screenWidth, setScreenWidth } = useContext(ScreenWidthContext)

    const [selected, setSelected] = useState(0)
    const [auto, setAuto] = useState(true)

    const increase = () => {
        if (selected < carouselObjects.length - 1) {
            setSelected(selected + 1)
        } else {
            setSelected(0)
        }
    }

    const decrease = () => {
        if (selected === 0) {
            setSelected(carouselObjects.length - 1)
        } else {
            setSelected(selected - 1)
        }
    }

    const turnOffAuto = () => {
        setAuto(false)
    }

    interface CarouselCard {
        background: string;
        linkTo: string;
    }

    interface NFTBuyCard {
        marketLink: string;
        background: string;
        boomerLink: string;
        boomRoomLink: string;
        zoomerLink: string;
    }

    interface ScratchBuyCard {
        linkTo: string;
        background: string;
        title: string;
    }

    const carouselObjects: CarouselCard[] = [
        {
            background: '/playground/meme/playground_meme_carousel.png',
            linkTo: '/PlaygroundMeme'
        },
        {
            background: '/launchpad/sousou/sousou_carousel.png',
            linkTo: '/launchpad/sousou'
        },
        {
            background: '/carousel/nov2.png',
            linkTo: 'https://boomerarcade.io/'
        },
        {
            background: '/carousel/nov4.png',
            linkTo: 'https://ztblabs.etsy.com/'
        },
        {
            background: '/carousel/nov3.png',
            linkTo: 'https://drive.google.com/file/d/1I7ml9h7VhFqA-nF2D3du1pWCWGF6oB4w/view?usp=sharing'
        },
    ]

    const NFTMarketObjects: NFTBuyCard[] = [
        {
            background: '/navbar/EbisusSmall.png',
            marketLink: 'https://app.ebisusbay.com/brands/boomer-squad',
            boomerLink: 'https://app.ebisusbay.com/collection/boomer-squad',
            boomRoomLink: 'https://app.ebisusbay.com/collection/boomer-squad-boom-rooms',
            zoomerLink: 'https://app.ebisusbay.com/collection/zoomer-squad'
        },
        {
            background: '/navbar/CDCSmall.png',
            marketLink: 'https://crypto.com/nft/',
            boomerLink: 'https://crypto.com/nft/collection/47e8272a479b4c3a5c046cf3ac31de33',
            boomRoomLink: 'https://crypto.com/nft/collection/f9025e947c512abb9b3c4ee1e59f07f5?tab=items',
            zoomerLink: 'https://crypto.com/nft/collection/01ab5640956bf51376e820f8f8a4ba3a?tab=items'
        },
        {
            background: '/navbar/MINTEDlogo.png',
            marketLink: 'https://minted.network/',
            boomerLink: 'https://minted.network/collections/cronos/0xb650279e3d726b0c75c984caa55341cb87a7f501',
            boomRoomLink: 'https://minted.network/collections/cronos/0x8dd9975c78423480a6188f55b0767fbf4478b001',
            zoomerLink: 'https://minted.network/en/collections/cronos/0x3d03292d428fd7d6c81a6e76b4a333a3d8a0e296'
        },
    ]

    const scratchBuyObjects: ScratchBuyCard[] = [
        {
            linkTo: 'https://vvs.finance/swap?outputCurrency=0x14fB0e7640e7fb7765CFA87cEc973ff5465d1c66',
            background: '/boomrooms/SCRATCH.svg',
            title: 'SWAP'
        }
    ]

    const ButtonList = () => {
        const buttonArray = []
        for (let i = 0; i < carouselObjects.length; i++) {
            buttonArray.push({ num: i })
        }
        const buttonItems = buttonArray.map((item, index) =>
            <Button key={index} value={item} />
        )
        return (
            <Row justify='center' align='middle' style={{ gap: '10px' }}>
                {buttonItems}
            </Row>
        )
    }

    const Button = (props) => {
        const num = props.value.num
        return (
            <div className={`carousel-button ${num === selected ? 'active' : ''}`} onClick={() => { turnOffAuto(); setSelected(num) }} />
        )
    }

    const ExploreList = (props) => {
        const exploreArray = props.list
        const exploreItems = exploreArray.map((item, index) =>
            <ExploreCard key={index} value={item} />
        )
        return (
            <Row justify='start' align='middle' style={{ gap: '40px' }}>
                {exploreItems}
            </Row>
        )
    }

    const ExploreCard = (props) => {
        const link = props.value.linkTo
        const bg = props.value.background
        const title = props.value.title
        const subtitle = props.value.subtitle

        let mobile = ''
        if (!checkNotMobile(screenWidth)) {
            mobile = 'mobile-'
        }
        return (
            <>
                <Link to={link}>
                    <div className='explore-card'>
                        <img className={'explore-card-image bigger-on-hover'} src={webStorage + bg} />
                        <div>
                            <div className='explore-card-title'>
                                {title}
                            </div>
                            <div className='explore-card-subtitle'>
                                {subtitle}
                            </div>
                        </div>
                    </div>
                </Link>
            </>
        )
    }

    const BuyList = (props) => {
        const arr = props.list
        const items = arr.map((item, index) =>
            <BuyCard key={index} value={item} />
        )
        return (
            <Row justify='space-between' align='middle' style={{ gap: '20px' }}>
                {items}
            </Row>
        )
    }

    const BuyCard = (props) => {
        const marketLink = props.value.marketLink
        const boomerLink = props.value.boomerLink
        const boomRoomLink = props.value.boomRoomLink
        const zoomerLink = props.value.zoomerLink
        const bg = props.value.background
        return (
            <div className='buy-card-container'>
                <a href={marketLink} target='_blank'>
                    <img className='buy-card-image bigger-on-hover' src={webStorage + bg} />
                </a>
                <div className='buy-card-collection-container'>
                    <a href={boomerLink} target='_blank'>
                        <img className='buy-card-collection-logo bigger-on-hover' src={webStorage + '/navbar/boomersquadlogo2.png'} />
                    </a>
                    {
                        boomRoomLink !== '' ?
                            <>
                                <a href={boomRoomLink} target='_blank'>
                                    <img className='buy-card-collection-logo bigger-on-hover' src={webStorage + '/navbar/boomroomLogo.png'} />
                                </a>
                            </>
                            :
                            <>
                                <img className='buy-card-collection-logo monochrome' src={webStorage + '/navbar/boomroomLogo.png'} />
                            </>
                    }
                    {
                        zoomerLink !== '' ?
                            <>
                                <a href={zoomerLink} target='_blank'>
                                    <img className='buy-card-collection-logo bigger-on-hover' src={webStorage + '/navbar/zoomerLogo.png'} />
                                </a>
                            </>
                            :
                            <>
                                <img className='buy-card-collection-logo monochrome' src={webStorage + '/navbar/zoomerLogo.png'} />
                            </>
                    }

                </div>

            </div>
        )
    }

    const ScratchBuyList = (props) => {
        const arr = props.list
        const items = arr.map((item, index) =>
            <ScratchBuyCard key={index} value={item} />
        )
        let mybool = checkNotMobile(screenWidth)
        return (
            <Row justify={mybool ? 'start' : 'center'} align='middle' style={{ gap: '80px' }}>
                {items}
            </Row>
        )
    }

    const ScratchBuyCard = (props) => {
        const linkTo = props.value.linkTo
        const background = props.value.background
        const title = props.value.title
        return (
            <a href={linkTo} target='_blank'>
                <div className='scratchbuy-card-container'>
                    <img className='scratchbuy-card-image shadow-button' src={webStorage + background} />
                    <div className='scratchbuy-card-title'>
                        {title}
                    </div>
                </div>
            </a>
        )
    }



    return (
        checkNotMobile(screenWidth) ?
            <>
                <div className='frontpage-section one'>
                    <div className='carousel-head'>
                        <div className='carousel-card inactive' style={{ background: `url(${webStorage + carouselObjects[selected !== 0 ? selected - 1 : carouselObjects.length - 1].background})` }}
                            onClick={() => { turnOffAuto(); decrease() }} />
                        {
                            carouselObjects[selected].linkTo !== '' ?
                                <>
                                    <Link to={carouselObjects[selected].linkTo} target='_blank'><div className='carousel-card' style={{ background: `url(${webStorage + carouselObjects[selected].background})` }} /></Link>
                                </> : <><div className='carousel-card' style={{ background: `url(${webStorage + carouselObjects[selected].background})` }} /></>
                        }
                        <div className='carousel-card inactive' style={{ background: `url(${webStorage + carouselObjects[selected !== carouselObjects.length - 1 ? selected + 1 : 0].background})` }}
                            onClick={() => { turnOffAuto(); increase() }} />
                        <div className='carousel-arrows-container'>
                            <div className='carousel-arrow' onClick={() => { turnOffAuto(); decrease() }}><div className='arrow-left' /></div>
                            <div className='carousel-arrow' onClick={() => { turnOffAuto(); increase() }}><div className='arrow-right' /></div>
                        </div>
                        <div className='carousel-buttons-container'>
                            <ButtonList />
                        </div>
                    </div>
                </div>
                <div className='frontpage-section two'>
                    <Row justify='center' style={{ width: '100%' }}>
                        <div className='frontpage-section-container'>
                            <div className='frontpage-section-title'>
                                RECENTLY ADDED
                            </div>
                            <div className='frontpage-list'>
                                <ExploreList list={newExploreObjects} />
                            </div>
                        </div>
                    </Row>
                </div>
                <div className='frontpage-section one'>
                    <Row justify='center' style={{ width: '100%' }}>
                        <div className='frontpage-section-container'>
                            <div className='frontpage-section-title'>
                                EXPLORE
                            </div>
                            <div className='frontpage-list'>
                                <ExploreList list={exploreObjects} />
                            </div>
                        </div>
                    </Row>
                </div>
                <div className='frontpage-section two'>
                    <Row justify='center' style={{ width: '100%' }}>
                        <div className='frontpage-section-container'>
                            <div className='mission-section-textonly'>
                                <div className='mission-section-big big-flashy-text'>
                                    <div className='neon-text neon-green text-50'>{'OVER 7 MILLION CRO'}</div><div>{'GIVEN BACK THROUGH MATTRESS FUND, AIRDROPS, AND FREE MINTS'}</div>
                                </div>
                                <div className='mission-section-big big-flashy-text'>
                                    <div className='neon-text neon-lblue text-50'>{'OVER 300K TRANSACTIONS'}</div><div>{'ACROSS OVER 40 SMART CONTRACTS'}</div>
                                </div>
                                <div className='mission-section-big big-flashy-text'>
                                    <div className='neon-text neon-pink text-50'>{'OVER 10.5 MILLION CRO'}</div><div>{'IN SECONDARY SALES ACROSS ALL COLLECTIONS'}</div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
                <div className='frontpage-section one'>
                    <Row justify='center' style={{ width: '100%' }}>
                        <div className='frontpage-section-container'>
                            <div className='frontpage-section-title'>
                                BUY NFTS
                            </div>
                            <div className='frontpage-list'>
                                <BuyList list={NFTMarketObjects} />
                            </div>
                        </div>
                    </Row>
                    <Row justify='center' style={{ width: '100%' }}>
                        <div className='frontpage-section-container'>
                            <div className='frontpage-section-title'>
                                BUY&nbsp;{<img src={webStorage + '/boomrooms/SCRATCH.svg'} style={{ height: '1em' }} />}&nbsp;SCRATCH
                            </div>
                            <div className='frontpage-list'>
                                <ScratchBuyList list={scratchBuyObjects} />
                            </div>
                        </div>
                    </Row>
                </div>
            </>

            :
            // MOBILE MOBILE MOBILEMOBILE MOBILE MOBILE MOBILE MOBILE MOBILEMOBILE MOBILE MOBILE MOBILE MOBILE MOBILEMOBILE MOBILE MOBILE MOBILE MOBILE MOBILEMOBILE MOBILE MOBILE

            <>
                <div className='frontpage-section one'>
                    <div className='mobile-carousel-head'>
                        <div className='mobile-carousel-card'>
                            {
                                carouselObjects[selected].linkTo !== '' ?
                                    <>
                                        <a href={carouselObjects[selected].linkTo} target='_blank'><img className='mobile-carousel-image' src={webStorage + carouselObjects[selected].background}></img></a>
                                    </> : <img className='mobile-carousel-image' src={webStorage + carouselObjects[selected].background}></img>
                            }
                        </div>
                    </div>
                    <div className='mobile-carousel-buttons-container flex-and-center'>
                        <ButtonList />
                    </div>
                </div>
                <div className='frontpage-section two'>
                    <Row justify='center' style={{ width: '100%' }}>
                        <div className='mobile-frontpage-section-container'>
                            <div className='mobile-frontpage-section-title'>
                                RECENTLY ADDED
                            </div>
                            <div className='frontpage-list'>
                                <ExploreList list={newExploreObjects} />
                            </div>

                        </div>
                    </Row>
                </div>
                <div className='frontpage-section one'>
                    <Row justify='center' style={{ width: '100%' }}>
                        <div className='mobile-frontpage-section-container'>
                            <div className='mobile-frontpage-section-title'>
                                EXPLORE
                            </div>
                            <div className='frontpage-list'>
                                <ExploreList list={exploreObjects} />
                            </div>

                        </div>
                    </Row>
                </div>
                <div className='frontpage-section two'>
                    <Row justify='center' style={{ width: '100%' }}>
                        <div className='mobile-frontpage-section-container'>
                            <div className='mobile-big-text-info'><div className='neon-text neon-green text-40'>{'OVER 4 MILLION CRO'}</div><div>{'GIVEN BACK THROUGH MATTRESS FUND, AIRDROPS, AND FREE MINTS'}</div></div>
                            <div className='mobile-big-text-info'><div className='neon-text neon-lblue text-40'>{'OVER 100K TRANSACTIONS'}</div><div>{'ACROSS OVER 40 SMART CONTRACTS'}</div></div>
                            <div className='mobile-big-text-info'><div className='neon-text neon-pink text-40'>{'OVER 5 MILLION CRO'}</div><div>{'IN SECONDARY SALES ACROSS ALL COLLECTIONS'}</div></div>
                        </div>
                    </Row>
                </div>
                <div className='frontpage-section one'>
                    <Row justify='center' style={{ width: '100%' }}>
                        <div className='mobile-frontpage-section-container'>
                            <div className='mobile-frontpage-section-title'>
                                BUY NFTS
                            </div>
                            <div className='frontpage-list'>
                                <BuyList list={NFTMarketObjects} />
                            </div>
                        </div>
                    </Row>
                    <Row justify='center' style={{ width: '100%' }}>
                        <div className='mobile-frontpage-section-container'>
                            <Row className='mobile-frontpage-section-title'>
                                BUY&nbsp;{<img src={webStorage + '/boomrooms/SCRATCH.svg'} style={{ height: '1em' }} />}$SCRATCH
                            </Row>
                            <div className='frontpage-list'>
                                <ScratchBuyList list={scratchBuyObjects} />
                            </div>
                        </div>
                    </Row>
                </div>
            </>
    )
}