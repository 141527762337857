
import { useState, useContext, useEffect } from 'react';
import ConnectedContext from '../../context/connectedcontext';
import Web3Context from '../../context/web3context';
import toContract from '../../types/truffle_contracts';
import Web3 from 'web3';
import { Row, InputNumber } from 'antd';
import ScreenWidthContext from '../../context/screenwidthcontext';
import Spinner from '../../components/spinner'
import connectedAndGoodChain from '../../components/logic/connectedandgoodchain';
import Web3ContractReadWriteContext from '../../context/web3ContractReadWriteContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { webStorage } from '../../config';
import checkNotMobile from '../../components/logic/mobile';
import Contract from '../../contracts/MicrochipsNFT.json'
import { Link } from "react-router-dom";

export const MintMicrochips = () => {

    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)
    const {connected, setConnected} = useContext(ConnectedContext)
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    
    const contractWrite = toContract(Contract)

    // set up contract
    if(contractWriteProviders){
        const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
        contractWrite.setProvider(contractWriteProviders)
    }

    // hooks for use throughout the mint page
    const [currentSupply, setCurrentSupply] = useState('')
    const [amountToMint, setAmountToMint] = useState(1)
    const [loading1, setLoading1] = useState(false)
    // 1000000000000000000 = 1 cro
    /**
     * 
     * Skeleton Variables
     * 
     */

    const description: string = `
    MiCROchips is a 1500 collection made up from 250 individually designed characters with 6 variations.
    As well as being a unique PFP, MiCROchips act as your ticket to our computer world featuring games, soft staking and revenue sharing.
    With a mobile game developed pre mint and a web3 battle game about to go into development, it's prime time to join the mainframe.    
    `

    const maxSupply ='1500'
    const mintPrice = '175000000000000000000'
    const wlMintPrice = '150000000000000000000'

    const MintDescription = () => {
        let m = ''
        if(!checkNotMobile(screenWidth)){
            m='m-'
        }
        return(
            <>
                <div className='lp-mintdescription-row'>{'Mint Start Date: '}<div className={m+'row-answer'}>{'June 1st 19:00 UTC'}</div></div>
                <div className='lp-mintdescription-row'>{'Mint Price:'}<div className={m+'row-answer'}>{'175 CRO'}</div></div>
                <div className='lp-mintdescription-row'>{'WL Mint Price:'}<div className={m+'row-answer'}>{'150 CRO'}</div></div>
                <div className='lp-mintdescription-row'>{'Max Supply:'}<div className={m+'row-answer'}>{maxSupply}</div></div>
            </>
        )
    }

    const multiplyBigInts = (a, b): string => {
        return (BigInt(a.toString()) * BigInt(b.toString())).toString()
    }

    const subBigInts = (a, b): string => {
        return (BigInt(a.toString()) - BigInt(b.toString())).toString()
    }

    const MintButtons = () => {
        return(
            <>
                <div className='lp-button lp-blue bigger-on-hover text-shadow shadow-button-w pink' onClick={() => onMint(mintPrice)}>
                <Spinner loading={loading1} size={75}/>
                {loading1?'':'Mint'}
                </div>
                <div className='lp-button lp-blue bigger-on-hover text-shadow shadow-button-w green' onClick={() => onMint(wlMintPrice)}>
                <Spinner loading={loading1} size={75}/>
                {loading1?'':'WL Mint'}
                </div>
            </>
        )
    }



    const refreshInfo = async() => {
        const web3 = new Web3(contractWriteProviders)

        // update CRO
        let croBalance = await web3.eth.getBalance(account)
        setBalance(web3.utils.fromWei(croBalance, "ether"))

        // update current supply
        let zoomC = await contractWrite.deployed()
        let curSup = await zoomC.totalSupply()
        setCurrentSupply(curSup.toString())
    }

    // update the value in the input number mint box
    const onChange = (value: number) => {
        setAmountToMint(value)
    }

    const onMint = async(pricePer: string) => {
        setLoading1(true)
        let instance = await contractWrite.deployed()
        let totalCost: string = multiplyBigInts(amountToMint, pricePer)

        if(BigInt(totalCost) > BigInt(Web3.utils.toWei(balance, 'ether'))){
            toast.error('Not enough CRO.', {autoClose: 5000});
            setLoading1(false)
            return 
        }

        let data = {from: account, value: totalCost}

        await toast.promise(
            instance.mint(amountToMint, data),
            {
              pending: 'Minting...',
              success: {render(){return <div>Success!</div>},icon: "🟢",},
              error: 'Failed to mint.'
            }
        )
        .then((result: any) => {
            setLoading1(false)
        })
        .catch(()=> setLoading1(false))

        await refreshInfo()
        setLoading1(false)
    }

    // load info on user web3 connection
    useEffect(() => {
        const load = async() => {
            refreshInfo()
        }
        load()
    },[connected])

    const inputNumber = 
    <Row justify='center' style={{marginBottom: '20px'}}>
        <div className='lp-mintdescription-fast'>{'Amount to Mint: '}&nbsp;</div><InputNumber className='lp-mint-inputnumber' size="large" min={1} max={10} defaultValue={1} onChange={onChange}/>
    </Row>

    const mintDetails = 
    <Row justify='center'>
        <div className='lp-mintdescription-fast flex-and-center flex-column'>
            <div className='lp-mintdescription-row'>{'Current Supply: '}{currentSupply}{' / 1500'}</div>
        </div>
    </Row>

    const linkToCollection = 
    <Row justify={'center'}>  
        <Link to={`/collection/0xf038C0cb2935FB8bf9b6e47bCA1df0835f359F92`}>
            <div className='lp-viewcollection-button shadow-button-w'>
                VIEW MY NFTS
            </div>
        </Link>
    </Row>

    const connectWalletComp = 
    <>
        {
            connectedAndGoodChain(connected, chainID)?
                <>
                    {linkToCollection}
                    {mintDetails}
                    <Row justify='center'>
                        <img className='lp-soldout' src={webStorage + '/stickers/soldout.png'}/>
                    </Row>
                    {/* {inputNumber}
                    <Row justify='center' style={{display: 'flex', margin: '0px 0px 40px 0px'}}>
                        <MintButtons />
                    </Row> */}
                </>
                :
                <>
                    <Row justify='center'>
                        <div className='lp-mintdescription-fast'>
                            <div className='lp-mintdescription-connectwallet'>{'Connect Wallet To Mint!'}</div>
                        </div>
                    </Row>
                </>
        }
    
    </>





    return (
        <>
            <ToastContainer position="top-center"/>
        {
            checkNotMobile(screenWidth)?
            <>
                <div className="container2"><img className='banner' src={webStorage + '/launchpad/microchips/banner.png'} /></div>
                <div className='lp-nftbanner-line'/>
                <Row justify='center'>
                    <div className='lp-description'>
                        {description}
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-mintrow'>
                        <div>
                            <img className='lp-mintgif' src={webStorage + '/launchpad/microchips/chips.gif'} alt=''/>
                        </div>
                        <div className='lp-mintdescription'>
                            <MintDescription/>
                        </div>
                    </div>
                </Row>
                {connectWalletComp}
            </>

            :
        //MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================
            <>
                <div className="container2"><img className='banner' src={webStorage + '/launchpad/microchips/banner.png'} alt="myboomersbanner"/></div>
                <div className='mobile-lp-nftbanner-line'/>
                <Row justify='center'>
                    <div className='mobile-lp-description'>
                        {description}
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='mobile-lp-mintrow flex-and-center flex-column'>
                        <div>
                            <img className='mobile-lp-mintgif' src={webStorage + '/launchpad/microchips/chips.gif'} alt=''/>
                        </div>
                        <div className='mobile-lp-mintdescription'>
                            <MintDescription/>
                        </div>
                    </div>
                </Row>
                {connectWalletComp}
            </>
        }
        </>

    )
}