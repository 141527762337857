import { useParams } from 'react-router-dom';
import { useState, useContext, useEffect, useCallback } from 'react';
import checkNotMobile from "../components/logic/mobile";
import ScreenWidthContext from '../context/screenwidthcontext';
import Web3Context from '../context/web3context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../components/spinner';
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';
import { webStorage } from '../config';
import { fetchSingleTokenMeta } from '../components/utils/api_calls';
import { getBoomerRank, getTraitRarity } from '../utils';
import BoomerScratchDistribution from './../contracts/BoomerScratchDistribution.json';
import BoomerDistribution from './../contracts/BoomerDistribution.json';
import Web3 from 'web3';
import toContract from './../types/truffle_contracts';

export const Boomer = () => {

    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    const [owner, setOwner] = useState('0x...')
    const [cAdd, setCAdd] = useState('0x...')
    const { id } = useParams();
    const [metadata, setMetadata] = useState({} as any)
    const [allowance, setAllowance] = useState('0')
    const [croOwed, setCroOwed] = useState('0')
    const [scratchOwed, setScratchOwed] = useState('0')

    const boomContractAddress = '0xb650279e3d726B0c75C984cAA55341cB87A7F501'
    const BDAddress = '0xf0EA472fB04dA831106940f58928b0e90e085BbB'
    const SBDAddress = '0x69F1a444d49Cb2BF0be0C2695E72fEd03272c17d'

    const bdWrite = toContract(BoomerDistribution)
    const sbdWrite = toContract(BoomerScratchDistribution)
  
    //set up contract
    if(contractWriteProviders){
        bdWrite.setProvider(contractReadProviders)
        sbdWrite.setProvider(contractWriteProviders)
    }


    const multiRelease = async(contract: any) => {
        let instance = await contract.deployed()
        await toast.promise(
            instance.multiRelease([id], account, {from: account}),
        {
          pending: 'Withdrawing from Mattress Fund...',
          success: {
            render(){
              return <div>Success! now refreshing Browser</div>
            },
            // other options
            icon: "🟢",
          },
          error: 'Failed to Withdraw.'
        }
      )
      .then(() => setTimeout((() => window.location.reload()), 5500))
      .catch((e) => {console.log('something went wrong :(', e); return})
    }

    const Attributes = (props) => {
        let list = props.list
        let listItems = list.map((item)=>{
            let trait_type = item.trait_type.toString()
            let value = item.value.toString()
            return(
            <div className='zoomer-trait-box'>
                <div className='zoomer-trait-box-type'>
                    {trait_type}
                </div>
                <div className='zoomer-trait-box-value'>
                    {value}
                </div>
                <div className='zoomer-trait-box-rarity'>
                    {getTraitRarity(trait_type, value)}
                </div>
            </div>
        )})
        return listItems
    }
    useEffect(()=>{
        const getMetadata = async() => {
            let json = await fetchSingleTokenMeta(id.toString(), boomContractAddress)
            return {
                json: json.json,
                owner: json.owner,
                add: boomContractAddress
            }
        }
        
        getMetadata().then((result)=>{setMetadata(result.json); setOwner(result.owner); setCAdd(result.add)})
    },[id])

    useEffect(()=>{
        const getMatOwed = async(abi: any, address: string) => {
            const web3 = new Web3(contractWriteProviders);
            let instance = new web3.eth.Contract(abi, address)
            let owed = await instance.methods.mymultiPAYOUT([id]).call()
            const owedCro = Web3.utils.fromWei(owed.toString(), 'ether').toString()
            const regex = /(\d+\.\d{2})\d*/;
            const result = owedCro.replace(regex, '$1');
            return result
        }
        getMatOwed(BoomerDistribution.abi, BDAddress).then((result)=>{setCroOwed(result)})
        getMatOwed(BoomerScratchDistribution.abi, SBDAddress).then((result)=>{setScratchOwed(result)})
    },[contractWriteProviders])

    let m = checkNotMobile(screenWidth)?'':'m-'
    

    return(
        <>
        <ToastContainer position="top-center"/>
        <div className='centered-x-and-y'><img className='banner' src={webStorage + '/myboomers/myboomersbanner.png'} alt="foo"/></div>
        <div className={m+'zoomer-all-container'}>
            <div className={m+'zoomer-section-container'}>
                <img className='zoomer-section-image' src={metadata.image}/>
            </div>
            <div className={m+'zoomer-section-container'}>
                <div className='zoomer-section-box'>
                    <div className='zoomer-section-row'>
                        <div className='zoomer-collection-symbol-container'>
                            <img src={webStorage + '/navbar/boomersquadlogo2.png'} style={{width: '100%'}}></img>
                        </div>
                        <div className='zoomer-section-name'>
                            {metadata.name}
                        </div>
                    </div>
                </div>
                <div className='zoomer-section-box'>
                    <div className='zoomer-info-container'>
                        <div className="test-container">
                            <div className="test-title">Rank</div>
                            <div className="test-description">{getBoomerRank(id)}</div>
                        </div>
                        <div className="test-container">
                            <div className="test-title">Token ID</div>
                            <div className="test-description">{metadata.id}</div>
                        </div>
                        <div className="test-container">
                            <div className="test-title">Contract Address</div>
                            <div className="test-description"><a href={'https://cronoscan.com/address/'+cAdd} target='blank'>{cAdd}</a></div>
                        </div>
                        <div className="test-container">
                            <div className="test-title">Owner</div>
                            <div className="test-description"><a href={'https://cronoscan.com/address/'+owner} target='blank'>{owner}</a></div>
                        </div>
                        <div className="test-container">
                            <div className="test-title">Description</div>
                            <div className="test-description">{metadata.description}</div>
                        </div>
                    </div>
                </div>
                <div className='zoomer-section-box'>
                    <div className='zoomer-attributes-container'>
                        <Attributes list={metadata.attributes?metadata.attributes:[]} />
                    </div>
                </div>
                {
                account === owner &&
                <div className='zoomer-section-box'>
                    <div className='flex-and-center' style={{gap: '10px', flexWrap: 'wrap'}}>
                        <div className='zoomer-special-button lb' onClick={() => multiRelease(bdWrite)}>
                            <div>WITHDRAW CRO</div>
                        </div>
                        <div className='zoomer-special-button green' onClick={() => multiRelease(sbdWrite)}>
                            <div>WITHDRAW SCRATCH</div>
                        </div>
                    </div>
                    <div className="test-container">
                        <div className="test-title">Mattress Funds Owed</div>
                        <div className="test-description" style={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                                <img className='cronoslogo' src={webStorage + '/myboomers/cronosLogo.png'}></img>{croOwed}
                            </div>
                            <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                                <img className='cronoslogo' src={webStorage + '/myboomers/scratchLogo.png'}></img>{scratchOwed}
                            </div>
                        </div>
                    </div>
                </div>
                }
                </div>
            </div>
        </>
    )
}