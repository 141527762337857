import { launchpadObjs } from "./launchpadobj"
import { useState, useContext, useEffect, useCallback } from 'react';
import { webStorage } from "../config";
import { Link } from "react-router-dom";

export const LaunchpadFront = () => {


    const [upcommingMints, setUpcommingMints] = useState([] as any[])
    const [currentMints, setCurrentMints] = useState([] as any[])
    const [soldOutMints, setSoldOutMints] = useState([] as any[])

    const MintCategory = (props) => {
        let list = props.list;
        let status = props.status;
        let title = props.title;
        let link = props.link
        let listItems = list.map((item)=>{

            return status === item.status && (
                <Link to={item.link}>
                    <div className='lp-section-card bigger-on-hover'>
                        <div className="lp-section-name">
                            {item.name}
                        </div>
                        <div className="lp-section-container">
                            <img className="lp-section-image" src={item.image}/>
                        </div>
                    </div>
                </Link>
            )
        })

        return(
            <div className='lp-section'>
                <div className="lp-section-title">
                    {title}
                </div>
                <div className="lp-section-items">
                    {listItems}
                </div>
            </div>
        )
    }

    useEffect(()=>{
        let tempUp = [] as any []
        let tempCur = [] as any []
        let tempSold = [] as any []
        for(let i in launchpadObjs){
            launchpadObjs[i].status === 1 && tempUp.push(launchpadObjs[i])
            launchpadObjs[i].status === 2 && tempCur.push(launchpadObjs[i])
            launchpadObjs[i].status === 2 && tempSold.push(launchpadObjs[i])
        }
        setUpcommingMints(tempUp)
        setCurrentMints(tempCur)
        setSoldOutMints(tempSold)
    },[])

    return(
        <>
            <div className='centered-x-and-y'><img className='banner' src={webStorage + '/launchpad/launchpad_banner.png'} alt="foo"/></div>
            <div className="lp-row">
                <div className='lp-stuff'>
                    <MintCategory list={launchpadObjs} status={1} title={'Upcoming'}/>
                    <MintCategory list={launchpadObjs} status={2} title={'Currently Minting'}/>
                    <MintCategory list={launchpadObjs} status={3} title={'Sold Out'}/>
                </div>
            </div>

        </>
    )
}