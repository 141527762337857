import { useParams } from 'react-router-dom';
import { webStorage } from '../config';
import { useState, useContext, useEffect } from 'react';
import './bazaarStyles.css'
import './bazaarStylesM.css'
import './bazaarItemFocus.css'
import checkNotMobile from "../components/logic/mobile";
import ScreenWidthContext from '../context/screenwidthcontext';
import Web3Context from '../context/web3context';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../components/spinner';
import { bazaarTestObj } from './bazaarTestObj';
import Web3 from 'web3';
import { InputNumber } from 'antd';

import ImageGallery from 'react-image-gallery';
import { approveTokenContract, buyItem, checkAllowance, convertToETH, getItemInfo, tokenContracts } from './bazaarUtils';
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';
import { ToastContainer } from 'react-toastify';
import { multiplyBigInts } from '../components/utils/web3Utils';


export const BazaarItemFocus = () => {


    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)

    const { id } = useParams();
    const item = bazaarTestObj[Number(id)-1]

    const [stock, setStock] = useState('')
    const [price, setPrice] = useState('')
    const [WLIndex, setWLIndex] = useState('')
    const [tokenIndex, setTokenIndex] = useState('')
    const [decimals, setDecimals] = useState(0)
    const [loadingInfo, setLoadingInfo] = useState(true)
    const [amount, setAmount] = useState(1)

    const idd = item.id
    const name = item.name
    // const price = item.price
    const currency = item.currency
    const image = item.images[0]
    const project = item.project
    const type = item.type
    // const WLIndex = item.WLIndex;
    const extraInfo = item.extraInfo
    const td = item.technicalDetails

    const onChange = (value: number) => {
        setAmount(value)
    };

    let mV = '100px' // mobile
    let dV = '500px' // desktop

    let tmV = '25px' // mobile
    let tdV = '125px' // desktop

    const imgs = Array.from(item.images, (image) => ({
        original: webStorage+'/bazaar/item_images/'+id+"/"+image+'.png',
        thumbnail: webStorage+'/bazaar/item_images/'+id+"/"+image+'.png',
    }))

    const buy = async(id) => {
        
        await buyItem(contractWriteProviders, account, id, amount, price, tokenIndex)
        refreshData()
    }

    // check sqaures contract Scratch allowance
    const [allowance, setAllowance] = useState('0')
    useEffect(() => {
        let on = true
        if(on && tokenIndex !== '0' && tokenIndex !== '' && contractWriteProviders){
            checkAllowance(contractWriteProviders, account, tokenIndex)
            .then((r) => {setAllowance(r)})
        }
        return () => {on = false}
    },[tokenIndex, contractWriteProviders])

    const refreshData = async() => {
        setLoadingInfo(true)
        let result = await getItemInfo(contractWriteProviders, id)
        setStock(result.itemStock)
        setPrice(result.itemPrice.toString())
        setWLIndex(result.WLIndex)
        setTokenIndex(result.itemTokenIndex)
        setDecimals(tokenContracts[result.itemTokenIndex].decimals)
        setLoadingInfo(false)
    }

    useEffect(() => {
        if(contractWriteProviders){
            refreshData()
        }
    },[contractWriteProviders])

    return(
        <>
            <img className='banner' style={{width: '100%'}} src={webStorage + '/bazaar/bazaar_temp_banner2.png.png'} alt=""/>
            <ToastContainer position="top-center"/>
            <div className={`${checkNotMobile(screenWidth)?"":"mobile-"}bazaar-item-focus-container`}>
                <div className={`${checkNotMobile(screenWidth)?"":"mobile-"}bazaar-item-focus-section-image`}>
                    <ImageGallery showNav={false} showPlayButton={false} items={imgs} />
                </div>
                <div className='bazaar-item-focus-section-info-container'>
                    <div className='bazaar-item-focus-section-info'>
                        <div className='bazaar-item-focus-section-info-name'>
                            {name}
                        </div>
                        <div className='bazaar-item-focus-section-info-project'>
                            by {project}
                        </div>
                        <div className='bazaar-item-focus-section-info-line' />
                        <div style={{height: '130px', display: 'flex', alignItems: 'start', flexDirection: 'column', width: '100%', gap: '5px', justifyContent:'center'}}>
                        {
                            loadingInfo?
                            <>
                                <Spinner loading={true} colour={'orange'} size={100}/>
                            </>
                            
                            :
                            <>
                                <div style={{display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'baseline'}}>
                                    <div className='bazaar-item-focus-section-info-price'>
                                        {convertToETH(price, decimals)}
                                    </div>
                                    <div className='bazaar-item-focus-section-info-currency'>
                                        {currency}
                                    </div>
                                </div>
                                <div className='bazaar-item-focus-section-info-stock'>
                                    <div style={{fontSize: '30px'}}>{stock}</div>&nbsp;in stock <div style={{fontStyle: 'italic'}}>{WLIndex !== '0'?' - Whitelist Required':''}</div>
                                </div>
                                    <div style={{display: 'flex', gap: '20px'}}>
                                        {
                                            BigInt(allowance) < BigInt(multiplyBigInts(amount.toString(), price)) && tokenIndex !== '0'?
                                            <>
                                                <div className='bazaar-item-focus-section-info-buy' onClick={()=>approveTokenContract(contractWriteProviders, account, tokenIndex)}>
                                                    Approve Contract
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='bazaar-item-focus-section-info-buy' onClick={()=>buy(id)}>
                                                    Buy item
                                                </div>
                                            </>
                                        }
                                        <div>
                                            Qty: <InputNumber style={{width: '55px'}} min={1} max={100} defaultValue={1} onChange={onChange} />
                                        </div>
                                    </div>
                            </>
                        }
                        </div>
                        <div className='bazaar-item-focus-section-info-line' />
                        <div className='bazaar-item-focus-section-info-extras'>
                            <div style={{fontFamily:'nowbold', fontSize: '17px'}}>About this item</div>
                            {extraInfo.map((info) => {
                                return(
                                    <li>
                                        {info}
                                    </li>
                                )
                            })}
                        </div>
                        <div className='bazaar-item-focus-section-info-details'>
                            Technical Details
                            <table className='bazaar-details-table'>
                                <tr>
                                    <th>Product Dimensions</th>
                                    <th>Item Weight</th>
                                    <th>Manufacturer</th>
                                </tr>
                                <tr>
                                    <td>{td.productDimensions}</td>
                                    <td>{td.itemWeight}</td>
                                    <td>{td.manufactuer}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>


            </div>




        </>
    )
}