import { Row } from 'antd';
import ConnectModal from './connectmodal'
import { useState, useContext, useEffect } from 'react';
import Web3Context from './context/web3context';
import Web3 from 'web3'
import ScreenWidthContext from './context/screenwidthcontext';
import './mobilestyles.css'
import Web3ProviderContext from './context/web3ProviderContext';
import { Link } from 'react-router-dom';
import { getScratchBalance } from './components/utils/scratch-functions';
import 'react-toastify/dist/ReactToastify.css';
import { webStorage } from './config';
import { exploreObjects, newExploreObjects } from './frontpage/frontObjs';

function NavBar() {
    //handle window resize and propagate context
    useEffect(() => {
        const handleWindowResize = () => setScreenWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize)
        return () => window.removeEventListener("resize", handleWindowResize)
    })
    //web 3 provider
    const { web3Provider, setWeb3Provider } = useContext(Web3ProviderContext)
    //Screen Width context
    const { screenWidth, setScreenWidth } = useContext(ScreenWidthContext)
    //Web3 context, account context
    const { account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance } = useContext(Web3Context)
    const [modalVisibility, setModalVisibility] = useState(false)
    //toggles visibility of modal, called when clicking on "Connect Wallet" button and used when hitting "Close" in the modal or pressing anywhere in the "mask area" aka grey area of modal  
    const toggleVisibility = () => {
        setModalVisibility(!modalVisibility)
    }
    const web3 = new Web3(web3Provider || Web3.givenProvider || "ws://localhost:8545"); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
    const [burgerToggle, setBurgerToggle] = useState(false)
    const toggleBurger = () => {
        setBurgerToggle(!burgerToggle)
    }
    //This useEffect listens to changes in account and chain for metamask... only? May not work for wallet connect or CDC connect
    useEffect(() => {
        const { ethereum } = window as any
        //Change account in context when user changes account in metamask
        function handleAccountsChanged(accounts) {

            //update account
            if (accounts.length > 0) {
                setAccount(accounts[0])
                console.log('changed to account', accounts[0])
                web3.eth.getBalance(accounts[0], function (err, result) {
                    if (err) {

                    } else {
                        setBalance(web3.utils.fromWei(result, "ether"))
                    }
                })
                getScratchBalance(Web3.givenProvider || "ws://localhost:8545", accounts[0]).then(function (scratchResult) {
                    setScratchBalance(scratchResult as string)
                })
            } else {
                // localStorage.setItem('isWalletConnected', 'false')
                window.location.reload()
            }
        }
        //Change chain in context when user changes chains in metamask
        function handleChainIDChanged(chain) {

            setChainID(parseInt(chain, 16))
            console.log('updated chain to ', parseInt(chain, 16))
        }
        if (ethereum) {
            ethereum.on('accountsChanged', handleAccountsChanged)
            ethereum.on('chainChanged', handleChainIDChanged)
        }
        return () => {
            if (ethereum) {
                ethereum.removeListener('accountsChanged', handleAccountsChanged)
                ethereum.removeListener('chainChanged', handleChainIDChanged)
            }
        }
    }, [])

    const BurgerIcon = () => {
        return (
            burgerToggle ?
                <div className='mobile-burgerContainer' onClick={() => { setBurgerToggle(false); }}>
                    <div className={`burgerIconLayer white`} />
                    <div className={'burgerIconLayer'} />
                    <div className={`burgerIconLayer white`} />
                    <div className={'burgerIconLayer'} />
                    <div className={`burgerIconLayer white`} />
                </div>
                :
                <div className='mobile-burgerContainer insideBurger' onClick={() => { setBurgerToggle(true); }}>
                    <div className={`burgerIconLayer red`} />
                    <div className={'burgerIconLayer'} />
                    <div className={`burgerIconLayer red`} />
                    <div className={'burgerIconLayer'} />
                    <div className={`burgerIconLayer red`} />
                </div>
        )
    }

    const BurgerMenu = () => {
        return (
            <div className={`burger ${burgerToggle ? "showBurger" : "hideBurger"}`} onClick={toggleBurger}>
                <div className='burger-header'>
                    <BurgerIcon />
                </div>
                <div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', height: 'calc(100vh - 100px)', paddingTop: '10px', gap: '20px' }}>
                    <Link to="/frontpage" style={{ display: 'flex', justifyContent: 'center' }}><img className='mobile-header-logo' style={{ width: '80px', height: '80px' }} src={webStorage + '/navbar/boomersquadlogo2.png'} /></Link>
                    <BoomerArcadeLogo />
                    <DropdownItems list={newExploreObjects} />
                    <DropdownItems list={exploreObjects} />
                    <div className='container-in-burger'>
                        <a href='https://boomer-squad.gitbook.io/boomer-squad-nft/' target='_blank'><img className='burger-socials' src={webStorage + '/navbar/gitbookLogo.png'} /></a>
                        <a href='https://discord.gg/boomer-squad-nft-939820143486320650' target='_blank'><img className='burger-socials' onClick={toggleBurger} src={webStorage + '/navbar/discordlogo.png'} alt="foo" /></a>
                        <a href='https://twitter.com/BoomerSquadNFT1' target="_blank"><img className='burger-socials' onClick={toggleBurger} src={webStorage + '/navbar/twitterlogo.png'} alt="foo" /></a>
                        <a href='https://medium.com/@BoomerSquadNFT' target="_blank"><img className='burger-socials' onClick={toggleBurger} src={webStorage + '/navbar/mediumlogo.png'} alt="foo" /></a>
                    </div>
                </div>
            </div>
        )
    }

    const DropdownItems = (props) => {
        const arr = props.list
        const items = arr.map((item, index) =>
            <DropdownItem key={index} value={item} />
        )
        return (
            <div className='flex-and-center flex-column'>
                {items}
            </div>
        )
    }

    const DropdownItem = (props) => {
        const link = props.value.linkTo
        const title = props.value.title
        return (
            link !== '/' ?
                <>
                    <Link to={link} >
                        <div className='dropdown-item'>
                            {title}
                        </div>
                    </Link>
                </> : <></>
        )
    }

    return (

        screenWidth > 1279

            ?
            <Row justify='center' style={{ width: '100%' }}>
                {/* <ToastContainer position="top-center"/> */}
                <div className='fixed-header'>
                    <Row justify='start' align='middle' style={{ gap: '40px' }}>
                        <Link to="/frontpage"><img className='header-logo bigger-on-hover' src={webStorage + '/navbar/boomersquadlogo2.png'} alt="boomersquadlogo2" /></Link>
                        <BoomerArcadeLogo />
                        <div className="dropdown-navbar">
                            <Row justify='space-between' align='middle'>EXPLORE<div className='dropdown-arrow' /></Row>
                            <div className="dropdown-content">
                                <div className='dropdown-invisible'>
                                    <div className='dropdown-blue-arrow'>

                                    </div>
                                </div>
                                <div className='dropdown-visible' >
                                    <DropdownItems list={exploreObjects} />
                                </div>
                            </div>
                        </div>
                        <div className="dropdown-navbar">
                            <Row justify='space-between' align='middle'>NEW<div className='dropdown-arrow' /></Row>
                            <div className="dropdown-content">
                                <div className='dropdown-invisible'>
                                    <div className='dropdown-blue-arrow'>

                                    </div>
                                </div>
                                <div className='dropdown-visible' >
                                    <DropdownItems list={newExploreObjects} />
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row justify='center' align='middle'>
                        <div className='container-in-burger'>
                            <a href='https://boomer-squad.gitbook.io/boomer-squad-nft/' target='_blank'><img className='burger-socials' src={webStorage + '/navbar/gitbookLogo.png'} /></a>
                            <a href='https://discord.gg/boomer-squad-nft-939820143486320650' target='_blank'><img className='burger-socials' src={webStorage + '/navbar/discordlogo.png'} /></a>
                            <a href='https://twitter.com/BoomerSquadNFT1' target="_blank"><img className='burger-socials' src={webStorage + '/navbar/twitterlogo.png'} /></a>
                            <a href='https://medium.com/@BoomerSquadNFT' target="_blank"><img className='burger-socials' src={webStorage + '/navbar/mediumlogo.png'} /></a>
                        </div>
                        <ConnectModal />
                    </Row>
                </div>
            </Row>


            :
            <>
                <div className='mobile-fixed-header'>{/*Used to make the header fixed at the top of the screen*/}
                    <Row justify='space-between' align='middle' style={{ width: '100%' }}>
                        <BurgerIcon />
                        <ConnectModal />
                        <Link to="/frontpage"><img className='mobile-header-logo' src={webStorage + '/navbar/boomersquadlogo2.png'} alt="" /></Link>
                    </Row>
                </div>
                <BurgerMenu />
            </>



    )
}

const BoomerArcadeLogo = () => {
    return (
        <Link to='https://boomerarcade.io' target='_blank' className='boomerarcade-logo'>
            Boomer<span className='boomerarcade-logo-accent'>Arcade</span>
        </Link>

    );
};


export default NavBar