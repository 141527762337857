import Web3 from 'web3';
import BazaarContract from '../contracts/Bazaar.json'
import ScratchContract from '../contracts/Scratch.json'
import USDCContract from '../contracts/CronosCRC20.json'
import toContract from "../types/truffle_contracts";
import { toast } from 'react-toastify';
import { Contract } from 'web3-eth-contract';

const baz = toContract(BazaarContract)
// const scratch = toContract(ScratchContract)
// const USDC = toContract(USDCContract)

// var BoomRoomABI = require(path.join(__dirname, '/contracts/BoomRoom.json'));
// let brContract = new web3.eth.Contract(BoomRoomABI.abi, boomroomConfig.boomRoomContractAddress)

export const getItemInfo = async(provider: any, id: string) => {
    baz.setProvider(provider)
    const b = await baz.deployed()
    let item = await b.viewItem(id)
    return item
}

export const getUserInventory = async(provider: any, account: string) => {
    baz.setProvider(provider)
    const b = await baz.deployed()
    let inventory = await b.viewUserStorage(account)
    return inventory
}


export const buyItem = async(provider: string, account: string, id: string, amount: number, price: string, tokenIndex: string) => {
    baz.setProvider(provider)
    const b = await baz.deployed()
    let totalPrice = (BigInt(price) * BigInt(amount))

    if(tokenIndex === '0'){
      let web3 = new Web3(provider);
      let balance = await web3.eth.getBalance(account)
        if(totalPrice > BigInt(balance)){
            toast.error('Not Enough CRO Balance', {autoClose: 5000});
            return
        }
    } else {
      // let tokenContract = tokenContracts[tokenIndex]
      // tokenContract.setProvider(provider)

      let tokenInstance = await getTokenInstance(provider, tokenIndex)

      // const s = await tokenContract.deployed()
      let tokenBalance = await tokenInstance.methods.balanceOf(account).call()
        if(totalPrice > BigInt(tokenBalance)){
            toast.error('Not Enough Token Balance', {autoClose: 5000});
            return
        }
    }

    if(tokenIndex == '0'){
        await toast.promise(
            b.purchaseItem(id, amount, {from: account, value: (BigInt(amount)*BigInt(price)).toString()}),
            {
              pending: 'Buying item...',
              success: {
                render(){
                  return <div>Success!</div>
                },
                // other options
                icon: "🟢",
              },
              error: 'Failed to buy.'
            }
        )
    } else {
        await toast.promise(
            b.purchaseItem(id, amount, {from: account}),
            {
              pending: 'Buying item...',
              success: {
                render(){
                  return <div>Success!</div>
                },
                // other options
                icon: "🟢",
              },
              error: 'Failed to buy.'
            }
        )  
    }
}

export const shipItems = async(provider: string, account: string, indexes: number[]) => {
  // function requestShipping(uint256[] memory _itemStorageIndexes) public {
    baz.setProvider(provider)
    const b = await baz.deployed()
    let tx = await toast.promise(
      b.requestShipping(indexes, {from: account}),
      {
        pending: 'Resquesting to Ship...',
        success: {
          render(){
            return <div>Success!</div>
          },
          // other options
          icon: "🟢",
        },
        error: 'Something went wrong :x.'
      }
  )
  return(tx)
}

export const checkAllowance = async(provider: string, account: string, tokenIndex: string): Promise<string> => {
  const b = await baz.deployed()
  let tokenContract = await getTokenInstance(provider, tokenIndex)
  let allowance = await tokenContract.methods.allowance(account, b.address).call()
  return allowance.toString()
}

export const approveTokenContract = async(provider: string, account: string, tokenIndex: string) => {
  let tokenContract = await getTokenInstance(provider, tokenIndex)
  baz.setProvider(provider)
  const b = await baz.deployed()
  await toast.promise(
    tokenContract.methods.approve(b.address, '10000000000000000000000000000000').send({from: account}),
    {
      pending: 'Approving Contract...',
      success: {
        render(){
          return <div>Success! Now Refreshing Browser</div>
        },
        // other options
        icon: "🟢",
      },
      error: 'Failed to approve.'
    }
  ).then(
    () => setTimeout((() => window.location.reload()), 5500)
  )
}

const getTokenInstance = async(provider: string, tokenIndex: string) => {
  //make web instance
  const web3 = new Web3(provider);

  //get token contract ABI using tokenIndex
  let tokenContract = tokenContracts[tokenIndex]

  //create contract instance
  let instance = new web3.eth.Contract(tokenContract.contract.abi, tokenContract.address)

  return instance
}

type TokenContract = {
  contract: any,
  address: string,
  decimals: number
}

export function convertToETH(number: string, decimals: number): string {
  const numberFloat = Number(number) / Math.pow(10, decimals);
  return numberFloat.toFixed(2);
}


export const tokenContracts: Record<string, TokenContract> = {
  '0': {
    contract: null,
    address: null,
    decimals: 18
  },
  '1': {
    contract: ScratchContract,
    address: '0x14fB0e7640e7fb7765CFA87cEc973ff5465d1c66',
    decimals: 18
  },
  '2': {
    contract: USDCContract,
    address: '0xc21223249ca28397b4b6541dffaecc539bff0c59',
    decimals: 6
  }
}

export const getTokenNameFromIndex = {
    '0': 'CRO',
    '1': 'SCRATCH',
    '2': 'USDC'
}

export const checkValidAddressInfo = (
  firstName: string,
  lastName: string,
  phoneNumber: string,
  address: string,
  postal: string,
  validEmail: string,
  discordTag: string
): boolean => {
  let valid = true;

  if(
    firstName.length < 1 ||
    lastName.length < 1 ||
    phoneNumber.length < 10 ||
    address.length < 4 ||
    postal.length < 1 ||
    (
      validEmail.length < 1 &&
      discordTag.length < 4
    )

    ){
    valid = false;
  }
  return valid
}

export interface BazaarOrder {
  tx: string
  items: Item[]
  address_1: string,
  address_2: string,
  postal: string,
  name: string
  email: string
  phone_number: string
  phone_ext: string
  discord_tag: string

}

export interface Item {
  item_id: string
  item_amount: string
}


