import { useParams } from 'react-router-dom';
import { useState, useContext, useEffect, useCallback } from 'react';
import checkNotMobile from "../components/logic/mobile";
import ScreenWidthContext from '../context/screenwidthcontext';
import Web3Context from '../context/web3context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../components/spinner';
import Web3ContractReadWriteContext from '../context/web3ContractReadWriteContext';
import { webStorage } from '../config';

import ScratchContract from '../contracts/Scratch.json'
import Zoomers from '../contracts/Zoomers.json'
import toContract from '../types/truffle_contracts';

import './zoomer.css'
import { zoomerRank, zoomerTraitP } from './zoomerTraitPercentages';
import { zConf } from './zoomerConfig';
import { fetchSingleTokenMeta, requestChangeVariant, updateCoverTrait } from '../components/utils/api_calls';
import { approveScratchContract, checkAllowance, rollTrait } from './zoomerContractCalls';

export const Zoomer = () => {

    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)

    const zoomerWrite = toContract(Zoomers)
    const scratchWrite = toContract(ScratchContract)
    const [owner, setOwner] = useState('0x...')
    const [cAdd, setCAdd] = useState('0x...')

    const { id } = useParams();

    const [metadata, setMetadata] = useState({} as any)
    const [allowance, setAllowance] = useState('0')

    const coverCodex = {
       '1':'Monochrome',
       '2':'Static',
       '3':'Fog',
       '4':'Oil Painting',
       '5':'Neon',
       '6':'Stain Glass',
       '7':'Silver',
       '8':'Gold',
       '9':'Holographic'
    }

    const appCon = async() => {
        await toast.promise(
            approveScratchContract(contractWriteProviders, account),
            {
                pending: 'Approving Contract...',
                success: {
                render(){
                    return <div>Success! Now refreshing browser</div>
                },
                // other options
                icon: "🟢",
                },
                error: 'Something went wrong! :x.'
            }
        ).then(
            () => setTimeout((() => window.location.reload()), 5500)
        )
    }

    const rollT = async() => {
        await toast.promise(
            rollTrait(contractWriteProviders, account, id),
            {
                pending: 'Rolling Cover...',
                success: {
                render(){
                    return <div>Success!</div>
                },
                // other options
                icon: "🟢",
                },
                error: 'Something went wrong! :x.'
            }
        )
        .then((result: any) => {
            console.log(result)
            updateCoverTrait(id).then(
                (result) => {
                    console.log(result)
                }
            )
            let newTrait = result.receipt.logs[0].args.trait.toString()
            let traitName = coverCodex[newTrait]
            setTimeout((() => window.location.reload()), 5500)
            toast(`🟢 Rolled ${traitName}! Now Refreshing Browser...`, {
                position: "top-center",
                autoClose: 4500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
    }

    const openModal = (id) => {
        // Get the modal
        var modal = document.getElementById(id);

        // Open modal
        modal.style.display = "flex";
    }

    const closeModal = (id) => {
        // Get the modal
        var modal = document.getElementById(id);

        // Close modal
        modal.style.display = "none";
    }

    const reqVChange = async(id: string, variant: string, func: any) => {
        func.setLoading(true)
        let response = await requestChangeVariant(id, variant)
        console.log(response)
        func.setLoading(false)
        func.setMessageData(response.message)
    }

    const coverCodexNameToNum = {
        'Monochrome':'1',
        'Static':'2',
        'Fog':'3',
        'Oil Painting':'4',
        'Neon':'5',
        'Stain Glass':'6',
        'Silver':'7',
        'Gold':'8',
        'Holographic':'9'
     }

    let variantModalID: string  = 'vModal'
    const VariantModal = () => {
        const [loading, setLoading] = useState(false)
        const [messageData, setMessageData] = useState('')
        const func = {setLoading: setLoading, setMessageData: setMessageData}
        let cNum = coverCodexNameToNum[metadata.attributes[8].value]
        return(
            <div id={variantModalID} className={'my-modal'} onClick={() => closeModal(variantModalID)}>
                <div className='my-modal-box' onClick={(e) => e.stopPropagation()}>
                    {
                        !loading?
                        <>
                            {
                                messageData === '' &&
                                <>
                                    <div className='my-modal-title'>
                                    Select Variant
                                    </div>
                                    <div className='my-modal-box-container'>
                                    <div className='my-modal-box-item bigger-on-hover' onClick={async() => reqVChange(id.toString(), '1', func)}>
                                        Variant 1: In-Game
                                        <img className='my-modal-box-item-image' src={zConf.V1 + id + '.png'}/>
                                    </div>
                                    <div className='my-modal-box-item bigger-on-hover' onClick={() => reqVChange(id.toString(), '2', func)}>
                                        Variant 2: PFP
                                        <img className='my-modal-box-item-image' src={zConf.V2 + id + '.png'}/>
                                    </div>
                                    <div className='my-modal-box-item bigger-on-hover' onClick={() => reqVChange(id.toString(), '3', func)}>
                                        Variant 3: Out-Game
                                        <img className='my-modal-box-item-image' src={zConf.V3 + id + '.png'}/>
                                    </div>
                                    {
                                        metadata.attributes[8].value !== 'None' &&
                                        <div className='my-modal-box-item bigger-on-hover' onClick={() => reqVChange(id.toString(), '4', func)}>
                                            Variant 4: Cover
                                            <img className='my-modal-box-item-image' src={zConf.V4 + cNum + '/' + id + '.png'}/>
                                        </div>
                                    }
      
                                    </div>
                                </>
                            }
                            {
                                messageData === 'success' &&
                                <>
                                <div className='my-modal-title success' >
                                SUCCESS!
                                </div>
                                <div className='my-modal-danger'>
                                    Please wait up to 2 hours to see the changes.
                                </div>
                                </>
                            }
                            {
                                (messageData === 'data error' || messageData === 'Error switching variant') &&
                                <>
                                <div className='my-modal-title error'>
                                Something Went Wrong
                                </div>
                                <div className='my-modal-danger'>
                                    Please try again later or contact support.
                                </div>
                                </>
                            }
                        </>
                        :
                        <>
                            <div className='my-modal-title'>
                                Loading...
                            </div>
                            <Spinner loading={loading} size={150} colour={'green'}/>
                        </>
                    }

                </div>
            </div>
        )
    }

    const Attributes = (props) => {
        let list = props.list
        let listItems = list.map((item)=>{
            let trait_type = item.trait_type.toString()
            let value = item.value.toString()
            return(
            <div className='zoomer-trait-box'>
                <div className='zoomer-trait-box-type'>
                    {trait_type}
                </div>
                <div className='zoomer-trait-box-value'>
                    {value}
                </div>
                <div className='zoomer-trait-box-rarity'>
                    {zoomerTraitP[trait_type][value]}
                </div>
            </div>
        )})

        return listItems
    }

    let zoomContractAddress = '0x3D03292D428fd7d6C81a6E76b4A333A3d8a0E296'

    useEffect(()=>{
        const getMetadata = async() => {
            let json = await fetchSingleTokenMeta(id.toString(), zoomContractAddress)
            return {
                json: json.json,
                owner: json.owner,
                add: zoomContractAddress
            }
        }
        
        getMetadata().then((result)=>{setMetadata(result.json); setOwner(result.owner); setCAdd(result.add)})
    },[id])

    useEffect(()=>{
        if(account && contractWriteProviders){
            checkAllowance(contractWriteProviders, account).then(
                (result) => {
                    setAllowance(result)
                }
            )
            
        }
    },[account, contractWriteProviders])

    let m = checkNotMobile(screenWidth)?'':'m-'
    

    return(
        <>
        <ToastContainer position="top-center"/>
        <div className='centered-x-and-y'><img className='banner' src={webStorage + '/zoomers/zoomer_mint_banner.png'} alt="foo"/></div>
        <div className={m+'zoomer-all-container'}>
            <div className={m+'zoomer-section-container'}>
                <img className='zoomer-section-image' src={metadata.image}/>
            </div>
            <div className={m+'zoomer-section-container'}>
                <div className='zoomer-section-box'>
                    <div className='zoomer-section-row'>
                        <div className='zoomer-collection-symbol-container'>
                            <img src={webStorage + '/navbar/zoomerLogo.png'} style={{width: '100%'}}></img>
                        </div>
                        <div className='zoomer-section-name'>
                            {metadata.name}
                        </div>
                    </div>
                </div>
                <div className='zoomer-section-box'>
                    <div className='zoomer-info-container'>
                        <div className="test-container">
                            <div className="test-title">Rank</div>
                            <div className="test-description">{zoomerRank[id]}</div>
                        </div>
                        <div className="test-container">
                            <div className="test-title">Token ID</div>
                            <div className="test-description">{metadata.id}</div>
                        </div>
                        <div className="test-container">
                            <div className="test-title">Contract Address</div>
                            <div className="test-description"><a href={'https://cronoscan.com/address/'+cAdd} target='blank'>{cAdd}</a></div>
                        </div>
                        <div className="test-container">
                            <div className="test-title">Owner</div>
                            <div className="test-description"><a href={'https://cronoscan.com/address/'+owner} target='blank'>{owner}</a></div>
                        </div>
                        <div className="test-container">
                            <div className="test-title">Description</div>
                            <div className="test-description">{metadata.description}</div>
                        </div>
                    </div>
                </div>
                <div className='zoomer-section-box'>
                    <div className='zoomer-attributes-container'>
                        <Attributes list={metadata.attributes?metadata.attributes:[]} />
                    </div>
                </div>
                {
                    account === owner &&
                    <>
                        <div className='zoomer-section-box'>
                            <div className='zoomer-special-button lb' onClick={() => openModal(variantModalID)}>
                                <div>SELECT VARIANT</div><div style={{fontStyle: 'italic'}}>FREE</div>
                            </div>
                            <VariantModal />
                            <div className="test-container">
                                <div className="test-title">Variant Types</div>
                                <div className="test-description">
                                    <div className='zoomer-quickbox'>
                                        Variant 1: In-Game
                                    </div>
                                    <div className='zoomer-quickbox'>
                                        Variant 2: PFP
                                    </div>
                                    <div className='zoomer-quickbox'>
                                        Variant 3: Out of Game
                                    </div>
                                    <div className='zoomer-quickbox'>
                                        Variant 4: Covers
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='zoomer-section-box'>
                            {
                                BigInt(allowance) <= BigInt('250000000000000000000')?
                                <div className='zoomer-special-button green' onClick={() => appCon()}>
                                    <div>APPROVE CONTRACT</div>
                                </div>
                                :
                                <div className='zoomer-special-button green' onClick={() => rollT()}>
                                    <div>ROLL COVER V1</div><div style={{fontStyle: 'italic'}}>250 SCRATCH</div>
                                </div>
                            }

                            <div className="test-container">
                                <div className="test-title">Cover Types and Percentages</div>
                                <div className="test-description">
                                    <div className='zoomer-quickbox'>
                                        Monochrome: 16%
                                    </div>
                                    <div className='zoomer-quickbox'>
                                        Static: 16%
                                    </div>
                                    <div className='zoomer-quickbox'>
                                        Fog: 16%
                                    </div>
                                    <div className='zoomer-quickbox'>
                                        Oil Painting: 11%
                                    </div>
                                    <div className='zoomer-quickbox'>
                                        Neon: 11%
                                    </div>
                                    <div className='zoomer-quickbox'>
                                        Stain Glass: 11%
                                    </div>
                                    <div className='zoomer-quickbox'>
                                        Silver: 7%
                                    </div>
                                    <div className='zoomer-quickbox'>
                                        Gold: 7%
                                    </div>
                                    <div className='zoomer-quickbox'>
                                        Holographic: 5%
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
        </>
    )
}