import React from 'react';
import { useContext } from 'react';
import ScreenWidthContext from '../../context/screenwidthcontext';
import checkNotMobile from '../logic/mobile';

export const YoutubeVideo = (props) => {
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)

    let videoURL = props.videoURL

    const height: string = checkNotMobile(screenWidth)?'390':'300'
    const width: string = checkNotMobile(screenWidth)?'640':'300'

    return (
        <div style={{margin: '20px'}}>
            <iframe width={width} height={height} src={videoURL} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    );
}