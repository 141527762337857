
import { useState, useContext, useEffect, useCallback } from 'react';
import ConnectedContext from '../../context/connectedcontext';
import Web3Context from '../../context/web3context';
import toContract from '../../types/truffle_contracts';

import ChowTime from '../../contracts/ChowTime.json'
import Web3 from 'web3';
import { Row, Col, InputNumber } from 'antd';
import ScreenWidthContext from '../../context/screenwidthcontext';

import Spinner from '../../components/spinner'
import connectedAndGoodChain from '../../components/logic/connectedandgoodchain';
import Web3ContractReadWriteContext from '../../context/web3ContractReadWriteContext';
import { MovePageDownHeader } from '../../components/movepagedownheader';

import CronosFossilGen2 from '../../contracts/CronosFossilGen2.json'

import '../launchpad.css'
import '../launchpadmobile.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { webStorage } from '../../config';


function CronosFossilsLaunchpad () {

    // Get web3 and the account (metamask public key) to place it on our button text once user is CONNECTED
    const {account, setAccount, balance, setBalance, chainID, setChainID} = useContext(Web3Context) //ommiting web3 and creating it locally because of having to set up contract
    // context to verify if the user WANTS to be connected ot the site, IE if they have clicked the connect button yet, again we cant DISCONNECT the user's metamask, only give the illusion of NOT being connected
    const {connected, setConnected} = useContext(ConnectedContext)
    // Screen Width context
    const {screenWidth, setScreenWidth} = useContext(ScreenWidthContext)
    // contract read and write providers context
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    
    const contractRead = toContract(CronosFossilGen2)
    const contractWrite = toContract(CronosFossilGen2)


    // set up contract
    if(contractWriteProviders){
        const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
        contractRead.setProvider(contractReadProviders)
        contractWrite.setProvider(contractWriteProviders)
    }

    // hooks for use throughout the mint page
    const [maxSupply, setMaxSupply] = useState('')
    const [currentSupply, setCurrentSupply] = useState('')
    // const [mintCost, setMintCost] = useState('')
    // const [mintCostBI, setMintCostBI] = useState(BigInt(0))
    const [amountToMint, setAmountToMint] = useState(1)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)

    const [currentAllowedMint, setCurrentAllowedMint] = useState('')
    const [freeClaimAmount, setFreeClaimAmount] = useState('')
    const [wlClaimAmount, setWlClaimAmount] = useState('')

    const [mode, setMode] = useState('select')

    // 1000000000000000000 = 1 cro

    /**
     * 
     * Skeleton Variables
     * 
     */

    let description: string = 'Come and join us as the Geologists from Cronos Rocks and Fossils excavate the Cronos Fossils Gen 2 collection. 3 different fossil types are waiting to be uncovered along with some unique and collaborative 1:1 NFT’s. Within the collection you will see CRONOS GOLD ingot coins and CRONOS GOLD Alluvial nuggets discovered and sent out to lucky minters. Holders of Gen 2 will also receive a free boom room cosmetic component mint for each fossil held which will be themed for the Cronos Rocks and Fossils project. At the heart of the Cronos Rocks team are 2 passionate and driven Geologists (IRL) who continue to re-invest and re-distribute royalties from the Fossil collections to the Fossil holders. 5% of the minting funds will be donated to “R U OK” and the “Sea Shepherd” Organizations.'

    /**
     * 
     * Smart Contract Functions
     * 
     */

    // mint
    const onFreeMint = async() => {
        // TODO mint function smart contract
        setLoading(true)
        const web3 = new Web3(contractWriteProviders)
        let cfContract = await contractWrite.deployed()

        await toast.promise(
            cfContract.freeMint(amountToMint, {from: account}),
            {
              pending: 'Free Minting...',
              success: {
                render(){
                  return <div>Success!</div>
                },
                // other options
                icon: "🟢",
              },
              error: 'Failed to mint.'
            }
        ).catch(()=> setLoading(false))
        setLoading(false)

        // await cfContract.freeMint(amountToMint, {from: account}).catch(
        //     setLoading(false),
        // )
        // setLoading(false)
        updateCurSup()

        // TODO refresh balance on successful mint
        web3.eth.getBalance(account, function(err, result) {
            if (err) {
                console.log(err)
            } else {
                console.log(web3.utils.fromWei(result, "ether") + " ETH")
                setBalance(web3.utils.fromWei(result, "ether"))
            }
        })
    }

    const onWLMint = async() => {
        // TODO mint function smart contract
        setLoading2(true)
        const web3 = new Web3(contractWriteProviders)
        let cfContract = await contractWrite.deployed()

        await toast.promise(
            cfContract.whitelistMint(amountToMint, {from: account, value: (BigInt(amountToMint)*BigInt('350000000000000000000')).toString()}),
            {
              pending: 'WL Minting...',
              success: {
                render(){
                  return <div>Success!</div>
                },
                // other options
                icon: "🟢",
              },
              error: 'Failed to mint.'
            }
        ).catch(()=> setLoading2(false))

        // await cfContract.whitelistMint(amountToMint, {from: account, value: (BigInt(amountToMint)*BigInt('350000000000000000000')).toString()}).catch(
        //     setLoading2(false),
        // )
        setLoading2(false)
        updateCurSup()

        // TODO refresh balance on successful mint
        web3.eth.getBalance(account, function(err, result) {
            if (err) {
                console.log(err)
            } else {
                console.log(web3.utils.fromWei(result, "ether") + " ETH")
                setBalance(web3.utils.fromWei(result, "ether"))
            }
        })
    }

    const onPublicMint = async() => {
        // TODO mint function smart contract
        setLoading3(true)
        const web3 = new Web3(contractWriteProviders)
        let cfContract = await contractWrite.deployed()

        await toast.promise(
            cfContract.mint(amountToMint, {from: account, value: (BigInt(amountToMint)*BigInt('375000000000000000000')).toString()}),
            {
              pending: 'Public Minting...',
              success: {
                render(){
                  return <div>Success!</div>
                },
                // other options
                icon: "🟢",
              },
              error: 'Failed to mint.'
            }
        ).catch(()=> setLoading3(false))

        // await cfContract.mint(amountToMint, {from: account, value: (BigInt(amountToMint)*BigInt('375000000000000000000')).toString()}).catch(
        //     setLoading3(false),
        // )
        setLoading3(false)
        updateCurSup()

        // TODO refresh balance on successful mint
        web3.eth.getBalance(account, function(err, result) {
            if (err) {
                console.log(err)
            } else {
                console.log(web3.utils.fromWei(result, "ether") + " ETH")
                setBalance(web3.utils.fromWei(result, "ether"))
            }
        })
    }

    // update the value in the input number mint box
    const onChange = (value: number) => {
        setAmountToMint(value)
    }

    // update current supply
    const updateCurSup = async() => {

        // TODO get new current supply from contract
        const web3 = new Web3(contractWriteProviders)
        let cfContract = await contractWrite.deployed()

        let curSup = await cfContract.totalSupply()

        setCurrentSupply(curSup.toString())
    }

    // get WL allocation
    const getCurrentAllowed = async() => {

        // TODO get allowed amount of NFTs that can be minted from smart contract using user 0x address as parameter
    }

    const getFreeMintClaims = async() => {
        const web3 = new Web3(contractWriteProviders)
        let cfContract = await contractRead.deployed()

        let curFree = await cfContract.checkFreeClaim(account)
        setFreeClaimAmount(curFree.toString())
    }

    const checkOnWL = async() => {
        const web3 = new Web3(contractWriteProviders)
        let cfContract = await contractRead.deployed()

        let onWL = await cfContract.checkWhitelistClaim(account)
        if(onWL.toString() === '0'){
            setWlClaimAmount('NO')
        } else {
            setWlClaimAmount('YES')
        }
    }

    // load info on user web3 connections
    useEffect(() => {
        const load = async() => {

            getCurrentAllowed()
            updateCurSup()
            getFreeMintClaims()
            checkOnWL()
        }

        load()
    },[connected])

    // evaluate current supply every new block
    useEffect(()=> {

        // TODO might have to rewrite this part, Web3.eth giving error

        // var subscription = Web3.eth.subscribe('newBlockHeaders', function(error, result){
        //   if (!error) {
        //       console.log('New Block');
        //       updateCurSup()
              
      
        //       return;
        //   }
      
        //   console.error(error);
        // })
    
        // return(() => {
        //   // unsubscribes the subscription
        //   subscription.unsubscribe(function(error, success){
        //     if (success) {
        //         console.log('Successfully unsubscribed!');
        //     }
        //   })
        // })
      },[])


    return (

    screenWidth > 1279?
        <div>
            <ToastContainer position="top-center"/>
            {MovePageDownHeader(screenWidth)}
            <div className='centered-x-and-y'><img className='banner' src={webStorage +'/launchpad/cronosfossils/BANNER.png'} alt="foo"/></div>
            <div className='lp-nftbanner-line'/>
            <Row justify='center'>
                <div className='lp-nftbanner'>
                    <img className='lp-nft' src={webStorage +'/launchpad/cronosfossils/NFT1.png'} alt="foo"/>
                    <img className='lp-nft' src={webStorage +'/launchpad/cronosfossils/NFT2.png'} alt="foo"/>
                    <img className='lp-nft' src={webStorage +'/launchpad/cronosfossils/NFT3.png'} alt="foo"/>
                </div>
            </Row>
            <Row justify='center'>
                <div className='lp-description'>
                    {description}
                </div>
            </Row>
            <Row justify='center'>
                <div className='lp-socials flex-and-center flex-column'>
                    Socials
                    <Row>
                        <a href='https://discord.gg/UtdADzxM6j' target='_blank'><img className='lp-socials-logo' src={webStorage + '/navbar//discordlogo.png'} alt="foo" /></a>
                        <a href='https://twitter.com/cronosrocksnft' target="_blank"><img className='lp-socials-logo' src={webStorage + '/navbar//twitterlogo.png'} alt="foo" /></a>
                        <a href='https://www.cronosrocks.com/' target="_blank"><img className='lp-socials-logo' src={webStorage + '/navbar//websitelogo.png'} alt="foo" /></a>
                    </Row>
                </div>
            </Row>
            <Row justify='center'>
                <div className='lp-mintrow'>
                    <div>
                        <img className='lp-mintgif' src={webStorage + '/launchpad/cronosfossils/MINTGIF.gif'} alt="chow time"/>
                    </div>
                    <div className='lp-mintdescription'>
                            <div className='lp-mintdescription-row'>{'Mint Tiers: Free Mint, WL Mint, Public Mint'}</div>
                            <div className='lp-mintdescription-row'>{'WL Mint Price: 350 CRO'}</div>
                            <div className='lp-mintdescription-row'>{'Public Mint Price: 375 CRO'}</div>
                            <div className='lp-mintdescription-row'>{'Free Mint Date: August 25th 12PM UTC'}</div>
                            <div className='lp-mintdescription-row'>{'Public & WL Mint Date: August 25th 1PM UTC'}</div>
                            <div className='lp-mintdescription-row'>{'Max Supply: 600'}</div>
                            <div className='lp-mintdescription-row'>{'Max mint per wallet: unlimited'}</div>
                            <div className='lp-mintdescription-row'>{'Max mint per transaction: 10'}</div>
                    </div>
                </div>
            </Row>
            <Row justify='center'>
                <img className='lp-soldout' src={webStorage + '/stickers/soldout.png'}/>
            </Row>
            {/* {connectedAndGoodChain(connected, chainID)?
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>
                        <div className='lp-mintdescription-row'>{'Current Supply: '}{currentSupply}{' / 600'}</div>
                        <div className='lp-mintdescription-row'>{'Current Free Claims: '}{freeClaimAmount}</div>
                        <div className='lp-mintdescription-row'>{'Whitelisted: '}{wlClaimAmount}</div>
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-button lp-green bigger-on-hover text-shadow' onClick={onFreeMint}>
                    <Spinner loading={loading}/>
                    {loading?'':'Free Mint'}
                    </div>
                    <div className='lp-button lp-blue bigger-on-hover text-shadow' onClick={onWLMint}>
                    <Spinner loading={loading2}/>
                    {loading2?'':'WL Mint'}
                    </div>
                    <div className='lp-button lp-pink bigger-on-hover text-shadow' onClick={onPublicMint}>
                    <Spinner loading={loading3}/>
                    {loading3?'':'Public Mint'}
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>{'Amount to Mint: '}</div><InputNumber className='lp-mint-inputnumber' size="large" min={1} max={10} defaultValue={1} onChange={onChange}/>
                </Row>
            </>
            :
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>
                        <div className='lp-mintdescription-row'>{'Connect Wallet To Mint!'}</div>
                    </div>
                </Row>
            </>} */}
            <Row justify='center'>
                <img className='lp-roadmap' src={webStorage +'/launchpad/cronosfossils/ROADMAP.png'} alt="foo"/>
            </Row>
        </div>

        :
//MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================
        <div>
            <ToastContainer position="top-center"/>
            <div className='centered-x-and-y'><img className='banner' src={webStorage +'/launchpad/cronosfossils/BANNER.png'} alt="foo"/></div>
            <div className='mobile-lp-nftbanner-line'/>
            <Row justify='center'>
                <div className='mobile-lp-nftbanner'>
                    <img className='mobile-lp-nft' src={webStorage +'/launchpad/cronosfossils/NFT1.png'} alt="foo"/>
                    <img className='mobile-lp-nft' src={webStorage +'/launchpad/cronosfossils/NFT2.png'} alt="foo"/>
                    <img className='mobile-lp-nft' src={webStorage +'/launchpad/cronosfossils/NFT3.png'} alt="foo"/>
                </div>
            </Row>
            <Row justify='center'>
                <div className='mobile-lp-description'>
                    {description}
                </div>
            </Row>
            <Row justify='center'>
                <div className='mobile-lp-socials flex-and-center flex-column'>
                    Socials
                    <Row>
                        <a href='https://discord.gg/UtdADzxM6j' target='_blank'><img className='mobile-lp-socials-logo' src={webStorage + '/navbar//discordlogo.png'} alt="foo" /></a>
                        <a href='https://twitter.com/cronosrocksnft' target="_blank"><img className='mobile-lp-socials-logo' src={webStorage + '/navbar//twitterlogo.png'} alt="foo" /></a>
                        <a href='https://www.cronosrocks.com/' target="_blank"><img className='mobile-lp-socials-logo' src={webStorage + '/navbar//websitelogo.png'} alt="foo" /></a>
                    </Row>
                </div>
            </Row>
            <Row justify='center'>
                <div className='mobile-lp-mintrow flex-and-center flex-column'>
                    <div>
                        <img className='mobile-lp-mintgif' src={webStorage + '/launchpad/cronosfossils/MINTGIF.gif'} alt="chow time"/>
                    </div>
                    <div className='mobile-lp-mintdescription'>
                            <div className='mobile-lp-mintdescription-row'>{'Mint Tiers: Free Mint, WL Mint, Public Mint'}</div>
                            <div className='mobile-lp-mintdescription-row'>{'WL Mint Price: 350 CRO'}</div>
                            <div className='mobile-lp-mintdescription-row'>{'Public Mint Price: 375 CRO'}</div>
                            <div className='mobile-lp-mintdescription-row'>{'Free Mint Date: August 25th 12PM UTC'}</div>
                            <div className='mobile-lp-mintdescription-row'>{'Public & WL Mint Date: August 25th 1PM UTC'}</div>
                            <div className='mobile-lp-mintdescription-row'>{'Max Supply: 600'}</div>
                            <div className='mobile-lp-mintdescription-row'>{'Max mint per wallet: unlimited'}</div>
                            <div className='mobile-lp-mintdescription-row'>{'Max mint per transaction: 10'}</div>
                    </div>
                </div>
            </Row>
            <Row justify='center'>
                <img className='mobile-lp-soldout' src={webStorage + '/stickers/soldout.png'}/>
            </Row>
            {/* {connectedAndGoodChain(connected, chainID)?
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>
                        <div className='lp-mintdescription-row'>{'Current Supply: '}{currentSupply}{' / 600'}</div>
                        <div className='lp-mintdescription-row'>{'Current Free Claims: '}{freeClaimAmount}</div>
                        <div className='lp-mintdescription-row'>{'Whitelisted: '}{wlClaimAmount}</div>
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-button lp-green bigger-on-hover text-shadow' onClick={onFreeMint}>
                    <Spinner loading={loading}/>
                    {loading?'':'Free Mint'}
                    </div>
                    <div className='lp-button lp-blue bigger-on-hover text-shadow' onClick={onWLMint}>
                    <Spinner loading={loading2}/>
                    {loading2?'':'WL Mint'}
                    </div>
                    <div className='lp-button lp-pink bigger-on-hover text-shadow' onClick={onPublicMint}>
                    <Spinner loading={loading3}/>
                    {loading3?'':'Public Mint'}
                    </div>
                </Row>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>{'Amount to Mint: '}</div><InputNumber className='lp-mint-inputnumber' size="large" min={1} max={10} defaultValue={1} onChange={onChange}/>
                </Row>
            </>
            :
            <>
                <Row justify='center'>
                    <div className='lp-mintdescription-fast'>
                        <div className='lp-mintdescription-row'>{'Connect Wallet To Mint!'}</div>
                    </div>
                </Row>
            </>} */}
            <Row justify='center'>
                <img className='mobile-lp-roadmap' src={webStorage +'/launchpad/cronosfossils/ROADMAP.png'} alt="foo"/>
            </Row>
        </div>
    )
}

export default CronosFossilsLaunchpad