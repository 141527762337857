
import { useState, useContext, useEffect } from 'react';

import ConnectedContext from '../../context/connectedcontext';
import Web3Context from '../../context/web3context';
import Web3 from 'web3';
import { Row, InputNumber } from 'antd';
import ScreenWidthContext from '../../context/screenwidthcontext';
import Spinner from '../../components/spinner'
import connectedAndGoodChain from '../../components/logic/connectedandgoodchain';
import Web3ContractReadWriteContext from '../../context/web3ContractReadWriteContext';
import '../launchpad.css'
import '../launchpadmobile.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { webStorage } from '../../config';
import checkNotMobile from '../../components/logic/mobile';
import { Link } from "react-router-dom";
import { AbiItem } from 'web3-utils';

const SouSouBearDen = require('./SouSouBearDen.json')


export const Sousou = () => {

    const { account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance } = useContext(Web3Context)
    const { connected, setConnected } = useContext(ConnectedContext)
    const { screenWidth, setScreenWidth } = useContext(ScreenWidthContext)
    const { contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders } = useContext(Web3ContractReadWriteContext)

    // // set up contract
    // if (contractWriteProviders) {
    //     const web3 = new Web3(contractWriteProviders); //<======= If ommited will not load web3 if starting from /myboomers, same goes for /mintboomer
    //     contractWrite.setProvider(contractWriteProviders)

    // }

    // hooks for use throughout the mint page
    const [currentSupply, setCurrentSupply] = useState('')
    const [amountToMint, setAmountToMint] = useState(1)
    const [loading1, setLoading1] = useState(false)
    const [publicState, setPublicState] = useState(false)
    const [contractInstance, setContractInstance] = useState(null)
    // 1000000000000000000 = 1 cro
    /**
     * 
     * Skeleton Variables
     * 
     */

    const description: string = `Welcome to the Sousoubears Den NFT Minting Page! 🍯Discover charming NFTs from Den Defenders to Den Divulgers. Mint your favorites, stake for $HONI rewards, and unlock perks in Honey Rush! Start minting now and join the adventure!`

    const maxSupply = '2815'
    const mintPrice = '33000000000000000000'
    const contractAddress = '0xa01D2269D4AC6531386C488C96732575efCE6990'
    const discordLink = 'https://discord.gg/w92Vf84BV9'
    const twitterLink = 'https://twitter.com/sousoubears'
    const websiteLink = 'https://linktr.ee/sousoubears'

    const Socials = () => {
        return (
            <Row>
                <a href={discordLink} target='_blank'><img className={`${screenWidth > 1279 ? 'lp-socials-logo bigger-on-hover' : 'mobile-lp-socials-logo'}`} src={webStorage + '/navbar/discordlogo.png'} alt="foo" /></a>
                <a href={twitterLink} target="_blank"><img className={`${screenWidth > 1279 ? 'lp-socials-logo bigger-on-hover' : 'mobile-lp-socials-logo'}`} src={webStorage + '/navbar/twitterlogo.png'} alt="foo" /></a>
                <a href={websiteLink} target="_blank"><img className={`${screenWidth > 1279 ? 'lp-socials-logo bigger-on-hover' : 'mobile-lp-socials-logo'}`} src={webStorage + '/navbar/websitelogo.png'} alt="foo" /></a>
            </Row>
        )
    }

    const MintDescription = () => {
        let m = ''
        if (!checkNotMobile(screenWidth)) {
            m = 'm-'
        }
        return (
            <>
                <div className='lp-mintdescription-row'>{'Mint Start Date: '}<div className={m + 'row-answer'}>{'April 3 23:00 UTC'}</div></div>
                <div className='lp-mintdescription-row'>{'Mint Price:'}<div className={m + 'row-answer'}>{'33 CRO'}</div></div>
                <div className='lp-mintdescription-row'>{'Max Supply:'}<div className={m + 'row-answer'}>{maxSupply}</div></div>
            </>
        )
    }

    const multiplyBigInts = (a, b): string => {
        return (BigInt(a.toString()) * BigInt(b.toString())).toString()
    }

    const MintButtons = () => {
        return (
            <>
                <div className='lp-button lp-blue bigger-on-hover text-shadow shadow-button-w pink' onClick={() => onMint()}>
                    <Spinner loading={loading1} size={75} />
                    {loading1 ? '' : 'Mint'}
                </div>
            </>
        )
    }


    const refreshInfo = async () => {
        const web3 = new Web3(contractWriteProviders)
        let contractInstance = new web3.eth.Contract(SouSouBearDen.abi, contractAddress)

        // update CRO
        let croBalance = await web3.eth.getBalance(account)
        setBalance(web3.utils.fromWei(croBalance, "ether"))

        // update current supply
        let curSup = await contractInstance.methods.totalSupply().call()
        setCurrentSupply(curSup.toString())

        let saleState = await contractInstance.methods.saleState().call()
        setPublicState(saleState)
    }

    // update the value in the input number mint box
    const onChange = (value: number) => {
        setAmountToMint(value)
    }

    const onMint = async () => {
        setLoading1(true);
        const web3 = new Web3(contractWriteProviders);

        // Check if the user has enough CRO to mint
        let totalMintPrice = multiplyBigInts(amountToMint, mintPrice);
        if (BigInt(totalMintPrice) > BigInt(Web3.utils.toWei(balance, 'ether'))) {
            toast.error('Not enough CRO.', { autoClose: 5000 });
            setLoading1(false);
            return;
        }

        // Check if mint is turned on
        if (!publicState) {
            toast.error('Minting is not live, please check again later.', { autoClose: 5000 });
            setLoading1(false);
            return;
        }

        // ABI fragment for the mint function
        const mintFunctionABI: AbiItem = {
            name: 'mint',
            type: 'function',
            inputs: [{
                type: 'uint256',
                name: 'amount'
            }],
        };

        // Encode the function call
        const data = web3.eth.abi.encodeFunctionCall(mintFunctionABI, [amountToMint.toString()]);

        const gasEstimate = await web3.eth.estimateGas({
            from: account,
            to: contractAddress, // The address of the contract you are calling
            data: data,
            value: totalMintPrice // The amount of Ether (in wei) to send with the transaction
        });
        const adjustedGasLimit = Math.floor(gasEstimate * 1.6);

        try {
            await toast.promise(
                web3.eth.sendTransaction({
                    from: account,
                    to: contractAddress, // The address of the contract you are calling
                    data: data,
                    value: totalMintPrice, // The amount of Ether (in wei) to send with the transaction
                    gas: adjustedGasLimit // Setting the gas limit for the transaction
                }),
                {
                    pending: 'Minting...',
                    success: 'Success!',
                    error: 'Failed to mint.'
                }
            );
            await refreshInfo();
        } catch (error) {
            console.error('Minting failed:', error);
        } finally {
            setLoading1(false);
        }
    };

    // load info on user web3 connection
    useEffect(() => {

        const web3 = new Web3(contractWriteProviders)
        let contractInstance = new web3.eth.Contract(SouSouBearDen.abi, contractAddress)
        setContractInstance(contractInstance)

        const load = async () => {
            refreshInfo()
        }
        load()
    }, [connected, contractWriteProviders])

    const inputNumber =
        <Row justify='center' style={{ marginBottom: '20px' }}>
            <div className='lp-mintdescription-fast'>{'Amount to Mint: '}&nbsp;</div><InputNumber className='lp-mint-inputnumber' size="large" min={1} max={50} defaultValue={1} onChange={onChange} />
        </Row>

    const mintDetails =
        <Row justify='center'>
            <div className='lp-mintdescription-fast flex-and-center flex-column'>
                <div className='lp-mintdescription-row'>{'Current Supply: '}{currentSupply}{' / '}{maxSupply}</div>
            </div>
        </Row>

    const linkToCollection =
        <Row justify={'center'}>
            <Link to={`/collection/0xa01D2269D4AC6531386C488C96732575efCE6990`}>
                <div className='lp-viewcollection-button shadow-button-w'>
                    VIEW MY NFTS
                </div>
            </Link>
        </Row>

    const connectWalletComp =
        <>
            {
                connectedAndGoodChain(connected, chainID) ?
                    <>
                        {linkToCollection}
                        {mintDetails}
                        {inputNumber}
                        <Row justify='center' style={{ display: 'flex', margin: '0px 0px 40px 0px' }}>
                            <MintButtons />
                        </Row>
                    </>
                    :
                    <>
                        <Row justify='center'>
                            <div className='lp-mintdescription-fast'>
                                <div className='lp-mintdescription-connectwallet'>{'Connect Wallet To Mint!'}</div>
                            </div>
                        </Row>
                    </>
            }

        </>

    const SocialsComp = <Row justify='center'>
        <div className='lp-socials flex-and-center flex-column'>
            Socials
            <Socials />
        </div>
    </Row>



    return (
        <>
            <ToastContainer position="top-center" />
            {
                checkNotMobile(screenWidth) ?
                    <>
                        <div className="container2"><img className='banner' src={webStorage + '/launchpad/sousou/sousou_banner.png'} /></div>
                        <div className='lp-nftbanner-line' />
                        <Row justify='center'>
                            <div className='lp-description'>
                                {description}
                            </div>
                        </Row>
                        {SocialsComp}
                        <Row justify='center'>
                            <div className='lp-mintrow'>
                                <div>
                                    <img className='lp-mintgif' src={webStorage + '/launchpad/sousou/sousou promo.png'} alt='' />
                                </div>
                                <div className='lp-mintdescription'>
                                    <MintDescription />
                                </div>
                            </div>
                        </Row>
                        {connectWalletComp}
                    </>

                    :
                    //MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================MOBILE STARTS HERE ==========================
                    <>
                        <div className="container2"><img className='banner' src={webStorage + '/launchpad/sousou/sousou_banner.png'} alt="myboomersbanner" /></div>
                        <div className='mobile-lp-nftbanner-line' />
                        <Row justify='center'>
                            <div className='mobile-lp-description'>
                                {description}
                            </div>
                        </Row>
                        {SocialsComp}
                        <Row justify='center'>
                            <div className='mobile-lp-mintrow flex-and-center flex-column'>
                                <div>
                                    <img className='mobile-lp-mintgif' src={webStorage + '/launchpad/sousou/sousou promo.png'} alt='' />
                                </div>
                                <div className='mobile-lp-mintdescription'>
                                    <MintDescription />
                                </div>
                            </div>
                        </Row>
                        {connectWalletComp}
                    </>
            }
        </>

    )
}