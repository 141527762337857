import './index.css'

export const styles = {

      connectModalBody : {
        border: "2px solid beige",
        backgroundColor: "#051221",
        width: '340px',
        height: '100%',
        margin: '0px',
    },

    buyModalBody : {
        border: "2px solid beige",
        backgroundColor: "#051221",
        width: '600px',
        height: '100%',
        margin: '0px',
    },

    buyModalBodyMobile : {
        border: "2px solid beige",
        backgroundColor: "#051221",
        width: '340px',
        height: '100%',
        margin: '0px',
    },

    marketplaceCardModal: {
        height: '100%',
        backgroundColor: '#051221',
        width: '900px',
        border: "5px solid beige",
    },

    mobilemarketplaceCardModal: {
        height: '100%',
        backgroundColor: '#051221',
        width: '300px',
        border: "2px solid beige",
    },
}